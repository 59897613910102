import React, { useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useIntl } from 'react-intl';
import ImageUploader from '../models/ImageUploader';

const ClassicEditor: any = Editor;

interface TextEditorProps {
    value: string;
    onChange: (value: string) => void;
}

const TextEditor = (props: TextEditorProps) => {
    const intl = useIntl();
    const { value, onChange } = props;
    const editorRef = useRef<any>();

    return (
        <CKEditor
            editor={ClassicEditor}
            data={value}
            config={{placeholder: intl.formatMessage({ id: "EmailEditor.Placeholder" })}}
            onReady={(editor: any) => {
                editor.ui.getEditableElement().parentElement.append(editor.ui.view.toolbar.element);
                editorRef.current = editor;
                editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => new ImageUploader(loader);
            }}
            onError={( error: any, { willEditorRestart }: any) => {
                if (willEditorRestart && !!editorRef.current) {
                    editorRef.current.ui.view.toolbar.element.remove();
                }
            }}
            onChange={(event: any, editor: any) => {
                onChange(editor.getData());
            }}
        />
    );
}

export default TextEditor;
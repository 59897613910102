import { Subject } from 'rxjs';
import io from 'socket.io-client';
import { SocketOperations, logging, StorageKeys } from 'utils';
import { socketAuth } from 'services/auth';
import { ConfigurationHelper, GeneralHelper } from 'utils/helpers';
import { JoinData } from 'services/io/interfaces';
import Lockr from 'lockr';

export class SocketManager {
  public socket: SocketIOClient.Socket | undefined;
  public socketId: string | undefined;
  private readonly socketServerAddress: string = ConfigurationHelper.socketServerDomain;
  private readonly roomsJoined: string[] = new Array<string>();
  private disconnected: boolean = true;
  private readonly logger = logging.getLogger('SocketManager');
  private readonly socketErrorValue = 'socket manager: error: ';
  private static readonly socketioDebugging: boolean = false;

  private interval: any;

  onInitialized: Subject<any> = new Subject<any>();
  onReconnect: Subject<any> = new Subject<any>();
  onDisconnect: Subject<boolean> = new Subject<boolean>();
  public errorStarting: boolean = false;

  public initialize() {
    GeneralHelper.logCox(`in socketio.ts, in initialize()`);
    this.connect((socketConnectedArgs) => {
      this.logger.info('in socketio.ts, in callback of this.connect(), will call this.onInitialized');
      this.onInitialized.next({ socketId: socketConnectedArgs.socketId });
      if (Lockr.get<boolean>("debug_reconnect")) {
        for (var i = 0; i < 5; ++i) {
          setTimeout(() => {
            this.onInitialized.next({ socketId: socketConnectedArgs.socketId });
          }, 100 * i);
        }
      }
    });
  }

  public connect(onConnected?: (socketConnectedArgs: any) => void): SocketManager {
    if (SocketManager.socketioDebugging) {
      this.logger.info('in socketio.ts, in connect, onConnected, initiating socket service');
    }

    if (this.socket) {
      (this.socket as any).destroy();
      delete this.socket;
      this.socket = undefined;
    }

    this.socket = io(this.socketServerAddress, {
      secure: this.socketServerAddress.startsWith('https://'),
      reconnection: true,
      rejectUnauthorized: false,
      //query: 'token=' + localStorage.getItem('tokenId')
    });

    setTimeout(() => {
      if (this.disconnected === true) {
        logging.offlineHandler.next();
      }
    }, 5000);

    this.socket.on(SocketOperations.SocketConnected, (value: any) => {
      GeneralHelper.logCox('in socketio.ts, in socket.on(SocketOperations.SocketConnected))');
      this.socketId = value.socketId;

      this.roomsJoined.forEach(r => this.joinRoom(r));

      logging.onlineHandler.next();

      if (onConnected) {
        onConnected(value);
      }
    });

    this.socket.on(SocketOperations.Connect, () => {
      logging.onlineHandler.next();

      this.disconnected = false;

      GeneralHelper.logCox('in socketio.ts, in socket.on(SocketOperations.Connect))');
    });

    this.socket.on(SocketOperations.Disconnect, () => {
      this.disconnected = true;
      this.onDisconnect.next(true);

      this.interval = window.setInterval(() => {
        if (this.disconnected) {
          GeneralHelper.logCox(`in socketio.ts, on(SocketOperations.Disconnect, setInterval, this.disconnected`);
          this.connect((socketConnectedArgs) => {
            this.onReconnect.next(socketConnectedArgs);
          });

          logging.offlineHandler.next();
        } else {
          GeneralHelper.logCox(`in socketio.ts, on(SocketOperations.Disconnect, setInterval, !this.disconnected`);
          window.clearInterval(this.interval);
        }
      }, 5000);
      if (SocketManager.socketioDebugging) {
        this.logger.info('socket manager: client socket disconnected.');
      }
    });

    this.socket.on(SocketOperations.SignedOut, () => {
      socketAuth.userSignedOut.next();
    });

    this.socket.on(SocketOperations.SignedOtherBrowser, () => {
      socketAuth.userSignedInOtherWindow.next();

      localStorage.removeItem(StorageKeys.ChatHistory);
    });

    return this;
  }

  public joinRoom(room: string): void {
    if (SocketManager.socketioDebugging) {
      this.logger.info(`in socketio.ts: jnoinRoom:${room}`);
    }
    try {
      if (this.socket) {
        const args: JoinData = {
          token: room,
          companyKey: Lockr.get<string>(StorageKeys.CompanyKey)
        };

        this.socket.emit(SocketOperations.JoinRoom, args);
      }
      if (!this.roomsJoined.includes(room)) {
        this.roomsJoined.push(room);
      }
    }
    catch (error) {
      this.logger.info(this.socketErrorValue + error);
    }
  }

  public leaveRoom(room: string): void {
    this.logger.info(`in socketio.ts: leaveRoom:${room}`);

    try {
      if (this.socket) {
        const args: JoinData = {
          token: room,
          companyKey: Lockr.get<string>(StorageKeys.CompanyKey)
        };
        this.socket.emit(SocketOperations.LeaveRoom, args);
      }

      if (this.roomsJoined.includes(room)) {
        this.roomsJoined.pop();
      }
    }
    catch (error) {
      this.logger.info(this.socketErrorValue + error);
    }
  }

  public disconnect(): void {
    this.logger.info(`in socketio.ts, in disconnect()`);

    try {
      if (this.socket) {
        this.socket.disconnect();

        window.clearInterval(this.interval);
      }
    }
    catch (error) {
      this.logger.info(this.socketErrorValue + error);
    }
  }

  public notifyAllViewsAtLogin(): void {
    this.logger.info(`notifyAllViewsAtLogin not implemented for stage 2`);
  }
}
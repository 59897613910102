import { TransferOperations, logging } from "utils";
import { Controllers } from "utils/enums-s3";
import { GeneralHelper } from "utils/helpers";

export class GenericTransfersService {
    private readonly logger = logging.getLogger('GenericInviteService');
    
    getTransferDestinations(): Promise<any[]> {
        return GeneralHelper.invokeServiceCall('', TransferOperations.ColdTransferIVRDestinations, this.logger, Controllers.Generics);
    }

    getTransferAgentsDestinations(searchPayload: any): Promise<any[]> {
        return GeneralHelper.invokeServiceCall(searchPayload, TransferOperations.ColdTransferDestinations, this.logger, Controllers.Generics);
    }
}

const genericTransferService = new GenericTransfersService();
export default genericTransferService;
import React, { useState, useMemo, useEffect } from 'react';
import { Menu, tabListBehavior, Flex, Box } from '@fluentui/react-northstar';
import { MenuActiveItem, SharedStyleClasses, StorageKeys, TabViewIds } from '../../../utils';
import './style.scss';
import { mainViewHelper } from '../../../utils/helpers/main-view-helper';
import { useIntl } from 'react-intl';
import AgentView from './AgentView/AgentView';
import HistoryView from './HistoryView/HistoryView';

interface TabData {
    key: string;
    content: string;
    onClick: () => void;
    tabcontent: any;
    tabid: string | number;
    active: boolean;
    viewtype: number;
    className?: string;
}

const TabsView = () => {
    const intl = useIntl();
    const [activeTabId, setActiveTabId] = useState<string | number>(TabViewIds.GenericAgent);
    const [dynamicTabs, setTabItems] = useState<TabData[]>([]);
 
    const staticTabs = useMemo<TabData[]>(() => {
        return [
            {
                key: 'agent',
                content: intl.formatMessage({ id: "MediaTabs.Agent" }),
                onClick: () => changeView(TabViewIds.GenericAgent),
                tabcontent: <AgentView />,
                tabid: TabViewIds.GenericAgent,
                active: activeTabId === TabViewIds.GenericAgent,
                viewtype: MenuActiveItem.GenericAgent,
                className: SharedStyleClasses.TabUnderline
            },
            {
                key: 'history',
                content: intl.formatMessage({ id: "MediaTabs.History" }),
                onClick: () => changeView(TabViewIds.GenericHistory),
                tabcontent: <HistoryView />,
                tabid: TabViewIds.GenericHistory,
                active: activeTabId === TabViewIds.GenericHistory,
                viewtype: MenuActiveItem.GenericHistory,
                className: SharedStyleClasses.TabUnderline
            }
        ];
    }, [activeTabId]);

    useEffect(() => {
        const storedActiveTab = localStorage.getItem(StorageKeys.GenericMediaActiveTab);

        if (storedActiveTab == `${TabViewIds.GenericHistory}`) {
            changeView(TabViewIds.GenericHistory);
        }
    }, []);

    const changeView = (tabId: TabViewIds) => {
        setActiveTabId(tabId || TabViewIds.GenericAgent);
        setTabItems((prev: TabData[]) => prev.map((x: TabData) => ({...x, active: x.tabid === tabId})));
        localStorage.setItem(StorageKeys.GenericMediaActiveTab, `${tabId}`);

        if (tabId && !Number.isInteger(tabId)) {
            mainViewHelper.tabViewChange.next(tabId);
        }
    }

    const getTabViewContent = (tab: TabData, index: number, tabKey: string) => (
        <Box
            className={activeTabId === tab.tabid ? "active-tab" : "hidden-tab"}
            key={`${tabKey}-${index}`}
        >
            {tab.tabcontent}
        </Box>);

    return (
        <>
            <Flex className="menu-tabs-container">
                <Flex.Item>
                    <Menu
                        items={staticTabs}
                        underlined
                        primary
                        accessibility={tabListBehavior}
                        aria-label="External tabs"
                        styles={() => ({
                            margin: '0 0 20px',
                        })}
                        className="menu-tabs"
                    />
                </Flex.Item>
                <Flex.Item>
                    <Menu
                        items={dynamicTabs}
                        underlined
                        primary
                        accessibility={tabListBehavior}
                        aria-label="External links"
                        styles={() => ({
                            margin: '0 0 20px',
                        })}
                        className="menu-tabs menu-links"
                    />
                </Flex.Item>
            </Flex>
            <div key="staticTabContentKey">
                {
                    staticTabs.map((tab: TabData, index: number) => getTabViewContent(tab, index, 'agentTab-id'))
                }
            </div>
            <div key={"externalTabs"}>
                {
                    dynamicTabs
                        .filter(x => x.viewtype === MenuActiveItem.ExternalTab)
                        .map((tab: TabData, index: number) => getTabViewContent(tab, index, 'externalTab-id'))
                }
            </div>
        </>
    )
};

export default TabsView;

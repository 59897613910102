import Lockr from 'lockr';
import { Subject } from 'rxjs';
import { ApiPresence } from '.';
import { logging } from 'utils/logging';
import { ResponseWrapper, ServiceOperations, StorageKeys, Controllers, ListenerOperations } from 'utils';
import { Presence, PresenceAndNoteDTO } from 'utils/domain/presence';
import { GeneralHelper } from 'utils/helpers';
import { Listener, listeners } from 'services/io/listeners';

export class PresenceService implements ApiPresence {
    private readonly logger = logging.getLogger('PresenceService');

    select: Subject<Presence> = new Subject<Presence>();
    wrapUp: Subject<boolean> = new Subject<boolean>();
    public readonly listenerPresenceAndNoteSet: Listener<PresenceAndNoteDTO> = listeners.createListener<PresenceAndNoteDTO>(ListenerOperations.PresenceAndNoteSet);

    GetWrapUpCodes(CompanyRef: number): Promise<ResponseWrapper> {
        const requestData = { CompanyRef: CompanyRef };
        return GeneralHelper.invokeServiceCall(requestData, ServiceOperations.GetReasonsFromCache, this.logger, Controllers.Presence);
    }

    GetCurrentPresence(): Promise<Presence> {
        const requestData = { sip: Lockr.get<string>(StorageKeys.SIP), UserAzureId: Lockr.get(StorageKeys.UserObjectId) };
        return GeneralHelper.invokeServiceCall(requestData, ServiceOperations.GetCurrentPresence, this.logger, Controllers.Presence);
    }

    GetAzureCurrentPresence(): Promise<Presence> {
        return GeneralHelper.invokeServiceCall(null, ServiceOperations.GetAzureCurrentPresence, this.logger, Controllers.Presence);
    }
}

import React from 'react';
import "./custom-agenda.scss"
export const CustomAgendaDateTemplate = (props: any) => {
    const { intl, dataContext } = props;
    const header = dataContext.header;
    const getHeader = () => {
        try {
            const dateFormat = getResource("Agenda.HeaderDateFormat");
            const dateFromString = new Date(header);
            const monthName = getMonthName(dateFromString);
            const dayName = getDayOfTheWeek(dateFromString);
            const day = '' + dateFromString.getDate();
            const year = '' + dateFromString.getFullYear();

            return dateFormat.replace("{month}", monthName)
                .replace("{dayOfWeek}", dayName)
                .replace("{day}", day)
                .replace("{year}", year);
        } catch (ex) {
            return header;
        }
    }



    const getDayOfTheWeek = (date: Date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const day = days[date.getDay()];

        return getResource(`Agenda.Day.${day}`);
    }

    const getMonthName = (date: Date) => {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const month = months[date.getMonth()];

        return getResource(`Agenda.Month.${month}`);
    }

    const getResource = (name: string) => {
        return intl.formatMessage({ id: name });
    }

    return (<>
        <div className='custom-agenda-header'>
            {getHeader()}
        </div>
    </>);
};
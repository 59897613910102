import React from 'react';
import { Flex, Text, CloseIcon } from '@fluentui/react-northstar';
import { ErrorColor } from 'utils';
import { useIntl } from 'react-intl';

export const ErrorView = (prop: any) => {
  const intl = useIntl();

  return <Flex vAlign="center" space="between"
    styles={({ theme: { siteVariables } }) => ({
      backgroundColor: prop.color === ErrorColor.Red ? siteVariables.colorScheme.red.background : siteVariables.colorScheme.default.backgroundPressed,
    })}
  >
    <Flex
      styles={({ theme: { siteVariables } }) => ({
        backgroundColor: prop.color === ErrorColor.Red ? siteVariables.colorScheme.red.background : siteVariables.colorScheme.default.backgroundPressed,
        color: prop.color === ErrorColor.Red ? siteVariables.colorScheme.default.foregroundHover1 : siteVariables.colorScheme.default.foregroundPressed,
        padding: '5px',
        width: '100%'
      })}
      hAlign="center"
      vAlign="center"
    >
      <Text align="center" content={prop.content}></Text>
    </Flex>
    <CloseIcon styles={({ theme: { siteVariables } }) => ({
      cursor: 'pointer',
      backgroundColor: prop.color === ErrorColor.Red ? siteVariables.colorScheme.red.background : siteVariables.colorScheme.default.backgroundPressed,
      color: prop.color === ErrorColor.Red ? siteVariables.colorScheme.default.foregroundHover1 : siteVariables.colorScheme.default.foregroundPressed,
      paddingRight: '5px'
    })} size="medium" title={intl.formatMessage({ id: "ErrorMessage.Close" })} onClick={prop.removeCallback} />
  </Flex>
};

export default ErrorView;
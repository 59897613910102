import { MediaType, QueueMediaStatusType } from "utils/enums";
import { WaitingMediaDto } from "../waitingMediaDTO";
import { ActiveMediaDto } from "./ActiveMediaDto";

export class SupervisorMediaDtoStage3 {
    ActiveMedia?: ActiveMediaDto[];
    WaitingMedia?: WaitingMediaDto[];
}

export class MediaDisplayItem {
    SessionId?: string;
    MediaType?: MediaType;
    MediaTypeAsString?: string;
    CallerName?: string;
    CallerPhone?: string;
    StartDate?: string;
    QueueRef?: string;
    QueueName?: string;
    Agent?: string;
    AgentUpn?: string;
    AssignedTo?: string;
    Status?: QueueMediaStatusType;
    StatusAsString?: string;
    IsActiveMedia?: boolean;
    QueuePriority!: number;
    IsCallPickupEnabled?: boolean;
    IsAssigned?: boolean;
    ScenarioId?: string;
    IsSupervised?: boolean;
    IsMonitoringEnabled?: boolean;
}
import { ResponseWrapper } from 'utils';
import { WrapUpSettings } from 'utils/domain/wrapUpSettings';
import { WrapUpService } from './socket-wrap-up';
import { PresenceAndNoteDTO } from 'utils/domain/presence';

export interface ApiWrapUp {
    GetAllWrapUpCollectionTopicMap(): Promise<ResponseWrapper>;
    IsAgentInWrapUp(sip: string): Promise<boolean>;
    GetOngoingWrapUpSettings(sip: string): Promise<WrapUpSettings>;
    GetPreviousPresenceAndNote(sip: string): Promise<PresenceAndNoteDTO>;
}

export const socketWrapUp = new WrapUpService();

import Lockr from 'lockr';
import { useIntl } from "react-intl";
import { Button, ContentIcon, Divider, Flex, LeaveIcon, ParticipantAddIcon } from '@fluentui/react-northstar';
import React, { useState } from 'react';
import { CustomerDTO } from 'utils/domain/customerJourneyModels';
import { StorageKeys } from 'utils';

export interface SearchProps {
    searchViewShown: boolean,
    updateSearchView: (arg: boolean) => void,
    EditCustomer: CustomerDTO | undefined,
    UpdateEditCustomer: (arg?: CustomerDTO | undefined) => void
}

export const CustomerJourneyNavigationView: React.FC<SearchProps> = ({searchViewShown, updateSearchView, UpdateEditCustomer}) => {    
    const intl = useIntl();

    const [isSearchViewShown, setIsSearchViewShown] = useState(searchViewShown);

    React.useEffect(() => {
        setIsSearchViewShown(searchViewShown);
    }, [searchViewShown]);

    const updateEditCustomer = (customer?: CustomerDTO | undefined) => {
        if (customer) {
            customer.CompanyRef = Lockr.get<number>(StorageKeys.CompanyId);
        }
        UpdateEditCustomer(customer);
    }

    const toggleSearchViewShown = () => {
        updateSearchView((isSearchViewShown ? false : true));
    }

    return (
        <Flex column styles={{padding: "5px"}}>
            <Flex gap="gap.medium"
                styles={({ theme: {siteVariables} }) => ({
                    color: siteVariables.colorScheme.default.foreground2,
                    backgroundColor: siteVariables.colorScheme.default.background2
                })}
            >
                <div style={{ position: "relative" }}>
                    <Button iconOnly text icon={<ContentIcon size="large" />} title="Customer journey" size="medium" />
                </div>
                <Button 
                    icon={<ParticipantAddIcon outline />}
                    content={intl.formatMessage({ id: "CustomerJourney.CustomerEditView.TitleAdd"})} primary
                    onClick={() => {updateEditCustomer(new CustomerDTO())}}
                    className="add-customer-button" 
                />
                <Button icon={<LeaveIcon outline />}
                    content={intl.formatMessage({ id: "CustomerJourney.CustomerSearchView.Title"})} primary
                    onClick={() => {toggleSearchViewShown()}}
                    className="switch-customer-button"
                />
                <Divider size={1} styles={{marginBottom: "5px"}}/>    
            </Flex>
        </Flex>
    );
};

export default CustomerJourneyNavigationView;
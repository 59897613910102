import { SocialMediaRequestDTO } from "utils/domain/socialMedia/socialMediaRequestDTO";
import { CMMediaSessionMessageDTO, SocialMediaSessionDTO } from "utils/domain/socialMedia/socialMediaSessionDTO";
import { WebhookSettingDTO } from "utils/domain/socialMedia/webhookSettingDTO";
import { SocialMediaService } from "./socket-socialmedia";

export interface ApiSocialMedia {
    SocialMediaAccept(socialMediaRequest: SocialMediaRequestDTO): Promise<any>;
    SocialMediaIgnore(socialMediaRequest: SocialMediaRequestDTO): Promise<any>;
    SocialMediaTerminate(socialMediaRequest: SocialMediaRequestDTO): Promise<any>;
    GetActiveSocialMediaSessions(sip: string): Promise<SocialMediaSessionDTO[]>;
    GetWebHookSetting(providerName?: string): Promise<WebhookSettingDTO>;
    GetActiveCmMediaConversationHistory(sessionId: string): Promise<CMMediaSessionMessageDTO[]>;
}

export const socketSocialMedia = new SocialMediaService();
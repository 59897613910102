import React, { useEffect, useRef, useState } from 'react';
import { Flex, List } from '@fluentui/react-northstar';
import ErrorView from '../ErrorView';
import { RealtimeReportsView } from '../';
import { logging } from '../../utils/logging';
import { serviceCall } from '../../services/io/service-call';
import { useIntl } from 'react-intl';
import './mainView.scss';
import { socketAuth } from '../../services';
import { socketAudio } from '../../services/calling';
import { mainViewHelper } from '../../utils/helpers/main-view-helper';
import { ActiveMainViewItem, ErrorColor } from '../../utils';
import { webChatManager } from '../../services/io/web-chat';
import { socketVoicemails } from '../../services/voiceMails';
import { BotNotificationDataDTO } from '../../utils/domain/botNotificationDataDTO';
import { Subscription } from 'rxjs';
import { socketOutbound } from '../../services/outbound';
import { GeneralHelper } from '../../utils/helpers';
import { StorageKeys } from "utils";
import Lockr from "lockr";
import ExtendedNotificationView from 'containers/VoiceMailsView/ExtendedNotificationView';
import ExtendedManagementView from 'containers/ManagementView/ExtendedManagementView';
import GenericView from 'containers/GenericView/GenericView';
import CallViewStage3 from 'containers/CallView/CallViewStage3';
import MediaViewStage3 from 'containers/MediaView/MediaViewStage3';
import PlaySoundHelper, { SoundType } from 'utils/helpers/play-sound-helper';

export const MainViewStage3 = () => {
  const intl = useIntl();
  const errorContent = useRef<any[]>([]);
  const [reference, setReference] = useState<any>(Math.random());
  const [activeViewItem, setActiveViewItem] = useState(ActiveMainViewItem.MediaView);

  let subscriptionServiceCallOnInitialized: Subscription | null = null;
  let subscriptionViewChanged: Subscription | null = null;
  let subscriptionWebchatNotificationReceived: Subscription | null = null;
  let subscriptionVoiceMailNotificationReceived: Subscription | null = null;
  let subscriptionLoggingErrorHandler: Subscription | null = null;
  let subscriptionPlayBusyTone: Subscription | null = null;

  useEffect(() => {
    initialize();

    return () => {
      componentWillUnmount();
    }
  }, []);

  const componentWillUnmount = () => {
    subscriptionServiceCallOnInitialized?.unsubscribe();
    subscriptionViewChanged?.unsubscribe();
    subscriptionWebchatNotificationReceived?.unsubscribe();
    subscriptionVoiceMailNotificationReceived?.unsubscribe();
    subscriptionLoggingErrorHandler?.unsubscribe();
    subscriptionPlayBusyTone?.unsubscribe();
  }

  const removeError = (value: string) => {
    const errorContentWithRemovedItem = errorContent.current.filter(x => x.content.props.content !== value);
    setTimeout(() => {
      errorContent.current = errorContentWithRemovedItem;
      GeneralHelper.logCox(`reference was ${reference}`);
      setReference(Math.random());
      GeneralHelper.logCox(`reference is ${reference}`);
      clearTimeout(0);
    }, 0);
  }

  const initialize = () => {
    GeneralHelper.logCox(`in MainView.tsx, in initialize`);

    if (!serviceCall.isDisconnected) {
      socketAuth.userVerified.next(true);
      socketAudio.getCurrentCallSession();
    }

    subscriptionServiceCallOnInitialized?.unsubscribe();
    subscriptionServiceCallOnInitialized = serviceCall.onInitialized.subscribe(() => {
      socketAuth.userVerified.next(true);
      socketAudio.getCurrentCallSession();
    });

    subscriptionViewChanged?.unsubscribe();
    subscriptionViewChanged = mainViewHelper.viewChanged.subscribe((item: ActiveMainViewItem) => {
      if (item === ActiveMainViewItem.MediaView || item === ActiveMainViewItem.SocialMedia) {
        socketOutbound.isAgentOutboundAccessEnabled();
      }

      setActiveViewItem(item);
    });

    subscriptionWebchatNotificationReceived?.unsubscribe();
    subscriptionWebchatNotificationReceived = webChatManager.webChatNotificationReceivedFromBot.subscribe((webchatSessionId: string) => {
      setActiveViewItem(ActiveMainViewItem.WebChatView);
    });

    subscriptionVoiceMailNotificationReceived?.unsubscribe();
    subscriptionVoiceMailNotificationReceived = socketVoicemails.voiceMailNotificationReceivedFromBot.subscribe((notificationData: BotNotificationDataDTO) => {
      setActiveViewItem(ActiveMainViewItem.VoiceMailsView);
    });

    subscriptionLoggingErrorHandler?.unsubscribe();
    subscriptionLoggingErrorHandler = logging.errorHandler.subscribe((data: any) => {
      const message = intl.formatMessage({ id: data });
      const errorMap = errorContent.current.map(x => x.content.props.content);

      const isRed = data.includes("Red");

      if (!errorMap.includes(message)) {
        GeneralHelper.logCox(`reference was ${reference}`);
        setReference(Math.random());
        GeneralHelper.logCox(`reference is ${reference}`);
        
        var interval = setTimeout(() => {
          errorContent.current.shift();
          GeneralHelper.logCox(`reference was ${reference}`);
          setReference(Math.random());
          GeneralHelper.logCox(`reference is ${reference}`);
          clearTimeout(interval);
        }, isRed ? 10000 : 5000);

        errorContent.current.push({
          content: <ErrorView content={message} color={isRed ? ErrorColor.Red : ErrorColor.Grey} removeCallback={() => removeError(message)} />
        });

        GeneralHelper.logCox(`reference was ${reference}`);
        setReference(Math.random());
        GeneralHelper.logCox(`reference is ${reference}`);
      }
    });

    subscriptionPlayBusyTone?.unsubscribe();
    subscriptionPlayBusyTone = socketAudio.playBusyToneNotification.subscribe((data:any)=>{
      PlaySoundHelper.playSound(SoundType.Busy, data.BusyTonePath);
    })

    const userwayKey = Lockr.get<string>(StorageKeys.UserwayKey);
    if (userwayKey) {
      enableUserWayScript(userwayKey);
    }
  }

  const enableUserWayScript = (dataAccount: string) => {
    var s = document.createElement("script");
    s.setAttribute("data-account", dataAccount);
    s.setAttribute("src", "https://cdn.userway.org/widget.js");
    (document.body || document.head).appendChild(s);
  }

  return (
    <Flex key="main-view-container" className="main-view-container" styles={({ theme: { siteVariables } }) => ({
      backgroundColor: siteVariables.colorScheme.default.background2,
    })}>

      <div key="app-header-container" className="app-header-container">
        <Flex styles={({ theme: { siteVariables } }) => ({
          backgroundColor: siteVariables.colorScheme.default.backgroundPressed,
          color: siteVariables.colorScheme.default.foregroundPressed,
          zIndex: '1',
          opacity: '0.8',
          width: '100%',
          position: 'fixed',
          top: 0,
          left: '0'
        })}>
          {/* #warning-js [cc4all-1102]: fix "Warning: Each child in a list should have a unique "key" prop." because of this List key={reference} for the first error ... */}
          <List key={reference} items={errorContent.current} className={'error-list'} />
        </Flex>

        <CallViewStage3 />
        <ExtendedManagementView />
      </div>
      <div key="app-body-container" className="app-body-container">
        {(activeViewItem === ActiveMainViewItem.MediaView || activeViewItem === ActiveMainViewItem.SocialMedia) && <MediaViewStage3 />}
        {activeViewItem === ActiveMainViewItem.WebChatView && <GenericView />}
        {activeViewItem === ActiveMainViewItem.VoiceMailsView && <ExtendedNotificationView />}
        {activeViewItem === ActiveMainViewItem.RealtimeReportsView && <RealtimeReportsView />}
      </div>
    </Flex>
  );
}

export default MainViewStage3;

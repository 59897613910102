import { Subject } from "rxjs";
import { HeaderBarDisplayedMessageType } from "utils";
import { FavoriteContactDTO } from "utils/domain/favoriteContactDTO";

export class MainViewHelper {
    viewChanged: Subject<any> = new Subject<any>();
    tabViewChange: Subject<string | number> = new Subject<string | number>();
    missedCallNotificationReceivedFromBot: Subject<string> = new Subject<string>();
    addedOrRemovedFavoriteContact: Subject<FavoriteContactDTO> = new Subject<FavoriteContactDTO>();
    navigationViewGoToDialpad: Subject<string | any> = new Subject<string | any>();
    showHideLeftPanelTriggerButtons: Subject<boolean> = new Subject<boolean>();
    showHeaderBarMessage: Subject<HeaderBarDisplayedMessageType> = new Subject<HeaderBarDisplayedMessageType>();
    showSocialMediaSwitchBtn: Subject<boolean> = new Subject<boolean>();
}

export const mainViewHelper = new MainViewHelper();

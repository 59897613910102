import { Button, CloseIcon, Flex, Text } from "@fluentui/react-northstar";
import React from "react";

export interface RecipientInfoProps {
    name: string;
    email: string;
    onClose: () => void;
}

const RecipientInfo = (props: RecipientInfoProps) => {
    return (<Flex 
        space="between"
        gap="gap.smaller"
        vAlign="center"
        hAlign="center"
        className="em-recipient-container"
    >
        {
            !!props.name && <Text
                truncated
                title={props.name}
                content={props.name}
                className="em-recipient-text"
            />
        }
        <Text
            truncated
            title={props.email}
            content={`<${props.email}>`}
            className="em-recipient-text"
        />
        <Button
            text
            iconOnly
            icon={<CloseIcon size="small"/>}
            size="small"
            onClick={() => props.onClose()}
        />
    </Flex>);
}

export default RecipientInfo;

import { Subject } from "rxjs";
import { serviceCall } from 'services/io/service-call';
import { InvokeServiceArgs } from 'services/io/interfaces';
import { ApiCallHistoryService } from '.';
import { logging, SocketOperations } from "utils";
import { PersonalCallHistoryDTO } from 'utils/domain/personalCallHistoryDTO';

export class CallHistoryService implements ApiCallHistoryService {
    private readonly logger = logging.getLogger('CallHistoryService');

    callBackContactInfo: Subject<string | any> = new Subject<string | any>();

    AddNewRecord(data: any): Promise<any> {//#warning-js [cc4all-1102]: delete obsolete method and the serverside
        return new Promise((resolve, reject) => {
            const args: InvokeServiceArgs = {
                operation: SocketOperations.CallHistoryAddNewRecord,
                requestData: data,
                responseHandler: {
                    success: (result) => {
                        resolve(result);
                    },
                    error: (err) => {
                        logging.errorHandler.next("ErrorMessage.CallHistoryAddNewRecord");
                        this.logger.error(err);
                        reject(err);
                    }
                }
            };
            serviceCall.invokeService(args);
        });
    }

    GetListByAgentRef(agentIdNumber: number): Promise<PersonalCallHistoryDTO[]> {
        return new Promise((resolve, reject) => {
            const args: InvokeServiceArgs = {
                operation: SocketOperations.GetCallHistoryListByAgentRef,
                requestData: agentIdNumber,
                responseHandler: {
                    success: (result) => {
                        resolve(result);
                    },
                    error: (err) => {
                        logging.errorHandler.next("ErrorMessage.GetCallHistoryListByAgentRef");
                        this.logger.error(err);
                        reject(err);
                    }
                }
            };
            return serviceCall.invokeService(args);
        });
    }
}

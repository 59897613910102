import { ChatMessageSource, ChatMessageType } from "utils";
import { CMStatusCode } from "./socialMedia/socialMediaSessionDTO";

export class ChatHistoryDTO {
    Id?: string;
    Message!: string;
    MessageOwner!: string;
    ChatSessionRef!: string;
    AgentSIP!: string;
    Source!: ChatMessageSource;
    Type!: ChatMessageType;
    AttachmentRef!: string;
    AttachmentURL!: string;
    AttachmentFileName!: string;
    AttachmentType!: string;
    AttachmentSize!: number;
    AttachmentContent?: ArrayBuffer;
    Date!: Date;
    Name?: string;
    CmStatusCode?: CMStatusCode;
}
import React, { useState } from 'react';
import { Button, Flex, Segment } from '@fluentui/react-northstar';
import './webChatView.scss';
import { ChatListView } from './ChatListView/ChatListView';
import { WebChatConversation } from './WebChatConversation/WebChatConversation';
import { WebChatInputView } from './WebChatInputView/WebChatInputView';
import { webChatManager } from 'services/io/web-chat';
import { ChatViewProps } from 'utils/domain/chatViewProps';
import { ChatStatusType } from 'utils';
import { Subscription } from 'rxjs';
import { GeneralHelper } from 'utils/helpers';

export const WebChatView = ({ activeWebchatsessionId, isCmMedia }: any) => {
    const [isLeftPanelActive, setIsLeftPanelActive] = useState(true);
    const leftPanelBtnKey = "leftPanelBtnKey-" + Math.random() as any;

    const [chatMessagesKey, setChatMessagesKey] = React.useState((Math.random() + 'message') as any);
    const [chatInputKey, setChatInputKey] = React.useState((Math.random() + 'input') as any);
    const [chatPropsObject, setChatPropsObject] = React.useState(new ChatViewProps());

    let subscriptionOnChatConversationChanged: Subscription | null = null;

    const leftPanelBtnClass = "show-hide-left-panel-btn";

    React.useEffect(() => {
        initialize();
        return () => {
            componentWillUnmount();
        }
    }, []);

    const initialize = () => {
        GeneralHelper.logCox(`in WebChatView.tsx, in initialize`);

        subscriptionOnChatConversationChanged?.unsubscribe();
        subscriptionOnChatConversationChanged = webChatManager.onChatConversationChanged.subscribe((obj: any) => {
            if (!isCmMedia && obj) {
                setChatPropsObject(obj);

                setChatMessagesKey(Math.random() + 'message');
                setChatInputKey(Math.random() + 'input');
            }
        });

        if (isCmMedia) {
            const chatConvProps: ChatViewProps = {
                SessionRef: activeWebchatsessionId,
                ChatStatusType: ChatStatusType.Accepted,
                ClientUniqueId: '',
                WebChatName: '',
                CustomerName: ''
            };
            setChatPropsObject(chatConvProps);
        }
    }

    const componentWillUnmount = () => {
        GeneralHelper.logCox(`in WebChatView.tsx, in componentWillUnmount`);
        subscriptionOnChatConversationChanged?.unsubscribe();
    }

    const toggleLeftPanel = (isActive: boolean) => {
        setIsLeftPanelActive(!isActive);
    }

    return (<Flex className="web-chat-container" gap="gap.small" >
        <div className={isLeftPanelActive ? "chat-list-container app-left-panel active" : "chat-list-container app-left-panel"}>
            <div className="navigation-container">
                <div className="column">
                    <div>
                        <Flex column
                            styles={({ theme: { siteVariables } }) => ({
                                color: siteVariables.colorScheme.default.foreground,
                                backgroundColor: siteVariables.colorScheme.default.background,
                            })}
                        >
                            <Segment key="chat-list-segment" className="chat-list-segment">
                                <ChatListView key="chat-list-segment-view" isCmMedia={isCmMedia} activeWebchatsessionId={activeWebchatsessionId} />
                            </Segment>
                        </Flex>
                    </div>
                </div>
            </div>
        </div>
        <div className="left-panel-trigger-buttons">
            <Button key={leftPanelBtnKey} circular primary className={isLeftPanelActive ? leftPanelBtnClass + " active" : leftPanelBtnClass}
                title={isLeftPanelActive ? "Hide" : "Show"} size="medium"
                content={<i className="arrow"></i>}
                onClick={() => toggleLeftPanel(isLeftPanelActive)}
            />
        </div>
        <div className="calls-container">
            <Flex column
                styles={({ theme: { siteVariables } }) => ({
                    color: siteVariables.colorScheme.default.foreground,
                    backgroundColor: siteVariables.colorScheme.default.background,
                })}
                className="web-chat-message-container"
            >
                <Segment className="messages-segment">
                    <WebChatConversation key={chatMessagesKey}
                        isCmMedia={isCmMedia}
                        chatConversation={chatPropsObject}
                        activeWebchatsessionId={activeWebchatsessionId} />
                </Segment>
                <Segment className="input-segment">
                    <WebChatInputView
                        key={chatInputKey}
                        isCmMedia={isCmMedia}
                        sessionRef={chatPropsObject.SessionRef}
                        chatStatus={chatPropsObject.ChatStatusType}
                    />
                </Segment>
            </Flex>
        </div>
    </Flex>
    )
};

export default WebChatView;

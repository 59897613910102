import { FavoriteContactType } from "./favoriteContactDTO";
import { Presence } from "./presence";

export class Contact {
    ID!: number;
    CompanyRef!: number;
    ContactName!: string;
    Source!: string;
    QueueMapList!: Array<number>;
    PresenceDto!: Presence;
    AddedBy!: number;
    IsDeleted!: boolean;
    Id!: string;
    DisplayName!: string;
    LocalPhone!: string;
    SipAddress!: string;
    Email!: string;
    UserPrincipalName?: string;
    Upn?: string;
    BusinessPhones!: string;
    MobilePhone!: string;
    JobTitle!: string;
    Department!: string;
    City!: string;
    OfficeLocation!: string;
    Country!: string;
    State!: string;
    PostalCode!: string;
    AzureADId!: string;
    ContactType!: number;
    Fields?: any;
    ExternalId?: string;
    ContactSourceType?: FavoriteContactType;
    FavoriteConactId?: number;
    IsFavorite?: boolean;
    QueueIds!: Array<string>;
}

export class PaginatedContacts {
    PageIndex!: number;
    PageSize!: number;
    Count !: number;
    ResultList!: Contact[];
}

export const contact = new Contact();
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Flex, Text, ShiftActivityIcon, CallIcon, Button, Input, SearchIcon, Tooltip } from '@fluentui/react-northstar';
import { useIntl } from 'react-intl';
import './mediaTabs.scss'
import { Subscription } from 'rxjs';
import AdvancedTable, { stringCellComparator } from 'components/AdvancedTable';
import { Paginator } from 'containers';
import { socketAudio } from 'services/calling';
import { socketOutbound } from 'services/outbound';
import { ConferenceStatusAction, StorageKeys, TabViewIds } from 'utils';
import { CallSessionDTO } from 'utils/domain/callSessionDTO';
import { GeneralHelper } from 'utils/helpers';
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import { CallHistoryDTO } from 'utils/domain/extended/callHistoryDTO';
import { extendedCallHistoryService } from 'services/extendedCallHistory';
import Lockr from 'lockr';
import { DateFromBackendUtc } from 'utils/helpers/date-help';

const operations = [
    ConferenceStatusAction[ConferenceStatusAction.Offering],
    ConferenceStatusAction[ConferenceStatusAction.Terminated],
    ConferenceStatusAction[ConferenceStatusAction.CallerClosedAfterAnswer],
    ConferenceStatusAction[ConferenceStatusAction.SimultaniousIgnored],
    ConferenceStatusAction[ConferenceStatusAction.WarmAccepted],
    ConferenceStatusAction[ConferenceStatusAction.QueueWarmTransferCommited],
    ConferenceStatusAction[ConferenceStatusAction.Ignored],
    ConferenceStatusAction[ConferenceStatusAction.AgentColdTransfered],
    ConferenceStatusAction[ConferenceStatusAction.OperatorIVRColdTransferred],
    ConferenceStatusAction[ConferenceStatusAction.QueueColdTransferToCC4SQueue],
    ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransfered],
    ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransferred],
    ConferenceStatusAction[ConferenceStatusAction.QueueWarmTransferStarted],
    ConferenceStatusAction[ConferenceStatusAction.WarmInviteSent],
    ConferenceStatusAction[ConferenceStatusAction.CallerClosedBeforeAnswer],
    ConferenceStatusAction[ConferenceStatusAction.QueueColdTransferToCC4SQueue]
];

interface HistoryTableRow {
    key: string;
    items: HistoryTableColumn[];
}

interface HistoryTableColumn {
    key: string;
    content: any;
    className: string;
}

export const ExtendedCallHistory = () => {
    const maxPageSize = 10;
    const currentPage = useRef<number>(1);
    const searchFormText = useRef<string>("");
    const currentHistoryPage = useRef<CallHistoryDTO[]>([]);
    const [callHistoryList, setCallHistoryList] = useState<HistoryTableRow[]>([]);
    const isInACall = useRef<boolean>(false);
    const [keyValue, setKeyValue] = useState<number>(Math.random());

    const numberOfPages = useRef<number>(1);
    const agentHasOutboundEnabled = useRef<boolean>(false);
    const isPullCallExecuting = useRef<boolean>(false);

    const paginatorExists = useMemo<boolean>(() => numberOfPages.current > 1, [numberOfPages.current]);

    const intl = useIntl();

    let subscriptionCallSessionStateChanged: Subscription | null = null;
    let subscriptionRefreshHistoryTab: Subscription | null = null;
    let subscriptionAgentHasOutboundAccessEnabled: Subscription | null = null;

    useEffect(() => {
        initialize();

        return () => {
            componentWillUnmount();
        }
    }, []);

    const initialize = () => {
        checkIsAgentOutboundAccessEnabled();
        const currentUserId = Lockr.get<string>(StorageKeys.UserId);
        checkIsInACallState(currentUserId, socketAudio.callSession);

        subscriptionCallSessionStateChanged?.unsubscribe();
        subscriptionCallSessionStateChanged = socketAudio.callSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
            checkIsInACallState(currentUserId, callSessionDTO);

            if (callSessionDTO.IsCurrentUserThePrimaryAgent && operations.includes(callSessionDTO.ConferenceActionAsString)) {
                pullCallHistoryForCurrentPage();
            }
        });

        subscriptionRefreshHistoryTab?.unsubscribe();
        subscriptionRefreshHistoryTab = mainViewHelper.tabViewChange.subscribe((value: number | string) => {
            if (value !== TabViewIds.CallHistory) {
                return;
            }

            changePage(1);
        });

        subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
        subscriptionAgentHasOutboundAccessEnabled = socketOutbound.agentHasOutboundAccessEnabled.subscribe((isOutboundEnabled: boolean) => {
            if (agentHasOutboundEnabled.current === isOutboundEnabled) {
                return;
            }

            agentHasOutboundEnabled.current = isOutboundEnabled;
            populateCallHistoryList(currentHistoryPage.current);
        });

        changePage(1);
    };

    const checkIsInACallState = (currentUserId: string, callSessionDTO: CallSessionDTO) => {
        const startOperations = GeneralHelper.getStage3StartOperations;
        const endOperations = GeneralHelper.getStage3EndOperations();

        if (startOperations.includes(callSessionDTO.ConferenceActionAsString)) {
            isInACall.current = true;
            populateCallHistoryList(currentHistoryPage.current);
        }

        if (endOperations.includes(callSessionDTO.ConferenceActionAsString) || currentUserId !== callSessionDTO?.AgentRef) {
            isInACall.current = false;
            populateCallHistoryList(currentHistoryPage.current);
        }

        if (callSessionDTO.IsCurrentUserMonitoring || callSessionDTO.IsCurrentUserThePrimaryAgent) {
            isInACall.current = true;
            populateCallHistoryList(currentHistoryPage.current);
        }
    }

    const changePage = (page: number) => {
        currentPage.current = page;
        pullCallHistoryForCurrentPage();
    }

    const pullCallHistoryForCurrentPage = () => {
        if (isPullCallExecuting.current) {
            return;
        }

        isPullCallExecuting.current = true;
        extendedCallHistoryService.GetAgentHistory(currentPage.current, searchFormText.current, maxPageSize)
            .then(result => {
                numberOfPages.current = Math.ceil(result.TotalCount / maxPageSize) || 1;
                populateCallHistoryList(result.Items);
            })
            .finally(() => {
                setTimeout(() => isPullCallExecuting.current = false, 400);
            });
    }

    const populateCallHistoryList = (items: CallHistoryDTO[]) => {
        currentHistoryPage.current = items;
        setCallHistoryList(items.map(mapCallHistoryItem));
        setKeyValue(Math.random());
    }

    const checkIsAgentOutboundAccessEnabled = () => {
        agentHasOutboundEnabled.current = socketOutbound.HasOutboundAccesEnabled();
    }

    const componentWillUnmount = () => {
        subscriptionCallSessionStateChanged?.unsubscribe();
        subscriptionRefreshHistoryTab?.unsubscribe();
        subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
    };

    const columnNormalSize = 'big-grow table-header-column';
    const columnBiggestSize = "biggest-grow";
    const columnSmallestSize = "smallest-grow";

    const columnsMembers = [
        { title: intl.formatMessage({ id: "CallHistoryView.Name" }), key: 'Name', name: 'CallHistoryNameColumn', cellComparator: stringCellComparator, className: columnBiggestSize },
        { title: intl.formatMessage({ id: "CallHistoryView.Phone" }), key: 'Phone', name: 'CallHistoryPhoneColumn', cellComparator: stringCellComparator, className: columnBiggestSize },
        { title: intl.formatMessage({ id: "CallHistoryView.Queue" }), key: 'Queue', name: 'CallHistoryQueueColumn', cellComparator: stringCellComparator, className: columnNormalSize },
        { title: intl.formatMessage({ id: "CallHistoryView.Handled" }), key: 'Handled', name: 'CallHistoryHandledColumn', cellComparator: stringCellComparator, className: columnNormalSize },
        { title: intl.formatMessage({ id: "CallHistoryView.Type" }), key: 'Type', name: 'CallHistoryTypeColumn', cellComparator: stringCellComparator, className: columnNormalSize },
        { title: intl.formatMessage({ id: "CallHistoryView.Date" }), key: 'Date', name: 'CallHistoryDateColumn', cellComparator: stringCellComparator, className: columnNormalSize },
        { title: '', key: 'CallBack', name: 'CallHistoryCallColumn', cellComparator: stringCellComparator, className: columnSmallestSize },
    ];

    const getCallLabelAndIcon = (isIncomingCall: boolean, IsCallback: boolean = false) => {
        return (isIncomingCall
            ?
            <Flex gap="gap.small">
                <img alt='Incoming Call Icon' width="15" height="15" className='incoming-call-icon' />
                <Text content={intl.formatMessage({ id: "CallHistoryView.Incoming" })} />
            </Flex>
            :
            <Flex gap="gap.smaller">
                <img alt='Outgoing Call Icon' width="15" height="15" className='outgoing-call-icon' />
                <Text content={intl.formatMessage({ id: IsCallback ? "CallHistoryView.IncomingCallback" : "CallHistoryView.Outgoing" })} />
            </Flex>
        );
    }

    const getHandledValue = (obj: CallHistoryDTO) => {
        return intl.formatMessage({ id: `CallHistoryView.${obj.Handled ? "Yes" : "No"}` });
    }

    const mapCallHistoryItem = (obj: CallHistoryDTO): HistoryTableRow => {
        return {
            key: obj.Id,
            items: [
                {
                    content: <Tooltip mouseLeaveDelay={300}
                        trigger={
                            <Text content={obj.ContactName}
                            />
                        }
                        content={<Flex>
                            <Text content={obj.SessionId}
                                className="call-history-tooltip-content"
                            />
                        </Flex>
                        }
                    />,
                    key: `${obj.Id}-name`,
                    className: columnBiggestSize
                },
                {
                    content: <Tooltip mouseLeaveDelay={300}
                        trigger={
                            <Text content={obj.ContactNumber}
                            />
                        }
                        content={<Flex>
                            <Text content={obj.SessionId}
                                className="call-history-tooltip-content"
                            />
                        </Flex>
                        }
                    />,
                    key: `${obj.Id}-phone`,
                    className: columnBiggestSize
                },
                { content: obj.QueueName, key: `${obj.Id}-queueName`, className: columnNormalSize },
                { content: getHandledValue(obj), key: `${obj.Id}-isHandled`, className: columnNormalSize },
                {
                    content: obj.IsMissing
                        ? <Flex gap="gap.small">
                            <img alt='Missed Call Icon' width="18" height="18" className='missed-call-icon' />
                            <Text content={intl.formatMessage({ id: obj.IsCallback ? "CallHistoryView.MissedCallback" : "CallHistoryView.Missing" })} styles={{ color: "#c91212" }} />
                        </Flex>
                        : getCallLabelAndIcon(obj.IsIncomingCall, obj.IsCallback),
                    key: `${obj.Id}-callType`,
                    className: columnNormalSize
                },
                { content: GeneralHelper.formatAMPM(DateFromBackendUtc(obj.CallTime)), key: `${obj.Id}-callTime`, className: columnNormalSize },
                {
                    content: agentHasOutboundEnabled.current
                        ? <Button
                            iconOnly
                            text
                            disabled={isInACall.current}
                            icon={<CallIcon outline onClick={() => performCallBack(obj)} />}
                            title={intl.formatMessage({ id: 'CallHistoryView.CallBack' })}
                        />
                        : '',
                    key: `${obj.Id}-callBack`,
                    className: columnSmallestSize,
                },
            ]
        }
    }

    const performCallBack = (contact: CallHistoryDTO) => {
        mainViewHelper.tabViewChange.next(TabViewIds.Agent);
        const contactNumber = contact.ContactNumber;

        const sipOrNumber = GeneralHelper.isMobileNumber(contactNumber)
            ? GeneralHelper.getMobileNumberFromSip(contactNumber)
            : contactNumber;

        const contactData = { id: contact.ContactId, target: sipOrNumber }

        mainViewHelper.navigationViewGoToDialpad.next(contactData);
    }


    const applySearchOnHistory = (code: string) => {
        const searchTextLength = searchFormText.current.length;

        if (code === "Enter" && (searchTextLength > 2 || searchTextLength === 0)) {
            changePage(1);
        }
    }

    return (
        <Flex column key={keyValue} className="call-history-container" >
            <Flex gap="gap.small" vAlign="center">
                <ShiftActivityIcon />
                <Text content={intl.formatMessage({ id: "CallHistoryView.Header" })} weight="bold" />

                <Input
                    style={{ marginLeft: "10px" }}
                    icon={<SearchIcon size="small" />}
                    inverted
                    iconPosition="start"
                    defaultValue={searchFormText.current}
                    placeholder={intl.formatMessage({ id: "ContactsView.Search" })}
                    onChange={(e: any) => searchFormText.current = e.target.value.trim()}
                    onKeyDown={(e: any) => applySearchOnHistory(e.key)}
                />
            </Flex>

            <AdvancedTable
                columns={columnsMembers}
                rows={callHistoryList}
                label="Call History"
                className="call-history-table"
            />

            {paginatorExists && <Paginator
                currentPage={currentPage.current}
                totalPages={numberOfPages.current}
                onChange={(page) => changePage(page)}
            />
            }
        </Flex>
    )
};

export default ExtendedCallHistory;

import { Subject, Subscription } from "rxjs";
import { IncomingRequests } from "./incoming-requests";
import { incomingRequests } from ".";

export class Listener<T>
{
    received: Subject<T> = new Subject<T>();
    private subscriptionReceived: Subscription | null = null;

    constructor(private readonly operationName: string, private readonly incomingRequests: IncomingRequests) {
        this.bind();
    }

    private bind() {
        this.subscriptionReceived?.unsubscribe();
        this.subscriptionReceived = this.incomingRequests.received.subscribe(obj => {
            if (obj.Operation === this.operationName) {
                this.received.next(obj.RequestData);
            }
        });
    }
}

export class Listeners {
    createListener<T>(operationName: string): Listener<T> {
        return new Listener<T>(operationName, incomingRequests);
    }
}

export const listeners = new Listeners();
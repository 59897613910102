import React, { useState, useEffect, useRef } from "react";
import Lockr from "lockr";
import {
  Flex,
  Segment,
  Input,
  Button,
  Text,
  Dropdown,
} from "@fluentui/react-northstar";
import { ADSigninParams, mgtAuth, socketAuth, ssoAuth } from "services/auth";
import { AzureLoginType, ResponseResult, StorageKeys } from "utils";
import { useIntl } from "react-intl";
import { EnvHelper, GeneralHelper } from "utils/helpers";
import { Subscription } from "rxjs";
import { socketManager } from "services";

interface DeploymentDropdownItem {
  id: string;
  title: string;
  header: string;
  selected: boolean;
  item: TenantDeploymentDTO;
}

interface DeploymentDTO {
  id: string;
  name: string;
}

interface TenantDeploymentDTO {
  tenantId: string;
  azureTenantId: string;
  tenantName: string;
  deployment: DeploymentDTO;
}

export const AuthView = () => {
  const [userData, setUserData] = useState({
    name: Lockr.get<string>(StorageKeys.UserName),
    password: "",
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoggedInTeams, setIsLoggedInTeams] = useState(false);
  const [helper, setHelper] = useState({ text: "", adText: "" });
  const isLoginProcessing = useRef<boolean>(false);
  const intl = useIntl();
  const [deployments, setDeployments] = useState<DeploymentDropdownItem[]>([]);
  const [selectedDeployment, setSelectedDeployment] =
    useState<DeploymentDropdownItem>();
  const [isLogInHidden, setisLogInHidden] = useState(false);

  let subscriptionSocketAuthUserADSignedIn: Subscription | null = null;
  let subscriptionSocketAuthUserADSignedInFailed: Subscription | null = null;
  let subscriptionSsoFailed: Subscription | null = null;

  useEffect(() => {
    setIsButtonDisabled(
      !userData.name || !userData.name.trim() || !userData.password.trim()
    );
  }, [userData]);

  useEffect(() => {
    initialize();

    return () => componentWillUnmount();
  }, []);

  const componentWillUnmount = () => {
    GeneralHelper.logCox(`in AuthView.tsx, in componentWillUnmount`);
    subscriptionSocketAuthUserADSignedIn?.unsubscribe();
    subscriptionSocketAuthUserADSignedInFailed?.unsubscribe();
    subscriptionSsoFailed?.unsubscribe();
  };

  const initialize = () => {
    GeneralHelper.logCox(`in AuthView.tsx, in initialize`);
    subscriptionSocketAuthUserADSignedIn?.unsubscribe();
    subscriptionSocketAuthUserADSignedIn = socketAuth.userADSignedIn.subscribe(
      (signInParams: ADSigninParams) => {
        socketAuth
          .signInAzureAD(signInParams.IdToken)
          .then((response) => {
            if (
              response.Result === ResponseResult.VALIDATIONERROR &&
              !!response.ValidationFailures
            ) {
              const message = response.ValidationFailures.map(
                (x) => x.Message
              ).join(" ");
              setHelper((prev) => ({ ...prev, adText: message }));
              socketAuth.userSignedOut.next(true);
            }

            if (response.Data) {
              socketAuth.afterSignIn(response.Data);
              socketAuth.userSignedIn.next(true);

              // on extended we initialize the signalr connection after the auth token is retreived
              if (EnvHelper.isStage3()) {
                socketManager.initialize();
              }
            }
          })
          .catch((err) => {
            setHelper((prev) => ({
              ...prev,
              adText: JSON.stringify(err.toJSON()),
            }));
            socketAuth.userSignedOut.next();
            isLoginProcessing.current = false;
          });
      }
    );

    subscriptionSocketAuthUserADSignedInFailed?.unsubscribe();
    subscriptionSocketAuthUserADSignedInFailed =
      socketAuth.userADSignedInFailed.subscribe((failureMessage: string) => {
        setHelper((prev) => ({ ...prev, adText: failureMessage }));
        socketAuth.userSignedOut.next();
      });

    subscriptionSsoFailed?.unsubscribe();
    subscriptionSsoFailed = socketAuth.userSSOFailed.subscribe(() => {
      mgtAuth.InitProvider();
      mgtAuth.Login();
    });

    setTimeout(() => {
      setIsLoggedInTeams(
        !!(window as any)["config"].azureClients &&
          !!localStorage.getItem(StorageKeys.UPNForAzure)
      );
    }, 1500);

    setDeploymentUrls();
  };

  const handleADSignin = () => {
    if (isLoginProcessing.current) {
      return;
    }

    isLoginProcessing.current = true;
    setHelper({ text: "", adText: "" });
    Lockr.flush();

    socketAuth
      .getCompanyKey()
      .then((response) => {
        if (!response) {
          setHelper((prev) => ({
            ...prev,
            adText: intl.formatMessage({ id: "ErrorMessage.SignInAzureAD" }),
          }));
          isLoginProcessing.current = false;

          return;
        }

        Lockr.set(StorageKeys.CompanyKey, response);
        ssoAuth.Login();
      })
      .catch((err) => {
        setHelper((prev) => ({
          ...prev,
          adText: JSON.stringify(err.toJSON()),
        }));
        isLoginProcessing.current = false;
      });
  };

  const handleSignIn = () => {
    if (isLoginProcessing.current) {
      return;
    }

    isLoginProcessing.current = true;
    setHelper({ text: "", adText: "" });

    socketAuth
      .getCompanyKeyByUserName(userData.name)
      .then((response) => {
        if (!response) {
          setHelper((prev) => ({
            ...prev,
            text: intl.formatMessage({ id: "ErrorMessage.SignIn" }),
          }));
          isLoginProcessing.current = false;

          return;
        }

        Lockr.set(StorageKeys.CompanyKey, response);
        signInFederated();
      })
      .catch((err) => {
        setHelper((prev) => ({ ...prev, text: err }));
        isLoginProcessing.current = false;
      });
  };

  const signInFederated = () => {
    socketAuth
      .signIn(userData.name, userData.password)
      .then((response) => {
        if (
          response.Result === ResponseResult.VALIDATIONERROR &&
          !!response.ValidationFailures
        ) {
          const message = response.ValidationFailures.map(
            (x) => x.Message
          ).join(" ");
          setHelper((prev) => ({ ...prev, text: message }));
          socketAuth.userSignedOut.next();
        }

        if (response.Data) {
          Lockr.set(StorageKeys.AzureADLoginType, AzureLoginType.None);
          socketAuth.afterSignIn(response.Data);
          socketAuth.userSignedIn.next(true);
        }
      })
      .catch((err) => {
        setHelper((prev) => ({ ...prev, text: err }));
        socketAuth.userSignedOut.next();
      });
  };

  const handleKeyPress = (e: any) => {
    if ((e.keyCode === 13 || e.which === 13) && !isButtonDisabled) {
      handleSignIn();
    }
  };

  const setDeploymentUrls = () => {
    // retreive deployments and set cookie
    const tid = localStorage.getItem(StorageKeys.TIDForAzure);
    if (tid) {
      socketAuth
        .getClientDeploymentsByAzureTenantId(tid)
        .then((deploymentsList: any) => {
          if (deploymentsList.length > 0) {
            processDeployments(deploymentsList);
          }
        });
    } else {
      setTimeout(() => {
        setDeploymentUrls();
      }, 500);
    }
  };

  const processDeployments = (tenantDeployments: any) => {
    let cachedDeploymentKey = localStorage.getItem(
      StorageKeys.DeploymentTenantKey
    );

    if (!tenantDeployments || tenantDeployments.length === 0) {
      // clear deployment info if cached
      if (cachedDeploymentKey) {
        clearCachedDeployment();
      }

      return;
    }

    // cache first deployment data if missing
    if (!cachedDeploymentKey) {
      cacheDeployment(tenantDeployments[0]);

      return;
    }

    // do not show deployments select if only one deployment found
    if (tenantDeployments.length === 1) {
      if (
        cachedDeploymentKey !==
        `${tenantDeployments[0].deployment.id}:${tenantDeployments[0].tenantId}:${tenantDeployments[0].azureTenantId}`
      ) {
        cacheDeployment(tenantDeployments[0]);
      }

      return;
    }

    // populate deployments select
    const mappedDeployments = [...tenantDeployments].map(
      (x: TenantDeploymentDTO) =>
        ({
          id: `${x.deployment.id}:${x.tenantId}:${x.azureTenantId}`,
          title: `${x.deployment.name}: ${x.tenantName}`,
          header: `${x.deployment.name}: ${x.tenantName}`,
          item: x,
        } as DeploymentDropdownItem)
    );

    const deployment = mappedDeployments.find(
      (x) => x.id === cachedDeploymentKey
    );

    // deployment does not exists anymore, clear cache
    if (!deployment) {
      clearCachedDeployment();

      return;
    }

    // populate deployments select
    setSelectedDeployment(deployment);
    setDeployments(mappedDeployments);
  };

  const selectDeploymentFromDropdown = (options: any) => {
    if (options) {
      cacheDeployment(options.value?.item);
    }
  };

  const cacheDeployment = (tenantDeployment: any) => {
    setisLogInHidden(true);

    localStorage.setItem(
      StorageKeys.DeploymentTenantKey,
      `${tenantDeployment.deployment.id}:${tenantDeployment.tenantId}:${tenantDeployment.azureTenantId}`
    );

    localStorage.setItem(
      StorageKeys.DeploymentTenantId,
      tenantDeployment.tenantId
    );

    document.cookie = `env=${tenantDeployment.deployment.id}; max-age=60480000; SameSite=None; Secure; Partitioned;`;

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const clearCachedDeployment = () => {
    document.cookie =
      "env=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None; Secure; Partitioned;";
    localStorage.removeItem(StorageKeys.DeploymentTenantKey);
    localStorage.removeItem(StorageKeys.DeploymentTenantId);

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  return (
    <Flex
      styles={({ theme: { siteVariables } }) => ({
        color: siteVariables.colorScheme.default.foreground1,
        backgroundColor: siteVariables.colorScheme.default.background1,
        height: "100vh",
        alignItems: "center",
        alignContent: "center",
        paddingBottom: "200px",
      })}
      column
    >
      <img
        src={process.env.PUBLIC_URL + "/logo192.png"}
        alt="cc4teams logo"
        width="192"
      />

      <Segment
        hidden={!isLoggedInTeams && !EnvHelper.isStage3()}
        styles={({ theme: { siteVariables } }) => ({
          color: siteVariables.colorScheme.default.foreground,
          backgroundColor: siteVariables.colorScheme.default.background,
          width: "400px",
          padding: "30px",
          paddingBottom: "20px",
          marginBottom: "20px",
        })}
      >
        <Flex column gap="gap.medium">
          {deployments.length > 1 ? (
            <Dropdown
              className="endpoint-dropdown"
              fluid
              checkable
              items={deployments}
              defaultValue={selectedDeployment}
              onChange={(_, selectedOption) => {
                selectDeploymentFromDropdown(selectedOption);
              }}
            />
          ) : (
            <></>
          )}
          <Button
            fluid
            content="Azure AD - Sign in"
            primary
            styles={{
              lineHeight: "30px",
              fontSize: "16px",
              height: "40px",
              marginBottom: "10px",
            }}
            hidden={isLogInHidden}
            onClick={() => handleADSignin()}
          />
          <Text error content={helper.adText} />
        </Flex>
      </Segment>

      <Text
        hidden={!isLoggedInTeams || EnvHelper.isStage3()}
        important
        content="OR"
      />

      <Segment
        hidden={EnvHelper.isStage3()}
        styles={({ theme: { siteVariables } }) => ({
          color: siteVariables.colorScheme.default.foreground,
          backgroundColor: siteVariables.colorScheme.default.background,
          width: "400px",
          padding: "30px",
          paddingBottom: "20px",
          margin: "20px",
        })}
      >
        <Flex column gap="gap.medium">
          <Input
            fluid
            placeholder="Username"
            type="email"
            styles={{
              lineHeight: "30px",
              fontSize: "16px",
            }}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                name: (e.target as HTMLInputElement).value,
              }))
            }
            onKeyPress={(e) => handleKeyPress(e)}
            required
          />

          <Input
            fluid
            placeholder={intl.formatMessage({ id: "AuthView.Password" })}
            type="password"
            styles={{
              lineHeight: "30px",
              fontSize: "16px",
            }}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                password: (e.target as HTMLInputElement).value,
              }))
            }
            onKeyPress={(e) => handleKeyPress(e)}
            required
          />

          <Button
            fluid
            content="CC4Teams - Sign in"
            primary
            styles={{
              lineHeight: "30px",
              fontSize: "16px",
              height: "40px",
              marginBottom: "0px",
            }}
            onClick={() => handleSignIn()}
            disabled={isButtonDisabled}
          />

          <Text error content={helper.text} />
        </Flex>
      </Segment>

      <a
        target="_blank"
        href="https://contactcenter4all.com/home/contact/"
        rel="noopener noreferrer"
      >
        Not on CC4Teams yet? In order to use this app, please contact us.
      </a>
    </Flex>
  );
};

export default AuthView;

import React, { useEffect, useState } from 'react';
import { Flex } from '@fluentui/react-northstar';
import { ExtendedContacts } from './Contacts/ExtendedContacts';
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import { TabViewIds } from 'utils';
import { supervisorManager } from 'services/supervisor/supervisor-manager';
import { queuesService, socketQueue } from 'services/queue';
import { socketAudio } from 'services/calling';
import { CallSessionDTO } from 'utils/domain/callSessionDTO';
import { SupervisorCallsViewStage3 } from '../AgentView/SupervisorCallsView/SupervisorCallsViewStage3';
import WaitingCallsStage3 from '../AgentView/WaitingCalls/WaitingCallsStage3';
import { useSubjectListenerEffect } from 'utils/helpers/listenerHook';

export const AgentView = () => {
  const [waitingKey, setWaitingKey] = useState(Math.random() + "waitingContentKey");
  const [agentKey, setAgentKey] = useState(Math.random() + "agentContentKey");
  const [isSupervisorCheckDone, setIsSupervisorCheckDone] = useState(false);
  const [isOperatorOrSupervisor, setIsOperatorOrSupervisor] = useState(false);
  const [isWarmTransferInProgress, setWarmTransferInProgress] = useState(false);

  useEffect(() => {
    initialize();
  }, []);

  useSubjectListenerEffect((value: number | string) => {
    if (value === TabViewIds.Agent) {
      setWaitingKey(Math.random() + "waitingContentKey");
      setAgentKey(Math.random() + "agentContentKey");
    }
  }, mainViewHelper.tabViewChange);

  useSubjectListenerEffect((callSession: CallSessionDTO) => {
    setWarmTransferInProgress(callSession.IsWarmTransferInProgress);
  }, socketAudio.warmTransferInProgress);

  useSubjectListenerEffect((obj: boolean) => {
    setIsSupervisorCheckDone(true);
    checkIsOperatorOrSupervisor(obj);
  }, supervisorManager.onIsUserSupervisorCheckDone);

  useSubjectListenerEffect((obj: any) => {
    checkIsOperatorOrSupervisor();
  }, socketQueue.subjectQueueChange);

  const initialize = () => {
    checkIsOperatorOrSupervisor(supervisorManager.isSupervisor);
    setIsSupervisorCheckDone(supervisorManager.isSupervisorCheckDone);
  };

  const checkIsOperatorOrSupervisor = (isSupervisor = false) => {
    const hasSupervisorOrOperatorRights = isSupervisor || queuesService.hasSupervisorOrOperatorRights();
    setIsOperatorOrSupervisor(hasSupervisorOrOperatorRights);
  }

  return (
    <Flex.Item>
        <Flex key={agentKey} column>
        {
            isSupervisorCheckDone && <>
            {
                isOperatorOrSupervisor
                    ? <SupervisorCallsViewStage3 isWarmTransferInProgress={isWarmTransferInProgress}/>
                    : <WaitingCallsStage3 key={waitingKey} />
            }
            </>
        }
        <ExtendedContacts />

      </Flex>
    </Flex.Item>)
};

import { serviceCall } from 'services/io/service-call';
import { InvokeServiceArgs } from 'services/io/interfaces';
import { ApiContacts } from '.';
import { logging } from 'utils/logging';
import { ResponseWrapper, ContactOperations, Controllers, CallOperation, ListenerOperations } from 'utils';
import { Contact } from 'utils/domain/contact';
import { Presence, PresenceList } from 'utils/domain/presence';
import { ConfigurationHelper, EnvHelper } from 'utils/helpers';
import { FavoriteContactDTO } from 'utils/domain/favoriteContactDTO';
import { Subject, Subscription } from "rxjs";

import { Listener, listeners } from 'services/io/listeners';
import { AzureActiveDirectorySettingsDTO } from 'utils/domain/azureActiveDirectorySettingsDTO';
import { UserChangedModel } from 'utils/domain/userChangedModel';
import { socketOutbound } from 'services/outbound';
import { BackendNotification } from 'utils/enums-s3';

export class ContactService implements ApiContacts {
    private readonly maxNumberOfFavoriteContacts: number = 20;

    private readonly logger = logging.getLogger('SocketWrapUp');
    private azureActiveDirectorySettingsForContacts: AzureActiveDirectorySettingsDTO | null | undefined = null;

    private readonly subscriptionUpdatedPresenceState: Subscription | null = null;//#warning-js [cc4all-2424]: maybe move this to socket-presence.ts
    private readonly subscriptionCompanyChanged: Subscription | null = null;
    private readonly subscriptionFavoriteContactsPresenceChanged: Subscription | null = null;
    private readonly subscriptionPresenceAndHasClientChanged: Subscription | null = null;

    public readonly listenerContactsChanged: Listener<UserChangedModel> = listeners.createListener<UserChangedModel>(ListenerOperations.ContactsChanged);
    public readonly listenerUpdatedPresenceState: Listener<Presence> = listeners.createListener<Presence>(ListenerOperations.UpdatedPresenceState);
    public readonly listenerFavoriteContactsPresenceChanged: Listener<boolean> = listeners.createListener<boolean>(ListenerOperations.FavoriteContactsPresenceChanged);
    public readonly listenerPresenceAndHasClientChanged: Listener<Presence> = listeners.createListener<any>(BackendNotification.InternalPresenceChanged);

    notifyUpdatedPresenceState: Subject<Presence> = new Subject<Presence>();
    hasAnyFavoriteContactChanged: Subject<boolean> = new Subject<boolean>();
    azureADSettingsForContactsChanged: Subject<any> = new Subject<any>();
    favoriteContactsPresenceChanged: Subject<boolean> = new Subject<boolean>();
    maxNumberOfFavoriteContactReached: Subject<number> = new Subject<number>();
    presenceAndHasClientNotification: Subject<number> = new Subject<number>();

    constructor() {
        this.subscriptionUpdatedPresenceState?.unsubscribe();
        this.subscriptionUpdatedPresenceState = this.listenerUpdatedPresenceState.received.subscribe((userPresence: Presence) => {
            this.notifyUpdatedPresenceState.next(userPresence);
        });

        this.subscriptionCompanyChanged?.unsubscribe();
        this.subscriptionCompanyChanged = socketOutbound.listenerCompanyChanged.received.subscribe((obj: any) => {
            this.checkAzureActiveDirectorySettingsForContacts();
        });

        this.subscriptionFavoriteContactsPresenceChanged?.unsubscribe();
        this.subscriptionFavoriteContactsPresenceChanged = this.listenerFavoriteContactsPresenceChanged.received.subscribe((obj: any) => {
            this.favoriteContactsPresenceChanged.next(obj);
        });

        this.subscriptionPresenceAndHasClientChanged?.unsubscribe();
        this.subscriptionPresenceAndHasClientChanged = this.listenerPresenceAndHasClientChanged.received.subscribe((obj: any) => {
            this.presenceAndHasClientNotification.next(obj);
        });
    }

    getContacts(data: any): Promise<ResponseWrapper> {
        const method = EnvHelper.isStage3() ?
            ContactOperations.GetContacts :
            ContactOperations.FilterContactsViaLucene;

        return this.invokeServiceCall(data, method);
    }

    getUsersPresence(data: any): Promise<PresenceList> {
        return this.invokeServiceCall(data, ContactOperations.GetUsersPresence);
    }

    getFavoriteContactsPresence(data: any): Promise<PresenceList> {
        return this.invokeServiceCall(data, ContactOperations.GetFavoriteContactPresence);
    }

    getPresenceForSip(data: any): Promise<ResponseWrapper> {
        return this.invokeServiceCall(data, ContactOperations.GetCurrentPresenceForSip);
    }

    saveContact(contact: Contact): Promise<ResponseWrapper> {
        return this.invokeServiceCall(contact, ContactOperations.SaveContact);
    }

    updateContact(contact: Contact): Promise<ResponseWrapper> {
        return this.invokeServiceCall(contact, ContactOperations.UpdateContact);
    }

    deleteContact(id: number): Promise<ResponseWrapper> {
        return this.invokeServiceCall(id, ContactOperations.DeleteContact);
    }

    callContacts(data: any): Promise<ResponseWrapper> {
        return this.invokeServiceCall(data, "", CallOperation.MakeCall, ConfigurationHelper.botApiUrl);
    }

    callTestContacts(data: any): Promise<ResponseWrapper> {
        return this.invokeServiceCall(data, CallOperation.Raise, Controllers.Calls, ConfigurationHelper.botApiUrl);
    }

    addFavoriteContact(contact: FavoriteContactDTO): Promise<number> {
        return this.invokeServiceCall(contact, ContactOperations.SaveFavoriteContact);
    }

    removeFavoriteContact(contact: FavoriteContactDTO): Promise<boolean> {
        return this.invokeServiceCall(contact, ContactOperations.RemoveFavoriteContact);
    }

    getFavoriteContactsByAgentId(agentRef: number): Promise<FavoriteContactDTO[]> {
        return this.invokeServiceCall(agentRef, ContactOperations.GetFavoriteContactsByAgentId);
    }

    hasAnyFavoriteContact(agentRef: number): Promise<boolean> {
        return this.invokeServiceCall(agentRef, ContactOperations.HasAnyFavoriteContact);
    }

    getCachedAzureActiveDirectorySettings(): Promise<AzureActiveDirectorySettingsDTO> {
        if (this.azureActiveDirectorySettingsForContacts) {
            return Promise.resolve(this.azureActiveDirectorySettingsForContacts);
        } else {
            return this.getAzureActiveDirectorySettingsForContacts();
        }
    }

    getAzureActiveDirectorySettingsForContacts(): Promise<AzureActiveDirectorySettingsDTO> {
        const method = EnvHelper.isStage3() ?
            ContactOperations.GetAzureActiveDirectorySettingsForContactsStage3 :
            ContactOperations.GetAzureActiveDirectorySettingsForContacts;

        return this.invokeServiceCall("", method).then((response: AzureActiveDirectorySettingsDTO) => {
            this.azureActiveDirectorySettingsForContacts = response;
            return response;
        })
    }

    cacheAzureActiveDirectorySettings(settings?: AzureActiveDirectorySettingsDTO) {
        this.azureActiveDirectorySettingsForContacts = settings;
    }

    checkAzureActiveDirectorySettingsForContacts(): void {
        this.getAzureActiveDirectorySettingsForContacts().then((response: AzureActiveDirectorySettingsDTO) => {
            if (JSON.stringify(this.azureActiveDirectorySettingsForContacts) !== JSON.stringify(response)) {
                this.azureADSettingsForContactsChanged.next(response);
            }
        }).catch(err => {
            console.log(err.message);
        });
    }

    getMaxNumberOfFavoriteContacts(): number {
        return this.maxNumberOfFavoriteContacts;
    }

    invokeServiceCall(data: any, operation: string, controller: string = Controllers.User, fetchApiUrl: string = ConfigurationHelper.gatewayApiUrl): Promise<any> {
        return new Promise((resolve, reject) => {
            const args: InvokeServiceArgs = {
                operation: operation,
                controller: controller,
                fetchApiUrl: fetchApiUrl,
                requestData: data,
                responseHandler: {
                    success: (result) => {
                        resolve(result);
                    },
                    error: (err) => {
                        logging.errorHandler.next("ErrorMessage.Offline");
                        this.logger.error(err);
                        reject(err);
                    }
                }
            };

            return serviceCall.invokeService(args);
        });
    }
}

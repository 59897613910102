import Lockr from 'lockr';
import { Subject } from 'rxjs';
import { serviceCall } from 'services/io/service-call';
import { InvokeServiceArgs } from 'services/io/interfaces';
import { ADSigninParams, ApiAuth } from '.';
import { logging } from 'utils/logging';
import { ResponseWrapper, ServiceOperations, StorageKeys, Controllers, UserResetStatus, HttpMethod } from 'utils';
import { ConfigurationHelper, EnvHelper } from 'utils/helpers';
import { Client } from "@microsoft/microsoft-graph-client";

export class SocketAuth implements ApiAuth {
    private readonly logger = logging.getLogger('SocketAuth');

    userSignedIn: Subject<boolean> = new Subject<boolean>();
    userSignedOut: Subject<boolean> = new Subject<boolean>();
    userManuallySignedOut: Subject<string> = new Subject<string>();
    userADSignedIn: Subject<ADSigninParams> = new Subject<ADSigninParams>();
    userADSignedInFailed: Subject<string> = new Subject<string>();
    userSSOFailed: Subject<string> = new Subject<string>();
    userVerified: Subject<boolean> = new Subject<boolean>();
    userSignedInOtherWindow: Subject<string> = new Subject<string>();
    graphClient: Client | null = null;
    graphClientDelegated: Client | null = null;

    signIn(username: string, password: string): Promise<ResponseWrapper> {
        return this.invokeServiceCall({ Username: username, Password: password, Target: Lockr.get(StorageKeys.CompanyKey) }, ServiceOperations.SignIn);
    }

    signInAzureAD(token: string): Promise<ResponseWrapper> {
        return this.invokeServiceCall({ Token: token, Target: Lockr.get(StorageKeys.CompanyKey) }, ServiceOperations.SignInAzureAD);
    }

    signInToken(token: string, hasUserSignedIn: boolean = false): Promise<ResponseWrapper> {
        return this.invokeServiceCall({ Token: token, Target: Lockr.get(StorageKeys.CompanyKey), hasUserSignedIn: hasUserSignedIn }, ServiceOperations.SignInToken);
    }

    signOut(token: string): Promise<ResponseWrapper> {
        return this.invokeServiceCall({ Token: token, Target: Lockr.get(StorageKeys.CompanyKey) }, ServiceOperations.SignOut);
    }

    signOutStage2(sip: string): Promise<ResponseWrapper> {
        return this.invokeServiceCall({ Username: sip, Target: Lockr.get(StorageKeys.CompanyKey) }, ServiceOperations.SignOut);
    }

    IsUserLoggedIn(): boolean {
        return !!(Lockr.get<string>(StorageKeys.TokenId));
    }

    getCompanyKey(): Promise<string> {
        if (EnvHelper.isStage3()) {
            const cachedTenantId = localStorage.getItem(StorageKeys.DeploymentTenantId);
            if (cachedTenantId) {
                return Promise.resolve(cachedTenantId);
            }

            return this.getCompanyKeyByTenantId(localStorage.getItem(StorageKeys.TIDForAzure) || '');
        }

        return this.getCompanyKeyBySip(localStorage.getItem(StorageKeys.UPNForAzure) || '');
    }

    getCompanyKeyBySip(sip: string): Promise<string> {
        return this.invokeServiceCall({ Sip: "sip:" + sip }, ServiceOperations.GetCompanyKeyBySip) as Promise<string>;
    }

    getCompanyKeyByTenantId(tid: string): Promise<string> {
        return this.invokeServiceCall({ Data: tid }, ServiceOperations.GetCompanyKeyByTenantId) as Promise<string>;
    }

    getClientDeploymentsByAzureTenantId(tid: string): Promise<string> {
        return this.invokeServiceCall(undefined, `${ServiceOperations.GetClientDeploymentsByAzureTenantId}/${tid}`, Controllers.Deployments, undefined, undefined, HttpMethod.Get) as Promise<string>;
    }

    getCompanyKeyByUserName(username: string): Promise<ResponseWrapper> {
        return this.invokeServiceCall({ Username: username }, ServiceOperations.GetCompanyKeyByUserName) as Promise<ResponseWrapper>;
    }

    initGraphApiClient(accessToken: string): Client {
        return Client.init({
            authProvider: (done: any) => {
                done(this.errorHandler, accessToken);
            }
        });
    }

    getGraphApiClient(isDelegated: boolean): Client | null {
        if (isDelegated && this.graphClientDelegated) {
            return this.graphClientDelegated;
        }

        if (!isDelegated && this.graphClient) {
            return this.graphClient;
        }

        const accessToken = isDelegated ? Lockr.get<string>(StorageKeys.TokenADDelegated) : Lockr.get<string>(StorageKeys.TokenAD);
        if (isDelegated) {
            this.graphClientDelegated = this.initGraphApiClient(accessToken);
        } else {
            this.graphClient = this.initGraphApiClient(accessToken);
        }

        return isDelegated ? this.graphClientDelegated : this.graphClient;
    }

    refreshGraphClient(accessToken: string, isDelegated: boolean): Client | null {
        if (isDelegated) {
            this.graphClientDelegated = Client.init({
                authProvider: (done: any) => {
                    done(this.errorHandler, accessToken);
                }
            });
        } else {
            this.graphClient = Client.init({
                authProvider: (done: any) => {
                    done(this.errorHandler, accessToken);
                }
            });
        }

        return isDelegated ? this.graphClientDelegated : this.graphClient;
    }

    errorHandler(err: any) {
        logging.errorHandler.next("ErrorMessage.refreshGraphClient");
        this.logger.error(err);
    }

    setHasCC4TeamsFalse(userId: string, tenantId: string): Promise<ResponseWrapper> | null | undefined {
        if (EnvHelper.isStage3()) {
            return this.invokeServiceCall({ userId: userId, tenantId: tenantId }, ServiceOperations.SetClientOffline) as Promise<ResponseWrapper>;
        }
    }

    setHasCC4TeamsTrue(userId: string): Promise<ResponseWrapper> | null | undefined {
        if (EnvHelper.isStage3()) {
            return this.invokeServiceCall(userId, ServiceOperations.SetClientOn) as Promise<ResponseWrapper>;
        }
    }

    afterSignIn(data: any) {
        localStorage.setItem(StorageKeys.UPN, data.Username || '');

        Lockr.set(StorageKeys.TokenId, data.JWT || '');
        Lockr.set(StorageKeys.SIP, data.SIP.toLowerCase() || '');
        Lockr.set(StorageKeys.CompanyId, data.CompanyId || '');
        Lockr.set(StorageKeys.CompanyKey, data.CompanyKey || '');
        Lockr.set(StorageKeys.UserId, data.UserId || '');
        Lockr.set(StorageKeys.FullName, data.FullName || '');
        Lockr.set(StorageKeys.SessionExpiryTimestamp, data.SessionExpiryTimestamp || 0);

        if (data.UserwayKey) {
            Lockr.set(StorageKeys.UserwayKey, data.UserwayKey);
        }
    }

    resetUser(): Promise<UserResetStatus> {
        return this.invokeServiceCall(Lockr.get(StorageKeys.UserId), ServiceOperations.ResetUser);
    }

    invokeServiceCall(data: any, operation: string, controller: string = Controllers.User, fetchApiUrl: string = ConfigurationHelper.gatewayApiUrl,
        sender: string = Lockr.get(StorageKeys.SIP), httpMethod: string = HttpMethod.Post): Promise<any> {
        return new Promise((resolve, reject) => {
            const args: InvokeServiceArgs = {
                sender: sender,
                operation: operation,
                controller: controller,
                fetchApiUrl: fetchApiUrl,
                requestData: data,
                httpMethod: httpMethod,
                responseHandler: {
                    success: (result) => {
                        resolve(result);
                    },
                    error: (err) => {
                        logging.errorHandler.next("ErrorMessage.Offline");
                        this.logger.error(err);
                        reject(err);
                    }
                }
            };
            serviceCall.invokeService(args);
        });
    }
}
import { Button, Flex, Input, SendIcon } from '@fluentui/react-northstar';
import React, { useEffect, useState } from 'react';
import { customerJourneyManager } from 'services/io/customer-journey';
import { StorageKeys } from 'utils';
import Lockr from 'lockr';
import { useIntl } from "react-intl";
import { CustomerJourneyNoteDTO } from 'utils/domain/customerJourneyModels';
import { GeneralHelper } from 'utils/helpers';

export const CustomerJourneyNotesInputView = ({ SessionId }: any) => {
    const intl = useIntl();

    const [noteMessage, setNoteMessage] = useState('');
    const [emptyNoteMessage, setEmptyNoteMessage] = useState(false);

    React.useEffect(() => {
        return () => componentWillUnmount();
    }, []);

    const componentWillUnmount = () => {
        GeneralHelper.logCox(`in CustomerJourneyNotesInputView.tsx, in componentWillUnmount`);
    }

    useEffect(() => {
        setEmptyNoteMessage(false);
    }, [noteMessage]);

    const createRequestData = () => {
        const note: CustomerJourneyNoteDTO = {
            Message: noteMessage,
            Date: new Date(),
            SessionId: SessionId,
            // Agent: {}
            Agent: {FullName: Lockr.get<string>(StorageKeys.FullName), AgentRef: Lockr.get<string>(StorageKeys.UserId), ID: Lockr.get<string>(StorageKeys.UserId)}
        }

        return note;
    }

    const sendNoteMessage = () => {
        if (noteMessage && noteMessage.trim()) {
            console.log("Preparing to send note message");
            setEmptyNoteMessage(false);
            const requestData = createRequestData();
            console.log(requestData);
            customerJourneyManager.sendConversationNote(requestData).then((response: any) => {
                customerJourneyManager.onNoteAdded.next(requestData);
                setNoteMessage('');
            }).catch(err => {
                console.log(err);
            })
        } else {
            setEmptyNoteMessage(true);
        }
    }

    const handleKeyPress = (e: any) => {
        if (e.keyCode === 13 || e.which === 13) {
            sendNoteMessage();
        }
    };

    return (
        <Flex gap="gap.small">
            <Input
                fluid
                onChange={(e) => setNoteMessage((e.target as HTMLInputElement).value)}
                onKeyPress={(e) => handleKeyPress(e)}
                value={noteMessage}
                placeholder={intl.formatMessage({ id: "CustomerJourney.NotesView.NotesInput"})}
                maxLength={1000}
            />
            <Flex
                gap="gap.medium"
                space="between"
            >
                <span>&nbsp;</span>
                <Button
                    icon={<SendIcon outline size="large" />}
                    iconOnly
                    text
                    onClick={sendNoteMessage}
                />
            </Flex>
        </Flex>
    );
};

export default CustomerJourneyNotesInputView;
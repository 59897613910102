import { Listener, listeners } from 'services/io/listeners';
import { Subject, Subscription } from 'rxjs';
import { AudioOperations, ListenerOperations, MonitorTypes, QueueMediaOperations, ServiceOperations } from 'utils';
import { logging } from 'utils/logging';
import { GeneralHelper } from 'utils/helpers';

export interface CallPickupObj {
    agentRef: number,
    sessionId: string
}

export interface CallPickupObjStage3 {
    scenarioId: string,
    agentUserId: string
}

export interface SuperviseCallObj {
    scenarioId: string,
    supervisingAgentId: string
    monitoringType: MonitorTypes
}

export class WaitingListManager {
    private readonly logger = logging.getLogger('WaitingListManager');

    private readonly subscriptionWaitingChange: Subscription | null = null;
    public readonly listenerWaitingMediaChange: Listener<any> = listeners.createListener<any>(ListenerOperations.WaitingMediaChange);

    onWaitingAdded: Subject<any> = new Subject<any>();
    onWaitingUpdated: Subject<any> = new Subject<any>();
    onWaitingRemoved: Subject<any> = new Subject<any>();

    constructor() {
        this.subscriptionWaitingChange?.unsubscribe();
        this.subscriptionWaitingChange = this.listenerWaitingMediaChange.received.subscribe((obj: any) => {
            switch (obj.changeType || obj.ChangeType) {
                case QueueMediaOperations.Added:
                    this.onWaitingAdded.next(obj.WaitingMedia);
                    break;
                case QueueMediaOperations.Updated:
                    this.onWaitingUpdated.next(obj.WaitingMedia);
                    break;
                case QueueMediaOperations.Removed:
                    this.onWaitingRemoved.next(obj.WaitingMedia);
                    break;
            }
        });
    }

    getWaitingMediaByAgent(agentIdNumber: number): Promise<any> {
        return GeneralHelper.invokeServiceCall(agentIdNumber, ServiceOperations.GetWaitingMediaByAgent, this.logger);
    }

    PickUpCall(callpickupInfo: CallPickupObj): Promise<any> {
        return GeneralHelper.invokeServiceCall(callpickupInfo, ServiceOperations.CallPickup, this.logger);
    }

    pickUpCallStage3(callpickupInfo: CallPickupObjStage3): Promise<any> {        
        return GeneralHelper.invokeServiceCall(callpickupInfo, ServiceOperations.CallPickup, this.logger);
    }

    superviseCall(superviseCallInfo: SuperviseCallObj): Promise<any> {        
        return GeneralHelper.invokeServiceCall(superviseCallInfo, AudioOperations.StartSupervisorMonitoring, this.logger);
    }
}

export const waitingListManager = new WaitingListManager();
import { serviceRequests } from 'services/io';
import { socketIoStressTest } from 'services/io/socketio-stress-test';
import { Controllers, logging } from 'utils';
import { GeneralHelper } from 'utils/helpers';

export class PresenceStressTest {
    private readonly logger = logging.getLogger('PresenceStressTest');

    testSpecialNote(presenceText: string) {
        if (presenceText == "stress test 1") {
            this.test1();
        } else if (presenceText == "stress test 2") {
            this.test2();
        } else if (presenceText == "stress test serverNotification") {
            this.stressTestServerNotification();
        } else if (presenceText == "clear log suffix") {
            socketIoStressTest.logSuffix = ``;
        } else if (presenceText == "long stress test") {
            this.longStressTest();
        }
    }

    longStressTest() {
        const nTests = 10;
        this.test2();
        let nPerformedTests = 1;
        const intervalHandler = window.setInterval(() => {
            ++nPerformedTests;
            this.test2();
            if (nPerformedTests == nTests) {
                window.clearInterval(intervalHandler);
            }
        }, 60 * 1000);
    }

    stressTestServerNotification() {
        const nSecondsForTest = 30;
        const nNotificationsPerSecond = 10;
        const nCount = nSecondsForTest * nNotificationsPerSecond;
        const nSleepMs = 1000 / nNotificationsPerSecond;
        this.invokeFloodServerNotification({ sleepMs: nSleepMs, nCount: nCount });
    }

    invokeFloodServerNotification(obj: any): Promise<object> {
        return GeneralHelper.invokeServiceCall(obj, "FloodServerNotification", this.logger);
    }

    test1() {
        this.stressTest_Stef(2, 60 * 5);
    }

    test2() {
        var nChangesBackToOnlinePerSecond = 12;
        var nSecondsForTest = 30;
        serviceRequests.ChangePresence(`sip:cata3@cc4all.onmicrosoft.com`, "Busy", "enable benchmark", "")
        window.setTimeout(() => {
            serviceRequests.ChangePresence(`sip:cata3@cc4all.onmicrosoft.com`, "Busy", "clear benchmark", "")
            window.setTimeout(() => {
                this.stressTest_BusyAndOnline(nChangesBackToOnlinePerSecond, nSecondsForTest);
                window.setTimeout(() => {
                    serviceRequests.ChangePresence(`sip:cata3@cc4all.onmicrosoft.com`, "Busy", "log benchmark", "")
                }, (nSecondsForTest + 1) * 1000);
            }, 1 * 1000);
        }, 1 * 1000);
    }

    stressTest_BusyAndOnline(nChangesBackToOnlinePerSecond: number, nSecondsForTest: number) {
        var artificialSip = `sip:cata3@cc4all.onmicrosoft.com`;
        var nIntervalBetweenAFullChange = Math.round(1000 / nChangesBackToOnlinePerSecond);
        var nIntervalBetweenASwap = nIntervalBetweenAFullChange / 2;
        var lastLoggedMs = 0;
        var nCurrentMs: number = 0;
        console.log(`in stress test, nSecondsForTest is ${nSecondsForTest}, nChangesBackToOnlinePerSecond is ${nChangesBackToOnlinePerSecond}`);
        socketIoStressTest.logSuffix = `stress test started`;
        const opName = "ChangePresence";
        var agentInterval = window.setInterval(() => {
            nCurrentMs = nCurrentMs + nIntervalBetweenAFullChange;
            if ((nCurrentMs - lastLoggedMs) >= 1000) {
                lastLoggedMs = nCurrentMs;
                socketIoStressTest.logSuffix = `in stress test for ${Math.round(lastLoggedMs / 1000)}s`;
                console.log(`in stress test, lastLoggedMs is ${lastLoggedMs}`);
            }
            if (nCurrentMs >= (nSecondsForTest * 1000)) {
                window.clearInterval(agentInterval);
                socketIoStressTest.logSuffix = ``;
                console.log(`in stress test, finished, lastLoggedMs is ${lastLoggedMs}`);
            }

            const requestData1 = { Sip: artificialSip, Presence: "Busy", Note: "", Id: "", };
            GeneralHelper.invokeServiceCall(requestData1, opName, this.logger, Controllers.Presence);

            window.setTimeout(() => {
                const requestData2 = { Sip: artificialSip, Presence: "Online", Note: "", Id: "", };
                GeneralHelper.invokeServiceCall(requestData2, opName, this.logger, Controllers.Presence);
            }, nIntervalBetweenASwap);
        }, nIntervalBetweenAFullChange);
    }

    stressTest_Stef(nChangesBackToOnlinePerSecond: number, nSecondsForTest: number) {
        var artificialSip = `sip: cata3@cc4all.onmicrosoft.com`;
        var nIntervalBetweenAFullChange = 1000 / nChangesBackToOnlinePerSecond;
        var nIntervalBetweenASwap = nIntervalBetweenAFullChange / 10;
        var lastLoggedMs = 0;
        var nCurrentMs: number = 0;
        console.log(`in stress test, lastLoggedMs is ${lastLoggedMs} `);
        var agentInterval = window.setInterval(function () {
            nCurrentMs = nCurrentMs + nIntervalBetweenAFullChange;
            if ((nCurrentMs - lastLoggedMs) >= 1000) {
                lastLoggedMs = nCurrentMs;
                console.log(`in stress test, lastLoggedMs is ${lastLoggedMs} `);
            }
            if (nCurrentMs >= (nSecondsForTest * 1000)) {
                window.clearInterval(agentInterval);
                console.log(`in stress test, finished, lastLoggedMs is ${lastLoggedMs} `);
                return;
            }
            serviceRequests.ChangePresence(artificialSip, "Busy", "", "");
            window.setTimeout(function () {
                serviceRequests.ChangePresence(artificialSip, "Online", "", "");
                window.setTimeout(function () {
                    serviceRequests.ChangePresence(artificialSip, "DoNotDisturb", "", "");
                    window.setTimeout(function () {
                        serviceRequests.ChangePresence(artificialSip, "Online", "", "");
                        window.setTimeout(function () {
                            serviceRequests.ChangePresence(artificialSip, "BeRightBack", "", "");
                            window.setTimeout(function () {
                                serviceRequests.ChangePresence(artificialSip, "Online", "", "");
                                window.setTimeout(function () {
                                    serviceRequests.ChangePresence(artificialSip, "Away", "", "");
                                    window.setTimeout(function () {
                                        serviceRequests.ChangePresence(artificialSip, "Online", "", "");
                                        window.setTimeout(function () {
                                            serviceRequests.ChangePresence(artificialSip, "Away", "", "");
                                            window.setTimeout(function () {
                                                serviceRequests.ChangePresence(artificialSip, "Online", "", "");
                                            }, nIntervalBetweenASwap);
                                        }, nIntervalBetweenASwap);
                                    }, nIntervalBetweenASwap);
                                }, nIntervalBetweenASwap);
                            }, nIntervalBetweenASwap);
                        }, nIntervalBetweenASwap);
                    }, nIntervalBetweenASwap);
                }, nIntervalBetweenASwap);
            }, nIntervalBetweenASwap);
        }, nIntervalBetweenAFullChange);
    }

}


export const presenceStressTest = new PresenceStressTest();
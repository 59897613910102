import { Button, Flex, Input, SearchIcon, StarIcon, ParticipantAddIcon, Text, Dialog, EditIcon, TrashCanIcon } from '@fluentui/react-northstar';
import InCallActions from 'components/ContactActions/InCallActions';
import NotInCallActions from 'components/ContactActions/NotInCallActions';
import Lockr from 'lockr';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Subscription } from 'rxjs';
import { extendedFavoritesService } from 'services/extendedFavorites';
import { initialClientLoadService } from 'services/initialClientLoad';
import { Contact, ContactType, PaginatedContacts, ExtendedContact } from 'utils/domain/extended/contact';
import { FavoriteConfiguration, FavoriteContactDTO } from 'utils/domain/extended/favoriteContactDTO';
import { TeamsClientInitSettingsDto } from 'utils/domain/extended/teamsClientInitSettingsDto';
import { FavoriteContactSender, FavoriteContactUpdate, FavoriteContactAction, notificationCenter } from 'utils/helpers/notificationCenter';
import AdvancedTable, { stringCellComparator } from '../../../../components/AdvancedTable';
import AgentAvatar from '../../../../components/AgentAvatar';
import { Paginator } from '../../..';
import { ExtendedAddEditContactView } from '../ExtendedAddEditContact';
import { socketAudio } from '../../../../services/calling';
import { socketContacts } from '../../../../services/contacts';
import { socketOutbound } from '../../../../services/outbound';
import { socketTransfer } from '../../../../services/transfers';
import {
  CallableContactColumnsEnum, ConferenceStatusAction, ContactColumns,
  ContactSipAdressColumnsEnum, DefaultPageSize, MonitorTypes, SortableColumnValue, SortDirection, StorageKeys, UserChangeType
} from '../../../../utils';
import { AzureActiveDirectorySettingsDTO, AzureAdAttributeSettingDTO, SipMappingType } from '../../../../utils/domain/azureActiveDirectorySettingsDTO';
import { CallSessionDTO } from '../../../../utils/domain/callSessionDTO';
import { UserChangedModel } from '../../../../utils/domain/userChangedModel';
import { EnvHelper, GeneralHelper } from '../../../../utils/helpers';
import { logging } from '../../../../utils/logging';
import './ExtendedContacts.scss';
import ChatButton from 'components/ContactActions/ChatButton';
import AgendaPopup from 'containers/AgendaView/AgendaPopUp';
import { extendedContactService as contactService } from 'services/extendedContacts/extendedContactService';
import { useSubjectListenerEffect } from 'utils/helpers/listenerHook';
import { supervisorManager } from 'services/supervisor/supervisor-manager';

interface ContactColumn {
  title: string,
  className: string,
  key: string,
  name: string,
  cellComparator: (cell1: string, cell2: string) => number
}

class PageStateData {
  searchText: string = '';
  page: number = 0;
  sortColumn: string = 'Agent';
  sortDirection: boolean = false;
}

export const ExtendedContacts = () => {
  const intl = useIntl();
  const [contactList, setContactList] = useState([] as any);
  const [contacts, setContacts] = useState(new PaginatedContacts());

  const [currentCallSession, setCurrentCallSession] = useState(new CallSessionDTO());

  const [favoriteContacts, setFavoriteContacts] = useState<FavoriteContactDTO[]>([]);
  const [azureADSettings, setContactsAzureADSettings] = useState(new AzureActiveDirectorySettingsDTO());
  const [columns, setColumns] = useState([] as any[]);
  const [agentHasOutboundEnabled, setAgentHasOutboundEnabled] = useState(false);
  const [isWarmTransferInProgress, setIsWarmTransferInProgress] = useState(false);
  const [isMonitoring, setIsMonitoring] = useState(false);
  const [favoriteConfiguration, setFavoriteConfiguration] = useState<FavoriteConfiguration>({ Limit: 0, IsEnabled: false });

  const callableColumns = useMemo<string[]>(() => Object.keys(CallableContactColumnsEnum), []);

  const [pageState, setPageState] = useState<PageStateData>(new PageStateData());
  const totalPages = useMemo<number>(() => (Math.ceil(contacts.Count / 10) || 1), [contacts]);
  const initSettingsRequestCompleted = useRef(false);
  const [isAgendaEnabled, setIsAgendaEnabled] = useState(false);


  const [isAddEditContactDialogOpen, setIsAddEditContactDialogOpen] = useState(false);
  const [contactToUpdate, setContactToUpdate] = useState({});
  const [isAddedContactFavorite, setIsAddedContactFavorite] = useState<boolean>(false);
  const [idOfContactToDelete, setIdOfContactToDelete] = useState('');
  const [nameOfContactToDelete, setNameOfContactToDelete] = useState("");
  const [supervisedQueues, setSupervisedQueues] = useState<string[]>([]);

  let subscriptionCallSessionStateChanged: Subscription | null = null;
  let subscriptionCurrentUserCallSessionStateChanged: Subscription | null = null;
  let subscriptionFavoriteContactsChanged: Subscription | null = null;
  let subscriptionAzureADSettingsForContactsChanged: Subscription | null = null;
  let subscriptionAgentHasOutboundAccessEnabled: Subscription | null = null;
  let subscriptionContactsChanged: Subscription | null = null;
  let subscriptionWarmTransferInProgress: Subscription | null = null;
  let subscriptionCurrentUserMonitoredCallSessionChanged: Subscription | null = null;
  let subscriptionInitialSettingsRequestCompleted: Subscription | null = null;
  let subscriptionOnWarmTransferStarted: Subscription | null = null;
  let subscriptionOnWarmTransferFailed: Subscription | null = null;

  useEffect(() => {
    initialize();

    return () => {
      componentWillUnmount();
    }
  }, []);

  useEffect(() => pullContactsForCurrentPageState(), [pageState]);

  useEffect(() => mapContacts(contacts), [
    currentCallSession.Started,
    currentCallSession.Transferring,
    currentCallSession.IsTeamsUnparkInProgress,
    currentCallSession.ActionHistory,
    currentCallSession.IsWarmTransferInProgress,
    favoriteContacts,
    isWarmTransferInProgress,
    favoriteConfiguration,
    columns,
    contacts,
    supervisedQueues
  ]);

  useEffect(() => buildColumnsHeader(), [
    azureADSettings,
    agentHasOutboundEnabled,
    isWarmTransferInProgress,
    isMonitoring,
  ]);

  useSubjectListenerEffect((obj: number[]) => {
    setSupervisedQueues(obj.map(x => x.toString()));
  }, supervisorManager.onMonitorableQueueIdsChanged);

  const initialize = () => {
    pullInitialData();

    subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
    subscriptionCurrentUserCallSessionStateChanged = socketAudio.currentUserCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      setCurrentCallSession(callSessionDTO);
    });

    subscriptionCallSessionStateChanged?.unsubscribe();
    subscriptionCallSessionStateChanged = socketAudio.callSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      if ((callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransfered] ||
        callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransferred]
      )&&
        callSessionDTO.AgentBeforeTransferRef === Lockr.get<string>(StorageKeys.UserId)) {
        callSessionDTO.Transferring = true;
      }
      setCurrentCallSession(callSessionDTO);
    });

    subscriptionAzureADSettingsForContactsChanged?.unsubscribe();
    subscriptionAzureADSettingsForContactsChanged = socketContacts.azureADSettingsForContactsChanged.subscribe((settings: AzureActiveDirectorySettingsDTO) => {
      setContactsAzureADSettings(settings);
    })

    subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
    subscriptionAgentHasOutboundAccessEnabled = socketOutbound.agentHasOutboundAccessEnabled.subscribe((isOutboundEnabled: boolean) => {
      setAgentHasOutboundEnabled(isOutboundEnabled);
    });

    subscriptionContactsChanged?.unsubscribe();
    subscriptionContactsChanged = socketContacts.listenerContactsChanged.received.subscribe((userChangedModel: UserChangedModel) => {
      handleContactsChanged(userChangedModel);
    });

    subscriptionWarmTransferInProgress?.unsubscribe();
    subscriptionWarmTransferInProgress = socketAudio.warmTransferInProgress.subscribe((callSessionDTO: CallSessionDTO) => {
      const sip = Lockr.get<string>(StorageKeys.UserId);
      const isWarm = !!callSessionDTO.TransferTargetUri && callSessionDTO.TransferTargetUri === sip &&
        (callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.WarmCanceled] &&
          callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent] &&
          callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.WarmAccepted]);

      setIsWarmTransferInProgress(isWarm);
    });

    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged = socketAudio.currentUserMonitoredCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      setIsMonitoring(callSessionDTO.MonitorType !== MonitorTypes.None && !callSessionDTO.IsComputedAsTerminated);
    });

    subscriptionFavoriteContactsChanged?.unsubscribe();
    subscriptionFavoriteContactsChanged = notificationCenter.favoriteContactUpdated.subscribe((data: FavoriteContactUpdate) => {
      if (data.sender === FavoriteContactSender.Contacts) {
        return;
      }

      if (data.action === FavoriteContactAction.Delete) {
        removeContactFromLocalFavorites(data.contact.ContactId);
      } else {
        insertContactToLocalFavorites(data.contact);
      }
    });

    subscriptionInitialSettingsRequestCompleted = initialClientLoadService.notifyInitialSettingsRequestCompleted.subscribe((settings: TeamsClientInitSettingsDto) => {
      pullInitialData();
    });

    subscriptionOnWarmTransferStarted?.unsubscribe();
    subscriptionOnWarmTransferStarted = socketTransfer.onWarmTransferStarted.subscribe((_) => {
      //#warning-js: currentCallSession is not accessible in this subscription, it will be an empty object; we should have an useRef
      setCurrentCallSession({ ...currentCallSession, IsWarmTransferInProgress: true } as CallSessionDTO)
    })

    subscriptionOnWarmTransferFailed?.unsubscribe();
    subscriptionOnWarmTransferFailed = socketTransfer.onWarmTransferFailed.subscribe((_) => {
      setCurrentCallSession({ ...currentCallSession, IsWarmTransferInProgress: false, Started: true } as CallSessionDTO)
    })
  }

  const componentWillUnmount = () => {
    subscriptionCallSessionStateChanged?.unsubscribe();
    subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
    subscriptionFavoriteContactsChanged?.unsubscribe();
    subscriptionAzureADSettingsForContactsChanged?.unsubscribe();
    subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
    subscriptionContactsChanged?.unsubscribe();
    subscriptionWarmTransferInProgress?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
    subscriptionInitialSettingsRequestCompleted?.unsubscribe();
    subscriptionOnWarmTransferStarted?.unsubscribe();
    subscriptionOnWarmTransferFailed?.unsubscribe();
  }

  const getColumnClassName = (columnName: string): string => {
    if (columnName === ContactColumns.DisplayName) {
      return 'bigger-grow';
    } else if (columnName === ContactColumns.Mail) {
      return 'bigger-grow table-column';
    } else if (!callableColumns.includes(columnName)) {
      return 'small-grow table-column';
    }

    return '';
  }

  const buildColumnsHeader = () => {
    const attributes = azureADSettings?.Attributes;

    if (!attributes) {
      setColumns([]);

      return;
    }

    const columnsData = attributes
      .filter(x => !callableColumns.includes(x.ColumnName) && !isSipColumn(x.ColumnName, azureADSettings.SipMappingType))
      .map((attribute: AzureAdAttributeSettingDTO) => ({
        title: intl.formatMessage({ id: `AgentView.${attribute.ColumnName}` }),
        key: attribute.ColumnName,
        name: `Contacts${attribute.ColumnName}`,
        cellComparator: stringCellComparator,
        className: getColumnClassName(attribute.ColumnName)
      })
      );

    const actionsColumn: ContactColumn = {
      title: '',
      key: 'Actions',
      name: 'ContactTransferColumn',
      cellComparator: stringCellComparator,
      className: "jend contacts-actions"
    }

    setColumns([...columnsData, actionsColumn]);
  }

  const pullInitialData = () => {
    if (!initialClientLoadService.isInitialSettingsRequestDone || !initialClientLoadService.initialSettings || initSettingsRequestCompleted.current) {
      return;
    }

    initSettingsRequestCompleted.current = true;
    setAgentHasOutboundEnabled(socketOutbound.HasOutboundAccesEnabled());

    socketContacts.getCachedAzureActiveDirectorySettings()
      .then(setContactsAzureADSettings);

    const config = initialClientLoadService.initialSettings;
    setIsAgendaEnabled(config.IsContactsAgendaEnabled);
    setFavoriteConfiguration({ Limit: config.FavoritesLimit, IsEnabled: config.FavoritesIsEnabled });

    if (!config.FavoritesIsEnabled) {
      return;
    }

    extendedFavoritesService.getCurrentAgentFavoriteContacts()
      .then(favorites => {
        setFavoriteContacts(favorites);
      });
  }

  const pullContactsForCurrentPageState = (contact: ExtendedContact = {} as ExtendedContact, isFavorite: any = false) => {
    socketContacts.getContacts({
      Keyword: pageState.searchText,
      Start: pageState.page,
      Size: DefaultPageSize,
      SortColumn: pageState.sortColumn,
      SortDirection: pageState.sortDirection,
      ExcludeIds: Lockr.get(StorageKeys.UserObjectId),
      UserSip: Lockr.get<number>(StorageKeys.SIP)
    })
      .then((result: any) => {
        setContacts(result);

        if (isFavorite) {
          const favoriteContact = {
            ContactId: contact.Id,
            DisplayName: contact.Name,
            Upn: contact.UserPrincipalName,
            Email: contact.Email,
            MobileNumber: contact.MobilePhoneNumber,
            BusinessNumbersAsString: contact.BusinessPhoneNumber
          } as FavoriteContactDTO;
          notificationCenter.favoriteContactUpdated.next({ action: FavoriteContactAction.Update, sender: FavoriteContactSender.Contacts, contact: favoriteContact });
        }
      });
  }

  const isContactFavorite = (contact: Contact): boolean => favoriteContacts.some(x => x.ContactId === contact.Id);

  const toggleFavoriteButton = (contact: Contact) => {
    if (isContactFavorite(contact)) {
      removeContactFromFavorites(contact)
    } else {
      addContactToFavorites(contact);
    }
  }

  const removeContactFromLocalFavorites = (contactId: string) => setFavoriteContacts(prev => prev.filter(x => x.ContactId != contactId));

  const removeContactFromFavorites = (contact: Contact) => {
    extendedFavoritesService.removeFromFavorite(contact.Id)
      .then(() => {
        const favorite = favoriteContacts.find(x => x.ContactId === contact.Id);

        if (favorite) {
          notificationCenter.favoriteContactUpdated.next({ action: FavoriteContactAction.Delete, sender: FavoriteContactSender.Contacts, contact: favorite });
          removeContactFromLocalFavorites(favorite.ContactId);
        }
      })
      .catch(error => {
        console.error(error);
        logging.errorHandler.next("ErrorMessage.FavoriteContact.RemoveContact");
      });
  }

  const insertContactToLocalFavorites = (contact: FavoriteContactDTO) => setFavoriteContacts(prev => [...prev, contact]);

  const addContactToFavorites = (contact: Contact) => {
    if (favoriteContacts.length >= favoriteConfiguration.Limit) {
      logging.errorHandler.next(intl.formatMessage({ id: "ErrorMessage.FavoriteContact.MaxNumberOfFavoriteContactsReached" }));
      return;
    }

    const favorite: FavoriteContactDTO = {
      Id: '',
      DisplayName: contact.DisplayName,
      Source: contact.ContactType,
      ContactId: contact.Id,
      Upn: contact.UserPrincipalName,
      Email: contact.Mail,
      BusinessNumbersAsString: contact.BusinessPhonesAsString,
      MobileNumber: contact.MobilePhone,
      Department: contact.Department
    };

    extendedFavoritesService.addToFavorite(favorite)
      .then((added: FavoriteContactDTO) => {
        if (!added) {
          logging.errorHandler.next(intl.formatMessage({ id: "ErrorMessage.FavoriteContact.MaxNumberOfFavoriteContactsReached" }));
          return;
        }

        insertContactToLocalFavorites(added);
        notificationCenter.favoriteContactUpdated.next({ action: FavoriteContactAction.Add, sender: FavoriteContactSender.Contacts, contact: added });
      })
      .catch(error => {
        console.error(error);
        logging.errorHandler.next("ErrorMessage.FavoriteContact.AddContact");
      });
  }

  const agentIsNotInACall = (azureAId: string) => {
    const isCallParked = GeneralHelper.isCallParkedOrFallback(currentCallSession.ActionHistory, ConferenceStatusAction.OperatorPark);
    const isFallbackCall = GeneralHelper.isCallParkedOrFallback(currentCallSession.ActionHistory, ConferenceStatusAction.Fallback);

    const startedInbound = currentCallSession.Started && !currentCallSession.IsOutboundCall;
    const startedOutbound = currentCallSession.Started && currentCallSession.IsOutboundCall &&
      currentCallSession.ConferenceAction !== ConferenceStatusAction.OutboundAcceptedByAgent

    return ((!startedInbound && !startedOutbound) ||
      currentCallSession.Transferring ||
      currentCallSession?.ParticipantIds?.includes(azureAId) ||
      currentCallSession.IsTeamsUnparkInProgress ||
      isCallParked || isFallbackCall ||
      GeneralHelper.isRinging(currentCallSession.ActionHistory)
      || (EnvHelper.isStage3() && currentCallSession.IsCurrentUserMonitoring));
  }

  const mapContacts = (result: PaginatedContacts) => {
    const userList: any = [];

    result.ResultList?.forEach((contact: Contact, index: any) => {
      const isFavorite = favoriteConfiguration.IsEnabled && isContactFavorite(contact);
      const items: any = [];

      if (azureADSettings && azureADSettings.Attributes) {
        azureADSettings.Attributes.forEach((setting: AzureAdAttributeSettingDTO) => {
          const columnName = mapColumnName(setting.ColumnName);

          if (columnName === ContactColumns.DisplayName) {
            items.push({
              content: <Flex vAlign="center" className="contacts-display-name" >
                <AgentAvatar
                  status={""}
                  name={contact.DisplayName}
                  showPopup
                  azureID={contact.Id}
                  contactSourceType={contact.ContactType}
                />
              </Flex>,
              key: index.toString() + columnName,
              className: "bigger-grow display-name-cell",
              displayname: contact.DisplayName,
              title: contact.DisplayName
            });
          } else if (!callableColumns.includes(columnName) && !isSipColumn(setting.ColumnName, azureADSettings.SipMappingType)) {
            items.push({
              content: contact[columnName as keyof Contact],
              key: index.toString() + columnName,
              className: columnName === ContactColumns.Email ? "bigger-grow table-column" : "small-grow table-column",
              displayname: contact[columnName as keyof Contact],
              title: contact[columnName as keyof Contact]
            });
          }
        });

        const isContactManuallyAdded = contact.ContactType === ContactType.Database;

        const isManualContactDeleteEnabled = contact.IsImported ? supervisedQueues.filter(value => contact.QueueIds.includes(value)).length > 0 : isContactManuallyAdded;

        items.push(
          {
            content: <Flex vAlign="center" hAlign="end">
              {isContactManuallyAdded && <Button text iconOnly icon={<EditIcon size="medium" outline />} size="medium"
                title={intl.formatMessage({ id: "ContactsView.EditContact" })} onClick={() => toggleAddEditContactButton(contact, isFavorite)} />}
              {isManualContactDeleteEnabled && <Button text iconOnly icon={<TrashCanIcon size="medium" outline />} size="medium"
                title={intl.formatMessage({ id: "ContactsView.DeleteContact" })} onClick={() => toggleDeleteContactButton(contact)} />}
              <ChatButton upns={[contact.UserPrincipalName]} />
              {agentIsNotInACall(contact.Id)
                ? <NotInCallActions contact={contact} azureADSettings={azureADSettings} agentHasOutboundEnabled={agentHasOutboundEnabled}
                  currentCallSession={currentCallSession} />
                : <InCallActions contact={contact} azureADSettings={azureADSettings} agentHasOutboundEnabled={agentHasOutboundEnabled} />
              }
              {
                favoriteConfiguration.IsEnabled &&
                <Button
                  className={isFavorite ? 'active-favorite' : ''}
                  text
                  iconOnly
                  icon={<StarIcon className="" outline size="medium" />}
                  title={intl.formatMessage({ id: `CallActions.${isFavorite ? 'RemoveFromFavorites' : 'AddToFavorites'}` })}
                  size="medium"
                  onClick={() => toggleFavoriteButton(contact)}
                />
              }
              {
                isAgendaEnabled && <AgendaPopup azureId={contact.Id} isDisabled={isContactManuallyAdded} />
              }
            </Flex>,
            key: `${index.toString()}-1`,
            className: "jend contacts-actions"
          },
        );

        userList.push({
          key: index.toString(),
          sip: (contact.UserPrincipalName || '').toLowerCase(),
          items: items
        });
      }
    });

    setContactList(userList);
  }

  const mapColumnName = (column: string) => column === ContactColumns.Upn ? ContactColumns.UserPrincipalName : (column || '');

  const isSipColumn = (name: string, sipType: SipMappingType): boolean => {
    return (name === ContactSipAdressColumnsEnum.Upn && sipType === SipMappingType.UPN) ||
      (name === ContactSipAdressColumnsEnum.Mail && sipType === SipMappingType.Mail);
  }

  const searchContacts = (e: any) => {
    if (e.keyCode === 13 && e.target.value.length > 2) {
      updateSearchState(e.target.value);
    }
  }

  const searchContactsOnChange = (e: any) => {
    if (e.target.value.length === 0) {
      updateSearchState(e.target.value);
    }
  }

  const updateSearchState = (searchText: string) => {
    setPageState(prev => ({ ...prev, searchText: searchText, page: 0 }));
  }

  const selectPage = (pageNumber: number) => {
    setPageState(prev => ({ ...prev, page: pageNumber - 1 }));
  }

  const sortHandler = (column: any, direction: any) => {
    setPageState(prev => ({ ...prev, sortColumn: getColumnValue(column), sortDirection: direction === SortDirection.Asc }));
  }

  const getColumnValue = (columnValue: string) => {
    switch (columnValue) {
      case SortableColumnValue.ContactsDisplayName:
        return "Agent";
      case SortableColumnValue.ContactsMail:
        return "Mail";
      case SortableColumnValue.ContactsJobTitle:
        return "JobTitle";
      case SortableColumnValue.ContactsDepartment:
        return "Department";
      case SortableColumnValue.ContactsCity:
        return "City";
      case SortableColumnValue.ContactsOfficeLocation:
        return "OfficeLocation";
      case SortableColumnValue.ContactsCountry:
        return "Country";
      case SortableColumnValue.ContactsState:
        return "State";
      case SortableColumnValue.ContactsPostalCode:
        return "PostalCode";
      default:
        return "Agent";
    }
  }

  const handleContactsChanged = (userChangedModel: UserChangedModel) => {
    let changeTypeMessage = "";

    switch (userChangedModel.UserChangeType) {
      case UserChangeType.Added: {
        changeTypeMessage = intl.formatMessage({ id: "ContactsView.AddChangeMessage" });
        break;
      }
      case UserChangeType.Updated: {
        changeTypeMessage = intl.formatMessage({ id: "ContactsView.UpdateChangeMessage" });
        break;
      }
      case UserChangeType.Deleted: {
        changeTypeMessage = intl.formatMessage({ id: "ContactsView.DeleteChangeMessage" });
        break;
      }
    }

    const user = userChangedModel.DisplayName ? userChangedModel.DisplayName : userChangedModel.Upn;
    const textContent = `Agent ${user} ${changeTypeMessage} by your organization.`;

    logging.errorHandler.next(textContent);

    pullContactsForCurrentPageState();
  }

  const getContactsSearchBox = () => (<Input
    icon={<SearchIcon size="small" />}
    iconPosition="start"
    inverted
    placeholder={intl.formatMessage({ id: "ContactsView.Search" })}
    onChange={searchContactsOnChange} onKeyDown={searchContacts} />
  );

  const toggleAddEditContactButton = (contact?: any, isFavorite: boolean = false) => {
    setIsAddEditContactDialogOpen(!isAddEditContactDialogOpen);
    setContactToUpdate(contact);
    setIsAddedContactFavorite(isFavorite ?? false);
  };

  const toggleDeleteContactButton = (contact: any) => {
    setIdOfContactToDelete(contact.Id);
    setNameOfContactToDelete(contact.DisplayName);
  }

  const deleteContact = () => {
    contactService.delete(idOfContactToDelete).then((response: any) => {
      logging.errorHandler.next("SuccessMessage.DeleteContact");
      setIdOfContactToDelete('');
      setTimeout(function () {
        pullContactsForCurrentPageState();

        const favoriteContact = {
          ContactId: idOfContactToDelete,
        } as FavoriteContactDTO;

        notificationCenter.favoriteContactUpdated.next({ action: FavoriteContactAction.Delete, sender: FavoriteContactSender.Contacts, contact: favoriteContact });
      }, 1000);
    }).catch((err: { message: any; }) => {
      logging.errorHandler.next("ErrorMessage.DeleteContact");
      console.log(err.message);
    });
  }

  return (
    <>
      <Flex column>
        <Flex space="between" vAlign="center">
          <Text content={`${intl.formatMessage({ id: "AgentView.Contacts" })} (${contacts.Count || 0})`} weight="bold" />
          <Flex vAlign="center" gap="gap.medium">
            <div className="main-search">
              {getContactsSearchBox()}
            </div>
            <Button icon={<ParticipantAddIcon outline />}
              content={intl.formatMessage({ id: "ContactsView.AddNewContact" })} primary
              onClick={() => toggleAddEditContactButton()}
              className="add-new-contact-button" />
          </Flex>
        </Flex>
        <div className="responsive-search">
          {getContactsSearchBox()}
        </div>

        <Dialog open={isAddEditContactDialogOpen}
          content={<ExtendedAddEditContactView
            closeAddContactPortal={() => toggleAddEditContactButton()}
            getAllContacts={pullContactsForCurrentPageState}
            contactToUpdate={contactToUpdate}
            isFavorite={isAddedContactFavorite}
          />}
          header={contactToUpdate ? intl.formatMessage({ id: "ContactsView.AddContact" }) : intl.formatMessage({ id: "ContactsView.EditContact" })}
          styles={{ width: "750px" }}
          className="add-edit-dialog"
        />

        <Dialog
          open={Boolean(idOfContactToDelete)}
          confirmButton={intl.formatMessage({ id: "ContactsView.DeleteContactConfirm" })}
          onConfirm={deleteContact}
          cancelButton={intl.formatMessage({ id: "ContactsView.DeleteContactCancel" })}
          onCancel={() => setIdOfContactToDelete('')}
          header={intl.formatMessage({ id: "ContactsView.DeleteContact" })}
          content={nameOfContactToDelete + intl.formatMessage({ id: "ContactsView.ContactWillBeDeletedMessage" })}
          styles={{ width: "500px" }}
        />

        <AdvancedTable
          className="contact-table"
          columns={columns}
          rows={contactList}
          sortHandler={sortHandler}
          label={intl.formatMessage({ id: "AgentView.Contacts" })}
        />

        <Paginator
          currentPage={pageState.page + 1}
          totalPages={totalPages}
          onChange={selectPage}
        />
      </Flex>
    </>
  )
};

export default ExtendedContacts;

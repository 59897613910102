import React, { useState, useRef } from 'react';
import Lockr from 'lockr';
import {
  Button, CallEndIcon, Flex, Text, CallParkingIcon, PauseThickIcon,
  CallIcon, PlayIcon, PhoneArrowIcon, CallRecordingIcon,
  ParticipantRemoveIcon, Popup, Input, UserFriendsIcon, ExpandIcon, AttendeeIcon
} from '@fluentui/react-northstar';
import { socketAudio } from 'services/calling';
import {
  StorageKeys, logging, ConferenceStatusAction, ReasonNameConstants,
  MonitorTypes, ActiveMainViewItem, HeaderBarDisplayedMessageType
} from 'utils';
import { transferRequest, callSessionRequest, ContactType } from 'utils/domain/transfercall';
import { socketTransfer } from 'services/transfers';
import { GeneralHelper, ValidationHelper, EnvHelper } from 'utils/helpers';
import { useIntl } from 'react-intl';
import { PresenceAndNoteDTO } from 'utils/domain/presence';
import { CallSessionDTO } from 'utils/domain/callSessionDTO';
import './callActions.scss'
import { Subscription } from 'rxjs';
import Timer from 'components/Timer';
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import { socketPresence } from 'services/presence';
import { TransferStartResponse } from 'utils/domain/transferStartResponse';

const styles = {
  backgroundColor: "#C4314B",
  borderRadius: '0px',
  color: "#ffffff",
  width: '50px',
  height: '50px',
  ':hover': {
    backgroundColor: 'rgba(167,32,55,0.9)',
    color: "#ffffff",
  },
  '[disabled]': {
    backgroundColor: 'rgba(127,127,127,0.5)',
  }
}

const stylesAnswer = {
  backgroundColor: "#35ad31",
  borderRadius: '0px',
  color: "#ffffff",
  width: '50px',
  height: '50px',
  ':hover': {
    backgroundColor: "#2e962a",
    color: "#ffffff",
  }
}

const stylesFaded = {
  backgroundColor: "#605e5c",
  borderRadius: '0px',
  color: "#c8c6c4",
  width: '50px',
  height: '50px',
  ':hover': {
    backgroundColor: 'rgb(52, 52, 65)',
    color: "#c8c6c4",
  }
}

export const CallActions = () => {
  const isFirstRun = useRef(true);
  const intl = useIntl();
  const callerUri = useRef('');
  const callerName = useRef('');
  const transferTo = useRef('');
  const transferToNameStage3 = useRef('');
  const contactType = useRef(ContactType.Agent);
  const [operation, setOperation] = useState('');
  const [callStartTime, setCallStartTime] = useState('');

  const [answerCallVisible, setAnswerCallVisible] = useState(false);
  const [rejectCallVisible, setRejectCallVisible] = useState(false);
  const [endCallVisible, setEndCallVisible] = useState(false);
  const [monitoringButtonsVisible, setMonitoringButtonsVisible] = useState(false);
  const [pauseCallVisible, setPauseCallVisible] = useState(false);
  const [resumeCallVisible, setResumeCallVisible] = useState(false);
  const [pauseActionCall, setPauseActionCall] = useState(false);
  const [parkCallVisible, setParkCallVisible] = useState(false);
  const [recordCallVisible, setRecordCallVisible] = useState(false);
  const [stopRecordCallVisible, setStopRecordCallVisible] = useState(false);
  const [coldTransferVisible, setColdTransferVisible] = useState(false);
  const [coldTransferInputValue, setColdTransferInputValue] = useState('');
  const [warmTransferVisible, setWarmTransferVisible] = useState(false);
  const [warmTransferInputValue, setWarmTransferInputValue] = useState('');
  const [warmTransferCancelVisible, setCancelWarmTransferVisible] = useState(false);
  const [finalizeWarmTransferVisible, setFinalizeWarmTransferVisible] = useState(false);
  const [warmTransferConsultCallerVisible, setWarmTransferConsultCallerVisible] = useState(false);
  const [warmTransferConsultAgentVisible, setWarmTransferConsultAgentVisible] = useState(false);
  const [isInvalidTransfer, setIsInvalidTransfer] = useState(false);
  const [listenButtonDisabled, setListenButtonDisabled] = useState(false);
  const [bargeInButtonDisabled, setBargeInButtonDisabled] = useState(false);

  const [currentCallSession, setCurrentCallSession] = useState(new CallSessionDTO());
  const currentSessionId = useRef('');
  const skipInputTransferChangeEvent = useRef(false);
  const [isOngoingCallSelected, setIsOngoingCallSelected] = useState(true);

  let subscriptionCurrentUserCallSessionStateChanged: Subscription | null = null;
  let subscriptionColdTransfer: Subscription | null = null;
  let subscriptionWarmTransfer: Subscription | null = null;
  let subscriptionPresence: Subscription | null = null;
  let subscriptionCurrentUserMonitoredCallSessionChanged: Subscription | null = null;
  let subscriptionViewChanged: Subscription | null = null;
  let subscriptionHeaderBarMessageChanged: Subscription | null = null;
  let subscriptionCallSessionChanged: Subscription | null = null;
  let subscriptionWarmTransferInProgress: Subscription | null = null;

  const endOperations = EnvHelper.isStage3() ? GeneralHelper.getStage3EndOperations() : GeneralHelper.getEndOperations();
  const transferInvalidInputMessage = "ErrorMessage.Transfer.InvalidInput";
  const currentUserId = Lockr.get<string>(StorageKeys.UserId);

  const getCallsessionAgentRef = (callSessionDTO: CallSessionDTO) => {
    return callSessionDTO.IsOutboundCall && callSessionDTO.CallerId == Lockr.get<string>(StorageKeys.UserObjectId)
      ? callSessionDTO.CallerId
      : callSessionDTO.AgentRef;
  }

  const setCallSession = (callSessionDTO: CallSessionDTO) => {
    callerUri.current = callSessionDTO.CallerUri;
    callerName.current = callSessionDTO.CallerName;

    callSessionRequest.ConversationKey = callSessionDTO.ConversationKey;
    callSessionRequest.SessionId = callSessionDTO.SessionId;
    callSessionRequest.SourceUri = Lockr.get(StorageKeys.SIP);

    if (EnvHelper.isStage3()) {
      callSessionRequest.CallId = callSessionDTO.SessionId;
      callSessionRequest.TenantId = callSessionDTO.TenantId;
      callSessionRequest.AgentRef = getCallsessionAgentRef(callSessionDTO);
      callSessionRequest.QueueRef = callSessionDTO.QueueRef;
      callSessionRequest.SecondaryAgentDisplayName = callSessionDTO.SecondaryAgentName;
      callSessionRequest.SecondaryAgentUpn = callSessionDTO.SecondaryAgentSIP;
      callSessionRequest.SecondaryAgentRef = callSessionDTO.SecondaryAgentRef;
      callSessionRequest.AzureTenantId = callSessionDTO.AzureTenantId;
      callSessionRequest.ScenarioId = callSessionDTO.ScenarioId;
    }

    transferRequest.CallSessionRequest.ConversationKey = callSessionDTO.ConversationKey;
    transferRequest.CallSessionRequest.SessionId = callSessionDTO.SessionId;
    //#warning-js [cc4all-1102]: isn't transferRequest.CallSessionRequest the same instance with callSessionRequest?
    transferRequest.CallSessionRequest.SourceUri = Lockr.get(StorageKeys.SIP);
  };

  const clearCallSession = () => {
    callerUri.current = '';
  };

  React.useEffect(() => {
    if (isFirstRun.current || !EnvHelper.isStage3()) {
      return () => {
        // ignore first run of useEffect when component is mounted
      };
    }

    if (pauseActionCall) {
      setPausedCall();
    } else {
      setResumedCall();
    }

    const pauseActionTimer = setTimeout(() => {
      if (pauseActionCall) {
        pauseCall();
      } else {
        resumeCall();
      }
    }, 400);

    return () => clearTimeout(pauseActionTimer);
  }, [pauseActionCall]);

  React.useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }

    initialize();
    return () => componentWillUnmount();
  }, []);

  const componentWillUnmount = () => {
    subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
    subscriptionColdTransfer?.unsubscribe();
    subscriptionWarmTransfer?.unsubscribe();
    subscriptionPresence?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
    subscriptionViewChanged?.unsubscribe();
    subscriptionHeaderBarMessageChanged?.unsubscribe();
    subscriptionCallSessionChanged?.unsubscribe();
  }

  const currentUserMonitoredCallSessionChanged = (callSessionDTO: CallSessionDTO) => {
    GeneralHelper.logCox(`in CallActions.tsx, 
    listenerCurrentUserMonitoredCallSessionChanged, 
    ActiveSupervisorSIP is ${callSessionDTO.ActiveSupervisorSIP}, 
    MonitorType is ${callSessionDTO.MonitorType}, 
    ConferenceActionAsString is ${callSessionDTO.ConferenceActionAsString}`);

    if (callSessionDTO.MonitorType !== MonitorTypes.None && callSessionDTO.MonitorType !== MonitorTypes.BySomeoneElse && !callSessionDTO.IsComputedAsTerminated) {
      let formatMessageId: string = "";
      switch (callSessionDTO.MonitorType) {
        case MonitorTypes.Ringing:
          formatMessageId = "CallActions.SupervisingSelfRinging";
          setListenButtonDisabled(false);
          setBargeInButtonDisabled(true);
          break;
        case MonitorTypes.BargeIn:
          formatMessageId = "CallActions.BargeIn";
          setListenButtonDisabled(false);
          setBargeInButtonDisabled(true);
          break;
        case MonitorTypes.Silent:
          formatMessageId = "CallActions.Listen";
          setListenButtonDisabled(true);
          setBargeInButtonDisabled(false);
          break;
        // case MonitorTypes.Whisper:
        //   formatMessageId = "MonitoringAction.Whisper";
        //   break;
      }
      if (formatMessageId) {
        setOperation(intl.formatMessage({ id: formatMessageId }));
      }
      setCallSession(callSessionDTO);
      setCallStartTime(callSessionDTO.SupervisingStartDate);
      setMonitoringButtonsVisible(callSessionDTO.MonitorType !== MonitorTypes.Ringing);
      socketAudio.callActionViewIsDisplayed.next(true);
    } else {
      setMonitoringButtonsVisible(false);
      setCallStartTime('');
      setOperation('');
      socketAudio.callActionViewIsDisplayed.next(false);
    }
  }

  const currentUserCallSessionChanged = (callSessionDTO: CallSessionDTO) => {
    setCurrentCallSession(callSessionDTO);
    const isParkCall = GeneralHelper.isCallParkedOrFallback(callSessionDTO.ActionHistory, ConferenceStatusAction.OperatorPark);
    const isFallbackCall = GeneralHelper.isCallParkedOrFallback(callSessionDTO.ActionHistory, ConferenceStatusAction.Fallback);

    if (EnvHelper.isStage3() &&
      ((callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Ringing] && !callSessionDTO.IsOutboundCall) ||
        (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransfered] &&
          callSessionDTO.AgentBeforeTransferRef !== currentUserId))
    ) {
      startStage3Call(callSessionDTO);
      return;
    } else {
      setRejectCallVisible(false);
    }

    if (callSessionDTO.ActionHistory?.includes(ConferenceStatusAction.Accepted)
      && callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] !== ConferenceStatusAction.AgentColdTransfered
      && callSessionDTO.StatusAsString !== ConferenceStatusAction[ConferenceStatusAction.Ringing]
      && !isParkCall && !isFallbackCall && !callSessionDTO.IsTeamsUnparkInProgress
      && !(EnvHelper.isStage3() && callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransfered])) {
      startCall(callSessionDTO);

      if (callSessionDTO.TransferTargetUri) {
        transferTo.current = callSessionDTO.TransferTargetUri;
        transferRequest.TargetUri = callSessionDTO.TransferTargetUri;
        transferRequest.ObjectId = callSessionDTO.OriginalTransferTargetUri;
      }

      if ((callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.Accepted
        || callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Accepted]
        || (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.Resumed && !callSessionDTO.TransferTargetUri))
        && (!callSessionDTO.IsOutboundCall)) {
        setOngoingCallOperation();
      }

      if (callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.WarmCanceled] &&
        callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent]) {
        if (callSessionDTO.IsWarmTransferInProgress) {
          transferToNameStage3.current = callSessionDTO.SecondaryAgentName ? callSessionDTO.SecondaryAgentName : '';
          if (EnvHelper.isStage3()) {
            if (callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.Resumed]) {
              setWarmTransferStarted();
            }
          }
          else {
            setWarmTransferInProgress();
          }
        } else if (callSessionDTO.IsCallerOnHold) {
          setPausedCall();
        }

        // warm invite sent
        if (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.WarmInviteSent
          || (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 2] === ConferenceStatusAction.WarmInviteSent
            && callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.OnHold)) {
          setWarmTransferStarted();
        }

        // warm invite accepted
        if (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.WarmInviteAccepted
          || (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 2] === ConferenceStatusAction.WarmInviteAccepted
            && callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.OnHold)) {
          transferToNameStage3.current = callSessionDTO.SecondaryAgentName;
          setWarmTransferAccepted();
        }

        // warm switch to caller
        if (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.WarmSwitchToCaller
          || (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 2] === ConferenceStatusAction.WarmSwitchToCaller
            && callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.Resumed)) {
          setWarmTransferConsultCaller();
        }

        // warm switch to agent
        if (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.WarmSwitchToAgent
          || (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 2] === ConferenceStatusAction.WarmSwitchToAgent
            && callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.OnHold)) {
          setWarmTransferConsultAgent();
        }
      }

      // cold transfer invite
      if (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.OperatorColdTransferInviteSent) {
        setRinging3dPartyOperation();
      }

      if (!!transferTo) {
        if (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.OperatorColdTransferInviteRejected) {
          transferTo.current = '';
          transferToNameStage3.current = '';

          logging.errorHandler.next("ErrorMessage.Transfer.ColdTransferFailed");
        }

        if (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmCanceled] ||
          callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent]
          || callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmInviteRejected]) {
          transferTo.current = '';
          transferToNameStage3.current = '';

          setWarmTransferCanceled();

          const isCallerResumed = EnvHelper.isStage3() ?
            callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.Resumed || !callSessionDTO.IsCallerOnHold
            : callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.Resumed;

          if (isCallerResumed) {
            setResumeCallVisible(false);
            setPauseCallVisible(true);
            setOngoingCallOperation();
          }
        }

        if (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmInviteRejected]) {
          logging.errorHandler.next("ErrorMessage.Transfer.WarmInviteRejected");
        }
      }

      if (callSessionDTO.IsManualRecordingSessionActive) {
        setStopRecordCallVisible(callSessionDTO.IsManualRecordingSessionActive);
      }

      if (!callSessionDTO.IsManualRecordingSessionActive && callSessionDTO.IsManualRecordingEnabled) {
        setRecordCallVisible(callSessionDTO.IsManualRecordingEnabled);
      }
      if (callSessionDTO.IsOutboundCall) {
        outboundCall(callSessionDTO);
      }
    } else if (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Ringing] &&
      (callSessionDTO.IsTeamsCampaignOutboundCall || callSessionDTO.IsOutboundCall)) {
      outboundRinging(callSessionDTO);
    } else if (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Accepted] && callSessionDTO.IsOutboundCall) {
      outboundCall(callSessionDTO);
    } else if (!EnvHelper.isStage3()) {
      endCall(callSessionDTO);
    }

    const sip = Lockr.get<string>(StorageKeys.SIP);
    if (GeneralHelper.callerHasLeft(callSessionDTO.ActionHistoryAsString, callSessionDTO.ConferenceActionAsString, sip === callSessionDTO.PrimaryAgentSIP)) {
      logging.errorHandler.next("ErrorMessage.Red.CallerLeft");
    }
  }

  const setRingingOperation = () => {
    setOperation(intl.formatMessage({ id: "CallActions.Ringing" }));
  }

  const setOngoingCallOperation = () => {
    setOperation(intl.formatMessage({ id: "CallActions.OngoingCall" }));
  }

  const setOutboundCallOperation = () => {
    setOperation(intl.formatMessage({ id: "CallActions.OutboundCall" }));
  }

  const setRinging3dPartyOperation = () => {
    setOperation(intl.formatMessage({ id: "CallActions.Ringing3dParty" }));
    const isCancelButtonVisible = !EnvHelper.isStage3() || currentCallSession.LastInvitedParticipantId !== '';
    setCancelWarmTransferVisible(isCancelButtonVisible);
  }

  const currentUserOnColdTransferStartedallSessionChanged = (obj: any) => {
    transferTo.current = obj.transferTo?.toString().toLowerCase();
    contactType.current = obj.contactType;

    handleColdTransfer();
  }

  const onWarmTransferStarted = (obj: any) => {
    transferTo.current = obj.transferTo.toLowerCase();
    contactType.current = obj.contactType;

    handleWarmTransfer();
  }

  const onPresenceAndNoteSet = (obj: PresenceAndNoteDTO) => {
    //handle teams outbound
    if (obj.Activity === ReasonNameConstants.InACall &&
      currentCallSession &&
      (currentCallSession.IsTeamsCampaignOutboundCall || currentCallSession.IsOutboundCall) &&
      currentCallSession.ActionHistory &&
      currentCallSession.ActionHistory.includes(ConferenceStatusAction.Accepted)) {
      setEndCallVisible(true);
      setPauseCallVisible(true);
      setResumeCallVisible(false);
      setColdTransferVisible(true);
      setWarmTransferVisible(true);

      setParkCallVisible(isParkCallVisible(obj));

      setOngoingCallOperation();
      socketAudio.callActionViewIsDisplayed.next(true);
    }

    if (obj.Activity === ReasonNameConstants.Ringing &&
      currentCallSession &&
      (currentCallSession.IsTeamsCampaignOutboundCall || currentCallSession.IsOutboundCall) &&
      currentCallSession.ActionHistory &&
      currentCallSession.ActionHistory.includes(ConferenceStatusAction.Accepted)) {
      outboundRinging(currentCallSession);
    }
  }

  const initialize = () => {
    GeneralHelper.logCox(`in CallActions.tsx, in initialize`);
    subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
    subscriptionCurrentUserCallSessionStateChanged = socketAudio.currentUserCallSessionChanged.subscribe(currentUserCallSessionChanged);
    subscriptionColdTransfer?.unsubscribe();
    subscriptionColdTransfer = socketTransfer.onColdTransferStarted.subscribe(currentUserOnColdTransferStartedallSessionChanged);
    subscriptionWarmTransfer?.unsubscribe();
    subscriptionWarmTransfer = socketTransfer.onWarmTransferStarted.subscribe(onWarmTransferStarted);
    subscriptionPresence?.unsubscribe();
    subscriptionPresence = socketPresence.listenerPresenceAndNoteSet.received.subscribe(onPresenceAndNoteSet);
    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged = socketAudio.currentUserMonitoredCallSessionChanged.subscribe(currentUserMonitoredCallSessionChanged);

    subscriptionViewChanged?.unsubscribe();
    subscriptionViewChanged = mainViewHelper.viewChanged.subscribe((activeViewItem: ActiveMainViewItem) => {
      setIsOngoingCallSelected(activeViewItem === ActiveMainViewItem.MediaView);
    });

    subscriptionHeaderBarMessageChanged?.unsubscribe();
    subscriptionHeaderBarMessageChanged = mainViewHelper.showHeaderBarMessage.subscribe((messageType: HeaderBarDisplayedMessageType) => {
      setIsOngoingCallSelected(messageType === HeaderBarDisplayedMessageType.MediaView)
    });

    subscriptionCallSessionChanged?.unsubscribe();
    subscriptionCallSessionChanged = socketAudio.callSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {

      if (EnvHelper.isStage3()) {
        const userId = Lockr.get<string>(StorageKeys.UserId);

        const warmTranferCancelledOrRejected = callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmInviteRejected] ||
          callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmCanceled] ||
          callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent];

        if (userId !== callSessionDTO.AgentRef && warmTranferCancelledOrRejected) {
          setOperation("");
        }

        if (userId === callSessionDTO.AgentRef &&
          (warmTranferCancelledOrRejected || callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OnHold])) {
          setPauseActionCall(true);
        }

        if ((endOperations.includes(callSessionDTO.ConferenceActionAsString) ||
          (currentCallSession.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransfered] &&
            callSessionDTO.AgentBeforeTransferRef === "" || callSessionDTO.AgentBeforeTransferRef === currentUserId)) &&
          (currentSessionId.current === callSessionDTO.SessionId || callSessionDTO.IsOutboundCall)) {
          endCall();
        }

        if (callSessionDTO.CallerId === userId && callSessionDTO.IsOutboundCall &&
          callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.Terminated]) {
          if (currentCallSession.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Ringing]) {
            outboundRinging(callSessionDTO);
          }
          else {
            outboundCall(callSessionDTO);
          }
        }
      }
    });

    subscriptionWarmTransferInProgress?.unsubscribe();
    subscriptionWarmTransferInProgress = socketAudio.warmTransferInProgress.subscribe((callSessionDTO: CallSessionDTO) => {
      if (EnvHelper.isStage3()) {
        if (callSessionDTO.IsWarmTransferInProgress &&
          callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.AgentClosed]) {
          setOperation(`${intl.formatMessage({ id: "CallActions.Transferring" })} ${transferToNameStage3.current}`)
        } else {
          setOperation("");
        }
      }
    });
  }

  const isParkCallVisible = (obj: any) => {
    if (!obj.IsOperatorQueue) {
      return false;
    }

    if (obj.ActionHistory && obj.ActionHistory.length) {
      if (obj.ActionHistory.indexOf(ConferenceStatusAction.OperatorColdTransfered) > -1 ||
        obj.ActionHistory.indexOf(ConferenceStatusAction.WarmInviteAccepted) > -1 ||
        obj.ActionHistory.indexOf(ConferenceStatusAction.WarmAccepted) > -1 ||
        obj.ActionHistory.indexOf(ConferenceStatusAction.OperatorColdTransferInviteAccepted) > -1 || obj.ActionHistory.indexOf(ConferenceStatusAction.AgentColdTransfered) > -1) {
        return false;
      }
    }

    return true;
  }

  const startStage3Call = (callSessionDTO: CallSessionDTO) => {
    currentSessionId.current = callSessionDTO && callSessionDTO.SessionId ? callSessionDTO.SessionId : '';
    setRejectCallVisible(false);
    setEndCallVisible(false);
    setPauseCallVisible(false);
    setResumeCallVisible(false);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);
    setParkCallVisible(false);

    setIsOngoingCallSelected(true);
    setRingingOperation();

    socketAudio.callActionViewIsDisplayed.next(true);
    setCallSession(callSessionDTO);
  }

  const startCall = (callSessionDTO: CallSessionDTO) => {
    currentSessionId.current = callSessionDTO && callSessionDTO.SessionId ? callSessionDTO.SessionId : '';
    ongoingCall(callSessionDTO);
    setCallSession(callSessionDTO);
    setCallStartTime(callSessionDTO.CallStartDate);
  }

  const ongoingCall = (obj?: CallSessionDTO) => {
    setEndCallVisible(true);
    setPauseCallVisible(!(obj?.IsCallerOnHold ?? false));
    setResumeCallVisible((obj?.IsCallerOnHold ?? false));
    setColdTransferVisible(true);
    setWarmTransferVisible(true);
    setCancelWarmTransferVisible(false);

    if (obj && obj.ActionHistory) {
      setParkCallVisible(isParkCallVisible(obj));
    }

    socketAudio.callActionViewIsDisplayed.next(true);
  }

  const endCall = (obj?: any) => {
    if (obj && obj.SessionId && currentSessionId.current && obj.SessionId !== currentSessionId.current) {
      //when operator is in a call and a parked call is ended by caller, ignore the end call event from the parked call session
      return;
    }
    currentSessionId.current = ''
    transferTo.current = '';
    transferToNameStage3.current = '';

    setAnswerCallVisible(false);
    setRejectCallVisible(false);
    setEndCallVisible(false);
    setPauseCallVisible(false);
    setResumeCallVisible(false);
    setParkCallVisible(false);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);
    setCancelWarmTransferVisible(false);
    setFinalizeWarmTransferVisible(false);
    setWarmTransferConsultCallerVisible(false);
    setWarmTransferConsultAgentVisible(false);
    setRecordCallVisible(false);
    setStopRecordCallVisible(false);

    setOperation("");
    socketAudio.callActionViewIsDisplayed.next(false);

    setCallStartTime("");
    clearCallSession();
  }

  const pauseCall = () => {
    const requestData = EnvHelper.isStage3() ? getStage3CallSessionRequestObject() : callSessionRequest;

    socketAudio.pauseCall(requestData).then((response) => {
      if (response === false) {
        logging.errorHandler.next("ErrorMessage.Transfer.PauseFailed");

        setResumedCall();
      }
    }).catch(err => {
      console.log(err.message);

      setResumedCall();

      logging.errorHandler.next("ErrorMessage.Transfer.PauseFailed");
    });
  }

  const resumeCall = () => {
    const requestData = EnvHelper.isStage3() ? getStage3CallSessionRequestObject() : callSessionRequest;

    socketAudio.resumeCall(requestData).then((response) => {
      if (response === false) {
        logging.errorHandler.next("ErrorMessage.Transfer.ResumeFailed");

        setPausedCall();
      }
    }).catch(err => {
      console.log(err.message);

      logging.errorHandler.next("ErrorMessage.Transfer.ResumeFailed");

      setPausedCall();
    });
  }

  const setPausedCall = () => {
    setOperation(intl.formatMessage({ id: "CallActions.OnHold" }));
    socketAudio.callActionViewIsDisplayed.next(true);

    setPauseCallVisible(false);
    setResumeCallVisible(true);
  };

  const setResumedCall = () => {
    setOngoingCallOperation();
    socketAudio.callActionViewIsDisplayed.next(true);
    setPauseCallVisible(true);
    setResumeCallVisible(false);
  };

  const checkTransferTo = (value: string) => {
    if (value.length < 50) {
      transferTo.current = value;

      setIsInvalidTransfer(false);
    } else {
      setIsInvalidTransfer(true);
    }
  };

  const handleColdTransferKeyPressFromTheCallActionPopup = (e: any) => {
    if (e.keyCode === 13 || e.which === 13) {
      handleColdTransferClickFromTheCallActionPopup();
    }
  };

  const handleColdTransferClickFromTheCallActionPopup = () => {
    contactType.current = ContactType.Agent;
    handleColdTransfer();
  };

  const handleWarmKeyPress = (e: any) => {
    if (e.keyCode === 13 || e.which === 13) {
      handleWarmTransfer();
    }
  };

  const handleColdTransfer = () => {
    if (ValidationHelper.isValidInput(transferTo.current?.trim())) {
      setIsInvalidTransfer(false);

      transferRequest.TargetUri = ValidationHelper.appendSipIfInputDoesNotContainsIt(transferTo.current);
      transferRequest.ObjectId = transferTo.current;
      transferRequest.ContactType = contactType.current;

      const requestData = EnvHelper.isStage3() ? getStage3CallSessionRequestObject() : transferRequest;

      socketTransfer.coldTransfer(requestData).then((response) => {
        if (EnvHelper.isStage3()) {
          handleColdTransferStartStage3(response);
        } else {
          handleColdTransferStartStage2(response);
        }
      });
    } else {
      setIsInvalidTransfer(true);

      logging.errorHandler.next(transferInvalidInputMessage);
    }
  }

  const handleColdTransferStartStage3 = (response: TransferStartResponse) => {
    if (response.ContactNotFound || !response.TransferStarted) {
      setIsInvalidTransfer(true);
    }

    if (response.ContactNotFound) {
      logging.errorHandler.next("ErrorMessage.Transfer.TransferContactNotFound");
      setOngoingCallOperation();
      return;
    }
    else {
      setRinging3dPartyOperation();
    }

    if (response.ContactUnavailable) {
      logging.errorHandler.next("ErrorMessage.Transfer.TransferContactUnavailable");
      setOngoingCallOperation();
      setCancelWarmTransferVisible(false);
      return;
    }

    if (!response.TransferStarted) {
      logging.errorHandler.next(transferInvalidInputMessage);
      return;
    }

    handleColdTransferPerformed();
  }

  const handleColdTransferStartStage2 = (response: any) => {
    if (response === true) {
      handleColdTransferPerformed();
    } else {
      setIsInvalidTransfer(true);

      logging.errorHandler.next(transferInvalidInputMessage);
    }
  }

  const handleColdTransferPerformed = () => {
    logging.errorHandler.next("ErrorMessage.Transfer.ColdTransferPerformed");

    endCall();
    clearTransferInputs();
  }

  const handleWarmTransfer = () => {
    const isStage3 = EnvHelper.isStage3();

    if (ValidationHelper.isValidInput(transferTo.current.trim())) {
      setIsInvalidTransfer(false);

      transferRequest.TargetUri = ValidationHelper.appendSipIfInputDoesNotContainsIt(transferTo.current);
      transferRequest.ObjectId = transferTo.current;
      transferRequest.ContactType = contactType.current;

      const requestData = isStage3 ? getStage3CallSessionRequestObject() : transferRequest;

      setWarmTransferStarted();
      socketTransfer.warmTransferStart(requestData).then((response) => {
        if (isStage3) {
          handleWarmTransferStartStage3(response);
        } else {
          handleWarmTransferStartStage2(response);
        }
      }).catch(err => {
        console.log(err.message);
        warmTransferFailed("ErrorMessage.Transfer.WarmTransferFailed");
      })
    } else {
      setIsInvalidTransfer(true);

      logging.errorHandler.next(transferInvalidInputMessage);
    }
  };

  const handleWarmTransferStartStage3 = (response: TransferStartResponse) => {
    if (response.ContactNotFound) {
      handleWarmTransferFailed("ErrorMessage.Transfer.TransferContactNotFound");
      return;
    }

    if (response.ContactUnavailable) {
      handleWarmTransferFailed("ErrorMessage.Transfer.TransferContactUnavailable");
      return;
    }

    if (!response.TransferStarted) {
      warmTransferFailed("ErrorMessage.Transfer.WarmTransferFailed");
      return;
    }

    clearTransferInputs();
  }

  const handleWarmTransferFailed = (message: string) => {
    warmTransferFailed(message);
    ongoingCall();
    setOngoingCallOperation();
  }

  const handleWarmTransferStartStage2 = (response: any) => {
    clearTransferInputs();
  }

  const clearTransferInputs = () => {
    setWarmTransferInputValue('');
    setColdTransferInputValue('');
  }

  const warmTransferFailed = (message: string) => {
    logging.errorHandler.next(message);
    setCancelWarmTransferVisible(false);
    setResumeCallVisible(true);
  }

  const setWarmTransferStarted = () => {
    const isCancelButtonVisible = !EnvHelper.isStage3() || currentCallSession.LastInvitedParticipantId !== '';
    setIsInvalidTransfer(false);
    setOperation(`${intl.formatMessage({ id: "CallActions.Ringing3dParty" })} ${!EnvHelper.isStage3() ? transferTo.current.replace('sip:', '') : transferToNameStage3.current}`);
    socketAudio.callActionViewIsDisplayed.next(true);
    setCancelWarmTransferVisible(isCancelButtonVisible);
    setPauseCallVisible(false);
    setResumeCallVisible(false);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);
    setEndCallVisible(false);
    setParkCallVisible(false);
  };

  const setWarmTransferInProgress = () => {
    setIsInvalidTransfer(false);
    socketAudio.callActionViewIsDisplayed.next(true);
    setCancelWarmTransferVisible(true);
    setPauseCallVisible(false);
    setResumeCallVisible(false);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);
    setEndCallVisible(false);
  };

  const setWarmTransferAccepted = () => {
    setPauseCallVisible(false);
    setResumeCallVisible(false);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);
    setEndCallVisible(false);
    setWarmTransferConsultCallerVisible(true);
    setFinalizeWarmTransferVisible(true);
    setCancelWarmTransferVisible(true);
    setOperation(`${intl.formatMessage({ id: "CallActions.Transferring" })} ${(!EnvHelper.isStage3() ? transferTo.current.replace('sip:', '') : transferToNameStage3.current)}`);
    socketAudio.callActionViewIsDisplayed.next(true);
  };

  const setWarmTransferCanceled = () => {
    setCancelWarmTransferVisible(false);
    setWarmTransferConsultAgentVisible(false);
    setWarmTransferConsultCallerVisible(false);
    setFinalizeWarmTransferVisible(false);
    setResumeCallVisible(true);
    setPauseCallVisible(false);
    setEndCallVisible(true);
    setColdTransferVisible(true);
    setWarmTransferVisible(true);

    setOperation(intl.formatMessage({ id: "CallActions.OnHold" }));
    socketAudio.callActionViewIsDisplayed.next(true);
  };

  const warmTransferCancel = () => {
    if (!EnvHelper.isStage3()) {
      setWarmTransferCanceled();
    }
    const requestData = EnvHelper.isStage3() ? getStage3CallSessionRequestObject() : transferRequest;
    socketTransfer.warmTransferCancel(requestData);
  }

  const warmTransferConsultCaller = () => {
    const requestData = EnvHelper.isStage3() ? getStage3CallSessionRequestObject() : callSessionRequest;
    setWarmTransferConsultCaller();

    socketTransfer.warmTransferConsultCaller(requestData);
  }

  const setWarmTransferConsultCaller = () => {
    setPauseCallVisible(false);
    setResumeCallVisible(false);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);
    setEndCallVisible(false);
    setWarmTransferConsultCallerVisible(false);
    setWarmTransferConsultAgentVisible(true);
    setFinalizeWarmTransferVisible(true);
    setCancelWarmTransferVisible(true);

    setOperation(`${intl.formatMessage({ id: "CallActions.ConsultCaller" })} ${(!EnvHelper.isStage3() ? callerUri.current.replace('sip:', '') : callerName.current)}`);
    socketAudio.callActionViewIsDisplayed.next(true);
  }

  const warmTransferConsultAgent = () => {
    const requestData = EnvHelper.isStage3() ? getStage3CallSessionRequestObject() : callSessionRequest;
    setWarmTransferConsultAgent();

    socketTransfer.warmTransferConsultAgent(requestData);
  }

  const setWarmTransferConsultAgent = () => {
    setPauseCallVisible(false);
    setResumeCallVisible(false);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);
    setEndCallVisible(false);
    setFinalizeWarmTransferVisible(true);
    setCancelWarmTransferVisible(true);
    setWarmTransferConsultCallerVisible(true);
    setWarmTransferConsultAgentVisible(false);

    setOperation(`${intl.formatMessage({ id: "CallActions.ConsultAgent" })} ${(!EnvHelper.isStage3() ? transferTo.current.replace('sip:', '') : transferToNameStage3.current)}`);
    socketAudio.callActionViewIsDisplayed.next(true);
  }

  const warmTransferFinalize = () => {
    logging.errorHandler.next("ErrorMessage.Transfer.WarmTransferPerformed");

    if (EnvHelper.isStage3()) {
      const requestData = getStage3CallSessionRequestObject();
      socketTransfer.warmTransferFinalize(requestData);
    }
    else {
      socketAudio.ejectFromMeeting(callSessionRequest);
    }

    endCall();
  }

  const hangUp = () => {
    if (!EnvHelper.isStage3()) {
      const callerUriSip = callerUri.current; //will be cleared on ejectFromMeeting;

      // eject agent
      socketAudio.ejectFromMeeting(callSessionRequest);
      // eject caller
      callSessionRequest.SourceUri = callerUriSip;
      socketAudio.ejectFromMeeting(callSessionRequest);
    } else {
      const stage3RequestData = getStage3CallSessionRequestObject();
      socketAudio.hangUp(stage3RequestData);
    }

    endCall();
  };

  const rejectCall = () => {
    const stage3RequestData = getStage3CallSessionRequestObject();
    socketAudio.rejectCall(stage3RequestData);

    endCall();
  }

  const terminateMonitoring = () => {
    //socketAudio.terminateSupervisorMonitoring(startMonitoringRequestDTO);
    // eject supervisor
    socketAudio.ejectFromMeeting(callSessionRequest);
    endCall();
  };

  const switchToMonitoringAction = (monitorType: MonitorTypes) => {
    const startMonitoringRequestDTO = {
      SessionID: callSessionRequest.SessionId,
      AgentSip: Lockr.get(StorageKeys.SIP),
      MonitorType: monitorType
    }
    socketAudio.switchToMonitoringAction(startMonitoringRequestDTO);
  };

  const parkCall = () => {
    socketAudio.parkCall(callSessionRequest);

    endCall();
  }

  const toggleRecording = () => {
    setRecordCallVisible(!recordCallVisible);
    setStopRecordCallVisible(!stopRecordCallVisible);

    socketTransfer.toggleRecording(callSessionRequest);
  }

  const outboundRinging = (callSessionDTO: CallSessionDTO) => {
    setEndCallVisible(true);
    setCallStartTime(callSessionDTO.CallStartDate);

    setPauseCallVisible(false);
    setResumeCallVisible(false);

    setParkCallVisible(false);

    setFinalizeWarmTransferVisible(false);
    setWarmTransferConsultAgentVisible(false);
    setWarmTransferConsultCallerVisible(false);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);

    if (callSessionDTO.IsOperatorCall) {
      setOngoingCallOperation();
    } else {
      setOutboundCallOperation();
    }
    socketAudio.callActionViewIsDisplayed.next(true);

    setCallSession(callSessionDTO);
  }

  const outboundStage3 = (callSessionDTO: CallSessionDTO) => {
    if (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Ringing] && callSessionDTO.IsColdTransferInProgress) {
      //cold transfer second agent ringing
      outboundRinging(callSessionDTO);
    } else {
      setCallStartTime(callSessionDTO.CallStartDate);
      if (!callSessionDTO.IsWarmTransferInProgress) {
        setOutboundCallOperation();
      }
    }
    socketAudio.callActionViewIsDisplayed.next(true);

    setCallSession(callSessionDTO);
  }

  const outboundStage2 = (callSessionDTO: CallSessionDTO) => {
    setEndCallVisible(true);
    setCallStartTime(callSessionDTO.CallStartDate);
    setPauseCallVisible(!callSessionDTO.IsCallerOnHold);
    setResumeCallVisible(callSessionDTO.IsCallerOnHold);

    setParkCallVisible(false);
    setFinalizeWarmTransferVisible(false);
    setWarmTransferConsultAgentVisible(false);
    setWarmTransferConsultCallerVisible(false);
    setColdTransferVisible(true);
    setWarmTransferVisible(true);

    setOutboundCallOperation();
    socketAudio.callActionViewIsDisplayed.next(true);

    setCallSession(callSessionDTO);
  }

  const outboundCall = (callSessionDTO: CallSessionDTO) => {
    if (EnvHelper.isStage3()) {
      outboundStage3(callSessionDTO);
    } else {
      outboundStage2(callSessionDTO);
    }
  }

  const onPasteInputTransfer = (value: string, isWarm: boolean) => {
    const formatedValue = GeneralHelper.formatSipOrPhoneNumber(value.trim());
    skipInputTransferChangeEvent.current = true;
    checkTransferTo(formatedValue);
    isWarm ? setWarmTransferInputValue(formatedValue) : setColdTransferInputValue(formatedValue);
  }

  const onChangeInputTransfer = (value: string, isWarm: boolean) => {
    if (!skipInputTransferChangeEvent.current) {
      checkTransferTo(value);
      isWarm ? setWarmTransferInputValue(value) : setColdTransferInputValue(value)
    }
    else {
      skipInputTransferChangeEvent.current = false;
    }
  }

  const onBlurInputTransfer = (value: string, isWarm: boolean) => {
    const formatedValue = GeneralHelper.formatSipOrPhoneNumber(value.trim());
    checkTransferTo(formatedValue);
    isWarm ? setWarmTransferInputValue(formatedValue) : setColdTransferInputValue(formatedValue);
  }

  const answerCallButton = () => {
    return <Button
      icon={<CallIcon size="large" />}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.Answer' })}
      styles={stylesAnswer} />;
  }

  const rejectCallButton = () => {
    return <Button
      icon={<CallEndIcon size="large" />}
      onClick={rejectCall}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.Reject' })}
      styles={styles} />;
  }
  const recordCallButton = () => {
    return <Button
      icon={<CallRecordingIcon size="large" />}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.StartRecord' })}
      onClick={toggleRecording}
      styles={stylesFaded} />;
  }
  const stopRecordCallButton = () => {
    return <Button className="stop-recording-button"
      icon={<div className="stop-recording-icon"></div>}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.StopRecord' })}
      onClick={toggleRecording}
      styles={stylesFaded} />;
  }
  const pauseCallButton = () => {
    return <Button
      icon={<PauseThickIcon size="large" />}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.Pause' })}
      onClick={EnvHelper.isStage3() ? () => setPauseActionCall(true) : pauseCall}
      styles={stylesFaded} />;
  }
  const resumeCallButton = () => {
    return <Button
      icon={<PlayIcon size="large" />}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.Resume' })}
      onClick={EnvHelper.isStage3() ? () => setPauseActionCall(false) : resumeCall}
      styles={stylesFaded} />;
  }
  const parkCallButton = () => {
    return <Button
      icon={<CallParkingIcon size="large" />}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.Park' })}
      styles={stylesFaded}
      onClick={parkCall} />;
  }
  const warmTransferPopupButton = () => {
    return <Popup
      trapFocus
      trigger={<Button
        icon={<UserFriendsIcon size="large" />}
        iconOnly
        text
        title={intl.formatMessage({ id: 'CallActions.WarmTransfer' })}
        styles={stylesFaded}
        onClick={() => setIsInvalidTransfer(false)} />}
      content={
        <Flex gap="gap.smaller"
          styles={{
            padding: '10px',
          }}>
          <Input maxLength={50} required fluid inverted placeholder={intl.formatMessage({ id: 'AgentView.FindContact' })}
            styles={{
              width: '250px',
            }}
            value={warmTransferInputValue}
            onChange={(e) => onChangeInputTransfer((e.target as HTMLInputElement).value, true)}
            onBlur={(e) => onBlurInputTransfer(e.target.value, true)}
            onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => { onPasteInputTransfer(e.clipboardData.getData("Text"), true) }}
            onKeyPress={(e) => handleWarmKeyPress(e)}
            showSuccessIndicator={false}
            error={isInvalidTransfer}
          />

          <Button circular icon={<UserFriendsIcon />} primary
            size="medium"
            title={intl.formatMessage({ id: 'CallActions.WarmTransfer' })}
            onClick={handleWarmTransfer}
          />
        </Flex>
      }
    />;
  }
  const coldTransferPopupButton = () => {
    return <Popup
      trapFocus
      trigger={<Button
        icon={<PhoneArrowIcon size="large" />}
        iconOnly
        text
        title={intl.formatMessage({ id: 'CallActions.ColdTransfer' })}
        styles={stylesFaded}
        onClick={() => setIsInvalidTransfer(false)} />}
      content={
        <Flex gap="gap.smaller"
          styles={{
            padding: '10px',
          }}>
          <Input maxLength={50} required fluid inverted placeholder={intl.formatMessage({ id: 'AgentView.FindContact' })}
            styles={{
              width: '250px',
            }}
            value={coldTransferInputValue}
            onChange={(e) => onChangeInputTransfer((e.target as HTMLInputElement).value, false)}
            onBlur={(e) => onBlurInputTransfer(e.target.value, false)}
            onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => { onPasteInputTransfer(e.clipboardData.getData("Text"), false) }}
            onKeyPress={(e) => handleColdTransferKeyPressFromTheCallActionPopup(e)}
            showSuccessIndicator={false}
            error={isInvalidTransfer}
          />

          <Button circular icon={<PhoneArrowIcon />} primary
            size="medium"
            title={intl.formatMessage({ id: 'CallActions.ColdTransfer' })}
            onClick={handleColdTransferClickFromTheCallActionPopup}
          />
        </Flex>
      }
    />;
  }
  const warmTransferConsultCallerButton = () => {
    return <Button
      icon={<ExpandIcon size="large" />}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.ConsultCaller' })}
      onClick={warmTransferConsultCaller}
      styles={stylesFaded} />;
  }
  const warmTransferConsultAgentButton = () => {
    return <Button
      icon={<ExpandIcon size="large" />}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.ConsultAgent' })}
      onClick={warmTransferConsultAgent}
      styles={stylesFaded} />;
  }
  const finalizeWarmTransferButton = () => {
    return <Button
      icon={<PhoneArrowIcon size="large" />}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.FinalizeWarmTransfer' })}
      onClick={warmTransferFinalize}
      styles={stylesAnswer} />;
  }
  const warmTransferCancelButton = () => {
    return <Button
      icon={<ParticipantRemoveIcon size="large" />}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.Ignore' })}
      onClick={warmTransferCancel}
      styles={styles} />;
  }
  const endCallButton = () => {
    return <Button
      onClick={hangUp}
      icon={<CallEndIcon size="large" />}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.Close' })}
      styles={styles} />;
  }

  const terminateMonitoringButton = () => {
    return <Button
      onClick={terminateMonitoring}
      icon={<CallEndIcon size="large" />}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.TerminateMonitoring' })}
      styles={styles} />;
  }
  const switchToListenButton = () => {
    return <Button
      disabled={listenButtonDisabled}
      onClick={() => switchToMonitoringAction(MonitorTypes.Silent)}
      icon={<img alt='Listen' className='ear-icon-call-action' />}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.Listen' })}
      styles={stylesFaded} />;
  }
  const switchToBargeInButton = () => {
    return <Button
      disabled={bargeInButtonDisabled}
      onClick={() => switchToMonitoringAction(MonitorTypes.BargeIn)}
      icon={<AttendeeIcon size="large" />}
      iconOnly
      text
      title={intl.formatMessage({ id: 'CallActions.BargeIn' })}
      styles={stylesFaded} />;
  }

  const getStage3CallSessionRequestObject = () => {
    const request = {
      AgentRef: callSessionRequest.AgentRef,
      CallId: callSessionRequest.CallId,
      TenantId: callSessionRequest.TenantId,
      QueueId: callSessionRequest.QueueRef,
      ScenarioId: callSessionRequest.ScenarioId,
      AzureTenantId: callSessionRequest.AzureTenantId,
      TransferTarget: '',
      TargetName: '',
      TransferTargetUpn: ''
    };

    if (transferTo.current) {
      request.TransferTarget = ValidationHelper.removeSipIfInputContainsIt(transferTo.current);
      request.TargetName = callSessionRequest.SecondaryAgentDisplayName || '';
      request.TransferTargetUpn = callSessionRequest.SecondaryAgentUpn || '';
    }

    return request;
  }

  if (!operation) {
    return null;
  }
  return <>
    {
      isOngoingCallSelected && <Flex></Flex>
    }
    {isOngoingCallSelected && <Flex vAlign="center" gap="gap.large">
      <Text content={operation} />
      {callStartTime && <Timer startDate={callStartTime}></Timer>}

      {/* <Sound
        url={notificationSound}
        playStatus={notificationSoundStatus}
        onFinishedPlaying={finishNotificationSoundPlaying}
        loop={false}
        volume={100}
      /> */}
      <Flex>
        {answerCallVisible ? answerCallButton() : null}
        {rejectCallVisible ? rejectCallButton() : null}
        {recordCallVisible ? recordCallButton() : null}
        {stopRecordCallVisible ? stopRecordCallButton() : null}
        {pauseCallVisible ? pauseCallButton() : null}
        {resumeCallVisible ? resumeCallButton() : null}
        {!EnvHelper.isStage3() && parkCallVisible ? parkCallButton() : null}
        {warmTransferVisible ? warmTransferPopupButton() : null}
        {coldTransferVisible ? coldTransferPopupButton() : null}
        {warmTransferConsultCallerVisible ? warmTransferConsultCallerButton() : null}
        {warmTransferConsultAgentVisible ? warmTransferConsultAgentButton() : null}
        {finalizeWarmTransferVisible ? finalizeWarmTransferButton() : null}
        {warmTransferCancelVisible ? warmTransferCancelButton() : null}
        {endCallVisible ? endCallButton() : null}
        {monitoringButtonsVisible ? switchToListenButton() : null}
        {monitoringButtonsVisible ? switchToBargeInButton() : null}
        {monitoringButtonsVisible ? terminateMonitoringButton() : null}
      </Flex>
    </Flex>
    }
  </>
};

export default CallActions;

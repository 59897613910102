import Lockr from 'lockr';
import { logging } from 'utils/logging';
import { ConfigurationHelper, GeneralHelper } from 'utils/helpers';
import { CustomerJourneyOperation, HttpMethod, ResponseWrapper, StorageKeys } from 'utils';
import { serviceCall } from './service-call';
import { InvokeServiceArgs } from './interfaces';
import { BehaviorSubject, Subject } from 'rxjs';
import { CustomerDTO, CustomerIdentificationDTO, CustomerJourneyNoteDTO, IJourneyCustomerSessionDTO, PostJourneyCustomerAndSessionDTO } from 'utils/domain/customerJourneyModels';
import { ApiCustomer } from 'containers/CustomerJourneyView';
import { CallSessionDTO } from 'utils/domain/callSessionDTO';

/**
 * The CustomerJourney API class. This class manages all calls from and to the Customer Journey API backend.
 * This is a singleton class.
 */
export class CustomerJourneyManager implements ApiCustomer {
    private static instance: CustomerJourneyManager;
    private readonly logger = logging.getLogger('CustomerJourneyManager');

    private readonly listenerCurrentUserCallSessionStateChanged: any = null;

    currentCustomer: BehaviorSubject<CustomerDTO> = new BehaviorSubject<CustomerDTO>(new CustomerDTO());
    currentCallSession: BehaviorSubject<CallSessionDTO | undefined> = new BehaviorSubject<CallSessionDTO | undefined>(undefined);
    currentConversation: BehaviorSubject<IJourneyCustomerSessionDTO> = new BehaviorSubject<IJourneyCustomerSessionDTO>(({} as any) as IJourneyCustomerSessionDTO);
    selectedSearchResult: Subject<CustomerDTO> = new Subject<CustomerDTO>();
    onNoteAdded: Subject<CustomerJourneyNoteDTO> = new Subject<CustomerJourneyNoteDTO>();

    public static getInstance() {
        if (!CustomerJourneyManager.instance) {
            CustomerJourneyManager.instance = new CustomerJourneyManager();
        }

        return CustomerJourneyManager.instance;
    }

    /**
     * This method retrieves an array of CustomerDTO objects from the API by GET method matching the name (and surname) given.
     * @param data The customer's name. Could be either only first name or first name and surname. If the value has both the
     * first name and the surname it should be in that exact order.
     * @returns Returns an array of CustomerDTO objects matching the data value. Returns an empty array in case of 0 matches.
     */
    getCustomersByName(data: any): Promise<CustomerDTO[]> {
        data.companyRef = Lockr.get<string>(StorageKeys.CompanyId);

        return this.invokeServiceCall(data, CustomerJourneyOperation.GetCustomersByName, '', HttpMethod.Get);
    }

    /**
     * Tries to retrieve CustomerDTO objects from the API by GET method matching the given one or more Customer Id's.
     * @param data one or more ids. Could be one number, or a string of multiple numbers seperated by a comma.
     * @returns Retrieves an array of CustomerDTO objects matching the given data value. Returns an empty array in case of 0 matches.
     */
    getCustomerById(data: any): Promise<CustomerDTO[]> {
        data.companyRef = Lockr.get<string>(StorageKeys.CompanyId);

        return this.invokeServiceCall(data, CustomerJourneyOperation.GetCustomer, '', HttpMethod.Get);
    }

    /**
     * Invokes a POST API method to try and save the given customer object.
     * @param customer The CustomerDTO object to save to the persistence layer.
     * @returns API Response message.
     */
    saveCustomer(customer: CustomerDTO): Promise<ResponseWrapper> {
        return this.invokeServiceCall(customer, CustomerJourneyOperation.SaveCustomer, '', HttpMethod.Post);
    }

    saveCustomerAndSession(customer: CustomerDTO, customerSession: IJourneyCustomerSessionDTO): Promise<PostJourneyCustomerAndSessionDTO> {
        const callData: any = {journeyCustomerDTO: customer, journeyCustomerSession: customerSession};
        callData.companyRef = Lockr.get<string>(StorageKeys.CompanyId);
        return this.invokeServiceCall(callData, CustomerJourneyOperation.PostCustomerAndSession, '', HttpMethod.Post);
    }

    // /**
    //  * Invokes a POST API method to try and update an existing customer object.
    //  * @param customer The CustomerDTO object to update to the persistense layer.
    //  * @returns API Response message.
    //  */
    // updateCustomer(customer: CustomerDTO): Promise<ResponseWrapper> {
    //     return this.invokeServiceCall(customer, CustomerJourneyOperation.UpdateCustomer, CustomerJourneyController.Customers, HttpMethod.Post);
    // }

    /**
     * Invokes a GET API method to fetch a list of IJourneyCustomerSessionDTO objects.
     * @param data Following data is required: customer.CompanyRef, the customerId, a startIndex (used to fetch a certain list, acts as a starting point in the list.) and a number as pageSize.
     * @returns An array of IJourneyCustomerSessionDTO objects.
     */
    getConversationHistoryByCustomer(data: any): Promise<IJourneyCustomerSessionDTO[]> {
        data.companyRef = Lockr.get<string>(StorageKeys.CompanyId);

        return this.invokeServiceCall(data, CustomerJourneyOperation.GetCustomerSession, '', HttpMethod.Get);
    }

    /**
     * Invoking a GET API call to try and identify any and all customer records by the caller URI from the CallSessionDTO.
     * @param data CallSessionDTO.CallerURI
     * @returns CustomerIdentificationDTO object with zero or more customer Ids in an array.
     */
    identifyCaller(data: any): Promise<CustomerIdentificationDTO> {
        data.companyRef = Lockr.get<string>(StorageKeys.CompanyId);

        return this.invokeServiceCall(data, CustomerJourneyOperation.IdentifyCaller, '', HttpMethod.Get);
    }

    /**
     * This API method is used to connect a selected customer to the current conversation so that the backend knows that the conversation the 
     * Customer Agent is currently having is with the given customer.
     * @param customerSession 
     * @returns API response message.
     */
    postCustomerSession(customerSession: IJourneyCustomerSessionDTO): Promise<IJourneyCustomerSessionDTO> {
        return this.invokeServiceCall(customerSession, CustomerJourneyOperation.PostCustomerSession, '', HttpMethod.Post);
    }

    sendConversationNote(note: CustomerJourneyNoteDTO): Promise<CustomerJourneyNoteDTO> {
        return this.invokeServiceCall(note, CustomerJourneyOperation.PostConversationNote, '', HttpMethod.Post);
    }

    getConversationNotesByConversation(SessionId: string): Promise<CustomerJourneyNoteDTO[]> {
        const companyRef = Lockr.get<string>(StorageKeys.CompanyId);
        return this.invokeServiceCall({companyRef: companyRef, sessionId: SessionId}, CustomerJourneyOperation.GetConversationNotes, '', HttpMethod.Get);
    }

    getDateTimeFormatted(dateTimeString: string): string {
        let result = '';
        if (!dateTimeString) {
            return result;
        }

        try {
            const dateFormatted = new Date(dateTimeString);
            result = `${dateFormatted.toDateString()} at ${dateFormatted.toLocaleTimeString()}`;
        } catch (err) {
            return result;
        }
        return result;
    }

    /**
     * The main method to invoke an API call
     * @param data The variables passed through the API method.
     * @param operation The type of operation, used to construct the url.
     * @param controller The controller of the API method, used to construct the url.
     * @param httpMethod The API method type. IE. GET, POST etc.
     * @returns 
     */
    invokeServiceCall(data: any, operation: string, controller: string, httpMethod: string): Promise<any> {

        if (controller == '') {
            return GeneralHelper.invokeServiceCall(data, operation, this.logger);
        }
        return new Promise((resolve, reject) => {
            const timeoutId = setTimeout(() => {
                reject(new Error("promise timeout"))
            }, (15 * 1000));
            const args: InvokeServiceArgs = {
                operation: operation,
                controller: controller,
                httpMethod: httpMethod,
                fetchApiUrl: ConfigurationHelper.customerJourneyApiUrl,
                requestData: data,
                responseHandler: {
                    success: (result: any) => {
                        resolve(result.data);
                        clearTimeout(timeoutId);
                    },
                    error: (err: any) => {
                        logging.errorHandler.next("ErrorMessage.Offline");
                        this.logger.error(err);
                        reject(err);
                        clearTimeout(timeoutId);
                    }
                }
            };

            serviceCall.invokeApiService(args);
        });
    }
}

export const customerJourneyManager = CustomerJourneyManager.getInstance();
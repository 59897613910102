import { logging } from 'utils';
import { incomingRequests, socketManager } from ".";
import { Subscription } from 'rxjs';
import { EnvHelper, GeneralHelper } from 'utils/helpers';

export class Messaging {
  private readonly logger = logging.getLogger('SocketManager');
  private static readonly investigateMultipleSubscribe: boolean = false;
  private subscriptionSocketManagerOnInitialized: Subscription | null = null;
  private subscriptionIncomingRequestReceived: Subscription | null = null;
  initialize() {
    this.subscriptionSocketManagerOnInitialized?.unsubscribe();
    this.subscriptionSocketManagerOnInitialized = socketManager.onInitialized.subscribe((obj: any) => {
      this.subscriptionIncomingRequestReceived?.unsubscribe();
      if (Messaging.investigateMultipleSubscribe) {//#warning-js [cc4all-1102]: this is not working, having multiple loggings
        this.subscriptionIncomingRequestReceived = incomingRequests.received.subscribe((incomingRequestObj: any) => {
          GeneralHelper.logCox(`in messaging.ts, in subscriptionIncomingRequestReceived, received incoming request from subscription:${JSON.stringify(incomingRequestObj)}`);
        });
      }
    });

    if (!EnvHelper.isStage3()) {
      socketManager.initialize();
    }
  }
}

export const messaging = new Messaging();

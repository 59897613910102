import { Flex } from "@fluentui/react-northstar";
import React, { useState } from "react";
import SupervisorMediaView from "./SupervisorMediaView/SupervisorMediaView";
import WaitingMediaView from "./WaitingMediaView/WaitingMediaView";
import { useSubjectListenerEffect } from "utils/helpers/listenerHook";
import { GenericOpenUrlInfo, genericListService } from "containers/GenericView/ConnectionListView/GenericListService";
import { openPopup } from "utils/helpers/popup-helper";
import EmailEditor from "components/EmailEditor/EmailEditor";
import useKeyCombination from 'utils/domain/hooks/useKeyCombination';
import ModifierKeys from 'utils/domain/hooks/ModifierKeys';

const AgentView = () => {
    const isUserSupervisor = false;
    const isSupervisorCheckDone = true;
    const [iframeSrc, setIframeSrc] = useState<string>();
    const [showEmailEditor, setShowEmailEditor] = useState<boolean>(false);

    useKeyCombination([ModifierKeys.Alt, ModifierKeys.Control], ['m'], () => {
        setShowEmailEditor(true);
    });

    useSubjectListenerEffect((generic: GenericOpenUrlInfo | null) => {
        if (!generic) {
            setIframeSrc('');
            return;
        }

        if (generic.isIframe) {
            setIframeSrc(generic.externalUrl);
        } else {
            openPopup(generic.externalUrl);
            setIframeSrc('');
        }
    }, genericListService.genericOpenUrlSubject);

    const mediaView = () => (isUserSupervisor
        ? <SupervisorMediaView />
        : <WaitingMediaView />
    );

    return (<Flex.Item>
        <Flex column>
            { 
                isSupervisorCheckDone && mediaView() 
            }
            {
                !!iframeSrc && <iframe key={iframeSrc} style={{height: '700px'}} src={iframeSrc} allow="true" width="100%" height="100%" title="Generic Url" />
            }
            {
                showEmailEditor && <EmailEditor
                    showSubject
                    to={[{id: "1", name: "Simion Schiopu", email: "simion.schiopu@expertnetwork.ro"}]}
                    onClose={() => setShowEmailEditor(false)}
                />
            }
        </Flex>
    </Flex.Item>);
}

export default AgentView;
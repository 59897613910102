import React, { useState } from "react";
import {
  MenuButton,
  Button,
  SettingsIcon,
  Text,
  Flex,
  Dialog,
  CloseIcon,
  InfoIcon,
  ArrowSyncIcon,
} from "@fluentui/react-northstar";
import { useIntl } from "react-intl";
import { socketSettings } from "../../services/settings";
import { EnvHelper } from "../../utils/helpers";
import "./settingsView.scss";
import { Subscription } from "rxjs";
import { initialClientLoadService } from "services/initialClientLoad";
import { TeamsClientInitSettingsDto } from "utils/domain/extended/teamsClientInitSettingsDto";

export const SettingsView = () => {
  const [serverIp, setServerIp] = useState("");
  const [webPortalFQDN, setWebPortalFQDN] = useState("");
  const [callCenterVersion, setCallCenterVersion] = useState("");
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [settingsItems, setSettingsItems] = useState([] as any[]);
  const intl = useIntl();
  let subscriptionInitialSettingsRequestCompleted: Subscription | null = null;

  React.useEffect(() => {
    initialize();

    return () => componentWillUnmount();
  }, []);

  const componentWillUnmount = () => {
    subscriptionInitialSettingsRequestCompleted?.unsubscribe();
  };

  const initialize = () => {
    if (EnvHelper.isStage3()) {
      setWebPortalFQDN((window as any)["config"].adminUrl);
      setCallCenterVersion((window as any)["config"].version);

      subscriptionInitialSettingsRequestCompleted =
        initialClientLoadService.notifyInitialSettingsRequestCompleted.subscribe(
          (settings: TeamsClientInitSettingsDto) => {
            const items = [
              {
                content: (
                  <Flex gap="gap.small" vAlign="center">
                    <InfoIcon />
                    <Text content={getAboutText()} />
                  </Flex>
                ),
                onClick: () => {
                  setOpenInfoDialog(true);
                },
                key: getAboutText(),
              },
            ];

            if (settings.SwitchClientEnabled) {
              items.unshift({
                content: (
                  <Flex gap="gap.small" vAlign="center">
                    <ArrowSyncIcon />
                    <Text
                      content={intl.formatMessage({
                        id: "SettingsView.ClientSwitch",
                      })}
                    />
                  </Flex>
                ),
                onClick: () => switchClient(),
                key: "clientSwitch",
              });
            }

            setSettingsItems(items);
          }
        );

      return;
    }

    socketSettings.getCallCenterInfo().then((result: any) => {
      setServerIp(result.CC4LServerIP);
      setWebPortalFQDN(result.WebPortalFQDN);
      setCallCenterVersion(result.CallCenterVersion);
    });
  };

  const getAboutText = () => {
    return intl.formatMessage({ id: "SettingsView.About" });
  };

  const switchClient = () => {
    document.cookie =
      "preview=always; max-age=60480000; SameSite=None; Secure; Partitioned;";
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <Flex vAlign="center">
      <Dialog
        open={openInfoDialog}
        onOpen={() => setOpenInfoDialog(true)}
        onCancel={() => setOpenInfoDialog(false)}
        onConfirm={() => setOpenInfoDialog(false)}
        className="dialog-box"
        content={
          <Flex column className="settings-informations">
            {serverIp ? (
              <Flex gap="gap.small">
                <Text
                  content={intl.formatMessage({ id: "ServerInfo.ServerIp" })}
                  weight="bold"
                />
                <Text content={serverIp} />
              </Flex>
            ) : (
              ""
            )}
            {webPortalFQDN ? (
              <Flex gap="gap.small">
                <Text
                  content={intl.formatMessage({
                    id: "ServerInfo.WebPortalFQDN",
                  })}
                  weight="bold"
                />
                <a
                  href={webPortalFQDN}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {webPortalFQDN}
                </a>
              </Flex>
            ) : (
              ""
            )}
            {callCenterVersion ? (
              <Flex gap="gap.small">
                <Text
                  content={intl.formatMessage({
                    id: "ServerInfo.CallCenterVersion",
                  })}
                  weight="bold"
                />
                <Text content={callCenterVersion} />
              </Flex>
            ) : (
              ""
            )}
          </Flex>
        }
        header={intl.formatMessage({ id: "ServerInfo.DialogHeader" })}
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => setOpenInfoDialog(false),
        }}
      />
      <MenuButton
        align="end"
        trigger={
          <Button
            icon={
              <SettingsIcon
                size="large"
                title={intl.formatMessage({ id: "SettingsView.Settings" })}
              />
            }
            text
            iconOnly
          />
        }
        menu={settingsItems}
        on="click"
      />
    </Flex>
  );
};

export default SettingsView;

export class TransferWebchatToAgentRequest {
    sessionRef!: string;
    fromAgentSIP!: string;
    targetAgentSIP!: string;
    message!: string;
}

export class TransferWebchatToQueueRequest {
    sessionRef!: string;
    fromAgentSIP!: string;
    targetQueueRef!: number;
    message!: string;
}

export class TransferTargetsRequest {
    companyId!: number;
    userId!: number;
    searchTerm!: string;
}

export const transferWebchatToAgentRequest = new TransferWebchatToAgentRequest();
export const transferWebchatToQueueRequest = new TransferWebchatToQueueRequest();
export const transferTargetsRequest = new TransferTargetsRequest();

import React, { useRef, useState } from 'react';
import { Flex, List } from '@fluentui/react-northstar';
import CallView from '../../containers/CallView';
import ManagementView from '../../containers/ManagementView';
import MediaView from '../../containers/MediaView';
import WebChatView from '../../containers/WebChatView';
import CustomerJourneyView from '../../containers/CustomerJourneyView';
import ErrorView from '../../containers/ErrorView';
import { RealtimeReportsView } from '../../containers';
import { logging } from '../../utils/logging';
import { serviceCall } from '../../services/io/service-call';
import { useIntl } from 'react-intl';
import './mainView.scss';
import { socketAuth } from '../../services';
import { socketAudio } from '../../services/calling';
import { mainViewHelper } from '../../utils/helpers/main-view-helper';
import { ActiveMainViewItem, ErrorColor } from '../../utils';
import VoiceMailsView from '../../containers/VoiceMailsView';
import { webChatManager } from '../../services/io/web-chat';
import { socketVoicemails } from '../../services/voiceMails';
import { BotNotificationDataDTO } from '../../utils/domain/botNotificationDataDTO';
import { Subscription } from 'rxjs';
import { socketOutbound } from '../../services/outbound';
import { GeneralHelper } from '../../utils/helpers';
import { socketSocialMedia } from 'services/social-media';
import { StorageKeys } from "utils";
import Lockr from "lockr";

export const MainView = () => {
  const intl = useIntl();
  var result: any[] = [];
  const errorContent = useRef(result);
  const [reference, setReference] = useState(Math.random() as any);
  const [activeViewItem, setActiveViewItem] = useState(ActiveMainViewItem.MediaView);
  const [activeWebChatSessionId, setActiveWebChatSessionId] = useState("");
  const [activeCmSessionId, setActiveCmSessionId] = useState("");

  const [voicemailNotificationData, setVoicemailNotificationData] = useState<BotNotificationDataDTO>();

  let subscriptionServiceCallOnInitialized: Subscription | null = null;
  let subscriptionViewChanged: Subscription | null = null;
  let subscriptionWebchatNotificationReceived: Subscription | null = null;
  let subscriptionVoiceMailNotificationReceived: Subscription | null = null;
  let subscriptionCurrentCmSessionId: Subscription | null = null;
  let subscriptionLoggingErrorHandler: Subscription | null = null;

  React.useEffect(() => {
    initialize();

    return () => {
      componentWillUnmount();
    }
  }, []);

  const componentWillUnmount = () => {
    subscriptionServiceCallOnInitialized?.unsubscribe();
    subscriptionViewChanged?.unsubscribe();
    subscriptionWebchatNotificationReceived?.unsubscribe();
    subscriptionVoiceMailNotificationReceived?.unsubscribe();
    subscriptionCurrentCmSessionId?.unsubscribe();
    subscriptionLoggingErrorHandler?.unsubscribe();
  }

  const removeError = (value: string) => {
    const errorContentWithRemovedItem = errorContent.current.filter(x => x.content.props.content !== value);
    setTimeout(() => {
      errorContent.current = errorContentWithRemovedItem;
      GeneralHelper.logCox(`reference was ${reference}`);
      setReference(Math.random());
      GeneralHelper.logCox(`reference is ${reference}`);
      clearTimeout(0);
    }, 0);
  }

  const initialize = () => {
    GeneralHelper.logCox(`in MainView.tsx, in initialize`);

    if (!serviceCall.isDisconnected) {
      socketAuth.userVerified.next(true);
      socketAudio.getCurrentCallSession();
    }

    subscriptionServiceCallOnInitialized?.unsubscribe();
    subscriptionServiceCallOnInitialized = serviceCall.onInitialized.subscribe(() => {
      socketAuth.userVerified.next(true);
      socketAudio.getCurrentCallSession();
    });

    subscriptionViewChanged?.unsubscribe();
    subscriptionViewChanged = mainViewHelper.viewChanged.subscribe((item: ActiveMainViewItem) => {
      if (item === ActiveMainViewItem.MediaView || item === ActiveMainViewItem.SocialMedia) {
        socketOutbound.isAgentOutboundAccessEnabled();
      }

      setActiveWebChatSessionId("");
      setActiveViewItem(item);
    });

    subscriptionWebchatNotificationReceived?.unsubscribe();
    subscriptionWebchatNotificationReceived = webChatManager.webChatNotificationReceivedFromBot.subscribe((webchatSessionId: string) => {
      setActiveWebChatSessionId(webchatSessionId);
      setActiveViewItem(ActiveMainViewItem.WebChatView);
    });

    subscriptionVoiceMailNotificationReceived?.unsubscribe();
    subscriptionVoiceMailNotificationReceived = socketVoicemails.voiceMailNotificationReceivedFromBot.subscribe((notificationData: BotNotificationDataDTO) => {
      setVoicemailNotificationData(notificationData);
      setActiveViewItem(ActiveMainViewItem.VoiceMailsView);
    });

    subscriptionCurrentCmSessionId?.unsubscribe();
    subscriptionCurrentCmSessionId = socketSocialMedia.currentCmSessionId.subscribe((sessionId: string) => {
      setActiveCmSessionId(sessionId);
    });

    subscriptionLoggingErrorHandler?.unsubscribe();
    subscriptionLoggingErrorHandler = logging.errorHandler.subscribe((data: any) => {
      const message = intl.formatMessage({ id: data });
      const errorMap = errorContent.current.map(x => x.content.props.content);

      const isRed = data.includes("Red");

      if (errorMap.includes(message) === false) {
        GeneralHelper.logCox(`reference was ${reference}`);
        setReference(Math.random());
        GeneralHelper.logCox(`reference is ${reference}`);
        var interval = setTimeout(() => {
          errorContent.current.shift();
          GeneralHelper.logCox(`reference was ${reference}`);
          setReference(Math.random());
          GeneralHelper.logCox(`reference is ${reference}`);
          clearTimeout(interval);
        }, isRed ? 10000 : 5000);

        errorContent.current.push({
          content: <ErrorView content={message} color={isRed ? ErrorColor.Red : ErrorColor.Grey} removeCallback={() => removeError(message)} />
        });

        GeneralHelper.logCox(`reference was ${reference}`);
        setReference(Math.random());
        GeneralHelper.logCox(`reference is ${reference}`);
      }
    });

    const userwayKey = Lockr.get<string>(StorageKeys.UserwayKey);
    if (userwayKey) {
      enableUserWayScript(userwayKey);
    }
  }

  const enableUserWayScript = (dataAccount: string) => {
    var s = document.createElement("script");
    s.setAttribute("data-account", dataAccount);
    s.setAttribute("src", "https://cdn.userway.org/widget.js");
    (document.body || document.head).appendChild(s);
  }

  return (
    <Flex key="main-view-container" className="main-view-container" styles={({ theme: { siteVariables } }) => ({
      backgroundColor: siteVariables.colorScheme.default.background2,
    })}>

      <div key="app-header-container" className="app-header-container">
        <Flex styles={({ theme: { siteVariables } }) => ({
          backgroundColor: siteVariables.colorScheme.default.backgroundPressed,
          color: siteVariables.colorScheme.default.foregroundPressed,
          zIndex: '1',
          opacity: '0.8',
          width: '100%',
          position: 'fixed',
          top: 0,
          left: '0'
        })}>
          {/* #warning-js [cc4all-1102]: fix "Warning: Each child in a list should have a unique "key" prop." because of this List key={reference} for the first error ... */}
          <List key={reference} items={errorContent.current} className={'error-list'} />
        </Flex>

        <CallView />
        <ManagementView />
      </div>
      <div key="app-body-container" className="app-body-container">
        {
          (activeViewItem === ActiveMainViewItem.MediaView || activeViewItem === ActiveMainViewItem.SocialMedia) &&
            <MediaView />
        }

        {activeViewItem === ActiveMainViewItem.WebChatView && <WebChatView activeWebchatsessionId={activeWebChatSessionId} />}
        {activeViewItem === ActiveMainViewItem.VoiceMailsView && <VoiceMailsView voiceMailData={voicemailNotificationData} />}
        {activeViewItem === ActiveMainViewItem.RealtimeReportsView && <RealtimeReportsView />}
        {
          activeViewItem === ActiveMainViewItem.CmMedia && activeCmSessionId && <WebChatView
            isCmMedia
            activeWebchatsessionId={activeCmSessionId}
          />
        }
        {activeViewItem === ActiveMainViewItem.CustomerJourneyView && <CustomerJourneyView />}
      </div>

      <Flex column>
        <Flex.Item grow>
          <Flex column styles={({ theme: { siteVariables } }) => ({
            backgroundColor: siteVariables.colorScheme.default.backgroundPressed,
            color: siteVariables.colorScheme.default.foregroundPressed,
          })}>
          </Flex>
        </Flex.Item>
      </Flex>
    </Flex>
  );
}

export default MainView;

//This file contains composed classes for the Customer Journey (28-nov-2021)

import { JourneyCustomerConversationAction } from "utils/enums";
import { CallSessionDTO } from "./callSessionDTO";

export interface IJourneyCustomerSessionDTO {
    ID?: number;
    JourneyCustomerRef: number;
    CompanyRef: number;
    SessionID: string;
    JourneyCustomerConversationDTO: JourneyCustomerConversationDTO;
    ConversationType: any;
    ConversationTypeString: string;
}

export interface PostJourneyCustomerAndSessionDTO {
    companyRef: number;
    journeyCustomerDTO: CustomerDTO;
    journeyCustomerSession: IJourneyCustomerSessionDTO;
}

export interface JourneyCustomerConversationDTO {
    Id: number;
    Date: string;
    EndDate: string;
    Duration: number;
    Agent?: any;
    Queue: QueueDTO;
    IsHandled: boolean;
    IsLoaded: boolean;
    SessionId: string;
    RowCount: number;
    IsOutBound: boolean;
    ConversationType: string;
    ContactHistory: JourneyCustomerConversationDetailDTO[];
}

export interface JourneyCustomerConversationDetailDTO {
    ID: number;
    ConversationAction: JourneyCustomerConversationAction;
    RecordDate: Date;
    IsHandled: boolean;
    Duration: number;
    TransferAttemptCount: number,
    TransferDestination: string;
    Agent: any;
    Queue: QueueDTO;
    Notes: string;
}

export interface CustomerJourneyNoteDTO {
    Id?: number;
    Message: string;
    Agent: any;
    SessionId: string;
    Date: Date;
}

export interface QueueDTO {
    ID: number;
    Name: string;
    CompanyRef: number;
}

export class PhoneNumberDTO {
    ID: number = -1;
    PhoneNumber: string = '';
    JourneyCustomerRef!: number;
    IsActive!: boolean;
    companyRef!: number;

    constructor(data?: any) {
        Object.assign(this, data);
    }
}

export class EmailAddressDTO {
    ID: number = -1;
    MailAddress: string = "customer.journey@contactcenter4all.com";
    JourneyCustomerRef!: number;
    IsActive!: boolean;
    companyRef!: number;

    constructor(data?: any) {
        Object.assign(this, data);
    }
}

export class CustomerDTO { //Composed class for Customer Journey
    // Instance: CustomerDTO = this;
    ID: number = -1;
    Name!: string;
    Surname!: string;
    CompanyRef!: number;
    PhoneNumbers: PhoneNumberDTO[] = [];
    MailAddresses: EmailAddressDTO[] = [];
    IsPlaceholder: boolean = true;
    CallHistory?: HistoricalConversationDTO[];
    IsCallHistoryLoaded: boolean = false;

    constructor(data?: any) {
        if (data !== undefined) {
            if (data.CallHistory !== undefined) {
                var historyCallObjects: HistoricalConversationDTO[] = [];
                (data.CallHistory as [any]).forEach((element: any, index: number | undefined) => {
                    console.log("checking and assigning CallHistory objects to appropriate class..");
                    console.log(element);
                    if (element.JourneyNotes !== undefined) {
                        historyCallObjects.push(new HistoryCallDTO(element));
                    } else if (element.CustomerIP !== undefined) {
                        historyCallObjects.push(new HistoryChatDTO(element));
                    }
                });
                data.CallHistory = historyCallObjects;
            }
            if (data.PhoneNumbers !== undefined && data.PhoneNumbers !== null) {
                var phoneNumbers: PhoneNumberDTO[] = [];
                (data.PhoneNumbers as any[]).forEach((element: any, index: number) => {
                    phoneNumbers.push(new PhoneNumberDTO(element));
                });
                data.PhoneNumbers = phoneNumbers;
            } else {
                data.PhoneNumbers = [];
            }
            if (data.MailAddresses !== undefined && data.MailAddresses !== null) {
                var mailAddresses: EmailAddressDTO[] = [];
                (data.MailAddresses as any[]).forEach((element: any, index: number) => {
                    mailAddresses.push(new EmailAddressDTO(element));
                });
                data.MailAddresses = mailAddresses;
            } else {
                data.MailAddresses = [];
            }
            Object.assign(this, data);
            this.IsPlaceholder = false;
        } else {
            console.log("data == null when initializing object");
        }
    }

    get getFullName(): string {
        return `${this.Name} ${this.Surname}`;
    }

    removePhoneNumber(phoneNumber: PhoneNumberDTO) {
        console.log(`Removing phone number from List of customer with Id ${this.ID}, phone Id ${phoneNumber.ID}, size before removing: ${this.PhoneNumbers.length}`);
        const index: number = this.PhoneNumbers.indexOf(phoneNumber);
        if (index > -1) {
            this.PhoneNumbers.splice(index, 1);
        }
        console.log(`Removed phone number with Id ${phoneNumber.ID} if it was present. New size of list: ${this.PhoneNumbers.length}`);
    }

    removeEmailAddress(emailAddress: EmailAddressDTO) {
        var index: number = this.MailAddresses.indexOf(emailAddress);
        if (index > -1) {
            this.MailAddresses.splice(index, 1);
        }
        console.log(`Removed email addresses with Id ${emailAddress.ID} if it was present. New size of list: ${this.MailAddresses.length}`);
    }

}

export interface CustomerIdentificationDTO {
    TypeFound: string;
    customerIds: number[];
}

export interface IHistoricalConversationDTO {
    ID: number;
    SessionId: string;
    Date: Date | undefined;
    IsHandled: boolean;
    IsAbandoned: boolean;
    Duration: number | undefined;
    AgentRef: string;
    AgentName: string;
    QueueRef: number;
    QueueName: string;
    SkillRef: number;
    Skill: string;
    ConversationDetails: IConversationDetailDTO[] | [];
}

export interface IConversationDetailDTO {
    SessionId: string;
    Agent: string;
    Queue: string;
    Time: number;
    Type: string;
    Notes: string;
}

export class HistoricalConversationDTO extends CallSessionDTO implements IHistoricalConversationDTO {
    ID!: number;
    CompanyRef!: any;
    RecordDate!: Date;
    Date: Date | undefined;
    Duration: number | undefined;
    SessionId!: string;
    RingingTime: number = 0;
    HandlingTime: number = 0;
    WaitingTime: number = 0;
    IsHandled!: boolean;
    IsAbandoned!: boolean;
    IsRequeue?: boolean = false;
    ConversationDetails = [] as IConversationDetailDTO[];

    SkillRef: any;
    IsPlaceHolder: boolean = true;

    constructor(data?: any) {
        super(data);
        if (data != null) {
            data.SessionID = data.SessionId;
            data.RecordDate = new Date(Date.parse(data.RecordDate));
            Object.assign(this, data);
            this.IsPlaceHolder = false;
        }
    }

    get getEndDate() {
        if (this.RecordDate === undefined) {
            return undefined;
        }
        var startMillis: number = this.RecordDate.getTime();
        var durationMillis: number = (this.RingingTime * 1000) + (this.HandlingTime * 1000) + (this.WaitingTime * 1000);
        var endMillis: number = startMillis + durationMillis;
        return new Date(endMillis);
    }
}

export class HistoryCallDTO extends HistoricalConversationDTO {
    ANI!: string;
    DNIS!: string;
    JoinedAgentCount!: number;
    JourneyNotes?: string;
    IsTransferred?: boolean;
    IsColdTransferred?: boolean;
    IsParked?: boolean;
    IsUnParked?: boolean;

    constructor(data?: any) {
        super(data);
        if (data != null) {
            Object.assign(this, data);
        }
    }
}

export class HistoryChatDTO extends HistoricalConversationDTO {
    CustomerIP!: string;
    ChatAnswerTime?: number;
    IsTransferredToQueue?: boolean;
    IsTransferredToAgent?: boolean;
    LastUpdateDate?: Date;

    constructor(data?: any) {
        super(data);
        if (data != null) {
            if (data.LastUpdateDate !== undefined) {
                data.LastUpdateDate = new Date(Date.parse(data.LastUpdateDate));
            }
            Object.assign(this, data);
        }
    }
}

// export class UserDTO {
//     ID!: number;
//     Name?: string;
//     Surname?: string;

//     constructor(data?: any) {
//         if (data != null) {
//             Object.assign(this, data);
//         }
//     }

//     get getFullName(): string {
//         return this.Name + " " + this.Surname;
//     }
// }

// export class QueueDTO {
//     ID!: number;
//     Name!: string;

//     constructor(data?: any) {
//         Object.assign(this, data);
//     }
// }

export class CompanyDTO {
    ID!: number;
    CompanyRef!: number;
    Name!: string;

    constructor(data?: any) {
        Object.assign(this, data);
    }
}
import { ServiceOperations, logging, StorageKeys } from 'utils';
import { GeneralHelper } from 'utils/helpers';
import Lockr from 'lockr';

interface SpeedProbeCheck {
  sentTime: number;
  receivedTime: number;
}

export class SocketIoStressTest {
  private readonly logger = logging.getLogger('SocketAuth');//#warning-js: why use SocketAuth in lots of places?

  private readonly speedProbesPerSecond: number = 5;
  private readonly speedProbeCheckInterval: number = 1000 / this.speedProbesPerSecond;
  private readonly averagedSeconds: number = 5;
  private readonly measureLagSeconds: number = 5;
  private readonly nAveragedProbes: number = this.averagedSeconds * 1000 / this.speedProbeCheckInterval;
  private readonly nLagProbes: number = this.measureLagSeconds * 1000 / this.speedProbeCheckInterval;
  private readonly nRecordedProbes: number = this.nAveragedProbes + this.nLagProbes;
  times: { [index: number]: SpeedProbeCheck; } = {};

  private readonly showHeartBeatsInterval: number = 1000;

  private currentIndex: number = 0;
  public logSuffix: string = "";

  public start() {
    if (!GeneralHelper.isCox()) {
      return;
    }
    if (!Lockr.get<string>(StorageKeys.CompanyKey)) {
      return;
    }
    const agentSip = Lockr.get<string>(StorageKeys.SIP);
    if ((!agentSip || agentSip?.indexOf("cata1@") == -1)) {
      return;
    }
    setInterval(() => {
      this.probeSpeed();
    }, this.speedProbeCheckInterval);

    setInterval(() => {
      this.showSpeed();
    }, this.showHeartBeatsInterval);

  }

  public probeSpeed() {
    const sentTime = new Date().getTime();
    this.times[this.currentIndex] = { sentTime: sentTime, receivedTime: 0 };

    const currentCheckIndex = this.currentIndex;
    this.invokeProbeSpeed(sentTime).then((x: any) => {
      this.onProbeSpeedResponse(currentCheckIndex, x);
    });
    this.currentIndex = this.mod(this.currentIndex + 1);
  }

  public onProbeSpeedResponse(index: number, x: string) {
    if (parseInt(x) != this.times[index].sentTime) {
      return;//old response
    }
    this.times[index].receivedTime = new Date().getTime();
  }

  public showSpeed() {
    const timesArray = Object.values(this.times);
    let missingResponses = 0;
    let receivedResponses = 0;
    let timeDiff = 0;
    const currentIndex = this.currentIndex;
    timesArray.forEach((t: SpeedProbeCheck, index: number) => {
      if (this.mod(index - currentIndex) >= this.nAveragedProbes) {
        return;
      }
      if (t.receivedTime == 0) {
        missingResponses++;
      } else {
        receivedResponses++;
        timeDiff += t.receivedTime - t.sentTime;
      }
    });
    const avgTimeDiff = Math.round(receivedResponses > 0 ? timeDiff / receivedResponses : 0);
    const totalResponses = missingResponses + receivedResponses;

    console.log(`speedCheck: missing ${missingResponses}/${totalResponses}; ` +
      `avg time diff is ${avgTimeDiff} ms ${this.logSuffix}`);
  }

  private invokeProbeSpeed(sentTime: number): Promise<object> {
    return GeneralHelper.invokeServiceCall(sentTime.toString(), ServiceOperations.ProbeSpeed, this.logger);
  }

  private mod(n: number): number {
    return ((n % this.nRecordedProbes) + this.nRecordedProbes) % this.nRecordedProbes;
  }

}

export const socketIoStressTest = new SocketIoStressTest();

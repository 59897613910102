import { ActiveMediaDto } from "utils/domain/supervisor/ActiveMediaDto";
import { MediaDisplayItem } from "utils/domain/supervisor/SupervisorMediaDtoStage3";
import { WaitingMediaDto } from "utils/domain/waitingMediaDTO";
import { QueueMediaStatusType } from "utils/enums";


export default class QueueMediaHelper {
    public static mapActiveItemToDisplayItem = (activeMedia: ActiveMediaDto): MediaDisplayItem => {
        const displayItem: MediaDisplayItem = new MediaDisplayItem();
        displayItem.SessionId = activeMedia.SessionId;
        displayItem.ScenarioId = activeMedia.ScenarioId;
        displayItem.Agent = activeMedia.Agent;
        displayItem.MediaType = activeMedia.MediaType;
        displayItem.MediaTypeAsString = activeMedia.MediaTypeAsString;
        displayItem.CallerName = activeMedia.CallerName;
        displayItem.CallerPhone = activeMedia.CallerPhone;
        displayItem.StartDate = activeMedia.StartDate;
        displayItem.QueueRef = activeMedia.QueueRef;
        displayItem.QueueName = activeMedia.QueueName;
        displayItem.AssignedTo = activeMedia.AssignedTo;
        displayItem.Status = activeMedia.Status;
        displayItem.StatusAsString = activeMedia.StatusAsString;
        displayItem.IsActiveMedia = true;
        displayItem.QueuePriority = activeMedia.QueuePriority;
        displayItem.IsSupervised = activeMedia.IsSupervised;
        displayItem.AgentUpn = activeMedia.AgentUpn;
        displayItem.IsMonitoringEnabled = activeMedia.IsMonitoringEnabled;

        return displayItem;
    }

    public static mapWaitingItemToDisplayItem = (waitingMedia: WaitingMediaDto): MediaDisplayItem => {
        const displayItem: MediaDisplayItem = new MediaDisplayItem();
        displayItem.SessionId = waitingMedia.SessionId;
        displayItem.Agent = waitingMedia.Agent;
        displayItem.MediaType = waitingMedia.MediaType;
        displayItem.MediaTypeAsString = waitingMedia.MediaTypeAsString;
        displayItem.CallerName = waitingMedia.CallerName;
        displayItem.CallerPhone = waitingMedia.CallerPhone;
        displayItem.StartDate = waitingMedia.StartDate;
        displayItem.QueueRef = waitingMedia.QueueRef;
        displayItem.QueueName = waitingMedia.QueueName;
        displayItem.Status = waitingMedia.Status;
        displayItem.StatusAsString = waitingMedia.StatusAsString;
        displayItem.IsActiveMedia = false;
        displayItem.QueuePriority = waitingMedia.QueuePriority;
        displayItem.IsCallPickupEnabled = waitingMedia.IsCallPickupEnabled;
        displayItem.IsAssigned = waitingMedia.IsAssigned;
        displayItem.ScenarioId = waitingMedia.ScenarioId;

        return displayItem;
    }

    public static getRecordIndexBySessionId(items: any[], sessionId: any) {
        const searchedObject = items.filter(element => element.SessionId === sessionId)[0];
        return searchedObject ? items.indexOf(searchedObject) : null;
    }

    public static getLastElementIdexByQueuePriority = (items: any[], obj: any, isSupervisorView: boolean, isActive = false) => {
        if (!items.length) {
            return 0;
        }

        let filterByQueuePriority = isSupervisorView ?
            items.filter(element => element.QueuePriority === obj.QueuePriority && obj.IsActiveMedia === isActive) :
            items.filter(element => element.QueuePriority === obj.QueuePriority);

        if (filterByQueuePriority.length) {
            return filterByQueuePriority.length;
        }

        filterByQueuePriority = isSupervisorView ?
            items.filter(element => element.QueuePriority < obj.QueuePriority && obj.IsActiveMedia === isActive) :
            items.filter(element => element.QueuePriority < obj.QueuePriority);

        return filterByQueuePriority.length;
    }


    public static getQueueMediaStatusText = (status: QueueMediaStatusType | undefined, intl: any) => {
        switch (status) {
            case QueueMediaStatusType.Waiting:
                return intl.formatMessage({ id: "QueueMediaStatus.Waiting" });
            case QueueMediaStatusType.Offering:
                return intl.formatMessage({ id: "QueueMediaStatus.Offering" });
            case QueueMediaStatusType.Assigned:
                return intl.formatMessage({ id: "QueueMediaStatus.Assigned" });
            case QueueMediaStatusType.ColdTransferRinging:
                return intl.formatMessage({ id: "QueueMediaStatus.ColdTransferRinging" });
            case QueueMediaStatusType.ColdTransfer:
                return intl.formatMessage({ id: "QueueMediaStatus.ColdTransfer" });
            case QueueMediaStatusType.WarmTransferRinging:
                return intl.formatMessage({ id: "QueueMediaStatus.WarmTransferRinging" });
            case QueueMediaStatusType.WarmTransferInitialized:
                return intl.formatMessage({ id: "QueueMediaStatus.WarmTransferInitialized" });
            case QueueMediaStatusType.WarmTransfer:
                return intl.formatMessage({ id: "QueueMediaStatus.WarmTransfer" });
            case QueueMediaStatusType.Parked:
                return intl.formatMessage({ id: "QueueMediaStatus.Parked" });
            case QueueMediaStatusType.FallBack:
                return intl.formatMessage({ id: "QueueMediaStatus.Fallback" });
            case QueueMediaStatusType.CallbackRequest:
                return intl.formatMessage({ id: "QueueMediaStatus.CallbackRequest" });
            case QueueMediaStatusType.CallbackOffering:
                return intl.formatMessage({ id: "QueueMediaStatus.CallbackOffering" });
            default:
                return '';
        }
    }
}


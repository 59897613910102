import { Listener, listeners } from 'services/io/listeners';
import { Subject, Subscription } from 'rxjs';
import { ListenerOperations, ServiceOperations, StorageKeys, QueueOperations, Controllers } from 'utils';
import { logging } from 'utils/logging';
import { WaitingMediaDto } from 'utils/domain/waitingMediaDTO';
import { UpdateQueueAgentDTO } from 'utils/domain/supervisor/UpdateQueueAgentDTO';
import Lockr from 'lockr';
import { socketAudio } from 'services/calling';
import { GeneralHelper } from 'utils/helpers';
import { SupervisedQueueDTO } from 'utils/domain/supervisor/SupervisedQueueDTO';
import { SupervisorMediaDtoStage3 } from 'utils/domain/supervisor/SupervisorMediaDtoStage3';

export interface CallPickupObj {
    agentRef: number,
    sessionId: string
}

export class SupervisorManager {
    //#warning-js [cc4all-1102]: why not having an interface like the others? why user the interface?
    //#warning-js [cc4all-1102]: why not starting with "socket-", like the others? why prefixing with "socket-"?
    private readonly logger = logging.getLogger('SupervisorManager');

    public readonly listenerUpdateClientRole: Listener<any> = listeners.createListener<any>(ListenerOperations.UpdateClientRole);
    public readonly listenerRefreshClientRole: Listener<any> = listeners.createListener<any>(ListenerOperations.RefreshClientRole);
    public readonly listenerAgentHasClientChanged: Listener<any> = listeners.createListener<any>(ListenerOperations.AgentHasClientChanged);

    private readonly subscriptionRoleChange: Subscription | null = null;

    private monitorableQueueIds: number[] = [];
    private offerCallsWithoutClientQueueIds: number[] = [];

    onUserRoleChange: Subject<any> = new Subject<any>();
    onIsUserSupervisorCheckDone: Subject<boolean> = new Subject<boolean>();
    onMonitorableQueueIdsChanged: Subject<number[]> = new Subject<number[]>();

    isSupervisor: boolean = false;
    isSupervisorCheckDone: boolean = false;

    constructor() {
        this.subscriptionRoleChange?.unsubscribe();
        this.subscriptionRoleChange = this.listenerUpdateClientRole.received.subscribe((obj: any) => {
            this.checkIsUserSupervisor();
            this.onUserRoleChange.next(obj);
        });
        //"initiating socket service" will be further on, so I cannot do the checkIsUserSupervisor call here, not yet ...
    }

    getAgentsBySupervisorRef(supervisorId: number): Promise<any> {
        return GeneralHelper.invokeServiceCall(supervisorId, ServiceOperations.GetAgentsBySupervisorRef, this.logger);
    }

    getSupervisedQueuesInfo(supervisorId: number): Promise<any> {
        return GeneralHelper.invokeServiceCall(supervisorId, QueueOperations.GetSupervisedQueuesInfo, this.logger);
    }

    getMediaBySupervisor(supervisorId: number, triggeredBySupervisedCallSessionChanged: boolean): Promise<WaitingMediaDto[]> {
        return new Promise((resolve, reject) => {
            GeneralHelper.invokeServiceCall(supervisorId, ServiceOperations.GetMediaBySupervisor, this.logger).then((response: WaitingMediaDto[]) => {
                const result = response.map(r => new WaitingMediaDto(r));
                if (!triggeredBySupervisedCallSessionChanged) {
                    var monitoredCalls = result.filter(r => r.IsCurrentUserMonitoring);
                    var monitoredCall = monitoredCalls[0];
                    if (monitoredCall) {
                        socketAudio.getSupervisedCallSessionForSessionId(monitoredCall.SessionID);
                    }
                }
                resolve(result);
            }).catch(err => {
                reject(err);
            });
        });
    }

    getMediaBySupervisorStage3(supervisorId: string): Promise<SupervisorMediaDtoStage3> {
        return GeneralHelper.invokeServiceCall(supervisorId, QueueOperations.GetSupervisorMedia,
            this.logger, Controllers.CallCenter).then((response: SupervisorMediaDtoStage3) => {
                return response;
            });
    }

    changeDesire(desireUpdateDTO: UpdateQueueAgentDTO): Promise<any> {
        return GeneralHelper.invokeServiceCall(desireUpdateDTO, ServiceOperations.UpdateAgentScoreAndDesire, this.logger);
    }

    checkIsUserSupervisor() {
        //#warning-js [cc4all-1102]: called twice, once from App.tsx, once from MediaTabs
        this.getSupervisedQueuesInfo(Lockr.get<number>(StorageKeys.UserId))
            .then((queuesInfo: SupervisedQueueDTO[]) => {
                this.handleSupervisedQueueInfoResponse(queuesInfo);
            }).catch(err => {
                console.log(err.message);
            });
    }

    handleSupervisedQueueInfoResponse(queuesInfo: SupervisedQueueDTO[]) {
        const isSupervisor = queuesInfo.length > 0;
        this.onIsUserSupervisorCheckDone.next(isSupervisor);

        const monitorableQueueIds = queuesInfo
            .filter((queueInfo: SupervisedQueueDTO) => queueInfo.IsMonitoringEnabled)
            .map((queueInfo: SupervisedQueueDTO) => queueInfo.QueueId);

        this.offerCallsWithoutClientQueueIds = queuesInfo
            .filter((queueInfo: SupervisedQueueDTO) => queueInfo.OfferCallsToAgentsWithoutClient)
            .map((queueInfo: SupervisedQueueDTO) => queueInfo.QueueId);

        this.onMonitorableQueueIdsChanged.next(monitorableQueueIds);
        this.monitorableQueueIds = monitorableQueueIds;
        this.isSupervisor = isSupervisor;
        this.isSupervisorCheckDone = true;
    }

    getMonitorableQueueIds() {
        return this.monitorableQueueIds;
    }

    doesQueueOffersCallsWithoutClient(queueId: number): boolean {
        return this.offerCallsWithoutClientQueueIds.includes(queueId);
    }
}

export const supervisorManager = new SupervisorManager();

import React from 'react';
import { Login, MgtSignIn } from '../containers/AuthView';
import { RouteDefinition } from './interfaces';
import { MainView } from 'containers';
import { EnvHelper } from './helpers';
import MainViewStage3 from 'containers/MainView/MainViewStage3';

const routes: RouteDefinition[] = [
    {
        route: '/auth/login',
        component: <Login />,
        authenticated: false,
        key: 'login'
    },
    {
        route: '/auth/mgtsignin',
        component: <MgtSignIn />,
        authenticated: false,
        key: 'signinmgt'
    },
    {
        route: '/',
        component: (EnvHelper.isStage3() ? <MainViewStage3 /> : <MainView />),
        authenticated: true,
        key: 'main'
    }
];

export default routes;
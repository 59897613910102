import { logging } from 'utils/logging';
import { ServiceOperations, ListenerOperations } from 'utils';
import { PresenceAndNoteDTO } from 'utils/domain/presence';
import { listeners } from 'services/io/listeners';
import { Subject } from 'rxjs';
import { GeneralHelper } from 'utils/helpers';
import { ApiExtendedWrapUp } from '.';
import { ExtendedWrapUpSettings } from 'utils/domain/extendedWrapUpSettings';

export class ExtendedWrapUpService implements ApiExtendedWrapUp {
    private readonly logger = logging.getLogger('ExtendedWrapUpService');

    public readonly listenerInitializeWrapUp = listeners.createListener<ExtendedWrapUpSettings>(ListenerOperations.InitializeWrapUp);
    public readonly listenerStopWrapUp = listeners.createListener<PresenceAndNoteDTO>(ListenerOperations.StopWrapUp);

    previousPresenceChangeNotification: Subject<PresenceAndNoteDTO> = new Subject<PresenceAndNoteDTO>();

    IsAgentInWrapUp(agentId: string): Promise<boolean> {
        return GeneralHelper.invokeServiceCall(agentId, ServiceOperations.IsAgentInWrapUp, this.logger)
    }

    GetOngoingWrapUpSettings(agentId: string): Promise<ExtendedWrapUpSettings> {
        return GeneralHelper.invokeServiceCall(agentId, ServiceOperations.GetOngoingWrapUpSettings, this.logger)
    }
}

import React, { useState, useRef } from 'react';
import { Button, Flex, CloseIcon, Text } from '@fluentui/react-northstar';
import { ActiveMainViewItem, ChatStatusType } from 'utils';
import { useIntl } from 'react-intl';
import { webChatManager } from 'services/io/web-chat';
import { WebChatTransferView } from 'containers';
import { ChatViewProps } from 'utils/domain/chatViewProps';
import { GeneralHelper } from 'utils/helpers';
import { socketSocialMedia } from 'services/social-media';
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import { Subscription } from 'rxjs';

const styles = {
    backgroundColor: "#C4314B",
    color: "#ffffff",
    width: '50px',
    height: '50px',
    ':hover': {
        backgroundColor: 'rgba(167,32,55,0.9)',
        color: "#ffffff",
    }
}

const chatNameStyle = {
    'flex-grow': '1',
    'padding-left': '30px'
}

export const WebChatActions = ({ isCmMediaActive }: any) => {
    const intl = useIntl();
    const webchatSessionRef = useRef("");
    const [isWebChatActive, setIsWebChatActive] = useState(true);
    const [chatIdText, setChatIdText] = useState("");

    const generateKey = () => {
        return `WebChatActions-${Math.random()}`;
    }

    const [key, setKey] = useState(generateKey());

    const diplayEndChatButton = true;

    let subscriptionOnSelectChat: Subscription | null = null;

    React.useEffect(() => {
        initialize();
        return () => componentWillUnmount();
    }, []);

    const componentWillUnmount = () => {
        GeneralHelper.logCox(`in WebChatActions.tsx, in componentWillUnmount`);

        subscriptionOnSelectChat?.unsubscribe();
    }

    const getChatIdentifier = (chat: ChatViewProps) => {
        if (chat.CustomerName && chat.CustomerName !== '') {
            setChatIdText(`Chat - ${chat.CustomerName}`.trim().replace(/\s\s+/g, ' '));
        } else {
            setChatIdText(`Chat - ${chat.ClientUniqueId}`);
        }
    }

    const initialize = () => {
        GeneralHelper.logCox(`in WebChatActions.tsx, in initialize`);

        subscriptionOnSelectChat?.unsubscribe();
        subscriptionOnSelectChat = webChatManager.onChatConversationChanged.subscribe((obj: ChatViewProps) => {
            if (obj && !isChatIncomingState(obj.ChatStatusType)) {
                webchatSessionRef.current = obj.SessionRef;
                setKey(generateKey());
                getChatIdentifier(obj);
                setIsWebChatActive(isChatActiveState(obj.ChatStatusType) ? true : false);
                webChatManager.webChatActionsViewIsDisplayed.next(true);
            } else {
                setChatIdText("");
                webchatSessionRef.current = "";
                webChatManager.webChatActionsViewIsDisplayed.next(false);
            }
        });
    }

    const isChatActiveState = (state: ChatStatusType) => {
        return (state === ChatStatusType.Accepted || state === ChatStatusType.TransferToAgentInviteAccepted || state === ChatStatusType.TransferToAgentInviteRejected);
    }

    const isChatIncomingState = (status: ChatStatusType) => {
        return (status === ChatStatusType.Incoming
            || status === ChatStatusType.Ringing
            || status === ChatStatusType.Ignored
            || status === ChatStatusType.TransferedToQueue
            || status === ChatStatusType.TransferToAgentInviteSent);
    }

    const endWebchat = () => {
        if (isCmMediaActive) {
            const request = GeneralHelper.getSocialMediaRequestDto(webchatSessionRef.current);
            socketSocialMedia.SocialMediaTerminate(request);
            mainViewHelper.viewChanged.next(ActiveMainViewItem.MediaView);
        } else {
            webChatManager.onEndChat.next(webchatSessionRef.current);
        }
    };

    return (
        <>
            {webchatSessionRef.current !== "" &&
                <Flex className="web-chat-actions-container" vAlign="center" gap="gap.medium" key={key}>
                    <Flex styles={chatNameStyle}>
                        <Text content={chatIdText} />
                    </Flex>
                    <Flex>
                        <>
                            {isWebChatActive && !isCmMediaActive &&
                                <WebChatTransferView sessionRef={webchatSessionRef.current} />
                            }
                            {diplayEndChatButton && <Button
                                onClick={endWebchat}
                                icon={<CloseIcon size="large" />}
                                iconOnly
                                text
                                title={intl.formatMessage({ id: 'ChatListView.EndChat' })}
                                styles={styles}
                            />}
                        </>
                    </Flex>
                </Flex>}
        </>
    )
};

export default WebChatActions;

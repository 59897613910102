import { Providers, MsalProvider } from '@microsoft/mgt'
import Lockr from 'lockr';
import { logging, StorageKeys } from "utils";
import { socketAuth } from "services";
import { socketGraphApi } from 'services/graphApi';

const azureClients = (window as any)['config'] ? (window as any)['config'].azureClients : undefined;

export class MsalHelper {
  private readonly logger = logging.getLogger('MsalHelper');
  private msalProvider: MsalProvider | null = null;

  public InitProvider() {
    const companyKey = Lockr.get<string>(StorageKeys.CompanyKey);
    const clientId = azureClients[companyKey] || azureClients['default'];

    const config = {
      clientId: clientId,
      loginHint: localStorage.getItem(StorageKeys.UPN) as string,
      prompt: "consent",
      scopes: ['openid', 'user.read.all'],
      redirectUri: window.location.origin
    }

    this.msalProvider = new MsalProvider(config)
    Providers.globalProvider = this.msalProvider;
  }

  public Login() {
    if (!this.msalProvider) {
      this.InitProvider();
    }

    const startLogin = this.msalProvider?.trySilentSignIn ? this.msalProvider.trySilentSignIn() : null;

    if (startLogin) {
      startLogin.then(() => {
        const config = { scopes: ['user.read.all'] };

        this.msalProvider?.getAccessToken(config).then((result: string) => {
          Lockr.set(StorageKeys.TokenAD, result);

          socketAuth.userSignedIn.next(true);
        }).catch((err: any) => {
          logging.errorHandler.next("ErrorMessage.MSALLogin");
          this.logger.error(err);
          socketAuth.userSignedIn.next(true);
        });
      });
    }
  }

  public async RefreshAccessToken(callback?: any, callbackParam?: any) {
    let provider = Providers.globalProvider;

    if (!provider) {
      this.InitProvider();
      provider = Providers.globalProvider;
    }

    return provider.getAccessToken().then((accessToken: string) => {
      if (callback) {
        return callback(accessToken, callbackParam);
      }
      return undefined;
    }).catch((err: any) => {
      socketGraphApi.getRefreshTokenAndRetryCall(callbackParam, false);
      logging.errorHandler.next("ErrorMessage.MSALLoginRefresh");
      this.logger.error(err);
    })
  }
}

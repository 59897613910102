import Lockr from 'lockr';
import React, { useRef, useState } from "react";
import { Flex, Loader, Provider, Segment, Text } from "@fluentui/react-northstar";
import { Subscription } from "rxjs";
import { socketAudio } from "services/calling";
import { CallSessionDTO } from "utils/domain/callSessionDTO";
import { ConferenceStatusAction, StorageKeys } from "utils";
import { customerJourneyManager } from "services/io/customer-journey";
import CustomerInformationView from "./CustomerInformationView/CustomerInformationView";
import { CustomerJourneyNavigationView } from "./CustomerJourneyNavigationView/CustomerJourneyNavigationView";
import CustomerSearchView from "./CustomerSearchView/CustomerSearchView";
import { CustomerDTO, IJourneyCustomerSessionDTO } from "utils/domain/customerJourneyModels";
import CustomerEditView from "./CustomerEditView/CustomerEditView";
import { HistoricalConversationOverview } from "./InteractionsOverview/InteractionsOverview";
import { ConversationDetailView } from "./InteractionDetails/InteractionDetailView";
import CustomerIdentificationView from "./CustomerIdentificationView/CustomerIdentificationView";

/**
* This contstant is the main view of the Customer Journey system (component). It defines important subscriptions to disperse
* changes to important information such as a new call accepted by the user and an identified customer to show information of.
* It shows or hides certain components' views based on the given state of the following constants' values: loading, IsSearchViewShown
* IsEditViewShown, IsIdentifyingCustomer, IsCreatingCustomer. By default, this component loads the components CustomerInformationView,
* HistoricalConversationOverview and ConversationDetailView.
*/
export const CustomerJourneyView = () => {
    const startOperations = [ConferenceStatusAction[ConferenceStatusAction.Accepted], ConferenceStatusAction[ConferenceStatusAction.Ringing], ConferenceStatusAction[ConferenceStatusAction.Resumed]];
    let listenerCurrentUserCallSessionStateChanged: Subscription | null = null;
    let listenerCurrentCustomerChanged: Subscription | null = null;
    
    const [unidentifiedCallSession, setUnidentifiedCallSession] = useState<CallSessionDTO | undefined>();
    const callSessionRef = useRef<CallSessionDTO | undefined>();
    const [customer, setCustomer] = useState<CustomerDTO>(customerJourneyManager.currentCustomer.value);
    const [editCustomer, setEditCustomer] = useState<CustomerDTO | undefined>();
    const [selectedConversation, setSelectedConversation] = useState<IJourneyCustomerSessionDTO>(
        //Should by default be the conversation the Customer Agent is having. It can be replaced when the Customer Agent manually selects a different conversation to check its details.
    );
    const [loading, setLoading] = useState(true);
    const [IsSearchViewShown, setIsSearchViewShown] = useState(true);
    const [IsEditViewShown, setIsEditViewShown] = useState(false);
    const [IsIdentifyingCustomer, SetIsIdentifyingCustomer] = useState(true);
    


    React.useEffect(() => {
        initialize();
        return () => componentWillUnmount();
    }, []);

    const componentWillUnmount = () => {
        listenerCurrentUserCallSessionStateChanged?.unsubscribe();
        listenerCurrentCustomerChanged?.unsubscribe();
    }

    const initialize = () => {
        //Sets the subscription with a subject that gives the new conversation the user has accepted. Based on this information, the Customer Journey system
        //will try to identify a Customer record.
        listenerCurrentUserCallSessionStateChanged = socketAudio.currentUserCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
            if ((startOperations.includes(callSessionDTO.ConferenceActionAsString)) && callSessionDTO.IsCurrentUserThePrimaryAgent) {
                //Get unique identifier of communication medium of customer from the callSessionDTO..
                if (callSessionDTO.SessionId === undefined || callSessionDTO.SessionId === "") {
                    return;
                }

                if (callSessionRef.current?.SessionId === callSessionDTO.SessionId || customerJourneyManager.currentConversation.value.SessionID === callSessionDTO.SessionId) {
                    callSessionRef.current = callSessionDTO;
                    return;
                }
                                
                updateIsIdentifyingCustomer(true);
                setUnidentifiedCallSession(callSessionDTO);
            }

            //Enable when the Conversation History and Details view should reset when the call is ended.
            // updateIsIdentifyingCustomer(true);
            // setCallSession(callSessionDTO);
        });

        socketAudio.getCurrentCallSession();

        /**
        * Sets the subscription with a subject that gives the current customer, this value can change when the identification process (when user accepted new conversation) returns a customer record
        * or when the user manually selects a customer record from either the search results (CustomerSearchView.tsx) or from the identification results (CustomerIdentificationView.tsx).
        */
        listenerCurrentCustomerChanged = customerJourneyManager.currentCustomer.subscribe((customerDto: CustomerDTO) => {
            setCurrentCustomer(customerDto);
        });
        SetIsIdentifyingCustomer(false);
    }

    /**
    * Sets the customer constant of this component and sets the ShowCustomerSearch and loading constants to false
    * to stop the system from showing the Customer Search view and the Loading icon.
    */
    function setCurrentCustomer(customerDto: CustomerDTO) {
        setCustomer(customerDto);
        setShowCustomerSearch(false);
        setLoading(false);
    }

    /**
    * Sets the EditCustomer contstant with a CustomerDTO value and sets the IsEditViewShown value accordingly. Also
    * updates the CurrentCustomer vallue when the CurrentCustomer value has the same ID value as the EditCustomer value.
    * this function also accepts undefined value to 'clear' the constant to indicate that no Customer object is being edited.
    */
    function updateEditCustomer(customerDto: CustomerDTO | undefined) {
        if (customerDto) {
            customerDto.CompanyRef = Lockr.get<number>(StorageKeys.CompanyId);
        }
        setEditCustomer(customerDto);
        setIsEditViewShown(customerDto === undefined ? false : true);
        if (customer.ID === customerDto?.ID) {
            setCurrentCustomer(customerDto);
        }
    }

    /**
    * Function to set wether the system is trying to indentify a Customer record based on the values of a HistoricalConversationDTO (a conversatioon).
    */
    function updateIsIdentifyingCustomer(identifying: boolean) {
        SetIsIdentifyingCustomer(identifying);
        setIsSearchViewShown((identifying ? identifying : IsSearchViewShown));
    }

    /**
    * Sets the IsSearchViewShown constant. This function is used to show and hide the CustomerSearchView.tsx component.
    */
    function setShowCustomerSearch(isShown: boolean) {
        setIsSearchViewShown(isShown);
    }

    /**
     * A function invoked by the CustomerIdentifictionView.tsx component, to mark that the identification process has been completed.
     * This function is called regardless of the result the identification process returned. It is aimed at flagging whether an identification has
     * been performed on a CallSession, to prevent double identification on the same CallSession.
     * @param callSession The CallSession on which the Identification process has been executed
     * @param idResult The result of the Identification process.
     */
    function identificationComplete(callSession: CallSessionDTO, idResult: CustomerDTO[] | undefined) {
        callSessionRef.current = callSession;
        customerJourneyManager.currentCallSession.next(callSession);
    }

    const getOneThirdSegmentStyle = function () {
        return {
            width: `${100 / 3}%`,
            borderRadius: "5px",
            margin: "10px",
            flexGrow: 1,
            flexShrink: 1,
            overflow: "scroll",
            maxHeight: "75vh"
        };
    }

    return (
        <Provider
        theme={{
          animations: {
          },
        }}
        >
        <Flex.Item styles={{
                maxHeight: "100vh",
                overflow: "scroll",}}>
            <Flex column
                styles={({ theme: { siteVariables } }) => ({
                    backgroundColor: siteVariables.colorScheme.default.background2,
                    color: siteVariables.colorScheme.default.foreground2,
                })}
            >
                <Flex column
                styles={({ theme: { siteVariables } }) => ({
                    backgroundColor: siteVariables.colorScheme.default.background2,
                    color: siteVariables.colorScheme.default.foreground2
                })}
                >
                    <CustomerJourneyNavigationView searchViewShown={IsSearchViewShown} EditCustomer={undefined}  updateSearchView={setShowCustomerSearch} UpdateEditCustomer={updateEditCustomer} />
                    {loading === true && <Loader label="Loading..." />}
                    {(loading === false && customer === null) && 
                        <Flex column>
                            <Text content="No customer could be found! Please select customer manually." />
                        </Flex>}
                    <Flex gap="gap.small" padding="padding.medium" styles={{paddingTop: "0px"}}>                        
                        <Segment styles={({ theme: { siteVariables } }) => {
                                            return getOneThirdSegmentStyle();
                                        }}>
                            {IsEditViewShown === false && <CustomerInformationView currentCustomer={customer} editViewShown={IsEditViewShown} updateEditView={setIsEditViewShown} updateEditCustomer={updateEditCustomer} />}
                            {IsEditViewShown && <CustomerEditView EditCustomer={editCustomer} UpdateEditCustomer={updateEditCustomer} editViewShown={IsEditViewShown} updateEditView={setIsEditViewShown} creating={false} /> }
                        </Segment>

                        <Segment styles={({ theme: { siteVariables } }) => {
                                            return getOneThirdSegmentStyle ();
                                        }}>
                            <Flex column styles={{ padding: "5px", overflow: "scroll"}}>
                                {IsSearchViewShown === true && <CustomerSearchView compactView={false} updateSearchView={setShowCustomerSearch} />}
                                {IsSearchViewShown === false && <HistoricalConversationOverview CurrentCustomer={customer} SelectedHistoricalConversation={selectedConversation} SelectHistoricalConversation={setSelectedConversation}/>}
                            </Flex>
                        </Segment>
                        <Segment styles={({ theme: { siteVariables } }) => ({
                            width: `${100/3}%`,
                            borderRadius: "5px",
                            margin: "10px",
                            flexGrow: 1,
                            flexShrink: 1,
                            overflow: "scroll",
                            maxHeight: "75vh"
                        })}>
                            <Flex column styles={{ padding: "5px"}}>
                                {IsIdentifyingCustomer === false && <ConversationDetailView SelectedConversation={selectedConversation} />}
                                <CustomerIdentificationView IdentifyCallSession={unidentifiedCallSession} CurrentConversation={undefined} IsIdentifyingCustomer={IsIdentifyingCustomer} UpdateIsIdentifyingCustomer={updateIsIdentifyingCustomer} UpdateIsCreatingCustomer={updateEditCustomer} IdentificationCompleteMethod={identificationComplete} />                            
                            </Flex>
                        </Segment>
                    </Flex>
                </Flex>
            </Flex>
        </Flex.Item>
        </Provider>
    );
};
export default CustomerJourneyView;


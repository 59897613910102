import { MediaType, MonitorTypes, OperatorCallState, QueueMediaStatusType, StorageKeys } from 'utils';
import Lockr from 'lockr';

export class WaitingMediaDto {
    SessionID?: string;
    SessionId?: string;
    MediaType?: MediaType;
    MediaTypeAsString?: string;
    Subject?: string;
    Source?: string;
    SourceDisplayName?: string;
    SkillName?: string;
    SkillRef?: number;
    StartDate?: string;
    QueueRef?: any;
    QueueName?: string;
    SupervisorRef?: number[];
    SilentCall?: boolean;
    IsAssigned?: boolean;
    OperatorCallState!: OperatorCallState;
    OperatorCallStateAsString?: OperatorCallState;//#warning-js [cc4all-1102]: why is PreviousOperatorCallStateAsString string while OperatorCallStateAsString is enum?
    PreviousOperatorCallState?: OperatorCallState;
    PreviousOperatorCallStateAsString?: string;
    OperatorSip!: string;
    OperatorName?: string;
    TargetAgentName!: string;
    TargetAgentSip?: string;
    ConversationKey?: string;
    ConferenceUri?: string;
    TrustedEndPoint?: string;
    LeavingTime?: string;
    IsCallPickupEnabled?: boolean;
    AreSupervisorCTAsDisabled: boolean = false;
    ActiveSupervisorSIP?: string;
    MonitorType!: MonitorTypes;
    QueuePriority!: number;
    Status?: QueueMediaStatusType;
    CallerName?: string;
    CallerPhone?: string;
    Agent?: string;
    StatusAsString?: string;
    ScenarioId?: string;

    constructor(data?: any) {
        this.OperatorCallState = OperatorCallState.None;
        this.TargetAgentName = "";
        this.OperatorSip = "";
        Object.assign(this, data);
    }

    get IsCurrentUserMonitoring(): boolean {
        return !!this.ActiveSupervisorSIP && this.ActiveSupervisorSIP.toLowerCase() === (Lockr.get<string>(StorageKeys.SIP)).toLowerCase();
    }
}
import { PresenceAvailability } from "utils";
import { WrapUpCodeExtended } from "../wrapUpCodeExtended";

export class ExtendedWrapUpSettings {
    SessionId!: string;
    QueueRef!: string;
    AgentRef!: string;
    SipAddress!: string;
    WrapUpTimeInterval!: number;
    Presence!: PresenceAvailability;
    Reason!: string;
    WrapupTimerVisibility!: boolean;
    WrapUpStartDate!: string;
    DefaultAssignedWrapUpCode?: WrapUpCodeExtended;
    AllWrapUpCodesFromCollections?: WrapUpCodeExtended[];
    IsLevelOneHidden?: boolean;

    constructor(data?: any) {
        Object.assign(this, data);
    }

    get EndDate(): Date | undefined {
        return new Date(new Date(this.WrapUpStartDate).getTime() + (this.WrapUpTimeInterval * 1000));
    }
}

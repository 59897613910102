import React from 'react';
import { AttendeeIcon, Button, CallEndIcon, CallIcon, CallParkingIcon, CallRecordingIcon, Dropdown, ExpandIcon, Flex, Input, ParticipantRemoveIcon, PauseThickIcon, PhoneArrowIcon, PlayIcon, Popup, Tooltip, UserFriendsIcon } from "@fluentui/react-northstar";
import CallActionsStyles from "./CallActionsStage3Styles";
import SandClock from './components/SandClock';
import SubFlowIcon from 'components/Icons/SubFlowIcon';
import { MonitorTypes } from 'utils/enums';
import ActiveAction from 'components/Buttons/ActiveAction';

export const answerCallButton = (intl: any) => {
  return <Button
    icon={<CallIcon size="large" />}
    iconOnly
    text
    title={intl.formatMessage({ id: 'CallActions.Answer' })}
    styles={CallActionsStyles.stylesAnswer} />;
}

export const rejectCallButton = (intl: any, rejectCall: any) => {
  return <Button
    icon={<CallEndIcon size="large" />}
    onClick={rejectCall}
    iconOnly
    text
    title={intl.formatMessage({ id: 'CallActions.Reject' })}
    styles={CallActionsStyles.styles} />;
}

export const recordCallButton = (intl: any, toggleRecording: any) => {
  return <Button
    icon={<CallRecordingIcon size="large" />}
    iconOnly
    text
    title={intl.formatMessage({ id: 'CallActions.StartRecord' })}
    onClick={toggleRecording}
    styles={CallActionsStyles.stylesFaded} />;
}

export const stopRecordCallButton = (intl: any, toggleRecording: any) => {
  return <Button className="stop-recording-button"
    icon={<div className="stop-recording-icon"></div>}
    iconOnly
    text
    title={intl.formatMessage({ id: 'CallActions.StopRecord' })}
    onClick={toggleRecording}
    styles={CallActionsStyles.stylesFaded} />;
}

export const pauseCallButton = (intl: any, pauseCall: any) => {
  return <Button
    text
    iconOnly
    icon={<PauseThickIcon size="large" />}
    title={intl.formatMessage({ id: 'CallActions.Pause' })}
    onClick={() => pauseCall()}
    styles={CallActionsStyles.stylesFaded} />;
}

export const resumeCallButton = (intl: any, resumeCall: any) => {
  return <Button
    text
    iconOnly
    icon={<PlayIcon size="large" />}
    title={intl.formatMessage({ id: 'CallActions.Resume' })}
    onClick={() => resumeCall()}
    styles={CallActionsStyles.stylesFaded} />;
}

export const loadingCallButton = (intl: any,) => {
  return <Button
    text
    iconOnly
    disabled
    icon={<SandClock size="large" />}
    title={intl.formatMessage({ id: 'CallActions.Loading' })}
    styles={CallActionsStyles.stylesFaded}
  />;
}

export const parkCallButton = (intl: any, parkCall: any) => {
  return <Button
    icon={<CallParkingIcon size="large" />}
    iconOnly
    text
    title={intl.formatMessage({ id: 'CallActions.Park' })}
    styles={CallActionsStyles.stylesFaded}
    onClick={parkCall} />;
}

export interface WarmTransferPopupButtonParams {
  intl: any;
  setIsInvalidTransfer: any;
  warmTransferInputValue: any;
  onChangeInputTransfer: any;
  onBlurInputTransfer: any;
  onPasteInputTransfer: any;
  handleWarmKeyPress: any;
  isInvalidTransfer: any;
  handleWarmTransfer: any;
}

export const warmTransferPopupButton = (param: WarmTransferPopupButtonParams) => {
  return <Popup
    trapFocus
    trigger={<Button
      icon={<UserFriendsIcon size="large" outline={false} />}
      iconOnly
      text
      title={param.intl.formatMessage({ id: 'CallActions.WarmTransfer' })}
      styles={CallActionsStyles.stylesFaded}
      onClick={() => param.setIsInvalidTransfer(false)} />
    }
    content={
      <Flex gap="gap.smaller"
        styles={{
          padding: '10px',
        }}>
        <Input maxLength={50} required fluid inverted placeholder={param.intl.formatMessage({ id: 'AgentView.FindContact' })}
          styles={{
            width: '250px',
          }}
          value={param.warmTransferInputValue}
          onChange={(e) => param.onChangeInputTransfer((e.target as HTMLInputElement).value, true)}
          onBlur={(e) => param.onBlurInputTransfer(e.target.value, true)}
          onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => { param.onPasteInputTransfer(e.clipboardData.getData("Text"), true) }}
          onKeyPress={(e) => param.handleWarmKeyPress(e)}
          showSuccessIndicator={false}
          error={param.isInvalidTransfer}
        />

        <Button circular icon={<UserFriendsIcon />} primary
          size="medium"
          title={param.intl.formatMessage({ id: 'CallActions.WarmTransfer' })}
          onClick={param.handleWarmTransfer}
        />
      </Flex>
    }
  />;
}

export interface ColdTransferPopupButtonParams {
  intl: any;
  setIsInvalidTransfer: any;
  coldTransferInputValue: any;
  onChangeInputTransfer: any;
  onBlurInputTransfer: any;
  onPasteInputTransfer: any;
  handleColdTransferKeyPressFromTheCallActionPopup: any;
  isInvalidTransfer: any;
  handleColdTransferClickFromTheCallActionPopup: any;
}

export const coldTransferPopupButton = (param: ColdTransferPopupButtonParams) => {
  return <Popup
    trapFocus
    trigger={<Button
      icon={<PhoneArrowIcon size="large" />}
      iconOnly
      text
      title={param.intl.formatMessage({ id: 'CallActions.ColdTransfer' })}
      styles={CallActionsStyles.stylesFaded}
      onClick={() => param.setIsInvalidTransfer(false)} />}
    content={
      <Flex gap="gap.smaller"
        styles={{
          padding: '10px',
        }}>
        <Input maxLength={50} required fluid inverted placeholder={param.intl.formatMessage({ id: 'AgentView.FindContact' })}
          styles={{
            width: '250px',
          }}
          value={param.coldTransferInputValue}
          onChange={(e) => param.onChangeInputTransfer((e.target as HTMLInputElement).value, false)}
          onBlur={(e) => param.onBlurInputTransfer(e.target.value, false)}
          onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => { param.onPasteInputTransfer(e.clipboardData.getData("Text"), false) }}
          onKeyPress={(e) => param.handleColdTransferKeyPressFromTheCallActionPopup(e)}
          showSuccessIndicator={false}
          error={param.isInvalidTransfer}
        />

        <Button circular icon={<PhoneArrowIcon />} primary
          size="medium"
          title={param.intl.formatMessage({ id: 'CallActions.ColdTransfer' })}
          onClick={param.handleColdTransferClickFromTheCallActionPopup}
        />
      </Flex>
    }
  />;
}


export interface ColdTransferIVRPopupButtonParams {
  intl: any;
  getColdTransferDestinationsAfterStopTyping: any;
  coldTransferDestinations: any;
  currentSelectedColdTransferDestination: any;
  handleColdTransferKeyPressFromTheCallActionPopup: any;
  areTranferDestinationsLoading: any;
  isInvalidTransfer: any;
  handleColdTransferIVR: any;
}

export const coldTransferIVRPopupButton = (param: ColdTransferIVRPopupButtonParams) => {
  return <Popup
    trapFocus
    trigger={<Button
      icon={<SubFlowIcon />}
      iconOnly
      text
      title={param.intl.formatMessage({ id: 'CallActions.ColdTransferIVR' })}
      styles={CallActionsStyles.stylesFaded}
      onClick={() => { param.getColdTransferDestinationsAfterStopTyping() }} />}
    content={
      <Flex gap="gap.smaller"
        styles={{
          padding: '10px',
        }}>
        <Dropdown
          search
          id="cold-transfer-dropdown"
          items={param.coldTransferDestinations}
          placeholder={param.intl.formatMessage({ id: 'AgentView.FindFlowOrQueue' })}
          noResultsMessage="We couldn't find any matches."
          getA11ySelectionMessage={{
            onAdd: item => `${item} has been selected.`,
          }}
          onSearchQueryChange={(e, data) => {
            param.getColdTransferDestinationsAfterStopTyping(data.searchQuery);
          }}
          onChange={(e, selectedOption) => {
            if (selectedOption) {
              param.currentSelectedColdTransferDestination.current = (selectedOption.value as any)?.header.toString() || '';
            }
          }}
          onKeyPress={(e: any) => param.handleColdTransferKeyPressFromTheCallActionPopup(e, true)}
          loading={param.areTranferDestinationsLoading}
          error={param.isInvalidTransfer}
        />

        <Button circular icon={<PhoneArrowIcon />} primary
          size="medium"
          title={param.intl.formatMessage({ id: 'CallActions.ColdTransferIVR' })}
          onClick={() => param.handleColdTransferIVR()}
        />
      </Flex>
    }
  />;
}

export const warmTransferConsultCallerButton = (intl: any, warmTransferConsultCaller: any) => {
  return <Button
    icon={<ExpandIcon size="large" />}
    iconOnly
    text
    title={intl.formatMessage({ id: 'CallActions.ConsultCaller' })}
    onClick={warmTransferConsultCaller}
    styles={CallActionsStyles.stylesFaded} />;
}

export const warmTransferConsultAgentButton = (intl: any, warmTransferConsultAgent: any) => {
  return <Button
    icon={<ExpandIcon size="large" />}
    iconOnly
    text
    title={intl.formatMessage({ id: 'CallActions.ConsultAgent' })}
    onClick={warmTransferConsultAgent}
    styles={CallActionsStyles.stylesFaded} />;
}

export const finalizeWarmTransferButton = (intl: any, warmTransferFinalize: any) => {
  return <Button
    icon={<PhoneArrowIcon size="large" />}
    iconOnly
    text
    title={intl.formatMessage({ id: 'CallActions.FinalizeWarmTransfer' })}
    onClick={warmTransferFinalize}
    styles={CallActionsStyles.stylesAnswer} />;
}

export const warmTransferCancelButton = (intl: any, warmTransferCancel: any) => {
  return <Button
    icon={<ParticipantRemoveIcon size="large" />}
    iconOnly
    text
    title={intl.formatMessage({ id: 'CallActions.Ignore' })}
    onClick={warmTransferCancel}
    styles={CallActionsStyles.styles} />;
}

export const endCallButton = (intl: any, hangUp: any) => {
  return <Button
    onClick={hangUp}
    icon={<CallEndIcon size="large" />}
    iconOnly
    text
    title={intl.formatMessage({ id: 'CallActions.Close' })}
    styles={CallActionsStyles.styles} />;
}

export const switchToListenButton = (intl: any, listenButtonDisabled: any, switchToMonitoringAction: any, monitoringType: any) => {
  return <Button
    disabled={listenButtonDisabled}
    onClick={() => switchToMonitoringAction(MonitorTypes.Silent)}
    icon={<img alt='Listen' className={'ear-icon-call-action'} />}
    iconOnly
    data-test="listen-button"
    text
    title={intl.formatMessage({ id: 'CallActions.Listen' })}
    styles={monitoringType === MonitorTypes.Silent ? CallActionsStyles.stylesActive : CallActionsStyles.stylesFaded} />;
}

export const switchToBargeInButton = (intl: any, monitoringType: any, bargeInButtonDisabled: any, switchToMonitoringAction: any) => {
  return monitoringType === MonitorTypes.BargeIn
    ? <ActiveAction icon={<AttendeeIcon size="large" />}></ActiveAction>
    : <Tooltip content={intl.formatMessage({ id: 'CallActions.UnmuteToBargeIn' })} trigger={<Button
      disabledFocusable={bargeInButtonDisabled}
      onClick={() => switchToMonitoringAction(MonitorTypes.BargeIn)}
      icon={<AttendeeIcon size="large" outline />}
      iconOnly
      data-test="barge-in-button"
      text
      title={intl.formatMessage({ id: "SupervisorCallsView.BargeIn" })}
      styles={CallActionsStyles.stylesFaded} />}
    />;
}

export const terminateMonitoringButton = (intl: any, terminateMonitoring: any) => {
  return <Button
    onClick={terminateMonitoring}
    icon={<CallEndIcon size="large" />}
    data-test="terminate-monitoring"
    iconOnly
    text
    title={intl.formatMessage({ id: 'CallActions.TerminateMonitoring' })}
    styles={CallActionsStyles.styles} />;
}


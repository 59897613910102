import { ShiftActivityIcon, SearchIcon } from "@fluentui/react-icons-northstar";
import { Flex, Input, Text } from "@fluentui/react-northstar";
import AdvancedTable from "components/AdvancedTable";
import { Paginator } from "containers";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { GenericHistoryDTO, HistoryState, genericHistoryService } from "./GenericHistoryService";
import HistoryColumnsResolver, { ColumnName } from "./HistoryColumnsResolver";
import { useSubjectListenerEffect } from "utils/helpers/listenerHook";
import { GeneralHelper } from "utils/helpers";
import { DateFromBackendUtc } from "utils/helpers/date-help";

const HistoryView = () => {
    const intl = useIntl();

    const [callHistoryList, setCallHistoryList] = useState<any[]>([]);
    const searchFormText = useRef<string>(genericHistoryService.searchText);
    const columns = HistoryColumnsResolver();

    useSubjectListenerEffect((historyData: GenericHistoryDTO[]) =>
        setCallHistoryList(historyData.map(buildRowData))
    , genericHistoryService.dataUpdatedSubject);

    useEffect(() => genericHistoryService.init(), []);

    const colorByState = useMemo(()=> ({
        [HistoryState.Accepted] : '',
        [HistoryState.Missed] : 'orange',
        [HistoryState.Rejected] : 'red'
    }), []);

    const buildTypeText = (message: string, state: HistoryState) => (<Text content={message} color={colorByState[state]} />);

    const getContent = (history: GenericHistoryDTO, column: string) => {
        switch (column) {
            case ColumnName.Name:
                return history.Name;
            case ColumnName.Subject:
                return history.Subject;
            case ColumnName.Queue:
                return history.QueueName;
            case ColumnName.Handled:
                return intl.formatMessage({ id: `Generic.History.${history.IsHandled ? 'Yes' : 'No'}`});
            case ColumnName.Type:
                return buildTypeText(intl.formatMessage({ id: `Generic.History.${HistoryState[history.State]}Type`}), history.State);
            case ColumnName.Date:
                return GeneralHelper.formatAMPM(DateFromBackendUtc(history.Date));
            default:
                return (<></>);
        }
    }

    const buildRowData = (history: GenericHistoryDTO) => {
        return {
            key: history.Id,
            items: columns.map(x => ({
                key: `${history.Id}${x.key}`,
                content: getContent(history, x.name),
                className: x.className
            }))
        };
    }

    const applySearchOnHistory = (code: string) => {
        const searchTextLength = searchFormText.current.length;
        
        if (code === "Enter" && (searchTextLength > 2 || searchTextLength === 0)) {
            genericHistoryService.updateSearch(searchFormText.current);
        }
    }
    
    return <Flex column className="call-history-container">
        <Flex gap="gap.small" vAlign="center">
            <ShiftActivityIcon />
            <Text content={intl.formatMessage({ id: "Generic.History.Header" })} weight="bold" />

            <Input
                inverted
                iconPosition="start"
                style={{marginLeft: "10px"}}
                icon={<SearchIcon size="small" />}
                defaultValue={searchFormText.current}
                placeholder={intl.formatMessage({ id: "Generic.History.Search" })}
                onChange={(e: any) => searchFormText.current = e.target.value.trim()}
                onKeyDown={(e: any) => applySearchOnHistory(e.key)}
            />
        </Flex>

        <AdvancedTable
            columns={columns}
            rows={callHistoryList} 
            label="Generic History" 
            className="call-history-table"
        />

        { 
            genericHistoryService.existsPaginator && <Paginator
                currentPage={genericHistoryService.currentPage}
                totalPages={genericHistoryService.numberOfPages}
                onChange={(page: number) => genericHistoryService.changePage(page)}
            />
        }
    </Flex>
};

export default HistoryView;
import React, { useState, useRef } from 'react';
import Lockr from 'lockr';
import Timer from '../../../../components/Timer';
import './WaitingCalls.scss';
import { queuesService } from '../../../../services/queue';
import { socketAudio } from '../../../../services/calling';
import { socketContacts } from '../../../../services/contacts';
import { logging, QueueMediaStatusType, QueueMediaViewType, StorageKeys } from '../../../../utils';
import { WaitingMediaDto } from '../../../../utils/domain/waitingMediaDTO';
import { CallSessionDTO } from '../../../../utils/domain/callSessionDTO';
import { useIntl } from 'react-intl';
import { waitingListManager } from '../../../../services/io/waiting-list';
import GeneralHelper from '../../../../utils/helpers/general-helper';
import { Subscription } from 'rxjs';
import { Presence, PresenceState } from 'utils/domain/presence';
import { socketPresence } from 'services/presence';
import { serviceRequests } from 'services';
import MediaTypeIcon from '../mediaComponents/MediaTypeIcon';
import CallPickupButton from '../mediaComponents/CallPickupButton';
import { QueueMediaViewStage3 } from '../mediaComponents/QueueMediaView';
import QueueMediaHelper from 'utils/helpers/queue-media-helper';

export const WaitingCallsStage3 = () => {
    const initialGetIsDone = useRef(false);
    const waitingMediaObjectList = useRef<WaitingMediaDto[]>([]);//waiting media list received from server
    const [waitingMediaCount, setWaitingMediaCount] = useState(0);
    const intl = useIntl();
    const isPresenceStateDND = useRef(false);
    const isOngoingCall = useRef(false);
    const [displayMediaList, setDisplayMediaList] = useState<any[]>([]);

    let subscriptionWaitingAdded: Subscription | null = null;
    let subscriptionWaitingUpdated: Subscription | null = null;
    let subscriptionWaitingRemoved: Subscription | null = null;
    let subscriptionCurrentUserCallSessionChanged: Subscription | null = null;
    let subscriptionCallSessionStateChanged: Subscription | null = null;
    let subscriptionQueueMembershipAdded: Subscription | null = null;
    let subscriptionQueueMembershipRemoved: Subscription | null = null;
    let subscriptionUpdatedPresenceState: Subscription | null = null;
    let subscriptionInternalPresenceChanged: Subscription | null = null;

    const endOperations = GeneralHelper.getStage3EndOperations();

    const [refresh, setRefresh] = useState<number>(Date.now());
    const intervalMs: number = 11000;

    React.useEffect(() => {
        initialize();

        // refresh waiting list every 11 seconds CC4ALL-4640
        const interval = setInterval(() => setRefresh(Date.now()), intervalMs);

        return () => {
            componentWillUnmount();
            clearInterval(interval);
        }
    }, []);

    React.useEffect(() => {
        checkForOngoingCallAndRefreshList();
    }, [refresh]);

    const initialize = async () => {
        subscriptionWaitingAdded?.unsubscribe();
        subscriptionWaitingAdded = waitingListManager.onWaitingAdded.subscribe((obj: WaitingMediaDto) => {
            if (!checkAgentIsMemberedOnQueue(obj.QueueRef)) {
                return;
            }

            const elementExist = QueueMediaHelper.getRecordIndexBySessionId(waitingMediaObjectList.current, obj.SessionId) !== null;

            if (!elementExist) {
                //insert element by queue priority
                const indexToInsert = QueueMediaHelper.getLastElementIdexByQueuePriority(waitingMediaObjectList.current, obj, false);
                waitingMediaObjectList.current.splice(indexToInsert, 0, obj);

                refreshWaitingMediaRowListWithoutServerRequest();
            }
        })

        subscriptionWaitingUpdated?.unsubscribe();
        subscriptionWaitingUpdated = waitingListManager.onWaitingUpdated.subscribe((obj: WaitingMediaDto) => {
            const indexToUpdate = QueueMediaHelper.getRecordIndexBySessionId(waitingMediaObjectList.current, obj.SessionId);

            if (indexToUpdate != null) {
                waitingMediaObjectList.current = waitingMediaObjectList.current.map((item, index) => {
                    return index === indexToUpdate ? obj : item
                });
                refreshWaitingMediaRowListWithoutServerRequest();
            }
        });

        subscriptionWaitingRemoved?.unsubscribe();
        subscriptionWaitingRemoved = waitingListManager.onWaitingRemoved.subscribe((obj: WaitingMediaDto) => {
            const indexToDelete = QueueMediaHelper.getRecordIndexBySessionId(waitingMediaObjectList.current, obj.SessionId);

            if (indexToDelete != null) {
                waitingMediaObjectList.current = waitingMediaObjectList.current.filter((item, index) => indexToDelete !== index);
                refreshWaitingMediaRowListWithoutServerRequest();
            }
        });

        subscriptionCurrentUserCallSessionChanged?.unsubscribe();
        subscriptionCurrentUserCallSessionChanged = socketAudio.currentUserCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
            refreshWaitingMediaRowListWithoutServerRequest(callSessionDTO);
        });

        subscriptionCallSessionStateChanged?.unsubscribe();
        subscriptionCallSessionStateChanged = socketAudio.callSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
            refreshWaitingMediaRowListWithoutServerRequest(callSessionDTO);
        });

        subscriptionQueueMembershipAdded = queuesService.queueMembershipAdded.subscribe((queueChange: any) => {
            checkForOngoingCallAndRefreshList();
        });

        subscriptionQueueMembershipRemoved = queuesService.queueMembershipRemoved.subscribe((queue: any) => {
            checkForOngoingCallAndRefreshList();
        });

        checkForOngoingCallAndRefreshList();

        subscriptionUpdatedPresenceState?.unsubscribe();
        subscriptionUpdatedPresenceState = socketContacts.notifyUpdatedPresenceState.subscribe((userPresence: Presence) => {
            if (userPresence.Id === Lockr.get(StorageKeys.UserObjectId)) {
                isPresenceStateDND.current = userPresence.Availability === PresenceState.DND;
            }
        })

        subscriptionInternalPresenceChanged = serviceRequests.internalPresenceChange.subscribe((internalPresence: any) => {
            const userId = Lockr.get(StorageKeys.UserObjectId);
            if (internalPresence.Id === userId) {
                isPresenceStateDND.current = internalPresence.Presence === PresenceState.DND;
            }
        });

        const presence = await socketPresence.GetCurrentPresence();
        isPresenceStateDND.current = presence?.Availability === PresenceState.DND;
    };

    const componentWillUnmount = () => {
        subscriptionWaitingAdded?.unsubscribe();
        subscriptionWaitingUpdated?.unsubscribe();
        subscriptionWaitingRemoved?.unsubscribe();
        subscriptionCurrentUserCallSessionChanged?.unsubscribe();
        subscriptionCallSessionStateChanged?.unsubscribe();
        subscriptionQueueMembershipAdded?.unsubscribe();
        subscriptionQueueMembershipRemoved?.unsubscribe();
        subscriptionUpdatedPresenceState?.unsubscribe();
        subscriptionInternalPresenceChanged?.unsubscribe();
    };

    const checkAgentIsMemberedOnQueue = (queueId: string) => {
        const filterMemberedQueues = queuesService.queuesArray.filter((element: any) => element.QueueRef === queueId);

        return filterMemberedQueues && filterMemberedQueues.length;
    }

    const refreshWaitingMediaRowListWithoutServerRequest = (callSessionDTO?: CallSessionDTO) => {
        checkOngoingCall(callSessionDTO);
        mapMediaListObjects();
    }

    const getWaitingMedia = () => {
        waitingListManager.getWaitingMediaByAgent(Lockr.get<number>(StorageKeys.UserId)).then((response: WaitingMediaDto[]) => {
            waitingMediaObjectList.current = [];
            response.forEach((element: WaitingMediaDto) => {
                const alreadyAddedObj = waitingMediaObjectList.current.filter(object => object.SessionId === element.SessionId);
                if (alreadyAddedObj.length === 0) {
                    waitingMediaObjectList.current = [...waitingMediaObjectList.current, element];
                }
            });

            initialGetIsDone.current = true;
            mapMediaListObjects();
        }).catch(err => {
            console.log(err.message);
        });
    }

    const mapMediaListObjects = () => {
        let mediaList = [] as any[];

        waitingMediaObjectList.current.forEach((element: WaitingMediaDto) => {
            const alreadyAddedObj = mediaList.filter(item => item.key === element.SessionId)[0];

            if (!alreadyAddedObj) {
                mediaList = [...mediaList, mapItem(element)];
            }
        });

        setDisplayMediaList(mediaList);
        setWaitingMediaCount(mediaList.length);
    }


    const mapItem = (obj: any) => {
        return {
            key: obj.SessionId,
            queueid: obj.QueueRef,
            items: [
                {
                    content: <MediaTypeIcon status={obj.Status} mediaType={obj.MediaType} sessionId={obj.SessionId} />,
                    key: `${obj.SessionId?.toString()}-waiting-media-type-icon`,
                    className: GeneralHelper.getMediaTypeContainerClassName(obj.MediaType)
                },
                { content: obj.CallerName || obj.CallerPhone, key: `${obj.SessionId?.toString()}-waiting-caller-name`, className: "caller-name-column test-waiting-media-caller-name" },
                { content: obj.CallerPhone || obj.CallerName, key: `${obj.SessionId?.toString()}-waiting-caller-phone`, className: "caller-name-column test-waiting-media-caller-phone" },
                { content: <Timer startDate={obj.StartDate}></Timer>, key: `${obj.SessionId?.toString()}-waiting-timer`, className: "smaller-grow table-header-column test-waiting-media-timer" },
                { content: obj.QueueName, key: `${obj.SessionId?.toString()}-waiting-queue-name`, className: "smaller-grow table-header-column test-waiting-media-queue-name" },
                {
                    content: QueueMediaHelper.getQueueMediaStatusText(obj.Status, intl),
                    key: `${obj.SessionId?.toString()}-waiting-call-status`, className: "smaller-grow table-header-column test-waiting-media-status"
                },
                {
                    content: getPickUpButton(obj.IsCallPickupEnabled, obj.IsAssigned, obj.ScenarioId, obj.Status),
                    key: `${obj.SessionId?.toString()}-waiting-action-buttons`, className: "pickup-column test-waiting-media-action-buttons"
                }
            ]
        }
    }

    const getPickUpButton = (isCallPickupEnabled: boolean, isAssigned: boolean, scenarioId: string, status: QueueMediaStatusType) => {
        const isVisible = status !== QueueMediaStatusType.CallbackRequest && isCallPickupEnabled && !isOngoingCall.current && !isAssigned;

        return <CallPickupButton
            key={`${scenarioId}-waiting-pickup-btn`}
            isVisible={isVisible}
            callback={() => pickUpButtonOnClick(scenarioId)}
        />
    }

    const pickUpButtonOnClick = async (scenarioId: string) => {
        if (isPresenceStateDND.current) {
            logging.errorHandler.next("ErrorMessage.CallPickup.CallPickupDND");
            return;
        }
        isOngoingCall.current = true;
        mapMediaListObjects();

        const isCallPickedUp = await waitingListManager.pickUpCallStage3({ scenarioId: scenarioId, agentUserId: Lockr.get<string>(StorageKeys.UserId) })
        if (!isCallPickedUp) {
            logging.errorHandler.next("ErrorMessage.CallPickup.CallUnavailable");
        }
    }

    const checkForOngoingCallAndRefreshList = () => {
        checkOngoingCall();
        getWaitingMedia();
    }

    const checkOngoingCall = (callSession: CallSessionDTO = socketAudio.getCallSessionForCurrentUser()) => {
        isOngoingCall.current = !!callSession.ConversationKey && !endOperations.includes(callSession.ConferenceActionAsString)
    }

    return (
        <QueueMediaViewStage3 viewType={QueueMediaViewType.Waiting}
            mediaItems={displayMediaList}
            waitingMediaCount={waitingMediaCount}
        />
    )
};

export default WaitingCallsStage3;

import React, { useState, useRef } from 'react';
import {
  Flex, Text, Input, SearchIcon, Button, PhoneArrowIcon, Dialog,
  EditIcon, TrashCanIcon, UserFriendsIcon, ParticipantAddIcon, CallIcon, StarIcon, MenuButton
} from '@fluentui/react-northstar';
import { socketAudio } from '../../../../services/calling';
import { socketContacts } from '../../../../services/contacts';
import AdvancedTable, { stringCellComparator } from '../../../../components/AdvancedTable';
import { AddEditContactView, Paginator } from '../../../../containers';
import { useIntl } from 'react-intl';
import { logging } from '../../../../utils/logging';
import {
  StorageKeys, DefaultPageSize, ConferenceStatusAction, MonitorTypes,
  PresenceAvailability, CallableContactColumnsEnum, ContactColumns, ContactSipAdressColumnsEnum, SortDirection, SortableColumnValue
} from '../../../../utils';
import Lockr from 'lockr';
import './Contacts.scss';
import { Contact, PaginatedContacts } from '../../../../utils/domain/contact';
import { PresenceList, Presence } from '../../../../utils/domain/presence';
import { CallSessionDTO } from '../../../../utils/domain/callSessionDTO';
import AgentAvatar from '../../../../components/AgentAvatar';
import { FavoriteContactDTO, FavoriteContactType } from '../../../../utils/domain/favoriteContactDTO';
import { Subscription } from 'rxjs';
import { mainViewHelper } from '../../../../utils/helpers/main-view-helper';
import { GeneralHelper, EnvHelper } from '../../../../utils/helpers';
import { AzureActiveDirectorySettingsDTO, AzureAdAttributeSettingDTO, SipMappingType } from '../../../../utils/domain/azureActiveDirectorySettingsDTO';
import { socketOutbound } from '../../../../services/outbound';
import { socketTransfer } from '../../../../services/transfers';
import AgendaPopup from 'containers/AgendaView/AgendaPopUp';

const DefaultSipAddressColumn = "SipAddress";

type TableCellComparator<T> = (cell1: T, cell2: T) => number;

interface ContactColumn {
  title: string,
  className: string,
  key: string,
  name: string,
  cellComparator: TableCellComparator<string>
}

export const Contacts = () => {
  const intl = useIntl();
  const [contactList, setContactList] = useState([] as any);
  const [contacts, setContacts] = useState(new PaginatedContacts());
  const contactsRef = React.useRef(contacts);
  const contactsInitalized = useRef(false);
  const [listCount, setListCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [sortColumn, setSortColumn] = useState("Agent");
  const [sortDirection, setSortDirection] = useState(false);
  const [contactToUpdate, setContactToUpdate] = useState({});
  const [currentCallSession, setCurrentCallSession] = useState(new CallSessionDTO());

  const [idOfContactToDelete, setIdOfContactToDelete] = useState(-1);
  const [nameOfContactToDelete, setNameOfContactToDelete] = useState("");
  const [listKey, setListKey] = useState(Math.random() + 'keyls');

  const isAddEditContactDialogOpen = useRef(false);

  const favoriteContactsRef = useRef([] as FavoriteContactDTO[]);
  const [favoriteContacts, setFavoriteContacts] = useState([] as FavoriteContactDTO[]);
  const [favContactsChangedTrigger, setFavContactsChangedTrigger] = useState(0 as any);

  const [contactsAzureADSettings, setContactsAzureADSettings] = useState(new AzureActiveDirectorySettingsDTO());
  const [columns, setColumns] = useState([] as any[]);
  const [agentHasOutboundEnabled, setAgentHasOutboundEnabled] = useState(false);
  const [isWarmTransferInProgress, setIsWarmTransferInProgress] = useState(false);
  const [isMonitoring, setIsMonitoring] = useState(false);
  const maxNumberOfFavoriteContactsReached = useRef(0);

  let subscriptionCurrentUserCallSessionStateChanged: Subscription | null = null;
  let subscriptionPresence: Subscription | null = null;
  let subscriptionFavoriteContactsChanged: Subscription | null = null;
  let subscriptionAzureADSettingsForContactsChanged: Subscription | null = null;
  let subscriptionAgentHasOutboundAccessEnabled: Subscription | null = null;
  let subscriptionWarmTransferInProgress: Subscription | null = null;
  let subscriptionCurrentUserMonitoredCallSessionChanged: Subscription | null = null;
  let subscriptionMaxNumberOfFavoriteContactReached: Subscription | null = null;
  let subscriptionFavoriteContactsPresenceChanged: Subscription | null = null;

  const callableContactColumns = Object.keys(CallableContactColumnsEnum);

  React.useEffect(() => {
    initialize();

    return () => {
      componentWillUnmount();
    }
  }, []);

  const setupContactsColumnsHeader = () => {
    setColumns([]);

    if (contactsAzureADSettings && contactsAzureADSettings.Attributes) {
      contactsAzureADSettings.Attributes.forEach((attribute: AzureAdAttributeSettingDTO) => {
        const isSip = isSipColumn(attribute.ColumnName, contactsAzureADSettings.SipMappingType);

        const col: ContactColumn = {
          title: intl.formatMessage({ id: `AgentView.${attribute.ColumnName}` }),
          key: attribute.ColumnName,
          name: "Contacts" + attribute.ColumnName,
          cellComparator: stringCellComparator,
          className: ''
        }
        if (attribute.ColumnName === 'DisplayName') {
          col.className = 'bigger-grow';
        } else if (attribute.ColumnName === 'Mail') {
          col.className = 'bigger-grow table-column';
        } else if (!callableContactColumns.includes(attribute.ColumnName)) {
          col.className = 'small-grow table-column';
        }

        if (!callableContactColumns.includes(attribute.ColumnName) && !isSip) {
          setColumns(cols => [...cols, col]);
        }
      });

      const transferColumn: ContactColumn = {
        title: '',
        key: 'Actions',
        name: 'ContactTransferColumn',
        cellComparator: stringCellComparator,
        className: "jend contacts-actions"
      }
      setColumns(cols => [...cols, transferColumn]);

      mapContacts(contacts);
    }
  }

  const componentWillUnmount = () => {
    subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
    subscriptionPresence?.unsubscribe();
    subscriptionFavoriteContactsChanged?.unsubscribe();
    subscriptionAzureADSettingsForContactsChanged?.unsubscribe();
    subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
    subscriptionWarmTransferInProgress?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
    subscriptionMaxNumberOfFavoriteContactReached?.unsubscribe();
    subscriptionFavoriteContactsPresenceChanged?.unsubscribe();
  }

  const initialize = () => {
    GeneralHelper.logCox(`in Contacts.tsx, in initialize`);

    subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
    subscriptionCurrentUserCallSessionStateChanged = socketAudio.currentUserCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      setCurrentCallSession(callSessionDTO);
    });

    subscriptionPresence?.unsubscribe();
    subscriptionPresence = socketContacts.notifyUpdatedPresenceState.subscribe((userPresence: Presence) => {
      updatePresence(Array(1).fill(userPresence));
    });

    checkIsAgentOutboundAccessEnabled();
    if (contactsInitalized.current === false) {
      getContacts()
    }

    getFavoriteContacts();
    subscriptionFavoriteContactsChanged?.unsubscribe();
    subscriptionFavoriteContactsChanged = mainViewHelper.addedOrRemovedFavoriteContact.subscribe((favContact: FavoriteContactDTO) => {
      if (favContact.IsDeleted) {
        removeFromFavoritesList(favContact);
      }
    });

    subscriptionAzureADSettingsForContactsChanged?.unsubscribe();
    subscriptionAzureADSettingsForContactsChanged = socketContacts.azureADSettingsForContactsChanged.subscribe((azureADSettings: AzureActiveDirectorySettingsDTO) => {
      setContactsAzureADSettings(azureADSettings);
    })

    getAzureActiveDirectorySettingsForContacts();
    subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
    subscriptionAgentHasOutboundAccessEnabled = socketOutbound.agentHasOutboundAccessEnabled.subscribe((isOutboundEnabled: boolean) => {
      setAgentHasOutboundEnabled(isOutboundEnabled);
    });

    subscriptionWarmTransferInProgress?.unsubscribe();
    subscriptionWarmTransferInProgress = socketAudio.warmTransferInProgress.subscribe((callSessionDTO: CallSessionDTO) => {
      const sip = Lockr.get<string>(StorageKeys.SIP);
      const isWarm = (callSessionDTO.TransferTargetUri && callSessionDTO.TransferTargetUri === sip &&
        (callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.WarmCanceled] &&
          callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent] &&
          callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.WarmAccepted])) || false

      setIsWarmTransferInProgress(isWarm);
    });

    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged = socketAudio.currentUserMonitoredCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      if (callSessionDTO.MonitorType === MonitorTypes.None || callSessionDTO.IsComputedAsTerminated) {
        setIsMonitoring(false);
      } else {
        setIsMonitoring(true);
      }
    });

    subscriptionMaxNumberOfFavoriteContactReached?.unsubscribe();
    subscriptionMaxNumberOfFavoriteContactReached = socketContacts.maxNumberOfFavoriteContactReached.subscribe((obj: number) => {
      maxNumberOfFavoriteContactsReached.current = obj;
    });

    subscriptionFavoriteContactsPresenceChanged?.unsubscribe();
    subscriptionFavoriteContactsPresenceChanged = socketContacts.favoriteContactsPresenceChanged.subscribe((obj: boolean) => {
      getAndUpdatePresence(contactsRef.current);
    });
  }

  const setContactsStateAndRef = (paginatedContacts: PaginatedContacts) => {
    setContacts(paginatedContacts);
    contactsRef.current = paginatedContacts;
  }

  const getAzureActiveDirectorySettingsForContacts = () => {
    socketContacts.getCachedAzureActiveDirectorySettings().then((azureADSettings: AzureActiveDirectorySettingsDTO) => {
      setContactsAzureADSettings(azureADSettings);
      setupContactsColumnsHeader();
    });
  }

  const checkIsAgentOutboundAccessEnabled = () => {
    setAgentHasOutboundEnabled(socketOutbound.HasOutboundAccesEnabled());
  }

  const getContacts = () => {
    const userSip = Lockr.get<number>(StorageKeys.SIP);
    const startPage = currentPage * DefaultPageSize - DefaultPageSize;

    socketContacts.getContacts({
      Keyword: searchText, Start: startPage,
      Size: DefaultPageSize, SortColumn: sortColumn, SortDirection: sortDirection, ExcludeIds: Lockr.get(StorageKeys.UserObjectId),
      UserSip: userSip
    }).then((result: any) => {
      contactsInitalized.current = true
      setContactsStateAndRef(result);
      initializeNumberOfPages(result.Count ? result.Count : 0);
    });
  }

  const handleTransfer = (contact: Contact, target: string, isSip: boolean | undefined = undefined, isWarm: boolean = false) => {
    let transferTo: string | undefined;
    let sipAddress = contact.SipAddress;

    if (contact.ContactSourceType === FavoriteContactType.AzureActiveDirectory) {
      sipAddress = target;
    }

    if (isSip === undefined) {
      transferTo = sipAddress ? sipAddress : target;
    } else {
      transferTo = isSip ? sipAddress : target;
    }

    if (isWarm) {
      socketTransfer.onWarmTransferStarted.next({ 'transferTo': transferTo, 'contactType': contact.ContactType });
    }
    else {
      socketTransfer.onColdTransferStarted.next({ 'transferTo': transferTo, 'contactType': contact.ContactType });
    }
  }

  const addRemoveFavorite = (contact: Contact) => {
    const favoriteContact: FavoriteContactDTO = {
      ID: contact.FavoriteConactId ? contact.FavoriteConactId : 0,
      AgentRef: Lockr.get<number>(StorageKeys.UserId),
      DisplayName: contact.DisplayName,
      SIP: contact.ContactSourceType === FavoriteContactType.AzureActiveDirectory ? '' : contact.SipAddress,
      FavoriteContactTypeId: contact.ContactSourceType ? contact.ContactSourceType : FavoriteContactType.Unknown,
      PhoneNumber: contact.LocalPhone.replace('sip:', ''),
      ContactID: contact.ExternalId ? contact.ExternalId : '',
      IsDeleted: false,
      Upn: contact.Upn,
      Email: contact.Email,
      BusinessPhones: contact.BusinessPhones,
      MobilePhone: contact.MobilePhone
    };

    if (contact.IsFavorite) {
      socketContacts.removeFavoriteContact(favoriteContact).then((response: boolean) => {
        if (response) {
          favoriteContact.IsDeleted = true;
          removeFromFavoritesList(favoriteContact);
          mainViewHelper.addedOrRemovedFavoriteContact.next(favoriteContact);
          socketContacts.hasAnyFavoriteContactChanged.next(true);
        } else {
          logging.errorHandler.next("ErrorMessage.FavoriteContact.RemoveContact");
        }
      }).catch(err => {
        logging.errorHandler.next("ErrorMessage.FavoriteContact.RemoveContact");
        console.log(err.message);
      });
    } else {
      if (maxNumberOfFavoriteContactsReached.current + 1 <= socketContacts.getMaxNumberOfFavoriteContacts()) {
        socketContacts.addFavoriteContact(favoriteContact).then((favoriteContactId: number) => {
          if (favoriteContactId) {
            favoriteContact.ID = favoriteContactId;
            addToFavoritesList(favoriteContact);
            mainViewHelper.addedOrRemovedFavoriteContact.next(favoriteContact);
            socketContacts.hasAnyFavoriteContactChanged.next(true);
          } else {
            logging.errorHandler.next("ErrorMessage.FavoriteContact.AddContact");
          }
        }).catch(err => {
          logging.errorHandler.next("ErrorMessage.FavoriteContact.AddContact");
          console.log(err.message);
        });
      } else {
        logging.errorHandler.next(intl.formatMessage({ id: "ErrorMessage.FavoriteContact.MaxNumberOfFavoriteContactsReached" }));
      }
    }
  }

  const checkIsFavoriteAndGetDetails = (contact: Contact) => {
    const favContact: Contact = new Contact();
    favContact.FavoriteConactId = 0;
    favContact.IsFavorite = false;

    if (favoriteContactsRef.current.length) {
      const matchingContacts = favoriteContactsRef.current.filter((el: FavoriteContactDTO) => {
        return el.FavoriteContactTypeId === contact.ContactSourceType && el.ContactID && el.ContactID === contact.ExternalId;
      });

      if (matchingContacts && matchingContacts.length) {
        favContact.FavoriteConactId = matchingContacts[0].ID;
        favContact.IsFavorite = true;
      }
    }

    return favContact;
  }

  const removeFromFavoritesList = (favoriteContact: FavoriteContactDTO) => {
    const removedIndex = favoriteContactsRef.current.findIndex(x => x.ID === favoriteContact.ID);

    if (removedIndex > -1) {
      favoriteContactsRef.current.splice(removedIndex, 1);
      setFavoriteContacts(favoriteContactsRef.current);
      setFavContactsChangedTrigger(Math.random());

      const resetPresence: Presence = {
        Id: favoriteContact.ContactID,
        Availability: 'Busy',
        Activity: 'Busy',
        PresenceType: PresenceAvailability.None
      };
      updatePresence(Array(1).fill(resetPresence));
    }
  }

  const addToFavoritesList = (favoriteContact: FavoriteContactDTO) => {
    const existingIndex = favoriteContactsRef.current.findIndex(x => x.ID === favoriteContact.ID);

    if (existingIndex < 0) {
      favoriteContactsRef.current.push(favoriteContact);
      setFavoriteContacts(favoriteContactsRef.current);
      setFavContactsChangedTrigger(Math.random());
    }
  }

  const getFavoriteContacts = () => {
    const userId = Lockr.get<number>(StorageKeys.UserId);

    socketContacts.getFavoriteContactsByAgentId(userId).then((favoriteContactsList: FavoriteContactDTO[] | null) => {
      if (favoriteContactsList) {
        favoriteContactsRef.current = favoriteContactsList;
        setFavoriteContacts(favoriteContactsRef.current);
      }
    });
  }

  const agentIsNotInACall = (azureAId: string) => {
    const isCallParked = GeneralHelper.isCallParkedOrFallback(currentCallSession.ActionHistory, ConferenceStatusAction.OperatorPark);
    const isFallbackCall = GeneralHelper.isCallParkedOrFallback(currentCallSession.ActionHistory, ConferenceStatusAction.Fallback);

    return (!currentCallSession.Started ||
      currentCallSession.Transferring ||
      currentCallSession?.ParticipantIds?.includes(azureAId) ||
      currentCallSession.IsTeamsUnparkInProgress ||
      isCallParked || isFallbackCall ||
      GeneralHelper.isRinging(currentCallSession.ActionHistory));
  }

  const mapContacts = (result: PaginatedContacts) => {
    const userList: any = [];

    result.ResultList?.forEach((element: Contact, index: any) => {
      const checkIsFavoriteDetails = checkIsFavoriteAndGetDetails(element);
      element.IsFavorite = checkIsFavoriteDetails.IsFavorite;
      element.FavoriteConactId = checkIsFavoriteDetails.FavoriteConactId;

      const items: any = [];
      if (contactsAzureADSettings && contactsAzureADSettings.Attributes) {
        contactsAzureADSettings.Attributes.forEach((setting: AzureAdAttributeSettingDTO) => {
          const columnName = getContactsColumnsName(setting.ColumnName);
          const isSip = isSipColumn(setting.ColumnName, contactsAzureADSettings.SipMappingType);

          if (columnName === "DisplayName") {
            const presenceStatus = getPresenceStatus(element.PresenceDto.Availability, element.PresenceDto.Activity);
            const azureId = element.ExternalId;
            items.push({
              content: <Flex vAlign="center" className="contacts-display-name">
                <AgentAvatar 
                  status={presenceStatus} 
                  name={element.DisplayName}
                  showPopup
                  azureID={azureId}
                  contactSourceType={element.ContactSourceType}
                />
              </Flex>,
              key: listKey + index.toString() + columnName,
              className: "bigger-grow display-name-cell",
              displayname: element.DisplayName,
              title: element.DisplayName
            });
          } else if (!callableContactColumns.includes(columnName as string) && !isSip) {
            const className = (columnName === "Email") ? "bigger-grow table-column" : "small-grow table-column";
            items.push({
              content: element[columnName as keyof Contact],
              key: listKey + index.toString() + columnName,
              className: className,
              displayname: element[columnName as keyof Contact],
              title: element[columnName as keyof Contact]
            });
          }
        });

        const showEditDeleteBtns = element.ContactType === 1;

        items.push(
          {
            content: <Flex vAlign="center" hAlign="end">
              {showEditDeleteBtns && <Button text iconOnly icon={<EditIcon size="medium" outline />} size="medium"
                title={intl.formatMessage({ id: "ContactsView.EditContact" })} onClick={() => toggleAddEditContactButton(element)} />}
              {showEditDeleteBtns && <Button text iconOnly icon={<TrashCanIcon size="medium" outline />} size="medium"
                title={intl.formatMessage({ id: "ContactsView.DeleteContact" })} onClick={() => toggleDeleteContactButton(element)} />}
              {agentIsNotInACall(element.AzureADId)
                ? getNotInACallActions(element)
                : getInCallActions(element)
              }
              <Button className={element?.IsFavorite ? "favorite-star-button active-favorite" : "favorite-star-button"} text iconOnly
                icon={<StarIcon className="" outline size="medium" />}
                title={element.IsFavorite ?
                  intl.formatMessage({ id: "CallActions.RemoveFromFavorites" }) :
                  intl.formatMessage({ id: "CallActions.AddToFavorites" })} size="medium"
                onClick={() => addRemoveFavorite(element)} />
              {GeneralHelper.isCalendarEnabled() &&
                <AgendaPopup azureId={element.ExternalId}
                  isDisabled={hasExternalId(element) && element.ContactSourceType !== FavoriteContactType.AzureActiveDirectory}
                />
              }
            </Flex>,
            key: `${listKey}${index.toString()}-1`,
            className: "jend contacts-actions"
          },
        );

        const userSip = Lockr.get<string>(StorageKeys.SIP);
        const upnSip = element.Upn ? 'sip:' + element.Upn.toLocaleLowerCase() : '';
        const elementSip = element.ContactSourceType === FavoriteContactType.AzureActiveDirectory ? upnSip : element.SipAddress?.toLocaleLowerCase();

        if (userSip !== element.SipAddress?.toLocaleLowerCase()) {
          userList.push(
            {
              key: element.AzureADId ?? index.toString(),
              sip: (elementSip || '').toLowerCase(),
              externalId: (element.ExternalId || '').toLowerCase(),
              presenceType: element.PresenceDto.PresenceType,
              items: items,
              contactSourceType: element.ContactSourceType || FavoriteContactType.Unknown
            }
          );
        }
      }
    });

    setContactList(userList);
    setListCount(result.Count ? result.Count : 0);
    getAndUpdatePresence(result);
  }

  const getPresenceStatus = (availability: string, activity: string | undefined) => {
    return activity || availability;
  }

  const getAndUpdatePresence = (pagedContacts: PaginatedContacts) => {
    if (pagedContacts.ResultList) {
      const companyKey = Lockr.get<string>(StorageKeys.CompanyKey);
      const filteredPageContacts = pagedContacts.ResultList.filter(x => x.ExternalId && x.ExternalId.length && x.IsFavorite);
      const param = { Ids: filteredPageContacts.map(({ ExternalId }) => ExternalId), CompanyKey: companyKey };
      getAndUpdateFavoriteContactsPresence(param);
    }
  }

  const getAndUpdateFavoriteContactsPresence = (param: any) => {
    socketContacts.getFavoriteContactsPresence(param).then((presenceResult: PresenceList) => {
      const presenceList = presenceResult.ResultList;
      updatePresence(presenceList);
    });
  }

  const updatePresence = (presences: Presence[]) => {
    let updated: boolean = false;

    setContactList((items: any[]) => {
      items.forEach((el, index) => {
        const presence = presences.find(({ Id }) =>
          (Id?.toLowerCase()) === (EnvHelper.isStage3() ? el.key : el.sip) ||
          (Id?.toLowerCase() === el.externalId && el?.presenceType === PresenceAvailability.None))
        if (presence && presence.Availability) {
          const displayNameColumnIndex = getDisplayNameIndexInArray(items[index].items);

          items[index].items[displayNameColumnIndex].content = <AgentAvatar
            status={getPresenceStatus(presence.Availability, presence.Activity)}
            name={items[index].items[displayNameColumnIndex].displayname}
            showPopup
            contactSourceType={items[index].contactSourceType}
            azureID={items[index].externalId} />;

          updated = true;

          setContacts((pagedItems: PaginatedContacts) => {
            pagedItems.ResultList.forEach((element) => {
              if (element.SipAddress === presence?.Id) {
                element.PresenceDto = presence;
              }
            });
            return pagedItems;
          });
        }
      });

      return items;
    });

    if (updated) {
      setListKey(() => Math.random() + "keyls");
    }
  }

  const getContactsColumnsName = (column: string) => {
    switch (column) {
      case ContactColumns.DisplayName:
        return ContactColumns.DisplayName;
      case ContactColumns.LocalPhone:
        return ContactColumns.LocalPhone;
      case ContactColumns.Upn:
        return ContactColumns.Upn;
      case ContactColumns.Mail:
        return ContactColumns.Email;
      case ContactColumns.BusinessPhones:
        return ContactColumns.BusinessPhones;
      case ContactColumns.MobilePhone:
        return ContactColumns.MobilePhone;
      case ContactColumns.JobTitle:
        return ContactColumns.JobTitle;
      case ContactColumns.Department:
        return ContactColumns.Department;
      case ContactColumns.City:
        return ContactColumns.City;
      case ContactColumns.OfficeLocation:
        return ContactColumns.OfficeLocation;
      case ContactColumns.Country:
        return ContactColumns.Country;
      case ContactColumns.State:
        return ContactColumns.State;
      case ContactColumns.PostalCode:
        return ContactColumns.PostalCode;
      case ContactColumns.UserPrincipalName:
        return ContactColumns.UserPrincipalName;
      default:
        return ""
    }
  }

  const getNotInACallActions = (element: Contact) => {
    const showCallButton = (!GeneralHelper.isRinging(currentCallSession.ActionHistory) &&
      !currentCallSession.Transferring && !isWarmTransferInProgress && !isMonitoring) &&
      element.ContactSourceType !== FavoriteContactType.CC4SkypeQueue;

    return (showCallButton ?
      <MenuButton align="end"
        trigger={<Button icon={<CallIcon outline />} iconOnly text title={intl.formatMessage({ id: "CallActions.Call" })} />}
        menu={getCallableItemsList(element, false, false)}
        on="click"
      /> : <Button icon={<CallIcon outline />} disabled={true} iconOnly text title={intl.formatMessage({ id: "CallActions.Call" })} />
    );
  }

  const getInCallActions = (element: Contact) => {
    return (
      <>
        {element.ContactSourceType !== FavoriteContactType.CC4SkypeQueue ?
          <MenuButton align="end"
            trigger={<Button icon={<UserFriendsIcon outline />} iconOnly text title={intl.formatMessage({ id: "CallActions.WarmTransfer" })} />}
            menu={getCallableItemsList(element, true, true)}
            on="click"
          /> : <Button icon={<UserFriendsIcon outline />} iconOnly disabled={true} text title={intl.formatMessage({ id: "CallActions.ColdTransfer" })} />}
        <MenuButton align="end"
          trigger={<Button icon={<PhoneArrowIcon outline />} iconOnly text title={intl.formatMessage({ id: "CallActions.ColdTransfer" })} />}
          menu={getCallableItemsList(element, true, false)}
          on="click"
        />
      </>
    );
  }

  const getCallableItemsList = (contact: Contact, isTransfer: boolean, isWarmTransfer: boolean) => {
    const items: any = [];
    let className = '';
    if (contactsAzureADSettings && contactsAzureADSettings.Attributes) {
      contactsAzureADSettings.Attributes.forEach((setting: AzureAdAttributeSettingDTO) => {
        const columnName = getContactsColumnsName(setting.ColumnName);
        const columnValue = contact[columnName as keyof Contact];
        let value = '';
        if (Array.isArray(columnValue)) {
          value = columnValue[0];
        } else {
          value = columnValue;
        }

        const isSip = contact.ContactSourceType === FavoriteContactType.AzureActiveDirectory && isSipColumn(setting.ColumnName, contactsAzureADSettings.SipMappingType);

        if (callableContactColumns.includes(setting.ColumnName) || isSip) {
          className = "";
          items.push(
            getCallableItem(contact, isTransfer, isWarmTransfer, setting.ColumnName, className, value, isSip)
          );
        }
      });

      if (contact.ContactSourceType !== FavoriteContactType.AzureActiveDirectory && contact.ContactSourceType !== FavoriteContactType.CC4SkypeQueue) {
        className = (agentHasOutboundEnabled === false && !isTransfer) ? "callable-item-readonly" : "";
        items.push(
          getCallableItem(contact, isTransfer, isWarmTransfer, DefaultSipAddressColumn, className, contact.SipAddress, true)
        );
      }

      if (contact.ContactSourceType === FavoriteContactType.CC4SkypeQueue) {
        items.push(
          getCallableItem(contact, isTransfer, isWarmTransfer, CallableContactColumnsEnum.QueueName, "", contact.SipAddress, true)
        );
      }
    }
    return items;
  }

  const isSipColumn = (columnName: string, sipMappingType: SipMappingType) => {
    const isUpnSip = columnName === ContactSipAdressColumnsEnum.Upn && sipMappingType === SipMappingType.UPN;
    const isMailSip = columnName === ContactSipAdressColumnsEnum.Mail && sipMappingType === SipMappingType.Mail;

    return isUpnSip || isMailSip;
  }

  const getCallableItem = (contact: Contact, isTransfer: boolean, isWarmTransfer: boolean, callableColumn: string, className: string, data: string, isSip: boolean) => {
    let content = '-';

    if (data !== null && data !== undefined && data !== '' && data.length !== 0 && !(contact.ContactSourceType === FavoriteContactType.CC4SkypeQueue && isSip)) {
      content = data?.toString().replace("sip:", '');
    }

    if (contact.ContactSourceType === FavoriteContactType.CC4SkypeQueue && callableColumn === CallableContactColumnsEnum.QueueName) {
      content = contact.DisplayName;
    }

    return (
      {
        content:
          (
            <Flex gap="gap.small" vAlign="center" className="callable-item">
              {getIconForCallableItems(callableColumn)}
              <Text
                content={content}
                className="callable-item-text"
              />
            </Flex>
          ),
        className: className,
        title: contact.ContactSourceType === FavoriteContactType.CC4SkypeQueue && isSip ? "" : data,
        key: `item-details-${data}${Math.random()}`,
        onClick: () => { handleCallButtonPressed(contact, data, isSip, isTransfer, isWarmTransfer) }
      }
    );
  }

  const handleCallButtonPressed = (contact: Contact, target: string, isSip: boolean, isTransfer: boolean, isWarmTransfer: boolean) => {
    makeCallAction(contact, target, isSip, isTransfer, isWarmTransfer);
  }

  const getIconForCallableItems = (columnName: string) => {
    switch (columnName) {
      case ContactSipAdressColumnsEnum.Upn:
      case ContactSipAdressColumnsEnum.Mail:
      case DefaultSipAddressColumn:
        return <img alt='SIP Icon' width="14" className="sip-icon" title={intl.formatMessage({ id: "AgentView.SipAddress" })} />;
      case CallableContactColumnsEnum.QueueName:
        return <img alt='Queue NAme Icon' width="14" className="sip-icon" title={intl.formatMessage({ id: "AgentView.QueueName" })} />;
      case CallableContactColumnsEnum.LocalPhone:
        return <CallIcon outline title={intl.formatMessage({ id: "AgentView.LocalPhone" })} />;
      case CallableContactColumnsEnum.MobilePhone:
        return <img alt='Mobile Phone Icon' width="17" className="mobile-phone-icon" title={intl.formatMessage({ id: "AgentView.MobilePhone" })} />
      case CallableContactColumnsEnum.BusinessPhones:
        return <img alt='Business Phone Icon' width="15" className="business-phone-icon" title={intl.formatMessage({ id: "AgentView.BusinessPhones" })} />
      default:
        return <></>
    }
  }

  const makeCallAction = (contact: Contact, target: string, isSip: boolean, isTransfer: boolean, isWarmTransfer: boolean) => {
    if (!target) {
      return;
    }

    if (isTransfer) {
      handleTransfer(contact, target, isSip, isWarmTransfer);
    } else if (agentHasOutboundEnabled) {
      performCall(target);
    }
  }

  const performCall = (value: string) => {
    value = Array.isArray(value) ? value[0] : value;

    let contactNumberValue = GeneralHelper.isMobileNumber(value) ? GeneralHelper.getMobileNumberFromSip(value) : value
    contactNumberValue = contactNumberValue ? contactNumberValue.toLowerCase().replace("tel:", "") : contactNumberValue;
    mainViewHelper.navigationViewGoToDialpad.next(contactNumberValue);
  }

  const getDisplayNameIndexInArray = (array: any[]) => {
    let index = 0;
    array.forEach((element: any, i: number) => {
      if (element.key.includes("DisplayName")) {
        index = i;
      }
    });
    return index;
  }

  const initializeNumberOfPages = (result: any) => {
    let nrOfPages = 0;
    if (result % 10 === 0) {
      nrOfPages = Math.floor(result / 10);
    }
    else {
      nrOfPages = Math.floor(result / 10) + 1;
    }

    nrOfPages === 0 ? setNumberOfPages(1) : setNumberOfPages(nrOfPages);
  }

  const searchContacts = (e: any) => {
    if (e.keyCode === 13 && e.target.value.length > 2) {
      coreSearchContact(e.target.value);
    }
  }

  const searchContactsOnChange = (e: any) => {
    if (e.target.value.length === 0) {
      coreSearchContact(e.target.value);
    }
  }

  const coreSearchContact = (e: any) => {
    setSearchText(e);
    setCurrentPage(1);

    const userSip = Lockr.get<number>(StorageKeys.SIP);

    socketContacts.getContacts({
      Keyword: e, Start: 0, Size: DefaultPageSize, SortColumn: sortColumn,
      SortDirection: sortDirection, ExcludeIds: Lockr.get(StorageKeys.UserObjectId),
      UserSip: userSip
    }).then((result: any) => {
      setContactsStateAndRef(result);
      initializeNumberOfPages(result.Count);
    });
  }

  const selectPage = (e: any) => {
    setCurrentPage(e);

    const userSip = Lockr.get<number>(StorageKeys.SIP);
    const startPage = e * DefaultPageSize - DefaultPageSize;

    socketContacts.getContacts({
      Keyword: searchText, Start: startPage,
      Size: DefaultPageSize, SortColumn: sortColumn, SortDirection: sortDirection, ExcludeIds: Lockr.get(StorageKeys.UserObjectId),
      UserSip: userSip
    }).then((result: any) => {
      setContactsStateAndRef(result);
    });
  }

  const sortHandler = (srtColumn: any, srtDirection: any) => {
    const columnValue = getColumnValue(srtColumn);
    setSortColumn(columnValue);
    setSortDirection(srtDirection === SortDirection.Asc);

    const userSip = Lockr.get<number>(StorageKeys.SIP);
    const startPage = currentPage * DefaultPageSize - DefaultPageSize;

    socketContacts.getContacts({
      Keyword: searchText, Start: startPage,
      Size: DefaultPageSize, SortColumn: columnValue, SortDirection: srtDirection === 1, ExcludeIds: Lockr.get(StorageKeys.UserObjectId),
      UserSip: userSip
    }).then((result: any) => {
      setContactsStateAndRef(result);
    });
  }

  const getColumnValue = (columnValue: string) => {
    switch (columnValue) {
      case SortableColumnValue.ContactsDisplayName:
        return "Agent";
      case SortableColumnValue.ContactsMail:
        return "Mail";
      case SortableColumnValue.ContactsJobTitle:
        return "JobTitle";
      case SortableColumnValue.ContactsDepartment:
        return "Department";
      case SortableColumnValue.ContactsCity:
        return "City";
      case SortableColumnValue.ContactsOfficeLocation:
        return "OfficeLocation";
      case SortableColumnValue.ContactsCountry:
        return "Country";
      case SortableColumnValue.ContactsState:
        return "State";
      case SortableColumnValue.ContactsPostalCode:
        return "PostalCode";
      default:
        return "Agent";
    }
  }

  const toggleAddEditContactButton = (contact?: any) => {
    isAddEditContactDialogOpen.current = !isAddEditContactDialogOpen.current;
    setListKey(() => Math.random() + "keyls");
    setContactToUpdate(contact);
  }

  const toggleDeleteContactButton = (contact: any) => {
    setIdOfContactToDelete(contact.ID);
    setNameOfContactToDelete(contact.DisplayName);
  }

  const deleteContact = () => {
    socketContacts.deleteContact(idOfContactToDelete).then((response: any) => {
      if (response.Result === 2) {
        logging.errorHandler.next("SuccessMessage.DeleteContact");
        setIdOfContactToDelete(-1);

        setTimeout(function () {
          getContacts();
        }, 1000);
      } else {
        logging.errorHandler.next("ErrorMessage.DeleteContact");
      }
    }).catch((err: { message: any; }) => {
      logging.errorHandler.next("ErrorMessage.DeleteContact");
      console.log(err.message);
    });
  }

  const hasExternalId = (element: any) => {
    return element && element.ExternalId?.length > 0;
  }

  React.useEffect(() => {
    mapContacts(contacts);
  }, [currentCallSession.Started, currentCallSession.Transferring,
  currentCallSession.IsTeamsUnparkInProgress, currentCallSession.ActionHistory, currentCallSession.IsWarmTransferInProgress]);

  React.useEffect(() => {
    mapContacts(contacts);
  }, [contacts, favoriteContacts]);

  React.useEffect(() => {
    if (favContactsChangedTrigger) {
      mapContacts(contacts);
    }
  }, [favContactsChangedTrigger]);

  React.useEffect(() => {
    setupContactsColumnsHeader();
  }, [contactsAzureADSettings, agentHasOutboundEnabled, isWarmTransferInProgress, isMonitoring]);

  const getContactsSearchBox = () => {
    return <Input icon={<SearchIcon size="small" />} iconPosition="start" inverted
      placeholder={intl.formatMessage({ id: "ContactsView.Search" })}
      onChange={searchContactsOnChange} onKeyDown={searchContacts} />
  }

  return (
    <>
      <Flex column>
        <Flex space="between" vAlign="center">
          <Text content={`${intl.formatMessage({ id: "AgentView.Contacts" })} (${listCount.toString()})`} weight="bold" />
          <Flex vAlign="center" gap="gap.medium">
            <div className="main-search">
              {getContactsSearchBox()}
            </div>
            <Button icon={<ParticipantAddIcon outline />}
              content={intl.formatMessage({ id: "ContactsView.AddNewContact" })} primary
              onClick={() => toggleAddEditContactButton()}
              className="add-new-contact-button" />
          </Flex>
        </Flex>
        <div className="responsive-search">
          {getContactsSearchBox()}
        </div>
        <Dialog open={isAddEditContactDialogOpen.current}
          content={<AddEditContactView
            closeAddContactPortal={() => toggleAddEditContactButton()}
            getAllContacts={() => getContacts()}
            contactToUpdate={contactToUpdate}
          />}
          header={contactToUpdate === null ? intl.formatMessage({ id: "ContactsView.AddContact" }) : intl.formatMessage({ id: "ContactsView.EditContact" })}
          styles={{ width: "600px" }}
          className="add-edit-dialog"
        />

        <Dialog
          open={idOfContactToDelete > 0}
          confirmButton={intl.formatMessage({ id: "ContactsView.DeleteContactConfirm" })}
          onConfirm={deleteContact}
          cancelButton={intl.formatMessage({ id: "ContactsView.DeleteContactCancel" })}
          onCancel={() => setIdOfContactToDelete(-1)}
          header={intl.formatMessage({ id: "ContactsView.DeleteContact" })}
          content={nameOfContactToDelete + intl.formatMessage({ id: "ContactsView.ContactWillBeDeletedMessage" })}
          styles={{ width: "500px" }}
        />

        <AdvancedTable className="contact-table" columns={columns} rows={contactList} sortHandler={sortHandler} label={intl.formatMessage({ id: "AgentView.Contacts" })} />
        <Paginator
          currentPage={currentPage}
          totalPages={numberOfPages}
          onChange={selectPage}
        />
      </Flex>
    </>
  )
};

export default Contacts;

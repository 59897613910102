import { useIntl } from 'react-intl';
import { stringCellComparator } from '../../../../components/AdvancedTable';
import './SupervisorCallsView.scss'

export const ColumnsResolver = () => {
  const intl = useIntl();

  const resposiveColumnClassName = 'big-grow table-column';

  return [
    { title: '', key: 'Media', name: 'SupervisorCallsViewMediaColumn', cellComparator: stringCellComparator, className: "smallest-grow" },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.Caller" }),
      key: 'Caller', name: 'SupervisorCallsViewCallerColumn', cellComparator: stringCellComparator, className: "caller-name-column"
    },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.Time" }),
      key: 'Time', name: 'SupervisorCallsViewTimeColumn', cellComparator: stringCellComparator, className: resposiveColumnClassName
    },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.Queue" }),
      key: 'Queue', name: 'SupervisorCallsViewQueueColumn', cellComparator: stringCellComparator, className: resposiveColumnClassName
    },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.Skill" }),
      key: 'Skill', name: 'SupervisorCallsViewSkillColumn', cellComparator: stringCellComparator, className: resposiveColumnClassName
    },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.Status" }),
      key: 'Status', name: 'SupervisorCallsViewStatusColumn', cellComparator: stringCellComparator, className: resposiveColumnClassName
    },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.Agent" }),
      key: 'Agent', name: 'SupervisorCallsViewAgentColumn', cellComparator: stringCellComparator, className: "biggest-grow table-column"
    },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.Actions" }),
      key: 'Actions', name: 'SupervisorCallsViewActionsColumn', cellComparator: stringCellComparator, className: "actions-column"
    },
  ];

}
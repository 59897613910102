import React from 'react';
import Lockr from 'lockr';
import { StorageKeys } from 'utils';

class ErrorBoundary extends React.Component<{}, { error: any, errorInfo: any }> {
    constructor(props: any) {
        super(props);

        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })

        // Log the error to an error reporting service
        console.log(`${error} ${JSON.stringify(errorInfo)}`);

        Lockr.set(StorageKeys.Exception, `${Lockr.get<string>(StorageKeys.SIP)} : ${error} - ${JSON.stringify(errorInfo)}}`)        
    }

    render() {
        if (this.state.errorInfo) {
            // You can render any custom fallback UI
            return <div style={{ margin: '20px' }}>
                <h1>Something went wrong.</h1>
                <details style={{ whiteSpace: 'pre-wrap' }}>
                    {this.state.error && this.state.error.toString()}
                    <br />
                    {this.state.errorInfo.componentStack}
                </details>
            </div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
import React from 'react';
import { Flex } from '@fluentui/react-northstar';
import Lockr from 'lockr';
import { ConfigurationHelper } from 'utils/helpers';
import { StorageKeys } from 'utils';

export const RealtimeReportsView = () => {
    const frontEndUrl = ConfigurationHelper.getFrontEndUrl(Lockr.get<string>(StorageKeys.CompanyKey));
    const path = '/Home/TeamsAgentLogin';
    const query = `?Token=${Lockr.get(StorageKeys.TokenId)}&CompanyId=${Lockr.get(StorageKeys.CompanyId)}`;

    return (
        <Flex className="external-tab-wrapper">
            <iframe src={`${frontEndUrl}${path}${query}`} allow="true" width="100%" height="100%" title="Realtime reports" />
        </Flex>
    );
};

export default RealtimeReportsView;

import { SocketTransfer } from "./socket-transfer";

export interface ApiTransfer {
    coldTransfer(transferData: any): Promise<any>;
    warmTransferStart(transferData: any): Promise<any>;
    warmTransferCancel(transferData: any): Promise<any>;
    warmTransferFinalize(transferData: any): Promise<any>;
    getColdTransferDestinations(searchQuery: any): Promise<any[]>;
    coldTransferIVR(transferData: any): Promise<any>;
}

export const socketTransfer = new SocketTransfer();
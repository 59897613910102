import { ApiWrapUp } from '.';
import { logging } from 'utils/logging';
import { ResponseWrapper, ServiceOperations, ListenerOperations } from 'utils';
import { WrapUpSettings } from 'utils/domain/wrapUpSettings';
import { PresenceAndNoteDTO } from 'utils/domain/presence';
import { Listener, listeners } from 'services/io/listeners';
import { Subject, Subscription } from 'rxjs';
import { GeneralHelper } from 'utils/helpers';

export class WrapUpService implements ApiWrapUp {
    private readonly logger = logging.getLogger('SocketWrapUp');

    public readonly listenerPreviousPresenceAndNoteSet: Listener<any> = listeners.createListener<any>(ListenerOperations.PreviousPresenceAndNoteSet);
    public readonly listenerInitializeWrapUp: Listener<WrapUpSettings> = listeners.createListener<WrapUpSettings>(ListenerOperations.InitializeWrapUp);
    public readonly listenerStopWrapUp: Listener<PresenceAndNoteDTO> = listeners.createListener<PresenceAndNoteDTO>(ListenerOperations.StopWrapUp);

    private readonly subscriptionPreviousPresenceAndNoteChange: Subscription | null = null;

    previousPresenceChangeNotification: Subject<PresenceAndNoteDTO> = new Subject<PresenceAndNoteDTO>();


    constructor() {
        this.subscriptionPreviousPresenceAndNoteChange?.unsubscribe();
        this.subscriptionPreviousPresenceAndNoteChange = this.listenerPreviousPresenceAndNoteSet.received.subscribe((obj: any) => {
            this.previousPresenceChangeNotification.next({ Availability: obj.Availability, Activity: obj.Activity })
        });
    }

    GetAllWrapUpCollectionTopicMap(): Promise<ResponseWrapper> {
        return GeneralHelper.invokeServiceCall("", ServiceOperations.GetAllWrapUpCollectionTopicMap, this.logger)
    }

    IsAgentInWrapUp(sip: string): Promise<boolean> {
        return GeneralHelper.invokeServiceCall(sip, ServiceOperations.IsAgentInWrapUp, this.logger)
    }

    GetOngoingWrapUpSettings(sip: string): Promise<WrapUpSettings> {
        return GeneralHelper.invokeServiceCall(sip, ServiceOperations.GetOngoingWrapUpSettings, this.logger)
    }

    GetPreviousPresenceAndNote(sip: string): Promise<PresenceAndNoteDTO> {
        return GeneralHelper.invokeServiceCall(sip, ServiceOperations.GetPreviousPresenceAndNote, this.logger)
    }
}

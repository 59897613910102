export enum Filter {
    None,
    Online = 'online',
    HasClient = 'hasClient',
    Joined = 'joined',
}

export enum Sort {
    None,
    Agent = 'agent',
    StatusTime = 'statusTime',
}

export enum SortDirectionS3 {
    None,
    Asc = 'asc',
    Desc = 'desc',
}
import { Accordion, Button, ButtonGroup, Flex, Input, List, Popup, ThumbtackIcon, ThumbtackSlashIcon } from "@fluentui/react-northstar";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";

export interface Template {
    id: string;
    title: string;
    body: string;
    isFavorite: boolean;
}

enum Category {
    All,
    Favorite
}

interface TemplatePopupProps {
    onSelected: (template: Template) => void;
}

const demoTemplates = [
    {
        id: "1",
        title: 'Default Template',
        body: 'Of on affixed civilly moments promise explain fertile in. Assurance advantage belonging happiness departure so of. Now improving and one sincerity intention allowance commanded not. Oh an am frankness be necessary earnestly advantage estimable extensive. Five he wife gone ye. Mrs suffering sportsmen earnestly any. In am do giving to afford parish settle easily garret. ',
        isFavorite: true
    },
    {
        id: "2",
        title: 'Hello introduction',
        body: 'Or neglected agreeable of discovery concluded oh it sportsman. Week to time in john. Son elegance use weddings separate. Ask too matter formed county wicket oppose talent. He immediate sometimes or to dependent in. Everything few frequently discretion surrounded did simplicity decisively. Less he year do with no sure loud. ',
        isFavorite: true
    },
    {
        id: "3",
        title: 'Not Posible',
        body: 'To sorry world an at do spoil along. Incommode he depending do frankness remainder to. Edward day almost active him friend thirty piqued. People as period twenty my extent as. Set was better abroad ham plenty secure had horses. Admiration has sir decisively excellence say everything inhabiting acceptance. Sooner settle add put you sudden him. ',
        isFavorite: false
    },
    {
        id: "4", 
        title: 'Financing', 
        body: 'Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of. We so opinion friends me message as delight. Whole front do of plate heard oh ought. His defective nor convinced residence own. Connection has put impossible own apartments boisterous. At jointure ladyship an insisted so humanity he. Friendly bachelor entrance to on by. ', 
        isFavorite: true
    },
    {
        id: "5", 
        title: 'Goodbye', 
        body: 'At every tiled on ye defer do. No attention suspected oh difficult. Fond his say old meet cold find come whom. The sir park sake bred. Wonder matter now can estate esteem assure fat roused. Am performed on existence as discourse is. Pleasure friendly at marriage blessing or. ', 
        isFavorite: false
    },
    {
        id: "6", 
        title: 'Extended Template', 
        body: '<p><strong>bold</strong> <i>italic</i> <u>uuuu</u> <s>ssssss</s> <span style=\"font-family:Georgia, serif;\">otherFont</span> <span class=\"text-big\">BigText</span> <span style=\"background-color:hsl(120, 75%, 60%);color:hsl(0, 75%, 60%);\">coloredText</span></p>', 
        isFavorite: true
    },
];

const TemplatePopup = (props: TemplatePopupProps) => {
    const { onSelected } = props;
    const intl = useIntl();
    const [search, setSearch] = useState<string>('');
    const [templates, setTemplates] = useState<Template[]>(demoTemplates);

    const togglePinnedState = (template: Template) => {
        // update on server
        setTemplates(prev => {
            const copyTemplates = prev.map(x => ({...x}))
            const toggled = copyTemplates.find(x => x.id === template.id);

            if (!!toggled) {
                toggled.isFavorite = !toggled.isFavorite;
            }

            return copyTemplates;
        });
    }

    const pinnedButton = (template: Template) => {
        const icon = template.isFavorite 
            ? <ThumbtackSlashIcon size="small" />
            : <ThumbtackIcon size="small"/>;

        return (<ButtonGroup
            buttons={[
                {
                    key: 'pinnedAction',
                    icon: icon,
                    iconOnly: true,
                    text: true,
                    onClick: (e) => {
                        e.stopPropagation(); 
                        togglePinnedState(template);
                    },
                },
            ]}
        />);
    }

    const cleanEditorTags = (value: string): string => value.replace(/<[^>]*>?/gm, '');

    const buildFinalItems = (items: Template[], categoryId: string = ''): JSX.Element => (<List
        key={`list_${categoryId}`}
        truncateContent
        truncateHeader
        selectable
        items={items.map((item: Template) => ({
            key: `item_${categoryId}_${item.id}`,
            header: {
                content: item.title,
                styles: { fontWeight: '600' },
            },
            content: cleanEditorTags(item.body),
            endMedia: pinnedButton(item),
            onClick: () => onSelected(item),
        }))}
        variables={{rootPadding: '0 0 0 1.25rem'}}
        style={{marginLeft: '-20px'}}
    />);

    const templateListItems = useMemo<any[]>(() => {
        const searchLower = search?.toLowerCase();
        const filteredTemplates = templates.filter(x => !searchLower || x.title.toLowerCase().includes(searchLower));
        
        const allItem = {
            key: Category[Category.All],
            title: intl.formatMessage({ id: 'EmailEditor.All' }),
            content: buildFinalItems(filteredTemplates, Category[Category.All]),
        };
        
        if (!filteredTemplates.some(x => x.isFavorite)) {
            return [allItem];
        }

        const favoritesItem = {
            key: Category[Category.Favorite],
            title: intl.formatMessage({ id: 'EmailEditor.Favorites' }),
            content: buildFinalItems(filteredTemplates.filter(x => x.isFavorite), Category[Category.Favorite]),
        };

        return [favoritesItem, allItem];
    }, [templates, search, intl]);

    const popupContent = useMemo(() => {
        return (<Flex 
                column
                gap="gap.small"
                hAlign="start"
                style={{
                    width: '25rem',
                    height: '25rem', 
                    borderRadius: '0.25rem',
                }}
            >
            <Input
                fluid
                clearable
                placeholder={intl.formatMessage({ id: 'EmailEditor.SearchResponse' })}
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                variables={{ 
                    inputPaddingWithIconAtEnd: '1rem 2.8rem 1rem 1rem', 
                    iconRight: '1rem'
                }}
            />
            <Accordion
                defaultActiveIndex={[0]} 
                panels={templateListItems}
                style={{
                    maxWidth: '100%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    width: '25rem',
                }}
            />
        </Flex>)
    }, [templateListItems, search, intl]);

    return (
        <Popup
            position='above'
            align='start'
            on={['click']}
            trapFocus
            trigger={<Button
                text
                iconOnly
                content={intl.formatMessage({ id: 'EmailEditor.Templates' })}
            />}
            content={{ content: popupContent }}
        />
    );
}

export default TemplatePopup;

import React, { useState } from 'react';
import { Flex } from '@fluentui/react-northstar';
import OperatorQueue from './OperatorQueue';
import WaitingCalls from './WaitingCalls';
import { Contacts } from './Contacts/Contacts';
import { GeneralHelper } from 'utils/helpers';
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import { TabViewIds } from 'utils';
import { supervisorManager } from 'services/supervisor/supervisor-manager';
import SupervisorCallsView from './SupervisorCallsView';
import { Subscription } from 'rxjs';

export const AgentView = () => {
  const [waitingKey, setWaitingKey] = useState(Math.random() + "waitingContentKey");
  const [agentKey, setAgentKey] = useState(Math.random() + "agentContentKey");
  const [operatorKey, setOperatorKey] = useState(Math.random() + "operatorContentKey");
  const [isUserSupervisor, setIsUserSupervisor] = useState(false);
  const [isSupervisorCheckDone, setIsSupervisorCheckDone] = useState(false);

  let subscriptionRefreshAgentTab: Subscription | null = null;
  let subscriptionIsUserSupervisor: Subscription | null = null;

  React.useEffect(() => {
    initialize();

    return () => {
      componentWillUnmount();
    }
  }, []);

  const initialize = () => {
    GeneralHelper.logCox(`in AgentView.tsx, in initialize`);
    //#warning-js [cc4all-1102]: on my local sometimes I need to refresh to have the first tab lists data loaded ...
    subscriptionRefreshAgentTab?.unsubscribe();
    subscriptionRefreshAgentTab = mainViewHelper.tabViewChange.subscribe((value: number | string) => {
      if (value === TabViewIds.Agent) {
        setWaitingKey(Math.random() + "waitingContentKey");
        setAgentKey(Math.random() + "agentContentKey");
        setOperatorKey(Math.random() + "operatorContentKey");
      }
    });

    subscriptionIsUserSupervisor?.unsubscribe();
    subscriptionIsUserSupervisor = supervisorManager.onIsUserSupervisorCheckDone.subscribe((obj: boolean) => {
      setIsUserSupervisor(obj);
      setIsSupervisorCheckDone(true);
    });

    setIsUserSupervisor(supervisorManager.isSupervisor);
    setIsSupervisorCheckDone(supervisorManager.isSupervisorCheckDone);
  };

  const componentWillUnmount = () => {
    subscriptionRefreshAgentTab?.unsubscribe();
    subscriptionIsUserSupervisor?.unsubscribe();
  };

  return (
    <Flex.Item>
      <Flex key={agentKey} column>
        {isSupervisorCheckDone &&
          <>
           <OperatorQueue key={operatorKey} />
            {
              isUserSupervisor
                ? <SupervisorCallsView />
                : <WaitingCalls key={waitingKey} />
            }
          </>
        }
        <Contacts />
      </Flex>
    </Flex.Item>)
};

export default AgentView;

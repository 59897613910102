import React, { useState, useRef } from 'react';
import { Flex, Text, ShiftActivityIcon, CallIcon, Button } from '@fluentui/react-northstar';
import Lockr from 'lockr';
import { StorageKeys, ConferenceStatusAction, TabViewIds } from '../../../../utils';
import { Paginator } from '../../../../containers';
import { socketCallHistory } from '../../../../services/callHistory';
import AdvancedTable, { stringCellComparator } from '../../../../components/AdvancedTable';
import { useIntl } from 'react-intl';
import './CallHistory.scss'
import { mainViewHelper } from '../../../../utils/helpers/main-view-helper';
import { socketOutbound } from '../../../../services/outbound';
import { PersonalCallHistoryDTO } from '../../../../utils/domain/personalCallHistoryDTO';
import {EnvHelper, GeneralHelper} from '../../../../utils/helpers';
import { CallSessionDTO } from '../../../../utils/domain/callSessionDTO';
import { socketAudio } from '../../../../services/calling';
import { Subscription } from 'rxjs';

const operations = [
  ConferenceStatusAction[ConferenceStatusAction.Ringing],
  ConferenceStatusAction[ConferenceStatusAction.Terminated],
  ConferenceStatusAction[ConferenceStatusAction.CallerClosedAfterAnswer],
  ConferenceStatusAction[ConferenceStatusAction.SimultaniousIgnored],
  ConferenceStatusAction[ConferenceStatusAction.WarmAccepted],
  ConferenceStatusAction[ConferenceStatusAction.QueueWarmTransferCommited],
  ConferenceStatusAction[ConferenceStatusAction.Ignored],
  ConferenceStatusAction[ConferenceStatusAction.AgentColdTransfered],
  ConferenceStatusAction[ConferenceStatusAction.QueueColdTransferToCC4SQueue],
  ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransfered],
  ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransferred],
  ConferenceStatusAction[ConferenceStatusAction.QueueWarmTransferStarted],
  ConferenceStatusAction[ConferenceStatusAction.WarmInviteSent],
  ConferenceStatusAction[ConferenceStatusAction.CallerClosedBeforeAnswer],
  ConferenceStatusAction[ConferenceStatusAction.QueueColdTransferToCC4SQueue]
];

export const CallHistory = () => {
  const [keyValue, setKeyValue] = useState(Math.random() + "historyContentKey");
  const currentPage = useRef(1);
  const numberOfPages = useRef(1);
  const paginatorExists = useRef(false);
  const callHistoryList = useRef<PersonalCallHistoryDTO[]>([]);
  const callHistoryListShown = useRef<PersonalCallHistoryDTO[]>([]);
  const callHistoryCount = useRef(0);
  const intl = useIntl();
  const agentHasOutboundEnabled = useRef(false);

  let subscriptionCallSessionStateChanged: Subscription | null = null;
  let subscriptionRefreshHistoryTab: Subscription | null = null;
  let subscriptionAgentHasOutboundAccessEnabled: Subscription | null = null;

  React.useEffect(() => {
    initialize();
    return () => {
      componentWillUnmount();
    }
  }, []);

  const initialize = () => {
    // REMOVE whole if when CallHistory is implemented in Stage3
    if (EnvHelper.isStage3()) {
      return;
    }
    GeneralHelper.logCox(`in CallHistory.tsx, in initialize`);

    checkisAgentOutboundAccessEnabled();

    getCallHistoryListByAgent()

    subscriptionCallSessionStateChanged?.unsubscribe();
    subscriptionCallSessionStateChanged = socketAudio.callSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      // REMOVE whole if when implemented in Stage3
      if (EnvHelper.isStage3()) {
        return;
      }
      if (callSessionDTO.IsCurrentUserThePrimaryAgent && operations.includes(callSessionDTO.ConferenceActionAsString)) {
        socketCallHistory.GetListByAgentRef(Lockr.get<number>(StorageKeys.UserId)).then(result => {
          addCallHistoryElementsToList(result);
        });
      }
    });

    subscriptionRefreshHistoryTab?.unsubscribe();
    subscriptionRefreshHistoryTab = mainViewHelper.tabViewChange.subscribe((value: number | string) => {
      if (value === TabViewIds.CallHistory) {
        currentPage.current = 1;
        // REMOVE only if line when implemented in Stage3
        if (EnvHelper.isStage3()) {
          socketCallHistory.GetListByAgentRef(Lockr.get<number>(StorageKeys.UserId)).then(result => {
            addCallHistoryElementsToList(result);
          });
        }
        setKeyValue(Math.random() + "historyContentKey");
      }
    });

    subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
    subscriptionAgentHasOutboundAccessEnabled = socketOutbound.agentHasOutboundAccessEnabled.subscribe((isOutboundEnabled: boolean) => {
      if (agentHasOutboundEnabled.current !== isOutboundEnabled) {
        agentHasOutboundEnabled.current = isOutboundEnabled;
        getCallHistoryListByAgent();
      }
    });

  };

  const getCallHistoryListByAgent = () => {
    // REMOVE whole if when implemented in Stage3
    if (EnvHelper.isStage3()) {
      return;
    }
    socketCallHistory.GetListByAgentRef(Lockr.get<number>(StorageKeys.UserId)).then(result => {
      addCallHistoryElementsToList(result);
    });
  }

  const checkisAgentOutboundAccessEnabled = () => {
    agentHasOutboundEnabled.current = socketOutbound.HasOutboundAccesEnabled();
  }

  const componentWillUnmount = () => {
    subscriptionCallSessionStateChanged?.unsubscribe();
    subscriptionRefreshHistoryTab?.unsubscribe();
    subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
  };

  const addCallHistoryElementsToList = (result: PersonalCallHistoryDTO[]) => {
    const callHistoryListCopy: any = [];
    result.forEach((element: any) => {
      callHistoryListCopy.push(mapItem(element));
    });
    callHistoryList.current = callHistoryListCopy;
    reinitializeWaitingMediaList();
    setKeyValue(Math.random() + "historyContentKey");
  };

  const columnsClassName = 'big-grow table-header-column';

  const columnsMembers = [
    { title: intl.formatMessage({ id: "CallHistoryView.Name" }), key: 'Name', name: 'CallHistoryNameColumn', cellComparator: stringCellComparator, className: "biggest-grow" },
    { title: intl.formatMessage({ id: "CallHistoryView.Queue" }), key: 'Queue', name: 'CallHistoryQueueColumn', cellComparator: stringCellComparator, className: columnsClassName },
    { title: intl.formatMessage({ id: "CallHistoryView.Skill" }), key: 'Skill', name: 'CallHistorySkillColumn', cellComparator: stringCellComparator, className: columnsClassName },
    { title: intl.formatMessage({ id: "CallHistoryView.Type" }), key: 'Type', name: 'CallHistoryTypeColumn', cellComparator: stringCellComparator, className: columnsClassName },
    { title: intl.formatMessage({ id: "CallHistoryView.Date" }), key: 'Date', name: 'CallHistoryDateColumn', cellComparator: stringCellComparator, className: columnsClassName },
    { title: '', key: 'CallBack', name: 'CallHistoryCallColumn', cellComparator: stringCellComparator, className: "smallest-grow" },
  ];

  const getCallLabelAndIcon = (isIncomingCall: boolean) => {
    return (
      isIncomingCall ?
        <Flex gap="gap.small">
          <img alt='Incoming Call Icon' width="15" height="15" className='incoming-call-icon' />
          <Text content={intl.formatMessage({ id: "CallHistoryView.Incoming" })} />
        </Flex> :
        <Flex gap="gap.smaller">
          <img alt='Outgoing Call Icon' width="15" height="15" className='outgoing-call-icon' />
          <Text content={intl.formatMessage({ id: "CallHistoryView.Outgoing" })} />
        </Flex>
    );
  }

  const mapItem = (obj: PersonalCallHistoryDTO) => {
    return {
      key: obj.ID,
      items: [
        { content: getDisplayName(obj), key: obj.ID.toString() + '-1', className: "biggest-grow" },
        { content: obj.QueueName, key: obj.ID.toString() + '-2', className: columnsClassName },
        { content: obj.Skill ? obj.Skill : "", key: obj.ID.toString() + '-3', className: columnsClassName },
        {
          content: obj.IsMissing === true ?
            <Flex gap="gap.small">
              <img alt='Missed Call Icon' width="18" height="18" className='missed-call-icon' />
              <Text content={intl.formatMessage({ id: "CallHistoryView.Missing" })} styles={{ color: "#c91212" }} />
            </Flex> : getCallLabelAndIcon(obj.IsIncomingCall),
          key: obj.ID.toString() + '-4',
          className: columnsClassName
        },
        { content: GeneralHelper.formatAMPM(new Date(obj.CallTime)), key: obj.ID.toString() + '-5', className: columnsClassName },
        {
          content: agentHasOutboundEnabled.current === true ? <Button iconOnly text
            icon={<CallIcon outline onClick={() => performCallBack(obj)} />}
            title={intl.formatMessage({ id: 'CallHistoryView.CallBack' })} /> : '',
          key: obj.ID.toString() + '-6',
          className: "smallest-grow",
        },
      ]
    }
  }

  const performCallBack = (contact: PersonalCallHistoryDTO) => {
    mainViewHelper.tabViewChange.next(TabViewIds.Agent);
    let contactNumberValue = GeneralHelper.isMobileNumber(contact.ContactNumber)
      ? GeneralHelper.getMobileNumberFromSip(contact.ContactNumber) 
      : contact.ContactNumber;
    contactNumberValue = contactNumberValue 
      ? contactNumberValue.toLowerCase().replace("tel:", "")
      : contactNumberValue;
    mainViewHelper.navigationViewGoToDialpad.next(contactNumberValue);
  }

  const getDisplayName = (contact: PersonalCallHistoryDTO) => {
    return (GeneralHelper.isMobileNumber(contact.ContactNumber) || contact.ContactName === null)
      ? getFormatedValue(contact.ContactNumber, contact.ContactName)
      : GeneralHelper.removeSipFromName(contact.ContactName);
  }

  const getFormatedValue = (number: string, name: string) => {
    return (name == null || !name.length || number === name || name === ' ')
      ? GeneralHelper.getMobileNumberFromSip(number)
      : `${GeneralHelper.getMobileNumberFromSip(number)} (${name})`;
  }

  const reinitializeWaitingMediaList = () => {
    callHistoryCount.current = callHistoryList.current.length;
    paginatorExists.current = callHistoryCount.current > 10;

    initializeNumberOfPages(callHistoryCount.current);
    if (numberOfPages.current < currentPage.current) {
      currentPage.current = numberOfPages.current;
    }

    callHistoryListShown.current = callHistoryList.current.slice((currentPage.current - 1) * 10, currentPage.current * 10);
  }

  const initializeNumberOfPages = (result: any) => {
    let number = 0;
    if (result % 10 === 0) {
      number = Math.floor(result / 10);
    }
    else {
      number = Math.floor(result / 10) + 1;
    }

    numberOfPages.current = number === 0 ? 1 : number;
  }

  const selectPage = (e: any) => {
    callHistoryListShown.current = callHistoryList.current.slice((e - 1) * 10, e * 10);
    currentPage.current = e;
    setKeyValue(Math.random() + "historyContentKey");
  }

  let paginator: any = null;
  if (paginatorExists.current) {
    paginator = <Paginator
      currentPage={currentPage.current}
      totalPages={numberOfPages.current}
      onChange={selectPage}
    />
  }

  return (
    <Flex column key={keyValue} className="call-history-container">
      <Flex gap="gap.small" vAlign="center">
        <ShiftActivityIcon />
        <Text content={intl.formatMessage({ id: "CallHistoryView.Header" })} weight="bold" />
      </Flex>

      <AdvancedTable columns={columnsMembers} rows={callHistoryListShown.current} label="Call History" className="call-history-table" />
      {paginator}
    </Flex>
  )
};

export default CallHistory;

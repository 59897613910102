import React, { useState } from 'react';
import Lockr from 'lockr';
import { StorageKeys } from 'utils';

const Timer = (props: any) => {
    const {startDate, isEndDate} = props;
    
    const [timer, setTimer] = useState('');
    const [timerStarted, setTimerStarted] = useState(false);
    let interval: any;

    const secondToTimeString = (totalSecond: number) => {
        let seconds: any = Math.floor((totalSecond) % 60);
        let minutes: any = Math.floor((totalSecond / 60) % 60);
        let hours: any = Math.floor((totalSecond / (60 * 60)) % 24);

        hours = (hours < 10) ? '0' + hours : hours;
        minutes = (minutes < 10) ? '0' + minutes : minutes;
        seconds = (seconds < 10) ? '0' + seconds : seconds;

        return `${hours}:${minutes}:${seconds}`;
    }

    React.useEffect(() => {
        if (interval) {
            clearInterval(interval);
        }
        if (timerStarted) {
            setTimerAccordingly();

            interval = setInterval(() => {
                setTimerAccordingly();
            }, 1000);
            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            }
        }
        return undefined;

        function setTimerAccordingly() {
            const serverClientTimeDifference = (Lockr.get<number>(StorageKeys.ServerClientTimeDifference) || 0);

            let initialSeconds: number = Math.round((Date.now() + serverClientTimeDifference - Date.parse(startDate)) / 1000);
            initialSeconds = initialSeconds * (isEndDate ? -1 : 1);
            initialSeconds = initialSeconds < 0 ? 0 : initialSeconds;
            setTimer(secondToTimeString(initialSeconds));
        }
    }, [timerStarted, props]);

    if (timerStarted === false) {
        setTimerStarted(true);
    }

    return <div>{timer}</div>
}

export default Timer;

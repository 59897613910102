import { Input, List, Popup, ListProps, Box, ErrorIcon, Text } from "@fluentui/react-northstar";
import React, { useState, useCallback, useRef, useMemo, useEffect } from "react";
import RecipientData from "../models/Recipient";
import { KeyCode } from "../extensions/KeyCode";
import { ValidationHelper } from "utils/helpers";
import { useIntl } from "react-intl";

const testContacts: RecipientData[] = [
    {id: "1", name: "Simion Schiopu", email: "simion.schiopu@expertnetwork.ro"},
    {id: "2", name: "Ion Zdrobau", email: "ion.zdrobau@expertnetwork.ro"},
    {id: "3", name: "Vlad Cozma", email: "vlad.cozma@expertnetwork.ro"},
    {id: "4", name: "Stefan Diaconescu", email: "stefan.diaconescu@expertnetwork.ro"},
    {id: "5", name: "Sorin Murarasu", email: "sorin.murarasu@expertnetwork.ro"},
    {id: "6", name: "Flavian Cristei", email: "flavian.cristei@expertnetwork.ro"},
    {id: "7", name: "Simion 1", email: "simion.1@expertnetwork.ro"},
    {id: "8", name: "Ion 1", email: "ion.1@expertnetwork.ro"},
    {id: "9", name: "Vlad 1", email: "vlad.1@expertnetwork.ro"},
    {id: "10", name: "Stefan 1", email: "stefan.1@expertnetwork.ro"},
    {id: "11", name: "Sorin 1", email: "sorin.1@expertnetwork.ro"},
    {id: "12", name: "Flavian 1", email: "flavian.1@expertnetwork.ro"},
    {id: "13", name: "Simion 2", email: "simion.2@expertnetwork.ro"},
    {id: "14", name: "Ion 2", email: "ion.2@expertnetwork.ro"},
    {id: "15", name: "Vlad 2", email: "vlad.2@expertnetwork.ro"},
    {id: "16", name: "Stefan 2", email: "stefan.2@expertnetwork.ro"},
    {id: "17", name: "Sorin 2", email: "sorin.2@expertnetwork.ro"},
    {id: "18", name: "Flavian 2", email: "flavian.2@expertnetwork.ro"},
];

interface RecipientSearchInputProps {
    onRecipientSelected: (contact: RecipientData) => void;
}

export const RecipientSearchInput = (props: RecipientSearchInputProps) => {
    const { onRecipientSelected } = props;
    const intl = useIntl();
    const [searchText, setSearchText] = useState<string>('');
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
    const [recipients, setRecipients] = useState<RecipientData[]>([]);
    const [inputError, setInputError] = useState<string | undefined>();
    const [inputOnBlur, setInputOnBlur] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const popupContentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (inputOnBlur && !!searchText && !ValidationHelper.isValidEmail(searchText)) {
            showEmailError();
        }
    }, [inputOnBlur]);

    const selectRecipient = useCallback((recipient: RecipientData) => {
        onRecipientSelected(recipient);
        setSearchText('');
        setRecipients([]);
        setSelectedIndex(undefined);
        setInputError(undefined);
    }, [onRecipientSelected]);

    const arrowVerticalDirection = (keyCode: KeyCode): number => {
        switch (keyCode){
            case KeyCode.UpArrow:
                return -1;
            case KeyCode.DownArrow:
                return 1;
            default: 
                return 0;
        }
    }

    const showEmailError = useCallback(() => {
        setInputError(intl.formatMessage({ id: "EmailEditor.InvalidEmail" }));
    }, [intl]);

    const processManualEmailInput = useCallback((email: string) => {
        if (ValidationHelper.isValidEmail(email)) {
            selectRecipient({ id: '', name: '', email: email});

            return;
        }
        
        if (!!email.length) {
            showEmailError();
        }
    }, [showEmailError, selectRecipient]);

    const tryAddNewRecipientFromSearch = useCallback(() => {
        if (!!recipients.length && selectedIndex !== undefined) {
            selectRecipient(recipients[selectedIndex]);

            return;
        }

        if (selectedIndex === undefined) {
            processManualEmailInput(searchText);
        }
    }, [searchText, selectedIndex, recipients, processManualEmailInput, selectRecipient]);

    const processSearchNavigation = useCallback((direction: number) => {
        const max = recipients.length;
        const defaultIndex = direction > 0 ? -1 : max;
        const newSelectedIndex = (((selectedIndex ?? defaultIndex) + direction) + max) % max;
        const listItemHeight = 48;
        
        popupContentRef.current?.scrollTo({
            top: newSelectedIndex * listItemHeight,
            behavior: "auto"
        });
        setSelectedIndex(newSelectedIndex);
    }, [recipients, selectedIndex]);

    const onKeyDownHandler = useCallback((e: any) => {
        const keyCode = e.keyCode;

        if (keyCode == KeyCode.Enter) {
            tryAddNewRecipientFromSearch();
            e.preventDefault();
            return;
        }
        
        const direction = arrowVerticalDirection(keyCode);

        if (direction !== 0) {
            processSearchNavigation(direction);
            e.preventDefault();
        }
    }, [recipients, processSearchNavigation, tryAddNewRecipientFromSearch]);

    const onInputChanged = (value: string) => {
        const valueLength = value.length;
        setSearchText(value);
        setInputError(undefined);

        if (valueLength > 2 || !valueLength) {
            searchByValue(value);
        }
    }

    const searchByValue = (value: string) => {
        const valueToLowerCase = value.toLowerCase();

        const items = value.length !== 0 
            ? testContacts.filter(x => x.name.toLowerCase().includes(valueToLowerCase) || x.email.toLowerCase().includes(valueToLowerCase))
            : [];
        setRecipients(items);
    }

    const recipientListItems = useMemo(() => recipients.map((recipient: RecipientData) => ({
        key: recipient.id ?? recipient.email,
        header: recipient.name,
        content: recipient.email
    })), [recipients]);

    return (
        <Popup
            position='below'
            align='center'
            on={['focus']}
            offset={[0, 0]}
            trigger={<Input
                ref={inputRef}
                value={searchText}
                onChange={(e: any) => onInputChanged(e.target.value)}
                onKeyDown={(e: any) => onKeyDownHandler(e)}
                onFocus={() => setInputOnBlur(false)}
                onBlur={() => setTimeout(() => setInputOnBlur(true), 200)}
                variables={{ backgroundColor: 'white', inputPaddingWithIconAtEnd: '0.3571rem 2rem 0.3571rem 0.1rem' }}
                error={!!inputError}
                errorIndicator={<Popup 
                    defaultOpen
                    content={<Text 
                        content={inputError}
                        className="em-recipient-search-input-error"
                        error
                    />}
                    trigger={<ErrorIcon />}
                />}
            />}
            content={<Box
                    ref={popupContentRef}
                    className="em-recipient-search-container"
                >
                    <List
                        selectable
                        truncateHeader
                        truncateContent
                        selectedIndex={selectedIndex}
                        items={recipientListItems}
                        onSelectedIndexChange={(e, newProps: ListProps | undefined) => {
                            if (!newProps || newProps.selectedIndex === undefined) {
                                return;
                            }
                            selectRecipient(recipients[newProps.selectedIndex]);
                            setTimeout(() => inputRef.current?.focus());
                        }}
                    />
                </Box>
            }
        />
    );
}

export default RecipientSearchInput;
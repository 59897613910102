import { Controllers, HttpMethod, logging } from "utils";
import { PagedResultDto } from "utils/domain/extended/pagedResultDto";
import { SupervisorAgentDto, SupervisorQueuesData } from "utils/domain/extended/supervisorDto";
import { GeneralHelper } from "utils/helpers";
import { PageStateData } from "./models/pageStateData";

class SupervisorService {
    private readonly logger = logging.getLogger('ExtendedTabsService');

    getQueues(): Promise<SupervisorQueuesData> {
        return this.invokeCall(null, "queues", HttpMethod.Get);
    }

    getAgentsData(queueId: string, pageState: PageStateData): Promise<PagedResultDto<SupervisorAgentDto>> {
        const request = {
            queueId: queueId,
            page: pageState.page,
            search: pageState.search,
            filter: pageState.filter,
            sort: pageState.sort,
            sortDirection: pageState.sortDirection,
        };

        return this.invokeCall(request, "agents", HttpMethod.Get);
    }

    updateJoinedStatus(agentId: string, queueId: string, isJoined: boolean): Promise<any> {
        const request = {
            agentId: agentId,
            queueId: queueId,
            isJoined: isJoined,
        };

        return this.invokeCall(request, "agents/joined", HttpMethod.Post);
    }

    updateDesireState(agentId: string, queueId: string, desire: number | undefined): Promise<any> {
        const request = {
            agentId: agentId,
            queueId: queueId,
            desire: desire,
        };

        return this.invokeCall(request, "agents/desire", HttpMethod.Post);
    }

    updateQueueDefinedEstimatedWaitTime(queueId: string, useDefinedEstimatedWaitTime: boolean, definedEstimatedWaitTime: string){
        const request = {
            queueId: queueId,
            useDefinedEstimatedWaitTime: useDefinedEstimatedWaitTime,
            definedEstimatedWaitTime: definedEstimatedWaitTime
        };
        return this.invokeCall(request, "queues/update-estimated-wait-time", HttpMethod.Post);
    }

    private invokeCall(data: any, operation: string, httpMethod: string): Promise<any> {
        return GeneralHelper.invokeServiceCall(data, operation, this.logger, Controllers.Supervisor, undefined, undefined, httpMethod);
    }
}

export const supervisorService = new SupervisorService();
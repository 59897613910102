import { useIntl } from "react-intl";
import { Alert, Avatar, Button, CallIcon, Divider, EditIcon, EmailIcon, Flex, Header, List, Text, Tooltip } from "@fluentui/react-northstar";
import React, { useState } from "react";
import { CustomerDTO, EmailAddressDTO, PhoneNumberDTO } from "utils/domain/customerJourneyModels";

type InformationProps = {
  currentCustomer?: CustomerDTO,
  editViewShown: boolean,
  updateEditView: (arg: boolean) => void
  updateEditCustomer: (arg: CustomerDTO) => void
}
type MyState = {
  expandable: boolean; // like this
};
const CustomerInformationView: React.FC<InformationProps> = ({currentCustomer, editViewShown, updateEditView, updateEditCustomer}) => {
  const intl = useIntl();
  
  const [customer, setCustomer] = useState(currentCustomer);
  const [phoneItems, setPhoneItems] = useState([] as any[]);
  const [emailItems, setEmailItems] = useState([] as any[]);
  
  React.useEffect(() => {
    initialize();
  }, []);

  React.useEffect(() => {
    setCustomer(currentCustomer);
    setPhoneItems(getMenuItemsPhone(currentCustomer?.PhoneNumbers));
    setEmailItems(getEmailItems(currentCustomer?.MailAddresses));
  }, [currentCustomer]);

  const initialize = () => {
    console.log("Creating customer information view");
    console.log(customer);
    setPhoneItems(getMenuItemsPhone(currentCustomer?.PhoneNumbers));
    setEmailItems(getEmailItems(currentCustomer?.MailAddresses));
  };

  const getName = (customerDto: CustomerDTO) => {
    return `${customerDto.Name} ${customerDto.Surname}`;
  }

  const getMenuItemsPhone =(phoneNumbers?: PhoneNumberDTO[]) => {
    const items: any[] = [];

    if (phoneNumbers === undefined) {
      return items;
    }
    for (var val of phoneNumbers) {
      items.push({
        key: 'phone#' + val.ID,
        media: <CallIcon />,
        // endMedia: <Tooltip
        // trigger={<Button text icon={<EditIcon />} size="small" iconOnly title="Close" className="customer-info-button"/>}
        // content="Click to expand actions." />,
        content: val.PhoneNumber,
        truncateContent: true,
      });
    }

    return items;
  }

  const getEmailItems =(emailAddresses?: EmailAddressDTO[]) => {
    const items: any[] = [];

    if (emailAddresses === undefined) {
      return items;
    }
    for (var val of emailAddresses) {
      items.push({
        key: 'email#' + val.ID,
        media: <EmailIcon />,
        // endMedia: <Tooltip
        // trigger={<Button text icon={<EditIcon />} size="small" iconOnly title="Close" className="customer-info-button"/>}
        // content="Click to expand actions." />,
        content: val.MailAddress,
        truncateContent: true,
      });
    }

    return items;
  }

   const getCustomerInformationItems = (customerDto?: CustomerDTO) => {
    let items: any[] = [];

    if (customerDto === undefined) {
      return items;
    }

    items = [{
          key: 'Name',
          header: <Text content={intl.formatMessage({ id: "CustomerJourney.CustomerInformationView.Name"})} weight="bold" />,
          // endMedia: <Tooltip
          // trigger={<Button text icon={<EditIcon />} size="small" iconOnly title="Close" className="customer-info-button"/>}
          // content="Click to expand actions." />,
          content: <Text>{getName(customerDto)}</Text>,
        }
      ];

      return items;
   }

  return (
    // <Segment styles={({ theme: { siteVariables } }) => ({
    //   backgroundColor: siteVariables.colorScheme.default.background,
    //   color: siteVariables.colorScheme.default.foreground,
    //   width: (100/3) + "%",
    //   borderRadius: "5px",
    //   margin: "10px",
    //   })}>
    <Flex column space="between" styles={({ theme: { siteVariables } }) => ({
        backgroundColor: siteVariables.colorScheme.default.background,
        color: siteVariables.colorScheme.default.foreground,
        })}>
    <Flex gap="gap.small" padding="padding.medium">
      {(customer === undefined || customer.ID === -1) && 
      <Flex.Item grow>
        <Flex space="between">
          <Header as="h2" content={intl.formatMessage({ id: "CustomerJourney.CustomerInformationView.Title"})} styles={({ theme: {siteVariables} }) => ({
                  color: siteVariables.colorScheme.brand.foreground,
                  margin: "0px"
              })}/>
        </Flex>
      </Flex.Item>}
      {(customer !== undefined && customer.ID !== -1) && <Flex.Item grow>
         <Flex space="between">
           <Flex gap="gap.small">
             <Avatar
               // image="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/RobertTolbert.jpg"
               name={(customer !== null ? getName(customer) : intl.formatMessage({ id: "CustomerJourney.CustomerInformationView.Name"}))}
             />
             <Flex column>
               <Text content={(customer !== null ? getName(customer) : intl.formatMessage({ id: "CustomerJourney.CustomerInformationView.Name"}))} weight="bold" />
               {/* <Text content={(customer !== null ? customer.CompanyRef : "company")} size="small" /> */}
             </Flex>
           </Flex>
           <Flex gap="gap.small">
              <Tooltip
              trigger={<Button text icon={<EditIcon size="large" />} iconOnly 
                  onClick={() => {
                    updateEditCustomer(customer);
                    updateEditView(true);
                  }} />}
              content={intl.formatMessage({ id: "CustomerJourney.CustomerEditView.EditCustomer"})} />
           </Flex>
         </Flex>
      </Flex.Item>}

    </Flex>
    <Divider size={3}/>
    {(customer === undefined || customer.ID === -1) && <Alert info styles={{alignSelf: "center", width: "80%", textAlign: "center"}} content={intl.formatMessage({ id: "CustomerJourney.CustomerInformationView.NoCustomer"})} />}
    {(customer !== undefined && customer.ID !== -1) &&
    <Flex column space="evenly">
    <Flex gap="gap.small" padding="padding.medium">
      <Flex.Item grow>
        <List navigable truncateContent={true} items={(customer !== null ? getCustomerInformationItems(customer) : [])} />
      </Flex.Item>
    </Flex>
    <Divider size={1}/>
    <Flex gap="gap.small">
      <Flex.Item grow>
        <Text content={intl.formatMessage({ id: "CustomerJourney.CustomerInformationView.PhoneNumbers"})} weight="bold" size="medium" styles={{paddingLeft: "30px"}} />
      </Flex.Item>
    </Flex>
    <Flex gap="gap.small" padding="padding.medium" styles={{paddingTop: "0px"}}>
    {(phoneItems === undefined || phoneItems.length < 1) && <Text content={intl.formatMessage({ id: "CustomerJourney.CustomerInformationView.NoPhoneNumbers"})} styles={{paddingLeft: "20px"}}/>}
      <Flex.Item grow>
        <List navigable truncateContent={true} items={phoneItems} />
      </Flex.Item>
    </Flex>
    <Divider size={1}/>
    <Flex gap="gap.small">
      <Flex.Item grow>
        <Text content={intl.formatMessage({ id: "CustomerJourney.CustomerInformationView.EmailAddresses"})} weight="bold" size="medium" styles={{paddingLeft: "30px"}} />
      </Flex.Item>
    </Flex>
    <Flex gap="gap.small" padding="padding.medium"  styles={{paddingTop: "0px"}}>
    {(emailItems === undefined || emailItems.length < 1) && <Text content={intl.formatMessage({ id: "CustomerJourney.CustomerInformationView.NoEmailAddresses"})} styles={{paddingLeft: "20px"}}/>}
      <Flex.Item grow>
        <List navigable truncateContent={true} items={emailItems} />
      </Flex.Item>
    </Flex>
    </Flex>}
    </Flex>
    // </Segment>
);
}

export default CustomerInformationView;
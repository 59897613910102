import { Subject } from "rxjs";
import { ListenerOperations, QueueMediaStatusType, logging } from "utils";
import { Controllers, GenericOperation } from "utils/enums-s3";
import { GeneralHelper } from "utils/helpers";
import GenericMediaData from "../../../Models/GenericMediaData";
import { Listener, listeners } from "services/io/listeners";
import { GenericNotificationData } from "containers/GenericView/Models/GenericNotificationData";

export class SupervisorMediaService {
    private readonly logger = logging.getLogger('SupervisorMediaService');
    
    private _mediaData: GenericMediaData[] = [];
    private _numberOfPages: number = 0;
    private _currentPage: number = 1;
    private _filters: GenericFilters = { active: true, waiting: true};
    private isInited: boolean = false;

    private readonly _acceptGenericListener: Listener<GenericNotificationData> = listeners.createListener<GenericNotificationData>(ListenerOperations.AcceptGenericNotification);
    private readonly _terminateGenericListener: Listener<GenericNotificationData> = listeners.createListener<GenericNotificationData>(ListenerOperations.TerminateGenericNotification);
    private readonly _offerGenericListener: Listener<GenericNotificationData> = listeners.createListener<GenericNotificationData>(ListenerOperations.OfferGenericNotification);

    public readonly RecordsPerPage: number = 10;
    public readonly dataUpdatedSubject: Subject<GenericMediaData[]> = new Subject();

    public get numberOfPages(): number {
        return this._numberOfPages;
    }

    public get currentPage(): number {
        return this._currentPage;
    }

    public get existsPaginator(): boolean {
        return this._numberOfPages > 1;
    }

    public get mediaData(): GenericMediaData[] {
        return this._mediaData
            .filter(x => (this._filters.waiting && this.isWaitingStatus(x.status)) || 
                        (this._filters.active && !this.isWaitingStatus(x.status)))
            .slice((this._currentPage - 1) * this.RecordsPerPage, this._currentPage * this.RecordsPerPage)
            .map(x => ({...x}));
    }

    public get filters(): GenericFilters {
        return {...this._filters};
    }

    public init() {
        if (this.isInited) {
            this.notifyDataChanged();
            return;
        }

        this.isInited = true;
        this.registerListeners();

        this.downloadData()
            .catch(() => {
                this.isInited = false;
            });
    }

    public selectPage(newPage: number) {
        if (this._currentPage === newPage) {
            return;
        }

        this._currentPage = newPage;
    }

    public applyFilters(newFilters: GenericFilters) {
        this._filters = {...newFilters};
        this.notifyDataChanged();
    }

    private notifyDataChanged() {
        this.dataUpdatedSubject.next(this.mediaData);
    }

    private isWaitingStatus(status: QueueMediaStatusType): boolean {
        return status == QueueMediaStatusType.Offering || status === QueueMediaStatusType.Waiting;
    }

    private downloadData(): Promise<void> {
        return GeneralHelper.invokeServiceCall('', GenericOperation.MediaSupervised, this.logger, Controllers.Generics)
            .then((data: GenericMediaData[]) => {
                this._mediaData = data;
                this._numberOfPages = Math.ceil(data.length / this.RecordsPerPage) || 1;

                this.notifyDataChanged();
            })
    }

    private registerListeners() {
        this._acceptGenericListener.received.subscribe((data: GenericNotificationData) => {
            const accepted = this._mediaData.find(x => x.sessionId === data.GenericId);
            
            if (!accepted) {
                return;
            }

            accepted.status = QueueMediaStatusType.Assigned;
            this.notifyDataChanged();
        });
        this._terminateGenericListener.received.subscribe((data: GenericNotificationData) => {
            const arrayWithoutTerminatedGeneric = this._mediaData.filter(x => x.sessionId !== data.GenericId);
            
            if (arrayWithoutTerminatedGeneric.length === this._mediaData.length) {
                return;
            }

            this._mediaData = arrayWithoutTerminatedGeneric;
            this.notifyDataChanged();
        });
        this._offerGenericListener.received.subscribe(_ => this.downloadData());
    }
}

export interface GenericFilters {
    active: boolean;
    waiting: boolean,
}

export const supervisorMediaService = new SupervisorMediaService();

import React, { useState, useRef } from 'react';
import Lockr from 'lockr';
import { Flex, Text, Divider } from '@fluentui/react-northstar';
import { socketAudio } from 'services/calling';
import {
  StorageKeys, logging, ConferenceStatusAction, ReasonNameConstants,
  MonitorTypes, ActiveMainViewItem, HeaderBarDisplayedMessageType
} from 'utils';
import { transferRequest, callSessionRequest, ContactType } from 'utils/domain/transfercall';
import { socketTransfer } from 'services/transfers';
import { GeneralHelper, ValidationHelper } from 'utils/helpers';
import { useIntl } from 'react-intl';
import { PresenceAndNoteDTO } from 'utils/domain/presence';
import { CallSessionDTO } from 'utils/domain/callSessionDTO';
import './callActions.scss'
import { Subscription } from 'rxjs';
import Timer from 'components/Timer';
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import { socketPresence } from 'services/presence';
import { TransferStartResponse } from 'utils/domain/transferStartResponse';
import { ColdTransferType } from './Enums/TransferType';
import { OnHoldStatus } from 'utils/domain/extended/onHoldStatusNotification';
import { queuesService } from 'services/queue';
import {
  ColdTransferIVRPopupButtonParams,
  ColdTransferPopupButtonParams,
  WarmTransferPopupButtonParams,
  answerCallButton, coldTransferIVRPopupButton, coldTransferPopupButton, endCallButton, finalizeWarmTransferButton,
  loadingCallButton, parkCallButton, pauseCallButton, recordCallButton, rejectCallButton, resumeCallButton,
  stopRecordCallButton, switchToBargeInButton, switchToListenButton, terminateMonitoringButton, warmTransferCancelButton,
  warmTransferConsultAgentButton, warmTransferConsultCallerButton, warmTransferPopupButton
} from './CallActionsStage3Buttons';
import { UnsupportedContactType } from './Enums/UnsupportedContactType';

enum CallPauseState {
  none,
  pause,
  resume,
  loading
}

export const CallActions = () => {
  const isFirstRun = useRef(true);
  const intl = useIntl();
  const callerUri = useRef('');
  const callerName = useRef('');
  const transferTo = useRef('');

  const transferToNameStage3 = useRef('');
  const contactType = useRef(ContactType.Agent);
  const [operation, setOperation] = useState('');
  const [callStartTime, setCallStartTime] = useState<string>();

  const [answerCallVisible, setAnswerCallVisible] = useState(false);
  const [rejectCallVisible, setRejectCallVisible] = useState(false);
  const [endCallVisible, setEndCallVisible] = useState(false);
  const [monitoringButtonsVisible, setMonitoringButtonsVisible] = useState(false);

  const [pauseState, setPauseState] = useState<CallPauseState>(CallPauseState.none);

  const [recordCallVisible, setRecordCallVisible] = useState(false);
  const [stopRecordCallVisible, setStopRecordCallVisible] = useState(false);
  const [parkCallVisible, setParkCallVisible] = useState(false);

  const [coldTransferVisible, setColdTransferVisible] = useState(false);
  const [coldTransferInputValue, setColdTransferInputValue] = useState('');
  const [warmTransferVisible, setWarmTransferVisible] = useState(false);
  const [warmTransferInputValue, setWarmTransferInputValue] = useState('');
  const [warmTransferCancelVisible, setCancelWarmTransferVisible] = useState(false);
  const [finalizeWarmTransferVisible, setFinalizeWarmTransferVisible] = useState(false);
  const [warmTransferConsultCallerVisible, setWarmTransferConsultCallerVisible] = useState(false);
  const [warmTransferConsultAgentVisible, setWarmTransferConsultAgentVisible] = useState(false);
  const [isInvalidTransfer, setIsInvalidTransfer] = useState(false);
  const [listenButtonDisabled, setListenButtonDisabled] = useState(false);
  const [bargeInButtonDisabled, setBargeInButtonDisabled] = useState(false);
  const [monitoringType, setMonitoringType] = useState(MonitorTypes.None);
  const [coldTransferDestinations, setColdTransferDestinations] = useState([] as any);
  const [areTranferDestinationsLoading, setAreTranferDestinationsLoading] = useState(false);
  const [currentCallSession, setCurrentCallSession] = useState(new CallSessionDTO());
  const [isOngoingCallSelected, setIsOngoingCallSelected] = useState(true);

  const currentSessionId = useRef('');
  const skipInputTransferChangeEvent = useRef(false);
  const currentSelectedColdTransferDestination = useRef('');
  const pauseTimeoutRef = useRef<any>(null);

  let subscriptionCurrentUserCallSessionStateChanged: Subscription | null = null;
  let subscriptionColdTransfer: Subscription | null = null;
  let subscriptionWarmTransfer: Subscription | null = null;
  let subscriptionPresenceAndNote: Subscription | null = null;
  let subscriptionCurrentUserMonitoredCallSessionChanged: Subscription | null = null;
  let subscriptionViewChanged: Subscription | null = null;
  let subscriptionHeaderBarMessageChanged: Subscription | null = null;
  let subscriptionCallSessionChanged: Subscription | null = null;
  let subscriptionWarmTransferInProgress: Subscription | null = null;
  let subscriptionOnHoldChanged: Subscription | null = null;

  const endOperations = GeneralHelper.getStage3EndOperations();
  const transferInvalidInputMessage = "ErrorMessage.Transfer.InvalidInput";
  const currentUserId = Lockr.get<string>(StorageKeys.UserId);

  let typingTimeout: any = 0;
  const searchInterval: any = 1000;
  const callStatusWaitingTimeout: number = 5000;

  const getCallSessionAgentRef = (callSessionDTO: CallSessionDTO) => {
    return callSessionDTO.IsOutboundCall && callSessionDTO.CallerId == Lockr.get<string>(StorageKeys.UserObjectId)
      ? callSessionDTO.CallerId
      : callSessionDTO.AgentRef;
  }

  const setCallSession = (callSessionDTO: CallSessionDTO) => {
    callerUri.current = callSessionDTO.CallerUri;
    callerName.current = callSessionDTO.CallerName;

    callSessionRequest.ConversationKey = callSessionDTO.ConversationKey;
    callSessionRequest.SessionId = callSessionDTO.SessionId;
    callSessionRequest.SourceUri = Lockr.get(StorageKeys.SIP);

    callSessionRequest.CallId = callSessionDTO.SessionId;
    callSessionRequest.TenantId = callSessionDTO.TenantId;
    callSessionRequest.AgentRef = getCallSessionAgentRef(callSessionDTO);
    callSessionRequest.QueueRef = callSessionDTO.QueueRef;
    callSessionRequest.SecondaryAgentDisplayName = callSessionDTO.SecondaryAgentName;
    callSessionRequest.SecondaryAgentUpn = callSessionDTO.SecondaryAgentSIP;
    callSessionRequest.SecondaryAgentRef = callSessionDTO.SecondaryAgentRef;
    callSessionRequest.AzureTenantId = callSessionDTO.AzureTenantId;
    callSessionRequest.ScenarioId = callSessionDTO.ScenarioId;

    transferRequest.CallSessionRequest.ConversationKey = callSessionDTO.ConversationKey;
    transferRequest.CallSessionRequest.SessionId = callSessionDTO.SessionId;
    //#warning-js [cc4all-1102]: isn't transferRequest.CallSessionRequest the same instance with callSessionRequest?
    transferRequest.CallSessionRequest.SourceUri = Lockr.get(StorageKeys.SIP);
  };

  const clearCallSession = () => {
    callerUri.current = '';
  };

  React.useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }

    initialize();
    return () => componentWillUnmount();
  }, []);

  const componentWillUnmount = () => {
    subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
    subscriptionColdTransfer?.unsubscribe();
    subscriptionWarmTransfer?.unsubscribe();
    subscriptionPresenceAndNote?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
    subscriptionViewChanged?.unsubscribe();
    subscriptionHeaderBarMessageChanged?.unsubscribe();
    subscriptionCallSessionChanged?.unsubscribe();
    subscriptionOnHoldChanged?.unsubscribe();
    clearPauseTimeout();
  }

  const currentUserMonitoredCallSessionChanged = (callSessionDTO: CallSessionDTO) => {
    GeneralHelper.logCox(`in CallActions.tsx, 
    listenerCurrentUserMonitoredCallSessionChanged, 
    ActiveSupervisorSIP is ${callSessionDTO.ActiveSupervisorSIP}, 
    MonitorType is ${callSessionDTO.MonitorType}, 
    ConferenceActionAsString is ${callSessionDTO.ConferenceActionAsString}`);

    let formatMessageId: string = "";
    if (callSessionDTO.ConferenceAction === ConferenceStatusAction.MonitoringStarting ||
      callSessionDTO.ConferenceAction === ConferenceStatusAction.MonitoringEnded) {

      const isMonitoringStarting = callSessionDTO.ConferenceAction === ConferenceStatusAction.MonitoringStarting;
      const newOperation = isMonitoringStarting ?
        intl.formatMessage({ id: "CallActions.SupervisingSelfRinging" }) : '';

      setOperation(newOperation);
      setMonitoringButtonsVisible(callSessionDTO.IsMonitoringEnabled && isMonitoringStarting && !newOperation);

      setListenButtonDisabled(true);
      setBargeInButtonDisabled(true);
      setParkCallVisible(false);

      disableActionsForMonitoring();
    }
    else if (callSessionDTO.MonitorType !== MonitorTypes.None && !callSessionDTO.IsComputedAsTerminated) {
      switch (callSessionDTO.MonitorType) {
        case MonitorTypes.BargeIn:
          formatMessageId = "CallActions.BargeIn";
          setListenButtonDisabled(false);
          setBargeInButtonDisabled(true);
          break;
        case MonitorTypes.Silent:
          formatMessageId = "CallActions.Listen";
          setListenButtonDisabled(true);
          setBargeInButtonDisabled(true);
          break;
      }

      disableActionsForMonitoring();

      setMonitoringType(callSessionDTO.MonitorType);
      setCallSession(callSessionDTO);
      setCallStartTime(callSessionDTO.SupervisingStartDate);
      setMonitoringButtonsVisible(callSessionDTO.IsMonitoringEnabled && callSessionDTO.MonitorType !== MonitorTypes.Ringing);
      socketAudio.callActionViewIsDisplayed.next(true);
      setOperation(intl.formatMessage({ id: formatMessageId }));
    } else {
      setMonitoringType(MonitorTypes.None);
      setMonitoringButtonsVisible(false);
      setCallStartTime('');
      setOperation('');
      socketAudio.callActionViewIsDisplayed.next(false);
    }

    if (formatMessageId) {
      setOperation(intl.formatMessage({ id: formatMessageId }));
    }
  }

  const disableActionsForMonitoring = () => {
    setEndCallVisible(false);
    setWarmTransferVisible(false);
    setColdTransferVisible(false);
    setPauseState(CallPauseState.none);
    setFinalizeWarmTransferVisible(false);
    setWarmTransferConsultCallerVisible(false);
    setWarmTransferConsultAgentVisible(false);
    setCancelWarmTransferVisible(false);
  }

  const currentUserCallSessionChanged = (callSessionDTO: CallSessionDTO) => {
    setCurrentCallSession(callSessionDTO);
    const isParkCall = GeneralHelper.isCallParkedOrFallback(callSessionDTO.ActionHistory, ConferenceStatusAction.OperatorPark);
    const isFallbackCall = GeneralHelper.isCallParkedOrFallback(callSessionDTO.ActionHistory, ConferenceStatusAction.Fallback);

    if ((callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Offering]
      && !callSessionDTO.IsOutboundCall) ||
      (
        callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransferred] &&
        (callSessionDTO.AgentBeforeTransferRef !== currentUserId
          && callSessionDTO.AgentBeforeTransferRef !== null)
        )
    ) {
      startStage3Call(callSessionDTO);
      return;
    } else {
      setRejectCallVisible(false);
    }

    if (callSessionDTO.ActionHistory?.includes(ConferenceStatusAction.Accepted)
      && callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] !== ConferenceStatusAction.AgentColdTransfered
      && callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] !== ConferenceStatusAction.OperatorIVRColdTransferred
      && callSessionDTO.StatusAsString !== ConferenceStatusAction[ConferenceStatusAction.Offering]
      && !isParkCall && !isFallbackCall && !callSessionDTO.IsTeamsUnparkInProgress
      && callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransferred]) {
      startCall(callSessionDTO);

      setOngoingCallOperation();
      if (callSessionDTO.TransferTargetUri) {
        transferTo.current = callSessionDTO.TransferTargetUri;
        transferRequest.TargetUri = callSessionDTO.TransferTargetUri;
        transferRequest.ObjectId = callSessionDTO.OriginalTransferTargetUri;
      }

      if (callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.WarmCanceled] &&
        callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent]) {
        if (callSessionDTO.IsWarmTransferInProgress) {
          transferToNameStage3.current = callSessionDTO.SecondaryAgentName ? callSessionDTO.SecondaryAgentName : '';
          if (callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.Resumed]) {
            setWarmTransferStarted();
          }
        } else if (callSessionDTO.IsCallerOnHold) {
          setPausedCall();
        }

        // warm invite sent
        if (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.WarmInviteSent
          || (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 2] === ConferenceStatusAction.WarmInviteSent
            && callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.OnHold)) {
          setWarmTransferStarted();
        }

        // warm invite accepted
        if (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.WarmInviteAccepted
          || (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 2] === ConferenceStatusAction.WarmInviteAccepted
            && callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.OnHold)) {
          transferToNameStage3.current = callSessionDTO.SecondaryAgentName;
          setWarmTransferAccepted();
        }

        // warm switch to caller
        if (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.WarmSwitchToCaller
          || (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 2] === ConferenceStatusAction.WarmSwitchToCaller
            && callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.Resumed)) {
          setWarmTransferConsultCaller();
        }

        // warm switch to agent
        if (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.WarmSwitchToAgent
          || (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 2] === ConferenceStatusAction.WarmSwitchToAgent
            && callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.OnHold)) {
          setWarmTransferConsultAgent();
        }
      }

      // cold transfer invite
      if (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.OperatorColdTransferInviteSent) {
        setRinging3dPartyOperation();
      }

      if (!!transferTo) {
        if (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.OperatorColdTransferInviteRejected) {
          transferTo.current = '';
          transferToNameStage3.current = '';

          logging.errorHandler.next("ErrorMessage.Transfer.ColdTransferFailed");
        }

        if (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmCanceled] ||
          callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent]
          || callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmInviteRejected]) {
          transferTo.current = '';
          transferToNameStage3.current = '';

          setWarmTransferCanceled();

          const isCallerResumed = callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.Resumed || !callSessionDTO.IsCallerOnHold;

          if (isCallerResumed) {
            setPauseState(CallPauseState.pause);
            setOngoingCallOperation();
          }
        }

        if (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmInviteRejected]) {
          logging.errorHandler.next("ErrorMessage.Transfer.WarmInviteRejected");
        }
      }

      if (callSessionDTO.IsManualRecordingSessionActive) {
        setStopRecordCallVisible(callSessionDTO.IsManualRecordingSessionActive);
      }

      if (!callSessionDTO.IsManualRecordingSessionActive && callSessionDTO.IsManualRecordingEnabled) {
        setRecordCallVisible(callSessionDTO.IsManualRecordingEnabled);
      }
      if (callSessionDTO.IsOutboundCall) {
        outboundCall(callSessionDTO);
      }
    } else if (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Offering] &&
      (callSessionDTO.IsTeamsCampaignOutboundCall || callSessionDTO.IsOutboundCall)) {
      outboundRinging(callSessionDTO);
    } else if (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Accepted] && callSessionDTO.IsOutboundCall) {
      outboundCall(callSessionDTO);
    }

    const sip = Lockr.get<string>(StorageKeys.SIP);
    if (GeneralHelper.callerHasLeft(callSessionDTO.ActionHistoryAsString, callSessionDTO.ConferenceActionAsString, sip === callSessionDTO.PrimaryAgentSIP)) {
      logging.errorHandler.next("ErrorMessage.Red.CallerLeft");
    }
  }

  const setOfferingOperation = () => {
    setOperation(intl.formatMessage({ id: "CallActions.Offering" }));
  }

  const setOngoingCallOperation = () => {
    setOperation(intl.formatMessage({ id: "CallActions.OngoingCall" }));
  }

  const setOutboundCallOperation = () => {
    setOperation(intl.formatMessage({ id: "CallActions.OutboundCall" }));
  }

  const setRinging3dPartyOperation = () => {
    setOperation(intl.formatMessage({ id: "CallActions.Ringing3dParty" }));
    const isCancelButtonVisible = currentCallSession.LastInvitedParticipantId !== '';
    setCancelWarmTransferVisible(isCancelButtonVisible);
  }

  const currentUserOnColdTransferStartedallSessionChanged = (obj: any) => {
    transferTo.current = obj.transferTo?.toString().toLowerCase();
    contactType.current = obj.contactType;

    handleColdTransfer();
  }

  const onWarmTransferStarted = (obj: any) => {
    transferTo.current = obj.transferTo.toLowerCase();
    contactType.current = obj.contactType;

    handleWarmTransfer();
  }

  const onPresenceAndNoteSet = (obj: PresenceAndNoteDTO) => {
    //handle teams outbound
    if (obj.Activity === ReasonNameConstants.InACall &&
      currentCallSession &&
      (currentCallSession.IsTeamsCampaignOutboundCall || currentCallSession.IsOutboundCall) &&
      currentCallSession.ActionHistory &&
      currentCallSession.ActionHistory.includes(ConferenceStatusAction.Accepted)) {
      setEndCallVisible(true);
      setPauseState(CallPauseState.pause);
      setColdTransferVisible(true);
      setWarmTransferVisible(true);

      setOngoingCallOperation();
      socketAudio.callActionViewIsDisplayed.next(true);
    }

    if (obj.Activity === ReasonNameConstants.Offering &&
      currentCallSession &&
      (currentCallSession.IsTeamsCampaignOutboundCall || currentCallSession.IsOutboundCall) &&
      currentCallSession.ActionHistory &&
      currentCallSession.ActionHistory.includes(ConferenceStatusAction.Accepted)) {
      outboundRinging(currentCallSession);
    }
  }

  const initialize = () => {
    GeneralHelper.logCox(`in CallActions.tsx, in initialize`);
    subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
    subscriptionCurrentUserCallSessionStateChanged = socketAudio.currentUserCallSessionChanged.subscribe(currentUserCallSessionChanged);
    subscriptionColdTransfer?.unsubscribe();
    subscriptionColdTransfer = socketTransfer.onColdTransferStarted.subscribe(currentUserOnColdTransferStartedallSessionChanged);
    subscriptionWarmTransfer?.unsubscribe();
    subscriptionWarmTransfer = socketTransfer.onWarmTransferStarted.subscribe(onWarmTransferStarted);
    subscriptionPresenceAndNote?.unsubscribe();
    subscriptionPresenceAndNote = socketPresence.listenerPresenceAndNoteSet.received.subscribe(onPresenceAndNoteSet);
    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged = socketAudio.currentUserMonitoredCallSessionChanged.subscribe(currentUserMonitoredCallSessionChanged);

    setIsOngoingCallSelected(Lockr.get<ActiveMainViewItem>(StorageKeys.MainViewTab) === ActiveMainViewItem.MediaView);

    subscriptionViewChanged?.unsubscribe();
    subscriptionViewChanged = mainViewHelper.viewChanged.subscribe((activeViewItem: ActiveMainViewItem) => {
      setIsOngoingCallSelected(activeViewItem === ActiveMainViewItem.MediaView);
    });

    subscriptionHeaderBarMessageChanged?.unsubscribe();
    subscriptionHeaderBarMessageChanged = mainViewHelper.showHeaderBarMessage.subscribe((messageType: HeaderBarDisplayedMessageType) => {
      setIsOngoingCallSelected(messageType === HeaderBarDisplayedMessageType.MediaView)
    });

    subscriptionCallSessionChanged?.unsubscribe();
    subscriptionCallSessionChanged = socketAudio.callSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      const userId = Lockr.get<string>(StorageKeys.UserId);

      const warmTranferCancelledOrRejected = callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmInviteRejected] ||
        callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmCanceled] ||
        callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent];

      if (userId !== callSessionDTO.AgentRef && warmTranferCancelledOrRejected && !callSessionDTO.IsCurrentUserMonitoring) {
        setOperation("");
      }

      if (userId === callSessionDTO.AgentRef &&
        (warmTranferCancelledOrRejected || callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OnHold])) {
        setPausedCall();
      }

      if ((endOperations.includes(callSessionDTO.ConferenceActionAsString) ||
        (currentCallSession.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransferred] &&
          (callSessionDTO.AgentBeforeTransferRef === "" || callSessionDTO.AgentBeforeTransferRef === null) || 
          callSessionDTO.AgentBeforeTransferRef === currentUserId)) &&
        (currentSessionId.current === callSessionDTO.SessionId || callSessionDTO.IsOutboundCall)
        && !callSessionDTO.IsCurrentUserMonitoring) {
        endCall();
      }

      if (callSessionDTO.CallerId === userId && callSessionDTO.IsOutboundCall &&
        callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.Terminated]) {
        if (currentCallSession.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Offering]) {
          outboundRinging(callSessionDTO);
        }
        else {
          outboundCall(callSessionDTO);
        }
      }
    });

    subscriptionWarmTransferInProgress?.unsubscribe();
    subscriptionWarmTransferInProgress = socketAudio.warmTransferInProgress.subscribe((callSessionDTO: CallSessionDTO) => {
      if (callSessionDTO.IsWarmTransferInProgress &&
        callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.AgentClosed]) {
        setOperation(`${intl.formatMessage({ id: "CallActions.Transferring" })} ${transferToNameStage3.current}`)
      } else {
        setOperation("");
      }
    });

    subscriptionOnHoldChanged?.unsubscribe();
    subscriptionOnHoldChanged = socketAudio.callOnHoldStatusChanged.subscribe((status: OnHoldStatus) => {
      if (!status.Status || !pauseTimeoutRef.current) {
        return;
      }
      setPausedCall();
    });
  }

  const startStage3Call = (callSessionDTO: CallSessionDTO) => {
    currentSessionId.current = callSessionDTO && callSessionDTO.SessionId ? callSessionDTO.SessionId : '';
    setRejectCallVisible(false);
    setEndCallVisible(false);
    setPauseState(CallPauseState.none);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);

    setIsOngoingCallSelected(true);
    setParkCallVisible(false);

    setOfferingOperation();

    socketAudio.callActionViewIsDisplayed.next(true);
    setCallSession(callSessionDTO);
  }

  const startCall = (callSessionDTO: CallSessionDTO) => {
    currentSessionId.current = callSessionDTO && callSessionDTO.SessionId ? callSessionDTO.SessionId : '';
    ongoingCall(callSessionDTO);
    setCallSession(callSessionDTO);
    setCallStartTime(callSessionDTO.PrimaryAgentAnswerDate);
  }

  const ongoingCall = (obj?: any) => {
    setEndCallVisible(true);
    setPauseState(CallPauseState.pause);
    setColdTransferVisible(true);
    setWarmTransferVisible(true);
    setCancelWarmTransferVisible(false);
    setParkCallVisible(isParkCallVisible(obj));
    socketAudio.callActionViewIsDisplayed.next(true);
  }

  const endCall = (obj?: any) => {
    if (obj && obj.SessionId && currentSessionId.current && obj.SessionId !== currentSessionId.current) {
      //when operator is in a call and a parked call is ended by caller, ignore the end call event from the parked call session
      return;
    }
    currentSessionId.current = ''
    transferTo.current = '';
    transferToNameStage3.current = '';

    setAnswerCallVisible(false);
    setRejectCallVisible(false);
    setEndCallVisible(false);
    setPauseState(CallPauseState.none);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);
    setCancelWarmTransferVisible(false);
    setFinalizeWarmTransferVisible(false);
    setWarmTransferConsultCallerVisible(false);
    setWarmTransferConsultAgentVisible(false);
    setRecordCallVisible(false);
    setStopRecordCallVisible(false);
    setParkCallVisible(false);

    setOperation("");
    socketAudio.callActionViewIsDisplayed.next(false);

    setCallStartTime("");
    clearCallSession();
  }

  const clearPauseTimeout = () => {
    if (!pauseTimeoutRef.current) {
      return;
    }
    clearTimeout(pauseTimeoutRef.current);
    pauseTimeoutRef.current = null;
  }

  const pauseCall = () => {
    setLoadingCall();
    pauseTimeoutRef.current = setTimeout(() => setPausedCall(), callStatusWaitingTimeout);

    socketAudio.pauseCall(getCallSessionRequestObject())
      .then((response) => {
        if (response === false) {
          setResumedCall();

          logging.errorHandler.next("ErrorMessage.Transfer.PauseFailed");
        }
      }).catch(err => {
        setResumedCall();

        console.log(err.message);
        logging.errorHandler.next("ErrorMessage.Transfer.PauseFailed");
      });
  }

  const resumeCall = () => {
    setResumedCall();

    socketAudio.resumeCall(getCallSessionRequestObject())
      .then((response) => {
        if (response === false) {
          setPausedCall();

          logging.errorHandler.next("ErrorMessage.Transfer.ResumeFailed");
        }
      }).catch(err => {
        setPausedCall();

        console.log(err.message);
        logging.errorHandler.next("ErrorMessage.Transfer.ResumeFailed");
      });
  }

  const setPausedCall = () => {
    clearPauseTimeout();
    setOperation(intl.formatMessage({ id: "CallActions.OnHold" }));
    socketAudio.callActionViewIsDisplayed.next(true);
    setPauseState(CallPauseState.resume);
  };

  const setResumedCall = () => {
    clearPauseTimeout();
    setOngoingCallOperation();
    socketAudio.callActionViewIsDisplayed.next(true);
    setPauseState(CallPauseState.pause);
  };

  const setLoadingCall = () => {
    socketAudio.callActionViewIsDisplayed.next(true);
    setPauseState(CallPauseState.loading);
  }

  const checkTransferTo = (value: string) => {
    const isValid = value.length < 50;

    if (isValid) {
      transferTo.current = value;
    }

    setIsInvalidTransfer(!isValid);
  };

  const handleColdTransferKeyPressFromTheCallActionPopup = (e: any, isColdTransferIVR: boolean = false) => {
    if (e.keyCode === 13 || e.which === 13) {
      if (!isColdTransferIVR) {
        handleColdTransferClickFromTheCallActionPopup();
      }
      else {
        handleColdTransferIVR();
      }
    }
  };

  const handleColdTransferClickFromTheCallActionPopup = () => {
    contactType.current = ContactType.Agent;
    handleColdTransfer();
  };

  const handleWarmKeyPress = (e: any) => {
    if (e.keyCode === 13 || e.which === 13) {
      handleWarmTransfer();
    }
  };

  const handleColdTransfer = () => {
    if (ValidationHelper.isValidInput(transferTo.current?.trim())) {
      setIsInvalidTransfer(false);

      transferRequest.TargetUri = ValidationHelper.appendSipIfInputDoesNotContainsIt(transferTo.current);
      transferRequest.ObjectId = transferTo.current;
      transferRequest.ContactType = contactType.current;

      const requestData = getCallSessionRequestObject();

      socketTransfer.coldTransfer(requestData).then((response) => {
        handleColdTransferStartStage3(response);
      });
    } else {
      setIsInvalidTransfer(true);

      logging.errorHandler.next(transferInvalidInputMessage);
    }
  }

  const handleColdTransferStartStage3 = (response: TransferStartResponse) => {
    if (response.ContactNotFound || !response.TransferStarted) {
      setIsInvalidTransfer(true);
    }

    if (response.ContactNotFound) {
      logging.errorHandler.next("ErrorMessage.Transfer.TransferContactNotFound");
      setOngoingCallOperation();

      return;
    }
    else {
      setRinging3dPartyOperation();
    }

    if (response.ContactUnavailable) {
      logging.errorHandler.next("ErrorMessage.Transfer.TransferContactUnavailable");
      setOngoingCallOperation();
      setCancelWarmTransferVisible(false);

      return;
    }

    if (!response.TransferStarted) {
      logging.errorHandler.next(transferInvalidInputMessage);

      return;
    }

    handleColdTransferPerformed();
  }

  const handleColdTransferPerformed = () => {
    logging.errorHandler.next("ErrorMessage.Transfer.ColdTransferPerformed");

    endCall();
    clearTransferInputs();
  }

  const handleWarmTransfer = () => {

    if (ValidationHelper.isValidInput(transferTo.current.trim())) {
      setIsInvalidTransfer(false);

      transferRequest.TargetUri = ValidationHelper.appendSipIfInputDoesNotContainsIt(transferTo.current);
      transferRequest.ObjectId = transferTo.current;
      transferRequest.ContactType = contactType.current;

      const requestData = getCallSessionRequestObject();
      socketTransfer.warmTransferStart(requestData).then((response) => {
        handleWarmTransferStartStage3(response);
      }).catch(err => {
        console.log(err.message);
        warmTransferFailed("ErrorMessage.Transfer.WarmTransferFailed");
      })
    } else {
      setIsInvalidTransfer(true);

      logging.errorHandler.next(transferInvalidInputMessage);
    }
  };

  const handleWarmTransferStartStage3 = (response: TransferStartResponse) => {
    if (response.ContactNotFound) {
      warmTransferFailed("ErrorMessage.Transfer.TransferContactNotFound");
      return;
    }

    if (response.ContactUnavailable) {
      warmTransferFailed("ErrorMessage.Transfer.TransferContactUnavailable");
      return;
    }

    if (response.ContactNotSupported != UnsupportedContactType.NA) {
      warmTransferFailed(`ErrorMessage.Transfer.TransferContactNotSupported.${UnsupportedContactType[response.ContactNotSupported]}`);
      setOngoingCallOperation();
      setCancelWarmTransferVisible(false);
      return;
    }

    if (!response.TransferStarted) {
      warmTransferFailed("ErrorMessage.Transfer.WarmTransferFailed");
      return;
    }

    clearTransferInputs();
    setWarmTransferStarted();
  }

  const clearTransferInputs = () => {
    setWarmTransferInputValue('');
    setColdTransferInputValue('');
  }

  const warmTransferFailed = (message: string) => {
    logging.errorHandler.next(message);
    socketTransfer.onWarmTransferFailed.next();
  }

  const setWarmTransferStarted = () => {
    const isCancelButtonVisible = currentCallSession.LastInvitedParticipantId !== '';
    setIsInvalidTransfer(false);
    setOperation(`${intl.formatMessage({ id: "CallActions.Ringing3dParty" })} ${transferToNameStage3.current}`);
    socketAudio.callActionViewIsDisplayed.next(true);
    setCancelWarmTransferVisible(isCancelButtonVisible);
    setPauseState(CallPauseState.none);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);
    setEndCallVisible(false);
    setParkCallVisible(false);
  };

  const setWarmTransferAccepted = () => {
    setPauseState(CallPauseState.none);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);
    setEndCallVisible(false);
    setWarmTransferConsultCallerVisible(true);
    setFinalizeWarmTransferVisible(true);
    setCancelWarmTransferVisible(true);
    setOperation(`${intl.formatMessage({ id: "CallActions.Transferring" })} ${(transferToNameStage3.current)}`);
    socketAudio.callActionViewIsDisplayed.next(true);
  };

  const setWarmTransferCanceled = () => {
    setCancelWarmTransferVisible(false);
    setWarmTransferConsultAgentVisible(false);
    setWarmTransferConsultCallerVisible(false);
    setFinalizeWarmTransferVisible(false);
    setPauseState(CallPauseState.resume);
    setEndCallVisible(true);
    setColdTransferVisible(true);
    setWarmTransferVisible(true);

    setOperation(intl.formatMessage({ id: "CallActions.OnHold" }));
    socketAudio.callActionViewIsDisplayed.next(true);
  };

  const warmTransferCancel = () => {
    socketTransfer.warmTransferCancel(getCallSessionRequestObject());
  }

  const warmTransferConsultCaller = () => {
    const requestData = getCallSessionRequestObject();
    setWarmTransferConsultCaller();
    socketTransfer.warmTransferConsultCaller(requestData);
  }

  const setWarmTransferConsultCaller = () => {
    setPauseState(CallPauseState.none);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);
    setEndCallVisible(false);
    setWarmTransferConsultCallerVisible(false);
    setWarmTransferConsultAgentVisible(true);
    setFinalizeWarmTransferVisible(true);
    setCancelWarmTransferVisible(true);

    setOperation(`${intl.formatMessage({ id: "CallActions.ConsultCaller" })} ${(callerName.current)}`);
    socketAudio.callActionViewIsDisplayed.next(true);
  }

  const warmTransferConsultAgent = () => {
    const requestData = getCallSessionRequestObject();
    setWarmTransferConsultAgent();

    socketTransfer.warmTransferConsultAgent(requestData);
  }

  const setWarmTransferConsultAgent = () => {
    setPauseState(CallPauseState.none);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);
    setEndCallVisible(false);
    setFinalizeWarmTransferVisible(true);
    setCancelWarmTransferVisible(true);
    setWarmTransferConsultCallerVisible(true);
    setWarmTransferConsultAgentVisible(false);

    setOperation(`${intl.formatMessage({ id: "CallActions.ConsultAgent" })} ${(transferToNameStage3.current)}`);
    socketAudio.callActionViewIsDisplayed.next(true);
  }

  const warmTransferFinalize = () => {
    logging.errorHandler.next("ErrorMessage.Transfer.WarmTransferPerformed");
    socketTransfer.warmTransferFinalize(getCallSessionRequestObject());

    endCall();
  }

  const hangUp = () => {
    socketAudio.hangUp(getCallSessionRequestObject());

    endCall();
  };

  const rejectCall = () => {
    socketAudio.rejectCall(getCallSessionRequestObject());

    endCall();
  }

  const terminateMonitoring = () => {
    const ejectParticipantRequest = {
      scenarioId: callSessionRequest.ScenarioId, participantId: Lockr.get<string>(StorageKeys.UserId),
      tenantId: callSessionRequest.TenantId
    };

    socketAudio.ejectFromMeeting(ejectParticipantRequest);
    endCall();
  };

  const switchToMonitoringAction = (actionType: MonitorTypes) => {
    const startMonitoringRequestDTO = {
      scenarioId: callSessionRequest.ScenarioId, supervisingAgentId: Lockr.get<string>(StorageKeys.UserId),
      monitoringType: actionType
    }

    return socketAudio.switchToMonitoringAction(startMonitoringRequestDTO);
  };

  const toggleRecording = () => {
    setRecordCallVisible(!recordCallVisible);
    setStopRecordCallVisible(!stopRecordCallVisible);

    socketTransfer.toggleRecording(callSessionRequest);
  }

  const outboundRinging = (callSessionDTO: CallSessionDTO) => {
    setEndCallVisible(!callSessionDTO.IsCallBackRequest);
    setCallStartTime(callSessionDTO.CallStartDate);

    setPauseState(CallPauseState.none);

    setFinalizeWarmTransferVisible(false);
    setWarmTransferConsultAgentVisible(false);
    setWarmTransferConsultCallerVisible(false);
    setColdTransferVisible(false);
    setWarmTransferVisible(false);
    setParkCallVisible(false);

    if (callSessionDTO.IsOperatorCall) {
      setOngoingCallOperation();
    } else {
      setOutboundCallOperation();
    }
    socketAudio.callActionViewIsDisplayed.next(true);

    setCallSession(callSessionDTO);
  }

  const outboundCall = (callSessionDTO: CallSessionDTO) => {
    if (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Offering]
      && (callSessionDTO.IsColdTransferInProgress || callSessionDTO.IsColdTransferIVRInProgress)) {
      //cold transfer second agent ringing
      outboundRinging(callSessionDTO);
    } else {
      setCallStartTime(callSessionDTO.CallStartDate);
      if (!callSessionDTO.IsWarmTransferInProgress) {
        setOutboundCallOperation();
      }
    }
    socketAudio.callActionViewIsDisplayed.next(true);

    setCallSession(callSessionDTO);
  }

  const onPasteInputTransfer = (value: string, isWarm: boolean) => {
    const formatedValue = GeneralHelper.formatSipOrPhoneNumber(value.trim());
    skipInputTransferChangeEvent.current = true;
    checkTransferTo(formatedValue);
    isWarm ? setWarmTransferInputValue(formatedValue) : setColdTransferInputValue(formatedValue);
  }

  const onChangeInputTransfer = (value: string, isWarm: boolean) => {
    if (!skipInputTransferChangeEvent.current) {
      checkTransferTo(value);
      isWarm ? setWarmTransferInputValue(value) : setColdTransferInputValue(value)
    }
    else {
      skipInputTransferChangeEvent.current = false;
    }
  }

  const onBlurInputTransfer = (value: string, isWarm: boolean) => {
    const formatedValue = GeneralHelper.formatSipOrPhoneNumber(value.trim());
    checkTransferTo(formatedValue);
    isWarm ? setWarmTransferInputValue(formatedValue) : setColdTransferInputValue(formatedValue);
  }

  const getColdTransferDestinationsAfterStopTyping = (searchQuery?: string) => {
    setIsInvalidTransfer(false);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    typingTimeout = setTimeout(() => {
      getSearchedColdTransferDestinations(searchQuery?.toLowerCase());
    }, searchInterval);
  }

  const getSearchedColdTransferDestinations = (searchQuery?: string) => {
    setAreTranferDestinationsLoading(true);

    socketTransfer.getColdTransferDestinations().then((coldTransferDestinationsDTO: any[]) => {
      if (coldTransferDestinationsDTO) {
        if (searchQuery) {
          coldTransferDestinationsDTO = coldTransferDestinationsDTO.filter(cd => cd.Name.toLowerCase().includes(searchQuery));
        }
        setColdTransferDestinations(mapTargetListForDisplay(coldTransferDestinationsDTO));
      }
      setAreTranferDestinationsLoading(false);
    });
  }

  const mapTargetListForDisplay = (transferList: any) => {
    const coldTransferDestinationList = [] as any;

    const queues = transferList.filter((el: any) => el.Type === ColdTransferType.Queue);
    const flows = transferList.filter((el: any) => el.Type === ColdTransferType.Flow);

    if (flows.length) {
      coldTransferDestinationList.push({
        content: <Divider content="Flows" />,
        key: Math.random() + 'flows',
        disabled: true
      });
    }

    flows.forEach((flow: any) => {
      coldTransferDestinationList.push({
        header: flow.Name,
        transferId: flow.Id,
        type: ColdTransferType.Flow,
        key: Math.random() + 'transferTarget',
      });
    });

    if (queues.length) {
      coldTransferDestinationList.push({
        content: <Divider content="Queues" />,
        key: Math.random() + 'queues',
        disabled: true
      });
    }

    queues.forEach((queue: any) => {
      coldTransferDestinationList.push({
        header: queue.Name,
        transferId: queue.Id,
        type: ColdTransferType.Queue,
        key: Math.random() + 'transferTarget',
      });
    });

    return coldTransferDestinationList;
  }

  const handleColdTransferIVR = () => {
    if (currentSelectedColdTransferDestination.current !== '' && coldTransferDestinations && coldTransferDestinations.length) {
      setIsInvalidTransfer(false);

      var coldTransferDestination = coldTransferDestinations.filter((ref: any) => ref.header === currentSelectedColdTransferDestination.current)[0];

      socketTransfer.coldTransferIVR({
        ScenarioId: callSessionRequest.ScenarioId,
        TransferId: coldTransferDestination.transferId,
        Type: coldTransferDestination.type,
        TenantId: callSessionRequest.TenantId
      })

      handleColdTransferPerformed();
    } else {
      setIsInvalidTransfer(true);
      logging.errorHandler.next("ErrorMessage.Transfer.InvalidColdTransferDestination");
    }
  }

  const isParkCallVisible = (obj: CallSessionDTO) => {
    const userSip: string | null | undefined = Lockr.get(StorageKeys.SIP);

    if (obj.IsOutboundCall || obj.WasCallTransferred || obj.IsWarmTransferInProgress ||
      (obj.IsMonitoringInProgress && userSip?.toLowerCase() === obj.ActiveSupervisorSIP?.toLowerCase())) {
      return false;
    }

    const operatorQueue = queuesService.queuesArray.filter((element: any) => element.QueueRef === obj.QueueRef && element.IsOperator);
    if (!operatorQueue.length) {
      return false;
    }

    if (obj.ActionHistory && obj.ActionHistory.length) {
      if (obj.ActionHistory.indexOf(ConferenceStatusAction.OperatorColdTransfered) > -1 ||
        obj.ActionHistory.indexOf(ConferenceStatusAction.OperatorColdTransferInviteAccepted) > -1 || obj.ActionHistory.indexOf(ConferenceStatusAction.AgentColdTransfered) > -1) {
        return false;
      }
    }

    return true;
  }

  const parkCall = () => {
    socketAudio.parkCall(callSessionRequest);

    endCall();
  }

  const getCallSessionRequestObject = () => {
    const request = {
      AgentRef: callSessionRequest.AgentRef,
      CallId: callSessionRequest.CallId,
      TenantId: callSessionRequest.TenantId,
      QueueId: callSessionRequest.QueueRef,
      ScenarioId: callSessionRequest.ScenarioId,
      AzureTenantId: callSessionRequest.AzureTenantId,
      TransferTarget: '',
      TargetName: '',
      TransferTargetUpn: ''
    };

    if (!transferTo.current) {
      return request;
    }

    request.TransferTarget = ValidationHelper.removeSipIfInputContainsIt(transferTo.current);
    request.TargetName = callSessionRequest.SecondaryAgentDisplayName || '';
    request.TransferTargetUpn = callSessionRequest.SecondaryAgentUpn || '';

    return request;
  }

  const getPauseStateButtons = () => {
    if (pauseState === CallPauseState.pause) {
      return pauseCallButton(intl, pauseCall);
    }

    if (pauseState === CallPauseState.resume) {
      return resumeCallButton(intl, resumeCall);
    }

    if (pauseState === CallPauseState.loading) {
      return loadingCallButton(intl);
    }

    return null;
  }

  if (!operation || Lockr.get<ActiveMainViewItem>(StorageKeys.MainViewTab) !== ActiveMainViewItem.MediaView) {
    return null;
  }

  return <>
    {
      isOngoingCallSelected && <Flex></Flex>
    }
    {isOngoingCallSelected && <Flex vAlign="center" gap="gap.large">
      <Text content={operation} />
      {callStartTime && <Timer startDate={callStartTime}></Timer>}

      <Flex>
        {answerCallVisible ? answerCallButton(intl) : null}
        {rejectCallVisible ? rejectCallButton(intl, rejectCall) : null}
        {recordCallVisible ? recordCallButton(intl, toggleRecording) : null}
        {stopRecordCallVisible ? stopRecordCallButton(intl, toggleRecording) : null}

        {getPauseStateButtons()}
        {parkCallVisible && parkCallButton(intl, parkCall)}
        {warmTransferVisible ? warmTransferPopupButton({
          intl: intl,
          setIsInvalidTransfer: setIsInvalidTransfer,
          warmTransferInputValue: warmTransferInputValue,
          onChangeInputTransfer: onChangeInputTransfer,
          onBlurInputTransfer: onBlurInputTransfer,
          onPasteInputTransfer: onPasteInputTransfer,
          handleWarmKeyPress: handleWarmKeyPress,
          isInvalidTransfer: isInvalidTransfer,
          handleWarmTransfer: handleWarmTransfer
        } as WarmTransferPopupButtonParams) : null}
        {coldTransferVisible ? coldTransferPopupButton({
          intl: intl,
          setIsInvalidTransfer: setIsInvalidTransfer,
          coldTransferInputValue: coldTransferInputValue,
          onChangeInputTransfer: onChangeInputTransfer,
          onBlurInputTransfer: onBlurInputTransfer,
          onPasteInputTransfer: onPasteInputTransfer,
          handleColdTransferKeyPressFromTheCallActionPopup: handleColdTransferKeyPressFromTheCallActionPopup,
          isInvalidTransfer: isInvalidTransfer,
          handleColdTransferClickFromTheCallActionPopup: handleColdTransferClickFromTheCallActionPopup
        } as ColdTransferPopupButtonParams) : null}
        {coldTransferVisible ? coldTransferIVRPopupButton({
          intl: intl,
          getColdTransferDestinationsAfterStopTyping: getColdTransferDestinationsAfterStopTyping,
          coldTransferDestinations: coldTransferDestinations,
          currentSelectedColdTransferDestination: currentSelectedColdTransferDestination,
          handleColdTransferKeyPressFromTheCallActionPopup: handleColdTransferKeyPressFromTheCallActionPopup,
          areTranferDestinationsLoading: areTranferDestinationsLoading,
          isInvalidTransfer: isInvalidTransfer,
          handleColdTransferIVR: handleColdTransferIVR
        } as ColdTransferIVRPopupButtonParams) : null}
        {warmTransferConsultCallerVisible ? warmTransferConsultCallerButton(intl, warmTransferConsultCaller) : null}
        {warmTransferConsultAgentVisible ? warmTransferConsultAgentButton(intl, warmTransferConsultAgent) : null}
        {finalizeWarmTransferVisible ? finalizeWarmTransferButton(intl, warmTransferFinalize) : null}
        {warmTransferCancelVisible ? warmTransferCancelButton(intl, warmTransferCancel) : null}
        {endCallVisible ? endCallButton(intl, hangUp) : null}
        {monitoringButtonsVisible ? switchToListenButton(intl, listenButtonDisabled, switchToMonitoringAction, monitoringType) : null}
        {monitoringButtonsVisible ? switchToBargeInButton(intl, monitoringType, bargeInButtonDisabled, switchToMonitoringAction) : null}
        {monitoringButtonsVisible ? terminateMonitoringButton(intl, terminateMonitoring) : null}
      </Flex>
    </Flex>
    }
  </>
};

export default CallActions;

import { Button, CallIcon, MenuButton } from "@fluentui/react-northstar";
import Lockr from 'lockr';
import React from "react";
import { useIntl } from "react-intl";
import { ConferenceStatusAction, StorageKeys } from "utils";
import { AzureActiveDirectorySettingsDTO } from "utils/domain/azureActiveDirectorySettingsDTO";
import { CallSessionDTO } from "utils/domain/callSessionDTO";
import { Contact } from "utils/domain/extended/contact";
import { EnvHelper, GeneralHelper } from "utils/helpers";
import CallableItemsList from "./CallableItemsList";

type Props = {
    contact: Contact;

    azureADSettings: AzureActiveDirectorySettingsDTO;

    agentHasOutboundEnabled: boolean;

    currentCallSession: CallSessionDTO;
}

const NotInCallActions = ({ contact, azureADSettings, agentHasOutboundEnabled, currentCallSession }: Props) => {

    const intl = useIntl();

    const { getCallableItemsList } = CallableItemsList({
        contact,
        azureADSettings,
        agentHasOutboundEnabled
    })

    const isConsultInProgress = (action: string) => action && [
        ConferenceStatusAction[ConferenceStatusAction.WarmSwitchToAgent],
        ConferenceStatusAction[ConferenceStatusAction.WarmSwitchToCaller]
    ].includes(action);

    const currentUserId = Lockr.get<string>(StorageKeys.UserId);
    const consultInProgress = isConsultInProgress(currentCallSession.ConferenceActionAsString);

    const isStatusRinging = currentCallSession.ActionHistory?.length && GeneralHelper.isRinging(currentCallSession.ActionHistory);
    const isNotCurrentAgentRinging = isStatusRinging && currentCallSession.AgentRef !== currentUserId;
    const currentAgentColdTransfered = (currentCallSession.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransfered] ||
        currentCallSession.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransferred]) &&
        (currentCallSession.AgentBeforeTransferRef === "" || currentCallSession.AgentBeforeTransferRef === currentUserId);

    let showCallButton = agentHasOutboundEnabled && !consultInProgress &&
        ((!isStatusRinging ||
            isNotCurrentAgentRinging) &&
            (!currentCallSession.IsWarmTransferInProgress ||
                currentAgentColdTransfered));

    if (EnvHelper.isStage3() && currentCallSession.IsCurrentUserMonitoring) {
        showCallButton = false;
    }

    return (showCallButton
        ? <MenuButton align="end"
            trigger={<Button icon={<CallIcon outline />}
                iconOnly
                text
                title={intl.formatMessage({ id: "CallActions.Call" })} />}
            menu={getCallableItemsList(false, false)}
            on="click"
        />
        : <Button icon={<CallIcon outline />}
            disabled={true}
            iconOnly
            text
            title={intl.formatMessage({ id: "CallActions.Call" })} />
    );
}

export default NotInCallActions;
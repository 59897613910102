import { Flex, CloseIcon } from "@fluentui/react-northstar";
import { Agenda } from "@microsoft/mgt-react";
import React, { useState } from "react";
import { socketGraphApi } from "services/graphApi";
import { useIntl } from 'react-intl';
import { EnvHelper, GeneralHelper } from "utils/helpers";
import { CalendarSensitivity } from "utils";
import { CustomAgendaEventTemplate } from "./CustomAgendaEventTemplate";
import { CustomAgendaDateTemplate } from "./CustomAgendaDateTemplate";
import { initialClientLoadService } from "services/initialClientLoad";

export const AgendaContent = (props: any) => {
    const intl = useIntl();
    const { azureId, closeAgenda } = props;
    const [events, setEvents] = useState([] as any[]);

    React.useEffect(() => {
        initialize();
    }, []);

    const initialize = () => {
        getUserTimezone()?.then((timezone) => {
            getUserAgenda(timezone);
        });
    }

    const getUserAgenda = (timezone: string | undefined | null) => {
        socketGraphApi.getUserCalendarView(azureId)?.then((response: any) => {
            if (response?.value) {
                const eventsList = response.value;

                if (timezone && !EnvHelper.isStage3()) {
                    eventsList.forEach((x: any) => {
                        x.end.timeZone = timezone;
                        x.start.timeZone = timezone;
                    });
                }

                const isRestricted = EnvHelper.isStage3() ? initialClientLoadService.initialSettings?.AreContactsAgendaEventsHidden : GeneralHelper.isCalendarRestricted();

                if (isRestricted) {
                    eventsList.forEach((x: any) => {
                        x.subject = intl.formatMessage({ id: "ContactsView.Busy" });
                        x.locations = [];
                        x.location = {};
                    });
                } else {
                    eventsList.forEach((x: any) => {
                        if (x.sensitivity === CalendarSensitivity.Private) {
                            x.subject = intl.formatMessage({ id: "ContactsView.Private" });
                            x.locations = [];
                            x.location = {};
                        }
                    });
                }

                setEvents(eventsList);
            }
        });
    }

    const getUserTimezone = () => {
        return socketGraphApi.getUserTimeZone(azureId)?.then((response: any) => {
            if (response?.value) {
                return response.value;
            }

            return null;
        });
    }

    const getAgendaView = () => {
        if (EnvHelper.isStage3()) {
            return <Agenda groupByDay events={events}>
                <CustomAgendaDateTemplate template="header" intl={intl} />
                <CustomAgendaEventTemplate template="event" intl={intl} />
            </Agenda>
        }

        return <Agenda groupByDay events={events}>
        </Agenda>
    }

    return (
        <Flex column
            className="agenda-content-container"
            styles={({ theme: { siteVariables } }) => ({
                backgroundColor: siteVariables.colorScheme.default.background2,
                color: siteVariables.colorScheme.default.foreground2,
                overflow: "scroll"
            })}
        >
            <>
                <CloseIcon size="medium"
                    className="agenda-close-button"
                    title={intl.formatMessage({ id: "ErrorMessage.Close" })}
                    onClick={closeAgenda} />
                {
                    events.length > 0 ? getAgendaView() :
                        <div className="no-agenda-found">
                            {intl.formatMessage({ id: "ContactsView.NoEventFound" })}
                        </div>
                }
            </>
        </Flex>
    );
};

export default AgendaContent;


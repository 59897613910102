import React, { useState } from 'react';
import { Avatar, Chat, ChatItemProps, Text, ShorthandCollection, Loader } from '@fluentui/react-northstar';
import { webChatManager } from 'services/io/web-chat';
import { ChatMessageSource, ChatMessageType, ChatStatusType, StorageKeys } from 'utils';
import { useIntl } from 'react-intl';
import { socketSocialMedia } from 'services/social-media';
import { GeneralHelper } from 'utils/helpers';
import { CMMediaSessionMessageDTO, CMSourceType, CMStatusCode } from 'utils/domain/socialMedia/socialMediaSessionDTO';
import { ChatHistoryDTO } from 'utils/domain/chathistoryDTO';
import Lockr from 'lockr';
import { Subscription } from 'rxjs';

export const WebChatConversation = ({ chatConversation, isCmMedia, activeWebchatsessionId }: any) => {
  const [massageItems, setMassageItems] = useState<ShorthandCollection<ChatItemProps>>([]);
  const [chatRef, setChatRef] = useState(Math.random() as any);
  const [isTransferInProgress, setIsTransferInProgress] = React.useState(false);

  let subscriptionOnMessageSentFromAgent: Subscription | null = null;
  let subscriptionOnMessageReceivedFromCustomer: Subscription | null = null;
  let subscriptionOnChatEnded: Subscription | null = null;
  let subscriptionOnTransferToAgentInitiated: Subscription | null = null;
  let subscriptionOnSendChatTransferInvitationIgnored: Subscription | null = null;
  let subscriptionOnSendChatTransferInvitationAccepted: Subscription | null = null;
  let subscriptionChatHandled: Subscription | null = null;
  let subscriptionSendCmMessageReceivedFromCustomer: Subscription | null = null;

  let messages: ShorthandCollection<ChatItemProps> = [];

  const intl = useIntl();

  React.useEffect(() => {
    initialize();
    return () => {
      componentWillUnmount();
    }
  }, []);

  const initialize = () => {
    GeneralHelper.logCox(`in WebChatConversation.tsx, in initialize`);

    if (chatConversation && chatConversation.ChatStatusType === ChatStatusType.TransferToAgentInviteSent) {
      setIsTransferInProgress(true);
    }
    if (isCmMedia || (chatConversation && !isChatIncomingState(chatConversation.ChatStatusType))) {
      getChatHistoryForSessionId();
    }

    subscriptionOnMessageSentFromAgent?.unsubscribe();
    subscriptionOnMessageSentFromAgent = webChatManager.onMessageSentFromAgent.subscribe((obj: any) => {
      obj.CmStatusCode = CMStatusCode.Ok;
      addMessageToChat(obj, ChatMessageSource.Agent, false);
    });

    if (!isCmMedia) {
      subscriptionOnMessageReceivedFromCustomer?.unsubscribe();
      subscriptionOnMessageReceivedFromCustomer = webChatManager.onMessageReceivedFromCustomer.subscribe((obj: any) => {
        if (obj && chatConversation && chatConversation.SessionRef && chatConversation.SessionRef === obj.ChatSessionRef) {
          addMessageToChat(obj, obj.Source, false);
        }
      });

      subscriptionOnChatEnded?.unsubscribe();
      subscriptionOnChatEnded = webChatManager.onChatEnded.subscribe((obj: any) => {
        if (obj === chatConversation.SessionRef) {
          setMassageItems([]);
        }
      });

      subscriptionOnTransferToAgentInitiated?.unsubscribe();
      subscriptionOnTransferToAgentInitiated = webChatManager.onTransferToAgentInitiated.subscribe((obj: any) => {
        if (obj) {
          setIsTransferInProgress(true);
        }
      });

      subscriptionOnSendChatTransferInvitationIgnored?.unsubscribe();
      subscriptionOnSendChatTransferInvitationIgnored = webChatManager.onSendChatTransferInvitationIgnored.subscribe((obj: any) => {
        setIsTransferInProgressFalse(obj);
      });

      subscriptionOnSendChatTransferInvitationAccepted?.unsubscribe();
      subscriptionOnSendChatTransferInvitationAccepted = webChatManager.onSendChatTransferInvitationAccepted.subscribe((obj: any) => {
        setIsTransferInProgressFalse(obj);
      });

      subscriptionChatHandled?.unsubscribe();
      subscriptionChatHandled = webChatManager.onAcceptRejectChat.subscribe((data: any) => {
        setIsTransferInProgress(false);
      });
    } else {
      subscriptionSendCmMessageReceivedFromCustomer?.unsubscribe();
      subscriptionSendCmMessageReceivedFromCustomer = socketSocialMedia.sendCmMessageReceivedFromCustomer.subscribe((cmMessage: CMMediaSessionMessageDTO) => {
        const agentSip = Lockr.get<string>(StorageKeys.SIP);
        const chatHistoryList: ChatHistoryDTO[] = GeneralHelper.mapCmMessagesToChatHistoryList([cmMessage], agentSip);
        const messageSource = cmMessage.SourceType === CMSourceType.FromAgent ? ChatMessageSource.Agent : ChatMessageSource.Visitor;

        if (chatHistoryList?.length) {
          const element = chatHistoryList[0];
          addMessageToChat(element, messageSource, false);
          scrollToChatMessagesBottom();
        }
      });
    }
  }

  const setIsTransferInProgressFalse = (obj: any) => {
    if (obj) {
      setIsTransferInProgress(false);
    }
  }

  const componentWillUnmount = () => {
    GeneralHelper.logCox(`in WebChatConversation.tsx, in componentWillUnmount`);

    subscriptionOnMessageSentFromAgent?.unsubscribe();
    subscriptionOnMessageReceivedFromCustomer?.unsubscribe();
    subscriptionOnChatEnded?.unsubscribe();
    subscriptionOnTransferToAgentInitiated?.unsubscribe();
    subscriptionOnSendChatTransferInvitationIgnored?.unsubscribe();
    subscriptionOnSendChatTransferInvitationAccepted?.unsubscribe();
    subscriptionChatHandled?.unsubscribe();
    subscriptionSendCmMessageReceivedFromCustomer?.unsubscribe();
  }

  const isChatIncomingState = (status: any) => {
    return (status === ChatStatusType.Incoming
      || status === ChatStatusType.Ringing
      || status === ChatStatusType.Ignored
      || status === ChatStatusType.TransferedToQueue
      || status === ChatStatusType.TransferToAgentInviteSent);
  }

  const initMessageList = (chatMessages: ChatHistoryDTO[]) => {
    if (chatMessages) {
      chatMessages.forEach((element: any) => {
        addMessageToChat(element, element.Source, true);
      });
      scrollToChatMessagesBottom();
    }
  }

  const getChatHistoryForSessionId = () => {
    if (!isCmMedia && chatConversation && chatConversation.SessionRef) {
      webChatManager.getChatHistoryFromSessionManager(chatConversation.SessionRef).then(response => {
        messages = [];
        initMessageList(response);
      })
    }

    if (isCmMedia) {
      socketSocialMedia.GetActiveCmMediaConversationHistory(activeWebchatsessionId).then((cmMessages: CMMediaSessionMessageDTO[]) => {
        renderSocialMediaMessages(cmMessages);
      });
    }
  }

  const renderSocialMediaMessages = (cmMessages: CMMediaSessionMessageDTO[]) => {
    const agentSip = Lockr.get<string>(StorageKeys.SIP);
    const chatHistoryList: ChatHistoryDTO[] = GeneralHelper.mapCmMessagesToChatHistoryList(cmMessages, agentSip);
    messages = [];
    initMessageList(chatHistoryList);
  }

  const createChatInitials = () => {
    return (chatConversation.CustomerName === '') ? chatConversation.WebChatName :
      chatConversation.CustomerName.trim().replace(/\s\s+/g, ' ').split(' ').splice(0, 2).join(' ').toUpperCase();
  }

  const createChatMessageAuthor = () => {
    return (chatConversation.CustomerName === '') ? chatConversation.ClientUniqueId :
      chatConversation.CustomerName.trim().replace(/\s\s+/g, ' ');
  }

  const addMessageToChat = (obj: ChatHistoryDTO, source: ChatMessageSource, isInit: boolean) => {
    const dateFormatted = getDateFormated(new Date(obj.Date));
    const chatName = isCmMedia ? obj.Name : createChatInitials();
    const chatAuthor = isCmMedia ? obj.Name : createChatMessageAuthor();
    const existingMessages = messages.filter((item: any) => item.chatmessageid === obj.Id);

    if (isCmMedia && existingMessages?.length && source === ChatMessageSource.Agent) {
      replaceExistingMessage(obj, dateFormatted);
    }

    if (!obj.Id || !existingMessages?.length) {
      if (source === ChatMessageSource.Visitor) {
        messages.push({
          gutter: <Avatar name={chatName} />,
          message: <Chat.Message content={obj.Message} author={chatAuthor} timestamp={dateFormatted} />,
          attached: 'top',
          key: `message-id-${obj.Date}-${Math.random()}`,
          chatmessageid: obj.Id
        });
      }
      if (source === ChatMessageSource.Agent) {
        let messageContent = obj.Message;
        let errClassName = '';

        if (isCmMedia && (obj.CmStatusCode === undefined || obj.CmStatusCode !== CMStatusCode.Ok)) {
          messageContent = `${messageContent}(message not sent)`;
          errClassName = 'chat-error';
        }

        if (obj.Type === ChatMessageType.PrivateMessage) {
          messages.push({
            message: <Chat.Message className={errClassName} content={messageContent} timestamp={dateFormatted} mine styles={{ backgroundColor: "#F9526B" }} />,
            contentPosition: 'end',
            attached: 'top',
            key: `message-id-${obj.Date}-${Math.random()}`,
            chatmessageid: obj.Id
          });
        } else {
          messages.push({
            message: <Chat.Message className={errClassName} content={messageContent} timestamp={dateFormatted} mine />,
            contentPosition: 'end',
            attached: 'top',
            key: `message-id-${obj.Date}-${Math.random()}`,
            chatmessageid: obj.Id
          });
        }
      }
      if (source === ChatMessageSource.Server) {
        messages.push({
          children: <Text content={obj.Message} align="center" className="chat-error" />,
          key: `message-id-${obj.Date}-${Math.random()}`,
          chatmessageid: obj.Id
        });
      }
    }

    setMassageItems(messages);
    setChatRef(Math.random());
    if (!isInit) {
      scrollToChatMessagesBottom();
    }
  }

  const replaceExistingMessage = (obj: ChatHistoryDTO, dateFormatted: string) => {
    if (obj.CmStatusCode !== CMStatusCode.Ok) {
      const index = GeneralHelper.findIndexByAttr(messages, "chatmessageid", obj.Id);

      const messageContent = `${obj.Message}(message not sent)`;
      const errClassName = 'chat-error';
      const replaceMessage = {
        message: <Chat.Message className={errClassName} content={messageContent} timestamp={dateFormatted} mine />,
        contentPosition: 'end',
        attached: 'top',
        key: `message-id-${obj.Date}-${Math.random()}`,
        chatmessageid: obj.Id
      } as ChatItemProps;

      messages.splice(index, 1, replaceMessage);
    }
  }

  const getDateFormated = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year} ${GeneralHelper.formatHourAMPM(date)}`;
  }

  const scrollToChatMessagesBottom = () => {
    const component = document.querySelector(".messages-component");

    if (component === null) {
      return;
    }

    component.scrollTo(0, component.scrollHeight);
  }

  return (
    <>
      <Text content={intl.formatMessage({ id: "WebChatConversationView.Messages" })} weight="bold" className="messages-text" />

      {isTransferInProgress && <Loader label={intl.formatMessage({ id: "WebChatTransferView.TransferInProgress" })}
        styles={{ position: "absolute", height: "50%", width: "75%" }} />}
      <Chat key={chatRef} items={massageItems} className="messages-component" />
    </>
  )
}

export default WebChatConversation;

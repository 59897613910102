import jwt_decode from 'jwt-decode';

export default class JwtHelper {
    public static isTokenExpired(token: string | null, secondsBefore: number = 0): boolean {
        if (token == null || token.length === 0) {
            return true;
        }
        const decodedToken = jwt_decode(token);
        if (decodedToken != null && (decodedToken as any).exp != null) {
            // Check expiration
            const currentDateTime = Math.floor(new Date().getTime() / 1000); // milliseconds to seconds
            const tokenExpiryDateTime = (decodedToken as any).exp; // seconds
            // Check if token is expired or will expire in the next secondsBefore
            return tokenExpiryDateTime - currentDateTime < secondsBefore;
        }
        return true;
    }
}

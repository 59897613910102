import { Accordion, AccordionContentProps, AccordionTitleProps, ButtonGroup, Flex, List, ShorthandValue, Text, ThumbtackIcon, ThumbtackSlashIcon } from '@fluentui/react-northstar';
import { useIntl } from 'react-intl';
import React, { useEffect, useMemo, useState } from 'react';
import { groupBy } from 'utils/helpers/array-helper';
import ChoiceGroup, { IChoiceGroupItem } from 'components/ChoiceGroup';
import { GenericFilter, GenericListData, genericListService } from './GenericListService';
import { useSubjectListenerEffect } from 'utils/helpers/listenerHook';

interface AccordionPanelProps {
    content: ShorthandValue<AccordionContentProps>;
    title: ShorthandValue<AccordionTitleProps>;
}

export const ConnectionListView = () => {
    const intl = useIntl();

    const [accordionPanels, setAccordionPanels] = useState<AccordionPanelProps[]>([]);

    useSubjectListenerEffect((data: GenericListData[]) => {
        buildAccordionViewData(data);
    }, genericListService.dataUpdatedSubject);

    useEffect(() => genericListService.init(), []);

    const buildAccordionTitle = (name: string, key: string): JSX.Element => (<Text key={key} truncated content={name} />);

    const onClickGenericData = (generic: GenericListData) => {
        genericListService.selectGeneric(generic);
    }

    const pinnedButton = (generic: GenericListData) => {
        const icon = generic.pinned 
            ? <ThumbtackSlashIcon size="small" />
            : <ThumbtackIcon size="small"/>;

        return (<ButtonGroup
            buttons={[
                {
                    key: 'pinnedAction',
                    icon: icon,
                    iconOnly: true,
                    text: true,
                    onClick: (e) => {
                        e.stopPropagation(); 
                        togglePinnedState(generic);
                    },
                },
            ]}
        />);
    }

    const togglePinnedState = (generic: GenericListData) => {
        genericListService.pinneGeneric(generic, !generic.pinned);
    }

    const sortByLastHandledTime = (x: GenericListData, y: GenericListData): number => {
        const xlastHandledTime = x.lastHandledTime;
        const ylastHandledTime = y.lastHandledTime;

        if (x === y || (!xlastHandledTime && !ylastHandledTime) || xlastHandledTime == ylastHandledTime) {
            return 0;
        }

        return (xlastHandledTime || new Date(0)) < (ylastHandledTime || new Date(0)) ? 1 : -1;
    }

    const buildAccordionViewData = (genericsData: GenericListData[]) => {
        const groupedByQueue = groupBy(genericsData, (c: GenericListData) => c.queueId);
        const accordionPanelsByQueue: AccordionPanelProps[] = Object.keys(groupedByQueue)
            .map(x => {
                const listItems = [...groupedByQueue[x]];
                const firstElement = listItems[0];
                listItems.sort(sortByLastHandledTime);

                return {
                    title: buildAccordionTitle(firstElement.queueName, firstElement.queueId),
                    content: buildFinalItems(listItems, x),
                }
            });

        const pinnedItems = genericsData
            .filter(x => x.pinned)
            .sort(sortByLastHandledTime);

        var tabs = [];

        if (!!pinnedItems.length) {
            tabs.push({
                title: buildAccordionTitle(intl.formatMessage({ id: "Generic.Panel.Pinned" }), 'pinned'),
                content: buildFinalItems(genericsData.filter(x => x.pinned).sort(sortByLastHandledTime), 'pinned'),
            });
        }

        if (!!accordionPanelsByQueue.length) {
            tabs.push({
                title: buildAccordionTitle(intl.formatMessage({ id: "Generic.Panel.Queues" }), 'queues'),
                content: <Accordion 
                            key='queuesAccordion' 
                            defaultActiveIndex={[0]} 
                            panels={accordionPanelsByQueue} 
                            style={{marginLeft: '-10px'}}
                        />
            });
        }

        setAccordionPanels(tabs);
    }

    const formatDateForHeaderMedia = (date: Date | undefined) => {
        if (!date) {
            return '';
        }

        const now = new Date();
        const todayStartDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        const options: Intl.DateTimeFormatOptions = date > todayStartDay
            ? {hour: 'numeric', minute: 'numeric'}
            : {year: "numeric", month: "numeric", day: "numeric"};

        return new Intl.DateTimeFormat(undefined, options).format(date);
    }

    const buildFinalItems = (array: GenericListData[], categoryId: string = ''): JSX.Element => (<List
        key={`list_${categoryId}`}
        truncateContent
        truncateHeader
        selectable
        items={array.map(generic => ({
            key: `tree_item_${categoryId}_${generic.id}`,
            header: generic.name,
            content: generic.description,
            important: !generic.closed,
            endMedia: pinnedButton(generic),
            headerMedia: formatDateForHeaderMedia(generic.lastHandledTime),
            variables: {rootPadding: '0 10px'},
            onClick: (e) => { 
                e.stopPropagation();
                onClickGenericData(generic);
            }
        }))}
        style={{marginLeft: '-20px'}}
        variables={{rootPadding: ''}}
    />);

    const mappedFilters = useMemo<IChoiceGroupItem[]>(() => [
        {
            key: GenericFilter.Ongoing,
            label: intl.formatMessage({ id: "Generic.Filter.Ongoing" }),
        },
        {
            key: GenericFilter.All,
            label: intl.formatMessage({ id: "Generic.Filter.All" }),
        }
    ], [intl]);

    return (<Flex column>
        <ChoiceGroup 
            push
            items={mappedFilters}
            value={genericListService.filter.toString()}
            onChange={(x: IChoiceGroupItem, _) => genericListService.applyFilter(x.key as GenericFilter)}
        />
        <Accordion defaultActiveIndex={[0, 1]} panels={accordionPanels} style={{marginLeft: -10}}/>
    </Flex>);
};

export default ConnectionListView;

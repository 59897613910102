import { CalendarIcon, Button, Popup } from "@fluentui/react-northstar";
import React, { useState } from "react";
import { useIntl } from 'react-intl';
import AgendaContent from "../AgendaContent";

export const AgendaPopup = (props: any) => {
    const [open, setOpen] = useState(false);
    const intl = useIntl();
    const { azureId, isDisabled } = props;

    const closeAgenda = () => {
        setOpen(false);
    }

    const openAgenda = () => {
        setOpen(true);
    }

    const openChanged = (event: any) => {
        if (!event.currentTarget?.className?.includes("open-calendar-trigger")) {
            setOpen(false);
        }
    }

    return (
        <Popup
            open={open}
            onOpenChange={(e) => openChanged(e)}
            trigger={
                <Button
                    icon={<CalendarIcon />} text iconOnly
                    title={intl.formatMessage({ id: "ContactsView.OpenAgenda" })}
                    disabled={isDisabled}
                    className="calendar open-calendar-trigger"
                    onClick={openAgenda}
                />
            }
            content={{
                content: <div className="user-agenda-popup-content">
                    <AgendaContent azureId={azureId} closeAgenda={closeAgenda} />
                </div>,
                className: "user-agenda-popup"
            }}
            positionFixed
            on="click"
        />
    );
};

export default AgendaPopup;


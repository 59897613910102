import { WebChatOperations } from 'utils';
import { Listener, listeners } from 'services/io/listeners';
import { Subject, Subscription } from 'rxjs';
import { logging } from 'utils/logging';
import { ChatDTO } from 'utils/domain/chatDTO';
import { ChatHistoryDTO } from 'utils/domain/chathistoryDTO';
import { TransferWebchatToAgentRequest, TransferWebchatToQueueRequest, TransferTargetsRequest } from 'utils/domain/transferWebchat';
import { GeneralHelper } from 'utils/helpers';
import { WebChatOperations as WebChatOperationsS2 } from 'utils/enums-s2';
import { WebChatOperations as WebChatOperationsS3 } from 'utils/enums-s3';

export class WebChatManager {
    private readonly logger = logging.getLogger('WebChatManager');

    public listenerSendChatSessionToAgent: Listener<any> | null = null;
    public listenerSendChatDetailsToClients: Listener<any> | null = null;
    public listenerMessageReceivedFromCustomer: Listener<any> | null = null;
    public listenerSetAgentInAChat: Listener<any> | null = null;
    public listenerSendChatTransferInvitationIgnored: Listener<any> | null = null;
    public listenerSendChatTransferInvitationAccepted: Listener<any> | null = null;
    public listenerSendChatDetailsToSupervisor: Listener<any> | null = null;

    private readonly subscriptionSendChatSessionToAgent: Subscription | null = null;
    private readonly subscriptionSendChatDetailsToClients: Subscription | null = null;
    private readonly subscriptionMessageReceivedFromCustomer: Subscription | null = null;
    private readonly subscriptionSetAgentInAChat: Subscription | null = null;
    private readonly subscriptionSendChatTransferInvitationIgnored: Subscription | null = null;
    private readonly subscriptionSendChatTransferInvitationAccepted: Subscription | null = null;

    onSendChatSessionToAgent: Subject<any> = new Subject<any>();
    onSendChatDetailsToClients: Subject<any> = new Subject<any>();
    onMessageReceivedFromCustomer: Subject<any> = new Subject<any>();
    onSetAgentInAChat: Subject<any> = new Subject<any>();
    onMessageSentFromAgent: Subject<any> = new Subject<any>();
    onChatConversationChanged: Subject<any> = new Subject<any>();
    onEndChat: Subject<any> = new Subject<any>();
    onChatEnded: Subject<any> = new Subject<any>();
    onAcceptRejectChat: Subject<any> = new Subject<any>();
    newWebChatRequestsOnAppLoad: Subject<any> = new Subject<any>();
    onTransferToQueueInitiated: Subject<any> = new Subject<any>();
    onTransferToAgentInitiated: Subject<any> = new Subject<any>();
    onSendChatTransferInvitationIgnored: Subject<any> = new Subject<any>();
    onSendChatTransferInvitationAccepted: Subject<any> = new Subject<any>();
    webChatActionsViewIsDisplayed: Subject<any> = new Subject<any>();
    webChatNotificationReceivedFromBot: Subject<string> = new Subject<string>();

    constructor() {
        // initialize listeners. fix for ReferenceError: Cannot access 'WebChatOperations' before initialization
        let stage3: boolean = false;
        try {
            stage3 = (new URLSearchParams(window.location.search)).get('stage3') === "true";
        }
        catch (err) {
            console.error(err);
        }
        this.listenerSendChatSessionToAgent = listeners.createListener<any>(
            stage3 ? WebChatOperationsS3.SendChatSessionToAgent : WebChatOperationsS2.SendChatSessionToAgent
        );
        this.listenerSendChatDetailsToClients = listeners.createListener<any>(
            stage3 ? WebChatOperationsS3.SendChatDetailsToClients : WebChatOperationsS2.SendChatDetailsToClients
        );
        this.listenerMessageReceivedFromCustomer = listeners.createListener<any>(
            stage3 ? WebChatOperationsS3.MessageReceivedFromCustomer : WebChatOperationsS2.MessageReceivedFromCustomer
        );
        this.listenerSetAgentInAChat = listeners.createListener<any>(
            stage3 ? WebChatOperationsS3.SetAgentInAChat : WebChatOperationsS2.SetAgentInAChat
        );
        this.listenerSendChatTransferInvitationIgnored = listeners.createListener<any>(
            stage3 ? WebChatOperationsS3.SendChatTransferInvitationIgnored : WebChatOperationsS2.SendChatTransferInvitationIgnored
        );
        this.listenerSendChatTransferInvitationAccepted = listeners.createListener<any>(
            stage3 ? WebChatOperationsS3.SendChatTransferInvitationAccepted : WebChatOperationsS2.SendChatTransferInvitationAccepted
        );
        this.listenerSendChatDetailsToSupervisor = listeners.createListener<any>(
            stage3 ? WebChatOperationsS3.SendChatDetailsToSupervisor : WebChatOperationsS2.SendChatDetailsToSupervisor
        );

        this.subscriptionSendChatSessionToAgent?.unsubscribe();
        this.subscriptionSendChatSessionToAgent = this.listenerSendChatSessionToAgent.received.subscribe((obj: any) => {
            this.onSendChatSessionToAgent.next(obj);
        });

        this.subscriptionSendChatDetailsToClients?.unsubscribe();
        this.subscriptionSendChatDetailsToClients = this.listenerSendChatDetailsToClients.received.subscribe((obj: any) => {
            this.onSendChatDetailsToClients.next(obj);
        });

        this.subscriptionMessageReceivedFromCustomer?.unsubscribe();
        this.subscriptionMessageReceivedFromCustomer = this.listenerMessageReceivedFromCustomer.received.subscribe((obj: any) => {
            this.onMessageReceivedFromCustomer.next(obj);
        });

        this.subscriptionSetAgentInAChat?.unsubscribe();
        this.subscriptionSetAgentInAChat = this.listenerSetAgentInAChat.received.subscribe((obj: any) => {
            this.onSetAgentInAChat.next(obj);
        });

        this.subscriptionSendChatTransferInvitationIgnored?.unsubscribe();
        this.subscriptionSendChatTransferInvitationIgnored = this.listenerSendChatTransferInvitationIgnored.received.subscribe((obj: any) => {
            this.onSendChatTransferInvitationIgnored.next(obj);
        });

        this.subscriptionSendChatTransferInvitationAccepted?.unsubscribe();
        this.subscriptionSendChatTransferInvitationAccepted = this.listenerSendChatTransferInvitationAccepted.received.subscribe((obj: any) => {
            this.onSendChatTransferInvitationAccepted.next(obj);
        });
    }

    postChatMessageToWebClient(message: ChatDTO): Promise<any> {
        return GeneralHelper.invokeServiceCall(message, WebChatOperations.PostChatMessageToWebClient, this.logger);
    }

    sendChatMessage(message: ChatHistoryDTO): Promise<any> {
        return GeneralHelper.invokeServiceCall(message, WebChatOperations.SendChatMessage, this.logger);
    }

    acceptChatSession(sessionRef: string, agentSIP: string): Promise<any> {
        const requestData = {
            sessionRef: sessionRef,
            agentSIP: agentSIP
        };

        return GeneralHelper.invokeServiceCall(requestData, WebChatOperations.AcceptChatSession, this.logger);
    }

    ignoreChatSession(sessionRef: string, agentSIP: string): Promise<any> {
        const requestData = {
            sessionRef: sessionRef,
            agentSIP: agentSIP
        };

        return GeneralHelper.invokeServiceCall(requestData, WebChatOperations.IgnoreChatSession, this.logger);
    }

    endChatSession(sessionRef: string, agentSIP: string): Promise<any> {
        const requestData = {
            sessionRef: sessionRef,
            agentSIP: agentSIP
        };

        return GeneralHelper.invokeServiceCall(requestData, WebChatOperations.EndChatSession, this.logger);
    }

    getAgentMemberedQueuesPredefinedMessaged(sip: string): Promise<any> {
        const requestData = {
            sip: sip
        };

        return GeneralHelper.invokeServiceCall(requestData, WebChatOperations.GetAgentMemberedQueuesPredefinedMessaged, this.logger);
    }

    getChatHistory(sessionRef: string): Promise<any> {
        const requestData = {
            sessionRef: sessionRef
        };

        return GeneralHelper.invokeServiceCall(requestData, WebChatOperations.GetChatHistory, this.logger);
    }

    recoveryChatSession(sessionRef: string): Promise<any> {
        const requestData = {
            sessionRef: sessionRef
        };

        return GeneralHelper.invokeServiceCall(requestData, WebChatOperations.RecoveryChatSession, this.logger);
    }

    getAgentChatSessions(agentSIP: string): Promise<any> {
        const requestData = {
            agentSIP: agentSIP
        };

        return GeneralHelper.invokeServiceCall(requestData, WebChatOperations.GetAgentChatSessions, this.logger);
    }

    getChatHistoryFromSessionManager(sessionRef: string): Promise<any> {
        const requestData = {
            sessionRef: sessionRef
        };

        return GeneralHelper.invokeServiceCall(requestData, WebChatOperations.GetChatHistoryFromChatSessionManager, this.logger);
    }

    TransferChatSessionToQueue(transferWebChatToQueueRequest: TransferWebchatToQueueRequest): Promise<any> {
        return GeneralHelper.invokeServiceCall(transferWebChatToQueueRequest, WebChatOperations.TransferChatSessionToQueue, this.logger);
    }

    TransferChatSessionToAgent(transferWebChatToAgentRequest: TransferWebchatToAgentRequest): Promise<any> {
        return GeneralHelper.invokeServiceCall(transferWebChatToAgentRequest, WebChatOperations.TransferChatSessionToAgent, this.logger);
    }

    GetQueuesAndAgentsForChatTransfer(transferTargetsRequest: TransferTargetsRequest): Promise<any> {
        return GeneralHelper.invokeServiceCall(transferTargetsRequest, WebChatOperations.GetQueuesAndAgentsForChatTransfer, this.logger);
    }
}

export const webChatManager = new WebChatManager();

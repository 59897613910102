import React from 'react';
import { Button, Flex, Text } from '@fluentui/react-northstar';
import './dialpadButtonView.scss'

export const DialpadButton = ({ disabled, row, column, digit, text, createPhoneNumber }: any) => {

    const createNumber = () => {
        createPhoneNumber(digit);
    }

    return (
        <Button
            className="dialpad-button"
            disabled={disabled}
            onClick={createNumber}
            onKeyPress={(e: any) => e.preventDefault()}
            text
            content={<Flex column={true} hAlign="center">
                <Text disabled={disabled} color="brand" content={digit} weight="regular" styles={{ fontSize: "xx-large" }} />
                {text ? <Text content={text} weight="light" size="small" /> : <span style={{ lineHeight: "1.3333", fontSize: "0.75rem" }}>&nbsp;</span>}
            </Flex>}
            key={digit}
            style={{
                gridRow: row,
                gridColumn: column,
                height: '80px',
                minWidth: '100%'
            }}
        />
    )
};

export default DialpadButton;
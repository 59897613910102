export function getApiPresenceForContactCenterPresence(ccPresence: number) {
    return presenceMap.find(p => p.ccValue === ccPresence)?.apiValue;
}
  
const presenceMap = [
    { ccValue: 3000, apiValue: 'Online' },
    { ccValue: 4500, apiValue: 'Online' },
    { ccValue: 6000, apiValue: 'Busy' },
    { ccValue: 7500, apiValue: 'Busy' },
    { ccValue: 9000, apiValue: 'DoNotDisturb' },
    { ccValue: 12000, apiValue: 'Away' },
    { ccValue: 15000, apiValue: 'Away' },
    { ccValue: 18000, apiValue: 'Offline' },
];
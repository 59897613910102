import { Button, Dialog, EyeFriendlierIcon, EyeSlashIcon, PlayIcon, TrashCanIcon, Text, CallIcon } from "@fluentui/react-northstar";
import AdvancedTable, { stringCellComparator } from "components/AdvancedTable";
import { Paginator } from "containers";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import ReactPlayer from "react-player";
import { socketQueue } from "services/queue";
import { socketVoicemails } from "services/voiceMails";
import { ActiveMainViewItem, NotificationActionStatus, NotificationDataType, StorageKeys } from "utils";
import { NotificationStatusRequestDTO } from "utils/domain/voiceMailsView/notificationStatusRequestDTO";
import { VoiceMailRecordDTO } from "utils/domain/voiceMailsView/voiceMailRecordDTO";
import { VoicemailRequestDTO } from "utils/domain/voiceMailsView/voicemailRequestDTO";
import { GeneralHelper } from "utils/helpers";
import Lockr from 'lockr';
import { NotificationBaseDTO } from "utils/domain/voiceMailsView/notificationBaseDto";
import './voiceTabContenView.scss';
import { socketOutbound } from "services/outbound";
import { mainViewHelper } from "utils/helpers/main-view-helper";
import { supervisorManager } from "services/supervisor/supervisor-manager";
import { SupervisorProcessDTO } from "utils/domain/supervisor/SupervisorProcessDTO";
import { Subscription } from "rxjs";

export const VoicemailsTabContentView = (props: any) => {
  const intl = useIntl();
  const agentHasOutboundEnabled = useRef(false);
  const voiceMailNotificationList = useRef(props.voiceMailNotificationList as VoiceMailRecordDTO[]);
  const queueList = useRef(props.queueList as number[]);
  const [displayedVoiceMailsPerPage, setDisplayedVoiceMailsPerPage] = useState([] as any[]);
  const displayedVoiceMails = useRef([] as any[]);
  const supervisorUserQueues = useRef([] as any[]);
  const [idOfVoicemailToDelete, setIdOfVoicemailToDelete] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const numberOfPagesRef = useRef(1);
  const ElementsPerPage = 20;
  const bigGrowBoldClassValue = "bold-text big-grow";

  const columnsMembers = [
    {
      title: intl.formatMessage({ id: "VoiceMailsTabs.From" }), className: 'big-grow',
      key: 'CallerSip' + Math.random(), name: 'CallerSipColumn', cellComparator: stringCellComparator
    },
    {
      title: intl.formatMessage({ id: "VoiceMailsTabs.Queue" }), className: 'big-grow',
      key: 'QueueName' + Math.random(), name: 'QueueNameColumn', cellComparator: stringCellComparator
    },
    {
      title: intl.formatMessage({ id: "VoiceMailsTabs.Date" }), className: 'big-grow',
      key: 'RecordDate' + Math.random(), name: 'RecordDateColumn', cellComparator: stringCellComparator
    },
    {
      title: '', className: 'big-grow',
      key: 'AudioPlayer' + Math.random(), name: 'AudioPlayerColumn', cellComparator: stringCellComparator
    },
    {
      title: '', className: 'big-grow',
      key: 'AudioPlayerButtons' + Math.random(), name: 'AudioPlayerButtonsColumn', cellComparator: stringCellComparator
    }
  ];

  let subscriptionSocketQueueChanged: Subscription | null = null;
  let subscriptionVoiceMailChanged: Subscription | null = null;
  let subscriptionAgentHasOutboundAccessEnabled: Subscription | null = null;
  let subscriptionRoleChange: Subscription | null = null;

  React.useEffect(() => {
    initialize();
    return () => componentWillUnmount();
  }, []);

  const componentWillUnmount = () => {
    GeneralHelper.logCox(`in VoicemailsTabContentView.tsx, in componentWillUnmount`);
    subscriptionSocketQueueChanged?.unsubscribe();
    subscriptionVoiceMailChanged?.unsubscribe();
    subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
    subscriptionRoleChange?.unsubscribe();
  }

  const initialize = () => {
    GeneralHelper.logCox(`in VoicemailsTabContentView.tsx, in initialize`);
    initVoiceMails();

    subscriptionSocketQueueChanged?.unsubscribe();
    subscriptionSocketQueueChanged = socketQueue.subjectQueueChange.subscribe((resultQueue: any) => {
      queueList.current = GeneralHelper.getAttachedUserQueues(resultQueue);
      mapVoiceMailsDisplayedItems();
    });

    subscriptionVoiceMailChanged?.unsubscribe();
    subscriptionVoiceMailChanged = socketVoicemails.alertVoicemailChangeNotification.subscribe((obj: NotificationBaseDTO) => {
      if (obj.Type === NotificationDataType.VoiceMail) {
        handleVoiceMailChange(obj);
      }
    });

    subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
    subscriptionAgentHasOutboundAccessEnabled = socketOutbound.agentHasOutboundAccessEnabled.subscribe((isOutboundEnabled: boolean) => {
      if (agentHasOutboundEnabled.current !== isOutboundEnabled) {
        agentHasOutboundEnabled.current = isOutboundEnabled;
        mapVoiceMailsDisplayedItems();
      }
    });

    subscriptionRoleChange?.unsubscribe();
    subscriptionRoleChange = supervisorManager.onUserRoleChange.subscribe(() => {
      const userId = Lockr.get<number>(StorageKeys.UserId);

      supervisorManager.getAgentsBySupervisorRef(userId).then((obj: SupervisorProcessDTO) => {
        if (obj) {
          supervisorUserQueues.current = [];
          const queues = obj.Queues || [];
          queues.forEach(x => supervisorUserQueues.current.push(x.QueueRef));

          voiceMailNotificationList.current.forEach(x => x.CanBeDeleted = supervisorUserQueues.current.includes(x.QueueRef));

          mapVoiceMailsDisplayedItems();
        }
      })
    });
  }

  const initVoiceMails = () => {
    const userId = Lockr.get<number>(StorageKeys.UserId);

    supervisorManager.getAgentsBySupervisorRef(userId).then((obj: SupervisorProcessDTO) => {
      if (obj) {
        supervisorUserQueues.current = [];
        const queues = obj.Queues || [];
        queues.forEach(x => supervisorUserQueues.current.push(x.QueueRef));

        voiceMailNotificationList.current.forEach(x => x.CanBeDeleted = supervisorUserQueues.current.includes(x.QueueRef));
      }
    }).then(() => {
      agentHasOutboundEnabled.current = socketOutbound.HasOutboundAccesEnabled();
      mapVoiceMailsDisplayedItems();
    });
  }

  const sortVoicemailsByDate = (records: VoiceMailRecordDTO[]) => {
    return records.sort((a, b) => (a.RecordDate > b.RecordDate) ? -1 : ((b.RecordDate > a.RecordDate) ? 1 : 0))
  }

  const handleVoiceMailChange = (notification: NotificationBaseDTO) => {
    if (!notification || !notification.VoiceMailNotification) {
      return;
    }

    const voiceMailRecord = notification.VoiceMailNotification;
    const userId = Lockr.get<number>(StorageKeys.UserId);

    if (voiceMailRecord?.ViewerAgentId === userId && notification.NotificationActionStatus !== NotificationActionStatus.Removed) {
      return;
    }

    switch (notification.NotificationActionStatus) {
      case NotificationActionStatus.Updated:
        updateVoicemailRecord(voiceMailRecord);
        break;
      case NotificationActionStatus.Added:
        addVoicemailRecord(voiceMailRecord);
        break;
      case NotificationActionStatus.Removed:
        removeVoicemailRecord(voiceMailRecord.ID);
        break;
    }
  }

  const removeVoicemailRecord = (id: number) => {
    const removedNotifications = voiceMailNotificationList.current.filter(x =>
      x.ID === id
    );

    if (!removedNotifications || removedNotifications.length === 0) {
      return;
    }

    const removedNotifIndex = voiceMailNotificationList.current.findIndex(x => x.ID === id);

    if (removedNotifIndex > -1) {
      voiceMailNotificationList.current.splice(removedNotifIndex, 1);
      mapVoiceMailsDisplayedItems();
    }
  }

  const addVoicemailRecord = (voiceMailRecord: VoiceMailRecordDTO) => {
    const addedNotifIndex = voiceMailNotificationList.current.findIndex(x => x.ID === voiceMailRecord.ID);

    if (addedNotifIndex === -1) {
      voiceMailRecord.CanBeDeleted = supervisorUserQueues.current.includes(voiceMailRecord.QueueRef);
      voiceMailNotificationList.current.push(voiceMailRecord);
      mapVoiceMailsDisplayedItems();
    }
  }

  const updateVoicemailRecord = (voiceMailRecord: VoiceMailRecordDTO) => {
    const updatedNotifications = voiceMailNotificationList.current.filter(x =>
      x.ID === voiceMailRecord.ID
    );

    if (!updatedNotifications || updatedNotifications.length === 0) {
      return;
    }

    const updatedNotification = updatedNotifications[0];
    voiceMailRecord.FilePath = updatedNotification.FilePath;
    const updatedNotifIndex = voiceMailNotificationList.current.findIndex(x => x.ID === voiceMailRecord.ID);
    voiceMailRecord.CanBeDeleted = supervisorUserQueues.current.includes(voiceMailRecord.QueueRef);
    voiceMailNotificationList.current[updatedNotifIndex] = voiceMailRecord;

    mapVoiceMailsDisplayedItems();
  }

  const mapVoiceMailsDisplayedItems = () => {
    const displayedItems = [] as any[];
    const sortedItems = sortVoicemailsByDate(voiceMailNotificationList.current);

    sortedItems.forEach((el) => {
      if (queueList.current?.includes(el.QueueRef)) {
        displayedItems.push({
          key: `voice-mail-item${el.ID}}`,
          items: [
            {
              key: `${el.ID}caller-sip${Math.random()}`,
              content: el.CallerSip ? el.CallerSip.toLocaleLowerCase().replace("sip:", "") : "",
              className: el.IsViewed ? "big-grow" : bigGrowBoldClassValue
            },
            {
              key: `${el.ID}queue-name${Math.random()}`,
              content: el.QueueName,
              className: el.IsViewed ? "big-grow" : bigGrowBoldClassValue
            },
            {
              key: `${el.ID}record-date${Math.random()}`,
              content: GeneralHelper.formatAMPM(new Date(el.RecordDate)),
              className: el.IsViewed ? "big-grow" : bigGrowBoldClassValue
            },
            {
              key: `${el.ID}audio-player`,
              content: el.Visible && el.FilePath && !el.NoFileFound ? <ReactPlayer
                className="react-player-item"
                url={el.FilePath ? el.FilePath : ''}
                playing
                height="30px"
                controls
                key={"voicemails-audio-player" + el.ID}
              /> : el.Visible && el.NoFileFound && <Text content={intl.formatMessage({ id: "VoiceMailsTabs.NoVoicemailFound" })} />,
              className: "big-grow"
            },
            {
              key: `${el.ID}audio-player-buttons${Math.random()}`,
              content: <div className="voice-mail-action-buttons">
                <Button key={"voicemails-play-button" + el.ID} iconOnly text icon={<PlayIcon key={"voicemails-play-icon" + el.ID} />}
                  title="Play Voice Mail"
                  size="medium"
                  onClick={() => downloadVoiceMailMedia(el)} />
                {el.CanBeDeleted && <Button key={"voicemails-delete-button" + el.ID} iconOnly text icon={<TrashCanIcon key={"voicemails-not-delete-icon" + el.ID} />}
                  title="Delete Voice Mail"
                  size="medium"
                  onClick={() => toggleDeleteVoicemail(el)} />
                }
                {el.IsViewed ?
                  <EyeFriendlierIcon title={el.ViewerAgentName ? el.ViewerAgentName : ""} className="view-icon" key={"voicemails-viewed-icon" + el.ID} size="large" /> :
                  <EyeSlashIcon className="view-icon" key={"voicemails-not-viewed-icon" + el.ID} size="large" />
                }
                {
                  agentHasOutboundEnabled.current && <Button iconOnly text icon={<CallIcon outline onClick={() => performCallBack(el)} />}
                    title={intl.formatMessage({ id: 'CallHistoryView.CallBack' })} />
                }
              </div>,
              className: el.IsViewed ? "voice-mail-action-buttons-container" : "bold-text voice-mail-action-buttons-container"
            }
          ]
        });
      }
    });

    displayedVoiceMails.current = displayedItems;
    initNumberOfPages();
    selectPage(currentPageRef.current);
  }

  const downloadVoiceMailMedia = (voicemailRecord: VoiceMailRecordDTO) => {
    if (voicemailRecord.FilePath && voicemailRecord.Visible) {
      return;
    }

    const voicemailRequestDTO: VoicemailRequestDTO = {
      VoiceMailPath: voicemailRecord.FileName,
      VoiceMailID: voicemailRecord.ID
    };

    socketVoicemails.DownloadVoiceMailMedia(voicemailRequestDTO).then((result: any) => {
      const encoded = result ? encodeURI("data:audio/mpeg;base64," + result) : '';
      voiceMailNotificationList.current.forEach((el) => {
        if (el.ID === voicemailRecord.ID) {
          el.Visible = true;
          el.FilePath = encoded;
          el.IsViewed = true;
          el.NoFileFound = encoded === '';
        } else {
          el.Visible = false;
          el.NoFileFound = false;
        }
      });

      if (!voicemailRecord.ViewerAgentId) {
        changeVoiceMailStatus(voicemailRecord);
      }

      mapVoiceMailsDisplayedItems();
    });
  }

  const changeVoiceMailStatus = (voiceMailRecordDTO: VoiceMailRecordDTO) => {
    const userId = Lockr.get<number>(StorageKeys.UserId);

    const notificationStatusRequest: NotificationStatusRequestDTO = {
      ID: voiceMailRecordDTO.ID,
      IsViewed: true,
      AgentRef: userId,
      Type: NotificationDataType.VoiceMail
    };
    socketVoicemails.NotificationSetStatus(notificationStatusRequest);
  }

  const deleteVoicemail = (id: number) => {
    if (id <= 0) {
      setIdOfVoicemailToDelete(0);
      return;
    }

    const itemsToDelete = voiceMailNotificationList.current.filter(x => x.ID === id);

    if (!itemsToDelete || !itemsToDelete.length) {
      setIdOfVoicemailToDelete(0);
      return;
    }

    const itemToDelete = itemsToDelete[0];
    const deleteObject: NotificationBaseDTO = {
      Type: NotificationDataType.VoiceMail,
      NotificationActionStatus: NotificationActionStatus.Removed,
      VoiceMailNotification: itemToDelete
    };

    socketVoicemails.RemoveNotification(deleteObject);

    removeVoicemailRecord(id);
    setIdOfVoicemailToDelete(0);
  }

  const toggleDeleteVoicemail = (voicemail: VoiceMailRecordDTO) => {
    setIdOfVoicemailToDelete(voicemail.ID);
  }

  const performCallBack = (voicemail: VoiceMailRecordDTO) => {
    mainViewHelper.viewChanged.next(ActiveMainViewItem.MediaView);
    let callerNumber = voicemail.CallerPhone ? voicemail.CallerPhone : voicemail.CallerSip;
    callerNumber = callerNumber ? callerNumber.toLowerCase().replace("tel:", "") : callerNumber;
    const contactNumberValue = GeneralHelper.isMobileNumber(callerNumber) ? GeneralHelper.getMobileNumberFromSip(callerNumber) : callerNumber;

    setTimeout(() => {
      mainViewHelper.navigationViewGoToDialpad.next(contactNumberValue);
    }, 1500);
  }

  const selectPage = (page: any) => {
    if (page > numberOfPagesRef.current) {
      page = 1;
    }

    const displayedItems = displayedVoiceMails.current.slice((page - 1) * ElementsPerPage, page * ElementsPerPage);
    setDisplayedVoiceMailsPerPage(displayedItems);
    setCurrentPage(page);
    currentPageRef.current = page;
  }

  const initNumberOfPages = () => {
    const itemsCount = displayedVoiceMails.current.length;
    if (!itemsCount) {
      setNumberOfPages(1);
      numberOfPagesRef.current = 1;
      return;
    }

    const nrOfPages = itemsCount % ElementsPerPage === 0 ? Math.floor(itemsCount / ElementsPerPage) : Math.floor(itemsCount / ElementsPerPage) + 1;
    setNumberOfPages(nrOfPages);
    numberOfPagesRef.current = nrOfPages;
  }

  return (
    <>
      <AdvancedTable key="voicemail-tab-table-key" className="voicemails-table" columns={columnsMembers} rows={displayedVoiceMailsPerPage}
        label={intl.formatMessage({ id: "VoiceMailsTabs.Voicemails" })} />
      {numberOfPages > 1 && <Paginator key="voicemail-tab-paginator-key"
        currentPage={currentPage}
        totalPages={numberOfPages}
        onChange={selectPage}
      />}
      <Dialog
        open={idOfVoicemailToDelete > 0}
        confirmButton={intl.formatMessage({ id: "VoiceMailsTabs.DeleteVoicemailConfirm" })}
        onConfirm={() => deleteVoicemail(idOfVoicemailToDelete)}
        cancelButton={intl.formatMessage({ id: "VoiceMailsTabs.DeleteVoicemailCancel" })}
        onCancel={() => setIdOfVoicemailToDelete(0)}
        header={intl.formatMessage({ id: "VoiceMailsTabs.DeleteVoicemail" })}
        content={intl.formatMessage({ id: "VoiceMailsTabs.VoicemailWillBeDeletedMessage" })}
        styles={{ width: "500px" }}
      />
    </>
  )
};

export default VoicemailsTabContentView;

import { MenuActiveItem } from "utils";
import { TabDTO } from "./tabDTO";

export class ActiveMediaView {
    public ActiveItem!: MenuActiveItem;
    public TabDetails?: TabDTO;
    public ActiveTabId?: number;

    constructor(params: ActiveMediaView = {} as ActiveMediaView) {
        const {
            ActiveItem = MenuActiveItem.Default,
            TabDetails = new TabDTO(),
            ActiveTabId = 0
        } = params;

        this.ActiveItem = ActiveItem;
        this.TabDetails = TabDetails;
        this.ActiveTabId = ActiveTabId;
    }
}
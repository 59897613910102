import { Subject } from 'rxjs';
import { ApiTransfer } from ".";
import { logging, TransferOperations, Controllers } from "utils";
import { CallSessionRequest } from "utils/domain/transfercall";
import { InvokeServiceArgs } from "services/io/interfaces";
import { serviceCall } from "services/io/service-call";
import { ConfigurationHelper, EnvHelper, GeneralHelper } from 'utils/helpers';

export class SocketTransfer implements ApiTransfer {
    private readonly logger = logging.getLogger('SocketAuth');

    onWarmTransferStarted: Subject<any> = new Subject<any>();
    onWarmTransferFailed: Subject<any> = new Subject<any>();
    onColdTransferStarted: Subject<any> = new Subject<any>();

    coldTransfer(transferData: any): Promise<any> {
        if (!EnvHelper.isStage3()) {
            return this.invokeServiceCall(transferData, TransferOperations.ColdTransfer);
        }
        return GeneralHelper.invokeServiceCall(transferData, TransferOperations.ColdTransfer, this.logger);
    }

    warmTransferStart(transferData: any): Promise<any> {
        // Stage2
        if (!EnvHelper.isStage3()) {
            return this.invokeServiceCall(transferData, TransferOperations.WarmTransferStart);
        }
        // Stage3
        return GeneralHelper.invokeServiceCall(transferData, TransferOperations.WarmTransferStart, this.logger);
    }

    warmTransferCancel(transferData: any): Promise<any> {
        // Stage2
        if (!EnvHelper.isStage3()) {
            return this.invokeServiceCall(transferData, TransferOperations.WarmTransferCancel);
        }
        // Stage3
        return GeneralHelper.invokeServiceCall(transferData, TransferOperations.WarmTransferCancel, this.logger);
    }

    warmTransferFinalize(transferData: any): Promise<any> {
        // Stage2
        if (!EnvHelper.isStage3()) {
            return this.invokeServiceCall(transferData, TransferOperations.WarmTransferFinalize);
        }
        // Stage3
        return GeneralHelper.invokeServiceCall(transferData, TransferOperations.WarmTransferFinalize, this.logger);
    }

    warmTransferConsultCaller(transferData: any): Promise<any> {
        // Stage2
        if (!EnvHelper.isStage3()) {
            return this.invokeServiceCall(transferData, TransferOperations.WarmTransferConsultCaller);
        }
        // Stage3
        return GeneralHelper.invokeServiceCall(transferData, TransferOperations.WarmTransferConsultCaller, this.logger);
    }

    warmTransferConsultAgent(transferData: any): Promise<any> {
        // Stage2
        if (!EnvHelper.isStage3()) {
            return this.invokeServiceCall(transferData, TransferOperations.WarmTransferConsultAgent);
        }
        // Stage3
        return GeneralHelper.invokeServiceCall(transferData, TransferOperations.WarmTransferConsultAgent, this.logger);
    }

    toggleRecording(transferData: CallSessionRequest): Promise<any> {
        // Stage2
        if (!EnvHelper.isStage3()) {
            return this.invokeServiceCall(transferData, TransferOperations.ToggleRecording);
        }
        // Stage3 - not yet implemented
        return Promise.reject(null);
    }

    getColdTransferDestinations(): Promise<any[]> {
        return GeneralHelper.invokeServiceCall(null, TransferOperations.ColdTransferIVRDestinations, this.logger);
    }

    coldTransferIVR(transferRequest: any): Promise<any> {
        return GeneralHelper.invokeServiceCall(transferRequest, TransferOperations.ColdTransferIVR, this.logger);
    }

    invokeServiceCall(data: any, operation: string, controller: string = Controllers.Calls, fetchApiUrl: string = ConfigurationHelper.botApiUrl): Promise<any> {
        return new Promise((resolve, reject) => {
            const args: InvokeServiceArgs = {
                operation: operation,
                controller: controller,
                fetchApiUrl: fetchApiUrl,
                requestData: data,
                responseHandler: {
                    success: (result) => {
                        resolve(result);
                    },
                    error: (err) => {
                        logging.errorHandler.next("ErrorMessage.Offline");
                        this.logger.error(err);
                        reject(err);
                    }
                }
            };

            serviceCall.invokeService(args);
        });
    }
}

import { Subject } from "rxjs";
import { Listener, listeners } from "services/io/listeners";
import { ListenerOperations, StorageKeys, logging } from "utils";
import { Controllers, GenericOperation, GenericTransferState } from "utils/enums-s3";
import { GeneralHelper } from "utils/helpers";
import { GenericNotificationData } from "../Models/GenericNotificationData";
import { DateFromBackendUtc } from "utils/helpers/date-help";
import { filter } from "rxjs/operators";
import Lockr from "lockr";
import { InviteStatus, genericInviteService } from "../GenericActionsView/GenericInviteService";

export class GenericListService {
    private readonly logger = logging.getLogger('GenericListService');
    private _genericsData: GenericListData[] = [];
    private _filter: GenericFilter = GenericFilter.Ongoing;
    private _isInited: boolean = false;
    private _currentUserId: string = '';
    private _selectedGenericId: string = '';

    private readonly _acceptGenericListener: Listener<GenericNotificationData> = listeners.createListener<GenericNotificationData>(ListenerOperations.AcceptGenericNotification);
    private readonly _terminateGenericListener: Listener<GenericNotificationData> = listeners.createListener<GenericNotificationData>(ListenerOperations.TerminateGenericNotification);

    public readonly dataUpdatedSubject: Subject<GenericListData[]> = new Subject();
    public readonly genericOpenUrlSubject: Subject<GenericOpenUrlInfo | null> = new Subject();
    
    public get filter(): GenericFilter { return this._filter; }
    public get genericsData(): GenericListData[] {
        return this._genericsData
            .filter(x => this._filter === GenericFilter.Ongoing ? !x.closed : true)
            .map(x => ({...x})); 
    }
    public get activeGeneric(): GenericListData | null {
        if (!this._selectedGenericId) {
            return null;
        }
        
        const result = this._genericsData.find(x => x.id === this._selectedGenericId);

        return !!result
            ? {...result}
            : null;
    }

    public init() {
        if (this._isInited) {
            this.notifyDataChanged();
            return;
        }

        this._isInited = true;
        this._currentUserId = Lockr.get<string>(StorageKeys.UserObjectId);
        this._selectedGenericId = Lockr.get<string>(StorageKeys.LastActiveGenericId);
        this.registerListeners();

        GeneralHelper.invokeServiceCall('', GenericOperation.None, this.logger, Controllers.Generics)
            .then((data: GenericListData[]) => {
                this._genericsData = data.map(x => ({...x, lastHandledTime: DateFromBackendUtc(x.lastHandledTime?.toString())}))
                this.notifyDataChanged();
                
                const active = this.activeGeneric;
                
                if (!!active) {
                    this.selectGeneric(active);
                }
            })
            .catch(() => {
                this._isInited = false;
            });
    }

    public applyFilter(genericFilter: GenericFilter) {
        if (this._filter === genericFilter) { 
            return;
        }

        this._filter = genericFilter;
        this.notifyDataChanged();
    }

    public selectGeneric(generic: GenericListData) {
        if (genericInviteService.inviteStatus === InviteStatus.received) {
            return;
        }

        this.updateSelectedGenericId(generic.id);

        if (generic.closed) {
            genericInviteService.hideInviteIfExists();
            return;
        }

        genericInviteService.showActiveGeneric({
            genericId: generic.id,
            queueId: generic.queueId,
            queueName: generic.queueName,
            name: generic.name,
            description: generic.description,
            externalUrl: generic.externalUrl,
            lastHandledTime: generic.lastHandledTime ?? new Date(),
            isIframe: generic.isIframe,
            transferState: generic.transferState
        });
    }

    genericInviteAccepted(data: GenericOpenUrlInfo) {
        this._selectedGenericId = data.id;
        Lockr.set(StorageKeys.LastActiveGenericId, data.id);
        this.genericOpenUrlSubject.next(data);
    }

    genericTerminated() {
        this.updateSelectedGenericId('');
        this.notifyDataChanged();
    }

    transferInviteInitialized(genericId?: string) {
        this.removeTransferedGeneric(genericId);
        this.updateSelectedGenericId('');
        this.notifyDataChanged();
    }

    public pinneGeneric(generic: GenericListData, status: boolean) {
        const updated = this.findGenericById(generic.id);
        
        if (updated === undefined) {
            return;
        }
        
        updated.pinned = status;
        this.notifyDataChanged();

        const data = {
            id: updated.id, 
            status: status
        };

        GeneralHelper.invokeServiceCall(data, GenericOperation.Pinne, this.logger, Controllers.Generics)
            .catch(() => {
                updated.pinned = !status;
                this.notifyDataChanged();
            });
    }

    private findGenericById(id: string): GenericListData | undefined {
        return this._genericsData.find(x => x.id === id);
    }

    private notifyDataChanged() {
        this.dataUpdatedSubject.next(this.genericsData);
    }

    private notifySelectedGeneric() {
        this.genericOpenUrlSubject.next(this.activeGeneric);
    }

    private registerListeners() {
        this._acceptGenericListener.received
        .pipe(filter(x => x.AgentId === this._currentUserId))
        .subscribe((e: GenericNotificationData) => {
            if (!this._isInited) {
                return;
            }
            this.appendAcceptedGeneric(e);
        });

        this._terminateGenericListener.received
        .pipe(filter(x => x.AgentId === this._currentUserId))
        .subscribe((e: GenericNotificationData) => {
            if (!this._isInited) {
                return;
            }
            this.updateTerminatedGeneric(e);
        });
    }

    private appendAcceptedGeneric(data: GenericNotificationData) {
        this._genericsData.push({
            id: data.GenericId,
            agentId: data.AgentId,
            queueId: data.QueueId,
            queueName: data.QueueName,
            closed: false,
            pinned: false,
            externalUrl: data.ExternalUrl,
            name: data.Name,
            description: data.Description,
            lastHandledTime: DateFromBackendUtc(data.LastHandledTime?.toString()) ?? new Date(),
            isIframe: data.IsIframe,
            transferState: data.TransferState
        });
        
        this.notifyDataChanged();
    }

    private removeTransferedGeneric(genericId?: string) {
        this._genericsData = this._genericsData.filter(x => x.id !== genericId);
    }

    private updateTerminatedGeneric(data: GenericNotificationData) {
        var toUpdate = this._genericsData.find(x => x.id === data.GenericId);

        if (!toUpdate) {
            return;
        }

        toUpdate.closed = true;
        this.notifyDataChanged();
    }

    private updateSelectedGenericId(genericId: string) {
        this._selectedGenericId = genericId;
        Lockr.set(StorageKeys.LastActiveGenericId, genericId);
        this.notifySelectedGeneric();
    }
}

export interface GenericOpenUrlInfo {
    id: string;
    isIframe: boolean;
    externalUrl: string;
}

export enum GenericFilter {
    All = 'All',
    Ongoing = 'Ongoing'
}

export interface GenericListData {
    id: string;
    agentId: string;
    queueId: string;
    queueName: string;
    closed: boolean;
    pinned: boolean;
    isIframe: boolean;
    externalUrl: string;
    name: string;
    description: string;
    lastHandledTime?: Date;
    transferState: GenericTransferState;
}

export const genericListService = new GenericListService();
import React, { useState, useRef } from 'react';
import { Button, Flex, Popup, UserFriendsIcon, TextArea, Dropdown, Divider } from '@fluentui/react-northstar';
import { useIntl } from 'react-intl';
import { webChatManager } from 'services/io/web-chat';
import { ChatStatusType, StorageKeys, logging } from 'utils';
import Lockr from 'lockr';
import { transferWebchatToAgentRequest, transferWebchatToQueueRequest, transferTargetsRequest } from 'utils/domain/transferWebchat';
import { Subscription } from 'rxjs';
import { GeneralHelper } from 'utils/helpers';

const stylesFaded = {
    backgroundColor: "#605e5c",
    color: "#c8c6c4",
    width: '50px',
    height: '50px',
    ':hover': {
        backgroundColor: 'rgb(52, 52, 65)',
        color: "#c8c6c4",
    }
}

export const WebChatTransferView = ({ sessionRef }: any) => {
    const intl = useIntl();

    const [chatIsTerminated, setChatIsTerminated] = useState(false);
    const [transferInitiated, setTransferInitiated] = useState(false);
    const [targetsTransferList, setTargetsTransferList] = useState([] as any);
    const [transferMessage, setTransferMessage] = useState("");
    const [isTargetListLoading, setIsTargetListLoading] = useState(false);

    const currentSelectedTransferTarget = useRef('-1');

    let subscriptionSendChatDetailsToClients: Subscription | null = null;
    let subscriptionOnSendChatTransferInvitationIgnored: Subscription | null = null;

    let typingTimeout: any = 0;
    const searchInterval: any = 1000;

    React.useEffect(() => {
        initialize();
        return () => componentWillUnmount();
    }, []);

    const initialize = () => {
        GeneralHelper.logCox(`in WebChatTransferView.tsx, in initialize`);

        subscriptionSendChatDetailsToClients = webChatManager.onSendChatDetailsToClients.subscribe((obj: any) => {
            if (obj && sessionRef && obj.SessionId === sessionRef && obj.ChatStatusType === ChatStatusType.Terminated) {
                setChatIsTerminated(true);
            }
        });

        subscriptionOnSendChatTransferInvitationIgnored = webChatManager.onSendChatTransferInvitationIgnored.subscribe((obj: any) => {
            if (obj) {
                setTransferInitiated(false);
            }
        });
    }

    const componentWillUnmount = () => {
        GeneralHelper.logCox(`in WebChatTransferView.tsx, in componentWillUnmount`);

        subscriptionSendChatDetailsToClients?.unsubscribe();
        subscriptionOnSendChatTransferInvitationIgnored?.unsubscribe();
        clearTimeout(typingTimeout);
    }

    const getTransferTargetsAfterStopTyping = (obj: any) => {
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        typingTimeout = setTimeout(() => {
            getSearchedTransferTargets(obj);
        }, searchInterval);

    }

    const getSearchedTransferTargets = (obj: any) => {
        setIsTargetListLoading(true);
        transferTargetsRequest.companyId = Lockr.get<number>(StorageKeys.CompanyId);
        transferTargetsRequest.userId = Lockr.get<number>(StorageKeys.UserId);
        transferTargetsRequest.searchTerm = obj;

        webChatManager.GetQueuesAndAgentsForChatTransfer(transferTargetsRequest).then((data: any) => {
            if (data) {
                setTargetsTransferList(mapTargetListForDisplay(data));
            }
            setIsTargetListLoading(false);
        });
    }

    const mapTargetListForDisplay = (transferList: any) => {
        const transferTargets = [] as any;

        const agents = transferList.filter((el: any) => el.TargetAgentSIP);
        const queues = transferList.filter((el: any) => el.TargetQueueRef);

        if (agents.length) {
            transferTargets.push({
                content: <Divider content="Agents" />,
                key: Math.random() + 'agents',
                disabled: true
            });
        }

        agents.forEach((element: any) => {
            transferTargets.push({
                header: element.DisplayName,
                key: Math.random() + 'transferTarget',
                targetqueueref: null,
                targetagentsip: element.TargetAgentSIP
            });
        });

        if (queues.length) {
            transferTargets.push({
                content: <Divider content="Queues" />,
                key: Math.random() + 'queues',
                disabled: true
            });
        }

        queues.forEach((element: any) => {
            transferTargets.push({
                header: element.DisplayName,
                key: Math.random() + 'transferTarget',
                targetqueueref: element.TargetQueueRef,
                targetagentsip: null
            });
        });
        return transferTargets;
    }

    const initiateTransfer = () => {
        if (currentSelectedTransferTarget.current !== '-1' && targetsTransferList && targetsTransferList.length) {
            var transferTarget = targetsTransferList.filter((ref: any) => ref.header === currentSelectedTransferTarget.current)[0];
            if (transferTarget.targetqueueref) {
                transferToQueue(transferTarget.targetqueueref);
            } else {
                transferToAgent(transferTarget.targetagentsip);
            }
        } else {
            logging.errorHandler.next("ErrorMessage.WebChatTransferView.SelectTransferTarget");
        }
    }

    const transferToQueue = (queueRef: any) => {
        const userSip = Lockr.get<string>(StorageKeys.SIP);

        transferWebchatToQueueRequest.sessionRef = sessionRef;
        transferWebchatToQueueRequest.fromAgentSIP = userSip;
        transferWebchatToQueueRequest.targetQueueRef = queueRef;
        transferWebchatToQueueRequest.message = transferMessage;

        webChatManager.onTransferToQueueInitiated.next(sessionRef);
        setTransferInitiated(true);

        webChatManager.TransferChatSessionToQueue(transferWebchatToQueueRequest)
            .then((response: any) => {
            }).catch(err => {
                console.log(err.message);
            });
    }

    const transferToAgent = (agentSip: any) => {
        const userSip = Lockr.get<string>(StorageKeys.SIP);

        transferWebchatToAgentRequest.sessionRef = sessionRef;
        transferWebchatToAgentRequest.fromAgentSIP = userSip;
        transferWebchatToAgentRequest.targetAgentSIP = agentSip;
        transferWebchatToAgentRequest.message = transferMessage;

        webChatManager.onTransferToAgentInitiated.next({ SessionRef: sessionRef, TargetAgent: agentSip });
        setTransferInitiated(true);

        webChatManager.TransferChatSessionToAgent(transferWebchatToAgentRequest)
            .then((response: any) => {
            }).catch(err => {
                console.log(err.message);
            });
    }

    return (
        <>
            {(!chatIsTerminated && !transferInitiated) &&
                <Popup
                    trapFocus
                    trigger={<Button
                        icon={<UserFriendsIcon size="large" />}
                        iconOnly
                        text
                        title={intl.formatMessage({ id: 'WebChatTransferView.TransferChat' })}
                        styles={stylesFaded}
                        onClick={() => getTransferTargetsAfterStopTyping("")}
                    />}
                    content={
                        <div style={{ padding: '10px' }}>
                            <Flex gap="gap.smaller">
                                <Dropdown
                                    loading={isTargetListLoading}
                                    loadingMessage={intl.formatMessage({ id: 'WebChatTransferView.Loading' })}
                                    search
                                    clearable
                                    items={targetsTransferList}
                                    onSearchQueryChange={(e, data) => {
                                        getTransferTargetsAfterStopTyping(data.searchQuery);
                                    }}
                                    placeholder={intl.formatMessage({ id: 'WebChatTransferView.FindAgentOrQueue' })}
                                    noResultsMessage={intl.formatMessage({ id: 'WebChatTransferView.NoMatchForSearch' })}
                                    onChange={(e, selectedOption) => {
                                        if (selectedOption) {
                                            currentSelectedTransferTarget.current = (selectedOption.value as any)?.header.toString() || '-1';
                                        }
                                    }}
                                />

                                <Button
                                    circular
                                    icon={<UserFriendsIcon />}
                                    primary
                                    size="medium"
                                    title={intl.formatMessage({ id: 'WebChatTransferView.TransferChat' })}
                                    onClick={initiateTransfer}
                                />
                            </Flex>
                            <br />
                            <TextArea
                                fluid
                                placeholder={intl.formatMessage({ id: 'WebChatTransferView.TransferChatMessage' })}
                                styles={{ height: "100px" }}
                                onChange={(e) => setTransferMessage((e.target as HTMLInputElement).value)}
                            />
                        </div>
                    }
                />
            }
        </>
    );
};

export default WebChatTransferView;

import React, { useState, useRef } from 'react';
import {
    Flex, Text, Checkbox, Button, ChatIcon,
    EmailIcon, CallIcon, AttendeeIcon
} from '@fluentui/react-northstar';
import Lockr from 'lockr';
import {
    StorageKeys, ConferenceStatusAction, MediaType, OperatorCallState, MediaState,
    MonitorTypes, logging, SocialMediaStatusType
} from '../../../../utils';
import { Paginator } from '../../..';
import AdvancedTable from '../../../../components/AdvancedTable';
import { useIntl } from 'react-intl';
import { supervisorManager } from '../../../../services/supervisor/supervisor-manager';
import Timer from '../../../../components/Timer';
import { operatorListManager } from '../../../../services/io/operator-list';
import { WaitingMediaDto } from '../../../../utils/domain/waitingMediaDTO';
import { socketAudio } from '../../../../services/calling';
import { waitingListManager } from '../../../../services/io/waiting-list';
import { socketQueue } from '../../../../services/queue';
import { QueueUserDTO } from '../../../../utils/domain/queueUserDTO';
import { SupervisorMediaFilters } from '../../../../utils/domain/supervisor/SupervisorMediaFilters';
import './SupervisorCallsView.scss'
import { SupervisorMediaDTO } from '../../../../utils/domain/supervisor/SupervisorMediaDTO';
import { CallSessionDTO } from '../../../../utils/domain/callSessionDTO';
import GeneralHelper from '../../../../utils/helpers/general-helper';
import { Subscription } from 'rxjs';
import { socketSocialMedia } from '../../../../services/social-media';
import { SocialMediaSessionDTO } from '../../../../utils/domain/socialMedia/socialMediaSessionDTO';
import { ColumnsResolver } from './ColumnsResolver';
import { webChatManager } from 'services/io/web-chat';
import { EnvHelper } from 'utils/helpers';

export const SupervisorCallsView = () => {
    const intl = useIntl();

    const [activeMediaFilter, setActiveMediaFilter] = useState(false);
    const [waitingMediaFilter, setWaitingMediaFilter] = useState(false);
    const [activeMediaCount, setActiveMediaCount] = useState(0);
    const [waitingMediaCount, setWaitingMediaCount] = useState(0);
    const mediaListRef = useRef<SupervisorMediaDTO[]>([]);
    const [mediaListObjectsMapTriggerKey, setMediaListObjectsMapTriggerKey] = useState<number>();
    const [displayMedia, setDisplayMedia] = useState<SupervisorMediaDTO[]>([]);
    //#warning-js [cc4all-1470]: have better discriminating naming: displayMedia, displayMediaList
    const [displayMediaList, setDisplayMediaList] = useState<SupervisorMediaDTO[]>([]);
    const [assignedQueuesRefs, setAssignedQueuesRefs] = useState<number[]>([]);
    const [isOngoingCall, setIsOngoingCall] = useState(false);
    const [isMonitoring, setIsMonitoring] = useState(false);
    const [isMonitoringAttemptMade, setIsMonitoringAttemptMade] = useState(false);
    const [monitorabledQueueIds, setMonitorableQueueIds] = useState<number[]>([]);
    const initialMediaGetIsDone = useRef(false);
    const activeMediaReceivedCopy = useRef<WaitingMediaDto>();
    const waitingMediaReceivedCopy = useRef<WaitingMediaDto>();
    const currentPage = useRef(1);
    const numberOfPages = useRef(1);
    const paginatorExists = useRef(false);

    let subscriptionSocketQueueChange: Subscription | null = null;
    let subscriptionRefreshClientRole: Subscription | null = null;
    let subscriptionSendChatDetailsToSupervisor: Subscription | null = null;
    let subscriptionCallSessionStateChanged: Subscription | null = null;
    let subscriptionCurrentUserCallSessionStateChanged: Subscription | null = null;
    let subscriptionCurrentUserMonitoredCallSessionChanged: Subscription | null = null;
    let subscriptionActiveMediaAdded: Subscription | null = null;
    let subscriptionActiveMediaUpdated: Subscription | null = null;
    let subscriptionActiveMediaRemoved: Subscription | null = null;
    let subscriptionWaitingMediaAdded: Subscription | null = null;
    let subscriptionWaitingMediaUpdated: Subscription | null = null;
    let subscriptionWaitingMediaRemoved: Subscription | null = null;
    let subscriptionSupervisedQueueIdsChanged: Subscription | null = null;
    let subscriptionSendSocialMediaDetailsToClientChangeNotification: Subscription | null = null;

    const startOperations = [ConferenceStatusAction[ConferenceStatusAction.Accepted], ConferenceStatusAction[ConferenceStatusAction.Ringing]];
    const endOperations = GeneralHelper.getEndOperations();

    const itemsPerPageForPaginator = 10;
    const columns = ColumnsResolver();

    React.useEffect(() => {
        GeneralHelper.logCox(`in SupervisorCallsView.tsx, in useEffect []`);
        initialize();
        mapMediaObjectsForTable();
        return () => {
            componentWillUnmount();
        }
    }, []);

    React.useEffect(() => {
        mapMediaObjectsForTable();
        GeneralHelper.logCox(`in SupervisorCallsView.tsx, in useEffect for stuff, to do mapMediaObjectsForTable`);
    }, [activeMediaFilter, waitingMediaFilter, isOngoingCall, isMonitoring, isMonitoringAttemptMade, socketQueue.queuesArray, mediaListObjectsMapTriggerKey]);

    //to monitor isOngoingCall: React.useEffect(() => { GeneralHelper.logCox(`[CC4ALL-3935] SupervisorCallsView: isOngoingCall is ${isOngoingCall}`); }, [isOngoingCall]);


    const setMediaListRefCurrent = (mediaListValue: SupervisorMediaDTO[]) => {
        //#warning-js CC4ALL-2508, maybe compare the stringified json form of before and after, and skip if the same
        mediaListRef.current = mediaListValue;
        setMediaListObjectsMapTriggerKey(Date.now());
        GeneralHelper.logCox(`in SupervisorCallsView.tsx, in setMediaListRefCurrent to update the mediaListRef.current to ${JSON.stringify(mediaListValue)}`);
    };

    const initialize = () => {
        GeneralHelper.logCox(`in SupervisorCallsView.tsx, in initialize`);

        getQueues();

        setMonitorableQueueIds(supervisorManager.getMonitorableQueueIds());

        subscriptionSocketQueueChange?.unsubscribe();
        subscriptionSocketQueueChange = socketQueue.subjectQueueChange.subscribe((resultQueue: any) => {
            setAssignedQueuesRefs(resultQueue.map((q: QueueUserDTO) => {
                return q.QueueRef;
            }));
        });

        setActiveMediaFilter(getInitialActiveMediaFilter());
        setWaitingMediaFilter(getInitialWaitingMediaFilter());

        if (!initialMediaGetIsDone.current) {
            getMedia(false);
        }

        subscriptionRefreshClientRole?.unsubscribe();
        subscriptionRefreshClientRole = supervisorManager.listenerRefreshClientRole.received.subscribe((obj: any) => {
            getMedia(false);
            supervisorManager.checkIsUserSupervisor();
        });

        subscriptionSendChatDetailsToSupervisor?.unsubscribe();
        if (webChatManager.listenerSendChatDetailsToSupervisor) {
            subscriptionSendChatDetailsToSupervisor = webChatManager.listenerSendChatDetailsToSupervisor.received.subscribe((obj: any) => {
                getMedia(false);
            });
        }

        subscriptionCallSessionStateChanged?.unsubscribe();
        subscriptionCallSessionStateChanged = socketAudio.callSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
            //#warning-js [cc4all-1470]: should actually update the item, instead of refreshing the whole list;
            GeneralHelper.logCox(`in SupervisorCallsView.tsx, in subscriptionCallSessionStateChanged, will call getMedia(false)`);
            getMedia(false);
        });

        subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
        subscriptionCurrentUserCallSessionStateChanged = socketAudio.currentUserCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
            const ongoingCall = (startOperations.includes(callSessionDTO.ConferenceActionAsString)) && callSessionDTO.IsCurrentUserThePrimaryAgent;
            setIsOngoingCall(ongoingCall);
        });

        subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
        subscriptionCurrentUserMonitoredCallSessionChanged = socketAudio.currentUserMonitoredCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
            GeneralHelper.logCox('in SupervisorCallsView.tsx, in subscriptionCurrentUserMonitoredCallSessionChanged, will call getMedia(true)');
            getMedia(true);
        });

        subscriptionActiveMediaAdded?.unsubscribe();
        subscriptionActiveMediaAdded = operatorListManager.onOperatorCallAdded.subscribe((obj: WaitingMediaDto) => {
            handleOperatorAdded(obj);
        })

        subscriptionActiveMediaUpdated?.unsubscribe();
        subscriptionActiveMediaUpdated = operatorListManager.onOperatorCallUpdated.subscribe((obj: WaitingMediaDto) => {
            //#warning-js [cc4all-1470]: should do an update, not a refresh, not a get all media calls...
            getMedia(false);
        });

        subscriptionActiveMediaRemoved?.unsubscribe();
        subscriptionActiveMediaRemoved = operatorListManager.onOperatorCallRemoved.subscribe((obj: WaitingMediaDto) => {
            handleOperatorRemoved(obj);
        });

        subscriptionWaitingMediaAdded?.unsubscribe();
        subscriptionWaitingMediaAdded = waitingListManager.onWaitingAdded.subscribe((obj: WaitingMediaDto) => {
            if (obj !== waitingMediaReceivedCopy.current && initialMediaGetIsDone.current === true) {
                getMedia(false);
            }
            waitingMediaReceivedCopy.current = obj;
        })

        subscriptionWaitingMediaUpdated?.unsubscribe();
        subscriptionWaitingMediaUpdated = waitingListManager.onWaitingUpdated.subscribe((obj: WaitingMediaDto) => {
            const indexToUpdate = getMediaIndexBySessionId(obj.SessionID);
            if (indexToUpdate !== null) {
                obj.OperatorCallState = setMediaCallState(obj.IsAssigned, obj.OperatorCallState);
                const objectToUpdate = mapMediaCallModel(obj);
                const waitingMediaObjectListUpdated = mediaListRef.current.map((item, index) => {
                    return index === indexToUpdate ? objectToUpdate : item
                });
                GeneralHelper.logCox(`in SupervisorCallsView.tsx, in subscriptionWaitingMediaUpdated, will call setMediaListRefCurrent; \
was ${JSON.stringify(mediaListRef.current)}; will be ${JSON.stringify(waitingMediaObjectListUpdated)}`);
                setMediaListRefCurrent(waitingMediaObjectListUpdated);

            }
        });

        subscriptionWaitingMediaRemoved?.unsubscribe();
        subscriptionWaitingMediaRemoved = waitingListManager.onWaitingRemoved.subscribe((obj: WaitingMediaDto) => {
            const indexToDelete = getMediaIndexBySessionId(obj.SessionID);
            if (indexToDelete !== null && !obj.IsAssigned) {
                removeMedia(indexToDelete)
            }
        });

        subscriptionSupervisedQueueIdsChanged?.unsubscribe();
        subscriptionSupervisedQueueIdsChanged = supervisorManager.onMonitorableQueueIdsChanged.subscribe((queueIds: number[]) => {
            setMonitorableQueueIds(queueIds);
        });

        subscriptionSendSocialMediaDetailsToClientChangeNotification?.unsubscribe();
        subscriptionSendSocialMediaDetailsToClientChangeNotification =
            socketSocialMedia.sendSocialMediaDetailsToClientChangeNotification.subscribe((obj: SocialMediaSessionDTO) => {
                const opCallsFilterBySessionId = mediaListRef.current.filter(element => element.SessionID === obj.SessionId);

                const existingObject: boolean = opCallsFilterBySessionId && opCallsFilterBySessionId.length > 0;
                const waitingObj = mapSocialMediaToWaitingMedia(obj);

                if (obj.StatusType === SocialMediaStatusType.Terminated) {
                    handleOperatorRemoved(waitingObj);
                    return;
                }

                if (existingObject) {
                    getMedia(false);
                } else {
                    handleOperatorAdded(waitingObj);
                }
            });
    }

    const componentWillUnmount = () => {
        GeneralHelper.logCox(`in SupervisorCallsView.tsx, in componentWillUnmount`);

        subscriptionSocketQueueChange?.unsubscribe();
        subscriptionRefreshClientRole?.unsubscribe();
        subscriptionSendChatDetailsToSupervisor?.unsubscribe();
        subscriptionCallSessionStateChanged?.unsubscribe();
        subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
        subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
        subscriptionActiveMediaAdded?.unsubscribe();
        subscriptionActiveMediaUpdated?.unsubscribe();
        subscriptionActiveMediaRemoved?.unsubscribe();
        subscriptionWaitingMediaAdded?.unsubscribe();
        subscriptionWaitingMediaUpdated?.unsubscribe();
        subscriptionWaitingMediaRemoved?.unsubscribe();
        subscriptionSupervisedQueueIdsChanged?.unsubscribe();
        subscriptionSendSocialMediaDetailsToClientChangeNotification?.unsubscribe();
    };

    const getQueues = () => {
        if (socketQueue.queuesArray && socketQueue.queuesArray.length) {
            setAssignedQueuesRefs(socketQueue.queuesArray.map((q: QueueUserDTO) => {
                return q.QueueRef;
            }));
        }
    }

    const assessWhetherIsSupervising = () => {
        const supervisedByMeCalls = mediaListRef.current.filter(m => m.MonitorType !== MonitorTypes.None && m.MonitorType !== MonitorTypes.BySomeoneElse);
        const hasSupervisedCall = supervisedByMeCalls.length > 0;
        setIsMonitoring(hasSupervisedCall);
    }

    const checkForOngoingCall = () => {
        let ongoingCall = false;

        const callSession = socketAudio.getCallSessionForCurrentUser();
        const isParkCall = GeneralHelper.isCallParkedOrFallback(callSession.ActionHistory, ConferenceStatusAction.OperatorPark);
        const isFallbackCall = GeneralHelper.isCallParkedOrFallback(callSession.ActionHistory, ConferenceStatusAction.Fallback);

        if (!isParkCall && !isFallbackCall && callSession.ConversationKey && !endOperations.includes(callSession.ConferenceActionAsString)) {
            ongoingCall = true;
            //#warning-js [cc4all-1102]: have unified isOngoingCall across the views;
            //do not set it from 2 functions (the other one is the listenerCurrentUserCallSessionStateChanged subscription)
        }

        setIsOngoingCall(ongoingCall);
    }

    const setMediaCallState = (isMediaAssigned: boolean = false, state?: OperatorCallState) => {
        if (state === undefined) {
            return OperatorCallState.None;
        }
        if (state !== OperatorCallState.None) {
            return state;
        }
        return isMediaAssigned ? OperatorCallState.Offering : OperatorCallState.UnAssigned;
    }

    const removeMedia = (indexToDelete: number) => {
        const mediaObjectListUpdated = mediaListRef.current.filter((item, index) => indexToDelete !== index);
        GeneralHelper.logCox(`in SupervisorCallsView.tsx, in removeMedia, will call setMediaListRefCurrent; was ${JSON.stringify(mediaListRef.current)}; \
will be ${JSON.stringify(mediaObjectListUpdated)}`);
        setMediaListRefCurrent(mediaObjectListUpdated);
    }

    const getMedia = (triggeredBySupervisedCallSessionChanged: boolean) => {
        GeneralHelper.logCox('in SupervisorCallsView.tsx, doing getMediaBySupervisor in getMedia');
        supervisorManager.getMediaBySupervisor(Lockr.get<number>(StorageKeys.UserId), triggeredBySupervisedCallSessionChanged).then((response: WaitingMediaDto[]) => {
            if (response) {
                const mediaObjects = [] as SupervisorMediaDTO[];
                response.forEach((element: WaitingMediaDto) => {
                    element.OperatorCallState = setMediaCallState(element.IsAssigned, element.OperatorCallState);
                    mediaObjects.push(mapMediaCallModel(element));
                });
                GeneralHelper.logCox(`in SupervisorCallsView.tsx, after getMediaBySupervisor, \
triggeredBySupervisedCallSessionChanged is ${triggeredBySupervisedCallSessionChanged}, will call setMediaListRefCurrent; was ${JSON.stringify(mediaListRef.current)}; \
will be ${JSON.stringify(mediaObjects)}`);
                setMediaListRefCurrent(mediaObjects);
                setIsMonitoringAttemptMade(false);
            }

            initialMediaGetIsDone.current = true;
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const getMediaIndexBySessionId = (sessionID?: string) => {
        const searchedObject = mediaListRef.current.filter(element => element.SessionID === sessionID)[0];
        return searchedObject ? mediaListRef.current.indexOf(searchedObject) : null;
    };

    const mapMediaCallModel = (obj: WaitingMediaDto) => {
        const monitorType = obj.MonitorType === MonitorTypes.None ? MonitorTypes.None : MonitorTypes.BySomeoneElse;
        return {
            SessionID: obj.SessionID,
            ConversationKey: obj.ConversationKey,
            ConferenceUri: obj.ConferenceUri,
            OperatorCallState: obj.OperatorCallState,
            OperatorSIP: obj.OperatorSip,
            Agent: obj.OperatorCallState === OperatorCallState.QueueColdTransferToQueue ? "" : obj.TargetAgentName || obj.OperatorName,
            Media: obj.MediaType?.toString(),
            StartDate: obj.StartDate,
            Source: obj.SourceDisplayName === "" || obj.SourceDisplayName === undefined || obj.SourceDisplayName === null ?
                obj.Source?.replace("sip:", "") : obj.SourceDisplayName,
            Queue: obj.QueueName,
            TrustedEndPoint: obj.TrustedEndPoint,
            QueueRef: obj.QueueRef,
            MediaType: obj.MediaType,
            Skill: obj.SkillName,
            Status: getStatus(obj.OperatorCallState),
            IsCallPickupEnabled: obj.IsCallPickupEnabled,
            isActive: isCallActive(obj.OperatorCallState, obj.TargetAgentName, obj.OperatorSip) ? true : false,
            MonitorType: (obj.ActiveSupervisorSIP?.toLowerCase() === (Lockr.get<string>(StorageKeys.SIP)).toLowerCase()) ? obj.MonitorType : monitorType,
            AreSupervisorCTAsDisabled: obj.AreSupervisorCTAsDisabled
        } as SupervisorMediaDTO;
    }

    const isCallActive = (status: OperatorCallState, target: string | undefined = undefined, operator: string | undefined = undefined) => {
        return ((target || operator) && getStatus(status) !== MediaState.Waiting && getStatus(status) !== MediaState.Offering);
    }

    const getStatus = (status: OperatorCallState) => {
        switch (status) {
            case OperatorCallState.None:
            case OperatorCallState.UnAssigned:
            case OperatorCallState.BeforeAssignedToOperator:
                return MediaState.Waiting;
            case OperatorCallState.AssignedToOperator:
            case OperatorCallState.OperatorInterrupt:
                return MediaState.Assigned;
            case OperatorCallState.AssignedToOperatorInWarmTransfer:
            case OperatorCallState.QueueColdTransferToQueue:
                return MediaState.Transferring;
            case OperatorCallState.Parked:
                return MediaState.Parked;
            case OperatorCallState.AssignedToAgentInCall:
                return MediaState.AgentHandling;
            case OperatorCallState.AssignedToAgentRinging:
                return MediaState.AgentRinging;
            case OperatorCallState.AssignedToAgentInPersonalQueue:
                return MediaState.AgentWaiting;
            case OperatorCallState.FallBack:
                return MediaState.Fallback;
            case OperatorCallState.OnHold:
                return MediaState.OnHold;
            case OperatorCallState.Offering:
                return MediaState.Offering;
            default:
                return "";
        }
    }

    const resposiveColumnClassName = 'big-grow table-column';

    const mapItem = (obj: SupervisorMediaDTO) => {
        return {
            key: obj.SessionID,
            queueid: obj.QueueRef,
            items: [
                {
                    content: getMediaTypeIcon(obj.Status, obj.MediaType, obj.SessionID), key: obj.SessionID?.toString() + '-0',
                    className: GeneralHelper.getMediaTypeContainerClassName(obj.MediaType)
                },
                { content: GeneralHelper.formatSource(obj.Source), key: obj.SessionID?.toString() + '-1', className: "caller-name-column" },
                { content: <Timer startDate={obj.StartDate}></Timer>, key: obj.SessionID?.toString() + '-2', className: resposiveColumnClassName },
                { content: obj.Queue, key: obj.SessionID?.toString() + '-3', className: resposiveColumnClassName },
                { content: obj.Skill ? obj.Skill : "", key: obj.SessionID?.toString() + '-4', className: resposiveColumnClassName },
                { content: obj.Status ? obj.Status : "", key: obj.SessionID?.toString() + '-5', className: resposiveColumnClassName },
                { content: obj.Agent ? obj.Agent : "", key: obj.SessionID?.toString() + '-6', className: "biggest-grow table-column" },
                { content: getActionButtons(obj), key: obj.SessionID?.toString() + '-7', className: "actions-column" }
            ]
        }
    }

    const getActionButtons = (obj: SupervisorMediaDTO) => {
        const buttons = []

        const pickupButton = getPickUpButton(obj.QueueRef, obj.SessionID, obj.Status, obj.IsCallPickupEnabled);
        buttons.push(pickupButton);

        const listenButton = getListenButton(obj);
        buttons.push(listenButton);

        const bargeInButton = getBargeInButton(obj);
        buttons.push(bargeInButton);

        return <Flex vAlign="center" hAlign="end">
            {buttons}
        </Flex>;
    }

    const getBargeInButton = (obj: SupervisorMediaDTO) => {
        if (isOngoingCall || isMonitoring || isMonitoringAttemptMade || !obj.isActive || obj.MonitorType !== MonitorTypes.None ||
            monitorabledQueueIds.indexOf(obj.QueueRef) === -1 || obj.MediaType !== MediaType.Call || obj.AreSupervisorCTAsDisabled) {

            GeneralHelper.logCox(`returning without a bargeIn CTA; isOngoingCall is ${isOngoingCall}, 
            isSupervising is ${isMonitoring}, 
            isSupervisingAttemptMade is ${isMonitoringAttemptMade}, 
            isActive is ${obj.isActive}, 
            MonitorType is ${obj.MonitorType} `);

            return <></>;
        }
        return <Button key={`barge-in-${obj.SessionID}`} text iconOnly icon={<AttendeeIcon size="medium" />} size="medium" primary={true}
            title={intl.formatMessage({ id: "SupervisorCallsView.BargeIn" })}
            onClick={() => monitoringActionButtonOnClick(obj, MonitorTypes.BargeIn)} />
    }

    const getListenButton = (obj: SupervisorMediaDTO) => {
        if (isOngoingCall || isMonitoring || isMonitoringAttemptMade || !obj.isActive || obj.MonitorType !== MonitorTypes.None ||
            monitorabledQueueIds.indexOf(obj.QueueRef) === -1 || obj.MediaType !== MediaType.Call || obj.AreSupervisorCTAsDisabled) {
            return <></>;
        }
        return <Button key={`listen-${obj.SessionID}`} text iconOnly icon={<img alt='Listen' className='ear-icon' />} size="medium" primary={true}
            title={intl.formatMessage({ id: "SupervisorCallsView.Listen" })}
            onClick={() => monitoringActionButtonOnClick(obj, MonitorTypes.Silent)} />
    }

    const monitoringActionButtonOnClick = (obj: SupervisorMediaDTO, monitorType: MonitorTypes) => {
        setIsMonitoringAttemptMade(true);
        GeneralHelper.logCox(`setting setIsSupervisingAttemptMade in monitoringActionButtonOnClick`);
        const startMonitoringRequestDTO = {
            SessionID: obj.SessionID,
            AgentSip: Lockr.get(StorageKeys.SIP),
            MonitorType: monitorType
        }
        socketAudio.startSupervisorMonitoring(startMonitoringRequestDTO).then(function (result: string | null) {
            if (result?.startsWith("ErrorMessage")) {
                //ErrorMessage.SupervisingMonitoring.CallAlreadyMonitored
                logging.errorHandler.next(result);
                setIsMonitoringAttemptMade(false);
            }
        }).catch(function (e: any) {
            //#warning-js [cc4all-1102]: maybe show a notification with the error ...
            logging.errorHandler.next("ErrorMessage.SupervisingMonitoring.UnexpectedErrorOccurred");
            setIsMonitoringAttemptMade(false);
        });
    }

    const getPickUpButton = (queueRef: number, sessionId: string, status?: string, isCallPickupEnabled: boolean = false) => {
        if (isCallPickupEnabled && !isMonitoring && !isOngoingCall && status === MediaState.Waiting && assignedQueuesRefs.includes(queueRef)) {
            return <Button key={`pickup-${sessionId}`} circular icon={<CallIcon size="small" />} size="small" className="pickup-button" primary
                title={intl.formatMessage({ id: "WaitingCallsView.CallPickup" })}
                onClick={() => { pickUpButtonOnClick(sessionId) }} />
        }
        return "";
    }

    const pickUpButtonOnClick = (sessionId: string) => {
        setIsOngoingCall(true);
        //#warning-js [cc4all-1102]: not doing anything, if adding in useEffect,
        //then mapMediaObjectsForTable will call checkForOngoingCall which will actually set the flag
        waitingListManager.PickUpCall({ agentRef: Lockr.get<number>(StorageKeys.UserId), sessionId: sessionId });
    }

    const getMediaTypeIcon = (status?: string, mediaType?: number, sessionId?: string | undefined) => {
        switch (mediaType) {
            case MediaType.Call:
                if (status !== MediaState.Waiting && status !== MediaState.Offering && status !== MediaState.Fallback && status !== MediaState.Parked) {
                    return <CallIcon className="active-call-icon" title={sessionId ?? ""} />
                } else {
                    return <CallIcon title={sessionId ?? ""} />
                }
            case MediaType.Chat:
                return <ChatIcon className="call-type-icon-color" title={sessionId ?? ""}></ChatIcon>
            case MediaType.Mail:
                return <EmailIcon className="call-type-icon-color" title={sessionId ?? ""}></EmailIcon>
            case MediaType.SocialMedia:
                return <img alt='Media Icon' width="15" className="call-type-icon-color social-media-icon" title={sessionId ?? ""} />
            default:
                return <></>
        }
    }

    const mapMediaObjectsForTable = () => {
        GeneralHelper.logCox(`in SupervisorCallsView.tsx, in mapMediaObjectsForTable`);
        //#warning-js [cc4all-1102]: rename, it's not only mapping objects, it's also resetting the isOngoingCall flag
        if (!initialMediaGetIsDone.current) {
            return;
        }

        checkForOngoingCall();
        assessWhetherIsSupervising();

        let objectsForTable: any = [];

        if (waitingMediaFilter) {
            mediaListRef.current.forEach((element: SupervisorMediaDTO) => {
                if (!element.isActive) {
                    objectsForTable.push(mapItem(element));
                }
            });
        }

        if (activeMediaFilter) {
            mediaListRef.current.forEach((element: SupervisorMediaDTO) => {
                if (element.isActive) {
                    objectsForTable.push(mapItem(element));
                }
            });
        }

        objectsForTable = objectsForTable.sort(orderArray);

        setActiveMediaCount(mediaListRef.current.filter((obj) => obj.isActive === true).length);
        setWaitingMediaCount(mediaListRef.current.filter((obj) => obj.isActive === false).length);

        setDisplayMedia(objectsForTable);
        GeneralHelper.logCox(`in SupervisorCallsView.tsx, called setDisplayMedia with ${JSON.stringify(objectsForTable)}}`);
        initializePaginator(objectsForTable);
    }

    const orderArray = (a: SupervisorMediaDTO, b: SupervisorMediaDTO) => {
        if (a.isActive === true && b.isActive === false) {
            return 1;
        }
        if (a.isActive === false && b.isActive === true) {
            return -1;
        }
        return 0;
    }

    const initializePaginator = (obj: any) => {
        paginatorExists.current = obj.length > itemsPerPageForPaginator;
        initializeNumberOfPages(obj.length);
        if (numberOfPages.current < currentPage.current) {
            currentPage.current = numberOfPages.current;
        }
        setDisplayMediaList(obj.slice((currentPage.current - 1) * itemsPerPageForPaginator, currentPage.current * itemsPerPageForPaginator));
    }

    const initializeNumberOfPages = (listLength: number) => {
        let pages = 0;
        if (listLength % itemsPerPageForPaginator === 0) {
            pages = Math.floor(listLength / itemsPerPageForPaginator);
        } else {
            pages = Math.floor(listLength / itemsPerPageForPaginator) + 1;
        }
        numberOfPages.current = pages === 0 ? 1 : pages;
    }

    const selectPage = (e: any) => {
        setDisplayMediaList(displayMedia.slice((e - 1) * itemsPerPageForPaginator, e * itemsPerPageForPaginator))
        currentPage.current = e;
    }

    let paginator: any = null;
    if (paginatorExists.current) {
        paginator = <Paginator
            currentPage={currentPage.current}
            totalPages={numberOfPages.current}
            onChange={selectPage}
        />
    }

    const getActiveMediaCount = () => {
        return activeMediaCount <= 0 ? "" : ` (${activeMediaCount.toString()})`;
    }

    const getWaitingMediaCount = () => {
        return waitingMediaCount <= 0 ? "" : ` (${waitingMediaCount.toString()})`;
    }

    const getMediaFiltersFromLocalStorage = () => {
        return (Lockr.get<SupervisorMediaFilters>(StorageKeys.SupervisorCallsFilters));
    }

    const modifyActiveMediaFilter = () => {
        setActiveMediaFilter(prevState => !prevState);

        const filtersFromLocalStorage = getMediaFiltersFromLocalStorage() || {} as SupervisorMediaFilters;
        filtersFromLocalStorage.ActiveMedia = !activeMediaFilter
        Lockr.set(StorageKeys.SupervisorCallsFilters, filtersFromLocalStorage);
    }

    const modifyWaitingMediaFilter = () => {
        setWaitingMediaFilter(prevState => !prevState);

        const filtersFromLocalStorage = getMediaFiltersFromLocalStorage() || {} as SupervisorMediaFilters;
        filtersFromLocalStorage.WaitingMedia = !waitingMediaFilter
        Lockr.set(StorageKeys.SupervisorCallsFilters, filtersFromLocalStorage);
    }

    const getInitialActiveMediaFilter = () => {
        const initialActiveMediaFilterValue = getMediaFiltersFromLocalStorage()?.ActiveMedia;
        return initialActiveMediaFilterValue === undefined ? true : initialActiveMediaFilterValue;
    }

    const getInitialWaitingMediaFilter = () => {
        const initialWaitingFilterValue = getMediaFiltersFromLocalStorage()?.WaitingMedia;
        return initialWaitingFilterValue === undefined ? true : initialWaitingFilterValue;
    }

    const mapSocialMediaStatusToOperatorCallState = (status: SocialMediaStatusType) => {
        switch (status) {
            case SocialMediaStatusType.Incoming:
                return OperatorCallState.UnAssigned;
            case SocialMediaStatusType.Ringing:
                return OperatorCallState.Offering;
            case SocialMediaStatusType.Accepted:
                return OperatorCallState.AssignedToOperator;
            case SocialMediaStatusType.Waiting:
                return OperatorCallState.UnAssigned;
            default:
                return OperatorCallState.None;
        }
    }

    const handleOperatorAdded = (obj: WaitingMediaDto) => {
        if (obj !== activeMediaReceivedCopy.current && initialMediaGetIsDone.current === true) {
            getMedia(false);
        }
        activeMediaReceivedCopy.current = obj;
    }

    const handleOperatorRemoved = (obj: WaitingMediaDto) => {
        const indexToDelete = getMediaIndexBySessionId(obj.SessionID);
        if (indexToDelete !== null && (obj.TargetAgentName || obj.OperatorSip ||
            obj.OperatorCallState === OperatorCallState.FallBack || obj.OperatorCallState === OperatorCallState.Parked)) {
            removeMedia(indexToDelete);
        }
    }

    const mapSocialMediaToWaitingMedia = (socialMedia: SocialMediaSessionDTO, queueName?: string) => {
        return {
            SessionID: socialMedia.SessionId,
            MediaType: MediaType.SocialMedia,
            SourceDisplayName: "Media",
            StartDate: socialMedia.StartDate + '',
            QueueRef: socialMedia.QueueId,
            IsAssigned: true,
            OperatorCallState: mapSocialMediaStatusToOperatorCallState(socialMedia.StatusType),
            TargetAgentName: socialMedia.AgentSip,
            TargetAgentSip: socialMedia.AgentSip,
            QueueName: queueName,
            MonitorType: MonitorTypes.None,
            IsCurrentUserMonitoring: false
        } as WaitingMediaDto;
    }

    return (
        <Flex column>
            <Flex gap="gap.medium">
                <Text content={intl.formatMessage({ id: "SupervisorCallsView.Overview" })} weight="bold" />
                {!EnvHelper.isStage3() && <Checkbox label={intl.formatMessage({ id: "SupervisorCallsView.ActiveMedia" }) + getActiveMediaCount()}
                    checked={activeMediaFilter} onClick={modifyActiveMediaFilter} className="filter-checkbox" />}
                <Checkbox label={intl.formatMessage({ id: "SupervisorCallsView.WaitingMedia" }) + getWaitingMediaCount()}
                    checked={waitingMediaFilter} onClick={modifyWaitingMediaFilter} className="filter-checkbox" />
            </Flex>

            <AdvancedTable columns={columns} rows={displayMediaList} label="Waiting Calls" className="waiting-list-table" />
            {paginator}
        </Flex>
    )
};

export default SupervisorCallsView;

import React, { useState } from 'react';
import { Button, Flex, Segment } from '@fluentui/react-northstar';
import './style.scss';
import { ConnectionListView } from './ConnectionListView/ConnectionListView';
import TabsView from './TabsView/TabsView';

export const GenericView = () => {
    const [isLeftPanelActive, setIsLeftPanelActive] = useState<boolean>(true);

    return (
    <Flex className="generic-container">
        <div className={`chat-list-container app-left-panel ${isLeftPanelActive ? 'active' : ''}`}>
            <div className="navigation-container column">
                <Flex column
                    styles={({ theme: { siteVariables } }) => ({
                        color: siteVariables.colorScheme.default.foreground,
                        backgroundColor: siteVariables.colorScheme.default.background,
                    })}
                >
                    <Segment key="chat-list-segment" className="chat-list-segment">
                        <ConnectionListView />
                    </Segment>
                </Flex>
            </div>
        </div>
        <div className="left-panel-trigger-buttons">
            <Button circular primary className={`show-hide-left-panel-btn ${isLeftPanelActive ? 'active' : ''}`}
                title={isLeftPanelActive ? "Hide" : "Show"} size="medium"
                content={<i className="arrow"></i>}
                onClick={() => setIsLeftPanelActive(prev => !prev)}
            />
        </div>
        <div className="calls-container">
            <Flex.Item>
                <Flex column className="media-container"
                    styles={({ theme: { siteVariables } }) => ({
                        backgroundColor: siteVariables.colorScheme.default.background2,
                        color: siteVariables.colorScheme.default.foreground2,
                    })}
                >
                    <TabsView />
                </Flex>
            </Flex.Item>
        </div>
    </Flex>
    )
};

export default GenericView;

export default class Env {
    public static isDemo(): boolean {
        const queryString = new URLSearchParams(window.location.search);
        return queryString.get('demo') === "true";
    }

    public static isStage3(): boolean {
        const queryString = new URLSearchParams(window.location.search);
        return queryString.get('stage3') === "true";
    }
}

import * as React from 'react';
import { Avatar, Popup, Button } from '@fluentui/react-northstar';
import { useIntl } from 'react-intl';
import './agentAvatar.scss'
import ProfilePictureView from 'containers/MediaView/AgentView/ProfilePictureView';
import { GeneralHelper } from 'utils/helpers';
import { socketGraphApi } from 'services/graphApi';

const AgentAvatarPopup = (props: any) => {
    const intl = useIntl();

    const { name, status, azureID, contactSourceType } = props;
    const [agentStatus, setAgentStatus] = React.useState(status);
    const popupMouseLeaveDelay = 50;

    const getGraphApiStatus = (azureId: string) => {
        const promise = socketGraphApi.getUserPresence(azureId);

        if (!promise) {
            return;
        }

        promise.then((response: any) => {
            if (response?.availability) {
                setAgentStatus(response?.availability);
            }
        });
    }

    const getPopupContent = () => {
        getGraphApiStatus(azureID);

        return <ProfilePictureView
            azureID={azureID}
            status={GeneralHelper.getStatus(agentStatus, intl)}
            getAvatarInitials={() => GeneralHelper.getInitials(name)}
            statusText={GeneralHelper.getStatusText(agentStatus, intl)}
            displayName={name}
            contactSourceType={contactSourceType}
        />;
    }

    return (<Popup
        mouseLeaveDelay={popupMouseLeaveDelay}
        trigger={
            <Button text iconOnly
                content={
                    <Avatar
                        name={GeneralHelper.getStatusText(status, intl)}
                        getInitials={() => GeneralHelper.getInitials(name)}
                        status={GeneralHelper.getStatus(status, intl)}
                    />
                }
            />
        }
        renderContent={getPopupContent}
        on="hover"
    />);
};

export default AgentAvatarPopup;

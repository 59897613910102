import React, { useState, useRef } from 'react';
import { Flex, Text } from '@fluentui/react-northstar';
import Lockr from 'lockr';

import { socketAudio } from 'services/calling';
import { StorageKeys, ConferenceStatusAction, MonitorTypes, ActiveMainViewItem, HeaderBarDisplayedMessageType, logging } from 'utils';
import { externalActions } from 'services';
import { GeneralHelper, ValidationHelper, EnvHelper } from 'utils/helpers';
import { CallSessionDTO } from 'utils/domain/callSessionDTO';
import { Subscription } from 'rxjs';
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import './callDetails.scss'

export const CallDetails = () => {
  const callerUri = useRef('');
  const [variable1, setVariable1] = useState('');
  const [variable2, setVariable2] = useState('');
  const [variable3, setVariable3] = useState('');
  const [variablesCleared, setVariablesCleared] = useState(false);
  const [supervisedAgent, setSupervisedAgent] = useState('');
  const [isOngoingCallSelected, setIsOngoingCallSelected] = useState(true);

  let subscriptionCurrentUserCallSessionStateChanged: Subscription | null = null;
  let subscriptionWarmTransferInProgress: Subscription | null = null;
  let subscriptionCurrentUserMonitoredCallSessionChanged: Subscription | null = null;
  let subscriptionCallSessionChanged: Subscription | null = null;
  let subscriptionViewChanged: Subscription | null = null;
  let subscriptionHeaderBarMessageChanged: Subscription | null = null;

  const hasVariables = useRef(false);
  const currentSessionId = useRef('');
  const endOperations = EnvHelper.isStage3() ? GeneralHelper.getStage3EndOperations() : GeneralHelper.getEndOperations();

  const currentUserId = Lockr.get<string>(StorageKeys.UserId);

  const MapCallerInfo = (callSessionDTO: CallSessionDTO, isMonitoring: boolean = false) => {
    if (!callSessionDTO.SessionId || callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.CallerClosedAfterAnswer]) {
      return;
    }
    setVariablesCleared(false);
    GeneralHelper.logCox(`in CallDetails.tsx, in MapCallerInfo, setVariablesCleared false`);

    if (EnvHelper.isStage3()) {
      setVariable1(formatVariables(callSessionDTO.CallerName));
      setVariable2(formatVariables(callSessionDTO.CallerSIP));
      setVariable3(callSessionDTO.QueueName);

      if (isMonitoring) {
        setSupervisedAgent(callSessionDTO.PrimaryAgentName);
      }
    } else {
      const userSip = Lockr.get<number>(StorageKeys.SIP);
      socketAudio.getClientVariableFields({
        QueueRef: callSessionDTO.QueueRef,
        CallerUri: callSessionDTO.CallerUri,
        SessionId: callSessionDTO.SessionId,
        CallerSIP: callSessionDTO.CallerSIP,
        CallerName: callSessionDTO.CallerName,
        TrustedEndPoint: callSessionDTO.TrustedEndPoint,
        LineUri: callSessionDTO.LineUri,
        QueueName: callSessionDTO.QueueName,
        Skill: callSessionDTO.Skill ?? "",
        AgentSip: userSip
      }).then((result: any) => {
        GeneralHelper.logCox(`in CallDetails.tsx, returning from getClientVariableFields... even if a cleared occured beforehands; variablesCleared is ${variablesCleared}`);
        if (variablesCleared) {
          return;
        }
        setVariable1(formatVariables(result.VariableField1));
        setVariable2(formatVariables(result.VariableField2));
        setVariable3(formatVariables(result.VariableField3));

        if (isMonitoring) {
          setSupervisedAgent(callSessionDTO.PrimaryAgentName);
        }
      });
    }
    hasVariables.current = true;
  }

  const formatVariables = (variable: any) => {
    const sip = "sip:";

    if (variable && variable.startsWith(sip)) {
      variable = variable.replace(sip, '');
    }

    if (ValidationHelper.isSipOrPhoneNumberInSipFormat(variable)) {
      const username = variable.substring(0, variable.lastIndexOf("@"));

      if (ValidationHelper.isValidPhoneNumber(username)) {
        variable = username;
      }
    }

    return variable;
  }

  const ClearCallerInfo = (obj: any) => {
    setVariablesCleared(true);
    GeneralHelper.logCox(`in CallDetails.tsx, in ClearCallerInfo, setVariablesCleared true`);
    hasVariables.current = false;
    setVariable1('');
    setVariable2('');
    setVariable3('');
    setSupervisedAgent('');
    callerUri.current = '';
    currentSessionId.current = '';
  };

  React.useEffect(() => {
    initialize();
    return () => componentWillUnmount();
  }, []);

  const componentWillUnmount = () => {
    subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
    subscriptionWarmTransferInProgress?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
    subscriptionCallSessionChanged?.unsubscribe();
    subscriptionViewChanged?.unsubscribe();
    subscriptionHeaderBarMessageChanged?.unsubscribe();
  }

  const initialize = () => {
    GeneralHelper.logCox(`in CallDetails.tsx, in initialize`);

    externalActions.initialize();
    setIsOngoingCallSelected(Lockr.get<ActiveMainViewItem>(StorageKeys.MainViewTab) === ActiveMainViewItem.MediaView);
    subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
    subscriptionCurrentUserCallSessionStateChanged = socketAudio.currentUserCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      const isParkCall = GeneralHelper.isCallParkedOrFallback(callSessionDTO.ActionHistory, ConferenceStatusAction.OperatorPark);
      const isFallbackCall = GeneralHelper.isCallParkedOrFallback(callSessionDTO.ActionHistory, ConferenceStatusAction.Fallback);
      GeneralHelper.logCox(`in CallDetails, in current call session changed, isParkCall is ${isParkCall}`);

      if (EnvHelper.isStage3() && endOperations.includes(callSessionDTO.ConferenceActionAsString) &&
        (callSessionDTO.AgentBeforeTransferRef === currentUserId || callSessionDTO.AgentBeforeTransferRef === null)) {
        ClearCallerInfo(callSessionDTO);
        return;
      }

      if (!callSessionDTO.ConferenceActionAsString ||
        (callSessionDTO.IsCurrentUserThePrimaryAgent && (isParkCall || isFallbackCall))) {
        if (callSessionDTO && callSessionDTO.SessionId && currentSessionId.current && callSessionDTO.SessionId !== currentSessionId.current) {
          //when operator is in a call and a parked call is ended by caller, ignore the end call event from the parked call session
          return;
        }

        GeneralHelper.logCox(`in CallDetails.tsx, clearing variables in currentUserCallSessionChanged`);
        ClearCallerInfo(callSessionDTO);
      } else {
        let shouldClearCallerInfo = callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.CallerClosedBeforeAnswer] ||
          callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.CallerClosedAfterAnswer];

        if (EnvHelper.isStage3()) {
          // only check it's same session for Stage3
          shouldClearCallerInfo = shouldClearCallerInfo && currentSessionId.current === callSessionDTO.SessionId;
        }

        if (shouldClearCallerInfo) {
          ClearCallerInfo(callSessionDTO);
          return;
        }

        if (EnvHelper.isStage3() && callSessionDTO && callSessionDTO.SessionId && currentSessionId.current
          && callSessionDTO.SessionId !== currentSessionId.current
          && callSessionDTO.ConferenceAction !== ConferenceStatusAction.Offering) {
          // when notification for a new session is received don't ignore it if ringing, only on Stage3
          return;
        }

        GeneralHelper.logCox(`in CallDetails.tsx, setting variables in currentUserCallSessionChanged`);
        callerUri.current = callSessionDTO.CallerUri;
        if (hasVariables.current === false || EnvHelper.isStage3()) {
          currentSessionId.current = callSessionDTO.SessionId ? callSessionDTO.SessionId : '';

          if (callSessionDTO.IsOutboundCall) {
            const callerName = callSessionDTO.CallerName ? callSessionDTO.CallerName : callSessionDTO.CallerId;
            setVariablesCleared(false);

            setVariable1(formatVariables(callerName));
            setVariable2(callSessionDTO.InternalMediaIdentificationName || '');
          }
          else {
            MapCallerInfo(callSessionDTO);
          }

          if (callSessionDTO.ActionHistory && callSessionDTO.ActionHistory?.includes(ConferenceStatusAction.Accepted) &&
            callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] !== ConferenceStatusAction.AgentColdTransfered &&
            callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] !== ConferenceStatusAction.OperatorIVRColdTransferred) {
            externalActions.process(callSessionDTO);
          }
        }
      }
    });

    subscriptionWarmTransferInProgress?.unsubscribe();
    subscriptionWarmTransferInProgress = socketAudio.warmTransferInProgress.subscribe((callSessionDTO: CallSessionDTO) => {
      const sip = Lockr.get<string>(StorageKeys.SIP);

      if (callSessionDTO.TransferTargetUri && callSessionDTO.TransferTargetUri === sip &&
        (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmCanceled] ||
          callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent] ||
          callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmInviteRejected] ||
          callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.CallerClosedAfterAnswer] ||
          callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Terminated])) {
        ClearCallerInfo(callSessionDTO);
      } else {
        callerUri.current = callSessionDTO.CallerUri;

        if (hasVariables.current === false || EnvHelper.isStage3()) {
          currentSessionId.current = callSessionDTO.SessionId ? callSessionDTO.SessionId : '';
          setVariablesCleared(false);
          setVariable1(formatVariables(callSessionDTO.PrimaryAgentName));
          setVariable2(callSessionDTO.QueueName);
        }
      }

      if (GeneralHelper.callerHasLeft(callSessionDTO.ActionHistoryAsString, callSessionDTO.ConferenceActionAsString, sip === callSessionDTO.PrimaryAgentSIP)) {
        logging.errorHandler.next("ErrorMessage.Red.CallerLeft");
      }
    });

    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged = socketAudio.currentUserMonitoredCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      if (callSessionDTO.MonitorType === MonitorTypes.None || callSessionDTO.IsComputedAsTerminated) {
        GeneralHelper.logCox(`in CallDetails.tsx, clearing variables in currentUserMonitoredCallSessionChanged`);
        ClearCallerInfo(callSessionDTO);
      } else {
        GeneralHelper.logCox(`in CallDetails.tsx, setting variables in currentUserMonitoredCallSessionChanged, 
        ConferenceActionAsString is ${callSessionDTO.ConferenceActionAsString}`);

        callerUri.current = callSessionDTO.CallerUri;
        if (hasVariables.current === false || EnvHelper.isStage3()) {
          currentSessionId.current = callSessionDTO.SessionId ? callSessionDTO.SessionId : '';
          MapCallerInfo(callSessionDTO, true);
        }
      }
    });

    subscriptionCallSessionChanged?.unsubscribe();
    subscriptionCallSessionChanged = socketAudio.callSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      if (EnvHelper.isStage3() &&
        (endOperations.includes(callSessionDTO.ConferenceActionAsString) ||
          (
            (
              (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransfered] ||
                callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransferred]) &&
              (callSessionDTO.AgentBeforeTransferRef === "" || callSessionDTO.AgentBeforeTransferRef === null)
            ) ||
            callSessionDTO.AgentBeforeTransferRef === currentUserId ||
            (
              callSessionDTO.AgentRef !== currentUserId &&
              callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmInviteRejected]
            )
          )
        ) &&
        (currentSessionId.current === callSessionDTO.SessionId || callSessionDTO.IsOutboundCall) &&
        !callSessionDTO.IsCurrentUserMonitoring) {
        ClearCallerInfo(callSessionDTO);
      }
      const userObjectId = Lockr.get<string>(StorageKeys.UserObjectId);

      const isSameCallSession = currentSessionId.current === callSessionDTO.SessionId;
      if (EnvHelper.isStage3() && !callSessionDTO.IsWarmTransferInProgress && callSessionDTO.AgentRef !== userObjectId && isSameCallSession && !callSessionDTO.IsCurrentUserMonitoring) {
        currentSessionId.current = "";
        ClearCallerInfo(callSessionDTO);
      }
      if (EnvHelper.isStage3() && callSessionDTO.IsWarmTransferInProgress) {
        if (callSessionDTO.AgentRef !== userObjectId) {
          if (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.AgentClosed] ||
            callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent] ||
            callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmInviteRejected]) {
            ClearCallerInfo(callSessionDTO);
          }
          else if (!callSessionDTO.IsCurrentUserMonitoring) {
            setVariablesCleared(false);
            setVariable1(formatVariables(callSessionDTO.PrimaryAgentName));
            setVariable2(callSessionDTO.QueueName);
          }
        }
      }

      if (EnvHelper.isStage3() && callSessionDTO.IsOutboundCall &&
        callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.Terminated]) {
        if (callSessionDTO.PrimaryAgentPId === userObjectId) {
          const callerName = callSessionDTO.CallerName ? callSessionDTO.CallerName : callSessionDTO.CallerId;
          setVariablesCleared(false);
          setVariable1(formatVariables(callerName));
          setVariable2(callSessionDTO.InternalMediaIdentificationName || '');
        }
        if (callSessionDTO.CallerId === userObjectId) {
          setVariablesCleared(false);
          setVariable1(formatVariables(callSessionDTO.PrimaryAgentName));
          setVariable2(callSessionDTO.InternalMediaIdentificationName || '');
        }
      }
    });

    subscriptionViewChanged?.unsubscribe();
    subscriptionViewChanged = mainViewHelper.viewChanged.subscribe((activeViewItem: ActiveMainViewItem) => {
      setIsOngoingCallSelected(activeViewItem === ActiveMainViewItem.MediaView);
    });

    subscriptionHeaderBarMessageChanged?.unsubscribe();
    subscriptionHeaderBarMessageChanged = mainViewHelper.showHeaderBarMessage.subscribe((messageType: HeaderBarDisplayedMessageType) => {
      setIsOngoingCallSelected(messageType === HeaderBarDisplayedMessageType.MediaView &&
        Lockr.get<ActiveMainViewItem>(StorageKeys.MainViewTab) === ActiveMainViewItem.MediaView);
    });
  }

  const havingNonEmptyVariables = (variable1 !== '' || variable2 !== '' || variable3 !== '' || supervisedAgent !== '');

  return (<>
    {isOngoingCallSelected && !variablesCleared && havingNonEmptyVariables &&
      <Flex.Item>
        <Flex
          styles={({ theme: { siteVariables } }) => ({
            padding: '10px 30px',
          })}
          gap="gap.medium"
          vAlign="center"
        >
          <Text content={variable1} />
          <Text content={variable2} className="sip-phone-text" />
          <Text content={variable3} />
          <Text content={supervisedAgent} />
        </Flex>
      </Flex.Item>
    }
  </>
  )
};

export default CallDetails;

import * as S2 from './enums-s2';
import * as S3 from './enums-s3';
export * from './constants';
export * from './interfaces';
export * from './enums';
export * from './logging';
export * from './domain/person';
export * from './domain/response';
export * from './routes';

let stage3: boolean = false;
try {
	stage3 = (new URLSearchParams(window.location.search)).get('stage3') === "true";
}
catch (err) {
	console.error(err);
}

export const Controllers = stage3 ? S3.Controllers : S2.Controllers;
export const ServiceOperations = stage3 ? S3.ServiceOperations : S2.ServiceOperations;
export const ContactOperations = stage3 ? S3.ContactOperations : S2.ContactOperations;
export const AudioOperations = stage3 ? S3.AudioOperations : S2.AudioOperations;
export const TransferOperations = stage3 ? S3.TransferOperations : S2.TransferOperations;
export const SocketOperations = stage3 ? S3.SocketOperations : S2.SocketOperations;
export const QueueOperations = stage3 ? S3.QueueOperations : S2.QueueOperations;
export const OutboundOperations = stage3 ? S3.OutboundOperations : S2.OutboundOperations;
export const WebChatOperations = stage3 ? S3.WebChatOperations : S2.WebChatOperations;

import React, { useState } from 'react';
import { Flex, Text, Button, List, Checkbox, Input, Loader } from '@fluentui/react-northstar';
import Lockr from 'lockr';
import { useIntl } from 'react-intl';
import { Contact, contact } from 'utils/domain/contact';
import { socketContacts } from 'services/contacts';
import { logging, StorageKeys } from 'utils';
import { GeneralHelper, ValidationHelper } from 'utils/helpers';
import './addEditContact.scss';
import { socketQueue } from 'services/queue';
import { Subscription } from 'rxjs';

export const AddEditContactView = ({ closeAddContactPortal, getAllContacts, contactToUpdate }: any) => {
    const intl = useIntl();

    const [contactName, setContactName] = useState(contactToUpdate?.DisplayName || '');
    const [contactNameError, setContactNameError] = useState(false);
    const [queuesError, setQueuesError] = useState(false);
    const [contactPhone, setContactPhone] = useState(contactToUpdate?.LocalPhone || contactToUpdate?.MobilePhone || '');
    const [contactPhoneError, setContactPhoneError] = useState(false);
    const [contactSip, setContactSip] = useState(contactToUpdate?.SipAddress || '');
    const [contactSipIsInvalid, setContactSipIsInvalid] = useState(false);
    const [selectedQueues, setSelectedQueues] = useState(contactToUpdate?.QueueRefList || []);
    const [errorMessage, setErrorMessage] = useState('');
    const [queues, setQueues] = useState([] as any);
    const [noQueuesReceived, setNoQueuesReceived] = useState(false);

    let subscriptionSocketQueueChange: Subscription | null = null;

    React.useEffect(() => {
        initialize();

        return () => {
            componentWillUnmount();
        }
    }, []);

    const componentWillUnmount = () => {
        subscriptionSocketQueueChange?.unsubscribe();
        GeneralHelper.logCox(`in AddEditContact.tsx, in componentWillUnmount`);
    }

    const initialize = () => {
        GeneralHelper.logCox(`in AddEditContact.tsx, in initialize`);

        getQueues();

        subscriptionSocketQueueChange?.unsubscribe();
        subscriptionSocketQueueChange = socketQueue.subjectQueueChange.subscribe((resultQueue: any) => {
            setQueues(resultQueue);
        });
    }

    const getQueues = () => {
        if (socketQueue.queuesArray && socketQueue.queuesArray.length) {
            setQueues(socketQueue.queuesArray);
        } else {
            setTimeout(() => {
                setNoQueuesReceived(true);
            }, 1000);
        }
    }

    React.useEffect(() => {
        setContactNameError(false);
        setErrorMessage("");
    }, [contactName]);

    React.useEffect(() => {
        setContactPhoneError(false);
        setContactSipIsInvalid(false);
        setErrorMessage("");
    }, [contactPhone]);

    React.useEffect(() => {
        setQueuesError(false);
        setErrorMessage("");
    }, [selectedQueues]);

    const removeQueueFromList = (queueList: any, queue: any) => {
        return queueList.filter((item: any) => item !== queue);
    };

    const handleQueueSelected = (queue: any) => {
        const index = selectedQueues.indexOf(queue);
        if (index > -1) {
            setSelectedQueues(removeQueueFromList(selectedQueues, queue));
        } else {
            setSelectedQueues((selectedQueues: any) => [...selectedQueues, parseInt(queue)]);
        }
    }

    const queueShouldBeChecked = (queue: any) => {
        if (!(selectedQueues === undefined || selectedQueues.length === 0) && selectedQueues.includes(queue)) {
            return true;
        }
        return false;
    }

    const handleAddNewContactSubmitAction = () => {
        if (isValidInformations()) {
            performAction();
            closeAddContactPortal();
        }
    }

    const isValidInformations = () => {

        if (!contactPhone && !contactSip) {
            setContactPhoneError(true);
            setContactSipIsInvalid(true);
        } else {
            //phone validation
            if (contactPhone && GeneralHelper.isStringEmptyOrWhiteSpace(contactPhone) && !ValidationHelper.isValidPhoneNumber(contactPhone)) {
                setContactPhoneError(true);
                setErrorMessage(intl.formatMessage({ id: "ErrorMessage.AddContact.InvalidPhone" }));
                return false;
            }
            //sip validation
            if (contactSip && GeneralHelper.isStringEmptyOrWhiteSpace(contactSip) && !ValidationHelper.isValidSip(contactSip)) {
                setContactSipIsInvalid(true);
                setErrorMessage(intl.formatMessage({ id: "ErrorMessage.AddContact.InvaliSip" }));
                return false;
            }
        }

        if (!contactName) {
            setContactNameError(true);
        }

        if (selectedQueues === undefined || selectedQueues.length === 0) {
            setQueuesError(true);

            setErrorMessage(intl.formatMessage({ id: "ErrorMessage.AddContact.InvalidInputs" }));
            return false;
        }

        if ((contactPhone || contactSip) && contactName && !(selectedQueues === undefined || selectedQueues.length === 0)) {
            return true;
        } else {
            setErrorMessage(intl.formatMessage({ id: "ErrorMessage.AddContact.InvalidInputs" }));
            return false;
        }
    }

    const performAction = () => {
        contact.CompanyRef = Lockr.get(StorageKeys.CompanyId);
        contact.ContactName = contactName;
        contact.Source = contactPhone;
        contact.AddedBy = Lockr.get(StorageKeys.UserId);
        contact.IsDeleted = false;
        contact.QueueMapList = selectedQueues;
        contact.SipAddress = contactSip;

        if (contactToUpdate == null) {
            addContact(contact);
        } else {
            contact.ID = contactToUpdate.ID;
            updateContact(contact);
        }
    }

    const addContact = (contact: Contact) => {
        socketContacts.saveContact(contact).then((response: any) => {
            if (response.Data) {
                logging.errorHandler.next("SuccessMessage.AddContact");
                setTimeout(function () {
                    getAllContacts();
                }, 1000);
            } else {
                logging.errorHandler.next("ErrorMessage.AddContact.AddContact");
            }
        }).catch(err => {
            logging.errorHandler.next("ErrorMessage.AddContact.AddContact");
            console.log(err.message);
        });
    }

    const updateContact = (contact: Contact) => {
        socketContacts.updateContact(contact).then((response: any) => {
            if (response.Data) {
                logging.errorHandler.next("SuccessMessage.UpdateContact");
                setTimeout(function () {
                    getAllContacts();
                }, 1000);
            } else {
                logging.errorHandler.next("ErrorMessage.UpdateContact");
            }
        }).catch(err => {
            logging.errorHandler.next("ErrorMessage.UpdateContact");
            console.log(err.message);
        });
    }

    return (
        <div>
            <Flex gap="gap.small">
                <Flex.Item size="60%">
                    <Flex column>
                        <Text error={contactNameError} content={intl.formatMessage({ id: "AddContactView.Name" })} />
                        <Input required maxLength={50} fluid placeholder={intl.formatMessage({ id: "AddContactView.TypeName" })}
                            error={contactNameError}
                            value={contactName}
                            onChange={(e) => setContactName((e.target as HTMLInputElement).value)}
                        />
                        <br />
                        <Text error={contactPhoneError} content={intl.formatMessage({ id: "AddContactView.Phone" })} />
                        <Input maxLength={50} type="tel" fluid placeholder={intl.formatMessage({ id: "AddContactView.TypePhone" })}
                            error={contactPhoneError}
                            value={contactPhone}
                            onChange={(e) => setContactPhone((e.target as HTMLInputElement).value)}
                        />
                        <br />
                        <Text error={contactSipIsInvalid} content={intl.formatMessage({ id: "AddContactView.Sip" })} />
                        <Input maxLength={50} type="email" fluid placeholder={intl.formatMessage({ id: "AddContactView.TypeSip" })}
                            error={contactSipIsInvalid}
                            value={contactSip}
                            onChange={(e) => setContactSip((e.target as HTMLInputElement).value)}
                        />
                        <br />
                        {errorMessage && <Text color="red" content={errorMessage} />}
                    </Flex>
                </Flex.Item>

                <Flex.Item size="40%">
                    <div>
                        <Text error={queuesError} content={intl.formatMessage({ id: "AddContactView.Queues" })} styles={{ paddingLeft: "0.625rem" }} /><br />
                        <Flex padding="padding.medium">
                            <div style={{ overflow: "scroll", height: "175px" }}>
                                {(queues && queues.length === 0 && !noQueuesReceived) ?
                                    <Loader size="small" /> :

                                    <List className="queueList"
                                        items={queues.map((queue: any) => (
                                            <Checkbox
                                                className="queues-checkbox"
                                                checked={queueShouldBeChecked(queue.QueueRef)}
                                                styles={{ minHeight: '0px' }}
                                                label={queue.QueueName}
                                                key={queue.QueueRef}
                                                onClick={() => handleQueueSelected(queue.QueueRef)}
                                            />
                                        ))}
                                    />
                                }
                            </div>
                        </Flex>
                    </div>
                </Flex.Item>
            </Flex>
            <Flex gap="gap.small" padding="padding.medium" hAlign="end">
                <Button content={intl.formatMessage({ id: "AddContactView.Cancel" })} onClick={() => closeAddContactPortal()} />
                <Button primary content={intl.formatMessage({ id: "AddContactView.Save" })} onClick={() => handleAddNewContactSubmitAction()} />
            </Flex>
        </div>
    )
};

export default AddEditContactView;

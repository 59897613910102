import React, { useState, useRef } from 'react';
import Lockr from 'lockr';
import { ConferenceStatusAction, MonitorTypes, StorageKeys, MediaType, OperatorCallState } from '../../../../utils';
import { Flex, Text, EmailIcon, PhoneClockIcon, ChatIcon, Button, PlayIcon } from '@fluentui/react-northstar';
import AdvancedTable from '../../../../components/AdvancedTable';
import { useIntl } from 'react-intl';
import { operatorListManager } from '../../../../services/io/operator-list';
import { WaitingMediaDto } from '../../../../utils/domain/waitingMediaDTO';
import { OperatorCallModel } from '../../../../utils/domain/operatorCallModel';
import { socketQueue } from '../../../../services/queue';
import Timer from '../../../../components/Timer';
import { socketAudio } from '../../../../services/calling';
import './operatorQueue.scss';
import { CallSessionDTO } from '../../../../utils/domain/callSessionDTO';
import { Subscription } from 'rxjs';
import { GeneralHelper } from '../../../../utils/helpers';
import { ColumnsResolver } from './ColumnsResolver';
import PlaySoundHelper, { SoundType } from 'utils/helpers/play-sound-helper';

export const OperatorQueue = () => {
  const intl = useIntl();
  const [userMemberdOnOperator, setUserMemberdOnOperator] = useState(false);
  const initialGetIsDone = useRef(false);
  const [operatorCallsObjectList, setOperatorCallsObjectList] = useState<OperatorCallModel[]>([]);
  const [operatorCalls, setOperatorCalls] = useState([]);
  const [operatorCallsCount, setOperatorCallsCount] = useState(0);
  const operatorCallReceivedCopy = useRef<any>();
  const canShowParkButton = useRef(true);
  const [inACall, setInACall] = useState(false);
  const [isInCallMonitoring, setIsInCallMonitoring] = useState(false);

  let subscriptionOperatorCallAdded: Subscription | null = null;
  let subscriptionOperatorCallUpdated: Subscription | null = null;
  let subscriptionOperatorCallRemoved: Subscription | null = null;
  let subscriptionSocketQueueChange: Subscription | null = null;
  let subscriptionCurrentUserCallSessionStateChanged: Subscription | null = null;
  let subscriptionCurrentUserMonitoredCallSessionChanged: Subscription | null = null;

  const columns = ColumnsResolver();

  React.useEffect(() => {
    initialize();
    mapOperatorCallsObjectsForTable();
    return () => {
      componentWillUnmount();
    }
  }, [operatorCallsObjectList, inACall, isInCallMonitoring]);
  //#warning-js, CC4ALL-2508: should use useRef instead of useState for operatorCallsObjectList as to no longer need to redo the subscribes

  React.useEffect(() => {
    verifyIfMemberedOnOperatorQueue(socketQueue.queuesArray);
  }, []);

  const initialize = () => {
    GeneralHelper.logCox(`in OperatorQueue.tsx, in initialize`);

    if (!initialGetIsDone.current) {
      getOperatorCalls();
    }

    subscriptionOperatorCallAdded?.unsubscribe();
    subscriptionOperatorCallAdded = operatorListManager.onOperatorCallAdded.subscribe((obj: WaitingMediaDto) => {
      handleOperatorAdded(obj);
    })

    subscriptionOperatorCallUpdated?.unsubscribe();
    subscriptionOperatorCallUpdated = operatorListManager.onOperatorCallUpdated.subscribe((obj: WaitingMediaDto) => {
      handleOperatorUpdated(obj);
    });

    subscriptionOperatorCallRemoved?.unsubscribe();
    subscriptionOperatorCallRemoved = operatorListManager.onOperatorCallRemoved.subscribe((obj: WaitingMediaDto) => {
      handleOperatorRemoved(obj);
    });

    subscriptionSocketQueueChange?.unsubscribe();
    subscriptionSocketQueueChange = socketQueue.subjectQueueChange.subscribe((resultQueue: any) => {
      verifyIfMemberedOnOperatorQueue(resultQueue);
    });

    subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
    subscriptionCurrentUserCallSessionStateChanged = socketAudio.currentUserCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      if ((callSessionDTO.ActionHistory &&
        callSessionDTO.ActionHistory.length &&
        (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.Offering ||
          callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.Accepted)) ||
        callSessionDTO.IsTeamsUnparkInProgress) {
        setInACall(true);
      } else {
        setInACall(false);
      }
    });

    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged = socketAudio.currentUserMonitoredCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      GeneralHelper.logCox(`in OperatorQueue.tsx, in listenerCurrentUserMonitoredCallSessionChanged, 
      MonitorType is ${callSessionDTO.MonitorType}, 
      IsComputedAsTerminated is ${callSessionDTO.IsComputedAsTerminated}`);

      setIsInCallMonitoring(callSessionDTO.MonitorType !== MonitorTypes.None && !callSessionDTO.IsComputedAsTerminated);
    });
  }

  const componentWillUnmount = () => {
    GeneralHelper.logCox(`in OperatorQueue.tsx, in componentWillUnmount`);

    subscriptionOperatorCallAdded?.unsubscribe();
    subscriptionOperatorCallUpdated?.unsubscribe();
    subscriptionOperatorCallRemoved?.unsubscribe();
    subscriptionSocketQueueChange?.unsubscribe();
    subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
  };

  const handleOperatorAdded = (obj: WaitingMediaDto) => {
    if (obj !== operatorCallReceivedCopy.current && initialGetIsDone.current === true) {
      addOperatorCall(obj)
    }
    operatorCallReceivedCopy.current = obj;
  }
  const handleOperatorUpdated = (obj: WaitingMediaDto) => {
    if (obj.OperatorCallState === OperatorCallState.FallBack) {
      PlaySoundHelper.playSound(SoundType.Notification);
    }

    const indexToUpdate = getElementIndexBySessionId(obj.SessionID);
    if (indexToUpdate != null) {
      updateOperatorCall(obj, indexToUpdate);
    }
  }
  const handleOperatorRemoved = (obj: WaitingMediaDto) => {
    const indexToDelete = getElementIndexBySessionId(obj.SessionID);
    if (indexToDelete != null) {
      deleteOperatorCall(indexToDelete);
    }
  }

  const verifyIfMemberedOnOperatorQueue = (obj: any) => {
    let isMember = false;
    obj.forEach((element: any) => {
      if (element.IsOperator === true) {
        isMember = true;
        setUserMemberdOnOperator(true)
      }
    });
    if (isMember === false) {
      setUserMemberdOnOperator(false)
    }
  }

  const addOperatorCall = (obj: WaitingMediaDto) => {
    const objectToAdd = mapOperatorCallModel(obj);
    const alreadyAddedObj = operatorCallsObjectList.filter(element => element.SessionID === obj.SessionID)[0];
    if (!alreadyAddedObj && objectToAdd.Status !== "") {
      setOperatorCallsCount(operatorCallsCount + 1);
      setOperatorCallsObjectList([...operatorCallsObjectList, objectToAdd])
    }
  }

  const updateOperatorCall = (obj: WaitingMediaDto, indexToUpdate: number) => {
    const objectToUpdate = mapOperatorCallModel(obj);
    const operatorCallsObjectListUpdated = operatorCallsObjectList.map((item, index) => {
      return index === indexToUpdate ? objectToUpdate : item
    });
    if (objectToUpdate.Status !== "") {
      setOperatorCallsObjectList(operatorCallsObjectListUpdated);
    }
  }

  const deleteOperatorCall = (indexToDelete: number) => {
    const operatorCallsObjectListUpdated = operatorCallsObjectList.filter((item, index) => indexToDelete !== index);
    setOperatorCallsCount(operatorCallsCount - 1);
    setOperatorCallsObjectList(operatorCallsObjectListUpdated);
  }

  const getElementIndexBySessionId = (sessionID: any) => {
    const searchedObject = operatorCallsObjectList.filter(element => element.SessionID === sessionID)[0];
    return searchedObject ? operatorCallsObjectList.indexOf(searchedObject) : null;
  };

  const getOperatorCalls = () => {
    operatorListManager.getOperatorCallsByAgentSip(Lockr.get<string>(StorageKeys.SIP)).then((response: WaitingMediaDto[]) => {
      if (response) {
        const operatorObjects = [] as OperatorCallModel[];
        let skipedRecordCount = 0;
        response.forEach((element: WaitingMediaDto) => {
          const model = mapOperatorCallModel(element);
          if (model.Status !== "") {
            operatorObjects.push(model);
          }
          else {
            skipedRecordCount = skipedRecordCount + 1;
          }
        });
        setOperatorCallsCount(response.length - skipedRecordCount);
        setOperatorCallsObjectList(operatorObjects);
      }
      initialGetIsDone.current = true;
    }).catch(err => {
      console.log(err.message);
    });
  }

  const mapOperatorCallModel = (obj: WaitingMediaDto) => {
    return {
      SessionID: obj.SessionID,
      ConversationKey: obj.ConversationKey,
      ConferenceUri: obj.ConferenceUri,
      OperatorCallState: obj.OperatorCallState,
      OperatorName: obj.OperatorName,
      Agent: obj.TargetAgentName,
      Media: obj.MediaType?.toString(),
      StartDate: obj.StartDate,
      Source: obj.SourceDisplayName === "" || obj.SourceDisplayName === undefined || obj.SourceDisplayName == null ? obj.Source?.replace("sip:", "") : obj.SourceDisplayName,
      Queue: obj.QueueName,
      TrustedEndPoint: obj.TrustedEndPoint,
      QueueRef: obj.QueueRef,
      MediaType: obj.MediaType,
      Skill: obj.SkillName,
      Status: getStatus(obj.OperatorCallState)
    } as OperatorCallModel;
  }

  const getStatus = (status: OperatorCallState) => {
    switch (status) {
      case OperatorCallState.None:
      case OperatorCallState.UnAssigned:
      case OperatorCallState.BeforeAssignedToOperator:
        return "";
      case OperatorCallState.AssignedToOperator:
      case OperatorCallState.OperatorInterrupt:
        return "Assigned";
      case OperatorCallState.AssignedToOperatorInWarmTransfer:
        return "Transferring";
      case OperatorCallState.Parked:
        return "Parked";
      case OperatorCallState.AssignedToAgentInCall:
        return "Agent Handling";
      case OperatorCallState.AssignedToAgentRinging:
        return "Agent Ringing";
      case OperatorCallState.AssignedToAgentInPersonalQueue:
        return "Agent Waiting";
      case OperatorCallState.FallBack:
        return "Fallback";
      case OperatorCallState.OnHold:
        return "On Hold";
      case OperatorCallState.Offering:
        return "Offering";
      default:
        return "";
    }
  }

  const mapOperatorCallsObjectsForTable = (parkedClicked: boolean = false) => {

    const objectsForTable: any = [];
    checkAnyCallAssigned(operatorCallsObjectList);
    operatorCallsObjectList.forEach((element: OperatorCallModel) => {
      objectsForTable.push(mapItem(element, parkedClicked))
    });


    setOperatorCalls(objectsForTable);
  }

  const checkAnyCallAssigned = (calls: OperatorCallModel[]) => {
    const agentSip = Lockr.get(StorageKeys.SIP);
    let hasCallAssigned = false;
    calls.forEach((element: OperatorCallModel) => {
      if (agentSip && element.OperatorSIP &&
        (element.OperatorCallState === OperatorCallState.Offering || element.OperatorCallState === OperatorCallState.AssignedToOperator) &&
        element.OperatorSIP === agentSip) {
        hasCallAssigned = true;
      }
    })

    canShowParkButton.current = !hasCallAssigned;
  }

  const resposiveColumnClassName = 'big-grow table-column';

  const mapItem = (obj: OperatorCallModel, parkedClicked: boolean = false) => {
    return {
      key: obj.SessionID,
      queueid: obj.QueueRef,
      items: [
        { content: getMediaTypeIcon(obj.MediaType, obj.SessionID), key: obj.SessionID?.toString() + '-0', className: GeneralHelper.getMediaTypeContainerClassName(obj.MediaType) },
        { content: GeneralHelper.formatSource(obj.Source), key: obj.SessionID?.toString() + '-1', className: "caller-name-column" },
        { content: <Timer startDate={obj.StartDate}></Timer>, key: obj.SessionID?.toString() + '-2', className: resposiveColumnClassName },
        { content: obj.Queue, key: obj.SessionID?.toString() + '-3', className: resposiveColumnClassName },
        { content: obj.Skill ? obj.Skill : "", key: obj.SessionID?.toString() + '-4', className: resposiveColumnClassName },
        { content: obj.Status ? obj.Status : "", key: obj.SessionID?.toString() + '-5', className: resposiveColumnClassName },
        { content: obj.OperatorName ? obj.OperatorName : "", key: obj.SessionID?.toString() + '-6', className: resposiveColumnClassName },
        { content: obj.Agent ? obj.Agent : "", key: obj.SessionID?.toString() + '-7', className: resposiveColumnClassName },
        { content: isUnparkButtonVisible(obj) ? getUnparkButton(obj, parkedClicked) : "", key: obj.SessionID?.toString() + '--0', className: "unPark-column" }
      ]
    }
  }

  const isUnparkButtonVisible = (obj: any) => {
    //#warning-js [cc4all-1102]: inconsistent visibility rules; canShowParkButton uses hasCallAssigned which uses the operator calls
    //table only; inACall is used in the className in the unpark button ...
    if (obj && (obj.Status === getStatus(OperatorCallState.Parked) || obj.Status === getStatus(OperatorCallState.FallBack)) && canShowParkButton.current) {
      return true;
    }

    return false;
  }

  const getUnparkButton = (obj: any, parkedClicked: boolean = false) => {
    return <Button circular icon={<PlayIcon size="small" />} size="small"
      className={(inACall || parkedClicked || isInCallMonitoring) ? "hide-unpark-button" : "show-unpark-button"} primary
      title={intl.formatMessage({ id: "CallActions.Resume" })}
      onClick={() => {
        mapOperatorCallsObjectsForTable(true);

        if (obj && obj.QueueRef) {
          if (!socketQueue.queuesArray || !socketQueue.queuesArray.length) {
            mapOperatorCallsObjectsForTable(false);
            return;
          }

          const callQueues = socketQueue.queuesArray.filter((el: any) => el.QueueRef === obj.QueueRef);
          let canUnpark = false;

          if (callQueues && callQueues.length) {
            const currentCallQueue = callQueues[0];

            if (currentCallQueue && currentCallQueue.IsAttached) {
              unparkCall(obj);
              canUnpark = true;
            }
          }

          if (!canUnpark) {
            mapOperatorCallsObjectsForTable(false);
          }
        }
      }}
    />
  }
  const unparkCall = (obj: any) => {
    const teamsUnParkRequestDTO = {
      SessionID: obj.SessionID,
      AgentSip: Lockr.get(StorageKeys.SIP),
      IsFallback: obj.OperatorCallState === OperatorCallState.FallBack
    }
    socketAudio.unparkCall(teamsUnParkRequestDTO);
  }

  const getMediaTypeIcon = (mediaType?: MediaType, sessionId?: string | undefined) => {
    switch (mediaType) {
      case MediaType.Call:
        return <PhoneClockIcon className="call-type-icon-color" title={sessionId ?? ""}></PhoneClockIcon>
      case MediaType.Chat:
        return <ChatIcon className="call-type-icon-color" title={sessionId ?? ""}></ChatIcon>
      case MediaType.Mail:
        return <EmailIcon className="call-type-icon-color" title={sessionId ?? ""}></EmailIcon>
      case MediaType.SocialMedia:
        return <img alt='Media Icon' width="15" className="call-type-icon-color social-media-icon" title={sessionId ?? ""} />
      default:
        return <></>
    }
  }

  const getOperatorCallsCount = () => {
    return operatorCallsCount === 0 ? "" : ` (${operatorCallsCount.toString()})`;
  }

  if (userMemberdOnOperator) {
    return (
      <Flex.Item>
        <Flex column>
          <Text content={intl.formatMessage({ id: "AgentView.OperatorQueue" }) + getOperatorCallsCount()} weight="bold" />
          <AdvancedTable columns={columns} rows={operatorCalls} label="Operator Queue" />
        </Flex>
      </Flex.Item>
    )
  }
  else {
    return <div></div>
  }
};

export default OperatorQueue;

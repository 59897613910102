import * as React from 'react';
import { Flex, Avatar } from '@fluentui/react-northstar';
import { useIntl } from 'react-intl';
import './agentAvatar.scss'
import AgentAvatarPopup from './AgentAvatarPopup';
import { GeneralHelper } from 'utils/helpers';

const AgentAvatar = (props: any) => {
  const intl = useIntl();

  const { name, status, showPopup, azureID, contactSourceType } = props;

  return (
    <Flex vAlign="center">
      <Flex.Item size="2.5rem" shrink={false}>
        <Flex column>
          {showPopup ?
            <AgentAvatarPopup 
              name={name}
              status={status} 
              azureID={azureID}
              contactSourceType={contactSourceType}
            /> :
            <Avatar styles={{ marginRight: '0.5rem' }}
              name={GeneralHelper.getStatusText(status, intl)}
              getInitials={() => GeneralHelper.getInitials(name)}
              status={GeneralHelper.getStatus(status, intl)} />}
        </Flex>
      </Flex.Item>
      <Flex.Item>
        <Flex column>
          {name}
        </Flex>
      </Flex.Item>
    </Flex >
  );
};

export default AgentAvatar;

import { Chat, ChatItemProps, Text, ShorthandCollection, Avatar } from '@fluentui/react-northstar';
import React, { useState } from 'react';
import { useIntl } from "react-intl";
import { Subscription } from 'rxjs';
import { customerJourneyManager } from 'services/io/customer-journey';
import { CustomerJourneyNoteDTO } from 'utils/domain/customerJourneyModels';
import { GeneralHelper } from 'utils/helpers';
import '../../../containers/WebChatView/webChatView.scss';

export const CustomerJourneyNotesView = ({ SessionId }: any) => {
    const intl = useIntl();

    const [messageItems, setMessageItems] = useState<ShorthandCollection<ChatItemProps>>([]);

    let subscriptionOnNoteReceived: Subscription | null = null;

    let messages: ShorthandCollection<ChatItemProps> = [];

    React.useEffect(() => {
        initialize();
        return () => {
            componentWillUnmount();
        }
    }, []);

    React.useEffect(() => {
        if (SessionId) {
            getConversationNotesForSessionId();

            refreshNoteSubscription();
        }
    }, [SessionId]);

    const initialize = () => {
        GeneralHelper.logCox(`in CustomerJourneyNotesView.tsx, in initialize`);

        refreshNoteSubscription();
    }

    const componentWillUnmount = () => {
        GeneralHelper.logCox(`in CustomerJourneyNotesView.tsx, in componentWillUnmount`);

        subscriptionOnNoteReceived?.unsubscribe();
    }

    const refreshNoteSubscription = () => {
        subscriptionOnNoteReceived?.unsubscribe();
        subscriptionOnNoteReceived = customerJourneyManager.onNoteAdded.subscribe((obj: CustomerJourneyNoteDTO) => {
            addNoteToView(obj, false);
            setMessageItems(messages);
            scrollToChatMessagesBottom();
        });
    }

    const initNotesList = (notes: CustomerJourneyNoteDTO[]) => {
        if (notes) {
            notes.forEach((element: any) => {
                addNoteToView(element, true);
            });
        }
        setMessageItems(messages);
        scrollToChatMessagesBottom();
    }

    const getConversationNotesForSessionId = () => {
        messages = [];
        setMessageItems([]);
        customerJourneyManager.getConversationNotesByConversation(SessionId).then(response => {
            initNotesList(response);
        }).catch(err => {
            console.log(err.message);
        });
    }

    const addNoteToView = (obj: CustomerJourneyNoteDTO, isInit: boolean) => {
        const dateFormatted = getDateFormatted(new Date(obj.Date));

        const newData = messages.slice(0);
        newData.push({
            gutter: <Avatar name={obj.Agent.FullName} />,
            message: <Chat.Message content={obj.Message} author={obj.Agent.FullName} timestamp={dateFormatted} />,
            attached: 'top',
            key: `message-id-${obj.Date}-${Math.random()}`,
            chatmessageid: Math.random()
        });
        messages = newData;

        if (!isInit) {
            setMessageItems(newData);
            scrollToChatMessagesBottom();
        }
    }

    const getDateFormatted = (date: Date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}/${month}/${year} ${GeneralHelper.formatHourAMPM(date)}`;
    }
    
    const scrollToChatMessagesBottom = () => {
        const component = document.querySelector(".messages-component");

        if (!component) {
            return;
        }
        component.scrollTo(0, component.scrollHeight);
    }

    return (
        <>
            <Text content={intl.formatMessage({ id: "CustomerJourney.NotesView.Notes"})} weight="bold" className="messages-text" />

            <Chat key="1" items={messageItems} styles={{maxHeight: "200px", overflow: "scroll"}} className="messages-component" />
        </>
    );
};

export default CustomerJourneyNotesView;
import { ConferenceStatusAction, MonitorTypes, StorageKeys } from "utils";
import Lockr from 'lockr';
import { EnvHelper } from "utils/helpers";

export class CallSessionDTO { //server side it's called ConversationDTO
    Message!: string;
    QueueRef!: any;
    AgentRef!: any;
    PrimaryAgentSIP!: string;
    CallerUri!: string;
    CallerName!: string;
    AgentName!: string;
    QueueName!: string;
    Skill!: string;
    StartDate!: string;
    ConferenceAction?: ConferenceStatusAction;
    ConferenceActionAsString!: string;
    ConversationKey!: string;
    ConferenceUri!: string;
    TrustedEndPoint!: string;
    ActiveSupervisorSIP!: string;
    MediaTypeAsString!: string;
    SupervisorRef!: string;
    SecondaryAgentSIP!: string;
    IsOperatorCall!: boolean;
    IsOutboundCall!: boolean;
    StatusAsString!: string;
    PrimaryAgentName!: string;
    SecondaryAgentName!: string;
    ActiveSupervisorName!: string;
    TransferSourceSIP!: string;
    TransferTargetUri!: string;
    OriginalTransferTargetUri!: string;
    CallStartDate!: string;
    AgentAnswerDate?: string;
    PrimaryAgentAnswerDate?: string;
    SecondaryAgentAnswerDate?: string;
    MessageDate!: string;
    LineUri!: string;
    CallerSIP!: string;
    IsCampaignCall!: boolean;
    IsTeamsCampaignOutboundCall?: boolean;
    IsTransferedToExternal!: boolean;
    IsWarmTransferInProgress!: boolean;
    IsColdTransferInProgress!: boolean;
    IsColdTransferIVRInProgress!: boolean;
    IsCallerOnHold!: boolean;
    QueueWarmTransferCommitedForQueue!: boolean;
    ActionHistory!: number[];
    ParticipantIds!: string[];
    UrlExecuter!: any;
    Started: boolean = false;
    Transferring: boolean = false;
    IsManualRecordingSessionActive!: boolean;
    IsManualRecordingEnabled!: boolean;
    IsTeamsUnparkInProgress!: boolean;
    IsOperatorQueue!: boolean;
    AllRelatedTargets?: string[];
    SessionId!: string;
    SupervisingStartDate!: string;//#warning-js [cc4all-1102]: to make it a Date
    MonitorType: MonitorTypes;
    MonitorTypeAsString?: string;
    IsTerminated?: boolean = false;
    SupervisorSIPList: string[] = Array<string>(0);
    ActionHistoryAsString?: string[];
    TenantId?: string;
    AgentBeforeTransferRef?: string;
    SecondaryAgentRef?: string;
    LastInvitedParticipantId?: string;
    AzureTenantId?: string;
    ScenarioId?: string;
    InternalMediaIdentificationName?: string;
    PrimaryAgentPId?: string;
    CallerPId?: string;
    CallerId?: string;
    WorkflowInstanceId?: string;
    IsMonitoringInProgress: boolean = false;
    IsMonitoringEnabled: boolean = true;
    WasCallTransferred: boolean = false;
    IsCallBackRequest: boolean = false;

    constructor(data?: any) {
        this.MonitorType = MonitorTypes.None;
        this.SessionId = "";
        Object.assign(this, data);
    }

    get IsCurrentUserInCall(): boolean {
        return this.IsCurrentUserThePrimaryAgent || this.IsCurrentUserTheSecondaryAgent || this.IsCurrentUserMonitoring;
    }

    get IsCurrentUserThePrimaryAgent(): boolean {
        return EnvHelper.isStage3() ?
            !!this.AgentRef && this.AgentRef.toLowerCase() === Lockr.get<string>(StorageKeys.UserId).toLocaleLowerCase() :
            !!this.PrimaryAgentSIP && this.PrimaryAgentSIP.toLowerCase() === (Lockr.get<string>(StorageKeys.SIP)).toLowerCase();
    }

    get IsCurrentUserTheSecondaryAgent(): boolean {
        return EnvHelper.isStage3() ?
            !!this.SecondaryAgentRef && this.SecondaryAgentRef.toLowerCase() === Lockr.get<string>(StorageKeys.UserId).toLocaleLowerCase() :
            !!this.SecondaryAgentSIP && this.SecondaryAgentSIP.toLowerCase() === (Lockr.get<string>(StorageKeys.SIP)).toLowerCase();
    }

    get IsCurrentUserMonitoring(): boolean {
        return EnvHelper.isStage3() ?
            !!this.SupervisorRef && this.SupervisorRef.toLowerCase() === Lockr.get<string>(StorageKeys.UserId).toLocaleLowerCase() :
            !!this.ActiveSupervisorSIP && this.ActiveSupervisorSIP.toLowerCase() === (Lockr.get<string>(StorageKeys.SIP)).toLowerCase();
    }

    get IsComputedAsTerminated(): boolean {//#warning-js [cc4all-1102]: see if IsTerminate bool flag is good as well
        return ['CallerClosedAfterAnswer', 'Terminated', 'AgentClosed'].indexOf(this.ConferenceActionAsString) > -1;
    }
}

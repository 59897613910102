import { CallIcon, Flex, Text } from "@fluentui/react-northstar";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { socketTransfer } from "services/transfers";
import { CallableContactColumnsEnum, ContactColumns, ContactSipAdressColumnsEnum } from "utils";
import { AzureActiveDirectorySettingsDTO, AzureAdAttributeSettingDTO, SipMappingType } from "utils/domain/azureActiveDirectorySettingsDTO";
import { Contact, ContactType } from "utils/domain/extended/contact";
import { ValidationHelper } from "utils/helpers";
import { mainViewHelper } from "utils/helpers/main-view-helper";

const DefaultSipAddressColumn = "SipAddress";

const callableContactColumns = Object.keys(CallableContactColumnsEnum);

type Props = {
  contact: Contact;

  azureADSettings: AzureActiveDirectorySettingsDTO;

  agentHasOutboundEnabled: boolean;
}

const CallableItemsList = ({ contact, azureADSettings, agentHasOutboundEnabled }: Props) => {

  const intl = useIntl();

  const callableColumns = useMemo<string[]>(() => Object.keys(CallableContactColumnsEnum), []);

  const isSipColumn = (name: string, sipType: SipMappingType): boolean => {
    return (name === ContactSipAdressColumnsEnum.Upn && sipType === SipMappingType.UPN) ||
      (name === ContactSipAdressColumnsEnum.Mail && sipType === SipMappingType.Mail);
  }

  const getIconForCallableItems = (columnName: string) => {
    switch (columnName) {
      case ContactSipAdressColumnsEnum.Upn:
      case ContactSipAdressColumnsEnum.Mail:
      case DefaultSipAddressColumn:
        return <img alt='SIP Icon' width="14" className="sip-icon" title={intl.formatMessage({ id: "AgentView.SipAddress" })} />;
      case CallableContactColumnsEnum.QueueName:
        return <img alt='Queue NAme Icon' width="14" className="sip-icon" title={intl.formatMessage({ id: "AgentView.QueueName" })} />;
      case CallableContactColumnsEnum.LocalPhone:
        return <CallIcon outline title={intl.formatMessage({ id: "AgentView.LocalPhone" })} />;
      case CallableContactColumnsEnum.MobilePhone:
        return <img alt='Mobile Phone Icon' width="17" className="mobile-phone-icon" title={intl.formatMessage({ id: "AgentView.MobilePhone" })} />
      case CallableContactColumnsEnum.BusinessPhones:
        return <img alt='Business Phone Icon' width="15" className="business-phone-icon" title={intl.formatMessage({ id: "AgentView.BusinessPhones" })} />
      default:
        return <></>
    }
  }

  const handleTransfer = (target: string, isSip: boolean | undefined = undefined, isWarm: boolean = false) => {
    let transferTo: string | undefined;
    const sipAddress = contact.UserPrincipalName;

    if (isSip === undefined) {
      transferTo = sipAddress || target;
    } else {
      transferTo = isSip ? sipAddress : target;
    }

    const transferData = { 'transferTo': transferTo, 'contactType': contact.ContactType };
    if (isWarm) {
      socketTransfer.onWarmTransferStarted.next(transferData);
    }
    else {
      socketTransfer.onColdTransferStarted.next(transferData);
    }
  }

  const makeCallAction = (target: string, isSip: boolean, isTransfer: boolean, isWarmTransfer: boolean) => {
    if (!target) {
      return;
    }

    if (isTransfer) {
      handleTransfer(target, isSip, isWarmTransfer);
    } else if (agentHasOutboundEnabled) {
      performCall(contact.Id, target);
    }
  }

  const performCall = (value: string, target: string) => {
    const id = Array.isArray(value) ? value[0] : value;
    mainViewHelper.navigationViewGoToDialpad.next({ id: id, target: target });
  }

  const getCallableItem = (isTransfer: boolean, isWarmTransfer: boolean, column: string, className: string, data: string, isSip: boolean) => {
    const phoneTypeColumns = [
      CallableContactColumnsEnum[CallableContactColumnsEnum.LocalPhone],
      CallableContactColumnsEnum[CallableContactColumnsEnum.MobilePhone],
      CallableContactColumnsEnum[CallableContactColumnsEnum.BusinessPhones]
    ] as string[];

    let content = column === CallableContactColumnsEnum.QueueName
      ? contact.DisplayName
      : (data?.toString().replace("sip:", '') || '-');
    let target = data;

    if (phoneTypeColumns.includes(column) && content !== '-') {
      content = ValidationHelper.stripPhoneNumber(content);
      target = ValidationHelper.stripPhoneNumber(content);
    }

    return ({
      content: (<Flex gap="gap.small" vAlign="center" className="callable-item">
        {getIconForCallableItems(column)}
        <Text content={content} className="callable-item-text" />
      </Flex>),
      className: className,
      title: target,
      key: `item-details-${target}${Math.random()}`,
      onClick: () => { makeCallAction(target, isSip, isTransfer, isWarmTransfer) }
    });
  }

  const mapColumnName = (column: string) => column === ContactColumns.Upn ? ContactColumns.UserPrincipalName : (column || '');

  const getCallableItemsList = (isTransfer: boolean, isWarmTransfer: boolean) => {
    const attributes = azureADSettings?.Attributes;

    if (!attributes) {
      return [];
    }

    const items: any = [];
    attributes.forEach((setting: AzureAdAttributeSettingDTO) => {
      const columnValue = contact[mapColumnName(setting.ColumnName) as keyof Contact];
      const value = Array.isArray(columnValue) ? columnValue[0] : columnValue?.toString();

      const isSip = (contact.ContactType === ContactType.AzureActiveDirectory || contact.ContactType === ContactType.Database) 
                    && isSipColumn(setting.ColumnName, azureADSettings.SipMappingType);
      
      if (callableColumns.includes(setting.ColumnName) || isSip) {
        items.push(getCallableItem(isTransfer, isWarmTransfer, setting.ColumnName, '', value, isSip));
      }
    });

    return items;
  }

  return { getCallableItemsList };
}

export default CallableItemsList;
import React, { useState, useMemo, useEffect } from 'react';
import { MenuButton, PlayIcon, Flex, Popup, List } from '@fluentui/react-northstar';
import { socketPresence } from 'services/presence';
import { StorageKeys } from 'utils';
import './presenceView.scss';
import Lockr from 'lockr';
import { useIntl } from 'react-intl';
import { GeneralHelper } from 'utils/helpers';
import { Presence, PresenceState, NotePresenceState, PresenceItem } from 'utils/domain/presence';
import AgentAvatar from 'components/AgentAvatar';
import { ReasonAssignedDto } from 'utils/domain/extended/reasonAssignedDto';
import { serviceRequests } from 'services';
import { PresenceViewBuilder } from './PresenceViewBuilder';

type Props = {
  id: string;
  sip: string;
  queueRef?: string;
  name: string;
  availability: string;
  activity: string;
}

export const UserPresenceView = (props: Props) => {
  const { sip, queueRef, name, availability, activity, id } = props;
  const intl = useIntl();

  const [busyReasons, setBusyReasons] = useState([] as any);
  const [dndReasons, setDndReasons] = useState([] as any);
  const [awayReasons, setAwayReasons] = useState([] as any);
  const [isPresenceMenuOpen, setIsPresenceMenuOpen] = useState(false);
  const presenceViewBuilder = useMemo<PresenceViewBuilder>(() => new PresenceViewBuilder(), []);

  useEffect(() => {
    socketPresence.GetWrapUpCodes(0)
      .then((result: any) => {
        if (result) {
          preparePresences(result, [queueRef]);
        }
      });
  }, []);

  const getPresenceStatusText = (presenceState: string): string => GeneralHelper.getStatusText(presenceState, intl);

  const getSubMenuItems = (presenceState: PresenceState) => {
    switch (presenceState) {
      case PresenceState.Busy:
        return busyReasons;
      case PresenceState.DND:
        return dndReasons;
      case PresenceState.Away:
        return awayReasons;
      default:
        return [];
    }
  }

  const getPopupMenuItems = (presence: PresenceState) => {
    const reasons = getSubMenuItems(presence);

    const trigger = (<span
      className="presence-with-reasons-popup-trigger"
      onClick={() => sendPresenceChange(presence, getPresenceStatusText(presence))}>
      {getElementForPresence(presence)}
      {!!reasons.length && <PlayIcon size="smallest" />}
    </span>);

    return {
      content: <Popup
        position='after'
        align='top'
        offset={() => [-6, 17]}
        mouseLeaveDelay={150}
        trigger={trigger}
        content={{ content: <List selectable items={[...reasons]} /> }}
        on="hover"
      />,
      key: presence.toString(),
      className: "presence-with-reasons",
    };
  }

  const getElementForPresence = (presenceState: string, presenceText?: string): JSX.Element => {
    const presenceStatusElement = presenceViewBuilder.statusElements.get(presenceState);
    const procesedPresenceText = getPresenceStatusText(presenceText || presenceState);

    return !!presenceStatusElement
      ? presenceViewBuilder.presenceElement(presenceStatusElement, procesedPresenceText)
      : presenceViewBuilder.genericPresenceElement(procesedPresenceText, sendPresenceChange);
  }

  const buildPresenceItems = () => [
    {
      content: getElementForPresence(PresenceState.Online),
      onClick: () => sendPresenceChange(PresenceState.Online),
      key: PresenceState.Online.toString(),
    },
    getPopupMenuItems(PresenceState.Busy),
    getPopupMenuItems(PresenceState.DND),
    {
      content: getElementForPresence(PresenceState.BeRightBack),
      onClick: () => sendPresenceChange(PresenceState.BeRightBack),
      key: PresenceState.BeRightBack.toString(),
    },
    getPopupMenuItems(PresenceState.Away),
  ];

  const preparePresences = (result: ReasonAssignedDto[], queueRefs: (string | undefined)[]) => {
    if (!queueRefs || !queueRefs.length) {
      return;
    }

    const attachedQueues = !!sip
      ? []
      : queueRefs.map((x: any) => x.QueueRef);

    items.forEach(presenceItem => {
      let reasons: any;

      if (presenceItem.key?.toLowerCase() === PresenceState.Busy.toLowerCase()) {
        reasons = preparePresenceForStatus(result, attachedQueues, NotePresenceState.Busy, PresenceState.Busy, queueRef);

        setBusyReasons(reasons);
      }
      else if (presenceItem.key?.toLowerCase() === PresenceState.DND.toLowerCase()) {
        reasons = preparePresenceForStatus(result, attachedQueues, NotePresenceState.DND, PresenceState.DND, queueRef);

        setDndReasons(reasons);
      }
      else if (presenceItem.key?.toLowerCase() === PresenceState.Away.toLowerCase()) {
        reasons = preparePresenceForStatus(result, attachedQueues, NotePresenceState.Away, PresenceState.Away, queueRef);

        setAwayReasons(reasons);
      }
    });
  }

  const preparePresenceForStatus = (result: ReasonAssignedDto[], attachedQueues: string[], status: NotePresenceState, statusValue: PresenceState, queueId?: string) => {
    const notes: string[] = result
      .filter((x: any) => x.Presence === status && !x.IsDeleted && (!queueId ? attachedQueues.includes(x.QueueRef) : x.QueueRef === queueId))
      .map((x: any) => x.Name);

    return Array.from(new Set(notes)).map((note: string) => (
      {
        content: getElementForPresence(statusValue, note),
        onClick: (e: MouseEvent) => {
          sendPresenceChange(statusValue, getPresenceStatusText(note));
          e.stopPropagation();
        },
        key: note,
        className: 'presence-submenu-item'
      })
    );
  }

  const sendPresenceChange = (localPresence: PresenceState, note: string = "") => {
    const oldReason = activity || "";
    const newReason = note || "";

    setIsPresenceMenuOpen(prev => !prev);

    if (localPresence !== availability || oldReason !== newReason) {
      serviceRequests.ChangePresence(sip, localPresence, note || "", id, Lockr.get<string>(StorageKeys.UserObjectId));

      if (id === Lockr.get(StorageKeys.UserObjectId)) {
        socketPresence.select.next({ Availability: localPresence, Activity: note, Sip: sip, Id: id } as Presence);
      }
    }
  }

  const items = useMemo<PresenceItem[]>(() => buildPresenceItems(), [busyReasons, dndReasons, awayReasons]);

  return (
    <MenuButton
      align="start"
      className="presence-menu"
      menu={items}
      onOpenChange={(_, popupProps) => setIsPresenceMenuOpen(!!popupProps?.open)}
      open={isPresenceMenuOpen}
      trigger={<Flex
        vAlign="center"
        className="poiting-cursor"
      >
        <AgentAvatar
          styles={{ marginRight: '0.5rem', }}
          status={availability || activity}
          name={name}
        />
      </Flex>}
    />
  )
};

export default UserPresenceView;

import { Subject } from "rxjs";
import { ListenerOperations, logging } from "utils";
import { Controllers, GenericOperation } from "utils/enums-s3";
import { GeneralHelper } from "utils/helpers";
import GenericMediaData from "../../../Models/GenericMediaData";
import { Listener, listeners } from "services/io/listeners";
import { GenericNotificationData } from "containers/GenericView/Models/GenericNotificationData";
import { queuesService } from "services/queue";

class WaitingMediaService {
    private readonly logger = logging.getLogger('WaitingMediaService');

    private _mediaData: GenericMediaData[] = [];
    private _numberOfPages: number = 0;
    private _currentPage: number = 1;
    private isInited: boolean = false;

    private readonly _acceptGenericListener: Listener<GenericNotificationData> = listeners.createListener<GenericNotificationData>(ListenerOperations.AcceptGenericNotification);
    private readonly _offerGenericListener: Listener<GenericNotificationData> = listeners.createListener<GenericNotificationData>(ListenerOperations.OfferGenericNotification);
    private readonly _waitingGenericListener: Listener<GenericNotificationData> = listeners.createListener<GenericNotificationData>(ListenerOperations.WaitingGenericChange);

    public readonly RecordsPerPage: number = 10;
    public readonly dataUpdatedSubject: Subject<GenericMediaData[]> = new Subject();

    public get numberOfPages(): number {
        return this._numberOfPages;
    }

    public get currentPage(): number {
        return this._currentPage;
    }

    public get existsPaginator(): boolean {
        return this._numberOfPages > 1;
    }

    public get mediaData(): GenericMediaData[] {
        return this._mediaData
            .slice((this._currentPage - 1) * this.RecordsPerPage, this._currentPage * this.RecordsPerPage)
            .map(x => ({ ...x }));
    }

    public init() {
        if (this.isInited) {
            this.notifyDataChanged();
            return;
        }

        this.isInited = true;
        this.registerListeners();

        this.downloadData()
            .catch(() => {
                this.isInited = false;
            });

        this.registerSubscriptions();
    }

    public selectPage(newPage: number) {
        if (this._currentPage === newPage) {
            return;
        }

        this._currentPage = newPage;
        this.notifyDataChanged();
    }

    private downloadData(): Promise<void> {
        return GeneralHelper.invokeServiceCall('', GenericOperation.MediaWaiting, this.logger, Controllers.Generics)
            .then((data: GenericMediaData[]) => {
                this._mediaData = data;
                this._numberOfPages = Math.ceil(data.length / this.RecordsPerPage) || 1;

                if (this._numberOfPages <= this._currentPage) {
                    this._currentPage = this._numberOfPages;
                }

                this.notifyDataChanged();
            })
    }

    private notifyDataChanged() {
        this.dataUpdatedSubject.next(this.mediaData);
    }

    private registerListeners() {
        this._acceptGenericListener.received.subscribe((data: GenericNotificationData) => {
            const arrayWithoutAccepted = this._mediaData.filter(x => x.sessionId !== data.GenericId);

            if (arrayWithoutAccepted.length === this._mediaData.length) {
                return;
            }

            this._mediaData = arrayWithoutAccepted;
            this.notifyDataChanged();
        });

        this._offerGenericListener.received.subscribe(_ => this.downloadData());
        this._waitingGenericListener.received.subscribe(_ => this.downloadData());
    }

    private registerSubscriptions() {
        queuesService.queueMembershipAdded.subscribe((_: any) => this.downloadData());
        queuesService.queueMembershipRemoved.subscribe((_: any) => this.downloadData());
    }
}

export const waitingMediaService = new WaitingMediaService();

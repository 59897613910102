import { Controllers, HttpMethod, logging } from "utils";
import { ExtendedContact } from "utils/domain/extended/contact";
import { GeneralHelper } from "utils/helpers";
import { ExtendedContactOperations } from "utils/enums-s3";

export class ExtendedContactService {
    private readonly logger = logging.getLogger('ExtendedContactService');

    create(contact: ExtendedContact): Promise<ExtendedContact> {
        return this.invokeCall(contact, ExtendedContactOperations.Create, HttpMethod.Post);
    }

    update(contact: ExtendedContact): Promise<ExtendedContact> {
        return this.invokeCall(contact, ExtendedContactOperations.Update, HttpMethod.Post);
    }

    delete(contactId: string): Promise<ExtendedContact> {
        return this.invokeCall(contactId, ExtendedContactOperations.Delete, HttpMethod.Post);
    }

    invokeCall(data: any, operation: string, httpMethod: string): Promise<any> {
        return GeneralHelper.invokeServiceCall(data, operation, this.logger, Controllers.Contact, undefined, undefined, httpMethod);
    }
}

export const extendedContactService = new ExtendedContactService;
import React, { useEffect, useState } from 'react';
import { Input, SendIcon, Button, Flex, Alert } from '@fluentui/react-northstar';
import { useIntl } from 'react-intl';
import { webChatManager } from 'services/io/web-chat';
import { ChatHistoryDTO } from 'utils/domain/chathistoryDTO';
import { ChatMessageSource, ChatMessageType, ChatStatusType, StorageKeys } from 'utils';
import Lockr from 'lockr';
import { socketSocialMedia } from 'services/social-media';
import { CMMediaSessionMessageDTO, CMSourceType, CMStatusCode } from 'utils/domain/socialMedia/socialMediaSessionDTO';
import { GeneralHelper } from 'utils/helpers';
import { Subscription } from 'rxjs';

export const WebChatInputView = ({ sessionRef, chatStatus, isCmMedia }: any) => {
    const intl = useIntl();

    const [webchatMessage, setWebchatMessage] = useState('');
    const [emptyMessage, setEmptyMessage] = useState(false);
    const [disableChatInput, setDisableChatInput] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [chatIsTerminated, setChatIsTerminated] = useState(false);

    let subscriptionSendChatDetailsToClients: Subscription | null = null;
    let subscriptionOnChatEnded: Subscription | null = null;
    let subscriptionOnTransferToAgentInitiated: Subscription | null = null;
    let subscriptionOnSendChatTransferInvitationIgnored: Subscription | null = null;

    React.useEffect(() => {
        initialize();
        return () => componentWillUnmount();
    }, []);

    const initialize = () => {
        GeneralHelper.logCox(`in WebChatInputView.tsx, in initialize`);

        if (!isCmMedia) {
            subscriptionSendChatDetailsToClients?.unsubscribe();
            subscriptionSendChatDetailsToClients = webChatManager.onSendChatDetailsToClients.subscribe((obj: any) => {
                if (obj && sessionRef && obj.SessionId === sessionRef && obj.ChatStatusType === ChatStatusType.Terminated) {
                    setChatIsTerminated(true);
                }
            });

            subscriptionOnChatEnded?.unsubscribe();
            subscriptionOnChatEnded = webChatManager.onChatEnded.subscribe((obj: any) => {
                if (obj === sessionRef) {
                    setDisableChatInput(true);
                    setErrorMessage("");
                }
            });

            subscriptionOnTransferToAgentInitiated?.unsubscribe();
            subscriptionOnTransferToAgentInitiated = webChatManager.onTransferToAgentInitiated.subscribe((obj: any) => {
                if (obj && obj.SessionRef === sessionRef) {
                    setDisableChatInput(true);
                }
            });

            subscriptionOnSendChatTransferInvitationIgnored?.unsubscribe();
            subscriptionOnSendChatTransferInvitationIgnored = webChatManager.onSendChatTransferInvitationIgnored.subscribe((obj: any) => {
                if (obj && obj === sessionRef) {
                    setDisableChatInput(false);
                }
            });
        }
    }

    const componentWillUnmount = () => {
        GeneralHelper.logCox(`in WebChatInputView.tsx, in componentWillUnmount`);

        subscriptionSendChatDetailsToClients?.unsubscribe();
        subscriptionOnChatEnded?.unsubscribe();
        subscriptionOnTransferToAgentInitiated?.unsubscribe();
        subscriptionOnSendChatTransferInvitationIgnored?.unsubscribe();
    }

    useEffect(() => {
        emptyMessage ? setErrorMessage(intl.formatMessage({ id: "WebChatInputView.EmptyInputMessage" })) : setErrorMessage('');
    }, [emptyMessage]);

    useEffect(() => {
        (disableChatInput && (chatStatus === ChatStatusType.Terminated ||
            chatIsTerminated)) ? setErrorMessage(intl.formatMessage({ id: "WebChatInputView.ConversationClosedByClient" })) : setErrorMessage('');
    }, [disableChatInput]);

    useEffect(() => {
        setEmptyMessage(false);
    }, [webchatMessage]);

    useEffect(() => {
        setDisableChatInput(isChatInactive);
    }, [chatStatus]);

    useEffect(() => {
        setDisableChatInput(isChatInactive);
    }, [chatIsTerminated]);

    const isChatInactive = () => {
        if (chatIsTerminated) {
            setWebchatMessage('');
            return true;
        } else if (chatStatus === ChatStatusType.Accepted ||
            chatStatus === ChatStatusType.TransferToAgentInviteAccepted ||
            chatStatus === ChatStatusType.TransferToAgentInviteRejected) {
            return false;
        } else if (chatStatus === ChatStatusType.TransferToAgentInviteSent) {
            return true;
        }
        setWebchatMessage('');
        return true;
    }

    const sendMessage = () => {
        if (webchatMessage && webchatMessage.trim()) {
            setEmptyMessage(false);
            const requestData = createRequestData();
            webChatManager.sendChatMessage(requestData).then((response: any) => {
                webChatManager.onMessageSentFromAgent.next({ Message: requestData.Message, Date: requestData.Date, SessionRef: requestData.ChatSessionRef });
                setWebchatMessage('');
            }).catch(err => {
                console.log(err.message);
            });
        } else {
            setEmptyMessage(true);
        }
    }

    const sendCmMessage = () => {
        if (webchatMessage && webchatMessage.trim()) {
            setEmptyMessage(false);
            const requestData = createRequestData();
            const reference = `agent-${Math.random()}`;
            const cmMessage: CMMediaSessionMessageDTO = {
                StatusCode: CMStatusCode.Ok,
                SourceType: CMSourceType.FromAgent,
                MessageText: requestData.Message,
                FromName: Lockr.get<string>(StorageKeys.SIP),
                Reference: reference,
                Channel: "WhatsApp",
                SessionId: requestData.ChatSessionRef
            };
            socketSocialMedia.SendCmMediaMessage(cmMessage).catch(err => {
                console.log(err.message);
            });
            webChatManager.onMessageSentFromAgent.next({ Message: requestData.Message, Date: requestData.Date, SessionRef: requestData.ChatSessionRef, Id: reference });
            setWebchatMessage('');
        } else {
            setEmptyMessage(true);
        }
    }

    const createRequestData = () => {
        const chatHistory = new ChatHistoryDTO();

        chatHistory.Message = webchatMessage;
        chatHistory.MessageOwner = localStorage.getItem(StorageKeys.UPN) || '';
        chatHistory.ChatSessionRef = sessionRef;
        chatHistory.AgentSIP = Lockr.get<string>(StorageKeys.SIP);
        chatHistory.Source = ChatMessageSource.Agent;
        chatHistory.Type = ChatMessageType.Text;
        chatHistory.Date = new Date();

        return chatHistory;
    }

    const handleKeyPress = (e: any) => {
        if (e.keyCode === 13 || e.which === 13) {
            isCmMedia ? sendCmMessage() : sendMessage();
        }
    };

    return (
        <div>
            {errorMessage &&
                <Alert
                    styles={{ backgroundColor: "#484644", color: "#fff", paddingLeft: "12px", fontSize: "smaller" }}
                    content={errorMessage}
                />
            }
            <Input
                disabled={disableChatInput}
                fluid
                placeholder={intl.formatMessage({ id: "WebChatInputView.InputPlaceholder" })}
                onChange={(e) => setWebchatMessage((e.target as HTMLInputElement).value)}
                onKeyPress={(e) => handleKeyPress(e)}
                value={webchatMessage}
            />
            <Flex
                gap="gap.medium"
                space="between"
            >
                <span>&nbsp;</span>
                <Button
                    disabled={disableChatInput}
                    icon={<SendIcon outline size="large" />}
                    iconOnly
                    text
                    onClick={isCmMedia ? sendCmMessage : sendMessage}
                />
            </Flex>
        </div>
    );
};

export default WebChatInputView;

import { useIntl } from "react-intl";
import { Alert, Button, CallEndIcon, CallIcon, Divider, Flex, GeofenceArrivesIcon, Header, ParticipantRemoveIcon, PhoneArrowIcon, Segment, Text, Tooltip, UserFriendsIcon } from '@fluentui/react-northstar';
import React, { useState } from 'react';
import { Subscription } from 'rxjs';
import { customerJourneyManager } from 'services/io/customer-journey';
import { JourneyCustomerConversationAction } from 'utils';
import { HistoryCallDTO, IJourneyCustomerSessionDTO, JourneyCustomerConversationDetailDTO } from 'utils/domain/customerJourneyModels';
import '../../../containers/MediaView/AgentView/CallHistory/CallHistory.scss';
import CustomerJourneyNotesInputView from '../InteractionNotes/InteractionNotesInputView';
import CustomerJourneyNotesView from '../InteractionNotes/InteractionNotesView';
import './InteractionDetailView.scss';
import { GeneralHelper } from "utils/helpers";

type ConversationDetailProps = {
    CurrentConversation?: IJourneyCustomerSessionDTO,
    SelectedConversation?: IJourneyCustomerSessionDTO,
}

/**
 * This constant shows the Detailed information of a conversation in the Customer Journey system.
 * @param param0 
 * @returns 
 */
export const ConversationDetailView: React.FC<ConversationDetailProps> = ({ CurrentConversation, SelectedConversation }) => {
    const intl = useIntl();

    let listenerCurrentCurrentConversationChanged: Subscription | null = null;

    var transferConversationActionTypes: number[] =
        [JourneyCustomerConversationAction.CallColdTransferred,
        JourneyCustomerConversationAction.CallWarmTransferInitiated,
        JourneyCustomerConversationAction.CallWarmTransferCancelled,
        JourneyCustomerConversationAction.CallWarmTransferCompleted];

    const [currentConversation, setCurrentConversation] = useState(CurrentConversation);
    const [selectedConversation, setSelectedConversation] = useState(SelectedConversation);
    const [activeConversation, setActiveConversation] = useState(CurrentConversation);

    React.useEffect(() => {
        initialize();
        console.log(activeConversation);
        return () => componentWillUnmount();
    }, []);

    React.useEffect(() => {
        updateCurrentConversation(CurrentConversation);
    }, [CurrentConversation]);

    React.useEffect(() => {
        updateSelectedConversation(SelectedConversation);
    }, [SelectedConversation]);

    const componentWillUnmount = () => {
        listenerCurrentCurrentConversationChanged?.unsubscribe();
    }

    const initialize = () => {
        GeneralHelper.logCox("Initializing conversation details component");

        listenerCurrentCurrentConversationChanged = customerJourneyManager.currentConversation.subscribe((response: IJourneyCustomerSessionDTO) => {
            GeneralHelper.logCox("Received updated conversation in detailview..");
            console.log(response);
            updateCurrentConversation(response);
        });
    }

    const updateCurrentConversation = (conversation?: IJourneyCustomerSessionDTO) => {
        setActiveConversation(conversation);
        setCurrentConversation(conversation);
    }

    const updateSelectedConversation = (conversation?: IJourneyCustomerSessionDTO) => {
        console.log("updating selected conversatiopn..");
        console.log(conversation);
        setActiveConversation(conversation);
        setSelectedConversation(conversation);
    }

    const getActiveConversationTimeContent = function () {
        if (!activeConversation) {
            return '';
        }
        let dateAndTime = "";
        if (activeConversation.JourneyCustomerConversationDTO) {
            dateAndTime = customerJourneyManager.getDateTimeFormatted(activeConversation.JourneyCustomerConversationDTO.Date);
        }
        const result = `${activeConversation.ConversationTypeString}(${dateAndTime})`;
        return result;
    }

    const getTextTagStyle = function (siteVariables: any) {
        return {
            color: siteVariables.colorScheme.default.foreground,
            backgroundColor: siteVariables.colorScheme.default.background2,
            width: "auto",
            // minWidth: "60%",
            left: "15%",
            position: "relative",
            borderRadius: "5px",

        };
    }

    const CommunicationMediumIcon =(data: JourneyCustomerConversationDetailDTO, isOutBound: boolean) => {
        var icon: any;

        if (isOutBound) {
            icon = <img alt='Outgoing Call Icon' className='historical-convo-icon outgoing-call-icon' />
        } else {
            if (data.IsHandled) {
                icon =  <img alt='Incoming Call Icon' className='historical-convo-icon incoming-call-icon' />
            } else {
                icon = <img alt='Missed Call Icon' className='historical-convo-icon missed-call-icon' />
            }
        }

        return icon;
    }

    const TransferTypeIcon = (data: JourneyCustomerConversationAction) => {
        if (data !== undefined) {
            switch (data) {
                case JourneyCustomerConversationAction.CallColdTransferred: 
                    return (<PhoneArrowIcon size="large" className="timeline-icon" />);
                case JourneyCustomerConversationAction.CallWarmTransferCompleted: 
                    return (<UserFriendsIcon size="large" className="timeline-icon" />);
                case JourneyCustomerConversationAction.CallWarmTransferCancelled: 
                    return (<ParticipantRemoveIcon size="large" className="timeline-icon" />);
                default: return (<PhoneArrowIcon size="large" className="timeline-icon" />);
            }
        }
        return (<UserFriendsIcon size="large" styles={{paddingLeft: "2%"}} />);
    }

    const TransferStepText = (data: JourneyCustomerConversationDetailDTO) => {
        const messagePrefix = "CustomerJourney.InteractionDetails";
        var transferMessage = "TransferAttempt";
        if (data === undefined || data.ConversationAction === undefined) {
            return intl.formatMessage({ id: `${messagePrefix}.${transferMessage}`});
        }
        switch (data.ConversationAction) {
            case JourneyCustomerConversationAction.CallColdTransferred: 
                return (`${intl.formatMessage({ id: `${messagePrefix}.ColdTransferTo`})} ${data.TransferDestination}`);
            case JourneyCustomerConversationAction.CallWarmTransferCompleted: 
                return (`${intl.formatMessage({ id: `${messagePrefix}.WarmTransferTo`})} ${data.TransferDestination}`);
            case JourneyCustomerConversationAction.CallWarmTransferCancelled: 
                transferMessage = data.TransferAttemptCount > 1 ? "TransferAttemptsUnknown" : "TranserAttemptUnknown";
                return (`${data.TransferAttemptCount} ${intl.formatMessage({ id: `${messagePrefix}.${transferMessage}`})}`);
            default: return (intl.formatMessage({ id: `${messagePrefix}.${transferMessage}`}));
        }
    }

    const QueueItem = (data: JourneyCustomerConversationDetailDTO) => {
        return (
        <Flex className="timeline-item">
            <Flex className="timeline-item-content">
                <Flex className="testTag" space="between" vAlign="center" styles={({ theme: { siteVariables } }) => {
                    return getTextTagStyle(siteVariables);
                }}>
                    <Flex column padding="padding.medium">
                        <Flex gap="gap.smaller" styles={{ marginBottom: 0 }} vAlign="center">
                            <GeofenceArrivesIcon size="large" className="timeline-icon" />
                            <Flex.Item grow>
                                <Text content={`${intl.formatMessage({ id: "CustomerJourney.InteractionDetails.QueueEntered"})} ${data.Queue.Name}`} weight="bold" size="medium" styles={{ paddingLeft: "30px" }} />
                            </Flex.Item>
                        </Flex>
                    </Flex>
                </Flex>
                <span className="circle" />
            </Flex>
        </Flex>)
    }

    const CallOfferedItem = (data: JourneyCustomerConversationDetailDTO) => {
        if (!data.Agent || !data.Queue) {
            return undefined;
        }
        return (
        <Flex className="timeline-item">
            <Flex className="timeline-item-content">
                <Flex className="testTag" space="between" vAlign="center" styles={({ theme: { siteVariables } }) => {
                    return getTextTagStyle(siteVariables);
                }}>
                    <Flex column padding="padding.medium">
                        <Flex gap="gap.smaller" styles={{ marginBottom: 0 }} vAlign="center">
                            <Flex className="timeline-icon">
                                <img alt='Missed Call Icon' className='historical-convo-icon missed-call-icon' />
                            </Flex>
                            <Flex.Item grow>
                                <Text content={`${intl.formatMessage({ id: "CustomerJourney.InteractionDetails.CallOfferedTo"})} ${data.Agent.FullName}`} weight="bold" size="medium" styles={{ paddingLeft: "30px" }} />
                            </Flex.Item>
                        </Flex>
                    </Flex>
                </Flex>
                <span className="circle" />
            </Flex>
        </Flex>)
    }

    const CallAnsweredItem = (data: JourneyCustomerConversationDetailDTO, IsOutBound: boolean) => {
        if (!data.Agent) {
            return undefined;
        }
        return (
        <Flex className="timeline-item">
            <Flex className="timeline-item-content">
                <Flex className="testTag" space="between" vAlign="center" styles={({ theme: { siteVariables } }) => {
                    return getTextTagStyle(siteVariables);
                }}>
                    <Flex column padding="padding.medium">
                        <Flex gap="gap.smaller" styles={{ marginBottom: 0 }} vAlign="center">
                            <Flex className="timeline-icon">
                                {CommunicationMediumIcon(data, IsOutBound)} 
                            </Flex>
                            <Flex.Item grow>
                                <Text content={`${intl.formatMessage({ id: "CustomerJourney.InteractionDetails.CallAnsweredBy"})} ${(IsOutBound ? customerJourneyManager.currentCustomer.value.getFullName : data.Agent.FullName)}`} weight="bold" size="medium" styles={{ paddingLeft: "30px" }} />
                            </Flex.Item>
                        </Flex>
                    </Flex>
                </Flex>
                <span className="circle" />
            </Flex>
        </Flex>)
    }

    const TransferItem = (data: JourneyCustomerConversationDetailDTO) => {
        if (data.Agent === undefined || data.Queue === undefined) {
            return undefined;
        }
        return (
        <Flex className="timeline-item">
            <Flex className="timeline-item-content">
                <Flex className="testTag" space="between" vAlign="center" styles={({ theme: { siteVariables } }) => {
                    return getTextTagStyle(siteVariables);
                }}>
                    <Flex column padding="padding.medium">
                        <Flex gap="gap.smaller" styles={{ marginBottom: 0 }} vAlign="center">
                            {TransferTypeIcon(data.ConversationAction)}
                            <Flex.Item grow>
                                <Text content={TransferStepText(data)} weight="bold" size="medium" styles={{ paddingLeft: "30px" }} />
                            </Flex.Item>
                        </Flex>
                    </Flex>
                </Flex>
                <span className="circle" />
            </Flex>
        </Flex>)
    }

    const GetTimeline = (data: IJourneyCustomerSessionDTO) => {
        return (
        <Flex className="timeline-container" column gap="gap.small">
                <Flex>
                    <Flex className="start-end-container" styles={({theme: { siteVariables } }) => ({backgroundColor: siteVariables.colorScheme.default.background2})}>
                        <Tooltip pointing={true} trigger={<CallIcon size="largest" styles={({ theme: { siteVariables } }) => ({color: siteVariables.colorScheme.green.foreground})} />}
                            content={`${intl.formatMessage({ id: "CustomerJourney.InteractionDetails.CallReceivedAt"})} ${customerJourneyManager.getDateTimeFormatted(data.JourneyCustomerConversationDTO.Date)}`} align="start" position="above" />
                    </Flex>
                </Flex>
                {data.JourneyCustomerConversationDTO.ContactHistory.map(function(object, i){
                    if (transferConversationActionTypes.indexOf(object.ConversationAction) > -1) {
                        return TransferItem(object);
                    }
                    switch (object.ConversationAction) {
                        case JourneyCustomerConversationAction.QueueEntered: 
                            return QueueItem(object);
                        case JourneyCustomerConversationAction.CallAnswered: 
                            return CallAnsweredItem(object, data.JourneyCustomerConversationDTO.IsOutBound);
                        case JourneyCustomerConversationAction.CallOffered: 
                            return CallOfferedItem(object);
                        default: return undefined;
                    }
                })}
                <Flex>
                    <Flex className="start-end-container" styles={({theme: { siteVariables } }) => ({backgroundColor: siteVariables.colorScheme.default.background2})}>
                        <Tooltip pointing={true} trigger={<CallEndIcon size="largest" styles={({ theme: { siteVariables } }) => ({ color: siteVariables.colorScheme.red.foreground })} />}
                            content={`${intl.formatMessage({ id: "CustomerJourney.InteractionDetails.CallEndedAt"})} ${customerJourneyManager.getDateTimeFormatted(data.JourneyCustomerConversationDTO.Date)}`} align="start" position="above" />
                    </Flex>
                </Flex>
        </Flex>)
    }

    return (
        <Flex column space="between" styles={({ theme: { siteVariables } }) => ({
            backgroundColor: siteVariables.colorScheme.default.background,
            color: siteVariables.colorScheme.default.foreground,
            overflow: "scroll"
        })}>
            <Flex gap="gap.small" padding="padding.medium">
                <Flex.Item grow>
                    <Flex column space="between">
                        <Header as="h2" content={intl.formatMessage({ id: "CustomerJourney.InteractionDetails.Title"})} styles={({ theme: { siteVariables } }) => ({
                            color: siteVariables.colorScheme.brand.foreground,
                            margin: "0px"
                        })} />
                        {activeConversation !== undefined && <Text weight="bold" content={getActiveConversationTimeContent()} />}
                    </Flex>
                </Flex.Item>
            </Flex>
            <Divider size={3} />
            <Flex.Item grow>
                <Flex space="between">
                    {(activeConversation !== undefined || currentConversation !== undefined || selectedConversation !== undefined) &&
                        <Flex gap="gap.small" styles={{ width: "100%" }}>
                            <Button fluid content={intl.formatMessage({ id: "CustomerJourney.InteractionDetails.CurrentInteraction"})} primary={(activeConversation === undefined || activeConversation?.ID === currentConversation?.ID ? true : false)} onClick={() => { (setActiveConversation(currentConversation)) }} />
                            <Button fluid content={intl.formatMessage({ id: "CustomerJourney.InteractionDetails.SelectedInteraction"})} primary={((activeConversation !== undefined && selectedConversation !== undefined) && activeConversation?.ID === selectedConversation?.ID ? true : false)} onClick={() => { (selectedConversation !== undefined && setActiveConversation(selectedConversation)) }} />
                        </Flex>}
                </Flex>
            </Flex.Item>
            <Flex column styles={{ 
                // maxHeight: "65vh",
                overflow: "scroll" }}>
                {(activeConversation === undefined) && <Alert info styles={{ alignSelf: "center", width: "80%", textAlign: "center", marginTop: "2px" }} content={intl.formatMessage({ id: "CustomerJourney.InteractionDetails.NoInteraction"})} />}
                {(activeConversation !== undefined) &&
                    <Flex column>
                        {(activeConversation !== undefined && activeConversation.JourneyCustomerConversationDTO !== undefined) && <Divider size={1} />}
                        <Flex column
                            styles={({ theme: { siteVariables } }) => ({
                                color: siteVariables.colorScheme.default.foreground,
                                backgroundColor: siteVariables.colorScheme.default.background,
                            })}
                            className="web-chat-message-container"
                        >
                            <Segment className="messages-segment">
                                <CustomerJourneyNotesView SessionId={activeConversation.SessionID} />
                            </Segment>
                            <Segment className="input-segment">
                                <CustomerJourneyNotesInputView SessionId={activeConversation.SessionID} />
                            </Segment>
                        </Flex>
                        {(activeConversation !== undefined && activeConversation instanceof HistoryCallDTO) && <Divider size={1} />}
                        {(activeConversation !== undefined && activeConversation instanceof HistoryCallDTO) &&
                            <Flex column styles={{ marginTop: "10px !important" }}>
                                <Flex gap="gap.small" styles={{ marginBottom: 0 }}>
                                    <Flex.Item grow>
                                        <Text content={intl.formatMessage({ id: "CustomerJourney.NotesView.Notes"})} weight="bold" size="medium" styles={{ paddingLeft: "30px" }} />
                                    </Flex.Item>
                                </Flex>
                                <Flex styles={{ paddingTop: 0 }}>
                                    <Flex.Item styles={{ marginBottom: 0 }}>
                                        <Text content={((activeConversation !== undefined && activeConversation.JourneyNotes != null) ? activeConversation.JourneyNotes : intl.formatMessage({ id: "CustomerJourney.NotesView.NoNotes"}))} size="medium" styles={{ paddingLeft: "30px" }} />
                                    </Flex.Item>
                                </Flex>
                            </Flex>}
                        {/* <Divider size={1} /> */}
                        {(activeConversation !== undefined && activeConversation.JourneyCustomerConversationDTO !== undefined) &&
                            <Flex column styles={{ width: "100%" }}>
                                {/* <Flex gap="gap.small">
                                    <img alt='Incoming Call Icon' width="15" height="15" className='incoming-call-icon' />
                                </Flex>
                                <Flex gap="gap.smaller">
                                    <img alt='Outgoing Call Icon' width="15" height="15" className='outgoing-call-icon' />
                                </Flex> */}
                                    {GetTimeline(activeConversation)}
                            </Flex>}
                    </Flex>}
            </Flex>
        </Flex>
    );
}
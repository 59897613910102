import React, { useState, useRef } from 'react';
import { Flex, Text, Dropdown, Checkbox, Input } from '@fluentui/react-northstar';
import { Subscription } from 'rxjs';
import Lockr from 'lockr';
import { StorageKeys } from 'utils';
import { Paginator } from 'containers';
import { socketQueue } from 'services/queue';
import './SupervisorView.scss';

import AdvancedTable, { stringCellComparator } from 'components/AdvancedTable';
import { useIntl } from 'react-intl';
import { SupervisorProcessDTO } from 'utils/domain/supervisor/SupervisorProcessDTO';
import { QueueSupervisorDTO } from 'utils/domain/supervisor/QueueSupervisorDTO';
import { SupervisorQueueAgentDTO } from 'utils/domain/supervisor/SupervisorQueueAgentDTO';
import { UpdateQueueAgentDTO } from 'utils/domain/supervisor/UpdateQueueAgentDTO';
import { SupervisorQueueAgentModel } from 'utils/domain/supervisor/SupervisorQueueAgentModel';
import { supervisorManager } from 'services/supervisor/supervisor-manager';
import PresenceView from '../../PresenceView';
import AgentAvatar from 'components/AgentAvatar';

import Timer from 'components/Timer';
import { Presence } from 'utils/domain/presence';
import { GeneralHelper } from 'utils/helpers';
import { socketContacts } from 'services/contacts';
import { UserQueueRequestDto } from 'utils/domain/queueUserDTO';
import { AgentStatusChangeNotificationDto } from 'utils/domain/supervisor/AgentStatusChangeNotificationDto';


enum SupervisorSortValues {
    Agent = "Agent",
    Score = "Score",
    Reason = "Reason",
    Skill = "Skill",
    Desire = "Desire",
    IsJoined = "IsJoined",
    HasClient = "HasClient"
}

export const SupervisorView = () => {
    const [supervisorInfo, setSupervisorInfo] = useState<SupervisorProcessDTO>();
    const supervisorModelList = useRef<SupervisorQueueAgentModel[]>([]);
    const [queueDropdownElements, setQueueDropdownElements] = useState<any>([]);
    const [supervisorInfoShown, setSupervisorInfoShown] = useState<any>([]);
    const [onlyOnline, setOnlyOnline] = useState<boolean>(Lockr.get<boolean>(StorageKeys.OnlyOnlineAgents) ?? false);
    const [offerCallsToAgentsWithoutClient, setOfferCallsToAgentsWithoutClient] = useState<boolean>(false);

    const selectedQueueId = useRef(0);
    const selectedQueueName = useRef("");
    const sortColumn = useRef("");
    const sortDirection = useRef(-1);

    const currentPage = useRef(1);
    const numberOfPages = useRef(1);
    const paginatorExists = useRef(false);
    const numberOfRowsPerPage = 20;

    const desireMaxValue = 100;
    const smallestGrowClassValue = "smallest-grow";

    const intl = useIntl();

    let subscriptionRoleChange: Subscription | null = null;
    let subscriptionQueueChange: Subscription | null = null;
    let subscriptionPresence: Subscription | null = null;
    let subscriptionAgentHasClientChanged: Subscription | null = null;

    React.useEffect(() => {
        initialize();

        return () => {
            componentWillUnmount();
        }
    }, []);

    React.useEffect(() => {
        mapObjectsToModels();
    }, [supervisorInfo, onlyOnline]);

    const componentWillUnmount = () => {
        GeneralHelper.logCox(`in SupervisorView.tsx, in componentWillUnmount`);

        subscriptionRoleChange?.unsubscribe();
        subscriptionQueueChange?.unsubscribe();
        subscriptionPresence?.unsubscribe();
        subscriptionAgentHasClientChanged?.unsubscribe();
    };

    const initialize = () => {
        GeneralHelper.logCox(`in SupervisorView.tsx, in initialize`);
        if (!supervisorInfo) {
            getSupervisorInfo();
        }

        subscriptionRoleChange?.unsubscribe();
        subscriptionRoleChange = supervisorManager.onUserRoleChange.subscribe(() => {
            getSupervisorInfo();
        });

        subscriptionQueueChange?.unsubscribe();
        subscriptionQueueChange = socketQueue.subjectQueueChangeNotification.subscribe((obj: UserQueueRequestDto) => {
            if (obj.QueueRef === selectedQueueId.current) {
                supervisorManager.checkIsUserSupervisor();
            }
            const userSip = Lockr.get(StorageKeys.SIP) as string;
            const supervisors = obj.SupervisorSips?.length ? obj.SupervisorSips.map(el => {
                return el.toLowerCase();
            }) : [];

            if (!(obj.ChangedBySip === userSip && obj.ChangedBySupervisor) && supervisors?.includes(userSip?.toLowerCase())) {
                getSupervisorInfo();
            }
        });

        subscriptionPresence?.unsubscribe();
        subscriptionPresence = socketContacts.notifyUpdatedPresenceState.subscribe((userPresence: Presence) => {
            GeneralHelper.logCox(`in SupervisorView.tsx, in subscriptionPresence`);
            setSupervisorInfo((supervisorProcessDto: SupervisorProcessDTO | undefined) => {
                if (!supervisorProcessDto) {
                    return undefined;
                }

                const agentDtosForSip = supervisorProcessDto.Agents.filter((ref: SupervisorQueueAgentDTO) => ((ref.SIP || '').toLowerCase()) === (userPresence.Id?.toLowerCase()));
                agentDtosForSip.forEach((supervisorQueueAgentDto: SupervisorQueueAgentDTO) => {
                    supervisorQueueAgentDto.Availability = userPresence.Availability;
                    supervisorQueueAgentDto.Reason = userPresence.Activity || "";
                    supervisorQueueAgentDto.StatusChangeTime = new Date().toISOString();
                });

                const supervisorProcessDtoCopy = new SupervisorProcessDTO();
                supervisorProcessDtoCopy.Agents = supervisorProcessDto.Agents;
                supervisorProcessDtoCopy.Queues = supervisorProcessDto.Queues;

                return supervisorProcessDtoCopy;
            });
        });

        subscriptionAgentHasClientChanged?.unsubscribe();
        subscriptionAgentHasClientChanged = supervisorManager.listenerAgentHasClientChanged.received.subscribe((obj: AgentStatusChangeNotificationDto) => {
            setSupervisorInfo((supervisorProcessDto: SupervisorProcessDTO | undefined) => {
                if (!supervisorProcessDto) {
                    return undefined;
                }

                const agents = supervisorProcessDto.Agents.filter(x => x.SIP === obj.Sip);
                agents.forEach((supervisorQueueAgentDto: SupervisorQueueAgentDTO) => {
                    supervisorQueueAgentDto.HasClient = obj.HasClient;
                });

                const supervisorProcessDtoCopy = new SupervisorProcessDTO();
                supervisorProcessDtoCopy.Agents = supervisorProcessDto.Agents;
                supervisorProcessDtoCopy.Queues = supervisorProcessDto.Queues;

                return supervisorProcessDtoCopy;
            });
        });
    };

    const getSupervisorInfo = () => {
        supervisorManager.getAgentsBySupervisorRef(Lockr.get<number>(StorageKeys.UserId)).then((obj: SupervisorProcessDTO) => {
            if (obj) {
                mapQueuesForDropdown(obj);

                if (obj.Queues && obj.Queues.length > 0 && selectedQueueId.current === 0) {
                    const sortedQueues = [...obj.Queues].sort(dynamicSort("QueueName"));

                    Lockr.set(StorageKeys.SupervisorQueueSelected, sortedQueues[0].QueueRef);

                    selectedQueueId.current = sortedQueues[0].QueueRef;
                    selectedQueueName.current = sortedQueues[0].QueueName;

                }

                const offerCallsWithoutClient = supervisorManager.doesQueueOffersCallsWithoutClient(selectedQueueId.current);
                setOfferCallsToAgentsWithoutClient(offerCallsWithoutClient);
                setSupervisorInfo(obj);
            }
        });
    }

    const mapObjectsToModels = () => {
        if (supervisorInfo) {
            const modelList: SupervisorQueueAgentModel[] = [];
            supervisorInfo.Agents.forEach((element: SupervisorQueueAgentDTO) => {
                modelList.push(mapModel(element))
            })

            supervisorModelList.current = modelList;

            mapSupervisorObjectsForTable(Lockr.get<number>(StorageKeys.SupervisorQueueSelected));
        }
    }

    const mapModel = (agent: SupervisorQueueAgentDTO) => {
        return {
            ID: agent.ID,
            QueueRef: agent.QueueRef,
            SIP: agent.SIP,
            Agent: agent.Name,
            Knowledge: agent.WeightKnowledge,
            IsMember: agent.IsMember,
            Desire: agent.Desire,
            Reason: agent.Reason,
            Availability: agent.Availability,
            IsJoined: agent.IsAttached,
            StatusChangeTime: agent.StatusChangeTime,
            Skill: agent.Skill,
            Score: agent.WeightKnowledge * agent.Desire,
            HasClient: agent.HasClient,

        } as SupervisorQueueAgentModel;
    }

    const mapSupervisorObjectsForTable = (selectedQueue: any) => {
        if (supervisorModelList) {
            const filteredModels = filterModels(supervisorModelList.current, selectedQueue);
            const sortedModels = sortModels(filteredModels, sortColumn.current, sortDirection.current);
            const filteredAndSortedModels = mapModelElementsToTable(sortedModels);

            initializeNumberOfPages(filteredAndSortedModels.length);

            if (filteredAndSortedModels.length > numberOfRowsPerPage) {
                paginatorExists.current = true;
                setSupervisorInfoShown(filteredAndSortedModels.slice((currentPage.current - 1) * numberOfRowsPerPage, currentPage.current * numberOfRowsPerPage));
            }
            else {
                paginatorExists.current = false;
                setSupervisorInfoShown(filteredAndSortedModels);
            }
        }
    }

    const filterModels = (modelList: SupervisorQueueAgentModel[], selectedQueue: any) => {
        let shownElements = modelList.filter((ref: SupervisorQueueAgentModel) => ref.QueueRef === selectedQueue && ref.IsMember === true);
        if (onlyOnline) {
            shownElements = shownElements.filter((ref: SupervisorQueueAgentModel) => ref.Availability !== "Offline");
        }
        const objectsForTable: any = [];
        shownElements.forEach((element: SupervisorQueueAgentModel) => {
            objectsForTable.push(element)
        })
        return objectsForTable;
    }

    const mapModelElementsToTable = (modelList: SupervisorQueueAgentModel[]) => {
        const objectsForTable: any = [];
        modelList.forEach((element: SupervisorQueueAgentModel) => {
            objectsForTable.push(mapItemToTable(element))
        })
        return objectsForTable
    }

    const mapItemToTable = (obj: SupervisorQueueAgentModel) => {
        return {
            key: obj.ID,
            queueid: obj.QueueRef,
            sip: obj.SIP,
            items: [
                { content: getAvatarAndName(obj), key: obj.ID?.toString() + '-0', className: "big-grow" },
                { content: obj.Availability !== "Offline" ? <Timer startDate={obj.StatusChangeTime}></Timer> : "", key: obj.ID?.toString() + '-1', className: "smaller-grow" },
                { content: obj.Score, key: obj.ID?.toString() + '-2', className: smallestGrowClassValue },
                { content: obj.Reason, key: obj.ID?.toString() + '-3', className: "big-grow" },
                {
                    content: obj.HasClient ? intl.formatMessage({ id: "SupervisorView.Yes" }) : intl.formatMessage({ id: "SupervisorView.No" }),
                    key: obj.ID?.toString() + '-4',
                    className: "small-grow",
                    sortColumn
                },
                { content: obj.Skill, key: obj.ID?.toString() + '-5', className: "big-grow" },
                {
                    content: <Input value={obj.Desire.toString()} className={obj.Desire.toString().length === 0 ? "invalid-value" : ""}
                        onInput={(e: any) => { onlyNumbersFilter(e, obj) }}
                        onChange={(e: any) => changeDesire(obj, e)}
                        onBlur={(e: any) => onBlurHandler(obj, e)} />, key: obj.ID?.toString() + '-6', className: smallestGrowClassValue
                },
                { content: <Checkbox checked={obj.IsJoined} onChange={() => { joinUnjoinAgent(obj) }} />, key: obj.ID?.toString() + '-7', className: smallestGrowClassValue },
            ]
        }
    }

    const onlyNumbersFilter = (e: any, agent: SupervisorQueueAgentModel) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');

        if (e.target.value.length > 1 && e.target.value.toString()[0] === '0') {
            e.target.value = e.target.value.length === 2 ? e.target.value.replace("0", "") : replaceWithEmpty(e.target.value);
        }

        while (e.target.value > desireMaxValue) {
            e.target.value = Math.trunc(e.target.value / 10);
        }

        setSupervisorInfo((supervisorProcessDto: SupervisorProcessDTO | undefined) => {
            if (!supervisorProcessDto) {
                return undefined;
            }

            const agentDtosForSip = supervisorProcessDto.Agents.filter(x => x.ID === agent.ID && x.QueueRef === agent.QueueRef);
            agentDtosForSip.forEach((supervisorQueueAgentDto: SupervisorQueueAgentDTO) => {
                if (e.target) {
                    supervisorQueueAgentDto.Desire = e.target.value;
                }
            });

            const supervisorProcessDtoCopy = new SupervisorProcessDTO();
            supervisorProcessDtoCopy.Agents = supervisorProcessDto.Agents;
            supervisorProcessDtoCopy.Queues = supervisorProcessDto.Queues;

            return supervisorProcessDtoCopy;
        });
    }

    const replaceWithEmpty = (str: string) => {
        let replaced = '';
        const regex = new RegExp(/^\s*0+/);
        replaced = str.replace(regex, el => {
            const { length } = el;
            return ''.repeat(length);
        });
        return replaced;
    };

    const onBlurHandler = (agent: SupervisorQueueAgentModel, e: any) => {
        if (e.target.value.length > 0) {
            sendNewDesire(agent, e.target.value);
        }
    }

    const changeDesire = (agent: SupervisorQueueAgentModel, e: any) => {
        if (e.target.value.length > 0 && e.keyCode === 13) {
            sendNewDesire(agent, e.target.value);
        }
    }

    const sendNewDesire = (agent: SupervisorQueueAgentModel, value: number) => {
        const updateAgent: UpdateQueueAgentDTO = {
            AgentRef: agent.ID || 0,
            IsMember: agent.IsMember || false,
            QueueRef: agent.QueueRef,
            Desire: value,
            ChangedBySupervisor: true,
            ChangedBySip: Lockr.get(StorageKeys.SIP)
        }
        supervisorManager.changeDesire(updateAgent);
    }

    const joinUnjoinAgent = (agent: SupervisorQueueAgentModel) => {
        if (agent.SIP) {
            socketQueue.clientQueuesChanged(agent.SIP, agent.QueueRef, !agent.IsJoined, true);

            setSupervisorInfo((supervisorProcessDto: SupervisorProcessDTO | undefined) => {
                if (!supervisorProcessDto) {
                    return undefined;
                }

                const agentDtosForSip = supervisorProcessDto.Agents.filter(x => x.ID === agent.ID && x.QueueRef === agent.QueueRef);
                agentDtosForSip.forEach((supervisorQueueAgentDto: SupervisorQueueAgentDTO) => {
                    supervisorQueueAgentDto.IsAttached = !agent.IsJoined;
                });

                const supervisorProcessDtoCopy = new SupervisorProcessDTO();
                supervisorProcessDtoCopy.Agents = supervisorProcessDto.Agents;
                supervisorProcessDtoCopy.Queues = supervisorProcessDto.Queues;

                return supervisorProcessDtoCopy;
            });
        }
    }

    const getAvatarAndName = (agent: SupervisorQueueAgentModel) => {
        return agent.Availability === "Offline" ? <Flex vAlign="center"><AgentAvatar styles={{ marginRight: '0.5rem', }} status={agent.Availability} name={agent.Agent} /></Flex> :
            <PresenceView sip={agent.SIP} id={undefined} queueRef={agent.QueueRef} name={agent.Agent} availability={agent.Availability} reason={agent.Reason} />
    }

    const columnsMembers = [
        {
            title: intl.formatMessage({ id: "SupervisorView.Agent" }), key: 'Agent',
            name: 'SupervisorViewAgentColumn', cellComparator: stringCellComparator, className: "big-grow"
        },
        {
            title: intl.formatMessage({ id: "SupervisorView.StatusTimer" }), key: 'StatusTimer',
            name: 'SupervisorStatusTimerColumn', cellComparator: stringCellComparator, className: "smaller-grow"
        },
        {
            title: intl.formatMessage({ id: "SupervisorView.Score" }), key: 'Score',
            name: 'SupervisorViewScoreColumn', cellComparator: stringCellComparator, className: smallestGrowClassValue
        },
        {
            title: intl.formatMessage({ id: "SupervisorView.Reason" }), key: 'Reason',
            name: 'SupervisorViewReasonColumn', cellComparator: stringCellComparator, className: "big-grow"
        },
        {
            title: intl.formatMessage({ id: "SupervisorView.HasClient" }), key: 'HasClient',
            name: 'SupervisorViewHasClientColumn', cellComparator: stringCellComparator, className: "small-grow"
        },
        {
            title: intl.formatMessage({ id: "SupervisorView.Skill" }), key: 'Skill',
            name: 'SupervisorViewSkillColumn', cellComparator: stringCellComparator, className: "big-grow"
        },
        {
            title: intl.formatMessage({ id: "SupervisorView.Desire" }), key: 'Desire',
            name: 'SupervisorViewDesireColumn', cellComparator: stringCellComparator, className: smallestGrowClassValue
        },
        {
            title: intl.formatMessage({ id: "SupervisorView.Joined" }), key: 'Joined',
            name: 'SupervisorViewIsJoinedColumn', cellComparator: stringCellComparator, className: smallestGrowClassValue
        },
    ];

    const mapQueuesForDropdown = (obj: SupervisorProcessDTO) => {
        GeneralHelper.logCox(`in mapQueuesForDropdown`);
        if (obj.Queues.length > 0) {
            const mappedQueues = obj.Queues.map((queue: QueueSupervisorDTO) => {
                return {
                    header: queue.QueueName,
                    item: queue,
                    title: queue.QueueName,
                    id: queue.QueueRef
                };
            });

            mappedQueues.sort(dynamicSort("header"));
            if (!mappedQueues.some(element => element.id === Lockr.get<number>(StorageKeys.SupervisorQueueSelected)) && mappedQueues.length > 0) {
                selectQueue(mappedQueues[0]);
            }

            setQueueDropdownElements(mappedQueues);
        }
    }

    const selectQueue = (data: any) => {
        Lockr.set(StorageKeys.SupervisorQueueSelected, data.id);

        selectedQueueName.current = data.header;
        selectedQueueId.current = data.id;
        const offerCallsWithoutClient = supervisorManager.doesQueueOffersCallsWithoutClient(data.id);
        setOfferCallsToAgentsWithoutClient(offerCallsWithoutClient);
        mapSupervisorObjectsForTable(data.id);
    }

    const sortHandler = (sortColumnValue: string, sortDirectionValue: number) => {
        sortColumn.current = sortColumnValue;
        sortDirection.current = sortDirectionValue;
        const filteredModels = filterModels(supervisorModelList.current, Lockr.get<number>(StorageKeys.SupervisorQueueSelected));
        const filteredAndSortedModels = sortModels(filteredModels, sortColumnValue, sortDirectionValue)
        setSupervisorInfoShown(mapModelElementsToTable(filteredAndSortedModels).slice((currentPage.current - 1) * 20, currentPage.current * 20))
    }

    const dynamicSort = (property: string) => {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return (a: any, b: any) => {
            let result: any;
            if (a[property] != null) {
                if (typeof a[property] == "string") {
                    result = (a[property]?.toLowerCase() < b[property]?.toLowerCase()) ? -1 : (a[property]?.toLowerCase() > b[property]?.toLowerCase()) ? 1 : 0;
                }
                else {
                    result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                }
            }
            else {
                return 0;
            }
            return result * sortOrder;
        }
    }

    const sortModels = (modelList: SupervisorQueueAgentModel[], sortColumnValue: string, sortDirectionValue: number) => {
        const sortColumn = getSortColumnValue(sortColumnValue) === "" ? SupervisorSortValues.Agent : getSortColumnValue(sortColumnValue);
        return modelList.sort(dynamicSort(sortDirectionValue === -1 ? sortColumn : "-" + sortColumn));
    }

    const getSortColumnValue = (sortColumnValue: string) => {
        switch (sortColumnValue) {
            case "SupervisorViewAgentColumn":
                return SupervisorSortValues.Agent;
            case "SupervisorViewScoreColumn":
                return SupervisorSortValues.Score;
            case "SupervisorViewReasonColumn":
                return SupervisorSortValues.Reason;
            case "SupervisorViewHasClientColumn":
                return SupervisorSortValues.HasClient;
            case "SupervisorViewSkillColumn":
                return SupervisorSortValues.Skill;
            case "SupervisorViewDesireColumn":
                return SupervisorSortValues.Desire;
            case "SupervisorViewIsJoinedColumn":
                return SupervisorSortValues.IsJoined;
            default:
                return "";
        }
    }

    const initializeNumberOfPages = (result: any) => {
        let number = 0;
        if (result % numberOfRowsPerPage === 0) {
            number = Math.floor(result / numberOfRowsPerPage);
        }
        else {
            number = Math.floor(result / numberOfRowsPerPage) + 1;
        }

        numberOfPages.current = number === 0 ? 1 : number;
    }

    const selectPage = (e: any) => {
        const filteredModels = filterModels(supervisorModelList.current, Lockr.get<number>(StorageKeys.SupervisorQueueSelected));
        const filteredAndSortedModels = sortModels(filteredModels, sortColumn.current, sortDirection.current)
        setSupervisorInfoShown(mapModelElementsToTable(filteredAndSortedModels).slice((e - 1) * numberOfRowsPerPage, e * numberOfRowsPerPage));
        currentPage.current = e;
    }

    let paginator: any = null;
    if (paginatorExists.current) {
        paginator = <Paginator
            currentPage={currentPage.current}
            totalPages={numberOfPages.current}
            onChange={selectPage}
        />
    }

    const onlyOnlineClicked = () => {
        Lockr.set(StorageKeys.OnlyOnlineAgents, !onlyOnline);
        setOnlyOnline(!onlyOnline);

    }

    return (
        <Flex column key="SupervisorViewKey">
            <Flex gap="gap.small" vAlign="center">
                {/* <ShiftActivityIcon /> */}
                <Text content={intl.formatMessage({ id: "SupervisorView.Header" })} weight="bold" />
                <Dropdown
                    items={queueDropdownElements}
                    placeholder={selectedQueueName.current}
                    inverted
                    onChange={(e, data) => { selectQueue(data.value); }}
                />
                <Checkbox label={intl.formatMessage({ id: "SupervisorCallsView.OnlyOnline" })}
                    checked={onlyOnline} onClick={onlyOnlineClicked} className="filter-checkbox" />
                <Checkbox label={intl.formatMessage({ id: "SupervisorCallsView.OfferCallsToAgentsWithoutClient" })}
                    checked={offerCallsToAgentsWithoutClient}
                    styles={{ "cursor": "default" }}
                />

            </Flex>

            <AdvancedTable columns={columnsMembers} rows={supervisorInfoShown} sortHandler={sortHandler} label="Supervisor" className="supervisor-table" />
            {paginator}
        </Flex>
    )
};

export default SupervisorView;

import React from 'react';
import { Flex, Segment, Text } from '@fluentui/react-northstar';
import { mgtAuth } from 'services';

export function MgtSignIn() {
    mgtAuth.StartSignIn();

    return (
        <Flex styles={({ theme: { siteVariables } }) => ({
            color: siteVariables.colorScheme.default.foreground1,
            backgroundColor: siteVariables.colorScheme.default.background1,
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            paddingBottom: '200px',
        })}
            column
        >
            <img src={process.env.PUBLIC_URL + '/logo192.png'} alt='cc4teams logo' width="192" />

            <Segment styles={({ theme: { siteVariables } }) => ({
                color: siteVariables.colorScheme.default.foreground,
                backgroundColor: siteVariables.colorScheme.default.background,
                width: '400px',
                padding: '30px',
                paddingBottom: '20px',
                marginBottom: '20px'
            })}
            >
                <Text important content="Signing in..." />
            </Segment>
        </Flex>
    );
}

export default MgtSignIn;

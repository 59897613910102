import { ServiceOperations, logging } from "utils";
import { Controllers } from "utils/enums-s3";
import { GeneralHelper } from "utils/helpers";

export interface MediaPickupObj {
    agentUserId: string,
    sessionId: string
}

export class GenericActionsService {
    private readonly logger = logging.getLogger('GenericTransfersService');
    
    pickupMedia(mediaPickup: MediaPickupObj): Promise<any[]> {
        return GeneralHelper.invokeServiceCall(mediaPickup, ServiceOperations.MediaPickup, this.logger, Controllers.GenericActions);
    }
}

const genericActionsService = new GenericActionsService();
export default genericActionsService;
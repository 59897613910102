import { socketAuth } from "services/auth";
import { AzureLoginType, Controllers, logging, ServiceOperations, StorageKeys } from "utils";
import { ApiGraphService } from ".";
import Lockr from 'lockr';
import { InvokeServiceArgs } from "services/io/interfaces";
import { ConfigurationHelper } from "utils/helpers";
import { serviceCall } from "services/io/service-call";

export class GraphService implements ApiGraphService {
    private readonly logger = logging.getLogger('SocketAuth');
    private readonly CalendarDefaultDaysInterval = 14;
    private readonly CalendarMaxEvents = 20;

    getUserProfilePicture(azureId: string) {
        const calledMethod = `https://graph.microsoft.com/beta/users/${azureId}/photo/$value`;
        return this.invokeGraphApiCall(calledMethod, false);
    }

    getUserCalendarView(azureId: string) {
        const startDateString = GraphService.getAgendaStartDateString();
        const endDateString = this.getAgendaEndDateString();
        const calledMethod = `/users/${azureId}/calendarView?$orderby=start/dateTime&startDateTime=${startDateString}&endDateTime=${endDateString}&$top=${this.CalendarMaxEvents}`;
        return this.invokeGraphApiCall(calledMethod, false);
    }

    getRefreshTokenAndRetryCall(callMethod: string, isDelegated: boolean) {
        return this.getGraphApiAccessToken(isDelegated).then((accessToken: string) => {
            return GraphService.refreshTokenAndRetryCall(accessToken, callMethod, isDelegated);
        })
    }

    getUserTimeZone(azureId: string) {
        const calledMethod = `https://graph.microsoft.com/v1.0/users/${azureId}/mailboxSettings/timeZone`;
        return this.invokeGraphApiCall(calledMethod, false);
    }

    getUserPresence(azureId: string) {
        const calledMethod = `https://graph.microsoft.com/v1.0/users/${azureId}/presence`;
        return this.invokeGraphApiCall(calledMethod, true);
    }

    private static getAgendaStartDateString() {
        const startDate = new Date();
        const startMonth = startDate.getMonth() + 1 < 10 ? `0${startDate.getMonth() + 1}` : `${startDate.getMonth() + 1}`;
        return `${startDate.getFullYear()}-${startMonth}-${startDate.getDate()}T00:00:00.0000000`;
    }

    private getAgendaEndDateString() {
        const startDate = new Date();
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + this.CalendarDefaultDaysInterval);
        const endMonth = endDate.getMonth() + 1 < 10 ? `0${endDate.getMonth() + 1}` : `${endDate.getMonth() + 1}`;
        return `${endDate.getFullYear()}-${endMonth}-${endDate.getDate()}T23:59:59.0000000`;
    }

    private handleFailedRequests(error: any, calledMethod: string, isDelegated: boolean): Promise<any> | null {
        if (error.statusCode === 403 || error.statusCode === 401 || error.statusCode === -1) {
            return this.getRefreshTokenAndRetryCall(calledMethod, isDelegated);
        }

        return null;
    }

    private static refreshTokenAndRetryCall(accessToken: string, method: string, isDelegated: boolean) {
        if (isDelegated) {
            Lockr.set(StorageKeys.TokenADDelegated, accessToken);
        } else {
            Lockr.set(StorageKeys.TokenAD, accessToken);
        }
        const refreshedClient = socketAuth.refreshGraphClient(accessToken, isDelegated);

        return refreshedClient?.api(method).get().catch(() => {
            return null;
        });
    }

    private static canAccessGraphApi() {
        const loginType = Lockr.get(StorageKeys.AzureADLoginType);

        return loginType !== AzureLoginType.None;
    }

    private getGraphApiAccessToken(isDelegated: boolean): Promise<string> {
        const idToken = Lockr.get(StorageKeys.TokenId);
        const sip = Lockr.get(StorageKeys.SIP);
        const userId = Lockr.get(StorageKeys.UserId);

        const requestObj = {
            IdToken: idToken,
            IsDelegated: isDelegated,
            Sip: sip,
            userId: userId
        }

        return this.invokeServiceCall(requestObj, ServiceOperations.GetGraphApiAccessToken);
    }

    private invokeServiceCall(data: any, operation: string, controller: string = Controllers.User, fetchApiUrl: string = ConfigurationHelper.gatewayApiUrl): Promise<any> {
        return new Promise((resolve, reject) => {
            const args: InvokeServiceArgs = {
                operation: operation,
                controller: controller,
                fetchApiUrl: fetchApiUrl,
                requestData: data,
                responseHandler: {
                    success: (result) => {
                        resolve(result);
                    },
                    error: (err) => {
                        logging.errorHandler.next("ErrorMessage.Offline");
                        this.logger.error(err);
                        reject(err);
                    }
                }
            };

            serviceCall.invokeService(args);
        });
    }

    private invokeGraphApiCall(url: string, isDelegated: boolean): Promise<any> | null | undefined {
        if (!GraphService.canAccessGraphApi()) {
            return null;
        }

        const client = socketAuth.getGraphApiClient(isDelegated);

        return client?.api(url).get()
            .catch((error: any) => {
                return this.handleFailedRequests(error, url, isDelegated);
            });
    }
}

import { Subject } from "rxjs";
import { socketAudio } from "services/calling";
import { StorageKeys } from "utils";
import { CallSessionDTO } from "utils/domain/callSessionDTO";
import { GeneralHelper } from "utils/helpers";
import Lockr from "lockr";

class AgentStatusService {
    private _status: AgentStatus = AgentStatus.none;
    private _session: CallSessionDTO | undefined;

    public readonly statusChanged: Subject<AgentStatus> = new Subject();

    public get status(): AgentStatus {
        return this._status;
    }

    public get currentAgentId(): string {
        return Lockr.get<string>(StorageKeys.UserObjectId);
    }

    public init(): AgentStatusService {
        socketAudio.callSessionChanged.subscribe(x => this.checkIsInACallState(x));
        this._status = AgentStatus.none;

        return this;
    }

    private notifyStatusChange() {
        this.statusChanged.next(this._status);
    }

    private checkIsInACallState(session: CallSessionDTO) {
        let newStatus = this._status;
        
        if ((GeneralHelper.getStage3StartOperations.includes(session.ConferenceActionAsString) || 
            session.ConferenceActionAsString === "OutboundAcceptedByAgent") && 
            (session.IsCurrentUserMonitoring || 
                session.IsCurrentUserThePrimaryAgent || 
                session.CallerId === this.currentAgentId || 
                session.ParticipantIds?.includes(this.currentAgentId)
            )
        ) {
            this._session = session;
            newStatus = AgentStatus.inACall;
        }
        else if (((!!this._session && this._session.SessionId === session.SessionId) && 
            GeneralHelper.getStage3EndOperations().includes(session.ConferenceActionAsString)) || 
            ![...(session.ParticipantIds ?? []), session.AgentRef, session.CallerId].includes(this.currentAgentId)) {
            this._session = undefined;
            newStatus = AgentStatus.none;
        }

        if (newStatus === this._status) {
            return;
        }

        this._status = newStatus;
        this.notifyStatusChange();
    }
}

export enum AgentStatus {
    none,
    inACall
}

export const agentStatusService = new AgentStatusService().init();
export class Contact {
    Id!: string;
    UserPrincipalName!: string;
    DisplayName!: string;
    GivenName!: string;
    Surname!: string;
    JobTitle!: string;
    Mail!: string;
    MobilePhone!: string;
    BusinessPhones!: string[];
    BusinessPhonesAsString!: string;
    Department!: string;
    UserType!: string;
    OfficeLocation!: string;
    PreferredLanguage!: string;
    Country!: string;
    City!: string;
    State!: string;
    PostalCode!: string;
    ProxyAddresses!: string[];
    ContactType!: ContactType;
    QueueIds!: string[];
    IsImported!: boolean;
}

export enum ContactType {
    Database = 0,
    AzureActiveDirectory = 1,
    Office365 = 2,
}

export class PaginatedContacts {
    PageIndex!: number;
    PageSize!: number;
    Count !: number;
    ResultList!: Contact[];
}

export class ExtendedContact {
    Id?: string;
    UserPrincipalName!: string;
    Name!: string;
    JobTitle!: string;
    Email!: string;
    MobilePhoneNumber!: string;
    BusinessPhoneNumber!: string;
    Department!: string;
    Office!: string;
    Country!: string;
    City!: string;
    QueueIds!: Array<string>;
}

import { useEffect, useState } from "react";
import { Subject } from "rxjs";
import { listeners } from "services/io/listeners";

export const useSubjectListenerEffect = <T>(action: (data: T) => void, subject: Subject<T>) => {
    const [state, setState] = useState<T | undefined>(undefined);
    
    useEffect(() => {
        const listener = subject.subscribe((response: T) => setState(response));

        return () => listener.unsubscribe();
    }, []);

    useEffect(() => {
        if (state === undefined) {
            return;
        }

        action(state);
    }, [state]);
};

export const useOperationListenerEffect = <T>(action: (data: T) => void, operation: string) => {
    const [state, setState] = useState<T | undefined>(undefined);
    
    useEffect(() => {
        const listener = listeners.createListener<T>(operation).received
            .subscribe((response: T) => setState(response));

        return () => listener.unsubscribe();
    }, []);

    useEffect(() => {
        if (state !== undefined) {
            action(state);
        }
    }, [state]);
};

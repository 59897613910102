import React from 'react';
import { Flex, Input,  } from '@fluentui/react-northstar';
import { useIntl } from 'react-intl';
import { useFormikContext, ErrorMessage } from 'formik';

export const ExtendedContactFieldContainer = (props: any) => {
    const intl = useIntl();
    const { fieldName, translationKeyword, isRequired } = props;
    const { values, touched, errors, handleBlur, handleChange } = useFormikContext<any>();
    const isInvalid = Boolean(touched[fieldName] && errors[fieldName]);
    return (<Flex column className="contact-field-container">
        <Input fluid required={isRequired}
            name={fieldName}
            error={isInvalid}
            value={values[fieldName]}
            onChange={handleChange}
            onBlur={handleBlur}
            label={intl.formatMessage({ id: `AddContactView.${translationKeyword}` })}
        />
        <ErrorMessage name={fieldName} render={msg => <div className='field-error'>{msg}</div>} />
        <br />
    </Flex>)
}

export default ExtendedContactFieldContainer;
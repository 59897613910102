class ImageUploader {
    private readonly loader: any;

    constructor(loader: any) {
        this.loader = loader;
    }

    upload(): Promise<any> {
        console.log("upload");

        // Return a promise that will be resolved when the file is uploaded.
        return Promise.resolve(
            {
                default: 'https://images.pexels.com/photos/1624496/pexels-photo-1624496.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
                // '160': 'http://example.com/images/image–size-160.image.png',
                // '500': 'http://example.com/images/image–size-500.image.png',
                // '1000': 'http://example.com/images/image–size-1000.image.png',
                // '1052': 'http://example.com/images/image–default-size.png'
            }
        );
    }

    abort(): void {
        console.log("abort");
        // Reject the promise returned from the upload() method.
        // server.abortUpload();
    }
}

export default ImageUploader;
import Lockr from 'lockr';
import { Languages } from '../constants';
import English from '../languages/en-en.json';
import Dutch from '../languages/nl-nl.json';
import French from '../languages/fr-fr.json';
import German from '../languages/de-de.json';
import Italian from '../languages/it-it.json';
import Spanish from '../languages/es-es.json';
import { StorageKeys } from 'utils';

export default class LanguageHelper {
    public static setLanguageToLocalStorage(language: string) {
        Lockr.set(StorageKeys.Language, language);
    }

    public static getLanguageFromLocalStorage(): string {
        const language = Lockr.get(StorageKeys.Language) as string;

        return language ?? Languages.english;
    }
    public static getUnflattenedLanguage() {
        const language = Lockr.get<string>(StorageKeys.Language);

        if (language) {
            if (language.includes(Languages.dutch)) {
                return Dutch;
            } else if (language.includes(Languages.french)) {
                return French;
            } else if (language.includes(Languages.german)) {
                return German;
            } else if (language.includes(Languages.italian)) {
                return Italian;
            } else if (language.includes(Languages.spanish)) {
                return Spanish;
            }
        }
        return English;
    }

    private static flatten(obj: any) {
        const newObj: any = {};
        for (var key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                var temp = LanguageHelper.flatten(obj[key])
                for (var key2 in temp) {
                    newObj[`${key}.${key2}`] = temp[key2];
                }
            } else {
                newObj[key] = obj[key];
            }
        }
        return newObj;
    }

    public static getLanguage() {
        var translations = LanguageHelper.getUnflattenedLanguage();

        return LanguageHelper.flatten(translations);
    }
}
import * as React from 'react';
import { Flex } from '@fluentui/react-northstar';
import CallDetails from './CallDetails';
import { socketAudio } from 'services/calling';
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import { HeaderBarDisplayedMessageType } from 'utils';
import CallActionsStage3 from './CallActions/CallActionsStage3';
import GenericActionsView from 'containers/GenericView/GenericActionsView/GenericActionsView';
import { useSubjectListenerEffect } from 'utils/helpers/listenerHook';
import './callView.scss'

export const CallViewStage3 = () => {

  React.useEffect(() => {
    initialize();
  }, []);

  useSubjectListenerEffect((_: boolean) => {
    handleDisplayHeaderBarMessageChange();
  }, socketAudio.callActionViewIsDisplayed);

  const initialize = () => {
    handleDisplayHeaderBarMessageChange();
  }

  const handleDisplayHeaderBarMessageChange = () => {
    mainViewHelper.showHeaderBarMessage.next(HeaderBarDisplayedMessageType.MediaView);
  }

  return (
    <Flex.Item>
      <Flex space="between"
        styles={({ theme: { siteVariables } }) => ({
          backgroundColor: siteVariables.colorScheme.default.foreground1,
          color: siteVariables.colorScheme.default.background1,
          height: '50px'
        })}
        className="action-button-bar"
      >
        <CallDetails />
        <CallActionsStage3 />
        <GenericActionsView />
      </Flex>
    </Flex.Item>
  )
};

export default CallViewStage3;

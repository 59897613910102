export const CallActionsStyles = {

   styles : {
    backgroundColor: "#C4314B",
    borderRadius: '0px',
    color: "#ffffff",
    width: '50px',
    height: '50px',
    ':hover': {
      backgroundColor: 'rgba(167,32,55,0.9)',
      color: "#ffffff",
    },
    '[disabled]': {
      backgroundColor: 'rgba(127,127,127,0.5)',
    }
  },

  stylesAnswer : {
    backgroundColor: "#35ad31",
    borderRadius: '0px',
    color: "#ffffff",
    width: '50px',
    height: '50px',
    ':hover': {
      backgroundColor: "#2e962a",
      color: "#ffffff",
    }
  },

  stylesFaded: {
    backgroundColor: "#605e5c",
    borderRadius: '0px',
    color: "#c8c6c4",
    width: '50px',
    height: '50px',
    ':hover': {
      backgroundColor: 'rgb(52, 52, 65)',
      color: "#c8c6c4",
    }
  },

  stylesActive: {
    borderRadius: '0px',
    width: '50px',
    height: '50px',
    backgroundColor: 'rgb(52, 52, 65)',
    color: "#c8c6c4"
  }

}
export default CallActionsStyles;

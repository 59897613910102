import { useCallback, useEffect, useRef } from "react";
import ModifierKeys from "./ModifierKeys";

const useKeyCombination = (modifiers: ModifierKeys[], keys: string[], callback: () => void) => {

    const keyMap = useRef<Map<string, boolean>>(new Map<string, boolean>());

    const areModifierPressed = useCallback((e: KeyboardEvent): boolean => {
        return modifiers.every(mk => keyMap.current.get(mk.toString()))
    }, [modifiers]);

    const keyDownListener = useCallback((e: KeyboardEvent) => {
        keyMap.current.set(e.key, true);
        if (areModifierPressed(e) && keys.every(k => keyMap.current.get(k))) {
            callback();
        }

    }, [modifiers, keys, callback]);

    const keyUpListener = (e: KeyboardEvent) => {
        keyMap.current.set(e.key, false);
    }

    useEffect(() => {
        document.addEventListener('keydown', keyDownListener, true);
        document.addEventListener('keyup', keyUpListener, true);

        return () => {
            document.removeEventListener('keydown', keyDownListener, true);
            document.removeEventListener('keyup', keyUpListener, true);
        }
    }, [keyDownListener]);
}

export default useKeyCombination;
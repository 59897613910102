export enum Controllers {
    User = 'user',
    Calls = 'calls',
    CallCenter = 'callcenter',
    Presence = 'presence',
    QueueUser = 'agentQueues',
    RefreshToken = 'refresh-token',
    Tabs = '',
    Agent = '',
    Supervisor = '',
    Deployments = '',
    Voicemail = '',
    Contact = ''
}

export enum ExternalTabsOperation {
    GetExternalTabs = 'GetExternalTabs',
    NotifyTabsChange = 'NotifyTabsChange'
}

export enum ServiceOperations {//#warning-js [cc4all-1102]: isn't this naming too generic?
    SignIn = 'SignIn',
    SignInToken = 'SignInToken',
    SignInAzureAD = 'SignInAzureAD',
    SignOut = 'SignOut',
    GetAllWrapUpCollectionTopicMap = 'GetAllWrapUpCollectionTopicMap',
    GetReasonsFromCache = 'GetReasonsFromCache',
    GetCurrentPresence = 'GetCurrentPresence',
    GetAzureCurrentPresence = 'GetCurrentPresence',
    GetCompanyKeyByUserName = 'GetCompanyKeyByUserName',
    GetCompanyKeyBySip = 'GetCompanyKeyBySip',
    GetCompanyKeyByTenantId = 'GetCompanyKey',
    GetClientDeploymentsByAzureTenantId = '',
    GetWaitingMediaByAgent = 'GetWaitingMediaByAgent',
    GetOperatorCallsByAgent = 'GetOperatorCallsByAgent',
    CheckInClient = 'CheckInClient',
    ResetClientAvailability = 'ResetClientAvailability',
    ResetUser = 'ResetUser',
    GetCallCenterInfo = 'GetCallCenterInfo',
    GetUsersWithPresence = 'UsersWithPresence',
    CreateUserPresenceWebhook = 'createpresencewebhook',
    IsAgentInWrapUp = 'IsAgentInAWrapUp',
    GetPreviousPresenceAndNote = 'GetPreviousPresenceAndNote',
    GetOngoingWrapUpSettings = 'GetOngoingWrapUpSettings',
    GetUsers = 'Users',
    CallPickup = 'CallPickup',
    GetCallCenterAssemblyVersion = 'GetCallCenterAssemblyVersion',
    KeepAlive = 'KeepAlive',
    ProbeSpeed = 'ProbeSpeed',
    GetAgentsBySupervisorRef = 'GetAgentsBySupervisorRef',
    GetMediaBySupervisor = 'GetMediaBySupervisor',
    GetActiveCallsBySupervisor = 'GetActiveCallsBySupervisor',//#warning-js [cc4all-1102]: not used?
    UpdateAgentScoreAndDesire = 'UpdateAgentScoreAndDesire',
    GetNotificationListForClient = 'GetNotificationListForClient',
    DownloadVoiceMailMedia = 'DownloadVoiceMailMedia',
    NotificationSetStatus = 'NotificationSetStatus',
    RemoveNotification = 'RemoveNotification',
    GetServerTime = 'GetServerTime',
    GetGraphApiAccessToken = 'GetGraphApiAccessToken',
    LogClientException = 'LogClientException',
    ChangePresence = 'ChangePresence',
    SetIsTeamsClient = 'SetIsTeamsClient',
    IsTeamsCustomerJourneyEnabled = 'IsTeamsCustomerJourneyEnabled',
    IsNewClientEnabled = "IsNewClientEnabled",

    // WrapUp
    SaveWrapUpHistory = 'SaveWrapUpHistory',
    CancelWrapUp = 'CancelWrapUp',
    ChangeWrapUpCode = 'na',

    GetUserBySip = 'GetUserBySip',
    RefreshToken = 'teams-client',
    SetClientOffline = 'set-client-offline',
    SetClientOn = 'set-client-on',
    GetVariables = 'get-variables',
    MediaPickup = 'media-pick-up',
}

export enum ContactOperations {
    FilterContactsViaLucene = 'FilterContactsViaLucene',
    GetContacts = 'get-contacts',
    GetUsersByIdsAsync = 'GetUsersByIds',
    GetCurrentPresenceForSip = 'GetCurrentPresenceForSip',
    SaveContact = 'SaveContact',
    UpdateContact = 'UpdateContact',
    DeleteContact = 'DeleteContact',
    GetUsersPresence = 'GetUsersPresence',
    SaveFavoriteContact = 'SaveFavoriteContact',
    RemoveFavoriteContact = 'RemoveFavoriteContact',
    GetFavoriteContactsByAgentId = 'GetFavoriteContactsByAgentId',
    HasAnyFavoriteContact = 'HasAnyFavoriteContact',
    GetAzureActiveDirectorySettingsForContacts = 'GetAzureActiveDirectorySettingsForContacts',
    GetAzureActiveDirectorySettingsForContactsStage3 = 'get-azure-active-directory-settings-for-contacts',
    GetFavoriteContactPresence = 'GetFavoriteContactPresence'
}

export enum AudioOperations {
    OnHoldCall = 'AgentPutOnHold',
    PauseCall = 'AgentPutOnHold',
    ResumeCall = 'AgentResumed',
    ParkCall = 'ParkCall',
    StartRejoinCall = 'StartRejoinCall',
    GetAgentCallSession = 'AgentCallSession',//#warning-js [cc4all-1102]: rename to something like GetAgentCallSession
    TeamsUnParkCall = 'TeamsUnParkCall',
    StartSupervisorMonitoring = 'StartSupervisorMonitoring',
    SwitchToMonitoringAction = 'SwitchToMonitoringAction',
    // Not present in Stage2, but needed for compatibility
    HangupCall = 'hangup-call',
    RejectCall = 'reject-call',
}

export enum FavoritesOperations {
    //stage 3 only requests
    GetAgentFavoriteContacts = 'get-agent-favorite-contacts',
    AddFavoriteContact = 'add-favorite-contact',
    DeleteFavoriteContact = 'delete-favorite-contact',
    GetFavoritesCount = 'get-favorites-count',
    GetFavoritesConfiguration = 'get-favorites-configuration',
}

export enum VoicemailOperations{
    //stage 3 only
    GetVoicemails = "list",
    UpdateVoicemail = "update",
    DeleteVoicemail = "delete"
}

export enum TransferOperations {
    ColdTransfer = 'ColdTransfer',
    WarmTransferStart = 'WarmTransferStart',
    WarmTransferCancel = 'WarmTransferCancel',
    WarmTransferFinalize = 'EjectFromMeeting',
    WarmTransferConsultCaller = 'SwitchToCallerDuringWarmTransfer',
    WarmTransferConsultAgent = 'SwitchToAgentDuringWarmTransfer',
    ToggleRecording = 'ToggleRecording',
    ColdTransferIVRDestinations = 'get-transfer-destinations',
    ColdTransferDestinations = 'get-transfer-agents-destinations',
    ColdTransferIVR = 'cold-transfer-ivr',
}

export enum SocketOperations {
    OnInitialized = 'onInitialized',
    OnReconnect = 'onReconnect',
    Connect = 'connect',
    Disconnect = 'disconnect',
    InvokeService = 'invokeService',
    InvokeServiceResult = 'invokeServiceResult',
    ServerNotification = 'serverNotification',
    JoinRoom = 'joinToken',
    LeaveRoom = 'leaveToken',
    ReceivedConnectedFeedback = 'receivedConnectedFeedback',
    SocketConnected = 'mySocketConnected',
    SignedIn = 'signedIn',
    SignedOut = 'signedOut',
    SignedOtherBrowser = 'signedOtherBrowser',
    EjectFromMeeting = 'EjectFromMeeting',
    GetClientVariableFields = 'GetClientVariableFields',
    CallHistoryAddNewRecord = 'PersonalCallHistoryAddNewRecord',
    GetCallHistoryListByAgentRef = 'GetPersonalCallHistoryListByAgentRef'
}

export enum QueueOperations {
    GetQueues = 'GetQueues',
    ClientQueuesChanged = 'ClientQueuesChanged',
    GetSupervisedQueuesInfo = 'GetSupervisedQueuesInfo',
    GetQueuesForAgent = "GetQueuesForAgent",
    GetSupervisorMedia = 'get-supervisor-media'
}

export enum OutboundOperations {
    IsAgentOutboundAccessEnabled = 'IsAgentOutboundAccessEnabled',
    GetEndPointListForAgent = 'GetEndPointListForAgent',
    CreateCall = 'CreateCall',
    CreateOutboundCall = 'CreateOutboundCall',
    CreateOutgoingCall = 'outgoing-call',
    SendTone = 'SendTone'
}

export enum WebChatOperations {
    SendChatSessionToAgent = 'SendChatSessionToAgent',
    SendChatDetailsToClients = 'SendChatDetailsToClients',
    SendChatDetailsToSupervisor = 'SendChatDetailsToSupervisor',
    MessageReceivedFromCustomer = 'MessageRecivedFromCustomer',
    SetAgentInAChat = 'SetAgentInAChat',
    SendChatTransferInvitationIgnored = 'SendChatTransferInvitationIgnored',
    SendChatTransferInvitationAccepted = "SendChatTransferInvitationAccepted",

    PostChatMessageToWebClient = 'PostChatMessageToWebClient',
    AcceptChatSession = 'AcceptChatSession',
    IgnoreChatSession = 'IgnoreChatSession',
    EndChatSession = 'EndChatSession',
    SendChatMessage = 'SendChatMessage',
    GetAgentMemberedQueuesPredefinedMessaged = 'GetAgentMemberedQueuesPredefinedMessaged',
    GetChatHistory = 'GetChatHistory',
    RecoveryChatSession = 'RecoveryChatSession',
    GetAgentChatSessions = 'GetAgentChatSessions',
    GetChatHistoryFromChatSessionManager = 'GetChatHistoryFromChatSessionManager',
    TransferChatSessionToQueue = "TransferChatSessionToQueue",
    TransferChatSessionToAgent = "TransferChatSessionToAgent",
    GetChatAgents = "GetChatAgents",
    GetQueuesAndAgentsForChatTransfer = "GetQueuesAndAgentsForChatTransfer"
}

export enum ClientInitialLoadOperations {
    GetInitialClientSettings = 'get-initial-client-settings'
}

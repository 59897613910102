import { Button, CallIcon } from "@fluentui/react-northstar";
import React from "react";
import { useIntl } from "react-intl";

export const CallPickupButton = (props: any) => {
    const { isVisible, callback } = props;
    const intl = useIntl();

    return <>{
        isVisible && <Button circular icon={<CallIcon size="small" />} size="small" className="pickup-button" primary
            title={intl.formatMessage({ id: "WaitingCallsView.CallPickup" })}
            onClick={callback} />
    }
    </>
};

export default CallPickupButton;

import { CampaingCallItemDto, OutgoingCallDto, OutgoingCallResponseDto, TrustedEndpointDTO } from 'utils/domain/outbound';
import { OutboundService } from './socket-outbound';

export interface ApiOutbound {
    HasOutboundAccesEnabled(): boolean;
    GetEndPointListForAgent(): void;
    CreateOutboundCall(callItem: CampaingCallItemDto): Promise<boolean>;
    CreateOutgoingCall(callItem: OutgoingCallDto): Promise<OutgoingCallResponseDto>;
    isAgentOutboundAccessEnabled(): void;
    GetAgentCachedEndPointList(): TrustedEndpointDTO[];
}

export const socketOutbound = new OutboundService();

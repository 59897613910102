import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { StorageKeys, Controllers, ResponseResult, ServiceOperations } from '../utils';
import Lockr from 'lockr';
import { ConfigurationHelper, JwtHelper } from '../utils/helpers';
import { socketAuth, ssoAuth } from './auth';

let isRefreshing = false;
let failedQueue: Array<any> = [];

const getSSOTokenForSilentRefreshTokenAcquire = async (): Promise<string | null> => {
  const token = await ssoAuth.getAuthToken().catch(() => {
    return getMsalTokenForSilentRefreshTokenAcquire();
  });

  return token;
}

const getMsalTokenForSilentRefreshTokenAcquire = (): string | null => {
  const azureClients = (window as any)['config'] ? (window as any)['config'].azureClients : undefined;
  if (!azureClients) {
    return null;
  }
  const companyKey = Lockr.get<string>(StorageKeys.CompanyKey);
  const clientId = azureClients[companyKey] || azureClients['default'];
  if (!clientId) {
    return null;
  }
  const idTokenKey = `msal.${clientId}.idtoken`;
  return localStorage.getItem(idTokenKey) as string;
}

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    }
    else {
      prom.resolve(token);
    }
  })
  failedQueue = [];
}

const onRequest = (config: AxiosRequestConfig) => {
  const token = Lockr.get<string>(StorageKeys.TokenId);
  config.headers['__tenant'] = Lockr.get(StorageKeys.CompanyKey) || '';
  config.withCredentials = true;

  // skip refresh token login for some requests
  if (config.url?.endsWith(ServiceOperations.GetCompanyKeyByTenantId) ||
    config.url?.endsWith(ServiceOperations.GetClientDeploymentsByAzureTenantId) ||
    config.url?.endsWith(ServiceOperations.SignInAzureAD) ||
    config.url?.endsWith(ServiceOperations.SignOut)) {
    config.headers['Authorization'] = `Bearer ${token}`;
    return Promise.resolve(config);
  }

  const originalRequest = config;
  if (token != null && token.length > 0) {
    if (JwtHelper.isTokenExpired(token, 20)) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then((newToken) => {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

          return Promise.resolve(originalRequest);
        }).catch((err) => {
          return Promise.reject(err);
        });
      }
      isRefreshing = true;
      return refreshTokenCall(originalRequest);
    }
    else {
      // Use existing access token
      config.headers['Authorization'] = `Bearer ${token}`;
      return Promise.resolve(config);
    }
  }
  else {
    return Promise.resolve(config);
  }
};

function refreshTokenCall(originalRequest: any) {
  return new Promise(async (resolve, reject) => {
    // Get MS access token. Cannot get refresh token because it is stored in a cookie
    const token = await getSSOTokenForSilentRefreshTokenAcquire();

    if (token == null) {
      reject('MS token is null');
      // Notify sign out

      socketAuth.userSignedOut.next(true);
      return;
    }

    _refreshTokenInternal(resolve, reject, token, Lockr.get<string>(StorageKeys.UserObjectId), originalRequest);
  });
}

function _refreshTokenInternal(resolve: any, reject: any, msalToken: string, userId: string | null, originalRequest: any) {
  axios.post(
    `${ConfigurationHelper.gatewayApiUrl}/${Controllers.RefreshToken}/${ServiceOperations.RefreshToken}`,
    {
      msalJwt: msalToken,
      appClientJwt: Lockr.get<string>(StorageKeys.TokenId),
      userId: userId
    },
    {
      headers: { '__tenant': Lockr.get(StorageKeys.CompanyKey) || '' },
      withCredentials: true // Must be set to true to include HttpOnly cookies
    }
  ).then(rs => {
    if (rs.data.result === ResponseResult.SUCCESS) {
      // Set new access token to local storage
      Lockr.set(StorageKeys.TokenId, rs.data.data);
      originalRequest.headers['Authorization'] = `Bearer ${rs.data.data}`;
      processQueue(null, rs.data.data);
      resolve(originalRequest);
    }
    else {
      processQueue(rs.data.data, null);
      socketAuth.userSignedOut.next(true);
      reject(rs.data.data);
    }
  }).catch(err => {
    processQueue(err, null);
    socketAuth.userSignedOut.next(true);
    reject(err);
  }).finally(() => { isRefreshing = false; });
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response.data;
};

const onResponseError = async (error: AxiosError) => {
  if (error == null) {
    return Promise.reject(null);
  }
  if (error.response == null) {
    return Promise.reject(error);
  }
  // Access Token was expired or invalid. This is a fallback to onRequest interceptor
  if (error.response.status === 401 && error.response.data === 'Token validation failed.') {
    try {
      // Request a new access token based on the refresh token
      const newToken = await refreshTokenCall(error.config);
      // Update authorization bearer token
      error.config.headers['Authorization'] = `Bearer ${newToken}`;
      // Retry the original request
      return axios.request(error.config);
    }
    catch (_error) {
      return Promise.reject(_error);
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

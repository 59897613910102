import { ResponseWrapper } from 'utils';
import { CustomerDTO, IJourneyCustomerSessionDTO } from 'utils/domain/customerJourneyModels';
import CustomerJourneyView from './CustomerJourneyView';
export default CustomerJourneyView;

export interface ApiCustomer {
    //getCustomersByPhoneNumber(data: any): Promise<CustomerDTO[]>;
    getCustomersByName(data: any): Promise<CustomerDTO[]>;
    getCustomerById(data: any): Promise<CustomerDTO[]>;
    saveCustomer(customer: CustomerDTO): Promise<ResponseWrapper>;
    //updateCustomer(customer: CustomerDTO): Promise<ResponseWrapper>;
    getConversationHistoryByCustomer(data: any): Promise<IJourneyCustomerSessionDTO[]>;
}
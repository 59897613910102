import { ListenerOperations, logging, SocialMediaOperations } from "utils";
import { GeneralHelper } from "utils/helpers";
import { ApiSocialMedia } from ".";
import { CMMediaSessionMessageDTO, CMSourceType, SocialMediaSessionDTO } from "utils/domain/socialMedia/socialMediaSessionDTO";
import { Subject, Subscription } from 'rxjs';
import { Listener, listeners } from "services/io/listeners";
import { SocialMediaRequestDTO } from "utils/domain/socialMedia/socialMediaRequestDTO";
import { WebhookSettingDTO } from "utils/domain/socialMedia/webhookSettingDTO";
import PlaySoundHelper, { SoundType } from "utils/helpers/play-sound-helper";


export class SocialMediaService implements ApiSocialMedia {
    private readonly logger = logging.getLogger('SocialMediaService');
    private readonly subscriptionSendSocialMediaDetailsToClient: Subscription | null = null;
    private readonly subscriptionSendCmMessageReceivedFromCustomer: Subscription | null = null;

    public readonly listenerSendSocialMediaDetailsToClients: Listener<any> = listeners.createListener<any>(ListenerOperations.SendSocialMediaDetailsToClients);
    public readonly listenerCmMessageReceivedFromCustomer: Listener<CMMediaSessionMessageDTO> =
        listeners.createListener<CMMediaSessionMessageDTO>(ListenerOperations.CmMessageReceivedFromCustomer);

    private webhookSetting: WebhookSettingDTO | null = null;

    private providerName: string = 'Tracebuzz';
    sendSocialMediaDetailsToClientChangeNotification: Subject<SocialMediaSessionDTO> = new Subject<SocialMediaSessionDTO>();
    sendCmMessageReceivedFromCustomer: Subject<CMMediaSessionMessageDTO> = new Subject<CMMediaSessionMessageDTO>();
    isInASocialMedia: Subject<boolean> = new Subject<boolean>();
    isInCmMedia: Subject<boolean> = new Subject<boolean>();
    currentCmSessionId: Subject<string> = new Subject<string>();
    constructor() {
        this.subscriptionSendSocialMediaDetailsToClient?.unsubscribe();
        this.subscriptionSendSocialMediaDetailsToClient = this.listenerSendSocialMediaDetailsToClients.received.subscribe((obj: any) => {
            if (obj) {
                this.sendSocialMediaDetailsToClientChangeNotification.next(obj);
            }
        });

        this.subscriptionSendCmMessageReceivedFromCustomer?.unsubscribe();
        this.subscriptionSendCmMessageReceivedFromCustomer = this.listenerCmMessageReceivedFromCustomer
            .received.subscribe((response: CMMediaSessionMessageDTO) => {
                if (response) {
                    if (response.SourceType === CMSourceType.FromCustomer) {
                        PlaySoundHelper.playSound(SoundType.Notification);
                    }
                    this.sendCmMessageReceivedFromCustomer.next(response);
                }
            });
    }

    SocialMediaAccept(socialMediaRequest: SocialMediaRequestDTO): Promise<any> {
        return GeneralHelper.invokeServiceCall(socialMediaRequest, SocialMediaOperations.SocialMediaAccept, this.logger);
    }

    SocialMediaIgnore(socialMediaRequest: SocialMediaRequestDTO): Promise<any> {
        return GeneralHelper.invokeServiceCall(socialMediaRequest, SocialMediaOperations.SocialMediaIgnore, this.logger);
    }

    SocialMediaTerminate(socialMediaRequest: SocialMediaRequestDTO): Promise<any> {
        return GeneralHelper.invokeServiceCall(socialMediaRequest, SocialMediaOperations.SocialMediaTerminate, this.logger);
    }

    GetActiveSocialMediaSessions(sip: string): Promise<SocialMediaSessionDTO[]> {
        return GeneralHelper.invokeServiceCall(sip, SocialMediaOperations.GetActiveSocialMediaSessions, this.logger);
    }

    GetRingingSocialMediaSessions(sip: string): Promise<SocialMediaSessionDTO[]> {
        return GeneralHelper.invokeServiceCall(sip, SocialMediaOperations.GetRingingSocialMediaSessions, this.logger);
    }

    GetWebHookSetting(providerName?: string): Promise<WebhookSettingDTO> {
        if (!providerName) {
            providerName = this.providerName;
        } else {
            this.providerName = providerName;
        }

        return GeneralHelper.invokeServiceCall(providerName, SocialMediaOperations.GetWebHookSetting, this.logger).then((settings: WebhookSettingDTO) => {
            this.webhookSetting = settings;
            return settings;
        });
    }

    getWebHookSetting(): WebhookSettingDTO | null {
        return this.webhookSetting;
    }

    GetActiveCmMediaConversationHistory(sessionId: string): Promise<CMMediaSessionMessageDTO[]> {
        return GeneralHelper.invokeServiceCall(sessionId, SocialMediaOperations.GetActiveCmMediaConversationHistory, this.logger).then((response: CMMediaSessionMessageDTO[]) => {
            return response;
        });
    }

    SendCmMediaMessage(cmMessage: CMMediaSessionMessageDTO): Promise<any> {
        return GeneralHelper.invokeServiceCall(cmMessage, SocialMediaOperations.SendCmMediaMessage, this.logger);
    }
}

import Lockr from 'lockr';
import { Subject, Subscription } from "rxjs";
import { ApiAudioService } from '.';
import { logging, AudioOperations, Controllers, SocketOperations, StorageKeys, ListenerOperations, ConferenceStatusAction, ServiceOperations } from "utils";
import { ConfigurationHelper, EnvHelper } from 'utils/helpers';
import { CallSessionDTO } from 'utils/domain/callSessionDTO';
import { Listener, listeners } from 'services/io/listeners';
import GeneralHelper from 'utils/helpers/general-helper';
import { CallSessionRequest, EjectParticipantRequest, TransferRequest } from 'utils/domain/transfercall';
import { OnHoldStatus } from 'utils/domain/extended/onHoldStatusNotification';
import { BackendNotification } from 'utils/enums-s3';

const endOperationsStage2 = GeneralHelper.getEndOperations();
const endOperationsStage3 = GeneralHelper.getStage3EndOperations();

export class AudioService implements ApiAudioService {
    private readonly logger = logging.getLogger('SocketAudio');

    public readonly listenerCallSessionStateChanged: Listener<CallSessionDTO> = listeners.createListener<CallSessionDTO>(ListenerOperations.CallSessionStateChanged);
    private readonly subscriptionCallSessionStateChanged: Subscription | null = null;
    private readonly subscriptionCallAnsweredTooLate: Subscription | null = null;
    private readonly subscriptionAgentAnsweredAfterOfferingTimeout: Subscription | null = null;
    private readonly subscriptionCallOnHoldStatusChanged: Subscription | null = null;
    private readonly subscriptionPlayBusyToneNotification: Subscription | null = null;

    callActionViewIsDisplayed: Subject<boolean> = new Subject();
    callOnHoldStatusChanged: Subject<OnHoldStatus> = new Subject();
    currentUserCallSessionChanged: Subject<CallSessionDTO> = new Subject<CallSessionDTO>();
    callSessionChanged: Subject<CallSessionDTO> = new Subject<CallSessionDTO>();
    warmTransferInProgress: Subject<CallSessionDTO> = new Subject<CallSessionDTO>();
    currentUserMonitoredCallSessionChanged: Subject<CallSessionDTO> = new Subject<CallSessionDTO>();
    playBusyToneNotification: Subject<any> = new Subject<any>();

    callSession: CallSessionDTO = new CallSessionDTO();

    constructor() {
        GeneralHelper.logCox(`in socket-audio.ts, in constructor, begin`);

        this.subscriptionCallSessionStateChanged?.unsubscribe();
        this.subscriptionCallSessionStateChanged = this.listenerCallSessionStateChanged.received.subscribe((callSessionDTO: CallSessionDTO) => {
            const newCallSessionDTO = new CallSessionDTO(callSessionDTO);

            GeneralHelper.logCox(`in socket-audio.ts, in constructor, in listenerCallSessionStateChanged, will call this.notifyCallSessionChanged`);
            this.notifyCallSessionChanged(newCallSessionDTO);
        })

        this.subscriptionCallAnsweredTooLate?.unsubscribe();
        this.subscriptionCallAnsweredTooLate = listeners.createListener<any>(ListenerOperations.CallAnsweredTooLate)
            .received.subscribe(() => {
                logging.errorHandler.next("ErrorMessage.Red.CallerLeft");
            });

        this.subscriptionAgentAnsweredAfterOfferingTimeout?.unsubscribe();
        this.subscriptionAgentAnsweredAfterOfferingTimeout = listeners.createListener<any>(ListenerOperations.AgentAnsweredAfterOfferingTimeout)
            .received.subscribe(() => {
                logging.errorHandler.next("ErrorMessage.Red.AgentAnswerAfterOfferingTimeout");
            });

        this.subscriptionCallOnHoldStatusChanged?.unsubscribe();
        this.subscriptionCallOnHoldStatusChanged = listeners.createListener<any>(ListenerOperations.OnHoldStatusChanged)
            .received.subscribe((notification: OnHoldStatus) => {
                this.callOnHoldStatusChanged.next(notification);
            });

        if (EnvHelper.isStage3()) {
            this.subscriptionPlayBusyToneNotification?.unsubscribe();
            this.subscriptionPlayBusyToneNotification = listeners.createListener<any>(BackendNotification.PlayBusyTone)
                .received.subscribe((notification: any) => {
                    this.playBusyToneNotification.next(notification);
                });
        }


        GeneralHelper.logCox(`in socket-audio.ts, in constructor, end`);
    }

    getCurrentCallSession() {
        const azureUserId = Lockr.get<string>(StorageKeys.UserObjectId);
        const requestData = EnvHelper.isStage3()
            ? { AgentRef: azureUserId }
            : { sip: Lockr.get<string>(StorageKeys.SIP), UserAzureId: azureUserId, Ref: Lockr.get<string>(StorageKeys.UserId) };

        GeneralHelper.invokeServiceCall(requestData, AudioOperations.GetAgentCallSession, this.logger).then((callSessionDTO: CallSessionDTO) => {
            GeneralHelper.logCox(`in socket-audio.ts, in getCurrentCallSession, after AgentCallSession, will call this.notifyCallSessionChanged`);
            this.notifyCallSessionChanged(new CallSessionDTO(callSessionDTO));
        });
    }

    getSupervisedCallSessionForSessionId(sessionId?: string) {
        const requestData = { sessionId: sessionId };
        GeneralHelper.invokeServiceCall(requestData, AudioOperations.GetAgentCallSession, this.logger).then((callSessionDTO: CallSessionDTO) => {
            GeneralHelper.logCox(`in socket-audio.ts, after AgentCallSession; MonitorType is: ${callSessionDTO.MonitorType}, asString is ${callSessionDTO.MonitorTypeAsString}`);
            this.currentUserMonitoredCallSessionChanged.next(new CallSessionDTO(callSessionDTO));
        });
    }

    getCallSessionForCurrentUser() {
        return this.callSession;
    }

    notifyCallSessionChanged(callSessionDTO: CallSessionDTO) {
        if (!callSessionDTO.ConversationKey) {
            Lockr.set(StorageKeys.ExternalProcessed, '');
        }

        const sip = Lockr.get<string>(StorageKeys.SIP);
        const userObjectId = Lockr.get<string>(StorageKeys.UserObjectId);

        if ((callSessionDTO.IsCurrentUserThePrimaryAgent ||
            (EnvHelper.isStage3() && callSessionDTO.IsCurrentUserMonitoring)) ||
            (callSessionDTO.AgentRef && userObjectId && callSessionDTO.AgentRef.toString().toLowerCase() === userObjectId.toLowerCase())) {
            if (this.isCallTerminated(callSessionDTO)) {
                callSessionDTO = this.notifyCallTerminated(callSessionDTO);
            } else {
                callSessionDTO = this.notifyCallUpdated(callSessionDTO);
            }

            if (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Unavailable]) {
                logging.errorHandler.next("ErrorMessage.Red.Unavailable");
            }

            this.callSession = callSessionDTO;
        }
        else if (!callSessionDTO.IsCurrentUserThePrimaryAgent &&
            this.callSession.SessionId === callSessionDTO.SessionId &&
            callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Ignored]) {
            this.callSession = new CallSessionDTO();
        }
        // for stage 3 only, set call session to empty if call ended
        else if (EnvHelper.isStage3() &&
            !callSessionDTO.IsCurrentUserInCall &&
            this.callSession.SessionId === callSessionDTO.SessionId) {
            this.callSession = new CallSessionDTO();
        }

        const currentUserId = Lockr.get<string>(StorageKeys.UserId);

        if (EnvHelper.isStage3() &&
            callSessionDTO.ActionHistory &&
            callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.OperatorColdTransfered &&
            callSessionDTO.AgentRef === currentUserId) {
            callSessionDTO.Transferring = true;

            this.currentUserCallSessionChanged.next(callSessionDTO);
            return;
        }

        const transferTarget = EnvHelper.isStage3() ? userObjectId : sip;
        if (callSessionDTO.TransferTargetUri?.toString().toLowerCase() === transferTarget?.toLowerCase()) {
            this.warmTransferInProgress.next(callSessionDTO);
        }

        if (callSessionDTO.IsCurrentUserMonitoring) {
            this.currentUserMonitoredCallSessionChanged.next(callSessionDTO);
        }

        GeneralHelper.logCox(`in socket-audio.ts, in notifyCallSessionChanged, will call callSessionChanged`);
        this.callSessionChanged.next(callSessionDTO);
    }

    isCallTerminated(callSessionDTO: CallSessionDTO) {
        const endOperations = EnvHelper.isStage3() ? endOperationsStage3 : endOperationsStage2;
        const isAnEndOperation = endOperations.includes(callSessionDTO.ConferenceActionAsString);
        const isLastFallback = (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 1] === ConferenceStatusAction.Fallback);
        const isSecondToLastFallback = (callSessionDTO.ActionHistory[callSessionDTO.ActionHistory.length - 2] === ConferenceStatusAction.Fallback);

        const result = isAnEndOperation || isLastFallback || (!EnvHelper.isStage3() && isSecondToLastFallback);

        return result;
    }

    notifyCallTerminated(callSessionDTO: CallSessionDTO) {
        const terminatedCallSession = new CallSessionDTO();
        terminatedCallSession.SessionId = callSessionDTO.SessionId;
        callSessionDTO.IsTerminated = true;
        if (callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.CallerClosedBeforeAnswer] ||
            callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.CallerClosedAfterAnswer]) {
            terminatedCallSession.ActionHistoryAsString = callSessionDTO.ActionHistoryAsString;
            terminatedCallSession.ConferenceActionAsString = callSessionDTO.ConferenceActionAsString;
        }
        terminatedCallSession.IsTerminated = true;
        this.currentUserCallSessionChanged.next(terminatedCallSession);
        Lockr.set(StorageKeys.ExternalProcessed, '');

        return callSessionDTO;
    }

    notifyCallUpdated(callSessionDTO: CallSessionDTO) {
        callSessionDTO.Started = callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.Offering];

        if (callSessionDTO.TransferTargetUri &&
            !(callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmCanceled] ||
                callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent] ||
                callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmInviteRejected]
            )) {
            callSessionDTO.Transferring = true;
        }

        // stage 2 bug, sometimes the call session callstartdate may be invalid
        if ((callSessionDTO.CallStartDate === "0001-01-01T00:00:00") && (this.callSession.CallStartDate !== "0001-01-01T00:00:00")) {
            callSessionDTO.CallStartDate = this.callSession.CallStartDate;
        }
        this.currentUserCallSessionChanged.next(callSessionDTO);
        return callSessionDTO;
    }

    ejectFromMeeting(data: CallSessionRequest | TransferRequest | EjectParticipantRequest): Promise<any> {
        this.callSession = new CallSessionDTO();
        this.currentUserCallSessionChanged.next(this.callSession);
        this.currentUserMonitoredCallSessionChanged.next(this.callSession);
        const controller = EnvHelper.isStage3() ? Controllers.CallCenter : Controllers.Calls;
        return GeneralHelper.invokeServiceCall(data, SocketOperations.EjectFromMeeting, this.logger, controller, ConfigurationHelper.botApiUrl);
    }

    hangUp(data: any): Promise<any> {
        // Only Stage3
        if (!EnvHelper.isStage3()) {
            return Promise.reject(null);
        }
        this.callSession = new CallSessionDTO();
        this.currentUserCallSessionChanged.next(this.callSession);
        return GeneralHelper.invokeServiceCall(data, AudioOperations.HangupCall, this.logger);
    }

    rejectCall(data: any): Promise<any> {
        // Only Stage3
        if (!EnvHelper.isStage3()) {
            return Promise.reject(null);
        }
        this.callSession = new CallSessionDTO();
        this.currentUserCallSessionChanged.next(this.callSession);
        return GeneralHelper.invokeServiceCall(data, AudioOperations.RejectCall, this.logger);
    }

    pauseCall(data: any): Promise<any> {
        if (EnvHelper.isStage3()) {
            return GeneralHelper.invokeServiceCall(data, AudioOperations.OnHoldCall, this.logger);
        }
        return GeneralHelper.invokeServiceCall(data, AudioOperations.OnHoldCall, this.logger, Controllers.Calls, ConfigurationHelper.botApiUrl);
    }

    resumeCall(data: any): Promise<any> {
        if (EnvHelper.isStage3()) {
            return GeneralHelper.invokeServiceCall(data, AudioOperations.ResumeCall, this.logger);
        }
        return GeneralHelper.invokeServiceCall(data, AudioOperations.ResumeCall, this.logger, Controllers.Calls, ConfigurationHelper.botApiUrl);
    }

    parkCall(data: any): Promise<any> {
        if (EnvHelper.isStage3()) {
            return GeneralHelper.invokeServiceCall(data, AudioOperations.ParkCall, this.logger);
        }
        return GeneralHelper.invokeServiceCall(data, AudioOperations.ParkCall, this.logger, Controllers.Calls, ConfigurationHelper.botApiUrl);
    }

    unparkCall(data: any): Promise<any> {
        return GeneralHelper.invokeServiceCall(data, AudioOperations.TeamsUnParkCall, this.logger);
    }

    startRejoinCall(data: any): Promise<any> {
        // Only Stage2
        if (EnvHelper.isStage3()) {
            return Promise.reject(null);
        }
        return GeneralHelper.invokeServiceCall(data, AudioOperations.StartRejoinCall, this.logger, Controllers.Calls, ConfigurationHelper.botApiUrl);
    }

    startSupervisorMonitoring(data: any): Promise<any> {
        const controller = EnvHelper.isStage3() ? Controllers.CallCenter : Controllers.Calls;
        return GeneralHelper.invokeServiceCall(data, AudioOperations.StartSupervisorMonitoring, this.logger, controller);
    }

    switchToMonitoringAction(data: any): Promise<any> {
        const controller = EnvHelper.isStage3() ? Controllers.CallCenter : Controllers.Calls;
        return GeneralHelper.invokeServiceCall(data, AudioOperations.SwitchToMonitoringAction, this.logger, controller);
    }

    getClientVariableFields(data: any): Promise<any> {
        return GeneralHelper.invokeServiceCall(data, SocketOperations.GetClientVariableFields, this.logger);
    }

    getWorkflowVariables(workflowId: string): Promise<any> {
        return GeneralHelper.invokeServiceCall(workflowId, ServiceOperations.GetVariables, this.logger);
    }
}

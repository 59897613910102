import { InvokeServiceArgs } from "services/io/interfaces";
import { serviceCall } from "services/io/service-call";
import { logging } from "utils";
import { CallHistoryDTO } from "utils/domain/extended/callHistoryDTO";
import { PagedResultDto } from "utils/domain/extended/pagedResultDto";
import { Controllers, ExternalTabsOperation } from "utils/enums-s3";

export class ExtendedCallHistoryService {
    private readonly logger = logging.getLogger('ExternalTabsService');

    GetAgentHistory(page: number, search: string, pageSize: number): Promise<PagedResultDto<CallHistoryDTO>> {
        return new Promise((resolve, reject) => {
            const args: InvokeServiceArgs = {
                controller: `reporting/${Controllers.Calls}`,
                operation: ExternalTabsOperation.GetCallsHistory,
                requestData: { pageNumber: page, filter: search, pageSize: pageSize },
                responseHandler: {
                    success: (result) => {
                        resolve(result);
                    },
                    error: (err) => {
                        logging.errorHandler.next("ErrorMessage.GetAgentHistory");
                        this.logger.error(err);
                        reject(err);
                    }
                }
            };
            serviceCall.invokeService(args);
        })
    }
}
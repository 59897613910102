import React, { ReactElement } from 'react';

const stylesActive = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0px',
    width: '50px',
    height: '50px',
    backgroundColor: 'rgb(52, 52, 65)',
    color: "#c8c6c4"
  }

type Props = {
    icon: ReactElement
}

const ActiveAction = ({ icon }: Props) => {

    return (<div style={stylesActive}>
        {icon}
    </div>)
}

export default ActiveAction;
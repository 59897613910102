import { Subject } from "rxjs";
import { FavoriteContactDTO } from "utils/domain/extended/favoriteContactDTO";

export enum FavoriteContactSender {
    Contacts,
    Dialpad
}

export interface FavoriteContactUpdate {
    contact: FavoriteContactDTO;
    sender: FavoriteContactSender;
    action: FavoriteContactAction
}

export enum FavoriteContactAction {
    Add = 0,
    Update = 1,
    Delete = 2
}

export class NotificationCenter {
    favoriteContactUpdated: Subject<FavoriteContactUpdate> = new Subject<FavoriteContactUpdate>();
}

export const notificationCenter = new NotificationCenter();

import React, { useState, useRef } from 'react';
import { Menu, tabListBehavior, Flex } from '@fluentui/react-northstar';
import { useIntl } from 'react-intl';
import { externalTabsSocket } from '../../../services/externalTabs';
import { ConferenceStatusAction, logging, MenuActiveItem, StorageKeys, TabViewIds } from '../../../utils';
import { supervisorManager } from '../../../services/supervisor/supervisor-manager';
import Lockr from 'lockr';
import { TabDTO, TabPositioning, TabTarget } from '../../../utils/domain/mediaView/tabDTO';
import { ActiveMediaView } from '../../../utils/domain/mediaView/activeMediaView';
import AgentView from '../AgentView';
import './mediaTabs.scss';
import { CallHistory, SupervisorView, ExternalTabsView } from '../../../containers';
import { socketAudio } from '../../../services/calling';
import { CallSessionDTO } from '../../../utils/domain/callSessionDTO';
import { mainViewHelper } from '../../../utils/helpers/main-view-helper';
import { Subscription } from 'rxjs';
import { GeneralHelper, EnvHelper } from '../../../utils/helpers';

//#warning-js [cc4all-1102]: why is this called MediaTabs?
export const MediaTabs = () => {
  const startOperations = [ConferenceStatusAction[ConferenceStatusAction.Accepted]];
  const inACallOperations = [
    ConferenceStatusAction[ConferenceStatusAction.OnHold],
    ConferenceStatusAction[ConferenceStatusAction.Monitoring],
    ConferenceStatusAction[ConferenceStatusAction.MonitoringEnded],
    ConferenceStatusAction[ConferenceStatusAction.SupervisorClosed],
    ConferenceStatusAction[ConferenceStatusAction.Resumed],
  ];
  const intl = useIntl();
  const [activeTabId, setActiveTabId] = useState(TabViewIds.Agent);
  const activeTabIdRef = useRef(TabViewIds.Agent);
  const [supervisorTabContentKey, setSupervisorTabContentKey] = useState(Math.random() + "supervisorTabContentKey");
  const [externalTabContentKey, setExternalTabContentKey] = useState(Math.random() + "externalTabContentKey");
  const [isSupervisor, setIsSupervisor] = useState(false);

  const [supervisorItem, setSupervisorItem] = useState([] as any[]);//#warning-js [cc4all-1102]: if it's one item, why have it as an array?
  const [externalItems, setExternalItems] = useState([] as any[]);
  const [externalLinks, setExternalLinks] = useState([] as any[]);

  let subscriptionCurrentUserCallSessionChanged: Subscription | null = null;
  let subscriptionTabsChange: Subscription | null = null;
  let subscriptionIsUserSupervisor: Subscription | null = null;
  let subscriptionTabViewChange: Subscription | null = null;
  let subscriptionMissedCallNotificationReceived: Subscription | null = null;

  const [agentTab, setAgentTab] = useState([{
    key: 'agent' + Math.random(),
    content: intl.formatMessage({ id: "MediaTabs.Agent" }),
    onClick: () => { changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.AgentView, ActiveTabId: TabViewIds.Agent })) },
    tabcontent: <AgentView />,
    tabid: TabViewIds.Agent,
    active: true,
    viewtype: MenuActiveItem.AgentView
  }] as any[]);

  const [callHistoryTab, setCallHistoryTab] = useState([{
    key: 'callhistory' + Math.random(),
    content: intl.formatMessage({ id: "SettingsView.CallHistory" }),
    onClick: () => { changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.CallHistory, ActiveTabId: TabViewIds.CallHistory })) },
    tabcontent: <CallHistory />,
    tabid: TabViewIds.CallHistory,
    active: false,
    viewtype: MenuActiveItem.CallHistory
  }] as any[]);

  const initialize = () => {
    GeneralHelper.logCox(`in MediaTabs.tsx, in initialize`);
    if (!EnvHelper.isStage3()) {
      loadExternalTabs();

      subscriptionCurrentUserCallSessionChanged?.unsubscribe();
      subscriptionCurrentUserCallSessionChanged = socketAudio.currentUserCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
        const isOngoingCall = callStatePermitsGettingExternalTabs(callSessionDTO);
        //#warning-js [cc4all-1102]: seems to be duplicated in multiple files (here in MediaTabs, in DialpadView, WaitingCalls, SupervisorCallsView);
        //#warning-js [cc4all-1102]: also should have obj?.PrimaryAgentSIP.? toLowerCase() in order to shorten the line
        getExternalTabs(callSessionDTO, isOngoingCall);
      });

      subscriptionTabsChange?.unsubscribe();
      subscriptionTabsChange = externalTabsSocket.listenerNotifyTabsChange.received.subscribe((obj: any) => {
        loadExternalTabs();
      });

      subscriptionIsUserSupervisor?.unsubscribe();
      subscriptionIsUserSupervisor = supervisorManager.onIsUserSupervisorCheckDone.subscribe((obj: boolean) => {
        setIsSupervisor(obj);
      })

      subscriptionTabViewChange?.unsubscribe();
      subscriptionTabViewChange = mainViewHelper.tabViewChange.subscribe((tabId: number | string) => {
        if (tabId === TabViewIds.Agent) {
          changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.AgentView, ActiveTabId: TabViewIds.Agent }));
        }
      });

      subscriptionMissedCallNotificationReceived?.unsubscribe();
      subscriptionMissedCallNotificationReceived = mainViewHelper.missedCallNotificationReceivedFromBot.subscribe((missedCallSessionId: string) => {
        changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.CallHistory, ActiveTabId: TabViewIds.CallHistory }));
      });
    }

    if (supervisorManager.isSupervisor) {
      setIsSupervisor(true);
    }
  };

  const callStatePermitsGettingExternalTabs = (callSession: CallSessionDTO) => {
    const isCallParked = GeneralHelper.isCallParkedOrFallback(callSession.ActionHistory, ConferenceStatusAction.OperatorPark);
    const isFallbackCall = GeneralHelper.isCallParkedOrFallback(callSession.ActionHistory, ConferenceStatusAction.Fallback);

    return ((((startOperations.includes(callSession.ConferenceActionAsString) && !GeneralHelper.isRinging(callSession.ActionHistory)) ||
      inACallOperations.includes(callSession.ConferenceActionAsString))
      && callSession.IsCurrentUserThePrimaryAgent
      && !isCallParked && !isFallbackCall
      && !callSession.IsTeamsUnparkInProgress)
      || callSession.IsWarmTransferInProgress);
  }

  const loadExternalTabs = () => {
    socketAudio.getCurrentCallSession();
    const callSession = socketAudio.getCallSessionForCurrentUser();
    if (callSession.ConversationKey) {
      getExternalTabs(callSession, true);
    } else {
      getExternalTabs(callSession, false);
    }
  }

  const getExternalTabs = (callSession: CallSessionDTO, isInACall: boolean) => {
    const companyKey = Lockr.get<string>(StorageKeys.CompanyKey);

    externalTabsSocket.GetExternalTabs(companyKey, callSession.SessionId ? callSession.SessionId : "").then((tabs: TabDTO[]) => {
      createInsideExternalTabs(callSession, tabs, isInACall);
      createPopupExternalTabs(callSession, tabs, isInACall);
    });
  }

  const createInsideExternalTabs = (callSession: CallSessionDTO, tabs: TabDTO[], isInACall: boolean) => {
    setExternalItems((currentState: any) => {
      const sortedTabs = sortTabsByOrder(tabs);
      const newItems = [] as any[];
      let wasActiveTabDeleted = activeTabIdRef.current > 0;

      sortedTabs.forEach(el => {
        let isVisibleTab = true;

        if (el.IsDeleted || !el.IsActive || el.Positioning !== TabPositioning.Telephony || el.Target !== TabTarget.Inside || (!isInACall && el.IsDynamic)) {
          isVisibleTab = false;
        }

        if (isInACall && el.IsDynamic) {
          el.URL = replaceVariables(el.URL, callSession);
        }

        if (isVisibleTab) {
          if (activeTabIdRef.current === el.ID) {
            wasActiveTabDeleted = false;
          }

          newItems.push({
            key: el.Name + Math.random(),
            content: el.Name,
            onClick: () => { changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.ExternalTab, TabDetails: el, ActiveTabId: el.ID })) },
            tabcontent: <ExternalTabsView
              id={el.ID}
              url={el.URL}
            />,
            tabid: el.ID,
            active: activeTabIdRef.current === el.ID,
            viewtype: MenuActiveItem.ExternalTab
          });
        }
      });

      if (wasActiveTabDeleted) {
        changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.AgentView, ActiveTabId: TabViewIds.Agent }));
      }

      return newItems;
    });

    setExternalTabContentKey(Math.random() + "externalTabContentKey");
  }

  const createPopupExternalTabs = (callSession: CallSessionDTO, tabs: TabDTO[], isInACall: boolean) => {
    setExternalLinks((currentState: any) => {
      const sortedTabs = sortTabsByOrder(tabs);
      const newItems = [] as any[];

      sortedTabs.forEach(el => {
        let isVisibleTab = true;

        if (el.IsDeleted || !el.IsActive || el.Positioning !== TabPositioning.Telephony || el.Target !== TabTarget.Popup || (!isInACall && el.IsDynamic)) {
          isVisibleTab = false;
        }

        if (isInACall && el.IsDynamic) {
          el.URL = replaceVariables(el.URL, callSession);
        }

        if (isVisibleTab) {
          newItems.push({
            key: el.Name + Math.random(),
            content: el.Name,
            onClick: () => { openLink(el) },
            tabcontent: null,
            tabid: el.ID,
            active: false,
            viewtype: MenuActiveItem.ExternalLink,
            className: "external-link"
          });
        }
      });

      return newItems;
    });
  }

  const addSupervisorView = () => {
    //#warning-js [cc4all-1102]: use early return
    if (supervisorItem.length === 0) {
      setSupervisorItem((currentState: any) => {
        const newItems = [];
        newItems.push({
          key: "SupervisorView" + Math.random(),
          content: "Supervisor",
          onClick: () => { changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.Supervisor, ActiveTabId: TabViewIds.Supervisors })) },
          tabcontent: <></>,
          tabid: TabViewIds.Supervisors,
          active: false,
          viewtype: MenuActiveItem.Supervisor
        });

        return newItems;
      });
      setSupervisorTabContentKey(Math.random() + "supervisorTabContentKey");
    }
  }

  const deleteSupervisorTab = () => {
    //#warning-js [cc4all-1102]: use early return
    if (supervisorItem.length > 0) {
      setSupervisorItem([]);
      setSupervisorTabContentKey(Math.random() + "supervisorTabContentKey");
      changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.AgentView, ActiveTabId: TabViewIds.Agent }))
    }
  }

  const getOrder = (firstElOrder: number, secondElOrder: number) => {
    return ((secondElOrder > firstElOrder) ? -1 : 0);
  }

  const sortTabsByOrder = (tabs: TabDTO[]) => {
    return tabs.sort((a, b) => (a.Order > b.Order) ? 1 : getOrder(a.Order, b.Order))
  }

  const changeView = (activeItem: ActiveMediaView) => {
    setActiveTabId(activeItem.ActiveTabId ? activeItem.ActiveTabId : TabViewIds.Agent);
    activeTabIdRef.current = activeItem.ActiveTabId ? activeItem.ActiveTabId : TabViewIds.Agent;

    setAgentTab((currentState: any) => {
      return setActiveState(currentState, activeItem);
    });

    setCallHistoryTab((currentState: any) => {
      return setActiveState(currentState, activeItem);
    });

    setExternalItems((currentState: any) => {
      return setActiveState(currentState, activeItem);
    });

    setSupervisorItem((currentState: any) => {
      return setActiveState(currentState, activeItem);
    });

    if (activeItem.ActiveTabId && activeItem.ActiveTabId > 0) {
      mainViewHelper.tabViewChange.next(activeItem.ActiveTabId);
    }
  }

  const setActiveState = (currentState: any, activeItem: ActiveMediaView) => {
    currentState.forEach((el: any) => {
      if (el.tabid === activeItem.ActiveTabId) {
        el.active = true;

        if (el.tabid === TabViewIds.Supervisors) {
          el.tabcontent = <SupervisorView />
        }
      } else {
        el.active = false;

        if (el.tabid === TabViewIds.Supervisors) {
          el.tabcontent = <></>
        }
      }
    });

    return currentState;
  }

  const openLink = (tab: TabDTO) => {
    window.open(tab.URL, tab.Name, "height=600,width=600");
  }

  const replaceVariables = (url: string, callSession: CallSessionDTO) => {
    try {
      if (url) {
        const ani = callSession.CallerSIP ? callSession.CallerSIP : "{ani}";
        url = url.replace(/{ani}/g, ani);

        const ANI_NR = callSession.CallerUri ? callSession.CallerUri : "{ani_nr}";
        url = url.replace(/{ani_nr}/g, ANI_NR);

        const ANI_NAME = callSession.CallerName ? callSession.CallerName : "{ani_name}";
        url = url.replace(/{ani_name}/g, ANI_NAME);

        const CALLER_PHONE = callSession.CallerUri ? callSession.CallerUri : "{caller_phone}";
        url = url.replace(/{caller_phone}/g, CALLER_PHONE);

        const Queue = callSession.QueueRef ? callSession.QueueRef : "{queue}";
        url = url.replace(/{queue}/g, Queue + "");

        const skill = callSession.Skill ? callSession.Skill : "{skill}";
        url = url.replace(/{skill}/g, skill);

      }
    } catch (error) {
      logging.errorHandler.next(error);
    }
    return url;
  }

  const componentWillUnmount = () => {
    GeneralHelper.logCox(`in MediaTabs.tsx, in componentWillUnmount`);

    if (!EnvHelper.isStage3()) {
      subscriptionCurrentUserCallSessionChanged?.unsubscribe();
      subscriptionTabsChange?.unsubscribe();
      subscriptionIsUserSupervisor?.unsubscribe();
      subscriptionTabViewChange?.unsubscribe();
      subscriptionMissedCallNotificationReceived?.unsubscribe();
    }
  }

  const getTabDiv = (el: any, i: number, tabKey: string) => {
    return <div className={activeTabId === el.tabid ? "active-tab" : "hidden-tab"} key={`${tabKey}-${i}`}>{el.tabcontent}</div>
  }

  React.useEffect(() => {
    initialize();
    return () => componentWillUnmount();
  }, []);

  React.useEffect(() => {
    isSupervisor ? addSupervisorView() : deleteSupervisorTab();
  }, [isSupervisor]);

  return (
    <>
      <Flex className="menu-tabs-container">
        <Flex.Item>
          <Menu
            items={agentTab}
            underlined
            primary
            accessibility={tabListBehavior}
            aria-label="External tabs"
            styles={({ theme: { siteVariables } }) => ({
              margin: '0 0 20px',
            })}
            className="menu-tabs"
          />
        </Flex.Item>
        {!EnvHelper.isStage3() ?
          <Flex.Item>
            <Menu
              items={supervisorItem}
              underlined
              primary
              accessibility={tabListBehavior}
              aria-label="External tabs"
              styles={({ theme: { siteVariables } }) => ({
                margin: '0 0 20px',
              })}
              className="menu-tabs hideTab"
            />
          </Flex.Item> : null
        }
        {!EnvHelper.isStage3() ?
          <Flex.Item>
            <Menu
              items={callHistoryTab}
              underlined
              primary
              accessibility={tabListBehavior}
              aria-label="External tabs"
              styles={({ theme: { siteVariables } }) => ({
                margin: '0 0 20px',
              })}
              className="menu-tabs"
            />
          </Flex.Item> : null
        }
        {!EnvHelper.isStage3() ?
          <Flex.Item>
            <Menu
              items={externalItems}
              underlined
              primary
              accessibility={tabListBehavior}
              aria-label="External tabs"
              styles={({ theme: { siteVariables } }) => ({
                margin: '0 0 20px',
              })}
              className="menu-tabs"
            />
          </Flex.Item> : null}
        {!EnvHelper.isStage3() ?
          <Flex.Item>
            <Menu
              items={externalLinks}
              underlined
              primary
              accessibility={tabListBehavior}
              aria-label="External links"
              styles={({ theme: { siteVariables } }) => ({
                margin: '0 0 20px',
              })}
              className="menu-tabs menu-links"
            />
          </Flex.Item>
          : null
        }
      </Flex>
      <div key="staticTabContentKey">
        {
          agentTab.map((el: any, i: number) => {
            return getTabDiv(el, i, 'agentTab-id')
          })
        }
        {
          callHistoryTab.map((el: any, i: number) => {
            return getTabDiv(el, i, 'callHistoryTab-id')
          })
        }
      </div>
      {
        !EnvHelper.isStage3() ?
          <div key={externalTabContentKey}>
            {
              externalItems.map((el: any, i: number) => {
                return getTabDiv(el, i, 'externalTab-id')
              })
            }
          </div> : null
      }
      {
        !EnvHelper.isStage3() ?
          <div key={supervisorTabContentKey}> { /*#warning-js: if using a single item, could use its key... */}
            {
              supervisorItem.map((el: any, i: number) => {
                return getTabDiv(el, i, 'supervisorTab-id')
              })
            }
          </div> : null
      }
    </>
  )
};

export default MediaTabs;

import { LocationIcon } from '@fluentui/react-northstar';
import React from 'react';
import { GeneralHelper } from 'utils/helpers';

export const CustomAgendaEventTemplate = (props: any) => {
  const { intl, dataContext } = props;
  const event = dataContext.event;

  const formatDate = () => {
    if (event.isAllDay) {
      return intl.formatMessage({ id: "Agenda.AllDay" });
    }

    const startDate = getFormattedHour(event.start);
    const endDate = getFormattedHour(event.end);

    return `${startDate} - ${endDate}`;
  }

  const getFormattedHour = (eventDateObject: any) => {
    const convertFromUtc = eventDateObject.timeZone?.toLocaleLowerCase() === "utc";

    return GeneralHelper.formatHourAMPMFromStringInput(eventDateObject.dateTime, convertFromUtc)?.toLocaleUpperCase();
  }

  return (<>
    <div className="custom-event">
      <div className="event-time-container">
        <div className="event-time">{formatDate()}</div>
      </div>
      <div className="event-details-container">
        <div className={event.location?.displayName ? "event-subject" : "event-subject center-vertically"} aria-label={event.subject}>{event.subject}</div>
        {event.location?.displayName && <div className="event-location-container">
          <div className="event-location-icon">
            <LocationIcon outline />
          </div>
          <div className="event-location" aria-label={event.location.displayName}>{event.location.displayName}</div>
        </div>
        }
      </div>
      <div className="event-other-container"></div>
    </div>
  </>);
};
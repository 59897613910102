export class TabDTO {
    ID!: number;
    CompanyRef!: number
    Name!: string;
    URL !: string;
    Target !: TabTarget;
    Positioning!: TabPositioning
    Order!: number;
    IsActive!: boolean;
    IsDeleted!: boolean;
    IsDynamic!: boolean;
}

export enum TabTarget {
    Inside = 1,
    Popup
}
export enum TabPositioning {
    Telephony = 1,
    Web_Chat
}
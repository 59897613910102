import React, { useState, useRef } from 'react';
import { Flex, Text, EmailIcon, PhoneClockIcon, ChatIcon, Button, CallIcon } from '@fluentui/react-northstar';
import Lockr from 'lockr';
import Timer from '../../../../components/Timer';
import './WaitingCalls.scss';
import { Paginator } from '../../../../containers';
import { socketQueue } from '../../../../services/queue';
import { socketAudio } from '../../../../services/calling';
import { ConferenceStatusAction, MediaType, StorageKeys } from '../../../../utils';
import { WaitingMediaDto } from '../../../../utils/domain/waitingMediaDTO';
import { CallSessionDTO } from '../../../../utils/domain/callSessionDTO';
import AdvancedTable, { stringCellComparator } from '../../../../components/AdvancedTable';
import { useIntl } from 'react-intl';
import { waitingListManager } from '../../../../services/io/waiting-list';
import GeneralHelper from '../../../../utils/helpers/general-helper';
import { Subscription } from 'rxjs';
import { EnvHelper } from 'utils/helpers';

export const WaitingCalls = () => {

  const WaitingCallsViewWaiting = "WaitingCallsView.Waiting";
  const CallerNameColumn = "caller-name-column";
  const CallerPhoneColumn = "caller-phone-column";
  const waitingTableKeyValue = "waiting-table-key";
  const smallerGrowClassValue = "smaller-grow table-header-column";
  const [waitingMediaRender, setWaitingMediaRender] = useState(Math.random());
  const [waitingTableKey, setWaitingTableKey] = useState(Math.random() + waitingTableKeyValue);

  const currentPage = useRef(1);
  const numberOfPages = useRef(1);
  const paginatorExists = useRef(false);
  const initialGetIsDone = useRef(false);
  const waitingMediaObjectList = useRef<WaitingMediaDto[]>([]);
  const waitingMediaList = useRef<any[]>([]);//#warning-js: not very clear, what's different in waitingMediaObjectList, from waitingMediaList
  const waitingMediaListShown = useRef<any[]>([]);
  const waitingMediaCount = useRef(0);
  const queueMemberd = useRef<any>([]);
  const waitingMediaReceivedCopy = useRef<any>();
  const intl = useIntl();

  let subscriptionWaitingAdded: Subscription | null = null;
  let subscriptionWaitingUpdated: Subscription | null = null;
  let subscriptionWaitingRemoved: Subscription | null = null;
  let subscriptionCurrentUserCallSessionChanged: Subscription | null = null;
  let subscriptionCallSessionStateChanged: Subscription | null = null;
  let subscriptionSocketQueueChanged: Subscription | null = null;

  const ongoingCallOperations = GeneralHelper.getOngoingCallOperations();
  const endOperations = GeneralHelper.getEndOperations();

  React.useEffect(() => {
    initialize();

    return () => {
      componentWillUnmount();
    }
  }, []);

  const initialize = () => {
    GeneralHelper.logCox(`in WaitingCalls.tsx, in initialize`);
    subscriptionWaitingAdded?.unsubscribe();
    subscriptionWaitingAdded = waitingListManager.onWaitingAdded.subscribe((obj: WaitingMediaDto) => {
      if (obj !== waitingMediaReceivedCopy.current && initialGetIsDone.current === true) {
        checkForOngoingCallAndRefreshList();
      }
      waitingMediaReceivedCopy.current = obj;
    })

    subscriptionWaitingUpdated?.unsubscribe();
    subscriptionWaitingUpdated = waitingListManager.onWaitingUpdated.subscribe((obj: WaitingMediaDto) => {
      const indexToUpdate = getElementIndexBySessionId(obj.SessionID || obj.SessionId);
      if (indexToUpdate != null) {
        waitingMediaObjectList.current = waitingMediaObjectList.current.map((item, index) => {
          return index === indexToUpdate ? obj : item
        });
        checkForOngoingCallAndRefreshList();
      }
    });

    subscriptionWaitingRemoved?.unsubscribe();
    subscriptionWaitingRemoved = waitingListManager.onWaitingRemoved.subscribe((obj: WaitingMediaDto) => {
      const indexToDelete = getElementIndexBySessionId(obj.SessionID || obj.SessionId);
      if (indexToDelete != null) {
        waitingMediaObjectList.current = waitingMediaObjectList.current.filter((item, index) => indexToDelete !== index);
        checkForOngoingCallAndRefreshList();
      }
    });

    subscriptionCurrentUserCallSessionChanged?.unsubscribe();
    subscriptionCurrentUserCallSessionChanged = socketAudio.currentUserCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      setWaitingMediaRender(Math.random());
      setWaitingTableKey(Math.random() + waitingTableKeyValue);
    });

    subscriptionCallSessionStateChanged?.unsubscribe();
    subscriptionCallSessionStateChanged = socketAudio.callSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      const isOngoingCall = ongoingCallOperations.includes(callSessionDTO.ConferenceActionAsString) && callSessionDTO.IsCurrentUserThePrimaryAgent;
      GeneralHelper.logCox(`[CC4ALL-3935] in WaitingCalls.tsx, in ConferenceActionAsString ${callSessionDTO.ConferenceActionAsString}`);
      getWaitingMedia(isOngoingCall);
    });

    subscriptionSocketQueueChanged?.unsubscribe();
    subscriptionSocketQueueChanged = socketQueue.subjectQueueChange.subscribe((obj: any) => {
      const unmemberedQueueRefs = queueMemberd.current.filter((x: any) => !obj.map((o: any) => o.QueueRef).includes(x));
      if (unmemberedQueueRefs.length > 0) {
        unmemberedQueueRefs.forEach((x: any) => deleteWaitingMediaByQueueId(x))
      }

      const memberedQueueRefs = obj.map((o: any) => o.QueueRef).filter((z: any) => !queueMemberd.current.includes(z));
      if (memberedQueueRefs.length > 0 && waitingMediaList.current.length === 0) {
        checkForOngoingCallAndRefreshList();
      }

      queueMemberd.current = obj.map((o: any) => o.QueueRef);
    });

    getWaitingMedia(false);
  };

  const componentWillUnmount = () => {
    GeneralHelper.logCox(`in WaitingCalls.tsx, in componentWillUnmount`);

    subscriptionWaitingAdded?.unsubscribe();
    subscriptionWaitingUpdated?.unsubscribe();
    subscriptionWaitingRemoved?.unsubscribe();
    subscriptionCurrentUserCallSessionChanged?.unsubscribe();
    subscriptionCallSessionStateChanged?.unsubscribe();
    subscriptionSocketQueueChanged?.unsubscribe();
  };

  const columnsMembers = EnvHelper.isStage3() ? [
    {
      title: intl.formatMessage({ id: "WaitingCallsView.Media" }), key: 'Media',
      name: 'WaitingCallsMediaColumn', cellComparator: stringCellComparator, className: "smallest-grow"
    },
    {
      title: intl.formatMessage({ id: "WaitingCallsView.CallerName" }), key: 'Caller',
      name: 'WaitingCallsCallerColumn', cellComparator: stringCellComparator, className: CallerNameColumn
    },
    {
      title: intl.formatMessage({ id: "WaitingCallsView.CallerPhone" }), key: 'Caller',
      name: 'WaitingCallsCallerColumn', cellComparator: stringCellComparator, className: CallerPhoneColumn
    },
    {
      title: intl.formatMessage({ id: WaitingCallsViewWaiting }), key: 'Waiting',
      name: 'WaitingCallsWaitingColumn', cellComparator: stringCellComparator, className: smallerGrowClassValue
    },
    {
      title: intl.formatMessage({ id: "WaitingCallsView.Queue" }), key: 'Queue',
      name: 'WaitingCallsQueueColumn', cellComparator: stringCellComparator, className: smallerGrowClassValue
    },
    {
      title: intl.formatMessage({ id: "WaitingCallsView.Status" }), key: 'Status',
      name: 'WaitingCallsStatusColumn', cellComparator: stringCellComparator, className: smallerGrowClassValue
    }
  ] : [
    {
      title: intl.formatMessage({ id: "WaitingCallsView.Media" }), key: 'Media',
      name: 'WaitingCallsMediaColumn', cellComparator: stringCellComparator, className: "smallest-grow"
    },
    {
      title: intl.formatMessage({ id: "WaitingCallsView.Caller" }), key: 'Caller',
      name: 'WaitingCallsCallerColumn', cellComparator: stringCellComparator, className: CallerNameColumn
    },
    {
      title: intl.formatMessage({ id: WaitingCallsViewWaiting }), key: 'Waiting',
      name: 'WaitingCallsWaitingColumn', cellComparator: stringCellComparator, className: smallerGrowClassValue
    },
    {
      title: intl.formatMessage({ id: "WaitingCallsView.Queue" }), key: 'Queue',
      name: 'WaitingCallsQueueColumn', cellComparator: stringCellComparator, className: smallerGrowClassValue
    },
    {
      title: intl.formatMessage({ id: "WaitingCallsView.Skill" }), key: 'Skill',
      name: 'WaitingCallSkillColumn', cellComparator: stringCellComparator, className: smallerGrowClassValue
    },
    {
      title: intl.formatMessage({ id: "WaitingCallsView.Status" }), key: 'Status',
      name: 'WaitingCallsStatusColumn', cellComparator: stringCellComparator, className: smallerGrowClassValue
    },
    {
      title: intl.formatMessage({ id: "WaitingCallsView.CallPickup" }), key: 'CallPickup',
      name: 'WaitingCallsCallPickupColumn', cellComparator: stringCellComparator, className: "pickup-column"
    },
  ];

  const getWaitingMedia = (isOngoingCall: boolean) => {
    GeneralHelper.logCox(`[CC4ALL-3935] in WaitingCalls.tsx, in getWaitingMedia ${isOngoingCall}`);
    waitingListManager.getWaitingMediaByAgent(Lockr.get<number>(StorageKeys.UserId)).then((response: WaitingMediaDto[]) => {


      response.forEach((element: WaitingMediaDto) => {
        const alreadyAddedObj = waitingMediaObjectList.current.filter(object => (object.SessionID || object.SessionId) ===
          (element.SessionID || element.SessionId))
        if (alreadyAddedObj.length === 0) {
          GeneralHelper.logCox(`in WaitingCalls.tsx, after getWaitingMediaByAgent, adding ${element.SessionID} to waitingMediaObjectList.current`);
          waitingMediaObjectList.current = [...waitingMediaObjectList.current, element];
        }
      });

      initialGetIsDone.current = true;
      mapMediaListObjects(isOngoingCall)
    }).catch(err => {
      console.log(err.message);
    });
  }

  const mapMediaListObjects = (isOngoing: boolean) => {
    waitingMediaList.current = []
    waitingMediaObjectList.current.forEach((element: WaitingMediaDto) => {
      addWaitingMedia(element, isOngoing);
    });
    reinitializeMedia();
  }

  const getElementIndexBySessionId = (sessionID: any) => {
    const searchedObject = waitingMediaObjectList.current.filter(element => element.SessionID === sessionID ||
      element.SessionId == sessionID)[0];
    return searchedObject ? waitingMediaObjectList.current.indexOf(searchedObject) : null;
  };

  const getElementIndexesByQueueId = (queueId: number) => {
    const searchedObject = waitingMediaList.current.filter(element => element.queueid === queueId);
    const indexes: any = [];
    searchedObject.forEach(element => indexes.push(waitingMediaList.current.indexOf(element)))
    return searchedObject ? indexes : null;
  };

  const deleteWaitingMediaByQueueId = (queueId: number) => {
    const indexes = getElementIndexesByQueueId(queueId);
    indexes.sort((a: any, b: any) => a - b).reverse().forEach((index: any) => {
      if (index != null) {
        waitingMediaList.current.splice(index, 1);
        reinitializeMedia();
      }
    });
  }

  const addWaitingMedia = (obj: WaitingMediaDto, isOngoing: boolean) => {
    const alreadyAddedObj = waitingMediaList.current.filter(element => element.key === obj.SessionID)[0];
    if (!alreadyAddedObj) {
      waitingMediaList.current = [...waitingMediaList.current, mapItem(obj, isOngoing)];
    }
  }

  const reinitializeMedia = () => {
    reinitializeWaitingMediaList();
    setWaitingMediaRender(Math.random());
    setWaitingTableKey(Math.random() + waitingTableKeyValue);
  }

  const mapItem = (obj: any, isOngoing: boolean) => {
    return {
      key: obj.SessionID || obj.SessionId,
      queueid: obj.QueueRef,
      items: EnvHelper.isStage3() ?
        [
          {
            content: getMediaTypeIcon(obj.MediaType, obj.SessionID),
            key: obj.SessionId.toString() + '-0', className: GeneralHelper.getMediaTypeContainerClassName(obj.MediaType)
          },
          { content: obj.CallerName || obj.CallerPhone, key: obj.SessionId.toString() + '-1', className: CallerNameColumn },
          { content: obj.CallerPhone, key: obj.SessionId.toString() + '-1', className: CallerPhoneColumn },
          { content: <Timer startDate={obj.StartDate}></Timer>, key: obj.SessionId.toString() + '-2', className: smallerGrowClassValue },
          { content: obj.QueueName, key: obj.SessionId.toString() + '-3', className: smallerGrowClassValue },
          {
            content: obj.IsAssigned ? intl.formatMessage({ id: "WaitingCallsView.Offering" }) : intl.formatMessage({ id: 'WaitingCallsView.StatusWaiting' }),
            key: obj.SessionId.toString() + '-5', className: smallerGrowClassValue
          }
        ] : [
          {
            content: getMediaTypeIcon(obj.MediaType, obj.SessionID),
            key: (obj.SessionID || obj.SessionId).toString() + '-0', className: GeneralHelper.getMediaTypeContainerClassName(obj.MediaType)
          },
          { content: getCallerName(obj.SourceDisplayName, obj.Source), key: (obj.SessionID || obj.SessionId).toString() + '-1', className: CallerNameColumn },
          { content: <Timer startDate={obj.StartDate}></Timer>, key: (obj.SessionID || obj.SessionId).toString() + '-2', className: smallerGrowClassValue },
          { content: obj.QueueName, key: (obj.SessionID || obj.SessionId).toString() + '-3', className: smallerGrowClassValue },
          { content: obj.SkillName ? obj.SkillName : "", key: (obj.SessionID || obj.SessionId).toString() + '-4', className: smallerGrowClassValue },
          {
            content: obj.IsAssigned ? intl.formatMessage({ id: "WaitingCallsView.Offering" }) : intl.formatMessage({ id: 'WaitingCallsView.StatusWaiting' }),
            key: (obj.SessionID || obj.SessionId).toString() + '-5', className: smallerGrowClassValue
          },
          {
            content: getPickUpButton(obj.IsCallPickupEnabled, obj.IsAssigned, (obj.SessionID || obj.SessionId), isOngoing),
            key: (obj.SessionID || obj.SessionId).toString() + waitingTableKey, className: "pickup-column"
          },
        ]
    }
  }

  const getCallerName = (sourceDisplayName: string, source: string) => {
    return sourceDisplayName === "" || sourceDisplayName === undefined || sourceDisplayName == null ? GeneralHelper.formatSource(source.replace("sip:", "")) :
      GeneralHelper.formatSource(sourceDisplayName)
  }

  const getPickUpButton = (isCallPickupEnabled: boolean, isAssigned: boolean, sessionId: string, isOngoing: boolean) => {
    GeneralHelper.logCox(`[CC4ALL-3935] WaitingCalls: isOngoing is ${isOngoing}`);
    if (isCallPickupEnabled && !isOngoing && !isAssigned) {
      return <Button circular icon={<CallIcon size="small" />} size="small" className="pickup-button" primary
        title={intl.formatMessage({ id: "WaitingCallsView.CallPickup" })}
        onClick={() => { pickUpButtonOnClick(sessionId) }} />
    }
    return "";
  }

  const pickUpButtonOnClick = (sessionId: string) => {
    mapMediaListObjects(true);
    waitingListManager.PickUpCall({ agentRef: Lockr.get<number>(StorageKeys.UserId), sessionId: sessionId });
    setWaitingMediaRender(Math.random());
    setWaitingTableKey(Math.random() + waitingTableKeyValue);
  }

  const getMediaTypeIcon = (mediaType: number, sessionId: string | undefined) => {
    switch (mediaType) {
      case MediaType.Call:
        return <PhoneClockIcon className="call-type-icon-color" title={sessionId ?? ""}></PhoneClockIcon>
      case MediaType.Chat:
        return <ChatIcon className="call-type-icon-color" title={sessionId ?? ""}></ChatIcon>
      case MediaType.Mail:
        return <EmailIcon className="call-type-icon-color" title={sessionId ?? ""}></EmailIcon>
      case MediaType.SocialMedia:
        return <img alt='Media Icon' width="15" className="call-type-icon-color social-media-icon" title={sessionId ?? ""} />
      default:
        return <></>
    }
  }

  const reinitializeWaitingMediaList = () => {
    waitingMediaCount.current = waitingMediaList.current.length;
    paginatorExists.current = waitingMediaCount.current > 10;

    initializeNumberOfPages(waitingMediaCount.current);
    if (numberOfPages.current < currentPage.current) {
      currentPage.current = numberOfPages.current;
    }

    waitingMediaListShown.current = waitingMediaList.current.slice((currentPage.current - 1) * 10, currentPage.current * 10);
  }

  const initializeNumberOfPages = (result: any) => {
    var number = 0;
    if (result % 10 === 0) {
      number = Math.floor(result / 10);
    }
    else {
      number = Math.floor(result / 10) + 1;
    }

    numberOfPages.current = number === 0 ? 1 : number;
  }

  const selectPage = (e: any) => {
    waitingMediaListShown.current = waitingMediaList.current.slice((e - 1) * 10, e * 10);
    currentPage.current = e;
    setWaitingMediaRender(Math.random());
  }

  const checkForOngoingCallAndRefreshList = () => {
    let isOngoingCall = false;

    const callSession = socketAudio.getCallSessionForCurrentUser();

    if (callSession.ConversationKey && !endOperations.includes(callSession.ConferenceActionAsString)) {
      isOngoingCall = true;
    }

    getWaitingMedia(isOngoingCall);
  }

  const getWaitingMediaCount = () => {
    return waitingMediaCount.current === 0 ? "" : ` (${waitingMediaCount.current.toString()})`;
  }

  let paginator: any = null;
  if (paginatorExists.current) {
    paginator = <Paginator
      currentPage={currentPage.current}
      totalPages={numberOfPages.current}
      onChange={selectPage}
    />
  }

  return (
    <Flex.Item>
      <Flex column key={waitingMediaRender}>
        <Text content={intl.formatMessage({ id: "AgentView.WaitingCalls" }) + getWaitingMediaCount()} weight="bold" />

        <AdvancedTable key={waitingTableKey} columns={columnsMembers} rows={waitingMediaListShown.current} label="Waiting Calls" className="waiting-list-table" />
        {paginator}
      </Flex>
    </Flex.Item>
  )
};

export default WaitingCalls;

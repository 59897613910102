export default interface RecipientData {
    id?: string;
    name: string;
    email: string;
}

export enum RecipientType {
    To,
    Cc,
    Bcc
}
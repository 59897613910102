import { Flex, FlexItem } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import './choiceGroup.scss'

export interface IChoiceGroupProps {
    defaultSelected?: string;
    value?: string;
    toggle?: boolean;
    push?: boolean;
    items: IChoiceGroupItem[];
    onChange: (item: IChoiceGroupItem, selected: boolean) => void;
}

export interface IChoiceGroupItem {
    key: string;
    label: string;
}

const ChoiceGroup = (props: IChoiceGroupProps) => {
    const { defaultSelected, value, toggle, push, items, onChange } = props;
    const [selected, setSelected] = useState<string | undefined>(defaultSelected);

    useEffect(() => {
        if (!!value) {
            setSelected(value);
        }
    });

    const handleOnClick = (item: IChoiceGroupItem) => {
        if (item.key === selected && !toggle) 
        {
            return;
        }

        const newValue = toggle && selected === item.key ? undefined : item.key;
        setSelected(newValue);
        onChange(item, !!newValue);
    }

    return (
        <FlexItem push={push}>
            <Flex
                inline
                gap="gap.smaller"
            >
                {
                    items.map((x: IChoiceGroupItem) => {
                        const isSelected = x.key === selected;

                        return <button 
                            className={`filter-label${isSelected ? ' selected' : ''}`}
                            key={x.key} 
                            onClick={() => handleOnClick(x)}
                        >
                            {x.label}
                        </button>
                    })
                }
            </Flex>
        </FlexItem>
    );
}

export default ChoiceGroup;
import * as React from 'react';
import { Button, CallIcon, Flex, MenuButton, Text } from '@fluentui/react-northstar';
import { CallableContactColumnsEnum } from '../../utils';

const SipAndPhoneNumberMenu = (props: any) => {
    const { callBack,
        classNameProp,
        triggerTitle,
        sip,
        phoneNumber,
        businessPhone,
        mobilePhone,
        isWarmTransfer,
        dtoDetails,
        triggerButton,
        isDisabledProp } = props;
    const className = classNameProp || '';
    const isDisabled = isDisabledProp || false;
    const formatedSip = sip?.replace("sip:", '');

    const getIconForCallableItems = (columnName: CallableContactColumnsEnum) => {
        switch (columnName) {
            case CallableContactColumnsEnum.LocalPhone:
                return <CallIcon outline />;
            case CallableContactColumnsEnum.MobilePhone:
                return <img alt='Mobile Phone Icon' width="17" className="mobile-phone-icon" />
            case CallableContactColumnsEnum.BusinessPhones:
                return <img alt='Business Phone Icon' width="15" className="business-phone-icon" />
            default:
                return <></>
        }
    }

    let items = [] as any;
    if (phoneNumber) {
        items = [...items, {
            content:
                <Flex style={{ overflow: 'hidden', minWidth: '350px' }} gap="gap.small" vAlign="center" >
                    {getIconForCallableItems(CallableContactColumnsEnum.LocalPhone)}<Text content={phoneNumber} />
                </Flex>,
            key: `item-details-phone-${phoneNumber}${Math.random()}`,
            onClick: () => { callBack(dtoDetails, CallableContactColumnsEnum.LocalPhone, isWarmTransfer) }
        }];
    }

    if (businessPhone) {
        items = [...items, {
            content:
                <Flex style={{ overflow: 'hidden', minWidth: '350px' }} gap="gap.small" vAlign="center" >
                    {getIconForCallableItems(CallableContactColumnsEnum.BusinessPhones)}<Text content={businessPhone} />
                </Flex>,
            key: `item-details-phone-${businessPhone}${Math.random()}`,
            onClick: () => { callBack(dtoDetails, CallableContactColumnsEnum.BusinessPhones, isWarmTransfer) }
        }];
    }

    if (mobilePhone) {
        items = [...items, {
            content:
                <Flex style={{ overflow: 'hidden', minWidth: '350px' }} gap="gap.small" vAlign="center" >
                    {getIconForCallableItems(CallableContactColumnsEnum.MobilePhone)}<Text content={mobilePhone} />
                </Flex>,
            key: `item-details-phone-${mobilePhone}${Math.random()}`,
            onClick: () => { callBack(dtoDetails, CallableContactColumnsEnum.MobilePhone, isWarmTransfer) }
        }];
    }

    if (formatedSip) {
        items = [...items, {
            content:
                <Flex style={{ overflow: 'hidden', minWidth: '350px' }} gap="gap.small" vAlign="center" >
                    <img alt='SIP Icon' width="13" className="sip-icon" /><Text content={formatedSip} />
                </Flex>,
            key: `item-details-sip-${sip}${Math.random()}`,
            onClick: () => { callBack(dtoDetails, CallableContactColumnsEnum.Sip, isWarmTransfer) }
        }];
    }

    return (
        <MenuButton align="end" className={className}
            trigger={<Button disabled={isDisabled} icon={triggerButton} iconOnly text title={triggerTitle} />}
            menu={items}
            on="click"
        />
    );
}

export default SipAndPhoneNumberMenu;

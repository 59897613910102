import { Button, Flex } from '@fluentui/react-northstar';
import MediaTabs from './MediaTabs';
import NavigationView from 'containers/NavigationView';
import { useSubjectListenerEffect } from 'utils/helpers/listenerHook';
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import React, { useMemo, useState } from 'react';

export const MediaView = () => {
  const [showLeftPanelTrigger, setShowLeftPanelTrigger] = useState<boolean>(false);
  const [isLeftPanelActive, setIsLeftPanelActive] = useState<boolean>(false);

  useSubjectListenerEffect((flag: boolean) => setShowLeftPanelTrigger(flag), mainViewHelper.showHideLeftPanelTriggerButtons);

  const navigationViewsClass = useMemo(() => 
    `app-left-panel ${isLeftPanelActive ? 'active' : ''} ${showLeftPanelTrigger ? '' : 'no-display'}`
  , [isLeftPanelActive, showLeftPanelTrigger]);

  return (
    <Flex>
      <Flex.Item>
        <>
          <div className={navigationViewsClass}>
            <NavigationView />
          </div>
          {
            showLeftPanelTrigger &&
            <div className="left-panel-trigger-buttons">
              <Button
                key="leftPanelBtnKey"
                circular
                primary
                className={`show-hide-left-panel-btn${isLeftPanelActive ? ' active' : ''}`}
                title={isLeftPanelActive ? "Hide" : "Show"}
                size="medium"
                content={<i className="arrow"></i>}
                onClick={() => setIsLeftPanelActive(prev => !prev)}
              />
            </div>
          }
        </>
      </Flex.Item>
      <Flex.Item >
        <div className="calls-container">
          <Flex.Item>
            <Flex column className="media-container"
              styles={({ theme: { siteVariables } }) => ({
                backgroundColor: siteVariables.colorScheme.default.background2,
                color: siteVariables.colorScheme.default.foreground2,
              })}
            >
              <MediaTabs />
            </Flex>
          </Flex.Item>
        </div>
      </Flex.Item>
    </Flex>)
};

export default MediaView;

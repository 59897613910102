import { PresenceAvailability } from "utils";

export class WrapUpSettings {
    SessionId!: string;
    QueueRef!: number;
    AgentRef!: number;
    SipAddress!: string;
    WrapUpTimeInterval!: number;
    Presence!: PresenceAvailability;
    Reason!: string;
    WrapupTopicRef?: number;
    WrapupCollectionRef?: number;
    WrapupTimerVisibility!: boolean;
    RecordDate?: Date;

    constructor(data?: any) {
        Object.assign(this, data);
    }

    get EndDate(): Date | undefined {
        if (!this.RecordDate || !this.WrapUpTimeInterval) {
            return undefined;
        }
        const endDate = new Date(new Date(this.RecordDate).getTime() + this.WrapUpTimeInterval);
        return endDate;
    }

}

import { SocialMediaStatusType } from "utils";
import { SocialMediaDto } from "./socialMediaDto";

export class SocialMediaSessionDTO {
    SocialMedia!: SocialMediaDto;
    SessionId!: string;
    AgentSip!: string;
    StatusType!: SocialMediaStatusType;
    QueueId?: number;
    StartDate!: Date;
    CMMessages?: CMMediaSessionMessageDTO[];
}

export class CMMediaSessionMessageDTO {
    Reference?: string;
    FromNumber?: string;
    FromName?: string;
    ToNumber?: string;
    MessageText?: string;
    MediaUri?: string;
    MediaContentType?: string;
    MediaTitle?: string;
    Channel?: string;
    Time?: Date;
    TimeUtc?: Date;
    StatusCode!: CMStatusCode;
    SourceType!: CMSourceType;
    SessionId?: string;
}

export enum CMStatusCode {
    Ok = 0,
    Unknown = 999,
    AuthenticationFailed = 101,
    InsuficientBalance = 102,
    TokenIncorrect = 103,
    RequestMessageErrors = 201,
    MalformedRquest = 202,
    IncorrectRequestMsgArray = 203,
    InvalidFromField = 301,
    InvalidToField = 302,
    InvalidPhoneNumber = 303,
    InvalidBody = 304,
    InvalidField = 305,
    SpamMessage = 401,
    BlackListMessage = 402,
    RejectedMessage = 403,
    ServerError = 500
}
export enum CMSourceType {
    FromCustomer,
    FromAgent
}
import { SocketQueue } from './socket-queue';
import { QueueService } from "./service-queue";

export interface ApiQueue {
    getQueues(userRef: number): Promise<any>;
    clientQueuesChanged(clientSip: string, queueRef: number, isJoined: boolean, changedBySupervisor: boolean): Promise<any>;
}

// Stage2
export const socketQueue = new SocketQueue();
// Stage3
export const queuesService = new QueueService();

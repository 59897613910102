import React, { useCallback, useEffect, useMemo, useState } from "react";
import './EmailEditor.css';
import { Button, Flex, PaperclipIcon, TrashCanIcon } from "@fluentui/react-northstar";
import RecipientData, { RecipientType } from "./models/Recipient";
import RecipientsView from "./components/RecipientsView";
import { useIntl } from "react-intl";
import EmailData from "./models/EmailData";
import SubjectInput from "./components/SubjectInput";
import FileInput from "./components/FileInput";
import AttachmentItem from "./components/AttachmentItem";
import { AttachmentData } from "./models/AttachmentData";
import TextEditor from "./components/TextEditor";
import TemplatePopup, { Template } from "./components/TemplatePopup";
import { debounce } from "utils/helpers";

interface EmailEditorProps {
    to?: RecipientData[]
    showSubject?: boolean;
    onClose: () => void;
}

const EmailEditor = (props: EmailEditorProps) => {
    const { to, showSubject, onClose } = props;
    const intl = useIntl();
    const [emailData, setEmailData] = useState<EmailData>(new EmailData());
    const [visibleDestinations, setVisibleDestinations] = useState<RecipientType[]>([RecipientType.To]);
    const [isDragAndDropActive, setDragAndDropActive] = useState<boolean>(false);

    useEffect(() => {
        setEmailData(prev => ({...prev, 
            [RecipientType[RecipientType.To].toLowerCase()]: to || [
                {name: "Simion Schiopu", email: "simion.simion@expertnetwork.ro"}
            ],
            [RecipientType[RecipientType.Bcc].toLowerCase()]: [],
            [RecipientType[RecipientType.Cc].toLowerCase()]: [],
        }));
    }, [to]);

    useEffect(() => {
        const visible = [RecipientType.To];
        
        if (!!emailData.bcc.length) {
            visible.push(RecipientType.Bcc);
        }
        if (!!emailData.cc.length) {
            visible.push(RecipientType.Cc);
        }
        
        setVisibleDestinations(visible);
    }, [to]);

    const sendButtonHandler = useCallback(() => {
        console.error(`[To Implement] Send Email with data: ${JSON.stringify(emailData)}`);
        alert(JSON.stringify(emailData));
    }, [emailData]);

    const fileSelectedHandler = useCallback((file: File) => {
        setEmailData(prev => ({...prev, attachments: [...prev.attachments, { 
            name: file.name, 
            size: file.size,
            file: file,
            url: "https://images.pexels.com/photos/1624496/pexels-photo-1624496.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        }]}))
    }, []);

    const onAddContactHandler = useCallback((type: RecipientType, contact: RecipientData) => {
        const typeFieldName = fieldName(type);
        const contactsByType = emailData[typeFieldName] as RecipientData[];

        if (contactsByType.some(x => x.email === contact.email)) {
            return;
        }

        setEmailData(prev => ({...prev, [typeFieldName]: [...contactsByType, contact]}));
    }, [emailData]);

    const onRemoveContactHandler = useCallback((type: RecipientType, contact: RecipientData) => {
        const typeFieldName = fieldName(type);
        const array = emailData[typeFieldName] as RecipientData[];
        setEmailData(prev => ({...prev, [typeFieldName]: array.filter(x => x !== contact)}));
    }, [emailData]);

    const titleByContactType = useCallback((type: RecipientType) => intl.formatMessage({ id: `EmailEditor.${RecipientType[type]}Destination` }), [intl]);

    const fieldName = (type: RecipientType): keyof EmailData => RecipientType[type].toLowerCase() as keyof EmailData;

    const destinationItems = useMemo(() => Object.keys(RecipientType)
        .filter(x => !isNaN(Number(x)))
        .map((type: string) => Number(type) as RecipientType)
        .filter((type: RecipientType) => visibleDestinations.includes(type))
        .map((type: RecipientType) => (<RecipientsView
                key={type}
                type={type}
                visibleTypes={visibleDestinations}
                title={titleByContactType(type)}
                recipients={emailData[fieldName(type)] as RecipientData[]} 
                onRemove={onRemoveContactHandler}
                onAddContact={onAddContactHandler}
                showDestinationType={(destination: RecipientType) => setVisibleDestinations(prev => ([...prev, destination]))}
            />)
    ), [emailData, visibleDestinations, onRemoveContactHandler, onAddContactHandler, titleByContactType]);

    const removeAttachmentHandler = useCallback((item: AttachmentData) => {
        setEmailData(prev => ({...prev, attachments: prev.attachments.filter(x => x !== item)}));
    }, []);

    const attachments = useMemo(() => (
        <Flex wrap gap='gap.small'>
        {
            emailData.attachments.map((item, index) => (
                <AttachmentItem
                    removable
                    key={`${index}${item.name}`}
                    item={item}
                    onRemove={(attachment: AttachmentData) => removeAttachmentHandler(attachment)}
                />
            ))
        }
        </Flex>
    ), [emailData, removeAttachmentHandler]);

    const onDropHandler = useCallback((ev: any) => {
        if (ev.dataTransfer.items) {
            [...ev.dataTransfer.items].forEach((item) => {
                if (item.kind === "file") {
                    fileSelectedHandler(item.getAsFile());
                }
            });

            return;
        }

        [...ev.dataTransfer.files].forEach((file) => {
            fileSelectedHandler(file);
        });
    }, [fileSelectedHandler]);

    const updateDragState = debounce((state: boolean) => setDragAndDropActive(state), 200);

    return (
        <Flex
            column
            className="em-mainContainer"
            gap='gap.small'
            onDrop={(ev: any) => {
                ev.preventDefault();
                onDropHandler(ev);
                updateDragState(false);
            }}
            onDragOver={(ev: any) => {
                ev.preventDefault();
                updateDragState(true);
            }}
            onDragEnter={() => setDragAndDropActive(true)}
            onDragLeave={() => updateDragState(false)}
            style={{
                outline: isDragAndDropActive ? '1px dotted blue' : 'none',
                outlineOffset: isDragAndDropActive ? '-1px' : 'none'
            }}
        >
            {destinationItems}
            {
                showSubject && <SubjectInput
                    value={emailData.subject}
                    onInputChange={(value: string) => setEmailData(prev => ({...prev, subject: value}))}
                />
            }
            {attachments}
            <TextEditor
                value={emailData.text}
                onChange={(value: string) => setEmailData(prev => ({...prev, text: value}))}
            />
            <Flex 
                fill
                space="between"
                vAlign="center"
                className="em-bottomContainer"
            >
                <Flex
                    gap="gap.small"
                    vAlign="center"
                >
                    <Button
                        primary
                        content={intl.formatMessage({ id: 'EmailEditor.SendButton' })}
                        className="em-sendButton"
                        variables={{padding: '0'}}
                        onClick={() => sendButtonHandler()}
                    />
                    <FileInput 
                        icon={<PaperclipIcon />} 
                        onFileSelected={(file: File) => fileSelectedHandler(file)}
                    />
                </Flex>
                <Flex
                    gap="gap.small"
                    vAlign="center"
                >
                    <TemplatePopup
                        onSelected={(template: Template) => setEmailData(prev => ({...prev, text: template.body}))}
                    />
                    <Button
                        text
                        iconOnly
                        icon={<TrashCanIcon />}
                        onClick={() => onClose()}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
}

export default EmailEditor;
import { Subject } from "rxjs";
import { Listener, listeners } from "services/io/listeners";
import { queuesService } from "services/queue";
import { Controllers, HttpMethod, ListenerOperations, logging, QueueMediaOperations, StorageKeys } from "utils";
import { VoicemailHistoryDto } from "utils/domain/voiceMailsView/extendedVoicemailHistoryDto";
import { VoicemailOperations } from "utils/enums-s2";
import { GeneralHelper } from "utils/helpers";
import { ExtendedVoicemailsApi } from "./extendedVoicemailsApi";
import Lockr from 'lockr';
import PlaySoundHelper, { SoundType } from "utils/helpers/play-sound-helper";

export class ExtendedVoicemailsService implements ExtendedVoicemailsApi {
    private readonly logger = logging.getLogger('ExtendedVoicemailsService');
    public readonly listenerVoicemailChanged: Listener<any> = listeners.createListener<any>(ListenerOperations.VoicemailChanged);
    newVoicemails: VoicemailHistoryDto[] = [];
    voicemails: VoicemailHistoryDto[] = [];

    subscriptionVoicemailChanged: Subject<any> = new Subject<any>();
    newVoicemailNotification: Subject<any> = new Subject<any>();
    subscriptionInvalidateVoicemailCache: Subject<any> = new Subject<any>();


    constructor() {
        this.listenerVoicemailChanged.received.subscribe((obj: any) => {
            switch (obj.ChangeType) {
                case QueueMediaOperations.Added:
                    this.handleVoicemailAdded(obj.Voicemail);
                    break;
                case QueueMediaOperations.Updated:
                    this.handleVoicemailUpdated(obj.Voicemail);
                    break;
                case QueueMediaOperations.Removed:
                    this.handleVoicemailDeleted(obj.Voicemail.Id);
                    break;
            }
            this.subscriptionVoicemailChanged.next();
        });
    }

    getVoicemails(agentId: string): Promise<VoicemailHistoryDto[]> {
        return this.invokeCall(agentId, VoicemailOperations.GetVoicemails, HttpMethod.Post).then((voicemailResponse) => {
            this.cacheVoicemails(voicemailResponse);

            return voicemailResponse;
        });
    }

    updateVoicemail(voicemail: VoicemailHistoryDto): Promise<boolean> {
        return this.invokeCall(voicemail, VoicemailOperations.UpdateVoicemail, HttpMethod.Post);
    }

    deleteVoicemail(voicemail: VoicemailHistoryDto): Promise<boolean> {
        return this.invokeCall(voicemail, VoicemailOperations.DeleteVoicemail, HttpMethod.Post);
    }

    invokeCall(data: any, operation: string, httpMethod: string): Promise<any> {
        return GeneralHelper.invokeServiceCall(data, operation, this.logger, Controllers.Voicemail, undefined, undefined, httpMethod);
    }

    getCachedVoicemails(): Promise<VoicemailHistoryDto[]> {
        if (this.voicemails.length === 0) {
            return this.getVoicemails(Lockr.get<string>(StorageKeys.UserId));
        }

        return Promise.resolve(this.voicemails);
    }

    getCachedNewVoicemails() {
        return this.newVoicemails;
    }

    handleVoicemailAdded(voicemail: VoicemailHistoryDto) {
        if (!this.checkUserCanReadVoicemail(voicemail.QueueId)) {
            return;
        }

        PlaySoundHelper.playSound(SoundType.Notification);
        this.voicemails = [voicemail, ...this.voicemails];
        this.newVoicemails = [voicemail, ...this.newVoicemails];
        this.newVoicemailNotification.next();
    }

    handleVoicemailUpdated(voicemail: VoicemailHistoryDto) {
        const voicemailIndex = this.getVoicemailIndex(voicemail.Id, this.voicemails);

        if (voicemailIndex > -1) {
            this.voicemails[voicemailIndex].AgentId = voicemail.AgentId;
            this.voicemails[voicemailIndex].IsRead = voicemail.IsRead;
        }

        const newVoicemailIndex = this.getVoicemailIndex(voicemail.Id, this.newVoicemails);

        if (newVoicemailIndex > -1 && voicemail.IsRead) {
            this.newVoicemails.splice(newVoicemailIndex, 1);
        }

        this.newVoicemailNotification.next();
    }

    cacheVoicemails(voicemailList: VoicemailHistoryDto[]) {
        if(voicemailList == null) {
            voicemailList = [];
        }
        this.newVoicemails = [];

        voicemailList.forEach((voiceMail: VoicemailHistoryDto) => {
            if (!voiceMail.IsRead) {
                this.newVoicemails.push(voiceMail);
            }
        });

        this.voicemails = voicemailList;
    }

    handleVoicemailDeleted(voicemailId: string) {
        const voicemailIndex = this.getVoicemailIndex(voicemailId, this.voicemails);

        if (voicemailIndex > -1) {
            this.voicemails.splice(voicemailIndex, 1);
        }

        const newVoicemailIndex = this.getVoicemailIndex(voicemailId, this.newVoicemails);

        if (newVoicemailIndex > -1) {
            this.newVoicemails.splice(newVoicemailIndex, 1);
        }

        this.newVoicemailNotification.next();
    }

    getVoicemailIndex(voicemailId: string | undefined, array: VoicemailHistoryDto[]) {
        if (!voicemailId) {
            return -1;
        }

        const index = array.findIndex(object => {
            return object.Id === voicemailId;
        });

        return index;
    }

    checkUserCanReadVoicemail(queueId: string | undefined): boolean {
        if (!queueId) {
            return false;
        }

        const queues = queuesService.queuesArray.filter(x => x.QueueRef === queueId);

        return queues && queues.length > 0;
    }
}
import { Listener, listeners } from 'services/io/listeners';
import { Subject, Subscription } from 'rxjs';
import { ListenerOperations, QueueMediaOperations } from 'utils';
import { logging } from 'utils/logging';

export class ActiveListManager {
    private readonly subscriptionActiveMediaChange: Subscription | null = null;
    private readonly logger = logging.getLogger('ActiveListManager');

    public readonly listenerctiveMediaChange: Listener<any> = listeners.createListener<any>(ListenerOperations.ActiveMediaChange);

    onActiveCallAdded: Subject<any> = new Subject<any>();
    onActiveCallUpdated: Subject<any> = new Subject<any>();
    onActiveCallRemoved: Subject<any> = new Subject<any>();

    constructor() {
        this.subscriptionActiveMediaChange?.unsubscribe();
        this.subscriptionActiveMediaChange = this.listenerctiveMediaChange.received.subscribe((obj: any) => {
            switch (obj.ChangeType) {
                case QueueMediaOperations.Added:
                    this.onActiveCallAdded.next(obj.ActiveMedia);
                    break;
                case QueueMediaOperations.Updated:
                    this.onActiveCallUpdated.next(obj.ActiveMedia);
                    break;
                case QueueMediaOperations.Removed:
                    this.onActiveCallRemoved.next(obj.ActiveMedia);
                    break;
            }
        });
    }
}

export const activeListManager = new ActiveListManager();
import { Flex } from "@fluentui/react-northstar";
import AdvancedTable, { stringCellComparator } from "components/AdvancedTable";
import { Paginator } from "containers";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { socketVoicemails } from "services/voiceMails";
import { NotificationActionStatus, NotificationDataType, StorageKeys } from "utils";
import { NotificationBaseDTO } from "utils/domain/voiceMailsView/notificationBaseDto";
import { NotificationStatusRequestDTO } from "utils/domain/voiceMailsView/notificationStatusRequestDTO";
import { RealtimeNotificationDTO } from "utils/domain/voiceMailsView/realtimeNotificationDTO";
import { GeneralHelper } from "utils/helpers";
import Lockr from 'lockr';
import './alertsTabContentView.scss';
import { Subscription } from "rxjs";


export const AlertsTabContentView = (props: any) => {
  const intl = useIntl();
  const alertsNotificationList = useRef(props.realtimeNotificationList as RealtimeNotificationDTO[]);
  const [displayedAlertsPerPage, setDisplayedAlertsPerPage] = useState([] as any[]);
  const displayedAlerts = useRef([] as any[]);
  let subscriptionAlertsChanged: Subscription | null = null;

  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const numberOfPagesRef = useRef(1);
  const ElementsPerPage = 20;

  const columnsMembers = [
    {
      title: intl.formatMessage({ id: "VoiceMailsTabs.AlertInfo" }), className: 'big-grow',
      key: 'CallerSip' + Math.random(), name: 'CallerSipColumn', cellComparator: stringCellComparator
    },
    {
      title: intl.formatMessage({ id: "VoiceMailsTabs.Date" }), className: 'smallest-grow',
      key: 'RecordDate' + Math.random(), name: 'RecordDateColumn', cellComparator: stringCellComparator
    },
  ];

  React.useEffect(() => {
    initialize();
    return () => componentWillUnmount();
  }, []);

  const componentWillUnmount = () => {
    GeneralHelper.logCox(`in AlertsTabContentView.tsx, in componentWillUnmount`);
    subscriptionAlertsChanged?.unsubscribe();
  }

  const initialize = () => {
    GeneralHelper.logCox(`in AlertsTabContentView.tsx, in initialize`);
    subscriptionAlertsChanged?.unsubscribe();
    subscriptionAlertsChanged = socketVoicemails.alertVoicemailChangeNotification.subscribe((obj: NotificationBaseDTO) => {
      const userId = Lockr.get(StorageKeys.UserId);
      if (obj.Type === NotificationDataType.Alert && obj.AlertNotification?.AgentRef === userId) {
        handleAlertChange(obj);
      }
    });

    mapAlertsDisplayedItems();
  }

  const mapAlertsDisplayedItems = () => {
    const displayedItems = [] as any[];
    alertsNotificationList.current = removeDuplicateAllerts();
    const sortedItems = sortAlertsByDate(alertsNotificationList.current);

    sortedItems.forEach((el) => {
      displayedItems.push({
        key: `voice-mail-item${el.ID}${Math.random()}`,
        items: [
          {
            key: `${el.ID}alert-info${Math.random()}`,
            content: <Flex className="alert-info">
              <Flex className="full-width">{el.Title}</Flex>
              <Flex className="alert-message">{el.Message}</Flex>
            </Flex>,
            className: el.IsViewed ? "big-grow" : "bold-text cursor-pointer big-grow",
            onClick: () => { changeAlertStatus(el) }
          },

          {
            key: `${el.ID}alert-record-date${Math.random()}`,
            content: GeneralHelper.formatAMPM(new Date(el.CreationDate)),
            className: el.IsViewed ? "smallest-grow" : "bold-text cursor-pointer smallest-grow",
            onClick: () => { changeAlertStatus(el) }
          },

        ],
        className: "alert-row-item",

      });
    });

    displayedAlerts.current = displayedItems;
    initNumberOfPages();
    selectPage(currentPageRef.current);
  }

  const sortAlertsByDate = (records: RealtimeNotificationDTO[]) => {
    return records.sort((a, b) => (a.CreationDate > b.CreationDate) ? -1 : ((b.CreationDate > a.CreationDate) ? 1 : 0))
  }

  const removeDuplicateAllerts = () => {
    const records: RealtimeNotificationDTO[] = [];

    alertsNotificationList.current.forEach((el) => {
      const existingRecords = records.filter(x =>
        x.ID === el.ID
      );

      if (existingRecords.length === 0) {
        records.push(el);
      }
    });

    return records;
  }

  const handleAlertChange = (notification: NotificationBaseDTO) => {
    if (!notification || !notification.AlertNotification) {
      return;
    }

    const alert = notification.AlertNotification;

    if (alert?.IsViewed) {
      return;
    }

    switch (notification.NotificationActionStatus) {
      case NotificationActionStatus.Updated:
        updateAlertRecord(alert);
        break;
      case NotificationActionStatus.Added:
        addAlertRecord(alert);
        break;
      case NotificationActionStatus.Removed:
        removeAlertRecord(alert.ID);
        break;
    }
  }

  const removeAlertRecord = (id: number) => {
    const removedNotifications = alertsNotificationList.current.filter(x =>
      x.ID === id
    );

    if (!removedNotifications || removedNotifications.length === 0) {
      return;
    }

    const removedNotifIndex = alertsNotificationList.current.findIndex(x => x.ID === id);

    if (removedNotifIndex > -1) {
      alertsNotificationList.current.splice(removedNotifIndex, 1);
      mapAlertsDisplayedItems();
    }
  }

  const addAlertRecord = (alertRecord: RealtimeNotificationDTO) => {
    const addedNotifIndex = alertsNotificationList.current.findIndex(x => x.ID === alertRecord.ID);

    if (addedNotifIndex === -1) {
      alertsNotificationList.current.push(alertRecord);
      mapAlertsDisplayedItems();
    }
  }

  const updateAlertRecord = (alertRecord: RealtimeNotificationDTO) => {
    const updatedNotifications = alertsNotificationList.current.filter(x =>
      x.ID === alertRecord.ID
    );

    if (!updatedNotifications || updatedNotifications.length === 0) {
      return;
    }

    const updatedNotifIndex = alertsNotificationList.current.findIndex(x => x.ID === alertRecord.ID);
    alertsNotificationList.current[updatedNotifIndex] = alertRecord;

    mapAlertsDisplayedItems();
  }

  const selectPage = (page: any) => {
    if (page > numberOfPagesRef.current) {
      page = 1;
    }

    const displayedItems = displayedAlerts.current.slice((page - 1) * ElementsPerPage, page * ElementsPerPage);
    setDisplayedAlertsPerPage(displayedItems);
    setCurrentPage(page);
    currentPageRef.current = page;
  }

  const initNumberOfPages = () => {
    const itemsCount = displayedAlerts.current.length;
    if (!itemsCount) {
      setNumberOfPages(1);
      numberOfPagesRef.current = 1;
      return;
    }

    const nrOfPages = itemsCount % ElementsPerPage === 0 ? Math.floor(itemsCount / ElementsPerPage) : Math.floor(itemsCount / ElementsPerPage) + 1;
    setNumberOfPages(nrOfPages);
    numberOfPagesRef.current = nrOfPages;
  }

  const changeAlertStatus = (alertRecordDTO: RealtimeNotificationDTO) => {
    alertsNotificationList.current.forEach((el) => {
      if (el.ID === alertRecordDTO.ID) {
        el.IsViewed = true;
      }
    });

    mapAlertsDisplayedItems();

    const userId = Lockr.get<number>(StorageKeys.UserId);

    const notificationStatusRequest: NotificationStatusRequestDTO = {
      ID: alertRecordDTO.ID,
      IsViewed: true,
      AgentRef: userId,
      Type: NotificationDataType.Alert
    };
    socketVoicemails.NotificationSetStatus(notificationStatusRequest);
  }

  return (
    <>
      <AdvancedTable key="voicemail-tab-table-key" className="voicemails-table" columns={columnsMembers} rows={displayedAlertsPerPage}
        label={intl.formatMessage({ id: "VoiceMailsTabs.Voicemails" })} />
      {numberOfPages > 1 && <Paginator key="voicemail-tab-paginator-key"
        currentPage={currentPage}
        totalPages={numberOfPages}
        onChange={selectPage}
      />}
    </>
  )
};

export default AlertsTabContentView;

import React, { useState } from 'react';
import { Segment } from '@fluentui/react-northstar';
import { logging } from 'utils/logging';
import { StorageKeys } from 'utils';
import { Subscription } from 'rxjs';

export const NotificationsView = () => {
    const [alertContent, setAlertContent] = useState('');

    let socketAuthUserSignedOut: Subscription | null = null;

    React.useEffect(() => {
        const existingAlert = localStorage.getItem(StorageKeys.AlertMessage);
        if (existingAlert) {
            setTimeout(() => {
                setAlertContent('');
                localStorage.setItem(StorageKeys.AlertMessage, '');
            }, 8000);

            setAlertContent(existingAlert);
        }

        socketAuthUserSignedOut?.unsubscribe();
        socketAuthUserSignedOut = logging.alertHandler.subscribe((obj: string) => {
            if (obj) {
                setAlertContent(obj);

                setTimeout(() => {
                    setAlertContent('');
                }, 8000);
            }
        });
        return () => componentWillUnmount();
    }, []);

    const componentWillUnmount = () => {
        socketAuthUserSignedOut?.unsubscribe();
    }

    return (
        <Segment hidden={alertContent.length === 0} content={alertContent} color="red" inverted />
    );
};

export default NotificationsView;

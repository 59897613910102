import * as React from "react";
import { Flex, Text, AcceptIcon } from "@fluentui/react-northstar";
import "./queueView.scss";

export const QueueView = (props: any) => {
  return (
    <Flex
      key={props.queue.QueueRef + "flex"}
      gap="gap.small"
      onClick={() => props.selectQueue(props.index)}
      className="queue-item-content"
    >
      <Flex.Item
        key={props.queue.QueueRef + "flexItem1"}
        size="10px"
        align="start"
      >
        <AcceptIcon
          key={props.queue.QueueRef + "acceptIcon"}
          className={
            props.queue.IsAttached
              ? "queue-check-icon selected"
              : "queue-check-icon"
          }
        />
      </Flex.Item>
      <Flex.Item grow key={props.queue.QueueRef + "flexItem2"}>
        <Text content={props.queue.QueueName} />
      </Flex.Item>
    </Flex>
  );
};

export default QueueView;

import { Button, SvgIconSizeValue } from "@fluentui/react-northstar";
import TeamsChatIcon from "components/Icons/TeamsChatIcon";
import React, { CSSProperties } from "react";
import { useIntl } from "react-intl";
import * as microsoftTeams from '@microsoft/teams-js';

export interface ChatButtonProps {
    upns: string[];
    size?: SvgIconSizeValue;
    style?: CSSProperties;
}

const ChatButton = (props: ChatButtonProps) => {
    const intl = useIntl();

    const openUserChat = (upns: string[]) => {
        if (microsoftTeams.chat.isSupported() && !!upns && upns.length > 0) {
            microsoftTeams.chat.openGroupChat({ users: upns })
                .then((result) => console.log(result))
                .catch((error) => console.error(error));
        } else {
            console.error("Chat is not supported");
        }
    }

    return (
        <Button
            text
            iconOnly
            title={intl.formatMessage({ id: "CallActions.Chat" })}
            icon={<TeamsChatIcon size={props.size} />}
            onClick={() => openUserChat(props.upns)}
            style={props.style}
        />
    );
}

export default ChatButton;
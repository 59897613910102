import { Button, Divider, Flex, Text } from "@fluentui/react-northstar";
import RecipientInfo from "./RecipientInfo";
import React, { useCallback, useMemo } from "react";
import RecipientData, { RecipientType } from "../models/Recipient";
import { useIntl } from "react-intl";
import RecipientSearchInput from "./RecipientSearchInput";

interface RecipientsViewProps {
    title: string;
    type: RecipientType;
    visibleTypes: RecipientType[];
    recipients: RecipientData[];
    onRemove: (type: RecipientType, contact: RecipientData) => void;
    onAddContact: (type: RecipientType, contact: RecipientData) => void;
    showDestinationType: (type: RecipientType) => void;
}

const RecipientsView = (props: RecipientsViewProps) => {
    const { title, type, visibleTypes, recipients, onRemove, onAddContact, showDestinationType } = props;
    const intl = useIntl();

    const titleByRecipientType = useCallback((recipientType: RecipientType) => {
        return intl.formatMessage({ id: `EmailEditor.${RecipientType[recipientType]}Destination` });
    }, [intl]);

    const hasCc = useMemo(() => visibleTypes.includes(RecipientType.Cc), [visibleTypes]);
    const hasBcc = useMemo(() => visibleTypes.includes(RecipientType.Bcc), [visibleTypes]);
    const isLastVisible = useMemo(() => [...visibleTypes].sort(((a, b) => Number(b) - Number(a)))[0] == type, [visibleTypes]);

    return (
        <Flex 
            gap="gap.small"
            vAlign="center"
            column
        >
            <Flex
                gap="gap.small"
                vAlign="center"
                className="em-header"
            >
                <Text content={title} />
                <Flex 
                    gap="gap.small"
                    vAlign="center"
                    wrap
                >
                    {
                        recipients.map(recipient => <RecipientInfo
                            key={recipient.email + recipient.name}
                            name={recipient.name}
                            email={recipient.email}
                            onClose={() => onRemove(type, recipient)}
                        />)
                    }
                    <RecipientSearchInput onRecipientSelected={(contact: RecipientData) => onAddContact(type, contact)} />
                </Flex>
                {
                    !hasCc && isLastVisible && <Flex.Item push={!hasCc}>
                        <Button
                            text
                            iconOnly
                            title={titleByRecipientType(RecipientType.Cc)}
                            content={titleByRecipientType(RecipientType.Cc)}
                            onClick={() => showDestinationType(RecipientType.Cc)}
                        />
                    </Flex.Item>
                }
                {
                    !hasBcc && isLastVisible && <Flex.Item push={!hasBcc && hasCc}>
                        <Button
                            text
                            iconOnly
                            title={titleByRecipientType(RecipientType.Bcc)}
                            content={titleByRecipientType(RecipientType.Bcc)}
                            onClick={() => showDestinationType(RecipientType.Bcc)}
                        />
                    </Flex.Item>
                }
            </Flex>
            <Divider
                fitted
                size={0}
                className="em-divider-padding"
            />
        </Flex>
    );
}

export default RecipientsView;

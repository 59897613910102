import { Controllers, HttpMethod, logging } from "utils";
import { FavoriteConfiguration, FavoriteContactDTO } from "utils/domain/extended/favoriteContactDTO";
import { GeneralHelper } from "utils/helpers";
import { FavoritesOperations } from "utils/enums-s3";

export class ExtendedFavoritesService {
    private readonly logger = logging.getLogger('ExtendedFavoritesService');

    getCurrentAgentFavoriteContacts(): Promise<FavoriteContactDTO[]> {
        return this.invokeCall(null, FavoritesOperations.Favorites, HttpMethod.Get);
    }

    addToFavorite(contactId: FavoriteContactDTO): Promise<FavoriteContactDTO> {
        return this.invokeCall(contactId, FavoritesOperations.Favorites, HttpMethod.Post);
    }

    removeFromFavorite(contactId: string): Promise<any> {
        const request = { "userObjectId": contactId };

        return this.invokeCall(request, `${FavoritesOperations.Favorites}/delete`, HttpMethod.Post);
    }

    getFavoritesCount(): Promise<number> {
        return this.invokeCall(null, FavoritesOperations.GetFavoritesCount, HttpMethod.Get);
    }

    getFavoriteConfiguration(): Promise<FavoriteConfiguration> {
        return this.invokeCall(null, FavoritesOperations.GetFavoritesConfiguration, HttpMethod.Get);
    }

    invokeCall(data: any, operation: string, httpMethod: string): Promise<any> {
        return GeneralHelper.invokeServiceCall(data, operation, this.logger, Controllers.Agent, undefined, undefined, httpMethod);
    }
}
import { Button, MenuButton, PhoneArrowIcon, UserFriendsIcon } from "@fluentui/react-northstar";
import React from "react";
import { useIntl } from "react-intl";
import { AzureActiveDirectorySettingsDTO } from "utils/domain/azureActiveDirectorySettingsDTO";
import { Contact } from "utils/domain/extended/contact";
import CallableItemsList from "./CallableItemsList";
import { Department } from "utils";

type Props = {
    contact: Contact;

    azureADSettings: AzureActiveDirectorySettingsDTO;

    agentHasOutboundEnabled: boolean;
}

const InCallActions = ({ contact, azureADSettings, agentHasOutboundEnabled }: Props) => {

    const intl = useIntl();

    const { getCallableItemsList } = CallableItemsList({
        contact,
        azureADSettings,
        agentHasOutboundEnabled
    })

    return (
        <>
            <MenuButton align="end"
                trigger={<Button icon={<UserFriendsIcon outline />}
                    iconOnly
                    text
                    title={intl.formatMessage({ id: "CallActions.WarmTransfer" })}
                    disabled={contact.Department === Department.applicationInstance}
                />}
                menu={getCallableItemsList(true, true)}
                on="click"
            />
            <MenuButton align="end"
                trigger={<Button icon={<PhoneArrowIcon outline />}
                    iconOnly
                    text
                    title={intl.formatMessage({ id: "CallActions.ColdTransfer" })} />}
                menu={getCallableItemsList(true, false)}
                on="click"
            />
        </>
    );
}

export default InCallActions;
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Login } from '../../containers/AuthView';
import { RouteDefinition } from 'utils';
import routes from 'utils/routes';

export default class RouterHelper {
  public static AuthenticatedRoutes(): JSX.Element {
    return RouterHelper.RenderRoutes(routes);
  }

  public static UnauthenticatedRoutes(): JSX.Element {
    const copyOfRoutes = routes;

    for (const route of copyOfRoutes) {
      if (route.authenticated) {
        route.component = <Login />
      }
    }

    return RouterHelper.RenderRoutes(copyOfRoutes);
  }

  private static RenderRoutes(routes: RouteDefinition[]): JSX.Element {
    return (
      <Router>
        <Switch>
          {routes.map(route =>
            <Route key={route.key} path={route.route} exact={route.route === '/'}>
              {route.component}
            </Route>
          )}
        </Switch>
      </Router>
    );
  }
}
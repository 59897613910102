import { TableRowProps, Flex, Text, Button, ChatIcon } from "@fluentui/react-northstar";
import AdvancedTable from "components/AdvancedTable";
import Timer from "components/Timer";
import { Paginator } from "containers";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import Lockr from "lockr";
import { useSubjectListenerEffect } from "utils/helpers/listenerHook";
import { AgentMediaResolver, styleClasses } from "../AgentMediaResolver";
import GenericMediaData from "../../../Models/GenericMediaData";
import { waitingMediaService } from "./WaitingMediaService";
import { QueueMediaStatusType, StorageKeys, logging } from "utils";
import genericActionsService from "containers/GenericView/Services/GenericActionsService";
import { socketContacts } from "services/contacts";
import { Presence, PresenceState } from "utils/domain/presence";
import { serviceRequests } from "services";
import { socketPresence } from "services/presence";
import { CallSessionDTO } from "utils/domain/callSessionDTO";
import { socketAudio } from "services/calling";
import { GeneralHelper } from "utils/helpers";
import { InviteStatus, genericInviteService } from "containers/GenericView/GenericActionsView/GenericInviteService";
import './styles.scss';

const ongoingCallOperations = GeneralHelper.getOngoingCallOperations();

const WaitingMediaView = () => {
    const intl = useIntl();

    const columns = AgentMediaResolver();
    const [currentPage, setCurrentPage] = useState<number>(waitingMediaService.currentPage);
    const [rows, setRows] = useState<TableRowProps[]>([]);
    const isOngoingCall = useRef<boolean>(false);
    const presence = useRef<Presence>();
    const internalPresence = useRef<any>();
    const [inviteStatus, setInviteStatus] = useState<InviteStatus>(InviteStatus.none);

    const userId = useRef(Lockr.get<string>(StorageKeys.UserId));

    useSubjectListenerEffect((data: GenericMediaData[]) => buildTableRows(data), waitingMediaService.dataUpdatedSubject);

    useSubjectListenerEffect((userPresence: Presence) => {
        if (userPresence.Id === userId.current) {
            presence.current = userPresence;
        }
    }, socketContacts.notifyUpdatedPresenceState);

    useSubjectListenerEffect((userPresence: any) => {
        if (userPresence.Id === userId.current) {
            internalPresence.current = userPresence;
        }
    }, serviceRequests.internalPresenceChange);

    useSubjectListenerEffect((callSessionDTO: CallSessionDTO) => {
        isOngoingCall.current = ongoingCallOperations.includes(callSessionDTO.ConferenceActionAsString) && callSessionDTO.IsCurrentUserThePrimaryAgent;
    }, socketAudio.callSessionChanged)

    useSubjectListenerEffect((status: InviteStatus) => {
        setInviteStatus(status);
    }, genericInviteService.inviteStatusSubject);

    useEffect(() => {
        waitingMediaService.init();
        initializePresence();
    }, []);

    useEffect(() => waitingMediaService.selectPage(currentPage), [currentPage]);

    const initializePresence = async () => {
        const currentPresence = await socketPresence.GetCurrentPresence();
        internalPresence.current = currentPresence;
    }

    const pickupMediaOnClick = useCallback(async (sessionId: string) => {
        if (presence.current?.Availability === PresenceState.DND || internalPresence.current?.Presence === PresenceState.DND) {
            logging.errorHandler.next("ErrorMessage.MediaPickup.MediaPickupDND");

            return;
        }

        const isCallPickedUp = await genericActionsService.pickupMedia({
            sessionId,
            agentUserId: Lockr.get<string>(StorageKeys.UserId)
        });

        if (!isCallPickedUp) {
            logging.errorHandler.next("ErrorMessage.MediaPickup.MediaUnavailable");
        }
    }, [presence, internalPresence]);

    const getPickUpButton = (isCallPickupEnabled: boolean, isAssigned: boolean, sessionId: string) => {
        if (isCallPickupEnabled && !isOngoingCall.current && !isAssigned && inviteStatus !== InviteStatus.received) {

            return <Button circular icon={<ChatIcon size="small" />} size="small" className="pickup-button" primary
                title={intl.formatMessage({ id: "Generic.Media.MediaPickup" })}
                onClick={() => pickupMediaOnClick(sessionId)} />
        }

        return "";
    }

    const buildTableRows = (data: GenericMediaData[]) => {
        const tableRows: TableRowProps[] = data.map((x: GenericMediaData) => ({
            key: x.sessionId,
            items: [
                {
                    content: x.sourceName,
                    key: `${x.sessionId}-caller`,
                    className: styleClasses.bigGrow
                },
                {
                    content: x.startDate && <>
                        {
                            getWaitingMediaTime(x.startDate)
                        }
                    </>,
                    key: `${x.sessionId}-startDate`,
                    className: styleClasses.smallerGrow
                },
                {
                    content: x.queueName,
                    key: `${x.sessionId}-queueName`,
                    className: styleClasses.bigGrow
                },
                {
                    content: QueueMediaStatusType[x.status].toString(),
                    key: `${x.sessionId}-status`,
                    className: styleClasses.smallGrow,
                },
                {
                    content: x.agentName,
                    key: `${x.sessionId}-agentName`,
                    className: styleClasses.bigGrow,
                },
                {
                    content: getPickUpButton(x.isMediaPickupEnabled, x.isAssigned, x.sessionId),
                    key: `${x.sessionId}-actions`,
                    className: styleClasses.smallerGrow,
                },
            ]
        }));

        setRows(tableRows);
    }

    const getWaitingMediaTime = (dateString: string) => {
        const nowDate = new Date() as any;
        const waitingDate = Date.parse(dateString);
        const hoursDifference = Math.abs(nowDate - waitingDate) / 36e5;
        const daysAgo = hoursDifference / 24;

        if (daysAgo >= 1) {
            return <div className="waiting-time-container">
                <span>{Math.floor(daysAgo)}d </span>
                <Timer startDate={dateString} />
            </div>
        }

        return <Timer startDate={dateString} />;
    }

    return (<Flex column>
        <Flex gap="gap.medium">
            <Text content={intl.formatMessage({ id: "Generic.Media.WaitingMediaViewName" })} weight="bold" />
        </Flex>

        <AdvancedTable
            columns={columns}
            rows={rows}
            label="Waiting Calls"
            className="waiting-list-table"
        />
        {
            waitingMediaService.existsPaginator && <Paginator
                currentPage={currentPage}
                totalPages={waitingMediaService.numberOfPages}
                onChange={(newPage: number) => setCurrentPage(newPage)}
            />
        }
    </Flex>);
};

export default WaitingMediaView;
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const AgentMediaResolver = () => {
    const intl = useIntl();

    const columns = useMemo(() => [
        {
            key: 'Name',
            title: intl.formatMessage({ id: "Generic.Media.CallerName" }),
            name: 'CallerColumn', 
            className: styleClasses.bigGrow
        },
        {
            key: 'Time',
            title: intl.formatMessage({ id: "Generic.Media.Time" }),
            name: 'StartTimeColumn', 
            className: styleClasses.smallerGrow
        },
        {
            key: 'QueueName',
            title: intl.formatMessage({ id: "Generic.Media.Queue" }),
            name: 'QueueNameColumn', 
            className: styleClasses.bigGrow
        },
        {
            key: 'Status',
            title: intl.formatMessage({ id: "Generic.Media.Status" }),
            name: 'StatusColumn', 
            className: styleClasses.smallGrow
        },
        {
            key: 'AgentName',
            title: intl.formatMessage({ id: "Generic.Media.AgentName" }),
            name: 'AgentNameColumn', 
            className: styleClasses.bigGrow
        },
        {
            key: 'Actions',
            title: intl.formatMessage({ id: "Generic.Media.Actions" }),
            name: 'ActionsColumn',
            className: styleClasses.smallerGrow
        }
    ], [intl]);

    return columns;
}

export const styleClasses = {
    smallerGrow: "smaller-grow",
    bigGrow: "big-grow",
    smallGrow: "small-grow",
    smallestGrow: "smallest-grow",
};

export default AgentMediaResolver;
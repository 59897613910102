import { logging } from 'utils/logging';
import { ListenerOperations, OutboundOperations, StorageKeys, Controllers } from 'utils';
import { ApiOutbound } from '.';
import { Subject, Subscription } from 'rxjs';
import Lockr from 'lockr';
import { CampaingCallItemDto, OutgoingCallDto, TrustedEndpointDTO, SendToneDto, OutgoingCallResponseDto } from 'utils/domain/outbound';
import { GeneralHelper, EnvHelper } from 'utils/helpers';
import { Listener, listeners } from 'services/io/listeners';

export class OutboundService implements ApiOutbound {
    private hasOutboundAccesEnabled: boolean = false;
    private readonly logger = logging.getLogger('SocketOutbound');
    private readonly subscriptionompanyChanged: Subscription | null = null;
    private readonly subscriptionUserRightChanged: Subscription | null = null;

    public readonly listenerCompanyChanged: Listener<any> = listeners.createListener<any>(ListenerOperations.CompanyChanged);
    public readonly listenerUserRightChanged: Listener<any> = listeners.createListener<any>(ListenerOperations.UserRightChange);
    public readonly listenerOutboundEndpointsChanged: Listener<any> = listeners.createListener<any>(ListenerOperations.OutboundEndpointsChange);
    public readonly listenerOutboundCallError: Listener<any> = listeners.createListener<any>(ListenerOperations.OutboundCallError);

    agentHasOutboundAccessEnabled: Subject<boolean> = new Subject<boolean>();
    trustedEndpointsChanged: Subject<TrustedEndpointDTO[]> = new Subject<TrustedEndpointDTO[]>();
    companyChanged: Subject<any> = new Subject<any>();

    trustedEndpoints: TrustedEndpointDTO[] = [];

    constructor() {
        this.subscriptionompanyChanged?.unsubscribe();
        this.subscriptionompanyChanged = this.listenerCompanyChanged.received.subscribe((obj: any) => {
            this.isAgentOutboundAccessEnabled();
        });

        this.subscriptionUserRightChanged?.unsubscribe();
        this.subscriptionUserRightChanged = this.listenerUserRightChanged.received.subscribe((obj: any) => {
            const userId = Lockr.get(StorageKeys.UserObjectId)
            const userChange = obj.UserRightChanges.filter((x: any) => x.userId !== userId);

            if (userChange && userChange.length > 0) {
                this.HandleOutboundRightChange(userChange[0].HasOutboundRights);
            }
        });
    }

    private IsAgentOutboundAccessEnabled(): Promise<boolean> {
        return GeneralHelper.invokeServiceCall(Lockr.get(StorageKeys.UserId), OutboundOperations.IsAgentOutboundAccessEnabled, this.logger);
    }

    HasOutboundAccesEnabled(): boolean {
        return this.hasOutboundAccesEnabled;
    }

    GetEndPointListForAgent(useCache: boolean = false): void {
        const canTakeFromCache = EnvHelper.isStage3() && useCache && this.trustedEndpoints?.length > 0;

        if (canTakeFromCache) {
            this.trustedEndpointsChanged.next(this.trustedEndpoints);
        }

        GeneralHelper.invokeServiceCall(Lockr.get(StorageKeys.UserId),
            OutboundOperations.GetEndPointListForAgent, this.logger,
            EnvHelper.isStage3() ? Controllers.QueueUser : Controllers.CallCenter).then((endpoints: TrustedEndpointDTO[]) => {
                this.trustedEndpoints = endpoints;
                this.trustedEndpointsChanged.next(endpoints);
            });
    }

    GetAgentCachedEndPointList(): TrustedEndpointDTO[] {
        return this.trustedEndpoints;
    }

    isAgentOutboundAccessEnabled(skipServerRequest: boolean = false, value: boolean = false): void {
        if (EnvHelper.isStage3() && skipServerRequest) {
            this.HandleOutboundRightChange(value || false);
            return;
        }

        this.IsAgentOutboundAccessEnabled().then((response: boolean) => {
            this.HandleOutboundRightChange(response);
        }).catch(err => {
            console.log(err.message);
        });
    }

    HandleOutboundRightChange(isOuboundEnabled: boolean): void {
        this.hasOutboundAccesEnabled = isOuboundEnabled;
        this.agentHasOutboundAccessEnabled.next(isOuboundEnabled);
    }

    CreateOutboundCall(callItem: CampaingCallItemDto): Promise<boolean> {
        return GeneralHelper.invokeServiceCall(callItem, OutboundOperations.CreateOutboundCall, this.logger);
    }

    CreateOutgoingCall(callItem: OutgoingCallDto): Promise<OutgoingCallResponseDto> {
        return GeneralHelper.invokeServiceCall(callItem, OutboundOperations.CreateOutgoingCall, this.logger);
    }

    SendTone(toneItem: SendToneDto): Promise<boolean> {
        return GeneralHelper.invokeServiceCall(toneItem, OutboundOperations.SendTone, this.logger);
    }
}

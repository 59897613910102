import React from 'react'
import { createUltimatePagination } from 'react-ultimate-pagination';
import './Pagination.scss'
import { Button } from '@fluentui/react-northstar';
/*#warning-js would restructure the hierarchy per common components, such as pagination, error;
or actual implementations, not frequently reused such as CallView; or top level components, such as media tabs
or move from "containers" to "components"
*/
const Page = (props: any) => {
  return (
    <Button
      styles={({ theme: { siteVariables } }) => ({
        color: siteVariables.colorScheme.default.foreground1,
        backgroundColor: siteVariables.colorScheme.default.background1,
        "&:hover": { backgroundColor: siteVariables.colorScheme.brand.foreground3 },
        "&.active-page": {
          backgroundColor: siteVariables.colorScheme.brand.backgroundActive1,
          color: siteVariables.colorScheme.default.background,
        },
      })}
      inverted
      iconOnly
      className={props.isActive ? "active-page" : ""}
      onClick={props.onClick}
      disabled={false}
    >{props.value}</Button>
  );
}

const Ellipsis = (props: any) => {
  return <Button iconOnly inverted onClick={props.onClick} disabled={props.disabled}
    styles={({ theme: { siteVariables } }) => ({
      color: siteVariables.colorScheme.default.foreground1,
      backgroundColor: siteVariables.colorScheme.default.background1,
    })}>...</Button>
}

const FirstPageLink = (props: any) => {
  return <Button iconOnly inverted onClick={props.onClick} disabled={props.disabled}
    styles={({ theme: { siteVariables } }) => ({
      color: siteVariables.colorScheme.default.foreground1,
      backgroundColor: siteVariables.colorScheme.default.background1,
    })}>&lt;&lt;</Button>
}

const PreviousPageLink = (props: any) => {
  return <Button iconOnly inverted onClick={props.onClick} disabled={props.disabled}
    styles={({ theme: { siteVariables } }) => ({
      color: siteVariables.colorScheme.default.foreground1,
      backgroundColor: siteVariables.colorScheme.default.background1,
    })}>&lt;</Button>
}

const NextPageLink = (props: any) => {
  return <Button iconOnly inverted onClick={props.onClick} disabled={props.disabled}
    styles={({ theme: { siteVariables } }) => ({
      color: siteVariables.colorScheme.default.foreground1,
      backgroundColor: siteVariables.colorScheme.default.background1,
    })}>&gt;</Button>
}

const LastPageLink = (props: any) => {
  return <Button iconOnly inverted onClick={props.onClick} disabled={props.disabled}
    styles={({ theme: { siteVariables } }) => ({
      color: siteVariables.colorScheme.default.foreground1,
      backgroundColor: siteVariables.colorScheme.default.background1,
    })}>&gt;&gt;</Button>
}

const Wrapper = (props: any) => {
  return <div className="pagination">{props.children}</div>
}

var itemTypeToComponent = {
  'PAGE': Page,
  'ELLIPSIS': Ellipsis,
  'FIRST_PAGE_LINK': FirstPageLink,
  'PREVIOUS_PAGE_LINK': PreviousPageLink,
  'NEXT_PAGE_LINK': NextPageLink,
  'LAST_PAGE_LINK': LastPageLink
};

var Pagination = createUltimatePagination({
  itemTypeToComponent: itemTypeToComponent,
  WrapperComponent: Wrapper
});

export default Pagination;
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Menu, tabListBehavior, CallDialpadIcon, Text, UserFriendsIcon, Flex, Divider } from '@fluentui/react-northstar';
import { useIntl } from 'react-intl';
import DialpadView from '../../containers/DialpadView';
import { socketOutbound } from '../../services/outbound';
import { SharedStyleClasses, StorageKeys } from '../../utils';
import { EnvHelper, GeneralHelper } from '../../utils/helpers';
import Lockr from 'lockr';
import FavoritesView from '../../containers/FavoritesView';
import { Subscription } from 'rxjs';
import { mainViewHelper } from '../../utils/helpers/main-view-helper';
import { socketCallHistory } from '../../services/callHistory';
import { socketContacts } from '../../services/contacts';
import { TrustedEndpointDTO } from '../../utils/domain/outbound';
import ExtendedFavoritesView from 'containers/ExtendedFavorites';
import Env from 'utils/helpers/env';
import { extendedFavoritesService } from 'services/extendedFavorites';
import { FavoriteContactUpdate, notificationCenter } from 'utils/helpers/notificationCenter';
import { initialClientLoadService } from 'services/initialClientLoad';
import { TeamsClientInitSettingsDto } from 'utils/domain/extended/teamsClientInitSettingsDto';
import useKeyCombination from 'utils/domain/hooks/useKeyCombination';
import ModifierKeys from 'utils/domain/hooks/ModifierKeys';
import { useSubjectListenerEffect } from 'utils/helpers/listenerHook';

interface TabData {
    isdialpad: string
    key: string,
    active: boolean,
    content: JSX.Element,
    onClick: () => void,
    className: string
}

export const NavigationView = () => {
    const intl = useIntl();

    const displayDialpadRef = useRef(false);
    const [isDialpadSelected, setIsDialpadSelected] = useState(false);
    const [displayDialpad, setDisplayDialpad] = useState(false);

    const displayFavoritesRef = useRef(false);
    const [isFavoritesSelected, setIsFavoritesSelected] = useState(false);
    const [displayFavorites, setDisplayFavorites] = useState(false);

    const [shouldDialpadPhoneNumberBeFocused, setShoulDialpadPhoneNumberBeFocused] = useState(false);
    const [shouldEndpointDropdownBeOpen, setShouldEndpointDropdownBeOpen] = useState(false);
    const [dialpadRenderKey, setDialpadRenderKey] = useState(Math.random());
    const hasOutboundAccesEnabled = useRef(false);

    useKeyCombination([ModifierKeys.Alt], ["o"], () => {
        handleDialpadKeyShortcuts();
        setShoulDialpadPhoneNumberBeFocused(true);
    });

    useKeyCombination([ModifierKeys.Alt, ModifierKeys.Control], ["e"], () => {
        handleDialpadKeyShortcuts();
        setShouldEndpointDropdownBeOpen(true);
    });

    let subscriptionAgentHasOutboundAccessEnabled: Subscription | null = null;
    let subscriptionHasAnyFavoriteContactChanged: Subscription | null = null;
    let subscriptionTrustedEnpointsChanged: Subscription | null = null;
    let subscriptionOutboundEndpointChanged: Subscription | null = null;
    let subscriptionInitialSettingsRequestCompleted: Subscription | null = null;

    const dialpadNavItem = useMemo<TabData>(() => ({
        isdialpad: 'true',
        key: 'dialpad' + Math.random(),
        active: isDialpadSelected,
        content: <Flex column hAlign="center" styles={{ paddingBottom: "7px" }}>
            <CallDialpadIcon />
            <Text content={intl.formatMessage({ id: "NavView.Dialpad" })} />
        </Flex>,
        onClick: () => toggleNavigationView(true),
        className: SharedStyleClasses.TabUnderline
    }), [isDialpadSelected]);

    const favoritesNavItem = useMemo<TabData>(() => ({
        isdialpad: 'false',
        key: 'favorites' + Math.random(),
        active: isFavoritesSelected,
        content: <Flex column hAlign="center" styles={{ paddingBottom: "7px" }}>
            <UserFriendsIcon outline />
            <Text content={intl.formatMessage({ id: "FavoritesView.Favorites" })} />
        </Flex>,
        onClick: () => toggleNavigationView(false),
        className: SharedStyleClasses.TabUnderline
    }), [isFavoritesSelected]);

    const [menuItems, setMenuItems] = useState<TabData[]>([]);

    useEffect(() => {
        initialize();

        return () => componentWillUnmount();
    }, []);

    useEffect(() => {
        const items = [];

        if (displayDialpad) {
            items.push(dialpadNavItem);
        }

        if (displayFavorites) {
            items.push(favoritesNavItem);
            setIsFavoritesSelected(true);
            setIsDialpadSelected(false);
        }

        if (displayDialpad && !displayFavorites && hasOutboundAccesEnabled.current) {
            setIsDialpadSelected(true);
            setIsFavoritesSelected(false);
            setShoulDialpadPhoneNumberBeFocused(false);
            setShouldEndpointDropdownBeOpen(false);
        }

        setMenuItems(items);
    }, [displayDialpad, displayFavorites]);

    useEffect(() => {
        const items = [];

        if (displayDialpad && hasOutboundAccesEnabled.current) {
            items.push(dialpadNavItem);
        }

        if (displayFavorites) {
            items.push(favoritesNavItem);
        }

        if (!isDialpadSelected) {
            setShoulDialpadPhoneNumberBeFocused(false);
            setShouldEndpointDropdownBeOpen(false);
        }

        setMenuItems(items);
    }, [isDialpadSelected, isFavoritesSelected, dialpadRenderKey]);

    useSubjectListenerEffect((obj: string) => {
        if (!displayDialpad) {
            return;
        }

        toggleNavigationView(true);
        setTimeout(() => socketCallHistory.callBackContactInfo.next(obj), 100);
    }, mainViewHelper.navigationViewGoToDialpad);

    const initialize = () => {
        GeneralHelper.logCox(`in NavigationView.tsx, in initialize`);

        subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
        subscriptionAgentHasOutboundAccessEnabled = socketOutbound.agentHasOutboundAccessEnabled.subscribe((obj: boolean) => {
            if (EnvHelper.isStage3()) {
                handleOutboundAccessEnabledStage3(obj);
            } else {
                handleOutboundAccessEnabledStage2(obj);
            }
        });

        if (EnvHelper.isStage3()) {
            subscriptionHasAnyFavoriteContactChanged?.unsubscribe();
            subscriptionHasAnyFavoriteContactChanged = notificationCenter.favoriteContactUpdated.subscribe((action: FavoriteContactUpdate) => {
                hasAnyFavoriteContact();
            });
        } else {
            subscriptionHasAnyFavoriteContactChanged?.unsubscribe();
            subscriptionHasAnyFavoriteContactChanged = socketContacts.hasAnyFavoriteContactChanged.subscribe((flag: boolean) => {
                hasAnyFavoriteContact();
            })
        }

        subscriptionTrustedEnpointsChanged?.unsubscribe();
        subscriptionTrustedEnpointsChanged = socketOutbound.trustedEndpointsChanged.subscribe((response: TrustedEndpointDTO[]) => {
            if (EnvHelper.isStage3() && hasOutboundAccesEnabled.current) {
                updateDialpadVisibility(!!response && response.length > 0);
            }
        });

        subscriptionOutboundEndpointChanged = socketOutbound.listenerOutboundEndpointsChanged
            .received.subscribe((response: any) => {
                if (EnvHelper.isStage3() && (!response || response.length !== 0) && hasOutboundAccesEnabled.current) {
                    updateDialpadVisibility(true);
                }
            });
        subscriptionInitialSettingsRequestCompleted = initialClientLoadService.notifyInitialSettingsRequestCompleted.subscribe((settings: TeamsClientInitSettingsDto) => {
            isAgentOutboundAccessEnabled();
            hasAnyFavoriteContact(true);
        });

        isAgentOutboundAccessEnabled();
        hasAnyFavoriteContact(true);
        displayDialpadIfNoneSelected();
    }

    const displayDialpadIfNoneSelected = () => {
        if (!isFavoritesSelected && !isDialpadSelected) {
            setIsDialpadSelected(true);
        }
    }

    const updateDialpadVisibility = (isVisible: boolean) => {
        displayDialpadRef.current = isVisible;
        setDisplayDialpad(isVisible);
        updateLeftPanelVisibiltiy();
    }

    const handleOutboundAccessEnabledStage3 = (isAccessEnabled: boolean) => {
        if (isAccessEnabled) {
            socketOutbound.GetEndPointListForAgent(true);
            hasOutboundAccesEnabled.current = isAccessEnabled;
            setDialpadRenderKey(Math.random());
        }
        else {
            setDisplayDialpad(isAccessEnabled);
            displayDialpadRef.current = isAccessEnabled;
            hasOutboundAccesEnabled.current = isAccessEnabled;

            updateLeftPanelVisibiltiy();
        }
    }

    const handleOutboundAccessEnabledStage2 = (isAccessEnabled: boolean) => {
        setDisplayDialpad(isAccessEnabled);
        displayDialpadRef.current = isAccessEnabled;
        hasOutboundAccesEnabled.current = isAccessEnabled;
        updateLeftPanelVisibiltiy();
    }

    const updateLeftPanelVisibiltiy = () => {
        mainViewHelper.showHideLeftPanelTriggerButtons.next(displayDialpadRef.current || displayFavoritesRef.current);
    }

    const handleDialpadKeyShortcuts = () => {
        if (!hasOutboundAccesEnabled.current || !displayDialpadRef.current) {
            return;
        }

        setIsDialpadSelected(true);
        setIsFavoritesSelected(false);
    }

    const hasAnyFavoriteContact = (isInit: boolean = false) => {
        if (Env.isStage3()) {
            isInit ? checkCacheIfExistsAnyFavorite() : existsAnyFavoriteContactsStage3();
        } else {
            existsAnyFavoriteContactsStage2();
        }
    }

    const existsAnyFavoriteContactsStage2 = () => {
        const userId = Lockr.get<number>(StorageKeys.UserId);

        socketContacts.hasAnyFavoriteContact(userId)
            .then((response: boolean) => toggleFavoriteTab(response));
    }

    const existsAnyFavoriteContactsStage3 = () => {
        if (!initialClientLoadService.initialSettings) {
            Promise.all([extendedFavoritesService.getFavoritesCount(), extendedFavoritesService.getFavoriteConfiguration()])
                .then((values: any) => toggleFavoriteTab(values[1].isEnabled && values[0] > 0));

            return;
        }

        if (!initialClientLoadService.initialSettings.FavoritesIsEnabled) {
            return;
        }

        extendedFavoritesService.getFavoritesCount()
            .then(count => toggleFavoriteTab(!!count));
    }

    const checkCacheIfExistsAnyFavorite = () => {
        const initialSettings = initialClientLoadService.initialSettings;

        if (!initialClientLoadService.isInitialSettingsRequestDone || !initialSettings) {
            return;
        }

        toggleFavoriteTab(initialSettings.FavoritesIsEnabled && !!initialSettings.FavoritesContactsCount);
    }

    const toggleFavoriteTab = (isVisible: boolean) => {
        setDisplayFavorites(isVisible);
        displayFavoritesRef.current = isVisible;

        updateLeftPanelVisibiltiy();
    }

    const isAgentOutboundAccessEnabled = () => {
        const accessEnabled = socketOutbound.HasOutboundAccesEnabled();
        setDisplayDialpad(accessEnabled);
        displayDialpadRef.current = accessEnabled;
        updateLeftPanelVisibiltiy();
    }

    const toggleNavigationView = (isDialpad: boolean) => {
        const isDialpadString = isDialpad.toString();

        setIsDialpadSelected(isDialpad);
        setIsFavoritesSelected(!isDialpad);

        setMenuItems((currentState: TabData[]) => currentState.map((el: TabData) => ({ ...el, active: el.isdialpad === isDialpadString })));
    }

    const componentWillUnmount = () => {
        GeneralHelper.logCox(`in NavigationView.tsx, in componentWillUnmount`);
        subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
        subscriptionHasAnyFavoriteContactChanged?.unsubscribe();
        subscriptionTrustedEnpointsChanged?.unsubscribe();
        subscriptionOutboundEndpointChanged?.unsubscribe();
        subscriptionInitialSettingsRequestCompleted?.unsubscribe();
    }

    return (<>
        {(displayDialpad || displayFavorites) && <div className="navigation-container">
            <div className="column">
                <Flex column
                    styles={({ theme: { siteVariables } }) => ({
                        color: siteVariables.colorScheme.default.foreground,
                        backgroundColor: siteVariables.colorScheme.default.background,
                    })}
                >
                    <div className="navigation-container-menu">
                        <Menu
                            defaultActiveIndex={1}
                            items={menuItems}
                            underlined
                            primary
                            accessibility={tabListBehavior}
                            styles={{
                                paddingBottom: '10px',
                                paddingTop: '20px',
                                alignSelf: 'center',
                                border: '0'
                            }}
                        />
                    </div>
                    <Divider size={1} />
                    {
                        isDialpadSelected && <DialpadView
                            isPhoneNumberInputFocused={shouldDialpadPhoneNumberBeFocused}
                            isEndpointDropdownOpenDefault={shouldEndpointDropdownBeOpen}
                        />
                    }
                    {
                        isFavoritesSelected && (EnvHelper.isStage3()
                            ? <ExtendedFavoritesView />
                            : <FavoritesView isDialpadEnabled={displayDialpad} />)
                    }
                </Flex>
            </div>
        </div>
        }
    </>
    )
};

export default NavigationView;

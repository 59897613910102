import { Subject } from 'rxjs';
import { logging } from 'utils/logging';
import { QueueUserS3DTO } from 'utils/domain/queueUserDTO';
import { GeneralHelper } from 'utils/helpers';
import { Controllers, QueueOperations } from 'utils/enums-s3';

// Stage3 Queue Service
export class QueueService {
    private readonly logger = logging.getLogger('QueueService');
    queuesArray: QueueUserS3DTO[] = [];
    queueChange: Subject<any> = new Subject<any>();
    queueChangeNotification: Subject<any> = new Subject<any>();

    //Management view handles queue membership add/remove and notifies the rest of the views
    queueMembershipAdded: Subject<any> = new Subject<any>();
    queueSupervisorChanged: Subject<any> = new Subject<any>();
    queueOperatorChanged: Subject<any> = new Subject<any>();
    queueMembershipRemoved: Subject<any> = new Subject<any>();

    getQueues(userRef: string): Promise<QueueUserS3DTO[]> {
        return Promise.all([
            GeneralHelper.invokeServiceCall(userRef, QueueOperations.GetQueuesForAgent, this.logger, Controllers.QueueUser), 
            GeneralHelper.invokeServiceCall(userRef, `agent-queues/${QueueOperations.GetQueuesForAgent}`, this.logger, Controllers.Messaging)
        ])
        .then((response: QueueUserS3DTO[][]) => {
            response[1].forEach(x => x.IsGeneric = true);
            const array = response.flatMap(x => x);
            this.queuesArray = array;
            return array;
        });
    }

    getGenericQueues(userRef: string): Promise<QueueUserS3DTO[]> {
        return GeneralHelper.invokeServiceCall(userRef, `agent-queues/${QueueOperations.GetQueuesForAgent}`, this.logger, Controllers.Messaging)
            .then((response: QueueUserS3DTO[]) => {
                this.queuesArray = response;
                return response;
            });
    }

    hasSupervisorRights() {
        return this.queuesArray.some(element => element.IsSupervisor);
    }

    hasSupervisorOrOperatorRights() {
        return this.queuesArray.some(element => element.IsOperator || element.IsSupervisor);
    }

    async clientQueuesChanged(userRef: string, queueRef: string, isAttached: boolean): Promise<any> {
        const requestData = {
            userRef: userRef,
            queueRef: queueRef,
            isAttached: isAttached
        };

        await GeneralHelper.invokeServiceCall(requestData, QueueOperations.ClientQueuesChanged, this.logger, Controllers.QueueUser);
    }

    async clientGenericQueuesChanged(userRef: string, queueRef: string, isAttached: boolean): Promise<any> {
        const requestData = {
            userRef: userRef,
            queueRef: queueRef,
            isAttached: isAttached
        };

        await GeneralHelper.invokeServiceCall(requestData, `agent-queues/${QueueOperations.ClientQueuesChanged}`, this.logger, Controllers.Messaging);
    }
}

import { StorageKeys } from 'utils';

export interface ConfigDefinition {
    socketServerDomain: string;
    azureEnabled: boolean;
    gatewayApiUrl: string;
    signalRUrl: string;
    botApiUrl: string;
    getFrontEndUrl(companyKey: string): string;
    customerJourneyApiUrl: string;
}

export class Configuration {
    socketServerDomain = (window as any)['config'] ? (window as any)['config'].socketServerDomain || 'localhost:8085' : undefined;
    azureEnabled = (window as any)['config'] ? !!(window as any)['config'].azureClients && !!(localStorage.getItem(StorageKeys.UPNForAzure)) : false;
    gatewayApiUrl = `${(window as any)['config'].gatewayApiUrl}/${(window as any)['config'].apiVersion}` || 'http://localhost:5121/v1';
    signalRUrl = (window as any)['config'].signalRUrl || 'http://localhost:5103/hub/notificationhub';
    botApiUrl = (window as any)['config'].botApiUrl || 'http://localhost:5110';
    customerJourneyApiUrl = (window as any)['config'].customerJourneyApiUrl || 'http://localhost:5120/api/v1';

    getFrontEndUrl(companyKey: string) {
        const frontEnds = (window as any)['config'].frontEnd;

        if (frontEnds) {
            for (const key in frontEnds) {
                if (key === companyKey) {
                    return frontEnds[key];
                }
            }
        }

        return '';
    }
}

export const ConfigurationHelper = new Configuration();
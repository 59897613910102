import { Listener, listeners } from 'services/io/listeners';
import { Subject, Subscription } from 'rxjs';
import { ListenerOperations, ServiceOperations } from 'utils';
import { serviceCall } from 'services/io/service-call';
import { InvokeServiceArgs } from 'services/io/interfaces';
import { logging } from 'utils/logging';

enum OperatorOperations {
    Add = 'Add',
    Update = 'Update',
    Delete = 'Delete'
}

export interface CallPickupObj {
    agentRef: number,
    sessionId: string
}

export class OperatorListManager {
    private readonly logger = logging.getLogger('OperatorListManager');

    private readonly subscriptionOperatorChange: Subscription | null = null;

    public readonly listenerUpdateOperatorCall: Listener<any> = listeners.createListener<any>(ListenerOperations.UpdateOperatorCall);

    onOperatorCallAdded: Subject<any> = new Subject<any>();
    onOperatorCallUpdated: Subject<any> = new Subject<any>();
    onOperatorCallRemoved: Subject<any> = new Subject<any>();

    constructor() {
        this.subscriptionOperatorChange?.unsubscribe();
        this.subscriptionOperatorChange = this.listenerUpdateOperatorCall.received.subscribe((obj: any) => {
            switch (obj.updateTypeAsString) {
                case OperatorOperations.Add:
                    this.onOperatorCallAdded.next(obj.waitingMedia);
                    break;
                case OperatorOperations.Update:
                    this.onOperatorCallUpdated.next(obj.waitingMedia);
                    break;
                case OperatorOperations.Delete:
                    this.onOperatorCallRemoved.next(obj.waitingMedia);
                    break;
            }
        });
    }

    getOperatorCallsByAgentSip(agentSip: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const request = agentSip;
            const args: InvokeServiceArgs = {
                operation: ServiceOperations.GetOperatorCallsByAgent,
                requestData: request,
                responseHandler: {
                    success: (result) => {
                        resolve(result);
                    },
                    error: (err) => {
                        logging.errorHandler.next("ErrorMessage.WebChat.PostChatMessageToWebClient");
                        this.logger.error(err);
                        reject(err);
                    }
                }
            };
            serviceCall.invokeService(args);
        });
    }
}

export const operatorListManager = new OperatorListManager();
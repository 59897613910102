import { Checkbox, Flex, Text } from "@fluentui/react-northstar"
import AdvancedTable from "components/AdvancedTable"
import { Paginator } from "containers";
import React, { useRef, useState } from "react"
import { useIntl } from "react-intl";
import { QueueMediaViewType } from "utils";
import { MediaDisplayItem } from "utils/domain/supervisor/SupervisorMediaDtoStage3";
import { ColumnsResolverStage3 } from "../SupervisorCallsView/ColumnsResolverStage3";
import '../SupervisorCallsView/SupervisorCallsView.scss';

export const QueueMediaViewStage3 = (props: any) => {
    const intl = useIntl();
    const { viewType, mediaItems, activeMediaCount, waitingMediaCount, showActiveMedia,
        showWaitingMedia, activeMediaFilterCallback, waitingMediaFilterCallback } = props;
    const [displayMediaList, setDisplayMediaList] = useState<MediaDisplayItem[]>(mediaItems);
    const columns = ColumnsResolverStage3(viewType === QueueMediaViewType.Waiting);

    const RecordsPerPage = 10;
    const currentPage = useRef(1);
    const numberOfPages = useRef(1);
    const paginatorExists = useRef(false);

    React.useEffect(() => {
        initializePaginator(mediaItems);
    }, [mediaItems]);

    const initializePaginator = (obj: any) => {
        paginatorExists.current = obj.length > RecordsPerPage;
        numberOfPages.current = getPaginatorNumberOfPages(obj.length);

        if (numberOfPages.current < currentPage.current) {
            currentPage.current = numberOfPages.current;
        }
        setDisplayMediaList(obj.slice((currentPage.current - 1) * RecordsPerPage, currentPage.current * RecordsPerPage));
    }

    const selectPage = (e: any) => {
        setDisplayMediaList(mediaItems.slice((e - 1) * RecordsPerPage, e * RecordsPerPage));
        currentPage.current = e;
    }

    const getPaginatorNumberOfPages = (records: any) => {
        return Math.ceil(records / RecordsPerPage) || 1;
    }

    let paginator: any = null;
    if (paginatorExists.current) {
        paginator = <Paginator
            currentPage={currentPage.current}
            totalPages={numberOfPages.current}
            onChange={selectPage}
        />
    }

    const getMediaCount = (isActive: boolean) => {
        if (isActive) {
            return activeMediaCount === 0 ? "" : ` (${activeMediaCount.toString()})`;
        }

        return waitingMediaCount === 0 ? "" : ` (${waitingMediaCount.toString()})`;
    }

    return (
        <Flex column>
            {
                viewType === QueueMediaViewType.Supervisor && <Flex gap="gap.medium">
                    <Text content={intl.formatMessage({ id: "SupervisorCallsView.Overview" })} weight="bold" />
                    <Checkbox label={intl.formatMessage({ id: "SupervisorCallsView.ActiveMedia" }) + getMediaCount(true)}
                        checked={showActiveMedia} onClick={activeMediaFilterCallback} className="filter-checkbox" />
                    <Checkbox label={intl.formatMessage({ id: "SupervisorCallsView.WaitingMedia" }) + getMediaCount(false)}
                        checked={showWaitingMedia} onClick={waitingMediaFilterCallback} className="filter-checkbox" />
                </Flex>
            }
            {
                viewType === QueueMediaViewType.Waiting && <Text
                    content={intl.formatMessage({ id: "AgentView.WaitingCalls" }) + getMediaCount(false)}
                    weight="bold"
                />
            }
            <AdvancedTable columns={columns} rows={displayMediaList} label="Waiting Calls" className="waiting-list-table" />
            {paginator}
        </Flex>
    )
}
import { Listener, listeners } from "services/io/listeners";
import { Controllers, logging } from "utils";
import { TabDTO } from "utils/domain/extended/tabDTO";
import { ExternalTabsOperation } from "utils/enums-s3";
import { GeneralHelper } from "utils/helpers";
import { ApiExtendedTabs } from ".";

export class ExtendedTabsService implements ApiExtendedTabs {
    private readonly logger = logging.getLogger('ExtendedTabsService');
    public readonly listenerNotifyTabsChange: Listener<any> = listeners.createListener<any>(ExternalTabsOperation.NotifyTabsChange);

    GetExternalTabs(): Promise<TabDTO[]> {
        return GeneralHelper.invokeServiceCall(null, ExternalTabsOperation.GetExternalTabs, this.logger, Controllers.Tabs);
    }
}

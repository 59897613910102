import { Accessibility, MenuItemBehaviorProps, MenuItemIndicatorProps, PopupEvents, PopupEventsArray, ShorthandValue } from "@fluentui/react-northstar";
import { PresenceAvailability } from "utils";

export class Presence {
    Id?: string;
    Sip?: string;
    Availability!: string
    Activity?: string;
    PresenceType?: PresenceAvailability;
    WrapUpCodeId?: string;
    WrapUpCodeName?: string;
    IsWrapup?: boolean;
}

export class PresenceList {
    ResultList!: Presence[];
}

export class PresenceAndNoteDTO {
    Availability?: string
    Activity?: string;
}

export enum PresenceState {
    Online = 'Online',
    Available = 'Available',
    AvailableIdle = 'AvailableIdle',
    Busy = 'Busy',
    BusyIdle = 'BusyIdle',
    DND = 'DoNotDisturb',
    BeRightBack = 'BeRightBack',
    Away = 'Away',
    Offline = 'Offline',
    PresenceUnknown = 'PresenceUnknown',
    InATeamsCall = 'InACall'
}

export enum NotePresenceState {
    Busy = 0,
    DND = 1,
    Unavailable = 2,
    Away = 3
}

export class PresenceItem {
    content?: JSX.Element;
    menu?: { items: PresenceItem[], className: string };
    onClick?: (e: MouseEvent) => void;
    onFocus?: (e: FocusEvent) => void;
    open?: boolean;
    disabled?: boolean;
    menuOpen?: boolean;
    on?: PopupEvents | PopupEventsArray;
    key?: string;
    indicator?: ShorthandValue<MenuItemIndicatorProps>;
    className?: string;
    accessibilityItemProps?: any;
    accessibility?: Accessibility<MenuItemBehaviorProps>;
}
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Subscription } from 'rxjs';
import Lockr from 'lockr';
import { MenuButton, Button, LeaveIcon, PlayIcon, Flex, Text, RetryIcon } from '@fluentui/react-northstar';
import { socketPresence } from 'services/presence';
import { logging, ReasonNameConstants, StorageKeys, UserResetStatus } from 'utils';
import { serviceRequests } from 'services/io';
import { socketQueue } from 'services/queue';
import './presenceView.scss';
import { socketAuth } from 'services';
import { useIntl } from 'react-intl';
import { GeneralHelper } from 'utils/helpers';
import { Presence, PresenceState, NotePresenceState, PresenceItem } from 'utils/domain/presence';
import { socketContacts } from 'services/contacts';
import { presenceStressTest } from 'services/presence/presence-stress-test';
import useKeyCombination from 'utils/domain/hooks/useKeyCombination';
import ModifierKeys from 'utils/domain/hooks/ModifierKeys';
import { ReasonAssignedDto } from 'utils/domain/extended/reasonAssignedDto';
import { PresenceViewBuilder } from './PresenceViewBuilder';

type Props = {
  enableKeyboardShortcut?: boolean;
}

export const CurrentUserPresenceView = (props: Props) => {
  const intl = useIntl();

  const currentPresenceText = useRef<string>("");
  const currentNoteText = useRef<string>("");
  const isWrapUpExist = useRef<boolean>(false);//#warning-js: would move this to socketPresence, to have a single ref, true/false, and not subscribe here ...
  const [presence, setPresence] = useState<JSX.Element>((<></>));
  const [busyReasons, setBusyReasons] = useState<any[]>([]);
  const [dndReasons, setDndReasons] = useState<any[]>([]);
  const [awayReasons, setAwayReasons] = useState<any[]>([]);
  const [isPresenceMenuOpen, setIsPresenceMenuOpen] = useState(false);
  const presenceViewBuilder = useMemo<PresenceViewBuilder>(() => new PresenceViewBuilder(), []);

  const userDetails = useMemo(() => ({
    id: Lockr.get<string>(StorageKeys.UserObjectId),
    fullName: Lockr.get<string>(StorageKeys.FullName),
    sip: Lockr.get<string>(StorageKeys.SIP),
  }), []);

  let subscriptionPresenceUpdateReceived: Subscription | null = null;
  let subscriptionSocketPresenceSelect: Subscription | null = null;
  let subscriptionSocketPresenceWrapup: Subscription | null = null;
  let subscriptionSocketQueueChange: Subscription | null = null;
  let subscriptionPresenceAndNoteSetPresence: Subscription | null = null;
  let subscriptionPresenceAndHasClient: Subscription | null = null;

  useKeyCombination([ModifierKeys.Alt], ['p'], () => {
    if (props.enableKeyboardShortcut) {
      setIsPresenceMenuOpen(prev => !prev);
    }
  });

  useEffect(() => {
    initialize();

    return () => {
      subscriptionPresenceUpdateReceived?.unsubscribe();
      subscriptionSocketPresenceSelect?.unsubscribe();
      subscriptionSocketPresenceWrapup?.unsubscribe();
      subscriptionPresenceAndNoteSetPresence?.unsubscribe();
      subscriptionSocketQueueChange?.unsubscribe();
      subscriptionPresenceAndHasClient?.unsubscribe();
    }
  }, []);

  const getPresenceStatusText = (presenceState: string): string => GeneralHelper.getStatusText(presenceState, intl);

  const getSubMenuItems = (presenceState: PresenceState) => {
    switch (presenceState) {
      case PresenceState.Busy:
        return busyReasons;
      case PresenceState.DND:
        return dndReasons;
      case PresenceState.Away:
        return awayReasons;
      default:
        return [];
    }
  }

  const getElementForPresence = (presenceState: string, presenceText?: string): JSX.Element => {
    const presenceStatusElement = presenceViewBuilder.statusElements.get(presenceState);
    const procesedPresenceText = getPresenceStatusText(presenceText || presenceState);

    return !!presenceStatusElement
      ? presenceViewBuilder.presenceElement(presenceStatusElement, procesedPresenceText)
      : presenceViewBuilder.genericPresenceElement(procesedPresenceText, sendPresenceChange);
  }

  const buildSubmenuReasons = (presenceState: PresenceState): PresenceItem => {
    const subMenuItems = getSubMenuItems(presenceState);
    const existsItems = !!subMenuItems.length;
    const indicator = existsItems ? <PlayIcon size="smallest" /> : null;
    const menu = existsItems
      ? {
        className: "presence-submenu",
        items: [...subMenuItems]
      }
      : undefined;

    return {
      content: <span className="presence-with-reasons-popup-trigger">
        {getElementForPresence(presenceState)}
      </span>,
      onClick: () => sendPresenceChange(presenceState, getPresenceStatusText(presenceState)),
      key: presenceState.toString(),
      on: "hover",
      menu: menu,
      indicator: indicator
    };
  }

  const buildPresenceItems = () => {
    const logoutText = intl.formatMessage({ id: "SettingsView.Logout" });
    const resetText = intl.formatMessage({ id: "SettingsView.Reset" });

    return [
      {
        content: getElementForPresence(PresenceState.Online),
        onClick: () => sendPresenceChange(PresenceState.Online, ""),
        key: PresenceState.Online.toString(),
      },
      buildSubmenuReasons(PresenceState.Busy),
      buildSubmenuReasons(PresenceState.DND),
      {
        content: getElementForPresence(PresenceState.BeRightBack),
        onClick: () => sendPresenceChange(PresenceState.BeRightBack, ""),
        key: PresenceState.BeRightBack.toString(),
      },
      buildSubmenuReasons(PresenceState.Away),
      {
        content: <hr />,
        key: "divider",
        disabled: true,
        className: "break-line",
        accessibility: presenceViewBuilder.getDividerAccesibilityAttributes
      },
      {
        content:
          <Flex gap="gap.small" vAlign="center" hAlign="center">
            <RetryIcon size="small" />
            <Text className="reset-button-text" content={resetText} />
          </Flex>,
        onClick: () => { processUserResetClick() },
        key: resetText,
        className: "reset-button"
      },
      {
        content: <hr />,
        key: "divider-reset-logout",
        className: "break-line",
        disabled: true,
        accessibility: presenceViewBuilder.getDividerAccesibilityAttributes
      },
      {
        content:
          <Flex gap="gap.small" vAlign="center" >
            <LeaveIcon size="small" />
            <Text className="logout-button-text" content={`${logoutText} ${userDetails.fullName}`} />
          </Flex>,
        onClick: () => { socketAuth.userManuallySignedOut.next() },
        key: logoutText,
        className: "logout-button"
      }];
  }

  const items = useMemo<PresenceItem[]>(() => buildPresenceItems(), [busyReasons, dndReasons, awayReasons]);

  const processUserResetClick = () => {
    socketAuth.resetUser()
      .then(resetStatus => {
        switch (resetStatus) {
          case UserResetStatus.ResetPerformed:
            logging.errorHandler.next("SettingsView.ResetPerformed");
            break;
          case UserResetStatus.DndStatusIsNotAllowed:
            logging.errorHandler.next("SettingsView.Red.DndStatusIsNotAllowed");
            break;
          case UserResetStatus.CallInProgress:
            logging.errorHandler.next("SettingsView.Red.CallInProgress");
            break;
        }
      })
      .catch(e => console.error(e));
  }

  const changePresence = (presenceState: string, presenceText: string, wrapUpCodeId?: string | undefined, wrapUpCodeName?: string | undefined, isWrapup: boolean = false) => {
    // do not set wrapup reason, if in wrapup and already 'in a teams call'
    if (isWrapup && currentNoteText.current === PresenceState.InATeamsCall) {
      return;
    }

    setPresence(getElementForPresence(presenceState, presenceText));
    currentPresenceText.current = presenceState;
    currentNoteText.current = presenceText || "";

    // send presence changed to server
    if (!isWrapup) {
      serviceRequests.ChangePresence(userDetails.sip, presenceState, presenceText || "", userDetails.id, undefined, wrapUpCodeId, wrapUpCodeName);
    }
    presenceStressTest.testSpecialNote(presenceText);
  }

  const initialize = () => {
    initializeComponent();

    subscriptionPresenceUpdateReceived?.unsubscribe();
    subscriptionPresenceUpdateReceived = socketContacts.notifyUpdatedPresenceState.subscribe((userPresence: Presence) => {
      if (userPresence.Id !== userDetails.id ||
        (userPresence.Activity === ReasonNameConstants.Offering && currentNoteText.current === ReasonNameConstants.Offering)) {
        return;
      }

      setPresence(getElementForPresence(userPresence.Availability, userPresence.Activity));
      currentPresenceText.current = userPresence.Availability;
      currentNoteText.current = userPresence.Activity as string || "";
    });

    // only subscribe listeners for current user presence view
    subscriptionSocketPresenceSelect?.unsubscribe();
    subscriptionSocketPresenceSelect = socketPresence.select.subscribe((result: Presence) => {
      changePresence(result.Availability, result.Activity ?? '', result.WrapUpCodeId, result.WrapUpCodeName, result.IsWrapup);
    });
    subscriptionSocketPresenceWrapup?.unsubscribe();
    subscriptionSocketPresenceWrapup = socketPresence.wrapUp.subscribe((result: boolean) => {
      isWrapUpExist.current = result;
    });
    subscriptionPresenceAndNoteSetPresence?.unsubscribe();
    subscriptionPresenceAndNoteSetPresence = socketPresence.listenerPresenceAndNoteSet.received.subscribe((obj: any) => {
      if (!isWrapUpExist.current || obj.Activity === ReasonNameConstants.Offering) {
        setPresence(getElementForPresence(obj.Availability, obj.Activity));
        currentPresenceText.current = obj.Availability as string;
        currentNoteText.current = obj.Activity as string || "";
        if (isWrapUpExist.current || obj.Activity === ReasonNameConstants.Offering) {
          socketPresence.select.next({ Availability: obj.Availability, Activity: obj.Activity, undefined } as Presence)
        }
      }
    });
    subscriptionSocketQueueChange?.unsubscribe();
    subscriptionSocketQueueChange = socketQueue.subjectQueueChange.subscribe((resultQueue: any) => {
      socketPresence.GetWrapUpCodes(0).then((result: any) => {
        preparePresences(result, resultQueue);
      });
    });

    subscriptionPresenceAndHasClient?.unsubscribe();
    subscriptionPresenceAndHasClient = socketContacts.presenceAndHasClientNotification.subscribe((presenceAndHasClient: any) => {
      const userId = Lockr.get(StorageKeys.UserObjectId);

      if (!presenceAndHasClient || !presenceAndHasClient.Presence || presenceAndHasClient.Presence?.Availability === PresenceState.Offline) {
        return;
      }

      if (presenceAndHasClient.Presence?.Id === userId && presenceAndHasClient?.HasClient === false) {
        socketAuth.setHasCC4TeamsTrue(userId as string);
      }
    });
  }

  const initializeComponent = () => {
    socketPresence.GetCurrentPresence().then((result: Presence) => {
      setPresence(getElementForPresence(result.Availability, result.Activity));
      currentPresenceText.current = result.Availability;
      currentNoteText.current = result.Activity || "";
    });
  }

  const preparePresences = (result: ReasonAssignedDto[], queueRefs: (any | undefined)[]) => {
    if (!queueRefs || !queueRefs.length) {
      return;
    }

    const queueIds = queueRefs.map(x => x.QueueRef);

    items.forEach(presenceItem => {
      let reasons: any;

      if (presenceItem.key?.toLowerCase() === PresenceState.Busy.toLowerCase()) {
        reasons = preparePresenceForStatus(result, NotePresenceState.Busy, PresenceState.Busy, queueIds);
        setBusyReasons(reasons);
      }
      else if (presenceItem.key?.toLowerCase() === PresenceState.DND.toLowerCase()) {
        reasons = preparePresenceForStatus(result, NotePresenceState.DND, PresenceState.DND, queueIds);
        setDndReasons(reasons);
      }
      else if (presenceItem.key?.toLowerCase() === PresenceState.Away.toLowerCase()) {
        reasons = preparePresenceForStatus(result, NotePresenceState.Away, PresenceState.Away, queueIds);
        setAwayReasons(reasons);
      }
    });
  }

  const preparePresenceForStatus = (result: ReasonAssignedDto[], status: NotePresenceState, statusValue: PresenceState, queueRefs: string[]) => {
    const notes: string[] = result
      .filter((x: any) => x.Presence === status && !x.IsDeleted && queueRefs.includes(x.QueueRef))
      .map((x: any) => x.Name);

    return Array.from(new Set(notes)).map((note: string) => (
      {
        content: getElementForPresence(statusValue, note),
        onClick: (e: MouseEvent) => {
          sendPresenceChange(statusValue, getPresenceStatusText(note));
          e.stopPropagation();
        },
        key: note,
        className: 'presence-submenu-item'
      })
    );
  }

  const sendPresenceChange = (localPresence: PresenceState, note: string, closePresenceMenu: boolean = true) => {
    if (localPresence !== currentPresenceText.current || currentNoteText.current !== note) {
      socketPresence.select.next({ Availability: localPresence, Activity: note } as Presence);
    }

    setIsPresenceMenuOpen(!closePresenceMenu);
  }

  return (
    <MenuButton
      align={"end"}
      className="presence-menu"
      onOpenChange={(_, popupProps) => setIsPresenceMenuOpen(!!popupProps?.open)}
      open={isPresenceMenuOpen}
      menu={items}
      trigger={<Button
        onClick={() => setIsPresenceMenuOpen(!isPresenceMenuOpen)}
        content={presence}
        aria-label="Presence"
        className="presence-button"
      />}
    />
  )
};

export default CurrentUserPresenceView;

import { AttachmentData } from "./AttachmentData";
import RecipientData from "./Recipient";

export default class EmailData {
    to: RecipientData[] = [];
    bcc: RecipientData[] = [];
    cc: RecipientData[] = [];
    subject: string = '';
    text: string = '';
    attachments: AttachmentData[] = [];
}
export class TeamsStorageUtils {
    
    readonly removeTeamsScopedTokenFromStorage = () => {
        for (const key in localStorage) {
            if (key.includes("authority") &&
                key.includes("scopes") &&
                key.includes("homeAccountIdentifier")) {
                localStorage.removeItem(key);
            }
        }
    }
}
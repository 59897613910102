import {
    HubConnectionBuilder,
    HubConnectionState,
    HubConnection,
    IHttpConnectionOptions,
    HttpTransportType,
} from '@microsoft/signalr';
import { ConfigurationHelper, EnvHelper } from 'utils/helpers';
import { Subject } from 'rxjs';
import { logging, NotificationHubOperations, SocketOperations, StorageKeys } from 'utils';
import { socketAuth } from 'services/auth';
import Lockr from 'lockr';
import { socketSettings } from 'services/settings';

export class SignalRManager {

    onInitialized: Subject<any> = new Subject<any>();
    onReconnect: Subject<any> = new Subject<any>();
    onDisconnect: Subject<boolean> = new Subject<boolean>();

    connectionOptions: IHttpConnectionOptions = {
        accessTokenFactory: () => {
            return Lockr.get<string>(StorageKeys.TokenId);
        },
        transport: HttpTransportType.None
    };

    socket: HubConnection = new HubConnectionBuilder()
        .withUrl(ConfigurationHelper.signalRUrl, this.connectionOptions)
        .withAutomaticReconnect([0, 1000, 3000, 5000])
        .build();

    private readonly roomsJoined: string[] = new Array<string>();

    public initialize = () => {
        this.setUpSignalRConnection();
    }

    private readonly setUpSignalRConnection = async () => {
        console.log(ConfigurationHelper.signalRUrl)

        try {
            if (EnvHelper.isStage3()) {
                const tokenId = Lockr.get<string>(StorageKeys.TokenId);

                if (!tokenId || this.socket.state === HubConnectionState.Connected) {
                    return;
                }
            }

            try {
                await this.socket.start();
            } catch (error) {
                // trigger a backend request that will refresh the expired jwt token
                await socketSettings.getCallCenterInfo()
                    .then(async () => {
                        await this.socket.start();
                    });
            }

            this.onInitialized.next();

            this.socket.on(SocketOperations.SocketConnected, () => {
                this.roomsJoined.forEach(r => this.joinRoom(r));
            });

            this.socket.on(SocketOperations.SignedOtherBrowser, (sessionId: string) => {
                socketAuth.userSignedInOtherWindow.next(sessionId);
            });
            this.socket.onreconnecting(() => {
                // reload window to get any missed changes CC4ALL-4640
                window.location.reload();
            });

            this.socket.onclose(() => {
                // set offline on connection closed
                logging.offlineHandler.next();
            });
        } catch (err) {
            console.log(err);
        }
    };

    public joinRoom(room: string): void {
        if (this.socket.state === HubConnectionState.Connected) {
            this.socket.invoke(NotificationHubOperations.SubscribeToNotificationHub, room).catch((err: Error) => {
                return console.error(err.toString());
            });
        }

        if (!this.roomsJoined.includes(room)) {
            this.roomsJoined.push(room);
        }
    }

    public leaveRoom(room: string): void {
        if (this.socket.state === HubConnectionState.Connected) {
            this.socket.invoke(NotificationHubOperations.UnSubscribeToNotificationHub, room).catch((err: Error) => {
                return console.error(err.toString());
            });
        }

        if (this.roomsJoined.includes(room)) {
            this.roomsJoined.pop();
        }
    }

    public disconnect(): void {
    }

    public notifyAllViewsAtLogin(userId: string, browserSessionId: string): void {
        if (this.socket.state === HubConnectionState.Connected) {
            this.socket.invoke(NotificationHubOperations.NotifySignedInAnotherView, userId, browserSessionId).catch((err: Error) => {
                return console.error(err.toString());
            });
        }
    }
}
import * as React from 'react';
import { Flex, Text, List, Avatar, AcceptIcon, CloseIcon, Button, BanIcon, MenuButton, MoreIcon, Popup } from '@fluentui/react-northstar';
import Lockr from 'lockr';
import { StorageKeys, ChatStatusType, ChatMessageType, ChatMessageSource, ActiveMainViewItem } from 'utils';
import { webChatManager } from 'services/io/web-chat';
import "./chatListView.scss";
import { ChatViewProps } from 'utils/domain/chatViewProps'
import { useIntl } from 'react-intl';
import { ChatSessionDTO } from 'utils/domain/chatSessionDTO';
import { socketSocialMedia } from 'services/social-media';
import { CMMediaSessionMessageDTO } from 'utils/domain/socialMedia/socialMediaSessionDTO';
import { GeneralHelper } from 'utils/helpers';
import { Subscription } from 'rxjs';
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import { ChatHistoryDTO } from 'utils/domain/chathistoryDTO';

export const ChatListView = ({ activeWebchatsessionId, isCmMedia }: any) => {
    const intl = useIntl();

    const [chatListKey, setChatListKey] = React.useState(Math.random() + "chat-list" as any);

    const [listItems, setListItems] = React.useState([] as any);

    let agentWebChats: any[] = [];
    let listItemsArray: any[] = [];

    let subscriptionOnMessageSentFromAgent: Subscription | null = null;
    let subscriptionSendCmMessageReceivedFromCustomer: Subscription | null = null;
    let subscriptionNewWebChat: Subscription | null = null;
    let subscriptionMessageReceivedFromCustomer: Subscription | null = null;
    let subscriptionSendChatDetailsToClients: Subscription | null = null;
    let subscriptionOnTransferToQueueInitiated: Subscription | null = null;
    let subscriptionOnTransferToAgentInitiated: Subscription | null = null;
    let subscriptionOnSendChatTransferInvitationAccepted: Subscription | null = null;
    let subscriptionOnSendChatTransferInvitationIgnored: Subscription | null = null;
    let subscriptionOnEndChat: Subscription | null = null;

    let nrOfChats = 0;

    const initListeners = () => {
        subscriptionOnMessageSentFromAgent?.unsubscribe();
        subscriptionOnMessageSentFromAgent = webChatManager.onMessageSentFromAgent.subscribe((obj: any) => {
            const activeChat = agentWebChats.find(c => c.SessionRef === obj.SessionRef);
            activeChat.LastMessageDate = obj.Date;
            activeChat.LastMessage = obj.Message;
            updateExistingChat(activeChat);

        });

        if (isCmMedia) {
            subscriptionSendCmMessageReceivedFromCustomer?.unsubscribe();
            subscriptionSendCmMessageReceivedFromCustomer = socketSocialMedia.sendCmMessageReceivedFromCustomer.subscribe((cmMessages: CMMediaSessionMessageDTO) => {
                const mapLastMessageToChatSessionArray = GeneralHelper.mapCmMessagesToChatSessionDTO([cmMessages], activeWebchatsessionId);
                if (mapLastMessageToChatSessionArray?.length && agentWebChats?.length) {
                    const chatHistory: ChatHistoryDTO[] = mapLastMessageToChatSessionArray[0].ChatHistories;
                    agentWebChats[0].ChatHistories = agentWebChats[0].ChatHistories.concat(chatHistory);
                    mapLastMessagesToWebChats();
                    selectChat(agentWebChats[0]);
                }

            });
        } else {
            subscriptionNewWebChat?.unsubscribe();
            subscriptionNewWebChat = webChatManager.onSendChatSessionToAgent.subscribe((chat: any) => {
                if (isNewChat(chat)) {
                    addNewChat(chat);
                }
            });

            subscriptionMessageReceivedFromCustomer?.unsubscribe();
            subscriptionMessageReceivedFromCustomer = webChatManager.onMessageReceivedFromCustomer.subscribe((message: any) => {
                for (const element of agentWebChats) {
                    if (element.SessionRef === message.ChatSessionRef) {
                        element.LastMessage = message.Message;
                        element.LastMessageDate = new Date(message.Date);

                        if (!element.IsActive) {
                            element.IsHighlighted = true;
                        } else {
                            updateChatClickTime(element);
                        }
                    }
                }
                sortChatListAfterChanges();
            });

            subscriptionSendChatDetailsToClients?.unsubscribe();
            subscriptionSendChatDetailsToClients = webChatManager.onSendChatDetailsToClients.subscribe((chat: any) => {
                if (!isNewChat(chat)) {
                    updateExistingChat(chat);
                }
            });

            subscriptionOnTransferToQueueInitiated?.unsubscribe();
            subscriptionOnTransferToQueueInitiated = webChatManager.onTransferToQueueInitiated.subscribe((obj: any) => {
                const chat = agentWebChats.find(c => c.SessionRef === obj);
                endChatAfterTransfer(chat, agentWebChats.indexOf(chat));
            });

            subscriptionOnTransferToAgentInitiated?.unsubscribe();
            subscriptionOnTransferToAgentInitiated = webChatManager.onTransferToAgentInitiated.subscribe((obj: any) => {
                const chat = agentWebChats.find(c => c.SessionRef === obj.SessionRef);
                chat.ChatStatusType = ChatStatusType.TransferToAgentInviteSent;
                chat.TargetAgentSIP = obj.TargetAgent;
                updateExistingChat(chat);
                selectChat(chat);
            });

            subscriptionOnSendChatTransferInvitationAccepted?.unsubscribe();
            subscriptionOnSendChatTransferInvitationAccepted = webChatManager.onSendChatTransferInvitationAccepted.subscribe((obj: any) => {
                const chat = agentWebChats.find(c => c.SessionRef === obj);
                endChatAfterTransfer(chat, agentWebChats.indexOf(chat));
            });

            subscriptionOnSendChatTransferInvitationIgnored?.unsubscribe();
            subscriptionOnSendChatTransferInvitationIgnored = webChatManager.onSendChatTransferInvitationIgnored.subscribe((obj: any) => {
                if (obj) {
                    const chat = agentWebChats.find(c => c.SessionRef === obj);
                    chat.ChatStatusType = ChatStatusType.TransferToAgentInviteRejected;
                    const agentSip = Lockr.get<string>(StorageKeys.SIP);
                    chat.TargetAgentSIP = agentSip;
                    updateExistingChat(chat);
                    selectChat(chat);
                }
            });

            subscriptionOnEndChat?.unsubscribe();
            subscriptionOnEndChat = webChatManager.onEndChat.subscribe((obj: any) => {
                const chat = agentWebChats.find(c => c.SessionRef === obj);
                endChat(chat, agentWebChats.indexOf(chat));
            });
        }
    }

    const addNewChat = (chat: any) => {
        chat.IsHighlighted = true;
        agentWebChats.push(chat);
        getAgentChatSessions();
    }

    const updateExistingChat = (chat: any) => {
        for (const element of agentWebChats) {
            if (isCmMedia) {
                element.IsActive = true;
            }
            if (element.SessionRef === chat.SessionRef || (element.SessionRef === chat.SessionId)) {
                element.ChatStatusType = chat.ChatStatusType;
                if (!element.IsActive) {
                    element.IsHighlighted = true;
                }
            }
        }

        isChatClosedByClientBeforeHandled(chat);
        sortChatListAfterChanges();
    }

    const isChatClosedByClientBeforeHandled = (chat: any) => {
        if (chat.ChatStatusType === ChatStatusType.Terminated) {
            webChatManager.onAcceptRejectChat.next(chat.SessionId);
        }
    }

    const isNewChat = (chat: any) => {
        const newChat = agentWebChats.filter((x) => x.SessionRef === chat.SessionId);
        return newChat.length === 0;
    }

    const getAgentChatSessions = () => {
        const agentSip = Lockr.get<string>(StorageKeys.SIP);
        webChatManager.getAgentChatSessions(agentSip).then(function (data) {
            agentWebChats = data;

            mapLastMessagesToWebChats();
            agentWebChats.sort((a: any, b: any) => (a.LastMessageDate > b.LastMessageDate) ? -1 : 1);

            if (agentWebChats && agentWebChats.length) {
                manageChatHistory();

                selectFirstChatFromList(agentWebChats[0]);
            } else {
                localStorage.removeItem(StorageKeys.ChatHistory);
            }

            preselectChatIfReceivedNotification();

            setChatListItems();
        })
    }

    const preselectChatIfReceivedNotification = () => {
        if (activeWebchatsessionId && activeWebchatsessionId !== "") {
            const chat = agentWebChats.find(c => c.SessionRef === activeWebchatsessionId);
            selectChat(chat);
        }
    }

    const manageChatHistory = () => {
        const chatHistoryList: any[] = getChatHistory();

        if (chatHistoryList && chatHistoryList.length) {
            updateUnreadChats(chatHistoryList);
        } else {
            createChatHistory();
        }
    }

    const updateUnreadChats = (chatHistoryList: any) => {
        for (const element of agentWebChats) {

            if (!isWebChatIncomingState(element.ChatStatusType, element.TargetAgentSIP)) {
                const lastClickTimeList = chatHistoryList.filter((el: any) => el.chatId === element.SessionRef);

                if (lastClickTimeList && lastClickTimeList.length) {
                    const lastClickTime = lastClickTimeList[0].lastClickTime;
                    if (lastClickTime < formatTimeStamp(new Date(element.LastMessageDate))) {
                        element.IsHighlighted = true;
                    }
                }
            }
        }
    }

    const createChatHistory = () => {
        agentWebChats.forEach(chat => {
            addChatToChatHistory(chat);
        });
    }

    const selectFirstChatFromList = (chat: any) => {
        chat.IsActive = true;
        chat.IsHighlighted = false;

        updateChatClickTime(chat);

        const chatViewPropsObj: ChatViewProps = mapChatViewProps(agentWebChats[0]);
        webChatManager.onChatConversationChanged.next(chatViewPropsObj);
    }

    const statesThatPermitHoverAction = (status: any) => {
        return (status === ChatStatusType.Accepted
            || status === ChatStatusType.Terminated
            || status === ChatStatusType.Removed
            || status === ChatStatusType.TransferToAgentInviteAccepted
            || status === ChatStatusType.TransferToAgentInviteRejected);
    }

    const chatContainsCustomerName = (chat: ChatSessionDTO) => {
        return (chat.VisitorPreChatFormDictionary && chat.VisitorPreChatFormDictionary.CustomerName && chat.VisitorPreChatFormDictionary.CustomerName.trim());
    }

    const createChatIdentifier = (chat: ChatSessionDTO) => {
        if (chatContainsCustomerName(chat)) {
            const customerName = chat.VisitorPreChatFormDictionary.CustomerName.trim().replace(/\s\s+/g, ' ');
            return (customerName.length > 25 ? customerName.substring(0, 20) + '...' : customerName)
        }
        return formatTimeStamp(chat.StartDate);
    }

    const createChatInitials = (chat: ChatSessionDTO) => {
        if (chatContainsCustomerName(chat)) {
            return chat.VisitorPreChatFormDictionary.CustomerName.trim().replace(/\s\s+/g, ' ').split(' ').splice(0, 2).join(' ').toUpperCase();
        }
        return chat.QueueName.toUpperCase();
    }

    const setChatListItems = () => {
        listItemsArray = [];
        for (const agentChat of agentWebChats) {
            const chatIniatials = createChatInitials(agentChat as ChatSessionDTO);
            let classNameString = "chat-list-item";
            classNameString = classNameString + (agentChat.IsActive ? " active" : "");
            classNameString = classNameString + (agentChat.IsHighlighted ? " highlighted" : "");

            const itemContent = getChatItemContent(agentChat);


            listItemsArray.push(
                {
                    key: agentChat.SessionRef,
                    media: <Avatar name={chatIniatials} />,
                    content: itemContent,
                    onClick: () => { selectChat(agentChat) },
                    className: classNameString,
                    styles: ({ theme: { siteVariables } }: any) => ({
                        height: "60px",
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: siteVariables.colorScheme.brand.foreground3,
                            color: siteVariables.colorScheme.default.shadow,
                        },
                        "&.active": {
                            backgroundColor: siteVariables.colorScheme.brand.borderActive2,
                            color: siteVariables.colorScheme.default.foregroundPressed,
                            "&:hover": { backgroundColor: siteVariables.colorScheme.brand.foreground3 }
                        },
                        "&.highlighted": {
                            "&:hover": { backgroundColor: siteVariables.colorScheme.brand.foreground3 }
                        }
                    })
                }
            );
        }
        nrOfChats = listItemsArray.length;
        setListItems(listItemsArray);
    }

    const getChatItemContent = (agentChat: ChatSessionDTO) => {
        const chatIdentifier = createChatIdentifier(agentChat);
        const banIconClass = statesThatPermitHoverAction(agentChat.ChatStatusType) ? "chat-hover" : "";

        return <div>
            <Flex space="between" styles={{ margin: "2px 0px" }} >
                <Text content={chatIdentifier} weight="semibold" />

                <Flex vAlign="center">
                    <Text className={statesThatPermitHoverAction(agentChat.ChatStatusType) ? "chat-hover" : ""}
                        content={formatLastMessageDate(new Date(agentChat.LastMessageDate ? agentChat.LastMessageDate : ''))}
                        weight="semibold"
                    />
                    <MenuButton
                        offset={() => [0, 10]}
                        trigger={<Button className={statesThatPermitHoverAction(agentChat.ChatStatusType) ? "chat-options" : "chat-options-disabled"}
                            text
                            iconOnly
                            icon={<MoreIcon />}
                            styles={{ height: "0" }}
                            title={intl.formatMessage({ id: 'ChatListView.MoreOptions' })}
                        />}
                        menu={[
                            {
                                content: < Flex gap="gap.small" vAlign="center" >
                                    <CloseIcon size="small" />
                                    <Text content={intl.formatMessage({ id: 'ChatListView.EndChat' })} />
                                </Flex>,
                                onClick: (e: any) => endChat(agentChat, agentWebChats.indexOf(agentChat), e),
                                key: intl.formatMessage({ id: 'ChatListView.EndChat' })
                            }
                        ]}
                        on="click"
                        align="end"
                    />
                </Flex>
            </Flex>
            <div>
                <Flex space="between" >
                    {!trasferHasPrivateMessage(agentChat) ? displayChatLastMessage(agentChat.LastMessage) :
                        returnTransferMessagePopup(agentChat.LastMessage, agentChat.ChatStatusType)
                    }
                    <span>&nbsp;</span>
                    {isWebChatIncomingState(agentChat.ChatStatusType, agentChat.TargetAgentSIP) ?
                        <Flex gap="gap.small" >
                            <Button size="small" circular
                                styles={({ theme: { siteVariables } }) => ({
                                    border: "0px",
                                    backgroundColor: siteVariables.colorScheme.brand.foreground,
                                    color: siteVariables.colorScheme.default.background
                                })}
                                icon={<AcceptIcon size="medium" title={intl.formatMessage({ id: 'ChatListView.AcceptChat' })} />}
                                onClick={() => acceptChat(agentChat, agentWebChats.indexOf(agentChat))}
                            />
                            <Button size="small" circular
                                styles={({ theme: { siteVariables } }) => ({
                                    border: "0px",
                                    backgroundColor: siteVariables.colorScheme.red.foreground,
                                    color: siteVariables.colorScheme.default.background
                                })}
                                icon={<CloseIcon size="medium" title={intl.formatMessage({ id: 'ChatListView.RejectChat' })} />}
                                onClick={() => rejectChat(agentChat, agentWebChats.indexOf(agentChat))}
                            />
                        </Flex> : null}
                    {agentChat.ChatStatusType === ChatStatusType.Removed || agentChat.ChatStatusType === ChatStatusType.Terminated
                        || agentChat.ChatStatusType === ChatStatusType.Abondoned ?
                        <BanIcon
                            className={banIconClass}
                            outline
                            styles={({ theme: { siteVariables } }) => ({
                                padding: "0 5px",
                                color: siteVariables.colorScheme.red.foreground
                            })}
                            size="large" />
                        : null}
                </Flex>
            </div>
        </div>
    }

    const isWebChatIncomingState = (status: any, targetAgent: any) => {
        const agentSip = Lockr.get<string>(StorageKeys.SIP);
        return (status === ChatStatusType.Incoming
            || status === ChatStatusType.Ringing
            || status === ChatStatusType.Ignored
            || status === ChatStatusType.TransferedToQueue
            || (status === ChatStatusType.TransferToAgentInviteSent && targetAgent === agentSip));
    }

    const displayChatLastMessage = (message: any) => {
        if (message) {
            return (message.length > 25 ? message.substring(0, 25) + '...' : message)
        }
        return "";
    }

    const returnTransferMessagePopup = (message: any, status: any) => {
        return (
            <Popup
                trigger={<span>{displayChatLastMessage(message)}</span>}
                on="hover"
                mouseLeaveDelay={200}
                pointing
                position="above"
                align="start"
                content={<Text
                    content={message}
                    className="transfer-message"
                />}
            />
        );
    }

    const trasferHasPrivateMessage = (chat: any) => {
        return (chat.ChatStatusType === ChatStatusType.TransferToAgentInviteSent
            && chat.ChatHistories[chat.ChatHistories.length - 1]?.Source === ChatMessageSource.Agent
            && chat.ChatHistories[chat.ChatHistories.length - 1]?.Type === ChatMessageType.PrivateMessage);
    }

    const sortChatListAfterChanges = () => {
        agentWebChats.sort((a: any, b: any) => (a.LastMessageDate > b.LastMessageDate) ? -1 : 1);
        setChatListItems();
        setChatListKey(Math.random() + "chat-list");
    }

    const selectChat = (chat: any) => {
        if (chat) {
            for (const element of agentWebChats) {
                if (element.SessionRef === chat.SessionRef) {
                    element.IsHighlighted = false;
                    element.IsActive = true;
                } else {
                    element.IsActive = false;
                }
            }

            updateChatClickTime(chat);

            const chatViewPropsObj: ChatViewProps = mapChatViewProps(chat);
            webChatManager.onChatConversationChanged.next(chatViewPropsObj);
            setChatListItems();
        }
    }

    const updateChatClickTime = (chat: any) => {
        let isChatFound: boolean = false;
        const chatHistoryList: any[] = getChatHistory();

        chatHistoryList.forEach(element => {
            if (element.chatId === chat.SessionRef) {
                isChatFound = true;
                element.lastClickTime = Date.now();
            }
        });

        if (!isChatFound && (chat.ChatStatusType === ChatStatusType.Accepted || chat.ChatStatusType === ChatStatusType.TransferToAgentInviteAccepted)) {
            const chatHistory = { chatId: chat.SessionRef, lastClickTime: Date.now() };
            chatHistoryList.push(chatHistory);
        }

        localStorage.setItem(StorageKeys.ChatHistory, JSON.stringify(chatHistoryList));
    }

    const acceptChat = (chat: any, index: any) => {
        const agentSip = Lockr.get<string>(StorageKeys.SIP);

        webChatManager.acceptChatSession(chat.SessionRef, agentSip);
        agentWebChats[index].ChatStatusType = ChatStatusType.Accepted;
        agentWebChats[index].IsHighlighted = false;
        sortChatListAfterChanges();
        webChatManager.onAcceptRejectChat.next(chat.SessionRef);

        addChatToChatHistory(chat);
    }

    const rejectChat = (chat: any, index: any) => {
        const agentSip = Lockr.get<string>(StorageKeys.SIP);

        webChatManager.ignoreChatSession(chat.SessionRef, agentSip);
        agentWebChats.splice(agentWebChats.indexOf(agentWebChats[index]), 1);
        sortChatListAfterChanges();
        webChatManager.onAcceptRejectChat.next(chat.SessionRef);
    }

    const endChat = (chat: any, index: any, e?: React.SyntheticEvent<HTMLButtonElement>) => {
        if (e) {
            e.stopPropagation();
        }

        if (isCmMedia && chat) {
            const request = GeneralHelper.getSocialMediaRequestDto(chat.SessionRef);
            socketSocialMedia.SocialMediaTerminate(request);
            mainViewHelper.viewChanged.next(ActiveMainViewItem.MediaView);

            return;
        }

        if (chat) {
            const agentSip = Lockr.get<string>(StorageKeys.SIP);

            webChatManager.endChatSession(chat.SessionRef, agentSip);
            agentWebChats.splice(agentWebChats.indexOf(agentWebChats[index]), 1);

            webChatManager.onChatEnded.next(chat.SessionRef);
            webChatManager.onChatConversationChanged.next(null);

            sortChatListAfterChanges();

            if (agentWebChats.length !== 0) {
                selectChat(agentWebChats[0]);
            }

            removeChatFromChatHistory(chat);
        }
    }

    const endChatAfterTransfer = (chat: any, index: any) => {
        if (chat) {
            agentWebChats.splice(agentWebChats.indexOf(agentWebChats[index]), 1);

            webChatManager.onChatEnded.next(chat.SessionRef);
            webChatManager.onChatConversationChanged.next(null);

            sortChatListAfterChanges();

            if (agentWebChats.length !== 0) {
                selectChat(agentWebChats[0]);
            }

            removeChatFromChatHistory(chat);
        }
    }

    const addChatToChatHistory = (chat: any) => {
        const chatHistoryList: any[] = getChatHistory();

        const chatIsAlreadySaved = chatHistoryList.filter((obj: any) => {
            return obj.chatId === chat.SessionRef;
        });

        if (chatIsAlreadySaved && chatIsAlreadySaved.length) {
            return;
        }

        const chatHistory = { chatId: chat.SessionRef, lastClickTime: Date.now() };
        chatHistoryList.push(chatHistory);
        localStorage.setItem(StorageKeys.ChatHistory, JSON.stringify(chatHistoryList));
    }

    const removeChatFromChatHistory = (chat: any) => {
        let chatHistoryList: any[] = getChatHistory();

        chatHistoryList = chatHistoryList.filter((obj: any) => {
            return obj.chatId !== chat.SessionRef;
        });
        localStorage.setItem(StorageKeys.ChatHistory, JSON.stringify(chatHistoryList));
    }

    const getChatHistory = () => {
        let chatHistoryList: any[] = [];
        const chatHistoryLocalStorage = JSON.parse(localStorage.getItem(StorageKeys.ChatHistory) || "[]");
        if (chatHistoryLocalStorage) {
            chatHistoryList = chatHistoryLocalStorage;
        }
        return chatHistoryList;
    }

    const mapChatViewProps = (chat: any) => {
        const chatViewPropsObj: ChatViewProps = new ChatViewProps();

        chatViewPropsObj.SessionRef = chat.SessionRef;
        chatViewPropsObj.ChatStatusType = chat.ChatStatusType;
        chatViewPropsObj.ClientUniqueId = formatTimeStamp(chat.StartDate) + '';
        chatViewPropsObj.WebChatName = chat.QueueName.toUpperCase();

        chatViewPropsObj.CustomerName = (chatContainsCustomerName(chat) as ChatSessionDTO) ? chat.VisitorPreChatFormDictionary.CustomerName : '';

        return chatViewPropsObj;
    }

    const formatTimeStamp = (startDate: any) => {
        return new Date(startDate).getTime();
    }

    const mapLastMessagesToWebChats = () => {
        if (agentWebChats && agentWebChats.length) {
            for (const element of agentWebChats) {
                if (element.ChatHistories && element.ChatHistories.length) {
                    element.LastMessageDate = new Date(element.ChatHistories[element.ChatHistories.length - 1].Date);
                    element.LastMessage = element.ChatHistories[element.ChatHistories.length - 1].Message;
                } else {
                    element.LastMessageDate = new Date(element.StartDate);
                    element.LastMessage = '';
                }
            }
        }
    }

    const formatLastMessageDate = (date: Date) => {
        const currentDate = new Date();
        const currentDateMidnight = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0);

        if (date < currentDateMidnight) {
            return `${(date.getMonth() + 1)}/${date.getDate()}`;
        } else {
            return GeneralHelper.formatAMPM(date);
        }
    }

    const getCmMediaConversationHistory = () => {
        socketSocialMedia.GetActiveCmMediaConversationHistory(activeWebchatsessionId).then((cmMessages: CMMediaSessionMessageDTO[]) => {
            agentWebChats = GeneralHelper.mapCmMessagesToChatSessionDTO(cmMessages, activeWebchatsessionId);
            mapLastMessagesToWebChats();
            selectChat(agentWebChats[0]);
        });
    }

    const initialize = () => {
        GeneralHelper.logCox(`in ChatListView.tsx, in initialize`);

        if (isCmMedia) {
            getCmMediaConversationHistory();
        } else {
            getAgentChatSessions();
        }

        initListeners();
    }

    React.useEffect(() => {
        initialize();
        return () => componentWillUnmount();
    }, []);

    const componentWillUnmount = () => {
        GeneralHelper.logCox(`in ChatListView.tsx, in componentWillUnmount`);

        subscriptionOnMessageSentFromAgent?.unsubscribe();
        subscriptionSendCmMessageReceivedFromCustomer?.unsubscribe();
        subscriptionNewWebChat?.unsubscribe();
        subscriptionMessageReceivedFromCustomer?.unsubscribe();
        subscriptionSendChatDetailsToClients?.unsubscribe();
        subscriptionOnTransferToQueueInitiated?.unsubscribe();
        subscriptionOnTransferToAgentInitiated?.unsubscribe();
        subscriptionOnSendChatTransferInvitationAccepted?.unsubscribe();
        subscriptionOnSendChatTransferInvitationIgnored?.unsubscribe();
        subscriptionOnEndChat?.unsubscribe();
        if (nrOfChats > 0) {
            webChatManager.onChatConversationChanged.next(null);
        }
    }

    return (<Flex column>
        <Text content={intl.formatMessage({ id: 'ChatListView.ChatSessions' })} weight="bold" className="chat-sessions-text" />
        <br />
        <List className="chat" key={chatListKey} items={listItems} />
    </Flex>
    )
};

export default ChatListView;

import { Flex } from "@fluentui/react-northstar";
import React from "react";
import ExtendedNotificationTabs from "./VoiceMailsTabs/ExtendedNotificationTabs";

export const ExtendedNotificationView = () => {

    return (
        <Flex.Item>
            <Flex column
                styles={({ theme: { siteVariables } }) => ({
                    backgroundColor: siteVariables.colorScheme.default.background2,
                    color: siteVariables.colorScheme.default.foreground2,
                })}
            >
                <ExtendedNotificationTabs key="extended-notification-tabs-container-key" />
            </Flex>
        </Flex.Item>
    );
};
export default ExtendedNotificationView;
import React from "react";
import { Status, AcceptIcon, WindowMinimizeIcon, MenuItemBehaviorProps } from "@fluentui/react-northstar";
import { PresenceState } from "utils/domain/presence";

type ChangePresenceAction = (presenceState: PresenceState, presenceText: string) => void;

export class PresenceViewBuilder {
    statusElements = new Map<string, JSX.Element>([
        [PresenceState.Online, <Status state="success" icon={<AcceptIcon />} className="presence-status" />],
        [PresenceState.Available, <Status state="success" icon={<AcceptIcon />} className="presence-status" />],
        [PresenceState.AvailableIdle, <Status state="success" icon={<AcceptIcon />} className="presence-status" />],
        [PresenceState.Busy, <Status state="error" className="presence-status" />],
        [PresenceState.BusyIdle, <Status state="error" className="presence-status" />],
        [PresenceState.DND, <Status state="error" icon={<WindowMinimizeIcon />} className="presence-status" />],
        [PresenceState.BeRightBack, <Status state="warning" icon={<img alt='Away Icon' />} className="presence-status be-right-back" />],
        [PresenceState.Away, <Status state="warning" icon={<img alt='Away Icon' />} className="presence-status away" />],
        [PresenceState.Offline, <Status state="unknown" className="presence-status" />],
        [PresenceState.PresenceUnknown, <Status state="unknown" className="presence-status" />]
    ]);

    presenceElement = (status: JSX.Element, presence: string) => {
        return (
            <span className="presence-item">
                {status}
                <span className="presence-text-size" title={presence}>{presence}</span>
            </span>);
    }

    genericPresenceElement = (presence: string, onClick: ChangePresenceAction) => {
        return (
            <div
                className="presence-item"
                onClick={() => onClick(PresenceState.Busy, presence || "")}
            >
                <Status state="error" className="presence-status" />
                <span className="presence-text-size" title={presence}>{presence}</span>
            </div>);
    }
    
    getDividerAccesibilityAttributes = (_: MenuItemBehaviorProps) => ({
        attributes: {
            'wrapper': {
                'aria-hidden': true,
                disabled: true
            }
        }
    });
}

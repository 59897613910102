export default class ValidationHelper {
    //old regex /^(sip:)?\w*([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/ causing 'catastrophic backtracking' for long sip addresses
    private static readonly sipValidator: RegExp = /^(sip:)?[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    private static readonly phoneNumberValidator: RegExp = /^\+?([0-9]+)$/;
    private static readonly stripPhoneNumberExp: RegExp = /[^0-9+]/g;//only digits and + sign accepted

    private static readonly toneKeyValidator: RegExp = /^([0-9*#]+)$/;
    private static readonly sipOrNumberInSipFormat: RegExp = /^(sip:)?([+]?\w+)\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    private static readonly email: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    private static readonly timeSpan: RegExp = /^(23|([0-5][0-9])):(59|([0-5][0-9])):(59|([0-5][0-9]))$/;

    private static readonly sip: string = "sip:";

    public static isValidSip(sip: string) {
        return this.sipValidator.test(sip);
    }

    public static isValidPhoneNumber(number: string) {
        return this.phoneNumberValidator.test(number);
    }

    public static isValidToneKeyNumber(number: string) {
        return this.toneKeyValidator.test(number);
    }

    public static isSipOrPhoneNumberInSipFormat(input: string) {
        return this.sipOrNumberInSipFormat.test(input);
    }

    public static isValidTimeSpan(input: string) {
        return this.timeSpan.test(input);
    }

    private static containsSip(input: string) {
        return this.isValidSip(input) && input.includes(this.sip);
    }

    public static isValidInput(input: string) {
        return this.isValidSip(input) || this.isValidPhoneNumber(input);
    }

    public static appendSipIfInputDoesNotContainsIt(input: string) {
        return !this.containsSip(input) && this.isValidSip(input)
            ? this.sip.concat(input)
            : input;
    }

    public static removeSipIfInputContainsIt(input: string) {
        return this.containsSip(input) && this.isValidSip(input)
            ? input.replace(this.sip, '')
            : input;
    }

    public static stripPhoneNumber(input: string | undefined | null): string {
        let startsWithPlus = false;
        if (!input) {
            return '';
        }

        let strippedPhone = input.replace(this.stripPhoneNumberExp, '');

        if (strippedPhone && strippedPhone[0] === '+') {
            startsWithPlus = true;
        }

        strippedPhone = strippedPhone.replaceAll('+', '');
        strippedPhone = startsWithPlus ? '+' + strippedPhone : strippedPhone;

        return strippedPhone;
    }

    public static isValidEmail(input: string): boolean {
        return this.email.test(input);
    }
}
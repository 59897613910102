import React, { useState, useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import { socketWrapUp } from 'services/wrapUp';
import { Flex, Dropdown, Box } from '@fluentui/react-northstar';
import { socketPresence } from 'services/presence';
import { serviceRequests } from 'services/io';
import { socketAuth } from 'services';
import { WrapStatus, StorageKeys } from 'utils';
import './WrapUpView.scss';
import Lockr from 'lockr';
import { WrapUpSettings } from 'utils/domain/wrapUpSettings';
import { Presence, PresenceAndNoteDTO } from 'utils/domain/presence';
import { EnvHelper, GeneralHelper } from 'utils/helpers';
import Timer from 'components/Timer';

function getApiPresenceForContactCenterPresence(ccPresence: number) {
  const presenceMapItem = presenceMap.find(p => p.ccValue === ccPresence);

  if (presenceMapItem) {
    return presenceMapItem.apiValue;
  } else {
    return null;
  }
}

const presenceMap = [
  { ccValue: 3000, apiValue: 'Online' },
  { ccValue: 4500, apiValue: 'Online' },
  { ccValue: 6000, apiValue: 'Busy' },
  { ccValue: 7500, apiValue: 'Busy' },
  { ccValue: 9000, apiValue: 'DoNotDisturb' },
  { ccValue: 12000, apiValue: 'Away' },
  { ccValue: 15000, apiValue: 'Away' },
  { ccValue: 18000, apiValue: 'Offline' },
];

export const WrapUpView = () => {
  const [wrapUpCodes, setWrapUpCodes] = useState(undefined as any);

  const [wrapUpSettingsState, setWrapUpSettings] = useState(undefined as WrapUpSettings | undefined);
  const wrapUpSettingsRef = useRef(undefined as WrapUpSettings | undefined);
  const wrapUpStopTimeout = useRef(undefined as NodeJS.Timeout | undefined);

  const previousPresence = useRef('');
  const previousNote = useRef('');

  const wrapUpTopicsRef = useRef([]);
  const [wrapUpTopicsState, setWrapUpTopicsState] = useState(undefined as any);
  const wrapUpStatusRef = useRef(WrapStatus.Ended);
  const currentTopicRef = useRef('-1');
  const [currentTopicState, setCurrentTopicState] = useState(currentTopicRef.current);


  let subscriptionSocketAuthUserVerified: Subscription | null = null;
  let subscriptionSocketPresenceSelect: Subscription | null = null;
  let subscriptionInitializeWrapUp: Subscription | null = null;
  let subscriptionStopWrapUp: Subscription | null = null;
  let subscriptionPreviousPresenceChangeNotification: Subscription | null = null;

  const componentWillUnmount = () => {
    subscriptionSocketAuthUserVerified?.unsubscribe();
    subscriptionSocketPresenceSelect?.unsubscribe();
    subscriptionInitializeWrapUp?.unsubscribe();
    subscriptionStopWrapUp?.unsubscribe();
    subscriptionPreviousPresenceChangeNotification?.unsubscribe();
  }

  React.useEffect(() => {
    initialize();
    return () => {
      componentWillUnmount();
    }
  }, []);

  //just for debugging use: React.useEffect(() => { GeneralHelper.logCox(`CC4ALL-3579 in useEffect currentTopicState is ${currentTopicState}`)  }, [currentTopicState]);

  const initiateStopWrapUp = (fromServerSide: boolean) => {
    wrapUpStatusRef.current = fromServerSide ? WrapStatus.EndedFromTheServerSide : WrapStatus.Ended;
    const presence = {
      Availability: previousPresence.current ?? getApiPresenceForContactCenterPresence(3000),
      Activity: previousNote.current ?? ''
    } as Presence;
    GeneralHelper.logCox(`in WrapUpView, in initiateStopWrapUp, calling socketPresence.wrapUp.next(false), setting presence to ${JSON.stringify(presence)}, \
having !!wrapUpSettingsRef.current ${!!wrapUpSettingsRef.current}, having fromServerSide ${fromServerSide}`);
    socketPresence.select.next(presence);
    socketPresence.wrapUp.next(false);
  }

  const saveWrapUpHistory = () => {
    GeneralHelper.logCox(`in WrapUpView, in saveWrapUpHistory, wrapUpSettingsRef.current is ${JSON.stringify(wrapUpSettingsRef.current)}`);
    if (!wrapUpSettingsRef.current) {
      return;
    }
    const topicRef = currentTopicRef.current !== '-1' ? wrapUpTopicsRef.current.filter((ref: any) => ref.Name === currentTopicRef.current).map((x: any) => x.ID)[0] : -1;
    serviceRequests.SaveWrapUpHistory({
      AgentRef: wrapUpSettingsRef.current.AgentRef,
      QueueRef: wrapUpSettingsRef.current.QueueRef,
      Source: 1,
      SessionId: wrapUpSettingsRef.current.SessionId,
      TopicRef: topicRef,
      RecordDate: wrapUpSettingsRef.current.RecordDate
    });
    wrapUpSettingsRef.current = undefined;
    setWrapUpSettings(undefined);
    if (wrapUpStopTimeout.current) {
      GeneralHelper.logCox(`in WrapUpView, in saveWrapUpHistory, also clearing the timeout ${wrapUpStopTimeout.current}`);
      clearTimeout(wrapUpStopTimeout.current);
      wrapUpStopTimeout.current = undefined;
    }
  }

  const initializeAfterGettingAllWrapUpCollectionTopicMap = () => {
    const sip = Lockr.get<string>(StorageKeys.SIP);
    // REMOVE only if when GetPreviousPresenceAndNote is implemented in Stage3
    if (!EnvHelper.isStage3()) {
      socketWrapUp.GetPreviousPresenceAndNote(sip).then(result => {
        GeneralHelper.logCox(`in WrapUpView, previousPresence.current set, in GetPreviousPresenceAndNote, result being ${JSON.stringify(result)}`);
        previousPresence.current = result.Availability ? result.Availability : "";
        previousNote.current = result.Activity ? result.Activity : "";
      });
    }
    socketWrapUp.IsAgentInWrapUp(sip).then(isInWrapUp => {
      if (isInWrapUp) {
        return socketWrapUp.GetOngoingWrapUpSettings(sip);
      }
      return undefined;
    }).then((result?: WrapUpSettings) => {
      if (result) {
        const typedWrapUpSettings = new WrapUpSettings(result);
        GeneralHelper.logCox(`in WrapUpView, after GetOngoingWrapUpSettings, for wrapUpSettingsRef.current, typedWrapUpSettings is ${JSON.stringify(typedWrapUpSettings)}`);
        setWrapUpSettings(typedWrapUpSettings);
        wrapUpSettingsRef.current = typedWrapUpSettings;
        GeneralHelper.logCox(`in WrapUpView, initialize, after IsAgentInWrapUp, no longer setting isWrapUpExist.current to true`);
        socketPresence.wrapUp.next(true);
      } else {
        GeneralHelper.logCox(`in WrapUpView, after GetOngoingWrapUpSettings, for wrapUpSettingsRef.current, typedWrapUpSettings is not set`);
      }
    });
  }

  const initializeAfterSocketAuthUserVerified = () => {
    GeneralHelper.logCox(`in WrapUpView, will call GetAllWrapUpCollectionTopicMap`);
    socketWrapUp.GetAllWrapUpCollectionTopicMap().then(result => {
      GeneralHelper.logCox(`in WrapUpView, calling setWrapUpCodes, in initialize, after GetAllWrapUpCollectionTopicMap`);
      setWrapUpCodes(result);
      initializeAfterGettingAllWrapUpCollectionTopicMap();
    });
  }

  const initialize = () => {
    GeneralHelper.logCox(`in WrapUpView, in initialize`);
    subscriptionSocketAuthUserVerified?.unsubscribe();
    subscriptionSocketAuthUserVerified = socketAuth.userVerified.subscribe((obj: any) => {
      initializeAfterSocketAuthUserVerified();
    });

    subscriptionSocketPresenceSelect?.unsubscribe();
    subscriptionSocketPresenceSelect = socketPresence.select.subscribe((result: Presence) => {
      GeneralHelper.logCox(`in WrapUpView, socketPresence.select, having result ${JSON.stringify(result)}`)
      if (!wrapUpSettingsRef.current) {
        GeneralHelper.logCox(`in WrapUpView, socketPresence.select, will return, as we don't have anything in wrapUpSettingsRef.current`);
        return;
      }
      const wrapUpPresenceAndNoteDto = {
        Availability: getApiPresenceForContactCenterPresence(wrapUpSettingsRef.current?.Presence ?? 0) ?? '',
        Activity: wrapUpSettingsRef.current?.Reason ?? ''
      } as Presence;

      GeneralHelper.logCox(`in WrapUpView, socketPresence.select, having wrapUpPresenceAndNoteDto ${JSON.stringify(wrapUpPresenceAndNoteDto)} \
      and wrapUpStatusRef.current ${wrapUpStatusRef.current}`)
      const hasADifferentPresence = (result.Availability !== wrapUpPresenceAndNoteDto.Availability) ||
        (result.Activity !== wrapUpPresenceAndNoteDto.Activity) ||
        (result.Activity === 'Ringing');
      if ((hasADifferentPresence && result.Sip === undefined) || (wrapUpStatusRef.current !== WrapStatus.Started)) {
        saveWrapUpHistory();
        if (wrapUpStatusRef.current !== WrapStatus.EndedFromTheServerSide) {
          serviceRequests.CancelWrapUp(Lockr.get<string>(StorageKeys.SIP), result.Availability, result.Activity ?? '');
        }
      } else {
        GeneralHelper.logCox(`in WrapUpView, socketPresence.select, presence is for wrapUp, so not calling stop`);
      }
    });

    subscriptionInitializeWrapUp?.unsubscribe();
    subscriptionInitializeWrapUp = socketWrapUp.listenerInitializeWrapUp.received.subscribe((obj: WrapUpSettings) => {
      const typedWrapUpSettings = new WrapUpSettings(obj);
      GeneralHelper.logCox(`in WrapUpView, in InitializeWrapUp, for wrapUpSettingsRef.current, typedWrapUpSettings is ${JSON.stringify(typedWrapUpSettings)}`);
      setWrapUpSettings(typedWrapUpSettings);
      wrapUpSettingsRef.current = typedWrapUpSettings;
      socketPresence.wrapUp.next(true);
    });

    subscriptionStopWrapUp?.unsubscribe();
    subscriptionStopWrapUp = socketWrapUp.listenerStopWrapUp.received.subscribe((obj: PresenceAndNoteDTO) => {
      GeneralHelper.logCox(`in WrapUpView, in listenerStopWrapUp, obj being ${JSON.stringify(obj)}`);
      previousPresence.current = obj.Availability ?? "";
      previousNote.current = obj.Activity ?? "";
      GeneralHelper.logCox(`in WrapUpView, in listenerStopWrapUp, calling socketPresence.wrapup.next(false)`);
      socketPresence.wrapUp.next(false);
      initiateStopWrapUp(true);
    });

    subscriptionPreviousPresenceChangeNotification?.unsubscribe();
    subscriptionPreviousPresenceChangeNotification = socketWrapUp.previousPresenceChangeNotification.subscribe((result: PresenceAndNoteDTO) => {
      GeneralHelper.logCox(`in WrapUpView, previousPresence.current set, in previousPresenceChangeNotification, result being ${JSON.stringify(result)}`);
      previousPresence.current = result.Availability ? result.Availability : "";
      previousNote.current = result.Activity ? result.Activity : "";
    });

  }

  useEffect(() => {
    GeneralHelper.logCox(`CC4ALL-3579 in useEffect for wrapUpSettingsState, wrapUpSettingsState is ${JSON.stringify(wrapUpSettingsState || {})}`);

    if (wrapUpSettingsState) {
      const wrapUpCollection = wrapUpCodes.find((x: any) => x.WrapUpCollectionRef === wrapUpSettingsState.WrapupCollectionRef);

      if (wrapUpCollection) {
        wrapUpTopicsRef.current = wrapUpCollection.WrapUpTopicList;
        setWrapUpTopicsState(wrapUpCollection.WrapUpTopicList);
        if (wrapUpSettingsState.WrapupTopicRef) {
          currentTopicRef.current = wrapUpTopicsRef.current.filter((x: any) => x.ID === wrapUpSettingsState.WrapupTopicRef).map((ref: any) => ref.Name)[0];
          setCurrentTopicState(currentTopicRef.current);
          GeneralHelper.logCox(`CC4ALL-3579 in useEffect for wrapUpSettingsState, after setCurrentTopicState to ${currentTopicState}`);
        }
      }
      GeneralHelper.logCox(`in WrapUpView, useEffect on wrapUpSettings`);
    }

    GeneralHelper.logCox(`in WrapUpView, in useEffect for wrapUpSettings: ${JSON.stringify(wrapUpSettingsState)}`);
    if (wrapUpSettingsState && wrapUpSettingsState?.WrapUpTimeInterval && wrapUpSettingsState?.EndDate) {
      GeneralHelper.logCox(`in WrapUpView, in useEffect, having wrapUp`);
      const serverClientTimeDifference = (Lockr.get<number>(StorageKeys.ServerClientTimeDifference) || 0);
      const timeoutInterval = wrapUpSettingsState.EndDate.getTime() - Date.now() - serverClientTimeDifference;
      GeneralHelper.logCox(`in WrapUpView, in useEffect for wrapUpSettings, having timeoutInterval ${timeoutInterval}`);
      if (timeoutInterval >= 0) {
        wrapUpStatusRef.current = WrapStatus.Started;

        const wrapUpPresenceAndNoteDto = {
          Availability: getApiPresenceForContactCenterPresence(wrapUpSettingsState.Presence) ?? '',
          Activity: wrapUpSettingsState.Reason
        } as Presence;
        socketPresence.select.next(wrapUpPresenceAndNoteDto);
        wrapUpStopTimeout.current = setTimeout(() => {
          GeneralHelper.logCox(`in WrapUpView, in useEffect for wrapUpSettings, in timeout, about to do initiateStopWrapUp`);
          initiateStopWrapUp(false);
        }, timeoutInterval);
      }
    }
    else {
      GeneralHelper.logCox(`in WrapUpView, in useEffect for wrapUpSettings, calling socketPresence.wrapup.next(false), not having wrapUp`);
      socketPresence.wrapUp.next(false);
    }
  }, [wrapUpSettingsState]);

  if (wrapUpSettingsState) {
    let topicDropdown;
    if (wrapUpTopicsState) {
      GeneralHelper.logCox(`CC4ALL-3579 before topicDropdown assigning currentTopicRef.current is ${currentTopicRef.current}`);
      topicDropdown = <Box styles={{ width: '240px' }}>
        <Dropdown inverted className="wrapup-dropdown"
          items={wrapUpTopicsRef.current.map((key: any, index: number) => {
            return key.Name;
          })}
          onChange={(e, selectedOption) => {
            if (selectedOption) {
              currentTopicRef.current = selectedOption.value?.toString() || '-1';
              setCurrentTopicState(currentTopicRef.current);
            }
          }}
          placeholder={currentTopicState !== "-1" ? currentTopicState : ""}
          fluid
        />
      </Box>
    }
    return <Flex vAlign="center" hAlign="end" gap="gap.medium"
      styles={({ theme: { siteVariables } }) => ({
        backgroundColor: siteVariables.colorScheme.default.backgroundPressed,
        color: siteVariables.colorScheme.default.foregroundPressed,
        padding: '0px 0px 0px 30px'
      })}>
      <Box content="Wrap-up" />
      {wrapUpTopicsState?.length ? topicDropdown : null}
      {wrapUpSettingsState?.WrapupTimerVisibility && wrapUpSettingsState?.EndDate ? <Timer startDate={wrapUpSettingsState?.EndDate} isEndDate={true} /> : null}
    </Flex>
  }
  else {
    return <div></div>
  }
};

export default WrapUpView;

import React, { useState, useRef, useEffect } from 'react';
import { Input, Grid, Button, CallIcon, Dropdown, CloseIcon } from '@fluentui/react-northstar';
import { useIntl } from 'react-intl';
import { socketOutbound } from '../../services/outbound';
import './dialpadView.scss'
import DialpadButton from '../../containers/DialpadView/DialpadButton';
import { CampaingCallItemDto, TrustedEndpointDTO, SendToneDto, OutgoingCallDto } from '../../utils/domain/outbound';
import { StorageKeys, ConferenceStatusAction, MonitorTypes, logging } from '../../utils';
import Lockr from 'lockr';
import { socketAudio } from '../../services/calling';
import { CallSessionDTO } from '../../utils/domain/callSessionDTO';
import { EnvHelper, GeneralHelper, ValidationHelper } from '../../utils/helpers';
import { socketCallHistory } from '../../services/callHistory';
import { Subscription } from 'rxjs';
import { socketQueue } from 'services/queue';
import { socketPresence } from 'services/presence';
import { PresenceState } from 'utils/domain/presence';
import useKeyCombination from 'utils/domain/hooks/useKeyCombination';
import ModifierKeys from 'utils/domain/hooks/ModifierKeys';
import PlaySoundHelper, { SoundType } from 'utils/helpers/play-sound-helper';

type Props = {
    isPhoneNumberInputFocused: boolean;
    isEndpointDropdownOpenDefault: boolean;
}

export const DialpadView = ({ isPhoneNumberInputFocused, isEndpointDropdownOpenDefault }: Props) => {

    const intl = useIntl();

    const [sipOrPhoneNumber, setPhoneNumber] = useState('');
    const sipOrPhoneNumberRef = useRef('');
    const skipInputDialpadChangeEvent = useRef(false);
    const [trustedEndpoints, setTrustedEndpoints] = useState([] as TrustedEndpointDTO[]);
    const trustedEndpointsRef = useRef(trustedEndpoints);
    const [currentEndpoint, setCurrentEndpoint] = useState({} as TrustedEndpointDTO);
    const currentEndpointRef = useRef({} as TrustedEndpointDTO);
    const [disabledDialpad, setDisabledDialpad] = useState(true);
    const [isInACall, setIsInACall] = useState(false);
    const isInACallRef = useRef(false);
    const [isInCallMonitoring, setIsInCallMonitoring] = useState(false);
    const isInCallMonitoringRef = useRef(false);
    const [dropdownPlaceholder, setDropdownPlaceholder] = useState(intl.formatMessage({ id: "DialpadView.SelectOutboundNumber" }));
    const [outboundDropdownKey, setOutboundDropdownKey] = useState(Math.random() + 'outboundDropdownKey');
    const [isEndpointDropdownOpen, setIsEndpointDropdownOpen] = useState(false);
    const defaultEndpointDropdownRef = useRef<HTMLDivElement | null>(null);
    const endpointDropdownRef = useRef<HTMLDivElement | null>(null);

    const currentContactRef = useRef('');

    const phoneNumberInputRef = useRef<HTMLInputElement | null>(null);

    const lastSelectedEndpoint = useRef(Lockr.get<TrustedEndpointDTO>(StorageKeys.OutboundEndpoint));
    const lastSelectedEndpointStillExists = useRef(false);

    useKeyCombination([ModifierKeys.Alt], ["o"], () => {
        phoneNumberInputRef.current?.focus();
    });

    useKeyCombination([ModifierKeys.Alt, ModifierKeys.Control], ["e"], () => {
        setIsEndpointDropdownOpen(!isEndpointDropdownOpen);
        focusDropdown();
    });

    let subscriptionQueueChange: Subscription | null = null;
    let subscriptionCallSessionStateChanged: Subscription | null = null;
    let subscriptionCurrentUserMonitoredCallSessionChanged: Subscription | null = null;
    let subscriptionSocketCallHistoryCallBackContactInfo: Subscription | null = null;
    let subscriptionTrustedEnpointsChanged: Subscription | null = null;
    let subscriptionQueueMembershipChanged: Subscription | null = null;
    let subscriptionQueueMembershipRemoved: Subscription | null = null;
    let subscriptionOutboundEndpointChanged: Subscription | null = null;
    let subscriptionOutboundCallError: Subscription | null = null;

    const startOperations = [ConferenceStatusAction[ConferenceStatusAction.Accepted], ConferenceStatusAction[ConferenceStatusAction.Offering],
    ConferenceStatusAction[ConferenceStatusAction.OnHold], ConferenceStatusAction[ConferenceStatusAction.Resumed]];

    const endOperations = EnvHelper.isStage3() ? GeneralHelper.getStage3EndOperations() : GeneralHelper.getEndOperations();

    const dialpadData = [
        {
            row: 1,
            column: 1,
            digit: 1,
            text: ''
        },
        {
            row: 1,
            column: 2,
            digit: 2,
            text: 'ABC'
        },
        {
            row: 1,
            column: 3,
            digit: 3,
            text: 'DEF'
        },
        {
            row: 2,
            column: 1,
            digit: 4,
            text: 'GHI'
        },
        {
            row: 2,
            column: 2,
            digit: 5,
            text: 'JKL'
        },
        {
            row: 2,
            column: 3,
            digit: 6,
            text: 'MNO'
        },
        {
            row: 3,
            column: 1,
            digit: 7,
            text: 'PQRS'
        },
        {
            row: 3,
            column: 2,
            digit: 8,
            text: 'TUV'
        },
        {
            row: 3,
            column: 3,
            digit: 9,
            text: 'WXYZ'
        },
        {
            row: 4,
            column: 1,
            digit: '*',
            text: ''
        },
        {
            row: 4,
            column: 2,
            digit: 0,
            text: '+'
        },
        {
            row: 4,
            column: 3,
            digit: '#',
            text: ''
        },
    ];

    useEffect(() => {
        initialize();
        window.addEventListener('keyup', handleEnterKeyPress);
        return () => {
            window.removeEventListener('keyup', handleEnterKeyPress);
            componentWillUnmount();
        };
    }, []);

    useEffect(() => {
        isInACallRef.current = isInACall;
    }, [isInACall]);

    useEffect(() => {
        isInCallMonitoringRef.current = isInCallMonitoring;
    }, [isInCallMonitoring]);

    useEffect(() => {
        if (disabledDialpad) {
            return;
        }

        if (isPhoneNumberInputFocused) {
            phoneNumberInputRef.current?.focus();
        }
        else {
            setIsEndpointDropdownOpen(isEndpointDropdownOpenDefault);
            focusDropdown();
        }

    }, [isEndpointDropdownOpenDefault, isPhoneNumberInputFocused, disabledDialpad])

    const initialize = () => {
        GeneralHelper.logCox(`in DialpadView.tsx, in initialize`);

        subscriptionQueueChange?.unsubscribe();
        subscriptionQueueChange = socketQueue.subjectQueueChangeNotification.subscribe((obj: any) => {
            getEndpoints();
        });

        subscriptionQueueMembershipChanged?.unsubscribe();
        subscriptionQueueMembershipChanged = socketQueue.listenerNotifyQueueUserMembershipChange
            .received.subscribe((queueChange: any) => {
                getEndpoints();
            });

        subscriptionQueueMembershipRemoved?.unsubscribe();
        subscriptionQueueMembershipRemoved = socketQueue.listenerNotifyQueueUserMembershipRemove
            .received.subscribe((queue: any) => {
                getEndpoints();
            });

        subscriptionOutboundEndpointChanged?.unsubscribe();
        subscriptionOutboundEndpointChanged = socketOutbound.listenerOutboundEndpointsChanged
            .received.subscribe((queue: any) => {
                getEndpoints();
            });

        subscriptionOutboundCallError?.unsubscribe();
        subscriptionOutboundCallError = socketOutbound.listenerOutboundCallError
            .received.subscribe((data: any) => {
                logging.errorHandler.next("CallActions.OutboundCallError");
            });

        getEndpoints(true);

        if (socketAudio.getCallSessionForCurrentUser().ConversationKey) {
            setIsInACall(!socketAudio.getCallSessionForCurrentUser().IsTerminated);
        }

        subscriptionCallSessionStateChanged?.unsubscribe();
        subscriptionCallSessionStateChanged = socketAudio.callSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
            EnvHelper.isStage3() ? handleConferenceActionStage3(callSessionDTO) : handleConferenceActionStage2(callSessionDTO);
        });

        subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
        subscriptionCurrentUserMonitoredCallSessionChanged = socketAudio.currentUserMonitoredCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
            setIsInCallMonitoring(callSessionDTO.MonitorType !== MonitorTypes.None && !callSessionDTO.IsComputedAsTerminated);
        });

        subscriptionSocketCallHistoryCallBackContactInfo?.unsubscribe();
        subscriptionSocketCallHistoryCallBackContactInfo = socketCallHistory.callBackContactInfo.subscribe((obj: string) => {
            setCallBackInfo(EnvHelper.isStage3() ? obj : GeneralHelper.formatSipOrPhoneNumber(obj));
        });

        subscriptionTrustedEnpointsChanged?.unsubscribe();
        subscriptionTrustedEnpointsChanged = socketOutbound.trustedEndpointsChanged.subscribe((response: TrustedEndpointDTO[]) => {
            setTrustedEndpoints(response);
            trustedEndpointsRef.current = response;
        });
    }

    const componentWillUnmount = () => {
        subscriptionQueueChange?.unsubscribe();
        subscriptionCallSessionStateChanged?.unsubscribe();
        subscriptionSocketCallHistoryCallBackContactInfo?.unsubscribe();
        subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
        subscriptionTrustedEnpointsChanged?.unsubscribe();
        subscriptionQueueMembershipChanged?.unsubscribe();
        subscriptionQueueMembershipRemoved?.unsubscribe();
        subscriptionOutboundEndpointChanged?.unsubscribe();
        subscriptionOutboundCallError?.unsubscribe();
    }

    useEffect(() => {
        if (!currentEndpoint || !currentEndpoint.EndpointID) {
            setDisabledDialpad(true);
            setPhoneNumber('');
            sipOrPhoneNumberRef.current = '';
            setDropdownPlaceholder(intl.formatMessage({ id: "DialpadView.SelectOutboundNumber" }));
        } else {
            setDisabledDialpad(false);
        }
    }, [currentEndpoint]);

    useEffect(() => {
        if (EnvHelper.isStage3() && sipOrPhoneNumber === "") {
            currentContactRef.current = "";
        }
        if (!sipOrPhoneNumber && (!currentEndpoint || !currentEndpoint.EndpointID)) {
            setDisabledDialpad(true);
        }
    }, [sipOrPhoneNumber]);

    useEffect(() => {
        if (lastSelectedEndpointStillExists.current) {
            setCurrentEndpoint(lastSelectedEndpoint.current);
            currentEndpointRef.current = lastSelectedEndpoint.current;
            setDropdownPlaceholder(lastSelectedEndpoint.current.DisplayValue as string);
        }
    }, [lastSelectedEndpointStillExists.current]);

    const getEndpoints = (useCache: boolean = false) => {
        socketOutbound.GetEndPointListForAgent(useCache);
    }

    const handleConferenceActionStage2 = (callSessionDTO: CallSessionDTO) => {
        const isParkCall = GeneralHelper.isCallParkedOrFallback(callSessionDTO.ActionHistory, ConferenceStatusAction.OperatorPark);
        const isFallbackCall = GeneralHelper.isCallParkedOrFallback(callSessionDTO.ActionHistory, ConferenceStatusAction.Fallback);

        if (!isParkCall && !isFallbackCall && startOperations.includes(callSessionDTO.ConferenceActionAsString) && callSessionDTO.IsCurrentUserThePrimaryAgent) {
            setIsInACall(true);
        }
        else {
            setIsInACall(false);
        }
    }

    const focusDropdown = () => {
        const triggerButtonRef = isLastSelectedEndpointValid() ?
            defaultEndpointDropdownRef
            : endpointDropdownRef

        triggerButtonRef.current?.querySelector('ul')?.focus();
    }

    const handleConferenceActionStage3 = (callSessionDTO: CallSessionDTO) => {
        const agentId = Lockr.get(StorageKeys.UserObjectId);

        if (startOperations.includes(callSessionDTO.ConferenceActionAsString)) {
            setIsInACall(true);
        }

        if (endOperations.includes(callSessionDTO.ConferenceActionAsString) || agentId !== callSessionDTO?.AgentRef) {
            //#CC4ALL-3650 fix-- agentId !== callSessionDTO?.AgentRef -- cold transfer on progress but notification received with ConferenceActionAsString = ringing. 
            setIsInACall(false);
            setDisabledDialpad(false);
        }
    }

    const retryGetEndpoints = () => {
        if (!trustedEndpoints?.length) {
            getEndpoints();
        }
    }

    const mapEndpointsForDropdown = () => {
        return trustedEndpoints?.map((endpoint: TrustedEndpointDTO) => {
            let isItemSelected = false;

            if (lastSelectedEndpoint.current &&
                lastSelectedEndpoint.current.EndpointID &&
                endpoint.EndpointID === lastSelectedEndpoint.current.EndpointID &&
                endpoint.QueueRef === lastSelectedEndpoint.current.QueueRef) {
                isItemSelected = true;
            }

            if (currentEndpoint && currentEndpoint.EndpointID && currentEndpoint.EndpointID === endpoint.EndpointID && currentEndpoint.QueueRef === endpoint.QueueRef) {
                isItemSelected = true;
            }

            if (isItemSelected && !lastSelectedEndpointStillExists.current && lastSelectedEndpoint.current && lastSelectedEndpoint.current.EndpointID) {
                lastSelectedEndpointStillExists.current = true;
                setOutboundDropdownKey(Math.random() + 'outboundDropdownKey');
            }

            if (EnvHelper.isStage3()) {
                let displayValue = `${endpoint.EndpointQueueName} (${endpoint.EndpointDisplayName}`;
                displayValue = endpoint.EndpointPhoneNumber ? displayValue + `, ${endpoint.EndpointPhoneNumber})` : displayValue + `)`;
                endpoint.DisplayValue = displayValue;
            }

            return {
                item: endpoint,
                header: endpoint.DisplayValue,
                selected: isItemSelected,
                title: endpoint.DisplayValue
            };
        });
    }

    const selectEndpointFromDropdown = (options: any) => {
        if (options) {
            const selectedEndpoint = (options.value)?.item;
            setCurrentEndpoint(selectedEndpoint);
            currentEndpointRef.current = selectedEndpoint
            Lockr.set(StorageKeys.OutboundEndpoint, selectedEndpoint);
            lastSelectedEndpoint.current = {} as TrustedEndpointDTO;
        }
    }

    const createPhoneNumber = (digit: string) => {

        setPhoneNumber(sipOrPhoneNumber + digit);
        sipOrPhoneNumberRef.current = sipOrPhoneNumber + digit;

        sendTone(digit);
    }

    const deletePhoneNumber = () => {
        setPhoneNumber('');
        sipOrPhoneNumberRef.current = '';
    }

    const formatTargetCustumerNumber = (phoneNr: string) => {
        if (phoneNr && phoneNr.toLowerCase().indexOf("sip:") !== 0) {
            const isPhoneNumber = ValidationHelper.isValidPhoneNumber(phoneNr);

            return isPhoneNumber ? phoneNr : "sip:" + phoneNr;
        }

        return phoneNr;
    }

    const sendTone = (tone: string) => {
        if (socketAudio.callSession.SessionId && ValidationHelper.isValidToneKeyNumber(tone)) {
            const sendToneRequest: SendToneDto = {
                Tone: tone,
                ConversationKey: socketAudio.callSession.ConversationKey,
                SourceUri: socketAudio.callSession.CallerUri,
                ScenarioId: socketAudio.callSession.ScenarioId
            };

            socketOutbound.SendTone(sendToneRequest);

            if (!socketAudio.callSession.IsWarmTransferInProgress) {
                PlaySoundHelper.playSound(SoundType.Tone);
            }
        }
    }

    const handleEnterKeyPress = (e: any) => {
        if ((e.keyCode === 13 || e.which === 13) && sipOrPhoneNumberRef.current && currentEndpointRef?.current?.EndpointID && !isInACallRef.current && !isInCallMonitoring) {
            makeCall();
        }
    };

    const makeCall = (ignoreUpnValidation: boolean = false) => {
        let isSipOrPhoneValid = true;
        const trimmmedPhoneNumber = sipOrPhoneNumberRef.current.trim();

        if (!trimmmedPhoneNumber) {
            isSipOrPhoneValid = false;
        }

        // separate Stage 3 checks
        if (EnvHelper.isStage3() && !ignoreUpnValidation) {
            isSipOrPhoneValid = ValidationHelper.isValidPhoneNumber(trimmmedPhoneNumber) || ValidationHelper.isValidSip(trimmmedPhoneNumber);
        }

        if (!isSipOrPhoneValid) {
            logging.errorHandler.next(intl.formatMessage({ id: "ErrorMessage.InvalidSipOrNumber" }));
            return;
        }

        if (currentEndpointRef?.current?.EndpointID) {
            createOutboundCall(currentEndpointRef.current, trimmmedPhoneNumber, ignoreUpnValidation);
        } else if (trustedEndpointsRef.current[0] && trustedEndpointsRef.current[0].EndpointID) {
            createOutboundCall(trustedEndpointsRef.current[0], trimmmedPhoneNumber, ignoreUpnValidation);
        }
    }

    const createOutboundCall = (endpoint: TrustedEndpointDTO, number: string, ignoreUpnValidation: boolean = false) => {
        EnvHelper.isStage3()
            ? handleOutboundCallCreationS3(endpoint, number, ignoreUpnValidation)
            : handleOutboundCallCreationS2(endpoint, number);
    }

    const handleOutboundCallCreationS2 = (endpoint: TrustedEndpointDTO, number: string) => {
        const callingAgentSip = Lockr.get(StorageKeys.SIP) ? Lockr.get(StorageKeys.SIP) + "" : "";
        let selectedEndpointNumber = endpoint.EndpointLineUri
        selectedEndpointNumber = selectedEndpointNumber ? selectedEndpointNumber.replace('Tel:', '') : selectedEndpointNumber;
        const selectedEndpointID = endpoint.EndpointID;
        const campaignItem: CampaingCallItemDto = {
            CallerAgentSip: callingAgentSip,
            UniqueCallId: `${(Math.random() * 1000 % 10000)}`,
            IsTeamsOutboundDialing: true,
            CallingNumber: selectedEndpointNumber,
            TargetCustomerPhoneNumber: formatTargetCustumerNumber(number),
            EndpointID: selectedEndpointID
        };

        setPhoneNumber('');
        sipOrPhoneNumberRef.current = '';
        socketOutbound.CreateOutboundCall(campaignItem);
    }

    const handleOutboundCallCreationS3 = (endpoint: TrustedEndpointDTO, phoneOrSip: string, ignoreUpnValidation: boolean = false) => {
        let target = phoneOrSip;
        const isValidPhone = ignoreUpnValidation || ValidationHelper.isValidPhoneNumber(phoneOrSip);
        let calledByUpn = false;

        //called from contacts list
        if (currentContactRef.current != "") {
            //target = phone or userId(in case upn is selected from contact call options)
            target = isValidPhone ? target : currentContactRef.current;
        } else if (!isValidPhone) {
            //call made by dialpad input and upn/sip is used
            calledByUpn = true;
        }

        if (!endpoint.ObjectId) {
            logging.errorHandler.next("DialpadView.NoObjectId");
        }
        else {
            const item: OutgoingCallDto = {
                AgentId: Lockr.get(StorageKeys.UserObjectId),
                AgentUpn: localStorage.getItem(StorageKeys.UPN) as string,
                Target: target,
                TenantId: Lockr.get(StorageKeys.CompanyId),
                AzureTenantId: localStorage.getItem(StorageKeys.TIDForAzure) || "",
                ApplicationInstanceId: endpoint.ObjectId,
                ApplicationInstanceName: endpoint.EndpointDisplayName,
                QueueId: endpoint.QueueRef,
                QueueName: endpoint.EndpointQueueName,
                CalledByUpn: calledByUpn,
                OutboundNumber: endpoint.EndpointPhoneNumber
            };

            setPhoneNumber('');
            sipOrPhoneNumberRef.current = '';

            socketPresence.GetAzureCurrentPresence()
                .then(presence => {
                    if (presence.Availability === PresenceState.DND || presence.Activity === PresenceState.DND) {
                        logging.errorHandler.next("ErrorMessage.Red.InvalidStatusForCall");
                        return;
                    }

                    socketOutbound.CreateOutgoingCall(item).then(response => {
                        if (response.ContactNotFound) {
                            logging.errorHandler.next(intl.formatMessage({ id: "ErrorMessage.InvalidSipOrNumber" }));
                        } else if (response.IsSelfRinging) {
                            logging.errorHandler.next(intl.formatMessage({ id: "ErrorMessage.OutboundSelfRinging" }));
                        } else if (response.TargetIsInACall) {
                            logging.errorHandler.next(intl.formatMessage({ id: "ErrorMessage.OutboundTargetIsInACall" }));
                        } else {
                            logging.errorHandler.next("CallActions.NewCall");
                        }
                    });
                });
        }
    }

    const setCallBackInfo = (obj: any) => {
        if (EnvHelper.isStage3()) {
            currentContactRef.current = obj.id;
        }

        setDisabledDialpad(false);
        setPhoneNumber(EnvHelper.isStage3() ? obj.target : obj);
        sipOrPhoneNumberRef.current = EnvHelper.isStage3() ? obj.target : obj;

        if (!trustedEndpointsRef.current || trustedEndpointsRef.current?.length === 0) {
            trustedEndpointsRef.current = socketOutbound.GetAgentCachedEndPointList();
        }

        if (!isInACallRef?.current && !isInCallMonitoringRef?.current && trustedEndpointsRef.current && trustedEndpointsRef.current.length === 1) {
            setCurrentEndpoint(trustedEndpointsRef.current[0]);
            currentEndpointRef.current = trustedEndpointsRef.current[0];
            lastSelectedEndpoint.current = trustedEndpointsRef.current[0];
            Lockr.set(StorageKeys.OutboundEndpoint, trustedEndpointsRef.current[0]);
            setOutboundDropdownKey(Math.random() + 'outboundDropdownKey');
            const ignoreUpnValidation = obj.ignoreUpnValidation && true;
            makeCall(ignoreUpnValidation);
        }
    }

    const isLastSelectedEndpointValid = () => {
        return (lastSelectedEndpoint &&
            lastSelectedEndpoint.current &&
            lastSelectedEndpoint.current.DisplayValue &&
            trustedEndpoints?.some(e => e.DisplayValue === lastSelectedEndpoint.current.DisplayValue &&
                e.QueueRef === lastSelectedEndpoint.current.QueueRef));
    }

    const onPasteInputDialpad = (value: string, isDisabledDialpad: boolean) => {
        if (isDisabledDialpad === false) {
            const formatedValue = GeneralHelper.formatSipOrPhoneNumber(value);
            skipInputDialpadChangeEvent.current = true;
            setPhoneNumber(formatedValue);
            sipOrPhoneNumberRef.current = formatedValue;

        }
    }

    const onChangeInputDialpad = (value: string) => {
        if (!skipInputDialpadChangeEvent.current) {
            setPhoneNumber(value);
            sipOrPhoneNumberRef.current = value;
        }
        else {
            skipInputDialpadChangeEvent.current = false;
        }
    }

    const getEndpointsDropDownTitle = () => {
        return currentEndpoint ? currentEndpoint.DisplayValue : '';
    }

    const getEndpointsDropDownDefaultValue = () => {
        return (lastSelectedEndpoint &&
            lastSelectedEndpoint.current &&
            lastSelectedEndpoint.current.DisplayValue) ? lastSelectedEndpoint.current.DisplayValue : '';
    }

    return (
        <div className="dialpad-view">
            {isLastSelectedEndpointValid() ?
                <Dropdown
                    ref={defaultEndpointDropdownRef}
                    className="endpoint-dropdown"
                    title={getEndpointsDropDownTitle()}
                    placeholder={dropdownPlaceholder}
                    fluid
                    defaultValue={getEndpointsDropDownDefaultValue()}
                    checkable
                    clearable
                    onOpenChange={(_, popupProps) => {
                        popupProps && setIsEndpointDropdownOpen(popupProps?.open || false);
                        retryGetEndpoints()
                    }}
                    open={isEndpointDropdownOpen}
                    items={mapEndpointsForDropdown()}
                    onChange={(_, selectedOption) => {
                        selectEndpointFromDropdown(selectedOption);
                    }}
                    key={outboundDropdownKey}
                /> :
                <Dropdown
                    ref={endpointDropdownRef}
                    className="endpoint-dropdown"
                    title={getEndpointsDropDownTitle()}
                    placeholder={dropdownPlaceholder}
                    fluid
                    checkable
                    clearable
                    onOpenChange={(_, popupProps) => {
                        popupProps && setIsEndpointDropdownOpen(popupProps?.open || false);
                        retryGetEndpoints()
                    }}
                    open={isEndpointDropdownOpen}
                    items={mapEndpointsForDropdown()}
                    onChange={(_, selectedOption) => {
                        selectEndpointFromDropdown(selectedOption);
                    }}
                    key={outboundDropdownKey}
                />
            }
            <br />
            <Input
                ref={phoneNumberInputRef}
                className={disabledDialpad ? "phone-number-input-disabled" : "phone-number-input"}
                disabled={disabledDialpad}
                fluid
                value={sipOrPhoneNumber}
                icon={<CloseIcon disabled={sipOrPhoneNumber?.length === 0} color="brand"
                    onClick={deletePhoneNumber} styles={{ cursor: "pointer" }}
                    className={(sipOrPhoneNumber === '') ? 'disable-clear-button' : ''} />}
                iconPosition="end"
                aria-label={intl.formatMessage({ id: "DialpadView.TypeNumberOrUpn" })}
                onChange={(e) => { onChangeInputDialpad((e.target as HTMLInputElement).value) }}
                onBlur={() => {
                    setPhoneNumber(GeneralHelper.formatSipOrPhoneNumber(sipOrPhoneNumber))
                    sipOrPhoneNumberRef.current = GeneralHelper.formatSipOrPhoneNumber(sipOrPhoneNumber);
                }}
                onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => { onPasteInputDialpad(e.clipboardData.getData("Text"), disabledDialpad) }}
            />

            <Grid
                columns={3}
                content={dialpadData.map((key: any) => {
                    return <DialpadButton disabled={disabledDialpad} row={key.row} column={key.column}
                        digit={key.digit} key={key.digit} text={key.text} createPhoneNumber={createPhoneNumber} />
                })}
                styles={{ padding: "10px 0px" }}
            />
            <div style={{ textAlign: "center" }}>
                <Button
                    className="call-button"
                    disabled={((sipOrPhoneNumber && !isInACall && !isInCallMonitoring && currentEndpoint && currentEndpoint.EndpointID) ? false : true) || disabledDialpad}
                    circular
                    primary
                    icon={<CallIcon size="large" />}
                    title={intl.formatMessage({ id: 'DialpadView.AudioCall' })}
                    onClick={() => makeCall()}
                />
            </div>
        </div>
    )
};

export default DialpadView;

import { Subject } from 'rxjs';
import { Person } from 'utils';
import { AudioService } from './socket-audio';

export interface ApiAudioService {
    //callStateChanged: Subject<{ state: any, person1: Person, person2: Person }>;
    // call(targetUri: string): Promise<string>;
    // registerIncomingAudio(): void;
    // acceptCall(): Promise<any>;
    // rejectCall(): Promise<any>;
    hangUp(data: any): Promise<any>;
    // joinMeeting(meetingUrl: string): void;
    // sendDTMF(tone: string): void;
    // hasActiveCall(): boolean;
    getClientVariableFields(data: any): Promise<any>
    getWorkflowVariables(workflowId: string): Promise<any>
}

export const socketAudio = new AudioService();

export interface ApiNote {
    noteChange: Subject<{ personId: string, note: any }>;
    bindNoteListenerForPerson(person: Person): void;
    unBindNoteListenerForPerson(person: Person): void;
    setNote(person: Person, note: string): void;
}
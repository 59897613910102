import React, { useState, useRef } from 'react';
import { Subscription } from 'rxjs';
import Lockr from 'lockr';
import { MenuButton, Button, Status, AcceptIcon, LeaveIcon, WindowMinimizeIcon, Popup, PlayIcon, List, Flex, Text, RetryIcon } from '@fluentui/react-northstar';
import { socketPresence } from 'services/presence';
import { logging, StorageKeys, UserResetStatus } from 'utils';
import { serviceRequests } from 'services/io';
import { socketQueue } from 'services/queue';
import './presenceView.scss';
import { socketAuth } from 'services';
import { useIntl } from 'react-intl';
import { EnvHelper, GeneralHelper } from 'utils/helpers';
import { Presence, PresenceState, NotePresenceState, PresenceItem } from 'utils/domain/presence';
import AgentAvatar from 'components/AgentAvatar';
import { socketContacts } from 'services/contacts';
import { presenceStressTest } from 'services/presence/presence-stress-test';
import Env from 'utils/helpers/env';

export const PresenceView = (props: any) => {
  const { sip, queueRef, name, availability, reason, id } = props;
  const intl = useIntl();

  const currentPresenceText = useRef("");
  const currentNoteText = useRef("");
  const isWrapUpExist = useRef(false);//#warning-js: would move this to socketPresence, to have a single ref, true/false, and not subscribe here ...
  const [presence, setPresence] = useState((<div></div>));
  const [busyReasons, setBusyReasons] = useState([] as any);
  const [dndReasons, setDndReasons] = useState([] as any);

  function getTextForPresenceState(presenceState: string): string {
    return GeneralHelper.getStatusText(presenceState, intl);
  }

  const getLogoutText = () => {
    return intl.formatMessage({ id: "SettingsView.Logout" });
  }

  const getResetText = () => {
    return intl.formatMessage({ id: "SettingsView.Reset" });
  }

  const getBusyReasons = () => {
    return <Popup position={sip || id ? 'after' : 'before'} align='top' key='busy_reasons-popup' offset={() => [-6, 17]} inline={sip || id ? false : true} mouseLeaveDelay={150}
      trigger={<span className="presence-with-reasons-popup-trigger" onClick={() => { sendPresenceChange(PresenceState.Busy, getTextForPresenceState(PresenceState.Busy)) }}>
        <span className="presence-item"><Status state="error" className="presence-status" />
          <span className="presence-text-size">{getTextForPresenceState(PresenceState.Busy)}</span>
        </span>
        {busyReasons.length !== 0 && <PlayIcon size="smallest" />}
      </span>}
      content={{
        content: <List selectable items={[...busyReasons]} />
      }}
      on="hover"
    />
  }

  const getDndReasons = () => {
    return <Popup position={sip || id ? 'after' : 'before'} align='top' key='dnd_reasons-popup' offset={() => [-6, 17]} inline={sip || id ? false : true} mouseLeaveDelay={150}
      trigger={<span className="presence-with-reasons-popup-trigger" onClick={() => { sendPresenceChange(PresenceState.DND, getTextForPresenceState(PresenceState.DND)) }}>
        <span className="presence-item"><Status state="error" icon={<WindowMinimizeIcon />} className="presence-status" />
          <span className="presence-text-size">{getTextForPresenceState(PresenceState.DND)}</span>
        </span>
        {dndReasons.length !== 0 && <PlayIcon size="smallest" />}
      </span>}
      content={{
        content: <List selectable items={[...dndReasons]} />
      }}
      on="hover"
    />
  }

  const addResetAndLogoutButtons = () => {
    if (sip === undefined && id === undefined) {
      items.push({
        content: <hr />,
        key: "divider",
        className: "break-line"
      });

      if (Env.isStage3()) {
        items.push({
          content:
            <Flex gap="gap.small" vAlign="center" hAlign="center">
              <RetryIcon size="small" />
              <Text className="reset-button-text" content={getResetText()} />
            </Flex>,
          onClick: () => { processUserResetClick() },
          key: getResetText(),
          className: "reset-button"
        });
        items.push({
          content: <hr />,
          key: "divider-reset-logout",
          className: "break-line"
        });
      }

      items.push({
        content:
          <Flex gap="gap.small" vAlign="center" >
            <LeaveIcon size="small" />
            <Text className="logout-button-text" content={`${getLogoutText()} ${Lockr.get<string>(StorageKeys.FullName)}`} />
          </Flex>,
        onClick: () => { socketAuth.userManuallySignedOut.next() },
        key: getLogoutText(),
        className: "logout-button"
      });
    }
  }

  const items: PresenceItem[] = [
    {
      content: getElementForPresence(PresenceState.Online),
      onClick: () => sendPresenceChange(PresenceState.Online, ""),
      key: PresenceState.Online.toString(),
    },
    {
      content: getBusyReasons(),
      key: PresenceState.Busy.toString(),
      className: "presence-with-reasons",
    },
    {
      content: getDndReasons(),
      key: PresenceState.DND.toString(),
      className: "presence-with-reasons",
    },
    {
      content: getElementForPresence(PresenceState.BeRightBack),
      onClick: () => sendPresenceChange(PresenceState.BeRightBack, ""),
      key: PresenceState.BeRightBack.toString(),
    },
    {
      content: getElementForPresence(PresenceState.Away),
      onClick: () => sendPresenceChange(PresenceState.Away, ""),
      key: PresenceState.Away.toString(),
    }
  ]

  addResetAndLogoutButtons();

  function processUserResetClick() {
    socketAuth.resetUser()
      .then(resetStatus => {
        switch (resetStatus) {
          case UserResetStatus.ResetPerformed:
            logging.errorHandler.next("SettingsView.ResetPerformed");
            break;
          case UserResetStatus.DndStatusIsNotAllowed:
            logging.errorHandler.next("SettingsView.Red.DndStatusIsNotAllowed");
            break;
          case UserResetStatus.CallInProgress:
            logging.errorHandler.next("SettingsView.Red.CallInProgress");
            break;
        }
      })
      .catch(e => console.error(e));
  }

  function changePresence(presenceState: string, presenceText: string, sipValue?: string, idValue?: string, wrapUpCodeId?: string, wrapUpCodeName?: string) {
    if (sipValue || idValue) {
      serviceRequests.ChangePresence(sipValue ? sipValue : "", presenceState, presenceText ? presenceText : "", idValue ? idValue : "", undefined, wrapUpCodeId, wrapUpCodeName);
    }
    else {
      setPresence(getElementForPresence(presenceState, presenceText));
      currentPresenceText.current = presenceState;
      currentNoteText.current = presenceText || "";

      //send presence changed to server
      serviceRequests.ChangePresence(Lockr.get<string>(StorageKeys.SIP), presenceState, presenceText ? presenceText : "", Lockr.get(StorageKeys.UserObjectId), undefined, wrapUpCodeId, wrapUpCodeName);
      presenceStressTest.testSpecialNote(presenceText);
    }
  }

  function getElementForPresence(presenceState: string, presenceText?: string): JSX.Element {
    if (!presenceText) {
      presenceText = getTextForPresenceState(presenceState);
    }
    else {
      presenceText = getTextForPresenceState(presenceText);
    }

    switch (presenceState) {
      case PresenceState.Online:
      case PresenceState.AvailableIdle:
      case PresenceState.Available: {
        return <span className="presence-item"><Status state="success" icon={<AcceptIcon />} className="presence-status" />
          <span className="presence-text-size">{presenceText}</span>
        </span>
      }
      case PresenceState.BusyIdle:
      case PresenceState.Busy: {
        return <span className="presence-item"><Status state="error" className="presence-status" />
          <span className="presence-text-size">{presenceText}</span>
        </span>
      }
      case PresenceState.DND: {
        return <span className="presence-item"><Status state="error" icon={<WindowMinimizeIcon />} className="presence-status" />
          <span className="presence-text-size">{presenceText}</span>
        </span>
      }
      case PresenceState.BeRightBack: {
        return <span className="presence-item"><Status state="warning" icon={<img alt='Away Icon' />} className="presence-status be-right-back" />
          <span className="presence-text-size">{presenceText}</span>
        </span>
      }
      case PresenceState.Away: {
        return <span className="presence-item"><Status state="warning" icon={<img alt='Away Icon' />} className="presence-status away" />
          <span className="presence-text-size">{presenceText}</span>
        </span>
      }
      case PresenceState.PresenceUnknown:
      case PresenceState.Offline: {
        return <span className="presence-item"><Status state="unknown" className="presence-status" />
          <span className="presence-text-size">{presenceText}</span>
        </span>
      }
      default: {
        break;
      }
    }

    return <div onClick={() => sendPresenceChange(PresenceState.Busy, presenceText || "")}><Status state="error" styles={{ marginRight: "10px", marginTop: "-3px" }} />
      <span>{presenceText}</span>
    </div>
  }

  let subscriptionPresenceUpdateReceived: Subscription | null = null;
  let subscriptionSocketPresenceSelect: Subscription | null = null;
  let subscriptionSocketPresenceWrapup: Subscription | null = null;
  let subscriptionSocketQueueChange: Subscription | null = null;
  let subscriptionPresenceAndNoteSetPresence: Subscription | null = null;

  React.useEffect(() => {
    initialize();

    return () => {
      componentWillUnmount();
    }
  }, []);

  const componentWillUnmount = () => {
    GeneralHelper.logCox(`in PresenceView.tsx, in componentWillUnmount`);

    subscriptionPresenceUpdateReceived?.unsubscribe();
    subscriptionSocketPresenceSelect?.unsubscribe();
    subscriptionSocketPresenceWrapup?.unsubscribe();
    subscriptionPresenceAndNoteSetPresence?.unsubscribe();
    subscriptionSocketQueueChange?.unsubscribe();
  }

  const initialize = () => {
    GeneralHelper.logCox(`in PresenceView.tsx, in initialize`);

    initializeComponent();

    if (EnvHelper.isStage3()) {
      subscriptionPresenceUpdateReceived?.unsubscribe();
      subscriptionPresenceUpdateReceived = socketContacts.notifyUpdatedPresenceState.subscribe((userPresence: Presence) => {
        if (userPresence.Id === Lockr.get(StorageKeys.UserObjectId)) {
          setPresence(getElementForPresence(userPresence.Availability, userPresence.Activity));
          currentPresenceText.current = userPresence.Availability;
          currentNoteText.current = userPresence.Activity as string || "";
        }
      })
    }

    // only subscribe listeners for current user presence view
    if (sip === undefined && id === undefined) {
      subscriptionSocketPresenceSelect?.unsubscribe();
      subscriptionSocketPresenceSelect = socketPresence.select.subscribe((result: Presence) => {
        GeneralHelper.logCox('[CC4ALL-2739] subscriptionSocketPresenceSelect, in PresenceView.tsx');
        changePresence(result.Availability, result.Activity ?? '', result.Sip, result.WrapUpCodeId, result.WrapUpCodeName);
      });

      subscriptionSocketPresenceWrapup?.unsubscribe();
      subscriptionSocketPresenceWrapup = socketPresence.wrapUp.subscribe((result: boolean) => {
        GeneralHelper.logCox(`in PresenceView, socketPresenceWrapup, setting isWrapUpExist.current to ${result}`);
        isWrapUpExist.current = result;
      });

      subscriptionPresenceAndNoteSetPresence?.unsubscribe();
      subscriptionPresenceAndNoteSetPresence = socketPresence.listenerPresenceAndNoteSet.received.subscribe((obj: any) => {
        GeneralHelper.logCox(`in PresenceView, listener PresenceAndNoteSet, obj is ${JSON.stringify(obj)}, isWrapUpExist.current is ${isWrapUpExist.current}`);
        if (!isWrapUpExist.current || obj.Activity === 'Ringing') {
          setPresence(getElementForPresence(obj.Availability, obj.Activity));
          currentPresenceText.current = obj.Availability as string;
          currentNoteText.current = obj.Activity as string || "";
          if (isWrapUpExist.current && obj.Activity === 'Ringing') {
            socketPresence.select.next({ Availability: obj.Availability, Activity: obj.Activity, sip } as Presence)
          }
        }
      });

      subscriptionSocketQueueChange?.unsubscribe();
      subscriptionSocketQueueChange = socketQueue.subjectQueueChange.subscribe((resultQueue: any) => {
        socketPresence.GetWrapUpCodes(Lockr.get<number>(StorageKeys.CompanyId)).then((result: any) => {
          preparePresences(result, resultQueue);
        });
      });
    }
  }

  function initializeComponent() {
    GeneralHelper.logCox(`in PresenceView.tsx, in initializeComponent; availability is ${availability}, and reason is ${reason}`);
    if (!availability) {
      socketPresence.GetCurrentPresence().then((result: Presence) => {
        setPresence(getElementForPresence(result.Availability, result.Activity as string));
        currentPresenceText.current = result.Availability;
        currentNoteText.current = result.Activity as string || "";
      });
    }

    if (sip || id) {
      socketPresence.GetWrapUpCodes(Lockr.get<number>(StorageKeys.CompanyId)).then((result: any) => {
        if (result) {
          preparePresences(result, [queueRef]);
        }
      });
    }
  }

  const preparePresences = (result: any[], queueRefs: any[]) => {
    if (queueRefs && queueRefs.length) {
      var attachedQueues = sip ? [] : queueRefs.map((x: any) => x.QueueRef);
      items.forEach(presenceItem => {
        var reasons: any;
        if (presenceItem.key?.toLowerCase() === PresenceState.Busy.toLowerCase()) {
          reasons = preparePresenceForStatus(result, attachedQueues, NotePresenceState.Busy, PresenceState.Busy, queueRef);
          if (reasons.length > 0) {
            setBusyReasons(reasons);
          }
        }
        else if (presenceItem.key?.toLowerCase() === PresenceState.DND.toLowerCase()) {
          reasons = preparePresenceForStatus(result, attachedQueues, NotePresenceState.DND, PresenceState.DND, queueRef);
          if (reasons.length > 0) {
            setDndReasons(reasons);
          }
        }
      });
    }
  }

  const preparePresenceForStatus = (result: any, attachedQueues: any[], status: NotePresenceState, statusValue: PresenceState, queueId?: number) => {
    var reasons: Array<{ key: string, content: JSX.Element, onClick: () => void, className: string }> = [];
    const notes: any[] = result.filter((i: any) => i.Presence === status && i.IsDeleted === false &&
      (queueId === undefined ?
        attachedQueues.includes(i.QueueRef) :
        i.QueueRef === queueId))
      .map((s: any) => s.Name);
    const uniqueNotes = Array.from(new Set(notes));
    uniqueNotes.forEach((note: any) => {
      reasons.push({
        content: getElementForPresence(statusValue, note),
        onClick: () => sendPresenceChange(statusValue, getTextForPresenceState(note)),
        key: note,
        className: 'presence-submenu-item'
      });
    });
    return reasons;
  }

  const sendPresenceChange = (localPresence: PresenceState, note: string) => {
    if (!(sip || id) && (localPresence !== currentPresenceText.current || currentNoteText.current !== note)) {
      socketPresence.select.next({ Availability: localPresence, Activity: note } as Presence);
    }

    const availableStatuses = [PresenceState.Online, PresenceState.Available, PresenceState.AvailableIdle];

    const isCurrentPresenceOnline = availableStatuses.includes(localPresence);
    const isOldPresenceOnline = availableStatuses.includes(availability);
    const areOldAndNewPresenceOnline = isCurrentPresenceOnline && isOldPresenceOnline;

    const oldReason = reason || "";
    const newReason = note || "";
    if ((sip || id) && ((localPresence !== availability && !areOldAndNewPresenceOnline) || oldReason !== newReason)) {
      socketPresence.select.next({ Availability: localPresence, Activity: note, Sip: sip } as Presence)
    }
  }

  const getStatusValue = () => {
    return reason ? reason : availability;
  }

  return (
    <MenuButton
      align={sip || id ? "start" : "end"}
      className="presence-menu"
      key="PresenceViewKey"
      trigger={!(sip || id)
        ? <Button
          content={presence}
          aria-label="Presence"
          className="presence-button"
        />
        : <Flex
          vAlign="center"
          className="poiting-cursor"
        >
          <AgentAvatar
            styles={{ marginRight: '0.5rem', }}
            status={getStatusValue()}
            name={name}
          />
        </Flex>}
      menu={items}
    />
  )
};
export default PresenceView;

import { Input } from "@fluentui/react-northstar";
import React from "react";

interface FileInputProps {
    icon: JSX.Element
    onFileSelected: (file: File) => void;
}

const FileInput = (props: FileInputProps) => {
    const { icon, onFileSelected: onFilesSelected } = props;

    return (<>
            <label style={{cursor: 'pointer'}} htmlFor='fileUpload'>{icon}</label>
            <Input
                id='fileUpload'
                type='file'
                style={{display: 'none'}}
                onChange={(event: any) => onFilesSelected(event.target.files[0])}
                onClick={(e: any) => e.target.value = null}
                // accept="*/*"
            />
        </>
    );
}

export default FileInput;
import * as React from "react";
import {
  Flex,
  Button,
  Popup,
  List,
  AttendeeIcon,
  CallIcon,
  ChatIcon,
  BellIcon} from "@fluentui/react-northstar";
import Lockr from "lockr";
import { useIntl } from "react-intl";
import "./managementView.scss";
import { socketQueue } from "../../services/queue";
import {
  ActiveMainViewItem,
  StorageKeys
} from "../../utils";
import { mainViewHelper } from "../../utils/helpers/main-view-helper";
import { QueueUserS3DTO } from "../../utils/domain/queueUserDTO";
import { Subscription } from "rxjs";
import {
  QueueView,
  SettingsView,
} from "..";
import { queuesService } from "../../services/queue/index";
import WrapUpExtendedView from "containers/WrapUpView/WrapUpExtendedView";
import { extendedVoicemailService } from "services/voiceMails/extendedVoicemailsApi";
import CurrentUserPresenceView from "containers/PresenceView/CurrentUserPresenceView";
import { useSubjectListenerEffect } from "utils/helpers/listenerHook";
import { InviteStatus, genericInviteService } from "containers/GenericView/GenericActionsView/GenericInviteService";
import { AgentStatus, agentStatusService } from "containers/GenericView/Services/AgentStatusService";
import { GenericListData, genericListService } from "containers/GenericView/ConnectionListView/GenericListService";

interface NotificatedElement {
  key: string;
  isVisible: boolean;
  icon: JSX.Element;
  value: number;
  isSelected: boolean;
  title: string;
  onClick: () => void;
}

export const ExtendedManagementView = () => {
  const intl = useIntl();

  let listItemsArray: any[] = [];
  const [listItems, setListItems] = React.useState([] as any);
  const [queueRef, setQueueRef] = React.useState(Math.random() as any);
  const [isQueuesButtonDisabled, setQueuesButtonDisabled] = React.useState(true);
  const [isAnyChatQueueActive, setIsAnyChatQueueActive] = React.useState(false);
  const [isAnyQueueJoinedState, setIsAnyQueueJoinedState] = React.useState(true);
  const [activeViewItem, setActiveViewItem] = React.useState(ActiveMainViewItem.MediaView);
  const activeViewItemRef = React.useRef(ActiveMainViewItem.MediaView);
  const newVoicemailsNotifications = React.useRef([] as any);
  const [alertsVoicemailsNotificationCount, setAlertsVoicemailsNotificationCount,] = React.useState(0);
  const currentUserId = React.useMemo<string>(() => Lockr.get<string>(StorageKeys.UserId), []);

  const [genericCount, setGenericCount] = React.useState<number>(0);
  const [isJoinCallInProgress, setIsJoinCallInProgress] = React.useState<boolean>(false);

  let subscriptionQueueMembershipChanged: Subscription | null = null;
  let subscriptionQueueMembershipRemoved: Subscription | null = null;
  let extendedSubscriptionQueueMembershipAdded: Subscription | null = null;
  let extendedSubscriptionQueueMembershipRemoved: Subscription | null = null;
  let extendedSubscriptionQueueSupervisorChanged: Subscription | null = null;
  let subscriptionNewVoicemails: Subscription | null = null;

  React.useEffect(() => {
    initialize();

    return () => componentWillUnmount();
  }, []);

  function initialize() {
    genericListService.init();
    getQueues();
    calculateGenericCount(genericInviteService.inviteStatus, genericListService.genericsData);
    setIsJoinCallInProgress(agentStatusService.status === AgentStatus.inACall);
  }

  React.useEffect(() => {
    if (isJoinCallInProgress) {
      genericInviteService.hideInviteIfExists();
    }
  }, [isJoinCallInProgress]);

  useSubjectListenerEffect((status: AgentStatus) => {
    setIsJoinCallInProgress(status === AgentStatus.inACall)
  }, agentStatusService.statusChanged);

  function attachQueueMembershipChangesListners() {
    subscriptionQueueMembershipChanged?.unsubscribe();
    subscriptionQueueMembershipChanged = socketQueue.listenerNotifyQueueUserMembershipChange
      .received.subscribe((queueChange: any) => {
        updateQueuesFromSignalR(queueChange);
        processApiServiceQueueCallResponse(queuesService.queuesArray);
      });

    subscriptionQueueMembershipRemoved?.unsubscribe();
    subscriptionQueueMembershipRemoved = socketQueue.listenerNotifyQueueUserMembershipRemove
      .received.subscribe((queue: any) => {
        removeQueuesFromSignalR(queue);
        processApiServiceQueueCallResponse(queuesService.queuesArray);
      });

    extendedSubscriptionQueueMembershipAdded = queuesService.queueMembershipAdded.subscribe((queueChange: any) => {
      getExtendedVoicemailListForClient(true);
    });

    extendedSubscriptionQueueMembershipRemoved = queuesService.queueMembershipRemoved.subscribe((queue: any) => {
      getExtendedVoicemailListForClient(true);
    });

    extendedSubscriptionQueueSupervisorChanged = queuesService.queueSupervisorChanged.subscribe((queue: any) => {
      getExtendedVoicemailListForClient(true);
    });
  }

  const calculateGenericCount = (status: InviteStatus, data: GenericListData[]) => {
    const inviteCount = status === InviteStatus.received || 
      (status === InviteStatus.accepted && !data.some(x => x.id === genericInviteService.inviteData?.genericId))
        ? 1 : 0;

    setGenericCount(data.filter(x => !x.closed).length + inviteCount);
  }

  useSubjectListenerEffect((status: InviteStatus) => {
    calculateGenericCount(status, genericListService.genericsData);
  }, genericInviteService.inviteStatusSubject);

  useSubjectListenerEffect((data: GenericListData[]) => {
    calculateGenericCount(genericInviteService.inviteStatus, data);
  }, genericListService.dataUpdatedSubject);

  function getQueues() {
    attachQueueMembershipChangesListners();
    
    queuesService.getQueues(currentUserId)
      .then((result: QueueUserS3DTO[]) => {
        processApiServiceQueueCallResponse(result);
      })
      .catch((exception) => {
        console.log(`Failure getting Queues ${exception}`);
      });
  }

  const setViewItemFromCache = (result: QueueUserS3DTO[]) => {
    let storedActiveViewItem = Lockr.get<ActiveMainViewItem>(StorageKeys.MainViewTab, ActiveMainViewItem.MediaView);

    if (!result.some(queue => queue.IsGeneric) && storedActiveViewItem === ActiveMainViewItem.WebChatView) {
      storedActiveViewItem = ActiveMainViewItem.MediaView;
    }

    selectMainViewItem(storedActiveViewItem);
  }

  function processApiServiceQueueCallResponse(result: QueueUserS3DTO[]) {
    listItemsArray = [];
    queuesService.queuesArray = [];

    if (result && result.length) {
      setViewItemFromCache(result);
      setQueuesButtonDisabled(false);
      queuesService.queuesArray = result;
      
      listItemsArray = queuesService.queuesArray.map((x: QueueUserS3DTO, index: number) => ({
        key: `${x.QueueRef}item`,
        content: (
          <QueueView
            selectQueue={selectQueue}
            index={index}
            queue={x}
            isReadOnly={!x.CanJoin}
          />
        ),
        className: `queue-list-item${!x.CanJoin ? ' readonly' : ''}`
      }));

      setIsAnyQueueJoinedState(isAnyQueueJoined());
      setListItems(listItemsArray);
      getExtendedVoicemailListForClient();

      socketQueue.subjectQueueChange.next(queuesService.queuesArray);
    } else {
      setIsAnyQueueJoinedState(false);
      setQueuesButtonDisabled(true);
      setListItems([]);
      setTimeout(() => {
        getQueues();
      }, 10000);
    }

    const anyChatQueue = isAnyWebChatQueueActive();
    setIsAnyChatQueueActive(anyChatQueue);

    subscriptionNewVoicemails = extendedVoicemailService.newVoicemailNotification.subscribe(() => {
      newVoicemailsNotifications.current = extendedVoicemailService.getCachedNewVoicemails();
      setAlertsVoicemailsNotificationCount(newVoicemailsNotifications.current.length);
    });
  }

  function updateQueuesFromSignalR(changedQueue: QueueUserS3DTO) {
    const queueToUpdate = queuesService.queuesArray.find((x: QueueUserS3DTO) => x.QueueRef === changedQueue.QueueRef);
    if (queueToUpdate) {
      handleQueueSupervisorChanged(changedQueue, queueToUpdate);
      handleQueueOperatorChanged(changedQueue, queueToUpdate);
      // join or can join was changed on queue from admin
      queueToUpdate.CanJoin = changedQueue.CanJoin;
      queueToUpdate.IsAttached = changedQueue.IsAttached;
      queueToUpdate.IsSupervisor = changedQueue.IsSupervisor;
      queueToUpdate.IsOperator = changedQueue.IsOperator;
    } else if (changedQueue.IsQueueAssigned) {
      // new queue was membered
      queuesService.queuesArray.push(changedQueue);
      queuesService.queueMembershipAdded.next(changedQueue);
    }
  }

  function removeQueuesFromSignalR(changedQueue: QueueUserS3DTO) {
    const queueToRemoveIndex = queuesService.queuesArray.findIndex((x: QueueUserS3DTO) => x.QueueRef === changedQueue.QueueRef);

    if (queueToRemoveIndex !== -1) {
      queuesService.queuesArray.splice(queueToRemoveIndex, 1);
      queuesService.queueMembershipRemoved.next(changedQueue);
    }
  }

  const handleQueueSupervisorChanged = (changedQueue: QueueUserS3DTO, oldQueueDetails: QueueUserS3DTO) => {
    if (changedQueue.IsSupervisor !== oldQueueDetails.IsSupervisor) {
      queuesService.queueSupervisorChanged.next(changedQueue);
    }
  }

  const handleQueueOperatorChanged = (changedQueue: QueueUserS3DTO, oldQueueDetails: QueueUserS3DTO) => {
    if (changedQueue.IsOperator !== oldQueueDetails.IsOperator) {
      queuesService.queueOperatorChanged.next(changedQueue);
    }
  }

  async function selectQueue(index: any) {
    const clickedQueue = queuesService.queuesArray[index];

    if (clickedQueue.CanJoin && !isJoinCallInProgress) {
      clickedQueue.IsAttached = !clickedQueue.IsAttached;
      listItemsArray[index].content = (
        <QueueView
          selectQueue={selectQueue}
          index={index}
          queue={queuesService.queuesArray[index]}
          key={queuesService.queuesArray[index].QueueRef + "view"}
        />
      );
      setListItems(listItemsArray);
      setQueueRef(Math.random());
      setIsAnyQueueJoinedState(isAnyQueueJoined());

      if (clickedQueue.IsGeneric) {
        await queuesService.clientGenericQueuesChanged(
          currentUserId,
          clickedQueue.QueueRef,
          clickedQueue.IsAttached
        );
      } else {
        await queuesService.clientQueuesChanged(
          currentUserId,
          clickedQueue.QueueRef,
          clickedQueue.IsAttached
        );
      }
    }
  }

  function isAnyQueueJoined() {
    return queuesService.queuesArray.some(queue => queue.IsAttached === true);
  }

  function isAnyWebChatQueueActive() {
    return queuesService.queuesArray.some(queue => queue.IsGeneric);
  }

  function selectMainViewItem(activeView: ActiveMainViewItem) {
    activeViewItemRef.current = activeView;
    setActiveViewItem(activeView);
    mainViewHelper.viewChanged.next(activeView);
    Lockr.set(StorageKeys.MainViewTab, activeView);
  }

  const getExtendedVoicemailListForClient = async (invalidateCache: boolean = false) => {
    const _ = await extendedVoicemailService.getVoicemails(currentUserId);

    newVoicemailsNotifications.current = extendedVoicemailService.getCachedNewVoicemails();
    setAlertsVoicemailsNotificationCount(newVoicemailsNotifications.current.length);
    extendedVoicemailService.newVoicemailNotification.next();

    if (invalidateCache) {
      extendedVoicemailService.subscriptionInvalidateVoicemailCache.next();
    }
  };

  const componentWillUnmount = () => {
    subscriptionQueueMembershipChanged?.unsubscribe();
    subscriptionQueueMembershipRemoved?.unsubscribe();
    subscriptionNewVoicemails?.unsubscribe();
    extendedSubscriptionQueueMembershipAdded?.unsubscribe();
    extendedSubscriptionQueueMembershipRemoved?.unsubscribe();
    extendedSubscriptionQueueSupervisorChanged?.unsubscribe();
  };

  const QueuePopupView = React.useMemo(() => {
    const trigger = <Button
      circular
      primary
      disabled={isQueuesButtonDisabled}
      icon={<AttendeeIcon />}
      title={intl.formatMessage({ id: "ManagementView.Queues" })}
      size="medium"
      className={`queues-popup-trigger${isAnyQueueJoinedState || isQueuesButtonDisabled ? '' : ' no-joined-queue'}`}
    />;

    return listItems.length > 0
      ? <Popup
          position="below"
          align="center"
          key="queue-popup"
          trigger={trigger}
          content={{
            content: <List key={queueRef} selectable items={listItems} />,
            className: "queue-list",
          }}
        />
      : trigger
  }, [listItems, isQueuesButtonDisabled, isAnyQueueJoinedState]);

  const isViewActive = (compared: ActiveMainViewItem): boolean => activeViewItem === compared;

  const buildNotificatedElement = (element: NotificatedElement) => (
    <div key={element.key} style={{ position: "relative" }}>
      {
        element.isVisible && <Button
          className="notification-count"
          circular
          content={element.value}
          size="small"
        />
      }
      <Button
        circular
        icon={element.icon}
        primary={!element.isSelected}
        tinted={element.isSelected}
        title={element.title}
        size="medium"
        onClick={() => element.onClick()}
      />
    </div>
  );

  const notificatedElements = React.useMemo<NotificatedElement[]>(() => {
    const result = [
      {
        key: ActiveMainViewItem[ActiveMainViewItem.MediaView],
        isVisible: isJoinCallInProgress,
        icon: <CallIcon />,
        value: 1,
        isSelected: isViewActive(ActiveMainViewItem.MediaView),
        title: intl.formatMessage({ id: "ManagementView.Call" }),
        onClick: () => selectMainViewItem(ActiveMainViewItem.MediaView)
      }
    ];

    if (isAnyChatQueueActive) {
      return [...result, {
        key: ActiveMainViewItem[ActiveMainViewItem.WebChatView],
        isVisible: genericCount > 0,
        icon: <ChatIcon />,
        value: genericCount,
        isSelected: isViewActive(ActiveMainViewItem.WebChatView),
        title: intl.formatMessage({ id: "ManagementView.Chat" }),
        onClick: () => selectMainViewItem(ActiveMainViewItem.WebChatView)
      }];
    }

    return result;
  }, [activeViewItem, isAnyChatQueueActive, genericCount, isJoinCallInProgress, intl]);

  return (
    <Flex
      styles={({ theme: { siteVariables } }) => ({
        backgroundColor: siteVariables.colorScheme.default.backgroundPressed,
        color: siteVariables.colorScheme.default.foregroundPressed,
        padding: "10px 20px",
      })}
      gap="gap.medium"
      space="between"
    >
      <Flex gap="gap.medium">
        {QueuePopupView}
        {notificatedElements.map(x => buildNotificatedElement(x))}
      </Flex>

      <Flex vAlign="center" gap="gap.smaller">
        <WrapUpExtendedView />
        <CurrentUserPresenceView enableKeyboardShortcut />
        {
          <div style={{ position: "relative" }}>
            {alertsVoicemailsNotificationCount > 0 && (
              <Button
                className="voicemail-notification-count"
                circular
                content={alertsVoicemailsNotificationCount}
                size="small"
              />
            )}
            <Button
              iconOnly
              text
              icon={<BellIcon size="large" />}
              title="Voice Mails"
              size="medium"
              onClick={() =>
                selectMainViewItem(ActiveMainViewItem.VoiceMailsView)
              }
              className="voice-mails-button"
            />
          </div>
        }
        <SettingsView />
      </Flex>
    </Flex>
  );
};

export default ExtendedManagementView;

export enum Controllers {
    CallCenter = 'call-center',
    Calls = 'calls',
    Presence = 'presence',
    QueueUser = 'agent-queues',
    RefreshToken = 'refresh-token',
    User = 'user',
    Tabs = 'tabs',
    Agent = 'agent',
    Supervisor = 'supervisors',
    Deployments = 'deployments',
    Voicemail = 'reporting/voicemail',
    Generics = 'generics',
    Messaging = 'messaging',
    GenericHistory = 'reporting/generics',
    GenericActions = 'generics/actions',
    Contact = 'contact'
}


export enum GenericOperation {
    None = '',
    Accept = 'accept',
    Reject = 'reject',
    Terminate = 'terminate',
    Invite = 'invite',
    Pinne = 'pinne',
    MediaSupervised = 'media/supervised',
    MediaWaiting = 'media/waiting',
    History = "history",
    TransferInitiate = 'tranfer/initiate',
    TransferToQueueInitiate = "tranfer-to-queue/initiate",
    TransferAccept = 'tranfer/accept',
    TransferReject = 'tranfer/reject'
}

export enum GenericTransferState {
    None = 0,
    Offering = 1,
    Transfered = 2,
    Fallback = 3,
}

export enum ExternalTabsOperation {
    GetExternalTabs = 'externals',
    NotifyTabsChange = 'NotifyTabsChange',
    GetCallsHistory = 'history',
}

export enum BackendNotification {
    SupervisorAgentsUpdated = 'SupervisorAgentsUpdated',
    InternalPresenceChanged = 'InternalPresenceChanged',
    QueueChanged = "QueueChangedNotification",
    UserTeamsClientChanged = "UserTeamsClientChanged",
    QueueEstimatedWaitTimesChanged = "QueueEstimatedWaitTimesChanged",
    PlayBusyTone = "PlayBusyTone"
}

export enum ServiceOperations {//#warning-js [cc4all-1102]: isn't this naming too generic?
    CallPickup = 'call-pick-up',
    CancelWrapUp = 'CancelWrapUp',
    ChangePresence = 'change-presence',
    ChangeWrapUpCode = 'change-wrap-up-code',
    CheckInClient = 'check-in-client',
    CreateUserPresenceWebhook = 'createpresencewebhook',
    DownloadVoiceMailMedia = 'DownloadVoiceMailMedia',
    GetActiveCallsBySupervisor = 'GetActiveCallsBySupervisor',//#warning-js [cc4all-1102]: not used?
    GetAgentsBySupervisorRef = 'GetAgentsBySupervisorRef',
    GetAllWrapUpCollectionTopicMap = 'get-all-wrap-up-collection-topic-map',
    GetCallCenterAssemblyVersion = 'get-call-center-assembly-version',
    GetCallCenterInfo = 'get-call-center-info',
    GetCompanyKeyBySip = 'GetCompanyKeyBySip',
    GetCompanyKeyByTenantId = 'get-company-key',
    GetCompanyKeyByUserName = 'GetCompanyKeyByUserName',
    GetClientDeploymentsByAzureTenantId = 'client-deployments',
    GetCurrentPresence = 'get-current-presence',
    GetAzureCurrentPresence = 'azure',
    GetGraphApiAccessToken = 'get-graph-api-access-token',
    GetMediaBySupervisor = 'get-waiting-media-by-agent',
    GetNotificationListForClient = 'GetNotificationListForClient',
    GetOngoingWrapUpSettings = 'GetOngoingWrapUpSettings',
    GetOperatorCallsByAgent = 'GetOperatorCallsByAgent',
    GetPreviousPresenceAndNote = 'GetPreviousPresenceAndNote',
    GetReasonsFromCache = 'get-reasons-from-cache',
    GetServerTime = 'GetServerTime',
    // NOT FOUND IN Stage3
    GetUserBySip = 'GetUserBySip',
    GetUsers = 'Users',
    GetUsersWithPresence = 'UsersWithPresence',
    GetWaitingMediaByAgent = 'get-waiting-media-by-agent',
    IsAgentInWrapUp = 'is-agent-in-wrap-up',
    // NOT FOUND IN Stage3
    IsTeamsCustomerJourneyEnabled = 'IsTeamsCustomerJourneyEnabled',
    KeepAlive = 'keep-alive',
    ProbeSpeed = 'ProbeSpeed',
    LogClientException = 'LogClientException',
    NotificationSetStatus = 'NotificationSetStatus',
    RefreshToken = 'teams-client',
    RemoveNotification = 'RemoveNotification',
    ResetClientAvailability = 'ResetClientAvailability',
    ResetUser = 'reset-user',
    SaveWrapUpHistory = 'SaveWrapUpHistory',
    SetIsTeamsClient = 'set-is-teams-client',
    SignIn = 'SignIn',
    SignInAzureAD = 'sign-in-azure-ad',
    SignInToken = 'sign-in-token',
    SignOut = 'sign-out',
    SetClientOffline = 'set-client-offline',
    SetClientOn = 'set-client-on',
    UpdateAgentScoreAndDesire = 'UpdateAgentScoreAndDesire',
    GetVariables = 'get-variables',
    MediaPickup = 'media-pick-up',
    IsNewClientEnabled = "IsNewClientEnabled",
}

export enum ContactOperations {
    DeleteContact = 'DeleteContact',
    FilterContactsViaLucene = 'filter-contacts-via-lucene',
    GetAzureActiveDirectorySettingsForContacts = 'GetAzureActiveDirectorySettingsForContacts',
    GetAzureActiveDirectorySettingsForContactsStage3 = 'get-azure-active-directory-settings-for-contacts',
    GetContacts = 'get-contacts',
    GetCurrentPresenceForSip = 'GetCurrentPresenceForSip',
    GetFavoriteContactPresence = 'GetFavoriteContactPresence',
    GetFavoriteContactsByAgentId = 'GetFavoriteContactsByAgentId',
    GetUsersByIdsAsync = 'GetUsersByIds',
    GetUsersPresence = 'not-implemented',
    HasAnyFavoriteContact = 'HasAnyFavoriteContact',
    RemoveFavoriteContact = 'RemoveFavoriteContact',
    SaveContact = 'SaveContact',
    SaveFavoriteContact = 'SaveFavoriteContact',
    UpdateContact = 'UpdateContact'
}

export enum AudioOperations {
    GetAgentCallSession = 'get-agent-call-session',
    HangupCall = 'hang-up-call',
    OnHoldCall = 'put-call-on-hold',
    RejectCall = 'reject-call',
    ResumeCall = 'resume-call',
    // Not present in Stage3, but needed for compatibility
    ParkCall = 'park-call',
    TeamsUnParkCall = 'resume-parked-call',
    StartRejoinCall = 'StartRejoinCall',
    StartSupervisorMonitoring = 'start-supervisor-monitoring',
    SwitchToMonitoringAction = 'switch-monitoring-action'
}

export enum FavoritesOperations {
    Favorites = "favorites",
    GetFavoritesCount = 'favorites/count',
    GetFavoritesConfiguration = 'favorites/configuration',
}

export enum VoicemailOperations {
    //stage 3 only
    GetVoicemails = "list",
    UpdateVoicemail = "update",
    DeleteVoicemail = "delete"
}

export enum TransferOperations {
    ColdTransfer = 'cold-transfer',
    ColdTransferIVRDestinations = 'get-transfer-destinations',
    ColdTransferDestinations = 'get-transfer-agents-destinations',
    ColdTransferIVR = 'cold-transfer-ivr',
    // NOT FOUND IN Stage3
    ToggleRecording = 'ToggleRecording',
    WarmTransferCancel = 'warm-transfer-cancel',
    WarmTransferConsultAgent = 'warm-transfer-consult-agent',
    WarmTransferConsultCaller = 'warm-transfer-consult-caller',
    WarmTransferFinalize = 'warm-transfer-finalize',
    WarmTransferStart = 'warm-transfer-start'
}

export enum SocketOperations {
    // NOT FOUND IN Stage3
    CallHistoryAddNewRecord = 'CallHistoryAddNewRecord',
    Connect = 'connect',
    Disconnect = 'disconnect',
    EjectFromMeeting = 'eject-from-meeting',
    GetCallHistoryListByAgentRef = 'GetPersonalCallHistoryListByAgentRef',
    GetClientVariableFields = 'get-client-variable-fields',
    InvokeService = 'invokeService',
    InvokeServiceResult = 'invokeServiceResult',
    JoinRoom = 'joinToken',
    LeaveRoom = 'leaveToken',
    OnInitialized = 'onInitialized',
    OnReconnect = 'onReconnect',
    ReceivedConnectedFeedback = 'receivedConnectedFeedback',
    ServerNotification = 'serverNotification',
    SignedIn = 'signedIn',
    SignedOtherBrowser = 'signedOtherBrowser',
    SignedOut = 'signedOut',
    SocketConnected = 'mySocketConnected'
}

export enum QueueOperations {
    ClientQueuesChanged = 'client-queues-changed',
    GetQueues = 'get-queues',
    GetQueuesForAgent = 'get-queues-for-agent',
    GetSupervisedQueuesInfo = 'get-supervized-queues-info',
    GetSupervisorMedia = 'get-supervisor-media'
}

export enum OutboundOperations {
    CreateCall = 'CreateCall',
    CreateOutboundCall = 'CreateOutboundCall',
    CreateOutgoingCall = 'outgoing-call',
    GetEndPointListForAgent = 'get-endpoint-list-for-agent',
    IsAgentOutboundAccessEnabled = 'is-agent-outbound-access-enabled',
    SendTone = 'send-tone'
}

export enum WebChatOperations {
    AcceptChatSession = 'AcceptChatSession',
    EndChatSession = 'EndChatSession',
    GetAgentChatSessions = 'get-agent-chat-sessions',
    GetAgentMemberedQueuesPredefinedMessaged = 'GetAgentMemberedQueuesPredefinedMessaged',
    GetChatAgents = 'GetChatAgents',
    GetChatHistory = 'GetChatHistory',
    GetChatHistoryFromChatSessionManager = 'GetChatHistoryFromChatSessionManager',
    GetQueuesAndAgentsForChatTransfer = 'GetQueuesAndAgentsForChatTransfer',
    IgnoreChatSession = 'IgnoreChatSession',
    MessageReceivedFromCustomer = 'MessageRecivedFromCustomer',
    PostChatMessageToWebClient = 'PostChatMessageToWebClient',
    RecoveryChatSession = 'RecoveryChatSession',
    SendChatDetailsToClients = 'SendChatDetailsToClients',
    SendChatDetailsToSupervisor = 'SendChatDetailsToSupervisor',
    SendChatMessage = 'SendChatMessage',
    SendChatSessionToAgent = 'SendChatSessionToAgent',
    SendChatTransferInvitationAccepted = 'SendChatTransferInvitationAccepted',
    SendChatTransferInvitationIgnored = 'SendChatTransferInvitationIgnored',
    SetAgentInAChat = 'SetAgentInAChat',
    TransferChatSessionToAgent = 'TransferChatSessionToAgent',
    TransferChatSessionToQueue = 'TransferChatSessionToQueue',
}

export enum ClientInitialLoadOperations {
    GetInitialClientSettings = 'get-initial-client-settings'
}

export enum ExtendedContactOperations {
    Create = 'create',
    Update = 'update',
    Delete = 'delete'
}

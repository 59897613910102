import { CallIcon, ChatIcon, EmailIcon } from "@fluentui/react-northstar";
import React from "react";
import { MediaType, QueueMediaStatusType } from "utils";



export const MediaTypeIcon = (props: any) => {
    const { status, mediaType, sessionId } = props;
    const waitingStatusList = [QueueMediaStatusType.Waiting, QueueMediaStatusType.Offering, QueueMediaStatusType.FallBack];

    const callIcon = () => {
        if (!waitingStatusList.includes(status)) {
            return <CallIcon className="active-call-icon" title={sessionId ?? ""} />
        }
        return <CallIcon title={sessionId ?? ""} />
    }

    return (
        <>
            {mediaType == MediaType.Call && callIcon()}
            {mediaType == MediaType.Chat && <ChatIcon className="call-type-icon-color" title={sessionId ?? ""}></ChatIcon>}
            {mediaType == MediaType.Mail && <EmailIcon className="call-type-icon-color" title={sessionId ?? ""}></EmailIcon>}
            {mediaType == MediaType.SocialMedia && <img alt='Media Icon' width="15" className="call-type-icon-color social-media-icon" title={sessionId ?? ""} />}
        </>
    )
};

export default MediaTypeIcon;

import { InvokeServiceArgs } from "services/io/interfaces";
import { Listener, listeners } from "services/io/listeners";
import { serviceCall } from "services/io/service-call";
import { logging } from "utils";
import { TabDTO } from "utils/domain/mediaView/tabDTO";
import { ExternalTabsOperation } from "utils/enums-s2";
import { ApiExternalTabsService } from ".";

export class ExternalTabsService implements ApiExternalTabsService {
    private readonly logger = logging.getLogger('ExternalTabsService');

    public readonly listenerNotifyTabsChange: Listener<any> = listeners.createListener<any>(ExternalTabsOperation.NotifyTabsChange);

    GetExternalTabs(companyRef: string, sessionId: string): Promise<TabDTO[]> {
        return new Promise((resolve, reject) => {
            const request = { CompanyKey: companyRef, SessionId: sessionId };
            const args: InvokeServiceArgs = {
                operation: ExternalTabsOperation.GetExternalTabs,
                requestData: request,
                responseHandler: {
                    success: (result) => {
                        resolve(result);
                    },
                    error: (err) => {
                        logging.errorHandler.next("ErrorMessage.GetExternalTabs");
                        this.logger.error(err);
                        reject(err);
                    }
                }
            };
            serviceCall.invokeService(args);
        })
    }
}
import { Subject } from "rxjs";
import { filter } from 'rxjs/operators';
import { Listener, listeners } from "services/io/listeners";
import { ActiveMainViewItem, ListenerOperations, StorageKeys, logging } from "utils";
import { Controllers, GenericOperation, GenericTransferState } from "utils/enums-s3";
import { GeneralHelper } from "utils/helpers";
import { GenericNotificationData } from "../Models/GenericNotificationData";
import Lockr from "lockr";
import { genericListService } from "../ConnectionListView/GenericListService";
import { GenericTransferStartResponse } from "utils/domain/transferStartResponse";

export class GenericInviteService {
    private readonly logger = logging.getLogger('GenericInviteService');
    private _isInited: boolean = false;
    private _inviteStatus: InviteStatus = InviteStatus.none;
    private _viewData: ActionDetails = {};
    private _inviteData: InviteData | undefined;
    private _currentUserId: string = '';

    private readonly submittedMessageTimeout = 3000;
    private _statusTimeout?: ReturnType<typeof setTimeout>;
    private readonly _offerGenericListener: Listener<GenericNotificationData> = listeners.createListener<GenericNotificationData>(ListenerOperations.OfferGenericNotification);
    private readonly _cancelGenericListener: Listener<GenericNotificationData> = listeners.createListener<GenericNotificationData>(ListenerOperations.CancelGenericNotification);

    private readonly states = {
        offering: "ChatListView.Offering",
        ongoing: "ChatListView.Ongoing",
        offeringTransfer: "ChatListView.OfferingTransfer",
        transferSubmitted: "ChatListView.TransferSubmited"
    };

    public readonly dataUpdatedSubject: Subject<ActionDetails> = new Subject();
    public readonly inviteStatusSubject: Subject<InviteStatus> = new Subject();

    public get inviteStatus(): InviteStatus {
        return this._inviteStatus;
    }

    public get inviteViewData(): ActionDetails {
        return { ...this._viewData };
    }

    public get inviteData(): InviteData | undefined {
        return !!this._inviteData ? { ...this._inviteData } : undefined;
    }

    public init() {
        if (this._isInited) {
            this.notifyDataChanged();
            this.notifyInviteStatusChanged();
            return;
        }

        this._isInited = true;
        this._currentUserId = Lockr.get<string>(StorageKeys.UserObjectId);
        this.registerListeners();

        GeneralHelper.invokeServiceCall('', GenericOperation.Invite, this.logger, Controllers.Generics)
            .then((result: any) => {
                if (!result) {
                    return
                }

                let genericNotificationData = {
                    AgentId: Lockr.get<string>(StorageKeys.UserObjectId),
                    GenericId: result.id,
                    QueueId: result.queueId,
                    QueueName: result.queueName,
                    Name: result.name,
                    Description: result.description,
                    ExternalUrl: result.externalUrl,
                    LastHandledTime: result.lastHandledTime,
                    IsIframe: result.isIframe,
                    TransferState: result.transferState
                }

                this.updateOngoingInviteData(genericNotificationData);
            })
            .catch(() => {
                this._isInited = false;
            });
    }

    public hideInviteIfExists() {
        if (!!this._viewData.isVisible) {
            this._viewData.isVisible = false;
            this.notifyDataChanged();
        }
    }

    public showActiveGeneric(data: GenericInviteData) {
        if (Lockr.get<ActiveMainViewItem>(StorageKeys.MainViewTab) !== ActiveMainViewItem.WebChatView ||
            (!!this._viewData.isVisible && this._inviteStatus == InviteStatus.received)) {
            return;
        }

        this._inviteData = data;
        this._viewData = {
            title: data.name,
            description: this.truncate(data.description),
            queue: data.queueName,
            state: this.states.ongoing,
            isAccepted: true,
            isVisible: true,
            lastHandledTime: data.lastHandledTime,
            isIframe: data.isIframe,
        };
        this.notifyDataChanged();
        this.updateInviteStatus(InviteStatus.none);
    }

    public rejectInvite() {
        this._viewData.isVisible = false;
        this.notifyDataChanged();
        this.updateInviteStatus(InviteStatus.rejected);

        this.sendRequest(GenericOperation.Reject)
            .catch(() => {
                this._viewData.isVisible = true;
                this.updateInviteStatus(InviteStatus.received);
                this.notifyDataChanged();
            });
    }

    public acceptInvite() {
        this._viewData.isAccepted = true;
        this._viewData.lastHandledTime = new Date();
        this._viewData.state = this.states.ongoing;
        this.updateInviteStatus(InviteStatus.accepted);
        this.notifyDataChanged();

        if (!!this._inviteData) {
            genericListService.genericInviteAccepted({
                id: this._inviteData.genericId,
                isIframe: this._inviteData.isIframe,
                externalUrl: this._inviteData.externalUrl
            });
        }

        this.sendRequest(GenericOperation.Accept)
            .catch(() => {
                this._viewData.isAccepted = false;
                this._viewData.state = this.states.offering;
                this.updateInviteStatus(InviteStatus.received);
                this.notifyDataChanged();
            });
    }

    public terminateGeneric() {
        this._viewData.isVisible = false;
        this.notifyDataChanged();
        this.updateInviteStatus(InviteStatus.terminated);
        genericListService.genericTerminated();

        this.sendRequest(GenericOperation.Terminate)
            .catch(() => {
                this._viewData.isVisible = true;
                this.notifyDataChanged();
                this.updateInviteStatus(InviteStatus.accepted);
            });
    }

    public transferGeneric(transferTarget?: string) {
        this.notifyDataChanged();
        this.updateInviteStatus(InviteStatus.terminated);
        genericListService.genericTerminated();

        this.sendRequest(GenericOperation.TransferInitiate, {
            transferTarget
        })
            .then((result) => {
                this.handleTransferGenericResponse(result);

            })
            .catch(() => {
                this._viewData.isVisible = true;
                this.notifyDataChanged();
                this.updateInviteStatus(InviteStatus.accepted);
            });
    }

    public transferGenericToQueue(transferTarget?: string) {
        this._viewData.isVisible = false;
        this.notifyDataChanged();
        this.updateInviteStatus(InviteStatus.terminated);
        genericListService.genericTerminated();

        this.sendRequest(GenericOperation.TransferToQueueInitiate, {
            transferTarget
        })
            .then(() => {
                this._viewData = {
                    state: this.states.transferSubmitted,
                    isVisible: true,
                    isAccepted: false,
                    transferSubmitted: true,
                };

                this.notifyDataChanged();

                this.setSubmittedStatusTimeout();

                genericListService.transferInviteInitialized(this._inviteData?.genericId)
            })
            .catch(() => {
                this._viewData.isVisible = true;
                this.notifyDataChanged();
                this.updateInviteStatus(InviteStatus.accepted);
            });
    }

    public acceptTransferedGeneric(transferTarget?: string) {
        this._viewData.isAccepted = true;
        this._viewData.lastHandledTime = new Date();
        this._viewData.state = this.states.ongoing;
        this._viewData.transferState = GenericTransferState.Transfered;
        this.updateInviteStatus(InviteStatus.accepted);
        this.notifyDataChanged();

        if (!!this._inviteData) {
            genericListService.genericInviteAccepted({
                id: this._inviteData.genericId,
                isIframe: this._inviteData.isIframe,
                externalUrl: this._inviteData.externalUrl
            });
        }

        this.sendRequest(GenericOperation.TransferAccept, {
            transferTarget
        })
            .catch(() => {
                this._viewData.isAccepted = false;
                this._viewData.state = this.states.offeringTransfer;
                this.updateInviteStatus(InviteStatus.received);
                this.notifyDataChanged();
            });
    }

    public rejectTransferedGeneric(transferTarget?: string) {
        this._viewData.isVisible = false;
        this.notifyDataChanged();
        this.updateInviteStatus(InviteStatus.rejected);

        this.sendRequest(GenericOperation.TransferReject, {
            transferTarget
        })
            .catch(() => {
                this._viewData.isVisible = true;
                this.updateInviteStatus(InviteStatus.received);
                this.notifyDataChanged();
            });
    }

    private handleTransferGenericResponse(response: GenericTransferStartResponse) {
        if (response.transferStarted) {
            this._viewData = {
                state: this.states.transferSubmitted,
                isVisible: true,
                isAccepted: false,
                transferSubmitted: true,
            };

            this.notifyDataChanged();

            this.setSubmittedStatusTimeout();

            genericListService.transferInviteInitialized(this._inviteData?.genericId)
        }

        if (response.contactNotFound) {
            logging.errorHandler.next("ErrorMessage.Transfer.TransferContactNotFound");
            return;
        }

        if (response.contactUnavailable) {
            logging.errorHandler.next("ErrorMessage.Transfer.TransferContactUnavailable");
        }
    }

    private readonly setSubmittedStatusTimeout = () => {
        if (this._statusTimeout) {
            clearTimeout(this._statusTimeout);
        }

        this._statusTimeout = setTimeout(() => {
            if (this._viewData.state === this.states.transferSubmitted) {
                this._viewData.isVisible = false;
                this.notifyDataChanged();
            }
        }, this.submittedMessageTimeout);
    }

    private notifyDataChanged() {
        this.dataUpdatedSubject.next(this.inviteViewData);
    }

    private notifyInviteStatusChanged() {
        this.inviteStatusSubject.next(this._inviteStatus);
    }

    private sendRequest(action: string, payload?: any): Promise<any> {
        const data = { messagingId: this._inviteData?.genericId, ...payload };

        return GeneralHelper.invokeServiceCall(data, action, this.logger, Controllers.GenericActions);
    }

    private processInviteReceiving(data: InviteData, isAccepted: boolean, state: string) {
        this._inviteData = data;
        this._viewData = {
            title: data.name,
            description: this.truncate(data.description),
            queue: data.queueName,
            state: state,
            isAccepted: isAccepted,
            isVisible: true,
            isIframe: data.isIframe,
            transferState: data.transferState
        };

        this.notifyDataChanged();
    }

    private updateInviteStatus(status: InviteStatus) {
        this._inviteStatus = status;
        this.notifyInviteStatusChanged();
    }

    private updateOngoingInviteData(result: any) {
        const data: InviteData = {
            genericId: result.GenericId ? result.GenericId : result.Id,
            queueId: result.QueueId,
            queueName: result.QueueName,
            name: result.Name,
            description: result.Description,
            externalUrl: result.ExternalUrl,
            isIframe: result.IsIframe,
            transferState: result.TransferState
        };

        this._inviteStatus = InviteStatus.received;
        this.notifyInviteStatusChanged();
        const offeringState = data.transferState === GenericTransferState.Offering ? this.states.offeringTransfer : this.states.offering;
        this.processInviteReceiving(data, false, offeringState);
    }

    private registerListeners() {
        this._offerGenericListener.received
            .pipe(filter((x) => x.AgentId === this._currentUserId))
            .subscribe((notification: GenericNotificationData) => {
                this.updateOngoingInviteData(notification);
            });
        this._cancelGenericListener.received
            .pipe(filter((x) => x.AgentId === this._currentUserId))
            .subscribe((_) => {
                this._inviteStatus = InviteStatus.canceled;
                this.hideInviteIfExists();
                this.notifyInviteStatusChanged();
            });
    }

    private truncate(value: string, length: number = 20): string {
        return value?.length > length
            ? value.slice(0, length) + '...'
            : value || '';
    }
}

export interface ActionDetails {
    title?: string;
    description?: string;
    queue?: string;
    state?: string;
    lastHandledTime?: Date;
    isAccepted?: boolean;
    isVisible?: boolean;
    isIframe?: boolean;
    transferState?: GenericTransferState;
    transferSubmitted?: boolean;
}

export enum InviteStatus {
    none,
    received,
    accepted,
    rejected,
    canceled,
    terminated
}

interface InviteData {
    genericId: string;
    queueId: string;
    queueName: string;
    name: string;
    description: string;
    externalUrl: string;
    isIframe: boolean;
    transferState: GenericTransferState;
}

interface GenericInviteData extends InviteData {
    lastHandledTime: Date;
}

export const genericInviteService = new GenericInviteService();
import React, { useState, useRef } from 'react';
import { AcceptIcon, Button, CloseIcon, Flex, Text } from '@fluentui/react-northstar';
import Lockr from 'lockr';
import { socketSocialMedia } from 'services/social-media';
import { SocialMediaSessionDTO } from 'utils/domain/socialMedia/socialMediaSessionDTO';
import { useIntl } from 'react-intl';
import { Subscription } from 'rxjs';
import { ActiveMainViewItem, HeaderBarDisplayedMessageType, SocialMediaStatusType, StorageKeys } from 'utils';
import './socialMediaView.scss';
import { WebhookSettingDTO } from 'utils/domain/socialMedia/webhookSettingDTO';
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import { EnvHelper, GeneralHelper } from 'utils/helpers';

export const SocialMediaView = () => {
    const intl = useIntl();
    let subscriptionSendSocialMediaDetailsToClientChangeNotification: Subscription | null = null;
    let subscriptionViewChanged: Subscription | null = null;
    let subscriptionShowSocialMediaSwitchBtn: Subscription | null = null;
    let subscriptionHeaderBarMessageChanged: Subscription | null = null;

    const [isSocialMediaVisible, setIsSocialMediaVisible] = useState(false);
    const [isSocialMediaMessageSelected, setIsSocialMediaMessageSelected] = useState(true);
    const [newSocilaMediaReceived, setNewSocilaMediaReceived] = useState(false);
    const [isCMMedia, setIsCMMedia] = useState(false);
    const [acceptedSocialMediaReceived, setAcceptedSocialMediaReceived] = useState(false);
    const [cmSocialMediaCaller, setCmSocialMediaCaller] = useState('');

    const socialMediaSessionId = useRef('');
    const cmMediaAcceptedClicked = useRef(false);
    const isVoiceMailButtonVisible = useRef(false);

    const visibleOperations = [SocialMediaStatusType.Accepted, SocialMediaStatusType.Ringing];

    React.useEffect(() => {
        initialize();
        return () => componentWillUnmount();
    }, []);

    const componentWillUnmount = () => {
        subscriptionSendSocialMediaDetailsToClientChangeNotification?.unsubscribe();
        subscriptionViewChanged?.unsubscribe();
        subscriptionShowSocialMediaSwitchBtn?.unsubscribe();
        subscriptionHeaderBarMessageChanged?.unsubscribe();
    }

    const initialize = () => {
        // REMOVE when SocialMedia is implemented in Stage3
        if (EnvHelper.isStage3()) {
            return;
        }
        GeneralHelper.logCox(`in SocialMediaView.tsx, in initialize`);

        getActiveSocialMediaSessions();
        getRingingSocialMediaSessions();
        subscriptionSendSocialMediaDetailsToClientChangeNotification?.unsubscribe();
        subscriptionSendSocialMediaDetailsToClientChangeNotification =
            socketSocialMedia.sendSocialMediaDetailsToClientChangeNotification.subscribe((obj: SocialMediaSessionDTO) => {
                const sip = Lockr.get<string>(StorageKeys.SIP);

                if (obj.AgentSip?.toLocaleLowerCase() !== sip?.toLocaleLowerCase()) {
                    return;
                }

                if (visibleOperations.includes(obj.StatusType)) {
                    socketSocialMedia.isInASocialMedia.next(true);
                    handleSocialMediaRinging(obj);
                    socialMediaAccepted(obj);

                } else {
                    clearOngoingSocialMedia();
                }
            });

        subscriptionViewChanged?.unsubscribe();
        subscriptionViewChanged = mainViewHelper.viewChanged.subscribe((activeViewItem: ActiveMainViewItem) => {
            if (isVoiceMailButtonVisible.current) {
                setIsSocialMediaMessageSelected(activeViewItem === ActiveMainViewItem.SocialMedia);
                return;
            }

            setIsSocialMediaMessageSelected(activeViewItem === ActiveMainViewItem.SocialMedia || activeViewItem === ActiveMainViewItem.MediaView);
        });

        subscriptionShowSocialMediaSwitchBtn?.unsubscribe();
        subscriptionShowSocialMediaSwitchBtn = mainViewHelper.showSocialMediaSwitchBtn.subscribe((value: boolean) => {
            isVoiceMailButtonVisible.current = value;
        });

        subscriptionHeaderBarMessageChanged?.unsubscribe();
        subscriptionHeaderBarMessageChanged = mainViewHelper.showHeaderBarMessage.subscribe((messageType: HeaderBarDisplayedMessageType) => {
            setIsSocialMediaMessageSelected(messageType === HeaderBarDisplayedMessageType.SocialMedia)
        });
    }

    const handleSocialMediaRinging = (obj: SocialMediaSessionDTO) => {
        if (obj.StatusType === SocialMediaStatusType.Ringing) {
            setNewSocilaMediaReceived(true);
            setAcceptedSocialMediaReceived(false);
            setIsSocialMediaVisible(true);
            socialMediaSessionId.current = obj.SessionId;

            if (obj.CMMessages?.length) {
                const cmName = obj.CMMessages[0].FromName || '';
                const cmNumber = obj.CMMessages[0].FromNumber || '';
                let cmCaller = cmName || cmNumber;

                if (cmName && cmNumber) {
                    cmCaller = `${cmCaller}(${cmNumber})`;
                }

                setCmSocialMediaCaller(cmCaller);
                setIsCMMedia(true);
            } else {
                setCmSocialMediaCaller('');
                setIsCMMedia(false);
            }
            socketSocialMedia.isInCmMedia.next(false);
        } else {
            setNewSocilaMediaReceived(false);
        }
    }

    const getWebHookAdress = (obj: SocialMediaSessionDTO, settings: WebhookSettingDTO) => {
        let address = settings.WebhookAddress;
        let index = 0;

        for (const property in obj.SocialMedia.WebhookParameters) {
            address = address.replace(`${index}`, `${obj.SocialMedia.WebhookParameters[property]}`);
            index++;
        }

        address = address.replaceAll('{', '');
        address = address.replaceAll('}', '');

        return address;
    }

    const openSocialMediaPage = (obj: SocialMediaSessionDTO, settings: WebhookSettingDTO) => {
        if (settings === null || obj.CMMessages) {
            return;
        }

        const address = getWebHookAdress(obj, settings);

        if (address !== '') {
            window.open(address, "Media", "height=600,width=600");
        }
    }

    const getWebhookSettingsAndOpenPage = (obj: SocialMediaSessionDTO) => {
        socketSocialMedia.GetWebHookSetting(obj.SocialMedia.ProviderName).then((settings: WebhookSettingDTO) => {
            handleActiveSocialMedia(obj);
            openSocialMediaPage(obj, settings);
        });
    }

    const socialMediaAccepted = (obj: SocialMediaSessionDTO) => {
        if (obj.StatusType === SocialMediaStatusType.Accepted) {
            getWebhookSettingsAndOpenPage(obj);
        } else {
            setAcceptedSocialMediaReceived(false);
        }
    }

    const handleActiveSocialMedia = (obj: SocialMediaSessionDTO) => {
        setNewSocilaMediaReceived(false);
        setAcceptedSocialMediaReceived(true);
        setIsSocialMediaVisible(true);
        socialMediaSessionId.current = obj.SessionId;
        socketSocialMedia.isInASocialMedia.next(true);

        if (obj.CMMessages?.length) {
            const cmName = obj.CMMessages[0].FromName || '';
            const cmNumber = obj.CMMessages[0].FromNumber || '';
            let cmCaller = cmName || cmNumber;

            if (cmName && cmNumber) {
                cmCaller = `${cmCaller}(${cmNumber})`;
            }

            setCmSocialMediaCaller(cmCaller);
            setIsCMMedia(true);
            socketSocialMedia.isInCmMedia.next(true);
            socketSocialMedia.currentCmSessionId.next(obj.SessionId);

            if (cmMediaAcceptedClicked.current) {
                mainViewHelper.viewChanged.next(ActiveMainViewItem.CmMedia);
            }
        } else {
            setCmSocialMediaCaller('');
            setIsCMMedia(false);
            socketSocialMedia.isInCmMedia.next(false);
        }
    }

    const clearOngoingSocialMedia = () => {
        setIsSocialMediaVisible(false);
        setAcceptedSocialMediaReceived(false);
        setNewSocilaMediaReceived(false);
        setCmSocialMediaCaller('');
        setIsCMMedia(false);
        socialMediaSessionId.current = '';
        socketSocialMedia.isInASocialMedia.next(false);
        socketSocialMedia.isInCmMedia.next(false);
    }

    const getActiveSocialMediaSessions = () => {
        const sip = Lockr.get<string>(StorageKeys.SIP);

        socketSocialMedia.GetActiveSocialMediaSessions(sip).then((sessions: SocialMediaSessionDTO[]) => {
            if (sessions && sessions.length) {
                const activeSocialMedia = sessions[0];
                handleActiveSocialMedia(activeSocialMedia);
                socketSocialMedia.currentCmSessionId.next(activeSocialMedia.SessionId);
            } else {
                socketSocialMedia.currentCmSessionId.next('');
            }
        });
    }

    const getRingingSocialMediaSessions = () => {
        const sip = Lockr.get<string>(StorageKeys.SIP);

        socketSocialMedia.GetRingingSocialMediaSessions(sip).then((sessions) => {
            if (sessions && sessions.length) {
                const ringingSocialMedia = sessions[0];
                socketSocialMedia.isInASocialMedia.next(true);
                handleSocialMediaRinging(ringingSocialMedia);
            }
        });
    }

    const acceptSocialMediaCall = () => {
        const socialMediaRequest = GeneralHelper.getSocialMediaRequestDto(socialMediaSessionId.current);

        socketSocialMedia.SocialMediaAccept(socialMediaRequest);

        if (isCMMedia) {
            cmMediaAcceptedClicked.current = true;
        }
    }

    const ignoreSocialMediaCall = () => {
        const socialMediaRequest = GeneralHelper.getSocialMediaRequestDto(socialMediaSessionId.current);

        socketSocialMedia.SocialMediaIgnore(socialMediaRequest);
    }

    const terminateSocialMediaCall = () => {
        const socialMediaRequest = GeneralHelper.getSocialMediaRequestDto(socialMediaSessionId.current);

        socketSocialMedia.SocialMediaTerminate(socialMediaRequest);
    }

    return (
        <>{
            isSocialMediaVisible && isSocialMediaMessageSelected && <>
                <Flex vAlign="center" gap="gap.large" className="social-media-message">
                    <>
                        {
                            newSocilaMediaReceived && <>
                                {isCMMedia ?
                                    <Text content={intl.formatMessage({ id: "SocialMedia.AcceptNewCMMedia" }).replace("{0}", cmSocialMediaCaller)}></Text> :
                                    <Text content={intl.formatMessage({ id: "SocialMedia.AcceptNewSocialMedia" })}></Text>
                                }
                            </>}
                        {
                            acceptedSocialMediaReceived && <>
                                {isCMMedia ?
                                    <Text content={intl.formatMessage({ id: "SocialMedia.OngoingCmMedia" }).replace("{0}", cmSocialMediaCaller)}></Text> :
                                    <Text content={intl.formatMessage({ id: "SocialMedia.OngoingSocialMedia" })}></Text>
                                }
                            </>
                        }
                    </>
                </Flex>
                <Flex className="social-media-handle">
                    {newSocilaMediaReceived && < >
                        <Button
                            className="social-media-btn accept"
                            icon={<AcceptIcon size="large" title={intl.formatMessage({ id: "SocialMedia.Accept" })} />}
                            iconOnly
                            text
                            title={intl.formatMessage({ id: "SocialMedia.Accept" })}
                            onClick={acceptSocialMediaCall}
                        >
                        </Button>
                        <Button
                            className="social-media-btn ignore"
                            icon={<CloseIcon size="large" title={intl.formatMessage({ id: "SocialMedia.Ignore" })} />}
                            iconOnly
                            text
                            title={intl.formatMessage({ id: "SocialMedia.Ignore" })}
                            onClick={ignoreSocialMediaCall}
                        >
                        </Button>
                    </>}
                    {
                        acceptedSocialMediaReceived && <Button
                            className="social-media-btn ignore"
                            icon={<CloseIcon size="large" title={intl.formatMessage({ id: "SocialMedia.Terminate" })} />}
                            iconOnly
                            text
                            title={intl.formatMessage({ id: "SocialMedia.Terminate" })}
                            onClick={terminateSocialMediaCall}
                        >
                        </Button>
                    }
                </Flex>
            </>
        }
        </>

    )
};

export default SocialMediaView;

import { ResponseWrapper } from 'utils';
import { PresenceService } from './socket-presence';

export interface ApiPresence {
    // presenceChange: Subject<{ personId: string, presenceState: any }>;
    // bindPresenceListenerForPerson(person: Person): void;
    // unbindPresenceListenerForPerson(person: Person): void;
    // setPresence(person: Person, status: any): void;
    GetWrapUpCodes(CompanyRef: number): Promise<ResponseWrapper>;
}

export const socketPresence = new PresenceService();
import Lockr from 'lockr';
import { useIntl } from "react-intl";
import { Alert, Avatar, Button, CloseIcon, Divider, EditIcon, Flex, Header, List, Loader, Text, Tooltip } from '@fluentui/react-northstar';
import React, { useState } from 'react';
import { customerJourneyManager } from 'services/io/customer-journey';
import { StorageKeys } from 'utils';
import { CallSessionDTO } from 'utils/domain/callSessionDTO';
import { CustomerDTO, CustomerIdentificationDTO, IJourneyCustomerSessionDTO } from 'utils/domain/customerJourneyModels';

export interface IdentificationProps {
    CurrentConversation?: IJourneyCustomerSessionDTO,
    IsIdentifyingCustomer: boolean,
    UpdateIsIdentifyingCustomer: (arg: boolean) => void,
    UpdateIsCreatingCustomer: (arg?: CustomerDTO | undefined) => void,
    IdentifyCallSession?: CallSessionDTO,
    IdentificationCompleteMethod?: (arg1: CallSessionDTO, arg2: CustomerDTO[] | undefined) => void
}

export const CustomerIdentificationView: React.FC<IdentificationProps> = ({ IdentifyCallSession, CurrentConversation, IsIdentifyingCustomer, UpdateIsIdentifyingCustomer, UpdateIsCreatingCustomer, IdentificationCompleteMethod }) => {
    const intl = useIntl();

    const [loading, setLoading] = useState(false);

    const [identifyResult, setIdentifyResult] = useState<CustomerDTO[]>([] as CustomerDTO[]);
    const [identifyResultList, setIdentifyResultList] = useState([] as any);

    React.useEffect(() => {
        console.log("Received new conversation in identification View");
        console.log(CurrentConversation);
    }, [CurrentConversation]);

    React.useEffect(() => {
        console.log("received new callsession...");
        identifyCustomer(true, IdentifyCallSession);
    }, [IdentifyCallSession]);

    const identifyCustomer = (identifying: boolean, callSession?: CallSessionDTO) => {
        console.log("Received call to identify customer..");
        console.log(callSession);
        console.log(identifying);

        if (callSession === undefined || callSession.CallerUri === undefined || callSession.CallerUri === "" || identifying === false) {
            console.log("Current convo === undefined or identifying state === false when trying to identify..");
            updateIsIdentifyingCustomer(false);
            return;
        }

        if (loading) {
            console.log("Identification in progress, cannot identify more than once at the same time..");
            return;
        }

        updateIsIdentifyingCustomer(true);
        setLoading(true);

        if (callSession === undefined) {
            return;
        }
        if (callSession.SessionId === undefined || callSession.SessionId === "") {
            return;
        }

        customerJourneyManager.identifyCaller({ ani: callSession.CallerUri }).then((response: CustomerIdentificationDTO) => {
            if (response.TypeFound === "none") {
                setLoading(false);
                setIdentifyResult([]);
            } else if (response.TypeFound === 'single' || response.TypeFound === 'multiple') {
                customerJourneyManager.getCustomerById({ customerIds: response.customerIds }).then((customerResponse: CustomerDTO[]) => {
                    updateIdentifyResult(customerResponse);
                    setLoading(false);
                }).catch(err => {
                    console.log(err.message);
                    setLoading(false);
                });
            }
            if (IdentificationCompleteMethod !== undefined) {
                IdentificationCompleteMethod(callSession, identifyResult);
            }

        }).catch(err => {
            console.log(err.message);
        });
    };

    const updateIsIdentifyingCustomer = (isIdentifying: boolean) => {
        UpdateIsIdentifyingCustomer(isIdentifying);
    }

    const createCustomerNoResult = () => {
        const companyRef = Lockr.get<number>(StorageKeys.CompanyId);
        const callerUri = IdentifyCallSession?.CallerUri;
        const startIndex = callerUri?.indexOf('+');
        const endIndex = callerUri?.indexOf('@');
        const trimmedAni: string | undefined = (startIndex && endIndex) ? callerUri?.substring(startIndex, endIndex) : '';
        const newCustomer = new CustomerDTO({CompanyRef: companyRef, PhoneNumbers: [{IsActive: true, companyRef: companyRef, PhoneNumber: trimmedAni}]});
        UpdateIsCreatingCustomer(newCustomer);
        updateIsIdentifyingCustomer(false);

    }

    const updateIdentifyResult = (result: CustomerDTO[]) => {
        var formattedResult: CustomerDTO[] = []
        result.forEach((element, index) => {
            formattedResult.push(new CustomerDTO(element));
        });
        setIdentifyResult(formattedResult);
        mapIdentifyResultList(formattedResult);
    };

    const mapIdentifyResultList = (result: CustomerDTO[]) => {
        var items: any[] = [];

        if (result.length < 1) {
            setIdentifyResultList(items);
            return;
        }

        result.forEach((element: CustomerDTO, index: any) => {
            items.push(getIdentifyResultItem(element));
        });

        setIdentifyResultList(items);
    }

    const getIdentifyResultItem = (customerDto: CustomerDTO) => {
        return({
            key: customerDto.ID,
            endMedia: <Tooltip
            trigger={<Button onClick={(event) => console.log((event.target as HTMLInputElement).parentElement)} circular icon={<EditIcon />} size="small" iconOnly className="customer-info-button"/>}
            content={intl.formatMessage({ id: "CustomerJourney.CustomerSearchView.SelectResult"})} />,
            content: 
            <Flex space="between" >
                <Flex gap="gap.small">
                    <Avatar
                        name={`${customerDto.Name} ${customerDto.Surname}`}
                    />
                    <Flex column>
                    <Text content={customerDto.Name} weight="bold" />
                    <Text content={customerDto.Surname} size="small" />
                    </Flex>
                </Flex>
            </Flex>,
            onClick: () => {
                updateIsIdentifyingCustomer(false);
                (IdentifyCallSession !== undefined && customerJourneyManager
                    .postCustomerSession(({ID: -1, JourneyCustomerRef: customerDto.ID, CompanyRef: customerDto.CompanyRef, SessionID: IdentifyCallSession.SessionId, ConversationType: "Call"} as IJourneyCustomerSessionDTO))
                    .then((response: IJourneyCustomerSessionDTO) => {
                        customerJourneyManager.currentConversation.next(response);
                    }).catch(err => {
                        console.log(err.message);
                    }).finally(() => {
                        customerJourneyManager.currentCustomer.next(customerDto);
                    }));
            }
        });
    };

    const noIdentificationAlert = () => {
        return <Flex.Item grow><Alert dismissible danger 
        content={
            <Flex column vAlign="center" styles={{alignContent: "center", margin: "1%"}}>
                <Text content={intl.formatMessage({ id: "CustomerJourney.CustomerIdentificationView.NoResult"})} weight="bold" />
                <Button primary content={intl.formatMessage({ id: "CustomerJourney.CustomerEditView.CreateCustomer"})} onClick={() =>createCustomerNoResult()} styles={{alignSelf: "center", marginTop: "0.5%"}} />
            </Flex>
            } styles={{alignSelf: "center", width: "80%", textAlign: "center"}} />
            </Flex.Item>
     }

    return(
        <Flex column
            styles={({ theme: {siteVariables} }) => ({
                color: siteVariables.colorScheme.default.foreground,
                backgroundColor: siteVariables.colorScheme.default.background
            })}
        >
            {IsIdentifyingCustomer && <>
                <Flex gap="gap.small" padding="padding.medium">
                    <Flex.Item grow>
                        <Flex space="between">
                            <Header as="h2" content={intl.formatMessage({ id: "CustomerJourney.CustomerIdentificationView.Title"})} styles={({ theme: {siteVariables} }) => ({
                                color: siteVariables.colorScheme.brand.foreground,
                                margin: "0px"
                            })}/>
                            <Flex.Item push grow>
                                <Tooltip
                                trigger={<Button text icon={<CloseIcon size="large" />} iconOnly onClick={() => updateIsIdentifyingCustomer(false)} />}
                                content={intl.formatMessage({ id: "CustomerJourney.CustomerEditView.CloseIdentificationView"})} />
                            </Flex.Item>
                        </Flex>
                    </Flex.Item>
                </Flex>
                <Divider size={3}/>
                {loading === true && <Loader label={intl.formatMessage({ id: "CustomerJourney.CustomerEditView.InProgress"})} />}
                <Flex gap="gap.small" padding="padding.medium">
                    {((identifyResult.length < 1) && loading === false 
                            ) && noIdentificationAlert()}
                    {(identifyResult.length > 0 && !loading) &&
                        <Flex.Item grow>
                            <List selectable items={identifyResultList} />
                        </Flex.Item>
                    }

                </Flex>
            </>}
        </Flex>
    )
};

export default CustomerIdentificationView;
import * as React from 'react';
import { Table, Flex, Text, TriangleUpIcon, TriangleDownIcon } from '@fluentui/react-northstar';
import { gridNestedBehavior } from '@fluentui/accessibility';
import "./AdvancedTable.scss"

enum SortableColumns {
  SupervisorViewAgentColumn = 'SupervisorViewAgentColumn',
  SupervisorStatusTimerColumn = 'SupervisorStatusTimerColumnS3',
  SupervisorViewScoreColumn = 'SupervisorViewScoreColumn',
  SupervisorViewReasonColumn = 'SupervisorViewReasonColumn',
  SupervisorViewHasClientColumn = 'SupervisorViewHasClientColumn',
  SupervisorViewSkillColumn = 'SupervisorViewSkillColumn',
  SupervisorViewDesireColumn = 'SupervisorViewDesireColumn',
  SupervisorViewIsJoinedColumn = 'SupervisorViewIsJoinedColumn',
  ContactsDisplayName = 'ContactsDisplayName',
  ContactsMail = 'ContactsMail',
  ContactsJobTitle = 'ContactsJobTitle',
  ContactsDepartment = 'ContactsDepartment',
  ContactsCity = 'ContactsCity',
  ContactsOfficeLocation = 'ContactsOfficeLocation',
  ContactsCountry = 'ContactsCountry',
  ContactsState = 'ContactsState',
  ContactsPostalCode = 'ContactsPostalCode',
}

const AdvancedTable = (props: any) => {
  const { rows, columns, label, sortHandler, className } = props;

  const [sortColumn, setSortColumn] = React.useState('');
  const [sortDirection, setSortDirection] = React.useState(0);

  let sortedRows = rows;

  if (!sortHandler) {
    if (sortColumn && sortDirection) {
      const sortColumnIndex = columns.findIndex((column: any) => column.name === sortColumn);
      const comparator = columns[sortColumnIndex].cellComparator;
      if (comparator) {
        sortedRows = rows.sort((r1: any, r2: any) => {
          const cell1 = r1.items[sortColumnIndex];
          const cell2 = r2.items[sortColumnIndex];
          return comparator(cell1.content, cell2.content) * sortDirection;
        });
      }
    }
  }

  const sortColumnHeader = (localClassName: any, name: any, title: any, order: any, onOrderChange: any) => ({
    content: getColumnHeaderContent(name, title, order),
    className: localClassName ? localClassName : "",
    key: title,
    onClick: () => onOrderChange(order === 0 ? 1 : -order),
    'aria-sort': order !== 0 ? getOrderByDirection(order) : undefined,
  });

  const getColumnHeaderContent = (name: any, title: any, order: any) => {
    const triangleIcon = order === 1 ? <TriangleUpIcon /> : <TriangleDownIcon />;
    
    return <Flex styles={() => ({
      cursor: Object.values(SortableColumns).includes(name) ? 'pointer' : 'default'
    })} gap="gap.small">
      <Text content={title} />
      {order !== 0 ? triangleIcon : ''}
    </Flex>
  }

  const getOrderByDirection = (order: any) => order > 0 ? 'ascending' : 'descending'

  const header = {
    key: 'header',
    items: columns.map((c: any) =>
      sortColumnHeader(c.className, c.name, c.title, c.name === sortColumn ? sortDirection : 0, (newSortDirection: any) => {
        if (Object.values(SortableColumns).includes(c.name)) {
          setSortColumn(c.name);
          setSortDirection(newSortDirection);
          if (sortHandler) {
            sortHandler(c.name, newSortDirection);
          }
        }
        // Use react-aria-live or similar component to announce change the order
        //document.getElementById('ariaLive').innerText = newSortDirection > 0 ? 'sorted ascending' : 'sorted descending';
      }),
    ),
  };

  return (
    <Table
      variables={{ cellContentOverflow: 'none' }}
      styles={({ theme: { siteVariables } }) => ({ margin: '10px 0 20px' })}
      header={header}
      rows={sortedRows}
      aria-label={label}
      className={className ? className : ""}
      accessibility={gridNestedBehavior}
    />
  );
};

export default AdvancedTable;

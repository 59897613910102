import React, { useState, useRef } from 'react';
import { Avatar, Flex, Text } from '@fluentui/react-northstar';
import './profilePictureView.scss';
import { FavoriteContactType } from 'utils/domain/favoriteContactDTO';
import { EnvHelper } from 'utils/helpers';
import { socketGraphApi } from 'services/graphApi';

export const ProfilePictureView = (props: any) => {
    const { getAvatarInitials, status, azureID, displayName, statusText, contactSourceType } = props;

    const avatarPicture = useRef("" as any);
    const [isPictureVisible, setIsPictureVisible] = useState(false);

    React.useEffect(() => {
        initialize();
    }, []);

    const initialize = () => {
        if (EnvHelper.isStage3() || contactSourceType === FavoriteContactType.AzureActiveDirectory) {
            getContactProfilePicture(azureID);
        }
    };

    const getContactProfilePicture = (azureId: string) => {
        const profilePicturePromise = socketGraphApi.getUserProfilePicture(azureId);
        return profilePicturePromise?.then((pictureBlob: Blob | null) => {
            if (pictureBlob) {
                const reader = new FileReader();
                reader.readAsDataURL(pictureBlob);
                reader.onloadend = function () {
                    avatarPicture.current = reader.result;
                    setIsPictureVisible(true);
                }
            } else {
                setIsPictureVisible(false);
                avatarPicture.current = '';
            }
        });
    }

    return (<Flex key={`profile-details-${azureID}`} className="user-profile-container">
        <Flex.Item>
            {
                isPictureVisible ? <Avatar
                    styles={{ marginRight: '0.5rem' }}
                    image={{
                        src: `${avatarPicture.current}`
                    }}
                    size="larger"
                    status={status}
                    name={statusText}
                /> : <Avatar styles={{ marginRight: '0.5rem' }}
                    getInitials={() => getAvatarInitials()}
                    status={status}
                    size="larger"
                    name={statusText}
                />
            }
        </Flex.Item>
        <Flex.Item >
            <Text className="user-profie-details" content={displayName} />
        </Flex.Item>
    </Flex>
    )
};

export default ProfilePictureView;

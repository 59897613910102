import { PaperclipIcon, EyeIcon, DownloadIcon, TrashCanIcon, ChevronDownIcon } from "@fluentui/react-icons-northstar"
import { Dialog, Image, Flex, Text, Attachment, List, Popup, Box } from "@fluentui/react-northstar"
import React, { useMemo, useState, useCallback } from "react";
import { AttachmentData } from "../models/AttachmentData";
import { useIntl } from "react-intl";

interface AttachmentItemProps {
    item: AttachmentData
    removable?: boolean;
    onRemove?: (item: AttachmentData) => void;
}

const AttachmentItem = (props: AttachmentItemProps) => {
    const { item, removable, onRemove } = props;
    const intl = useIntl();
    const [isPreviewVisible, setPreviewVisibility] = useState<boolean>(false);

    const formattedFileSize = useMemo(() => {
        const bytes = 1024;
        const kbs = item.size / bytes;

        
        if (kbs < bytes) {
            return `${kbs.toFixed(2)}kb`;
        }
        const mbs = kbs / bytes;

        if (mbs < bytes) {
            return `${mbs.toFixed(2)}mb`; 
        }

        return `${(mbs / bytes).toFixed(2)}gb`
    }, [item]);

    const downloadHandler = useCallback(() => {
        if (!item.url) {
            return;
        }
        // download logic
    }, [item]);

    const deleteHandler = useCallback(() => {
        if (!!onRemove) {
            onRemove(item);
        }
    }, [onRemove]);

    const actionContent = useCallback((icon: JSX.Element, title: string) => (
        <Flex vAlign="center" gap="gap.small">
            {icon}
            <Text content={intl.formatMessage({ id: `EmailEditor.${title}` })}/>
        </Flex>
    ), [intl]);

    const popupActions = useMemo(() => {
        const actions = [
            { key: 'preview', content: actionContent(<EyeIcon size="small"/>, 'Preview'), onClick: () => setPreviewVisibility(true) }, 
            { key: 'download', content: actionContent(<DownloadIcon size="small"/>, 'Download'),  onClick: downloadHandler }
        ];

        return removable
            ? [...actions, { key: 'delete', content: actionContent(<TrashCanIcon outline size="small"/>, 'Delete'), onClick: deleteHandler }]
            : actions;
    }, [removable, actionContent, deleteHandler, downloadHandler]);

    return (
        <Box
            style={{marginTop: '0.2rem', marginBottom: '0.2rem'}}
        >
            <Attachment
                actionable
                title={item.name}
                description={formattedFileSize}
                header={{content: item.name, className: 'em-attachment', styles: {maxWidth: '10rem'}}}
                icon={{
                    content: <PaperclipIcon outline size="smallest" />,
                    variables: { iconSize: '1.1rem' },
                    styles: { marginBottom: '5px' }
                }}
                style={{
                    maxWidth: '15rem',
                    height: '2.5rem'
                }}
                variables={{
                    backgroundColor: 'white',
                    backgroundColorHover: '#fafafa',
                    boxShadow: 'none',
                }}
                action={<Popup
                        align='end'
                        position='below'
                        offset={[5, 18]}
                        on={['click']}
                        trigger={<ChevronDownIcon />}
                        content={<List
                                navigable
                                items={popupActions}
                                variables={{
                                    minHeight: '2rem',
                                    rootPadding: '0 1.4286rem 0 0.4286rem'
                                }}
                            />
                        }
                    />
                }
            />
            <Dialog
                backdrop
                content={<Image
                    src={item.url}
                    style={{maxWidth: '800px', maxHeight: '600px', objectFit: 'fill'}}
                />}
                open={isPreviewVisible}
                cancelButton="Close"
                onCancel={() => setPreviewVisibility(false)}
                variables={{rootWidth: 'auto'}}
            />
        </Box>
    )
}

export default AttachmentItem;

import * as microsoftTeams from '@microsoft/teams-js';
import Lockr from 'lockr';
import { socketAuth } from '.';
import { AzureLoginType, StorageKeys } from 'utils';
import { ADSigninParams, teamsStorageUtils } from 'services';

const azureClients = (window as any)['config'] ? (window as any)['config'].azureClients : undefined;

export class SSOHelper {
    /**
     * Attempts to get an access token for the user. If successful,
     * sends the user to the home page again where they will now
     * be logged in.
     */
    
    public async Login() {
        teamsStorageUtils.removeTeamsScopedTokenFromStorage();
        
        microsoftTeams.authentication.getAuthToken({claims: [], resources: [], silent: false})
            .then(result => this.ssoLoginSuccess(result))
            .catch(error => this.ssoLoginFailure(error));
    }

    public async getAuthToken(): Promise<string> {
        return microsoftTeams.authentication.getAuthToken();
    }

    ssoLoginSuccess = async (result: string) => {
        Lockr.set(StorageKeys.AzureADLoginType, AzureLoginType.SSO);
        Lockr.set(StorageKeys.TokenAD, result);

        const params: ADSigninParams = {
            IdToken: result,
            LoginType: AzureLoginType.SSO
        };

        socketAuth.userADSignedIn.next(params);
    }

    ssoLoginFailure(err: string) {
        socketAuth.userSSOFailed.next();
    }

    public getClientId(companyKey: string) {
        return azureClients[companyKey] || 'cc4teams';
    }
}
import { useIntl } from "react-intl";
import { Alert, Avatar, Button, CloseIcon, Divider, EditIcon, Flex, Header, Input, List, Loader, SearchIcon, Text, Tooltip } from '@fluentui/react-northstar';
import React, { useState } from 'react';
import { customerJourneyManager } from 'services/io/customer-journey';
import { CustomerDTO } from 'utils/domain/customerJourneyModels';

export interface SearchProps {
    compactView: boolean,
    updateSearchView: (showSearchView: boolean) => void
}
export const CustomerSearchView: React.FC<SearchProps> = ({compactView, updateSearchView}) => {
    const intl = useIntl();

    const [searchResult, setSearchResult] = useState([] as any);
    const [loading, setLoading] = useState(false);

    //OnKeyDown event in input
    const onKeyDownSearch = (e: any) => {
        if (e.keyCode == 13 && e.target.value.length > 2) {
            invokeNameSearch(e.target.value);
        }
    }

    //OnChange event in input
    const onChangeSearch = (e: any) => {
        if (e.target.value.length > 2 || e.target.value.length === 0) {
            invokeNameSearch(e.target.value);
        }
    }

    //Core search
    const invokeNameSearch = (e: any) => {
        setLoading(true);
        setSearchResult(null as unknown as []);
        customerJourneyManager.getCustomersByName({customerName: e}).then((response: any) => {
            console.log("Received name search results..");
            console.log(response);
            if (Object.prototype.toString.call(response) !== '[object Array]') {
                updateSearchResult([]);
                return;
            }
            const customers: CustomerDTO[] = [];
            (response as any[]).forEach((element, index) => {
                console.log("Looping through result and pushing to customer list");
                customers.push(new CustomerDTO(element));
            });
            console.log(customers);
            updateSearchResult(customers);
        }).catch(err => {
            console.log(err.message);
        }).finally(() => {
            setLoading(false);
        });
    }

    const getResultItem = (customer: CustomerDTO) => {
        return({
            key: customer.ID,
            endMedia: <Tooltip
            trigger={<Button onClick={(event) => console.log((event.target as HTMLInputElement).parentElement)} circular icon={<EditIcon />} size="small" iconOnly className="customer-info-button"/>}
            content={intl.formatMessage({ id: "CustomerJourney.CustomerSearchView.SelectResult"})} />,
            content: 
            <Flex space="between" >
                <Flex gap="gap.small">
                    <Avatar
                    name={`${customer.Name} ${customer.Surname}`}
                    />
                    <Flex column>
                    <Text content={customer.Name} weight="bold" />
                    <Text content={customer.Surname} size="small" />
                    </Flex>
                </Flex>
            </Flex>,
            onClick: () => {
                if (compactView) {
                    customerJourneyManager.selectedSearchResult.next(customer)
                } else {
                    customerJourneyManager.currentCustomer.next(customer)
                }}
        });
    };

    const updateSearchResult = (response: CustomerDTO[]) => {
        if (response === null) {
            return;
        }

        setSearchResult((items: any[]) => {
            if (items === null) {
                items = [];
            }
            if (items.length > 0) {
                items.forEach((element, index) => {
                    const newSearchResult = response.find(({ ID: Id }) => (Id === element.key));
    
                    if (newSearchResult) {
                        response.splice(response.indexOf(newSearchResult, 1));
                    }
                });
            }

            response.forEach((customer, index) => {
                if (customer instanceof CustomerDTO) {
                    items.push(getResultItem(customer));
                }
            });

            return items;
        });
       
     }

    return (
        <Flex column
            styles={({ theme: {siteVariables} }) => ({
                color: siteVariables.colorScheme.default.foreground,
                backgroundColor: siteVariables.colorScheme.default.background
            })}
        >
            {compactView === false && <Flex gap="gap.small" padding="padding.medium">
                <Flex.Item grow>
                    <Flex space="between">
                        <Header as="h2" content={intl.formatMessage({ id: "CustomerJourney.CustomerSearchView.Title"})} styles={({ theme: {siteVariables} }) => ({
                            color: siteVariables.colorScheme.brand.foreground,
                            margin: "0px"
                        })}/>
                        <Flex.Item push grow>
                            <Tooltip
                            trigger={<Button text icon={<CloseIcon size="large" />} iconOnly onClick={() => updateSearchView(false)} />}
                            content={intl.formatMessage({ id: "CustomerJourney.CustomerSearchView.CloseSearch"})} />
                        </Flex.Item>
                    </Flex>
                </Flex.Item>
            </Flex>}
            <Divider size={3}/>

            <Flex gap="gap.small" padding="padding.medium">
                <Flex.Item grow>
                    <Text content={intl.formatMessage({ id: "CustomerJourney.CustomerSearchView.SearchByName"})} size="medium" />
                </Flex.Item>
            </Flex>
            <Flex gap="gap.small" padding="padding.medium" styles={{paddingTop: "0px"}}>
                <Flex.Item grow>
                    <Input fluid icon={<SearchIcon />} onChange={onChangeSearch}  onKeyDown={onKeyDownSearch} placeholder={intl.formatMessage({ id: "CustomerJourney.CustomerSearchView.Placeholder"})} />
                </Flex.Item>
            </Flex>           
            <Divider size={1}/>
            {loading === true && <Loader label="Loading..." />}
            <Flex column hAlign="center" gap="gap.small" padding="padding.medium">
                {((searchResult === null || searchResult.length < 1) && loading === false) && <Alert info styles={{alignSelf: "center", width: "80%", textAlign: "center"}} content={intl.formatMessage({ id: "CustomerJourney.CustomerSearchView.NoResults"})} />}
                {searchResult !== null && 
                <Flex.Item grow>
                    <List selectable items={searchResult} styles={{width: "100%"}}/>
                </Flex.Item>
                }
            </Flex>
        </Flex>
    )
};

export default CustomerSearchView;
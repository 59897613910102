import * as React from 'react';
import { Flex, Image } from '@fluentui/react-northstar';

import CallDetails from './CallDetails';
import { CallActions } from './CallActions';
import { WebChatActions } from 'containers';
import { socketAudio } from 'services/calling';
import { webChatManager } from 'services/io/web-chat';
import SocialMediaView from './SocialMedia';
import { socketSocialMedia } from 'services/social-media';
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import { HeaderBarDisplayedMessageType } from 'utils';
import { Subscription } from 'rxjs';
import { GeneralHelper } from 'utils/helpers';

export const CallView = () => {

  const [isCallActionsViewDisplayed, setIsCallActionsViewDisplayed] = React.useState(false);
  const [isWebChatActionsViewDisplayed, setIsWebChatActionsViewDisplayed] = React.useState(false);
  const [isInASocialMedia, setIsInASocialMedia] = React.useState(false);
  const isInASocialMediaRef = React.useRef(false);
  const [isCmMediaActive, setIsCmMediaActive] = React.useState(false);
  const isCallActionsViewDisplayedRef = React.useRef(false);

  let subscriptionCallActionViewIsDisplayed: Subscription | null = null;
  let subscriptionWebChatActionsViewIsDisplayed: Subscription | null = null;
  let subscriptionIsInASocialMedia: Subscription | null = null;
  let subscriptionIsInACmMedia: Subscription | null = null;

  React.useEffect(() => {
    initialize();
    return () => componentWillUnmount();
  }, []);

  const initialize = () => {
    GeneralHelper.logCox(`in CallView.tsx, in initialize`);
    subscriptionCallActionViewIsDisplayed?.unsubscribe();
    subscriptionCallActionViewIsDisplayed = socketAudio.callActionViewIsDisplayed.subscribe((obj: boolean) => {
      isCallActionsViewDisplayedRef.current = obj;
      setIsCallActionsViewDisplayed(obj);
    });

    subscriptionWebChatActionsViewIsDisplayed?.unsubscribe();
    subscriptionWebChatActionsViewIsDisplayed = webChatManager.webChatActionsViewIsDisplayed.subscribe((obj: any) => {
      setIsWebChatActionsViewDisplayed(obj);
    });

    subscriptionIsInASocialMedia?.unsubscribe();
    subscriptionIsInASocialMedia = socketSocialMedia.isInASocialMedia.subscribe((obj: boolean) => {
      isInASocialMediaRef.current = obj;
      setIsInASocialMedia(obj);
      handleDisplayHeaderBarMessageChange();
    });

    subscriptionIsInACmMedia?.unsubscribe();
    subscriptionIsInACmMedia = socketSocialMedia.isInCmMedia.subscribe((obj: boolean) => {
      setIsCmMediaActive(obj);
    });

    handleDisplayHeaderBarMessageChange();
  }

  const getLogo = () => {
    return <>
      <Flex></Flex>
      <Image fluid src={process.env.PUBLIC_URL + '/logow192.png'} styles={{
        width: '50px',
        marginRight: '30px'
      }} />
    </>

  }

  const handleDisplayHeaderBarMessageChange = () => {
    let displayedMessagetype = HeaderBarDisplayedMessageType.MediaView;

    if (isInASocialMediaRef.current && !isCallActionsViewDisplayedRef.current) {
      displayedMessagetype = HeaderBarDisplayedMessageType.SocialMedia;
    }

    mainViewHelper.showHeaderBarMessage.next(displayedMessagetype);
    mainViewHelper.showSocialMediaSwitchBtn.next(isInASocialMediaRef.current && isCallActionsViewDisplayedRef.current);
  }

  const componentWillUnmount = () => {
    subscriptionCallActionViewIsDisplayed?.unsubscribe();
    subscriptionWebChatActionsViewIsDisplayed?.unsubscribe();
    subscriptionIsInASocialMedia?.unsubscribe();
    subscriptionIsInACmMedia?.unsubscribe();
  }

  return (
    <Flex.Item styles={{overflowX: 'scroll', overflowY: 'hidden'}}>
      <Flex space="between"
        styles={({ theme: { siteVariables } }) => ({
          backgroundColor: siteVariables.colorScheme.default.foreground1,
          color: siteVariables.colorScheme.default.background1,
          height: '50px'
        })}
        className={isWebChatActionsViewDisplayed ? "web-chat-wrapper" : ""}
      >
        <SocialMediaView />
        <CallDetails />
        <CallActions />
        <WebChatActions isCmMediaActive={isCmMediaActive} />
        {
          (!isInASocialMedia && !isCallActionsViewDisplayed && !isWebChatActionsViewDisplayed) && getLogo()
        }
      </Flex>
    </Flex.Item>
  )
};

export default CallView;

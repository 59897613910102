import { Subject, Subscription } from 'rxjs';
import { ApiQueue } from '.';
import { logging } from 'utils/logging';
import { ListenerOperations, QueueOperations, StorageKeys } from 'utils';
import { QueueUserDTO } from 'utils/domain/queueUserDTO';
import Lockr from 'lockr';
import { Listener, listeners } from 'services/io/listeners';
import { GeneralHelper } from 'utils/helpers';

// Stage 2 Queue Service
export class SocketQueue implements ApiQueue {
    private readonly logger = logging.getLogger('SocketAuth');
    private readonly subscriptionQueueChange: Subscription | null = null;

    public readonly listenerQueueChange: Listener<any> = listeners.createListener<any>(ListenerOperations.QueueChange);
    public readonly listenerNotifyQueueUserMembershipChange: Listener<any> = listeners.createListener(ListenerOperations.NotifyQueueUserMembershipChange)
    public readonly listenerNotifyQueueUserMembershipRemove: Listener<any> = listeners.createListener(ListenerOperations.NotifyQueueUserMembershipRemove)

    subjectQueueChange: Subject<any> = new Subject<any>();
    subjectQueueChangeNotification: Subject<any> = new Subject<any>();

    queuesArray: QueueUserDTO[] = [];

    constructor() {
        this.subscriptionQueueChange?.unsubscribe();
        this.subscriptionQueueChange = this.listenerQueueChange.received.subscribe((obj: any) => {
            this.subjectQueueChangeNotification.next(obj);
        });
    }

    getQueues(userRef: number): Promise<QueueUserDTO[]> {
        const requestData = { UserRef: userRef };

        return GeneralHelper.invokeServiceCall(requestData, QueueOperations.GetQueues, this.logger)
    }

    clientQueuesChanged(clientSip: string, queueRef: number, isJoined: boolean, changedBySupervisor: boolean): Promise<any> {
        const requestData = {
            AgentSip: clientSip,
            QueueRef: queueRef,
            IsJoined: isJoined,
            ChangedBySupervisor: changedBySupervisor,
            ChangedBySip: Lockr.get(StorageKeys.SIP)
        };

        return GeneralHelper.invokeServiceCall(requestData, QueueOperations.ClientQueuesChanged, this.logger)
    }
}

export class ResponseWrapper {
    Result!: ResponseResult;
    ValidationFailures!: Array<ValidationFailure>;
    Data!: any;
}

export class ValidationFailure {
    Message!: string;
    Type!: FailureType;
}

export enum ResponseResult {
    VALIDATIONERROR = 0,
    EXCEPTION = 1,
    SUCCESS = 2
}

export enum FailureType {
    EMPTY = 0,
    NOTFOUND = 1,
    DUPLICATE = 2,
    INVALID = 3,
    SHORT = 4,
    LONG = 5,
    INUSE = 6,
    LOCKED= 7
}

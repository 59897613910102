import { NotificationBaseDTO } from "utils/domain/voiceMailsView/notificationBaseDto";
import { NotificationContainerDTO } from "utils/domain/voiceMailsView/notificationContainerDTO";
import { NotificationStatusRequestDTO } from "utils/domain/voiceMailsView/notificationStatusRequestDTO";
import { VoicemailRequestDTO } from "utils/domain/voiceMailsView/voicemailRequestDTO";
import { VoicemailsService } from "./socket-voicemails";

export interface ApiVoiceMails {
    GetNotificationListForClient(agentRef: number): Promise<NotificationContainerDTO>;
    DownloadVoiceMailMedia(voicemailRequestDTO: VoicemailRequestDTO): Promise<any>;
    NotificationSetStatus(notificationRequest: NotificationStatusRequestDTO): Promise<any>;
    RemoveNotification(notificationRequest: NotificationBaseDTO): Promise<any>;
}

export const socketVoicemails = new VoicemailsService();
import { logging } from 'utils/logging';
import { ApiSettings } from '.';
import { ServiceOperations } from 'utils';
import { GeneralHelper } from 'utils/helpers';

export class SettingsService implements ApiSettings {
    private readonly logger = logging.getLogger('SettingsService');

    getCallCenterInfo(): Promise<any> {
        return GeneralHelper.invokeServiceCall("", ServiceOperations.GetCallCenterInfo, this.logger);
    }

    getServerTime(): Promise<Date> {
        return GeneralHelper.invokeServiceCall("", ServiceOperations.GetServerTime, this.logger);
    }

    isTeamsCustomerJourneyEnabled(): Promise<boolean> {
        return GeneralHelper.invokeServiceCall("", ServiceOperations.IsTeamsCustomerJourneyEnabled, this.logger);
    }

    isNewClientEnabled(tenantId: string): Promise<boolean> {
        return GeneralHelper.invokeServiceCall(tenantId, ServiceOperations.IsNewClientEnabled, this.logger);
    }
}

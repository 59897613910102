import { Presence } from "./presence";

export class FavoriteContactDTO {
    ID!: number;
    AgentRef!: number;
    DisplayName?: string;
    SIP?: string;
    FavoriteContactTypeId!: FavoriteContactType;
    PhoneNumber!: string;
    ContactID?: string;
    IsDeleted?: boolean;
    Presence?: Presence;
    Upn?: string;
    Email?: string;
    BusinessPhones?: string;
    MobilePhone?: string;
}

export enum FavoriteContactType {
    Agent = 1,
    Office365 = 2,
    AzureActiveDirectory = 3,
    DbContact = 4,
    ActiveDirectory = 5,
    Federated = 6,
    CommonAreaPhone = 7,
    ApplicationQueue = 8,
    ResponseGroup = 9,
    NonCC4SkypeApplication = 10,
    CC4SkypeQueue = 11,
    ExchangeUMContact = 12,
    Unknown = 13
}
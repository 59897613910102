export class TransferRequest {
    CallSessionRequest!: CallSessionRequest;
    TargetUri!: string;
    ObjectId!: string;
    ContactType!: ContactType;

    constructor() {
        this.CallSessionRequest = callSessionRequest;
    }
}

export class EjectParticipantRequest {
    scenarioId?: string;

    participantId?: string;

    tenantId?: string;
}

export enum ContactType {
    Agent = 0,
    Contact,
    CommonAreaPhone,
    ApplicationQueue,
    ResponseGroup,
    NonCC4SkypeApplication,
    CC4SkypeQueue,
    ExchangeUMContact,
    AzureContact
}


export class CallSessionRequest {
    SourceUri!: string;
    ConversationKey!: string;
    SessionId!: string;
    CallId?: string;
    TenantId?: string;
    AgentRef?: string;
    QueueRef?: string;
    SecondaryAgentRef?: string;
    SecondaryAgentUpn?: string;
    SecondaryAgentDisplayName?: string;
    AzureTenantId?: string;
    ScenarioId?: string;
}

export class StartReJoinRequest {
    ConversationKey!: string;
    OperatorSip!: string;
}

export const startReJoinRequest = new StartReJoinRequest();

export const callSessionRequest = new CallSessionRequest();
//#warning-js [cc4all-1102]: maybe move out of here, it's used for endCall, not just for transfers like the file name suggests

export const transferRequest = new TransferRequest();

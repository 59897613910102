import { Button, Flex, List, StarIcon } from "@fluentui/react-northstar";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { extendedFavoritesService } from "services/extendedFavorites";
import { FavoriteContactDTO } from "utils/domain/extended/favoriteContactDTO";
import { useIntl } from 'react-intl';
import { ConferenceStatusAction, StorageKeys } from "utils";
import { FavoriteContactSender, FavoriteContactUpdate, notificationCenter, FavoriteContactAction } from "utils/helpers/notificationCenter";
import { Subscription } from "rxjs";
import { Contact, ContactType } from "utils/domain/extended/contact";
import { AzureActiveDirectorySettingsDTO, SipMappingType } from "utils/domain/azureActiveDirectorySettingsDTO";
import { socketContacts } from "services/contacts";
import './extendedFavorites.scss';
import { socketOutbound } from "services/outbound";
import { CallSessionDTO } from "utils/domain/callSessionDTO";
import { EnvHelper, GeneralHelper, ValidationHelper } from "utils/helpers";
import { socketAudio } from "services/calling";
import Lockr from 'lockr';
import AgentAvatarPopup from "components/AgentAvatar/AgentAvatarPopup";
import InCallActions from "components/ContactActions/InCallActions";
import NotInCallActions from "components/ContactActions/NotInCallActions";
import { socketTransfer } from "services/transfers";

interface FavoriteItem {
  key: string;
  id: string;
  media: JSX.Element;
  header: any;
  endMedia?: JSX.Element;
  sip?: string;
  displayname?: string;
  className?: string;
}

const ExtendedFavoritesView = () => {
  const intl = useIntl();
  const [favorites, setFavorites] = useState<FavoriteContactDTO[]>([]);
  const azureADSettings = useRef(new AzureActiveDirectorySettingsDTO());

  const [agentHasOutboundEnabled, setAgentHasOutboundEnabled] = useState(false);
  const [isInCall, setInCall] = useState<boolean>(false);
  const [currentCallSession, setCurrentCallSession] = useState(new CallSessionDTO());

  let subscriptionFavoriteContactsChanged: Subscription | null = null;
  let subscriptionAzureADSettingsChanged: Subscription | null = null;
  let subscriptionAgentHasOutboundAccessEnabled: Subscription | null = null;
  let subscriptionCallSessionStateChanged: Subscription | null = null;
  let subscriptionCurrentUserCallSessionStateChanged: Subscription | null = null;
  let subscriptionOnWarmTransferStarted: Subscription | null = null;
  let subscriptionOnWarmTransferFailed: Subscription | null = null;

  const currentUserId = Lockr.get<string>(StorageKeys.UserId);

  useEffect(() => {
    init();

    return () => deinit();
  }, []);

  const getFavoriteContacts = () => {
    extendedFavoritesService.getCurrentAgentFavoriteContacts()
      .then(setFavorites)
      .catch(error => {
        console.error(error);
      });
  }

  const init = () => {
    setAgentHasOutboundEnabled(socketOutbound.HasOutboundAccesEnabled());
    checkIsInACallState(socketAudio.callSession);
    setCurrentCallSession(socketAudio.callSession);
    
    subscriptionCallSessionStateChanged?.unsubscribe();
    subscriptionCallSessionStateChanged = socketAudio.callSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      checkIsInACallState(callSessionDTO);
      if ((callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransfered] ||
        callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.OperatorColdTransferred]) &&
        callSessionDTO.AgentBeforeTransferRef === Lockr.get<string>(StorageKeys.UserId)) {
        callSessionDTO.Transferring = true;
      }

      setCurrentCallSession(callSessionDTO);
    });

    getFavoriteContacts();

    getAzureActiveDirectorySettingsForContacts();

    subscriptionFavoriteContactsChanged?.unsubscribe();
    subscriptionFavoriteContactsChanged = notificationCenter.favoriteContactUpdated.subscribe((data: FavoriteContactUpdate) => {
      if (data.sender === FavoriteContactSender.Dialpad) {
        return;
      }

      if (data.action === FavoriteContactAction.Delete) {
        removeContactFromLocalFavorites(data.contact.ContactId);
      } else if (data.action === FavoriteContactAction.Update) {
        updateContactFromLocalFavorites(data.contact);
      } else {
        insertContactToLocalFavorites(data.contact);
      }
    });

    subscriptionAzureADSettingsChanged?.unsubscribe();
    subscriptionAzureADSettingsChanged = socketContacts.azureADSettingsForContactsChanged.subscribe((settings: AzureActiveDirectorySettingsDTO) => {
      azureADSettings.current = settings;
    });

    subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
    subscriptionAgentHasOutboundAccessEnabled = socketOutbound.agentHasOutboundAccessEnabled.subscribe((isOutboundEnabled: boolean) => {
      setAgentHasOutboundEnabled(isOutboundEnabled);
    });

    subscriptionCurrentUserCallSessionStateChanged?.unsubscribe();
    subscriptionCurrentUserCallSessionStateChanged = socketAudio.currentUserCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      setCurrentCallSession(callSessionDTO);
    });

    subscriptionOnWarmTransferStarted?.unsubscribe();
    subscriptionOnWarmTransferStarted = socketTransfer.onWarmTransferStarted.subscribe((_) => {
      setCurrentCallSession({ ...currentCallSession, IsWarmTransferInProgress: true } as CallSessionDTO)
    });

    subscriptionOnWarmTransferFailed?.unsubscribe();
    subscriptionOnWarmTransferFailed = socketTransfer.onWarmTransferFailed.subscribe((_) => {
      setCurrentCallSession({ ...currentCallSession, IsWarmTransferInProgress: false, Started: true } as CallSessionDTO)
    })
  };

  const deinit = () => {
    subscriptionFavoriteContactsChanged?.unsubscribe();
    subscriptionAzureADSettingsChanged?.unsubscribe();
    subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
    subscriptionCallSessionStateChanged?.unsubscribe();
    subscriptionOnWarmTransferStarted?.unsubscribe();
    subscriptionOnWarmTransferFailed?.unsubscribe();
  };

  const checkIsInACallState = (callSessionDTO: CallSessionDTO) => {
    const startOperations = GeneralHelper.getStage3StartOperations;
    const endOperations = GeneralHelper.getStage3EndOperations();

    if (startOperations.includes(callSessionDTO.ConferenceActionAsString)) {
      setInCall(true);
    }

    if (endOperations.includes(callSessionDTO.ConferenceActionAsString) || currentUserId !== callSessionDTO?.AgentRef) {
      setInCall(false);
    }

    if (callSessionDTO.IsCurrentUserMonitoring || callSessionDTO.IsCurrentUserThePrimaryAgent) {
      setInCall(true);
    }

  }

  const getAzureActiveDirectorySettingsForContacts = () => {
    socketContacts.getCachedAzureActiveDirectorySettings().then((settings: AzureActiveDirectorySettingsDTO) => {
      azureADSettings.current = settings;
    });
  }

  const mapDisplayedContacts = useCallback((contacts: FavoriteContactDTO[]): FavoriteItem[] => {
    const shouldUseEmailForSip = azureADSettings.current.SipMappingType === SipMappingType.Mail;

    return [...contacts]
      .sort((a, b) => (a.DisplayName || '').localeCompare(b.DisplayName || '', undefined, { sensitivity: "base" }))
      .map((contact: FavoriteContactDTO) => {
        const sipValue = contact.Source === ContactType.AzureActiveDirectory && shouldUseEmailForSip
          ? contact.Email
          : contact.Upn;

        return {
          key: `fav-item-${contact.Id}`,
          id: contact.Id,
          media: buildAgentAvatar(contact),
          endMedia: buildContactButtons(contact, sipValue),
          header: contact.DisplayName,
          sip: sipValue,
          displayname: contact.DisplayName,
          className: "fav-item"
        }
      });
  }, [currentCallSession, agentHasOutboundEnabled]);

  const buildAgentAvatar = (contact: FavoriteContactDTO) => (
    <AgentAvatarPopup
      name={contact.DisplayName}
      azureID={contact.ContactId}
      contactSourceType={contact.Source}
    />
  );

  const removeContactFromLocalFavorites = (contactId: string) => setFavorites(prev => prev.filter(x => x.ContactId !== contactId));

  const updateContactFromLocalFavorites = (contact: FavoriteContactDTO) => {
    setFavorites(prev => {
      const updateIndex = prev.findIndex(x => x.ContactId === contact.ContactId);
      return prev.map((favorite, index) => {
        if (index === updateIndex) {
          favorite.DisplayName = contact.DisplayName;
          favorite.Upn = contact.Upn;
          favorite.Email = contact.Email;
          favorite.MobileNumber = contact.MobileNumber;
          favorite.BusinessNumbersAsString = contact.BusinessNumbersAsString;
        }

        return favorite;
      });
    });
  }

  const insertContactToLocalFavorites = (contact: FavoriteContactDTO) => setFavorites(prev => [...prev, contact]);

  const removeFavorite = (contact: FavoriteContactDTO) => {
    extendedFavoritesService.removeFromFavorite(contact.ContactId)
      .then(() => {
        removeContactFromLocalFavorites(contact.ContactId);
        notificationCenter.favoriteContactUpdated.next({ action: FavoriteContactAction.Delete, sender: FavoriteContactSender.Dialpad, contact: contact });
      })
      .catch(error => console.error(error));
  }

  const agentIsNotInACall = (azureAId: string) => {
    const isCallParked = GeneralHelper.isCallParkedOrFallback(currentCallSession.ActionHistory, ConferenceStatusAction.OperatorPark);
    const isFallbackCall = GeneralHelper.isCallParkedOrFallback(currentCallSession.ActionHistory, ConferenceStatusAction.Fallback);

    const startedInbound = currentCallSession.Started && !currentCallSession.IsOutboundCall;
    const startedOutbound = currentCallSession.Started && currentCallSession.IsOutboundCall && 
      currentCallSession.ConferenceAction !== ConferenceStatusAction.OutboundAcceptedByAgent

    return ((!startedInbound && !startedOutbound) ||
      currentCallSession.Transferring ||
      currentCallSession?.ParticipantIds?.includes(azureAId) ||
      currentCallSession.IsTeamsUnparkInProgress ||
      isCallParked || isFallbackCall ||
      GeneralHelper.isRinging(currentCallSession.ActionHistory)
      || (EnvHelper.isStage3() && currentCallSession.IsCurrentUserMonitoring));
  }

  const buildContactButtons = useCallback((favoriteContact: FavoriteContactDTO, sipValue: string) => {
    const contact: Contact = {
      DisplayName: favoriteContact.DisplayName,
      ContactType: favoriteContact.Source,
      Id: favoriteContact.ContactId,
      UserPrincipalName: favoriteContact.Upn,
      Mail: favoriteContact.Email,
      BusinessPhonesAsString: favoriteContact.BusinessNumbersAsString,
      MobilePhone: ValidationHelper.stripPhoneNumber(favoriteContact.MobileNumber),
      GivenName: '',
      Surname: '',
      JobTitle: '',
      BusinessPhones: [ValidationHelper.stripPhoneNumber(favoriteContact.BusinessNumbersAsString)],
      Department: favoriteContact?.Department ?? '',
      UserType: '',
      OfficeLocation: '',
      Country: '',
      City: '',
      State: '',
      PreferredLanguage: '',
      PostalCode: '',
      ProxyAddresses: [],
      QueueIds: [],
      IsImported: false
    };

    return <>
      {
        agentIsNotInACall(contact.Id) ?
          <NotInCallActions contact={contact} agentHasOutboundEnabled={agentHasOutboundEnabled} azureADSettings={azureADSettings.current}
            currentCallSession={currentCallSession} /> :
          <InCallActions contact={contact} agentHasOutboundEnabled={agentHasOutboundEnabled} azureADSettings={azureADSettings.current} />
      }
      <Button
        text
        iconOnly
        size="medium"
        icon={<StarIcon className="active-favorite" outline size="medium" />}
        title={intl.formatMessage({ id: "CallActions.RemoveFromFavorites" })}
        onClick={() => removeFavorite(favoriteContact)}
      />
    </>
  }, [currentCallSession.Started,
  currentCallSession.Transferring,
  currentCallSession.IsTeamsUnparkInProgress,
  currentCallSession.ActionHistory,
  currentCallSession.IsWarmTransferInProgress,
    agentHasOutboundEnabled]);

  const mappedItems = useMemo<FavoriteItem[]>(() => mapDisplayedContacts(favorites), [
    favorites,
    agentHasOutboundEnabled,
    isInCall,
    currentCallSession
  ]);

  return (
    <Flex className="favorites-contacts-container">
      <List key="favorites-contacts-list"
        className="favorites-contacts-list"
        styles={{ padding: '10px' }}
        truncateHeader
        items={mappedItems}
      />
    </Flex>);
};

export default ExtendedFavoritesView;
import { Subject } from "rxjs";
import { logging } from "utils";
import { PagedResultDto } from "utils/domain/extended/pagedResultDto";
import { Controllers } from "utils/enums-s3";
import { GeneralHelper } from "utils/helpers";

export class GenericHistoryService {
    private readonly logger = logging.getLogger('WaitingMediaService');

    private _history: GenericHistoryDTO[] = [];
    private _numberOfPages: number = 1;
    private _currentPage: number = 1;
    private _isInited: boolean = false;
    private _searchText: string = '';

    public readonly RecordsPerPage: number = 10;
    public readonly dataUpdatedSubject: Subject<GenericHistoryDTO[]> = new Subject();

    public get numberOfPages(): number {
        return this._numberOfPages;
    }

    public get currentPage(): number {
        return this._currentPage;
    }

    public get existsPaginator(): boolean {
        return this._numberOfPages > 1;
    }

    public get historyData(): GenericHistoryDTO[] {
        return this._history.map((x: GenericHistoryDTO) => ({...x}));
    }

    public get searchText(): string {
        return this._searchText;
    }

    public init() {
        if (this._isInited) {
            this.notifyDataChanged();
            return;
        }

        this._isInited = true;
        this.pullHistoryData();
    }

    public updateSearch(value: string) {
        this._searchText = value;
        this._currentPage = 1;
        this.pullHistoryData();
    }

    public changePage(page: number) {
        if (this._currentPage === page) {
            return;
        }

        this._currentPage = page;
        this.pullHistoryData();
    }

    private pullHistoryData() {
        const request = {
            page: this._currentPage - 1,
            searchText: this._searchText
        };
        GeneralHelper.invokeServiceCall(request, '', this.logger, Controllers.GenericHistory)
            .then((data: PagedResultDto<GenericHistoryDTO>) => {
                this._history = data.Items;
                this._numberOfPages = Math.ceil(data.TotalCount / this.RecordsPerPage) || 1;

                this.notifyDataChanged();
            });
    }

    private notifyDataChanged() {
        this.dataUpdatedSubject.next(this.historyData);
    }
}

export interface GenericHistoryDTO {
    Id: string;
    AgentUpn: string;
    Name: string;
    Subject: string;
    QueueId: string;
    QueueName: string;
    IsHandled: boolean;
    State: HistoryState;
    Date: string;
}

export enum HistoryState {
    Accepted,
    Missed,
    Rejected
}

export const genericHistoryService = new GenericHistoryService();
export const TeamsThemes = {
    contrast: "contrast",
    dark: "dark",
    default: "default"
};

export const Auth = {
    singInMgt: 'auth/mgtsignin',
    authenticatedDomains: {
        "https://graph.microsoft.com": "https://graph.microsoft.com"
    },
    actionParamName: 'action',
    signoutAction: 'signout',
};

export const Languages = {
    english: "en",
    dutch: "nl",
    french: "fr",
    german: 'de-de',
    italian: 'it',
    spanish: 'es',
    romanian: "ro"
}

export const DefaultPageSize = 10;

export const DefaultTimeSpan = '00:00:00';

export const UnknownStatus = "unknown";

export const Department = {
    applicationInstance: "Microsoft Communication Application Instance"
}

export const RegExpressions = {
    upn: "^(sip:)?[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+)*@\\w+([.-]?\\w+)*(\\.\\w{2,3})+$",
    phoneNumber: "^\\+?([0-9]+)$"
}

export const HttpStatusCodes = {
    success: 200,
    forbidden: 403,
    notFound: 404,
    conflict: 409
}
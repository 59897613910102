import { Subject, Subscription } from "rxjs";
import { logging } from 'utils/logging';
import { SocketOperations } from "utils";
import { socketManager } from ".";
import { HubConnection } from "@microsoft/signalr";
import { EnvHelper, GeneralHelper } from "utils/helpers";

type SocketType = SocketIOClient.Socket | HubConnection | null | undefined;

export class IncomingRequests {
    private readonly logger = logging.getLogger('IncomingRequests');
    private bindingCount = 0;
    socket: SocketType;

    received: Subject<any> = new Subject();
    private readonly subscriptionOnInitialized: Subscription | null = null;
    private readonly subscriptionOnReconnect: Subscription | null = null;

    constructor() {
        if (socketManager) {
            this.subscriptionOnInitialized?.unsubscribe();
            this.subscriptionOnInitialized = socketManager.onInitialized.subscribe((obj: any) => {
                this.socket = socketManager.socket;
                GeneralHelper.logCox(`in incoming-requests.ts, in constructor, in the socketManager.onInitialized subscription, bindingCount is ${this.bindingCount}`);
                this.bind();
            });

            this.subscriptionOnReconnect?.unsubscribe();
            this.subscriptionOnReconnect = socketManager.onReconnect.subscribe((obj: any) => {
                GeneralHelper.logCox(`in incoming-requests.ts, in constructor, in the socketManager.onReconnect subscription, bindingCount is ${this.bindingCount}`);
                this.socket = socketManager.socket;
            });
        }
    }

    private bind() {
        if (this.socket) {
            ++this.bindingCount;
            let label = '';
            if (GeneralHelper.isCox() && !EnvHelper.isStage3()) {
                let socketId = '';
                const socketIoSocket = this.socket as SocketIOClient.Socket;
                socketId = socketIoSocket.id;
                label = `onInitialized ${this.bindingCount}, socket.id:  ${socketId}`;
            }

            this.socket.off(SocketOperations.ServerNotification);
            this.socket.on(SocketOperations.ServerNotification, (value: any) => {
                let socketId = '';
                if (!EnvHelper.isStage3()) {
                    const socketIoSocket = this.socket as SocketIOClient.Socket;
                    socketId = socketIoSocket.id;
                }

                GeneralHelper.logCox(`in incoming-requests.ts, in bind, in socket.on(SocketOperations.ServerNotification), \
label ${label}, current socketId being ${socketId}, value ${JSON.stringify(value)}`);
                if (typeof value.RequestData === "string") {
                    try {
                        value.RequestData = JSON.parse(value.RequestData);
                    } catch (error) {
                        this.logger.info('Incoming request data parse error:' + error);
                    }
                }

                this.received.next(value);
            });
        } else {
            GeneralHelper.logCox(`in incoming-requests.ts, this.socket is missing`);
        }
    }
}
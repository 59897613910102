import { ChatStatusType, StorageKeys } from "utils";
import { webChatManager } from "services/io/web-chat";
import Lockr from 'lockr';
import { GeneralHelper } from ".";
import PlaySoundHelper, { SoundType } from "./play-sound-helper";

export class WebchatNotifications {
    private readonly isIncomingState = (state: any) => {
        return (state === ChatStatusType.Incoming || state === ChatStatusType.Ringing);
    }

    notifyNewWebChatRequests = () => {
        const sip = Lockr.get<string>(StorageKeys.SIP);

        webChatManager.getAgentChatSessions(sip).then(data => {
            if (data == null || data.length === 0 || (Array.isArray(data) && data.length === 0)) {
                return;
            }
            const webChatRequests = data.filter((x: any) => this.isIncomingState(x.ChatStatusType));
            if (webChatRequests && webChatRequests.length) {
                this.playWebchatAudioNotifcation();
                webChatManager.newWebChatRequestsOnAppLoad.next(webChatRequests.map((item: any) => item.SessionRef));
            }
        });
    }

    playWebchatAudioNotifcation = () => {
        PlaySoundHelper.playSound(SoundType.Notification);
    }
}

export const webchatNotifications = new WebchatNotifications();

import { ResponseWrapper } from 'utils';
import { Contact } from 'utils/domain/contact';
import { FavoriteContactDTO } from 'utils/domain/favoriteContactDTO';
import { ContactService } from './socket-contacts';

export interface ApiContacts {
    //getAll(): Promise<Person[]>
    //getPerson(id: string): Promise<Person>;
    getContacts(data: any): Promise<any>;
    saveContact(contact: Contact): Promise<ResponseWrapper>;
    updateContact(contact: Contact): Promise<ResponseWrapper>;
    deleteContact(id: number): Promise<ResponseWrapper>;
    addFavoriteContact(contact: FavoriteContactDTO): Promise<number>;
    removeFavoriteContact(contact: FavoriteContactDTO): Promise<boolean>;
    getFavoriteContactsByAgentId(agentRef: number): Promise<FavoriteContactDTO[]>;
    hasAnyFavoriteContact(agentRef: number): Promise<boolean>;
}

export const socketContacts = new ContactService();

import React, { useState } from 'react';
import { Flex, Text, Button, List, Checkbox } from '@fluentui/react-northstar';
import { useIntl } from 'react-intl';
import { ExtendedContact } from 'utils/domain/extended/contact';
import { extendedContactService as contactService } from 'services/extendedContacts/extendedContactService';
import { HttpStatusCodes, logging, RegExpressions } from 'utils';
import './addEditContact.scss';
import ExtendedContactFieldContainer from './ExtendedContactFieldContainer';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { queuesService } from "services/queue";
import { QueueUserS3DTO } from 'utils/domain/queueUserDTO';

export const ExtendedAddEditContactView = ({ closeAddContactPortal, getAllContacts, contactToUpdate, isFavorite }: any) => {
    const intl = useIntl();
    const [queuesError, setQueuesError] = useState(false);
    const [selectedQueues, setSelectedQueues] = useState(contactToUpdate?.QueueIds || [] as any);
    const [queuesValidationMessage, setQueuesValidationMessage] = useState('');
    const [oneOfFieldsRequiredValidationMessage, setOneOfFieldsRequiredValidationMessage] = useState('');
    const [queues, setQueues] = useState([] as any);

    const [showExtraFields, setShowExtraFields] = useState(false);

    React.useEffect(() => {
        initialize();
    }, []);

    React.useEffect(() => {
        setQueuesError(false);
        setQueuesValidationMessage("");

    }, [selectedQueues]);

    const initialize = () => {
        setQueues(queuesService.queuesArray);
    }

    const setQueuesErrorMessage = () => {
        setQueuesError(true);
        setQueuesValidationMessage(intl.formatMessage({ id: "ErrorMessage.AddContact.NoQueueSelected" }));
    }

    const removeQueueFromList = (queueList: any, queueId: any) => {
        return queueList.filter((item: any) => item !== queueId);
    };

    const handleQueueSelected = (queue: QueueUserS3DTO) => {
        const index = selectedQueues.indexOf(queue.QueueRef);

        if (index > -1) {
            setSelectedQueues(removeQueueFromList(selectedQueues, queue.QueueRef));
        } else {
            setSelectedQueues((newSelectedQueues: any) => [...newSelectedQueues, queue.QueueRef]);
        }
    }

    const queueShouldBeChecked = (queue: any) => {
        if (!(selectedQueues === undefined || selectedQueues.length === 0) && selectedQueues.includes(queue)) {
            return true;
        }

        return false;
    }

    const addContact = (contact: ExtendedContact) => {
        contactService.create(contact).then((response: any) => {
            logging.errorHandler.next("SuccessMessage.AddContact");
            closeAddContactPortal();
            setTimeout(function () {
                getAllContacts();
            }, 1000);
        }).catch(err => {
            if (err.response.status == HttpStatusCodes.conflict) {
                logging.errorHandler.next("ErrorMessage.AddContact.Conflict");
            } else {
                logging.errorHandler.next("ErrorMessage.AddContact.AddContact");
            }
            console.log(err.message);
        });
    }

    const translate = (label: string) => {
        return intl.formatMessage({ id: label });
    }

    const requiredFieldValidationMessage = translate("ExtendedAddContactView.RequiredField");
    const invalidFieldValidationMessage = translate("ExtendedAddContactView.InvalidInput");

    const updateContact = (contact: ExtendedContact) => {
        contactService.update(contact).then((response: any) => {
            logging.errorHandler.next("SuccessMessage.UpdateContact");
            closeAddContactPortal();
            setTimeout(function () {
                getAllContacts(contact, isFavorite);
            }, 1000);

        }).catch(err => {
            if (err.response.status == HttpStatusCodes.conflict) {
                logging.errorHandler.next("ErrorMessage.AddContact.Conflict");
            } else {
                logging.errorHandler.next("ErrorMessage.UpdateContact");
            }
            console.log(err.message);
        });
    }

    const validateOneRequiredOfMultipleFields = (object: any) => {
        const anyFieldIsFilledIn = Boolean(object.email || object.mobilePhoneNumber || object.businessPhoneNumber);
        
        if (anyFieldIsFilledIn) {
            setOneOfFieldsRequiredValidationMessage('');
        } else {
            setOneOfFieldsRequiredValidationMessage(translate("ErrorMessage.AddContact.OneOfTheFieldsIsRequired"));
        }
        return anyFieldIsFilledIn;
    }

    return (
        <Formik
            initialValues={{
                name: contactToUpdate?.DisplayName || '',
                email: contactToUpdate?.Mail || '',
                mobilePhoneNumber: contactToUpdate?.MobilePhone || '',
                businessPhoneNumber: contactToUpdate?.BusinessPhones[0] || '',
                userPrincipalName: contactToUpdate?.UserPrincipalName || '',
                jobTitle: contactToUpdate?.JobTitle || '',
                department: contactToUpdate?.Department || '',
                office: contactToUpdate?.OfficeLocation || '',
                city: contactToUpdate?.City || '',
                country: contactToUpdate?.Country || '',
            }}
            validateOnBlur={false}
            validationSchema={() => {
                return Yup.object().shape({
                    name: Yup
                        .string()
                        .required(requiredFieldValidationMessage),
                    email: Yup.string()
                        .email(invalidFieldValidationMessage),
                    mobilePhoneNumber: Yup.string()
                        .matches(new RegExp(RegExpressions.phoneNumber), invalidFieldValidationMessage),
                    businessPhoneNumber: Yup.string()
                        .matches(new RegExp(RegExpressions.phoneNumber), invalidFieldValidationMessage),
                    userPrincipalName: Yup.string()
                        .matches(new RegExp(RegExpressions.upn), invalidFieldValidationMessage)
                }).test({ name: 'oneOfTheFieldsRequired', test: validateOneRequiredOfMultipleFields, exclusive: true })
            }}
            onSubmit={async (values) => {

                if (selectedQueues.length === 0) {
                    setQueuesErrorMessage();
                    return;
                }
                const contact: ExtendedContact = {
                    UserPrincipalName: values.userPrincipalName,
                    Name: values.name,
                    Email: values.email,
                    MobilePhoneNumber: values.mobilePhoneNumber,
                    BusinessPhoneNumber: values.businessPhoneNumber,
                    JobTitle: values.jobTitle,
                    Department: values.department,
                    Office: values.office,
                    City: values.city,
                    Country: values.country,
                    QueueIds: selectedQueues
                }

                if (contactToUpdate?.Id) {
                    contact.Id = contactToUpdate.Id;
                    updateContact(contact);
                } else {
                    addContact(contact);

                }
            }}>
            {({
                handleSubmit
            }) => (
                <div>
                    <Flex gap="gap.small">
                        <Flex.Item size="70%">
                            <Flex wrap>
                                <ExtendedContactFieldContainer fieldName="name" translationKeyword="Name" isRequired="true" />
                                <ExtendedContactFieldContainer fieldName="email" translationKeyword="Email" />
                                <ExtendedContactFieldContainer fieldName="mobilePhoneNumber" translationKeyword="MobilePhoneNumber" />
                                <ExtendedContactFieldContainer fieldName="businessPhoneNumber" translationKeyword="BusinessPhoneNumber" />
                                {oneOfFieldsRequiredValidationMessage && <Flex column className="error-message"><Text color="red" content={oneOfFieldsRequiredValidationMessage} /></Flex>}
                                {!showExtraFields && <Flex column className="extra-fields-button">
                                    <Button text content={translate("ExtendedAddContactView.ShowExtraFields")} onClick={() => { setShowExtraFields(true) }} />
                                </Flex>}
                                {showExtraFields && <>
                                    <ExtendedContactFieldContainer fieldName="userPrincipalName" translationKeyword="UserPrincipalName" />
                                    <Flex column className="column-placeholder"></Flex>
                                    <ExtendedContactFieldContainer fieldName="jobTitle" translationKeyword="JobTitle" />
                                    <ExtendedContactFieldContainer fieldName="department" translationKeyword="Department" />
                                    <ExtendedContactFieldContainer fieldName="office" translationKeyword="Office" />
                                    <ExtendedContactFieldContainer fieldName="city" translationKeyword="City" />
                                    <ExtendedContactFieldContainer fieldName="country" translationKeyword="Country" />
                                    <Flex column className="extra-fields-button">
                                        <Button text content={translate("ExtendedAddContactView.HideExtraFields")} onClick={() => { setShowExtraFields(false) }} />
                                    </Flex>
                                </>}
                                {queuesValidationMessage && <Text color="red" content={queuesValidationMessage} />}
                            </Flex>
                        </Flex.Item>

                        <Flex.Item size="30%">
                            <div>
                                <Text content={intl.formatMessage({ id: "AddContactView.Queues" })} styles={{ paddingLeft: "0.625rem" }} /><br />
                                <Flex padding="padding.medium">
                                    <div className="queue-list-container">
                                        {(queues && queues.length === 0) ?
                                            <br /> :

                                            <List className="queueList"
                                                items={queues.map((queue: any) => (
                                                    <Checkbox
                                                        className="queues-checkbox"
                                                        checked={queueShouldBeChecked(queue.QueueRef)}
                                                        label={queue.QueueName}
                                                        key={queue.QueueRef}
                                                        onClick={() => handleQueueSelected(queue)}
                                                    />
                                                ))}
                                            />
                                        }
                                    </div>
                                </Flex>
                            </div>
                        </Flex.Item>
                    </Flex>
                    <Flex gap="gap.small" padding="padding.medium" hAlign="end">
                        <Button content={translate("AddContactView.Cancel")} onClick={() => closeAddContactPortal()} />
                        <Button primary content={translate("AddContactView.Save")} onClick={() => handleSubmit()} />
                    </Flex>
                </div>)}
        </Formik>
    )
};

export default ExtendedAddEditContactView;

import { ListenerOperations, logging, ServiceOperations } from "utils";
import { NotificationContainerDTO } from "utils/domain/voiceMailsView/notificationContainerDTO";
import { GeneralHelper } from "utils/helpers";
import { ApiVoiceMails } from ".";
import { VoicemailRequestDTO } from "utils/domain/voiceMailsView/voicemailRequestDTO";
import { Listener, listeners } from "services/io/listeners";
import { Subject, Subscription } from 'rxjs';
import { NotificationBaseDTO } from "utils/domain/voiceMailsView/notificationBaseDto";
import { NotificationStatusRequestDTO } from "utils/domain/voiceMailsView/notificationStatusRequestDTO";
import { VoiceMailRecordDTO } from "utils/domain/voiceMailsView/voiceMailRecordDTO";
import { RealtimeNotificationDTO } from "utils/domain/voiceMailsView/realtimeNotificationDTO";
import { BotNotificationDataDTO } from "utils/domain/botNotificationDataDTO";

export class VoicemailsService implements ApiVoiceMails {
    private readonly logger = logging.getLogger('VoicemailsService');

    public readonly listenerNotification: Listener<NotificationBaseDTO> = listeners.createListener<NotificationBaseDTO>(ListenerOperations.SendNotificationToClients);

    private readonly subscriptionNotificationChange: Subscription | null = null;
    alertVoicemailChangeNotification: Subject<NotificationBaseDTO> = new Subject<NotificationBaseDTO>();
    voiceMailNotificationReceivedFromBot: Subject<BotNotificationDataDTO> = new Subject<BotNotificationDataDTO>();

    newAlertsChanged: Subject<any> = new Subject<any>();
    newVoicemailsChanged: Subject<any> = new Subject<any>();

    newVoicemails: VoiceMailRecordDTO[] = [];
    newAlerts: RealtimeNotificationDTO[] = [];

    constructor() {
        this.subscriptionNotificationChange?.unsubscribe();
        this.subscriptionNotificationChange = this.listenerNotification.received.subscribe((obj: NotificationBaseDTO) => {
            if (obj) {
                this.alertVoicemailChangeNotification.next(obj);
            }
        });
    }

    GetNotificationListForClient(agentRef: number): Promise<NotificationContainerDTO> {
        return GeneralHelper.invokeServiceCall(agentRef, ServiceOperations.GetNotificationListForClient, this.logger);
    }

    DownloadVoiceMailMedia(voicemailRequestDTO: VoicemailRequestDTO): Promise<any> {
        return GeneralHelper.invokeServiceCall(voicemailRequestDTO, ServiceOperations.DownloadVoiceMailMedia, this.logger);
    }

    NotificationSetStatus(notificationRequest: NotificationStatusRequestDTO): Promise<any> {
        return GeneralHelper.invokeServiceCall(notificationRequest, ServiceOperations.NotificationSetStatus, this.logger);
    }

    RemoveNotification(notificationRequest: NotificationBaseDTO): Promise<any> {
        return GeneralHelper.invokeServiceCall(notificationRequest, ServiceOperations.RemoveNotification, this.logger);
    }
}

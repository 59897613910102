import { AcceptIcon, AddIcon, Alert, Avatar, Button, CallIcon, CloseIcon, Divider, EmailIcon, Flex, Header, Input, List, Text, Tooltip } from "@fluentui/react-northstar";
import React, { useState } from "react";
import Lockr from 'lockr';
import { useIntl } from "react-intl";
import { Subscription } from "rxjs";
import { customerJourneyManager } from "services/io/customer-journey";
import { ConferenceStatusAction, ResponseWrapper, StorageKeys } from "utils";
import { CallSessionDTO } from "utils/domain/callSessionDTO";
import { CustomerDTO, EmailAddressDTO, IJourneyCustomerSessionDTO, PhoneNumberDTO, PostJourneyCustomerAndSessionDTO } from "utils/domain/customerJourneyModels";

type EditProps = {
    EditCustomer: CustomerDTO | undefined,
    UpdateEditCustomer: (arg: CustomerDTO) => void
    editViewShown: boolean,
    updateEditView: (arg: boolean) => void,
    creating: boolean
  }
export const CustomerEditView: React.FC<EditProps> = ({EditCustomer, UpdateEditCustomer, editViewShown, updateEditView}) => {
    const intl = useIntl();

    const [currentCallSession, setCurrentCallSession] = useState<CallSessionDTO | undefined>(customerJourneyManager.currentCallSession.value);
    const [editCustomer, setEditCustomer] = useState(EditCustomer);

    const [phoneNumbers, setPhoneNumbers] = useState((EditCustomer?.PhoneNumbers !== undefined ? EditCustomer.PhoneNumbers : []));
    const [phoneNumberItems, setPhoneNumberItems] = useState([] as any);

    const [emailAddresses, setEmailAddresses] = useState((EditCustomer?.MailAddresses !== undefined ? EditCustomer.MailAddresses : []));
    const [emailAddressesList, setEmailAddressesList] = useState([] as any);

    const [name, setName] = useState(EditCustomer?.Name);
    const [surname, setSurname] = useState(EditCustomer?.Surname);

    const [errorPopupShown, setErrorPopupShown] = useState(false);
    const [errorMSG, setErrorMSG] = useState("An error has occured");

    const currentUserId = Lockr.get<string>(StorageKeys.UserId);

    let subscriptionCallSession: Subscription | null = null;

    React.useEffect(() => {
        initialize();        
    }, []);

    React.useEffect(() => {
        updateEditCustomer(EditCustomer);
    }, [EditCustomer]);

    React.useEffect(() => {
        mapPhoneNumbersList(phoneNumbers);
    }, [phoneNumbers]);

    React.useEffect(() => {
        mapEmailAddressesList(emailAddresses);
    }, [emailAddresses]);

    function initialize() {
        setCallSessionSubscription();
        mapPhoneNumbersList(editCustomer?.PhoneNumbers);
        mapEmailAddressesList(editCustomer?.MailAddresses);        
    }

    const setCallSessionSubscription = () => {
        subscriptionCallSession?.unsubscribe();
        subscriptionCallSession = customerJourneyManager.currentCallSession.subscribe((callSession: CallSessionDTO | undefined) => {
            setCurrentCallSession(callSession);
        });
    }

    
    const updateEditCustomer = (customer?: CustomerDTO | undefined) => {
        setEditCustomer(new CustomerDTO(customer));
        setName(customer?.Name);
        setSurname(editCustomer?.Surname);
        setPhoneNumbers((editCustomer?.PhoneNumbers !== undefined ? editCustomer.PhoneNumbers : []));
        setEmailAddresses((editCustomer?.MailAddresses !== undefined ? editCustomer.MailAddresses : []));
        mapPhoneNumbersList(customer?.PhoneNumbers);
        mapEmailAddressesList(customer?.MailAddresses);
    }

    function removePhoneNumber(phoneNumber: PhoneNumberDTO) {
        if (phoneNumbers === undefined || editCustomer === undefined) {
            return;
        }

        phoneNumbers.forEach((element, index) => {
            if (element.ID === phoneNumber.ID) {
                editCustomer.PhoneNumbers.splice(index, 1);
            }
        });
        setPhoneNumbers(phoneNumbers);
        mapPhoneNumbersList(editCustomer?.PhoneNumbers);
    }

    function addPhoneNumber(phoneNumber: PhoneNumberDTO) {
        var items: PhoneNumberDTO[] | undefined = [phoneNumber];

        if (phoneNumbers !== undefined) {
            items = items.concat(phoneNumbers);
        }

        setPhoneNumbers(items);
        mapPhoneNumbersList(items);
    }

    function updatePhoneNumber(phoneNumber: PhoneNumberDTO, number: string) {
        setPhoneNumbers((items: PhoneNumberDTO[]) => {
            let newNumber = true;
            if (items === undefined || items.length < 1) {
                items = [phoneNumber];
                return items;
            }
            let presentIndex: number = -1;
            items.forEach((element: PhoneNumberDTO, index: any) => {
                if (phoneNumber.ID != -1) {
                    if (element.ID === phoneNumber.ID) {
                        element.PhoneNumber = number;
                        newNumber = false;
                        presentIndex = index;
                    }
                } else if (element.PhoneNumber.toLocaleLowerCase() === phoneNumber.PhoneNumber.toLocaleLowerCase()) {
                    element.PhoneNumber = number;
                    newNumber = false;
                    presentIndex = items.indexOf(element);
                }

            });
            if (newNumber) {
                phoneNumber.PhoneNumber = number;
                items = items.concat(phoneNumber);
                presentIndex = items.indexOf(phoneNumber);
            }

            if (number === '' && presentIndex > -1) {
                items = items.filter((element) => items.indexOf(element) != presentIndex);
            }
            return items;
        })
    }

    function onlyNumberKey(evt: any) {
          
        // Only ASCII character in that range allowed
        var ASCIICode = (evt.which) ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
            return false;
        }
        return true;
    }

    const mapPhoneNumbersList = (phoneNumberDtos: PhoneNumberDTO[] | undefined) => {
        const items: any[] = [];

        if (!phoneNumberDtos) {
            setPhoneNumberItems(items);
            return;
        }

        phoneNumberDtos.forEach((element, index) => {
            items.push({
                key: element.ID,
                media: <CallIcon />,
                endMedia: <Tooltip
                trigger={<Button text icon={<CloseIcon size="medium" />} iconOnly onClick={(event) => {removePhoneNumber(element)}} />}
                content={intl.formatMessage({ id: "CustomerJourney.CustomerEditView.RemovePhone" })} />,
                content: <Input inverted required fluid maxLength={11} onKeyPress={(event) => onlyNumberKey(event)} placeholder={(element.PhoneNumber !== '' ? element.PhoneNumber : '31612345678')} onChange={(event: any) => {updatePhoneNumber(element, (event.target as HTMLInputElement).value)}} />,
                truncateContent: true,
              });
        });
        setPhoneNumberItems(items);
    }

    const addPhoneNumberList = (phoneNumberDTO: PhoneNumberDTO) => {
        const phoneNumberList = phoneNumberItems.splice(0);

        phoneNumberList.push({
            key: phoneNumberDTO.ID,
            media: <CallIcon />,
            endMedia: <Tooltip
            trigger={<Button text icon={<CloseIcon size="medium" />} iconOnly onClick={(event) => {removePhoneNumber(phoneNumberDTO)}} />}
            content={intl.formatMessage({ id: "CustomerJourney.CustomerEditView.RemovePhone" })} />,
            content: <Input inverted required fluid maxLength={11} onKeyPress={(event) => onlyNumberKey(event)} placeholder={(phoneNumberDTO.PhoneNumber !== '' ? phoneNumberDTO.PhoneNumber : '31612345678')} onChange={(event: any) => {updatePhoneNumber(phoneNumberDTO, (event.target as HTMLInputElement).value)}} />,
            truncateContent: true,
          });

          setPhoneNumberItems(phoneNumberList);
    }

    const mapEmailAddressesList = (emailAddressDtos: EmailAddressDTO[] | undefined) => {
        const items: any[] = [];

        if (emailAddressDtos === undefined || emailAddressDtos.length < 1) {
            setEmailAddressesList(items);
            return;
        }

        emailAddressDtos.forEach((element, index) => {
            items.push({
                key: element.ID,
                media: <EmailIcon />,
                endMedia: <Tooltip
                trigger={<Button text icon={<CloseIcon size="medium" />} iconOnly onClick={(event) => {removeEmailAddress(element)}} />}
                content={intl.formatMessage({ id: "CustomerJourney.CustomerEditView.RemoveEmail" })} />,
                content: <Input inverted required fluid placeholder={element.MailAddress}/>,
                truncateContent: true,
              });
        });
        setEmailAddressesList(items);
    }

    function removeEmailAddress(emailAddress: EmailAddressDTO) {
        if (emailAddresses === undefined || editCustomer === undefined) {
            return;
        }

        emailAddresses.forEach((element, index) => {
            if (element.ID === emailAddress.ID) {
                emailAddresses.splice(index, 1);
            }
        });
        setEmailAddresses(emailAddresses);
        mapEmailAddressesList(emailAddresses);
    }

    function addEmailAddress(emailAddress: EmailAddressDTO) {
        var items: EmailAddressDTO[] | undefined = [emailAddress];

        if (emailAddresses !== undefined) {
            items = items.concat(emailAddresses);
        }
        setEmailAddresses(items);
        mapEmailAddressesList(items);
    }

    const saveChanges = () => {
        if (editCustomer !== undefined) {
            if (!name || !surname || !phoneNumbers.length) {
                setErrorMSG("Some required fields are not filled!");
                setErrorPopupShown(true);
                return;
            }

            editCustomer.Name = (name !== undefined ? name : "");
            editCustomer.Surname = (surname !== undefined ? surname : "");
            editCustomer.MailAddresses = emailAddresses;
            editCustomer.PhoneNumbers = phoneNumbers;


            if (currentCallSession && editCustomer.PhoneNumbers.find((phoneNumber) => currentCallSession?.CallerUri.includes(phoneNumber.PhoneNumber))) {
                const journeyCustomerSession = {
                    JourneyCustomerRef: editCustomer.ID, CompanyRef: editCustomer.CompanyRef, SessionID: currentCallSession.SessionId, ConversationType: "Call",
                };
                customerJourneyManager.saveCustomerAndSession(editCustomer, journeyCustomerSession as IJourneyCustomerSessionDTO).then((response: PostJourneyCustomerAndSessionDTO) => {
                    console.log("Saved customer..");
                    console.log(response);
                    var editCustomerDto = new CustomerDTO(response.journeyCustomerDTO);
                    customerJourneyManager.currentCustomer.next(editCustomerDto);

                    if (currentCallSession.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.Accepted] 
                        && currentCallSession.AgentRef == currentUserId) {
                        customerJourneyManager.currentConversation.next(response.journeyCustomerSession);
                    }

                    updateEditView(false);
                }).catch(err => {
                    console.log(err);
                    setErrorMSG("Something went wrong while saving the changes to the Database!");
                    setErrorPopupShown(true);
                });
            } else {
                customerJourneyManager.saveCustomer(editCustomer).then((response: ResponseWrapper) => {
                    console.log("Saved customer..");
                    console.log(response);
                    var editCustomerDto = new CustomerDTO(response);
                    
                    customerJourneyManager.currentCustomer.next(editCustomerDto);
    
                    updateEditView(false);
                }).catch(err => {
                    setErrorMSG("Something went wrong while saving the changes to the Database!");
                    setErrorPopupShown(true);
                });
            }


        }
    }

    const getHeaderContent = (customerObject?: CustomerDTO) => {
        var message = "TitleAdd";
        
        if (customerObject && customerObject.ID != -1) {
            message = "TitleEdit";
        }

        return intl.formatMessage({ id: `CustomerJourney.CustomerEditView.${message}`});
    }

    const marginTopStype = { marginTop: "10px !important" };

    return (
        <Flex column space="between" styles={({ theme: { siteVariables } }) => ({
            backgroundColor: siteVariables.colorScheme.default.background,
            color: siteVariables.colorScheme.default.foreground,
        })}>
            {(errorPopupShown) && <Alert warning content={errorMSG} dismissible dismissAction={{onClick:() => setErrorPopupShown(false)}} visible={errorPopupShown} />}
            <Flex gap="gap.small" padding="padding.medium">
            <Flex.Item grow>
                    <Flex space="between">
                        <Header as="h2" content={getHeaderContent(EditCustomer)} styles={({ theme: {siteVariables} }) => ({
                            color: siteVariables.colorScheme.brand.foreground,
                            margin: "0px"
                        })}/>
                        <Flex gap="gap.small">
                            <Tooltip
                                trigger={<Button text icon={<AcceptIcon size="large" />} iconOnly onClick={() => {
                                    saveChanges();
                                }} />}
                            content={intl.formatMessage({ id: "CustomerJourney.CustomerEditView.SaveChanges"})} />
                            <Tooltip
                            trigger={<Button text icon={<CloseIcon size="large" />} iconOnly onClick={() => updateEditView(false)} />}
                            content={intl.formatMessage({ id: "CustomerJourney.CustomerEditView.CloseEdit"})} />
                        </Flex>
                    </Flex>
                </Flex.Item>
            </Flex>
            <Divider size={3}/>
            <Flex column space="between" styles={({ theme: { siteVariables } }) => ({
                backgroundColor: siteVariables.colorScheme.default.background,
                color: siteVariables.colorScheme.default.foreground,
                })}>
                <Flex gap="gap.small" padding="padding.medium">
                <Flex.Item grow>
                    <Flex space="between">
                        <Flex gap="gap.small">
                            <Avatar
                            name={`${(name !== undefined ? name : "C")} ${(surname !== undefined ? surname : "J")}`}
                            />
                            <Flex column>
                                <Text content={`${(name !== undefined ? name : "")} ${(surname !== undefined ? surname : "")}`} weight="bold" />
                                <Text content={(editCustomer?.ID !== -1 ? editCustomer?.CompanyRef : "")} size="small" />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex.Item>
            
                </Flex>
            </Flex>
            <Divider size={3}/>
            <Flex column styles={marginTopStype}>
                <Flex gap="gap.small" styles={{marginBottom: 0}}>
                    <Flex.Item grow>
                        <Text content={intl.formatMessage({ id: "CustomerJourney.CustomerInformationView.FirstName"})} weight="bold" size="medium" styles={{paddingLeft: "30px"}} />
                    </Flex.Item>
                </Flex>
                <Flex styles={{paddingTop: 0}}>
                    <Flex.Item styles={{marginBottom: 0}}>
                        <Input inverted required fluid placeholder={name} value={name} onChange={(event: any) => {setName((event.target as HTMLInputElement).value)}} styles={{paddingLeft: "20px"}} />
                    </Flex.Item>
                </Flex>
            </Flex>
            <Flex column styles={marginTopStype}>
                <Flex gap="gap.small" styles={{marginBottom: 0}}>
                    <Flex.Item grow>
                        <Text content={intl.formatMessage({ id: "CustomerJourney.CustomerInformationView.Surname"})} weight="bold" size="medium" styles={{paddingLeft: "30px"}} />
                    </Flex.Item>
                </Flex>
                <Flex gap="gap.small">
                    <Flex.Item grow>
                        <Input inverted required fluid placeholder={editCustomer?.Surname} onChange={(event: any) => {setSurname((event.target as HTMLInputElement).value)}} styles={{paddingLeft: "20px"}} />
                    </Flex.Item>
                </Flex>
            </Flex>
            <Divider size={1}/>
            <Flex column styles={marginTopStype}>
                <Flex gap="gap.small" vAlign="center">
                    <Flex.Item grow>
                        <Text content={intl.formatMessage({ id: "CustomerJourney.CustomerInformationView.PhoneNumbers"})} weight="bold" size="medium" styles={{paddingLeft: "30px"}} />
                    </Flex.Item>
                    <Flex.Item push>
                        <Tooltip
                            trigger={<Button text icon={<AddIcon size="medium" />} iconOnly onClick={() => {addPhoneNumberList(new PhoneNumberDTO({JourneyCustomerRef: editCustomer?.ID, IsActive: true, companyRef: editCustomer?.CompanyRef}))}} />}
                            content={intl.formatMessage({ id: "CustomerJourney.CustomerEditView.AddPhone" })} />
                    </Flex.Item>
                </Flex>
                <Flex gap="gap.small" styles={{paddingTop: "0px"}}>
                    <Flex.Item grow>
                        <List navigable truncateContent={true} items={phoneNumberItems} />
                    </Flex.Item>
                </Flex>
            </Flex>
            <Divider size={1}/>
            <Flex column styles={marginTopStype}>
                <Flex gap="gap.small" vAlign="center">
                    <Flex.Item grow>
                        <Text content={intl.formatMessage({ id: "CustomerJourney.CustomerInformationView.EmailAddresses"})} weight="bold" size="medium" styles={{paddingLeft: "30px"}} />
                    </Flex.Item>
                    <Flex.Item push>
                        <Tooltip
                            trigger={<Button text icon={<AddIcon size="medium" />} iconOnly onClick={() => {addEmailAddress(new EmailAddressDTO({JourneyCustomerRef: editCustomer?.ID, IsActive: true, companyRef: editCustomer?.CompanyRef}))}} />}
                            content={intl.formatMessage({ id: "CustomerJourney.CustomerEditView.AddEmail" })} />
                    </Flex.Item>
                </Flex>
                <Flex gap="gap.small" styles={{paddingTop: "0px"}}>
                    <Flex.Item grow>
                        <List navigable truncateContent={true} items={emailAddressesList} />
                    </Flex.Item>
                </Flex>
            </Flex>
        </Flex>
    )
};

export default CustomerEditView;
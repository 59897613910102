import { Subject } from "rxjs";
import { socketContacts } from "services/contacts";
import { socketOutbound } from "services/outbound";
import { supervisorManager } from "services/supervisor/supervisor-manager";
import { HttpMethod, logging, StorageKeys } from "utils";
import { TabDTO } from "utils/domain/extended/tabDTO";
import { TeamsClientInitSettingsDto } from "utils/domain/extended/teamsClientInitSettingsDto";
import { ClientInitialLoadOperations } from "utils/enums-s2";
import { GeneralHelper } from "utils/helpers";
import { ApiInitialClientLoad } from ".";
import { ExtendedWrapUpSettings } from "utils/domain/extendedWrapUpSettings";
import Lockr from "lockr";

export class InitialClientLoadService implements ApiInitialClientLoad {
    private readonly logger = logging.getLogger('InitialClientLoadService');
    initialSettings: TeamsClientInitSettingsDto | null = null;
    notifyInitialSettingsRequestCompleted: Subject<TeamsClientInitSettingsDto> = new Subject<TeamsClientInitSettingsDto>();
    notifyIsNewClientEnabledChange: Subject<boolean> = new Subject<boolean>();
    isInitialSettingsRequestDone: boolean = false;
    isInitialNavigationViewRequestDone: boolean = false;

    getInitialClientSettings(agentId: string): any {
        this.invokeCall({ agentId: agentId }, ClientInitialLoadOperations.GetInitialClientSettings, HttpMethod.Get)
            .then((response: TeamsClientInitSettingsDto) => {
                this.initialSettings = response;
                this.initialSettings.WrapUpSettings = new ExtendedWrapUpSettings(response.WrapUpSettings);
                this.isInitialSettingsRequestDone = true;
            
                socketOutbound.isAgentOutboundAccessEnabled(true, response.IsAgentOutboundAccessEnabled);
                supervisorManager.handleSupervisedQueueInfoResponse(response.SupervisedQueues);
                socketContacts.cacheAzureActiveDirectorySettings(response.AzureActiveDirectorySettings);
            
                const isNewClientEnabled = Lockr.get<boolean>(StorageKeys.IsNewClientEnabled);

                if (isNewClientEnabled != response.IsNewClientEnabled) {
                    this.notifyIsNewClientEnabledChange.next(response.IsNewClientEnabled);
                }

                this.notifyInitialSettingsRequestCompleted.next(response);
            });
    }

    setTabs(tabs: TabDTO[]): any {
        if (this.initialSettings) {
            this.initialSettings.Tabs = tabs;
        }
    }

    invokeCall(data: any, operation: string, httpMethod: string): Promise<any> {
        return GeneralHelper.invokeServiceCall(data, operation, this.logger, undefined, undefined, undefined, httpMethod);
    }
}
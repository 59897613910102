import { Flex } from "@fluentui/react-northstar";
import React, { useState } from "react";
import { Subscription } from "rxjs";
import { GeneralHelper } from "utils/helpers";
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import './externalTabsView.scss';

interface ExternalTabsViewProps {
    id: string | number;
    url: string;
}

export const ExternalTabsView = (props: ExternalTabsViewProps) => {
    let subscriptionRefreshExternalTab: Subscription | null = null;
    const [externalTabKey, setExternalTabKey] = useState(Math.random() + "extTabKey");

    const initialize = () => {
        GeneralHelper.logCox(`in ExternalTabsView.tsx, in initialize`);

        subscriptionRefreshExternalTab?.unsubscribe();
        subscriptionRefreshExternalTab = mainViewHelper.tabViewChange.subscribe((value: string | number) => {
            if (props.id === value) {
                setExternalTabKey(Math.random() + "extTabKey");
            }
        });
    };

    const componentWillUnmount = () => {
        subscriptionRefreshExternalTab?.unsubscribe();
    }

    React.useEffect(() => {
        initialize();
        return () => {
            componentWillUnmount();
        }
    }, []);

    return (
        <Flex key={externalTabKey} className="external-tab-wrapper">
            <iframe src={props.url} width="100%" height="100%" title="External tab" />
        </Flex>
    )
}

export default ExternalTabsView;

import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Menu, tabListBehavior, Flex, Box } from '@fluentui/react-northstar';
import { ConferenceStatusAction, MenuActiveItem, SharedStyleClasses, StorageKeys, TabViewIds } from '../../../utils';
import './mediaTabs.scss';
import { ExternalTabsView } from '../../../containers';
import { socketAudio } from '../../../services/calling';
import { CallSessionDTO } from '../../../utils/domain/callSessionDTO';
import { mainViewHelper } from '../../../utils/helpers/main-view-helper';
import { Subscription } from 'rxjs';
import { GeneralHelper } from '../../../utils/helpers';
import { extendedTabsService } from 'services/extendedTabs';
import { useIntl } from 'react-intl';
import { TabDTO } from 'utils/domain/extended/tabDTO';
import { queuesService, socketQueue } from 'services/queue';
import ExtendedCallHistory from './ExtendedCallHistory';
import { initialClientLoadService } from 'services/initialClientLoad';
import { TeamsClientInitSettingsDto } from 'utils/domain/extended/teamsClientInitSettingsDto';
import SupervisorView from '../ExtendedSupervisor/SupervisorView';
import { supervisorManager } from 'services/supervisor/supervisor-manager';
import { useOperationListenerEffect, useSubjectListenerEffect } from 'utils/helpers/listenerHook';
import { BackendNotification } from 'utils/enums-s3';
import { AgentMemberUpdatedDto } from 'services/extendedSupervisor/models/agentMemberUpdatedDto';
import Lockr from 'lockr';
import AgentView from '../ExtendedAgentView';

class ActiveMediaView {
    ActiveItem?: number;
    ActiveTabId?: string | number;
    TabDetails?: TabDTO;
}

interface TabData {
    key: string;
    content: string;
    onClick: () => void;
    tabcontent: any;
    tabid: string | number;
    active: boolean;
    viewtype: number;
    className?: string;
}

const ExtendedMediaTabs = () => {
    const startOperations = [ConferenceStatusAction[ConferenceStatusAction.Accepted]];
    const inACallOperations = [
        ConferenceStatusAction[ConferenceStatusAction.OnHold],
        ConferenceStatusAction[ConferenceStatusAction.Monitoring],
        ConferenceStatusAction[ConferenceStatusAction.MonitoringEnded],
        ConferenceStatusAction[ConferenceStatusAction.SupervisorClosed],
        ConferenceStatusAction[ConferenceStatusAction.Resumed],
    ];
    const intl = useIntl();
    const [activeTabId, setActiveTabId] = useState<string | number>(TabViewIds.Agent);
    const [dynamicTabs, setTabItems] = useState<TabData[]>([]);
    const isGetTabsInProcess = useRef<boolean>(false);
    const initSettingsRequestCompleted = useRef<boolean>(false);
    const [isSupervisor, setIsSupervisor] = useState<boolean>(false);
    const userIdRef = useRef<string>(Lockr.get<string>(StorageKeys.UserId));

    let subscriptionCurrentUserCallSessionChanged: Subscription | null = null;
    let subscriptionTabsChange: Subscription | null = null;
    let subscriptionTabViewChange: Subscription | null = null;
    let subscriptionSocketQueueChange: Subscription | null = null;
    let subscriptionInitialSettingsRequestCompleted: Subscription | null = null;

    useEffect(() => {
        initialize();

        return () => componentWillUnmount();
    }, []);

    useOperationListenerEffect((agentsUpdate: AgentMemberUpdatedDto) => {
        if (!agentsUpdate) {
            return;
        }
        const shouldUpdate = agentsUpdate.Deleted.some(x => x === userIdRef.current) ||
            agentsUpdate.Updated.some(x => x.Id === userIdRef.current);

        if (!shouldUpdate) {
            return;
        }

        supervisorManager.checkIsUserSupervisor();
    }, BackendNotification.SupervisorAgentsUpdated);

    useSubjectListenerEffect((status: boolean) => {
        setIsSupervisor(status);

        if (!status && activeTabId == TabViewIds.Supervisors) {
            changeView({ ActiveItem: MenuActiveItem.AgentView, ActiveTabId: TabViewIds.Agent });
        }
    }, supervisorManager.onIsUserSupervisorCheckDone);

    const initialize = () => {
        loadExternalTabs(true);

        subscriptionSocketQueueChange?.unsubscribe();
        subscriptionSocketQueueChange = socketQueue.subjectQueueChange.subscribe((_: any) => {
            loadExternalTabs();
        });

        subscriptionTabsChange?.unsubscribe();
        subscriptionTabsChange = extendedTabsService.listenerNotifyTabsChange.received.subscribe((obj: any) => {
            loadExternalTabs();
        });

        subscriptionTabViewChange?.unsubscribe();
        subscriptionTabViewChange = mainViewHelper.tabViewChange.subscribe((tabId: string | number) => {
            if (tabId === TabViewIds.Agent) {
                changeView({ ActiveItem: MenuActiveItem.AgentView, ActiveTabId: TabViewIds.Agent });
            }
        });

        subscriptionCurrentUserCallSessionChanged?.unsubscribe();
        subscriptionCurrentUserCallSessionChanged = socketAudio.callSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
            getExternalTabs(callStatePermitsGettingExternalTabs(callSessionDTO), callSessionDTO, !startOperations.includes(callSessionDTO.ConferenceActionAsString));
        });

        subscriptionInitialSettingsRequestCompleted = initialClientLoadService.notifyInitialSettingsRequestCompleted.subscribe((settings: TeamsClientInitSettingsDto) => {
            loadExternalTabs(true);
        });

        loadStoredTab();
    };

    const loadStoredTab = () => {
        const hasSupervisorRights = queuesService.hasSupervisorRights();
        setIsSupervisor(hasSupervisorRights);

        const storedActiveTab = localStorage.getItem(StorageKeys.ExtendedMediaActiveTab) || `${TabViewIds[TabViewIds.Agent]}`;

        if (storedActiveTab == `${TabViewIds.CallHistory}`) {
            changeView({ ActiveItem: MenuActiveItem.CallHistory, ActiveTabId: TabViewIds.CallHistory });
        } else if (storedActiveTab == `${TabViewIds.Supervisors}`) {
            changeView({ ActiveItem: MenuActiveItem.Supervisor, ActiveTabId: TabViewIds.Supervisors });
        }
    }

    const callStatePermitsGettingExternalTabs = (callSession: CallSessionDTO) => {
        const isCallParked = GeneralHelper.isCallParkedOrFallback(callSession.ActionHistory, ConferenceStatusAction.OperatorPark);
        const isFallbackCall = GeneralHelper.isCallParkedOrFallback(callSession.ActionHistory, ConferenceStatusAction.Fallback);
        const hasCallOperations = startOperations.includes(callSession.ConferenceActionAsString) && !GeneralHelper.isRinging(callSession.ActionHistory)

        return (hasCallOperations || inACallOperations.includes(callSession.ConferenceActionAsString)) &&
            !isCallParked &&
            !isFallbackCall &&
            !callSession.IsTeamsUnparkInProgress &&
            !callSession.IsWarmTransferInProgress &&
            !callSession.IsColdTransferInProgress &&
            !callSession.ActionHistory.includes(ConferenceStatusAction.WarmAccepted);
    }

    const loadExternalTabs = (isInit: boolean = false) => {
        if (isInit) {
            loadExternalTabsFromCache();
            return;
        }
        socketAudio.getCurrentCallSession();
        const callSession = socketAudio.getCallSessionForCurrentUser();
        getExternalTabs(!!callSession.ConversationKey, callSession);
    }

    const loadExternalTabsFromCache = () => {
        if (!initialClientLoadService.isInitialSettingsRequestDone || !initialClientLoadService.initialSettings || initSettingsRequestCompleted.current) {
            return;
        }

        initSettingsRequestCompleted.current = true;
        socketAudio.getCurrentCallSession();
        const callSession = socketAudio.getCallSessionForCurrentUser();
        const cachedTabs = initialClientLoadService.initialSettings.Tabs;
        createTabs(cachedTabs, !!callSession.ConversationKey, callSession);
    }

    const getExternalTabs = (isInACall: boolean, callSessionDTO: CallSessionDTO, useCache: boolean = false) => {
        if (isGetTabsInProcess.current || !initSettingsRequestCompleted.current) {
            return;
        }

        isGetTabsInProcess.current = true;

        if (useCache && initialClientLoadService.initialSettings) {
            const cachedTabs = initialClientLoadService.initialSettings.Tabs;
            createTabs(cachedTabs, isInACall, callSessionDTO);
            isGetTabsInProcess.current = false;
            return;
        }

        extendedTabsService.GetExternalTabs()
            .then((tabs: TabDTO[]) => {
                initialClientLoadService.setTabs(tabs);
                createTabs(tabs, isInACall, callSessionDTO);
            })
            .finally(() => {
                setTimeout(() => {
                    isGetTabsInProcess.current = false;
                }, 200);
            });
    }

    const createTabs = (tabs: TabDTO[], isInACall: boolean, callSessionDTO: CallSessionDTO) => {
        let wasActiveTabDeleted = !Number.isInteger(activeTabId);
        tabs = filterTabsOnNonIvrCall(tabs, callSessionDTO);

        const newTabs = tabs
            .filter(tab => (isInACall && tab.InMediaHandlingOnly && tab.QueueId === callSessionDTO.QueueRef) || !tab.InMediaHandlingOnly)
            .map(tab => {
                if (tab.IsPopup) {
                    return createPopupTab(tab);
                }

                if (activeTabId === tab.Id) {
                    wasActiveTabDeleted = false;
                }

                return createInsideTab(tab);
            });

        if (wasActiveTabDeleted) {
            changeView({ ActiveItem: MenuActiveItem.AgentView, ActiveTabId: TabViewIds.Agent });
        }

        setTabItems(newTabs);
    }

    const filterTabsOnNonIvrCall = (tabs: TabDTO[], callSessionDTO: CallSessionDTO) => {
        if (!!callSessionDTO.ActionHistory &&
            (callSessionDTO.ActionHistory.includes(ConferenceStatusAction.OperatorColdTransfered) ||
                callSessionDTO.ActionHistory.includes(ConferenceStatusAction.WarmAccepted))) {
            return tabs.filter(x => !x.InMediaHandlingOnly);
        }

        return tabs;
    }

    const createInsideTab = (tab: TabDTO): TabData => {
        return {
            key: `${tab.Name}${Math.random()}`,
            content: tab.Name,
            onClick: () => { changeView({ ActiveItem: MenuActiveItem.ExternalTab, TabDetails: tab, ActiveTabId: tab.Id }) },
            tabcontent: <ExternalTabsView id={tab.Id} url={tab.Url} />,
            tabid: tab.Id,
            active: activeTabId === tab.Id,
            viewtype: MenuActiveItem.ExternalTab,
            className: SharedStyleClasses.TabUnderline
        };
    }

    const createPopupTab = (tab: TabDTO): TabData => {
        return {
            key: `${tab.Name}${Math.random()}`,
            content: tab.Name,
            onClick: () => { openLink(tab) },
            tabcontent: null,
            tabid: tab.Id,
            active: false,
            viewtype: MenuActiveItem.ExternalLink,
            className: `external-link ${SharedStyleClasses.TabUnderline}`
        };
    }

    const changeView = (activeItem: ActiveMediaView) => {
        localStorage.setItem(StorageKeys.ExtendedMediaActiveTab, `${activeItem.ActiveTabId}`);

        setActiveTabId(activeItem.ActiveTabId || TabViewIds.Agent);
        setTabItems((currentState: any) => setActiveState(currentState, activeItem));

        if (activeItem.ActiveTabId && !Number.isInteger(activeItem.ActiveTabId)) {
            mainViewHelper.tabViewChange.next(activeItem.ActiveTabId);
        }
    }

    const setActiveState = (currentState: TabData[], activeItem: ActiveMediaView) => {
        currentState.forEach((tab: TabData) => tab.active = tab.tabid === activeItem.ActiveTabId);

        return currentState;
    }

    const openLink = (tab: TabDTO) => {
        window.open(tab.Url, tab.Name, "height=600,width=600");
    }

    const componentWillUnmount = () => {
        subscriptionCurrentUserCallSessionChanged?.unsubscribe();
        subscriptionTabsChange?.unsubscribe();
        subscriptionTabViewChange?.unsubscribe();
        subscriptionSocketQueueChange?.unsubscribe();
        subscriptionInitialSettingsRequestCompleted?.unsubscribe();
    }

    const staticTabs = useMemo<TabData[]>(() => {
        const tabs: TabData[] = [
            {
                key: `agent`,
                content: intl.formatMessage({ id: "MediaTabs.Agent" }),
                onClick: () => { changeView({ ActiveItem: MenuActiveItem.AgentView, ActiveTabId: TabViewIds.Agent }) },
                tabcontent: <AgentView />,
                tabid: TabViewIds.Agent,
                active: activeTabId === TabViewIds.Agent,
                viewtype: MenuActiveItem.AgentView,
                className: SharedStyleClasses.TabUnderline
            },
            {
                key: `callhistory`,
                content: intl.formatMessage({ id: "SettingsView.CallHistory" }),
                onClick: () => { changeView({ ActiveItem: MenuActiveItem.CallHistory, ActiveTabId: TabViewIds.CallHistory }) },
                tabcontent: <ExtendedCallHistory />,
                tabid: TabViewIds.CallHistory,
                active: activeTabId === TabViewIds.CallHistory,
                viewtype: MenuActiveItem.CallHistory,
                className: SharedStyleClasses.TabUnderline
            }
        ];

        if (isSupervisor) {
            const supervisorView: TabData = {
                key: `supervisor`,
                content: intl.formatMessage({ id: "MediaTabs.Supervisor" }),
                onClick: () => { changeView({ ActiveItem: MenuActiveItem.Supervisor, ActiveTabId: TabViewIds.Supervisors }) },
                tabcontent: <SupervisorView />,
                tabid: TabViewIds.Supervisors,
                active: activeTabId === TabViewIds.Supervisors,
                viewtype: MenuActiveItem.Supervisor,
                className: SharedStyleClasses.TabUnderline
            };
            tabs.splice(1, 0, supervisorView);
        }

        return tabs;
    }, [activeTabId, isSupervisor]);

    const getTabViewContent = (tab: TabData, index: number, tabKey: string) => (
        <Box
            className={activeTabId === tab.tabid ? "active-tab" : "hidden-tab"}
            key={`${tabKey}-${index}`}
        >
            {tab.tabcontent}
        </Box>);

    return (
        <>
            <Flex className="menu-tabs-container">
                <Flex.Item>
                    <Menu
                        items={staticTabs}
                        underlined
                        primary
                        accessibility={tabListBehavior}
                        aria-label="External tabs"
                        styles={() => ({
                            margin: '0 0 20px',
                        })}
                        className="menu-tabs"
                    />
                </Flex.Item>
                <Flex.Item>
                    <Menu
                        items={dynamicTabs}
                        underlined
                        primary
                        accessibility={tabListBehavior}
                        aria-label="External links"
                        styles={() => ({
                            margin: '0 0 20px',
                        })}
                        className="menu-tabs menu-links"
                    />
                </Flex.Item>
            </Flex>
            <div key="staticTabContentKey">
                {
                    staticTabs.map((tab: TabData, index: number) => getTabViewContent(tab, index, 'agentTab-id'))
                }
            </div>
            <div key={"externalTabs"}>
                {
                    dynamicTabs
                        .filter(x => x.viewtype === MenuActiveItem.ExternalTab)
                        .map((tab: TabData, index: number) => getTabViewContent(tab, index, 'externalTab-id'))
                }
            </div>
        </>
    )
};

export default ExtendedMediaTabs;

import { Flex } from "@fluentui/react-northstar";
import React from "react";
import VoiceMailsTabs from "./VoiceMailsTabs";

export const VoiceMailsView = ({ voiceMailData }: any) => {

    return (
        <Flex.Item>
            <Flex column
                styles={({ theme: { siteVariables } }) => ({
                    backgroundColor: siteVariables.colorScheme.default.background2,
                    color: siteVariables.colorScheme.default.foreground2,
                })}
            >
                <VoiceMailsTabs key="voicemails-tabs-container-key" voiceMailData={voiceMailData} />
            </Flex>
        </Flex.Item>
    );
};
export default VoiceMailsView;


import { Button, Flex, Menu, tabListBehavior, Text } from "@fluentui/react-northstar";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Subscription } from "rxjs";
import { extendedVoicemailService } from "services/voiceMails/extendedVoicemailsApi";
import { MenuActiveItem, TabViewIds } from "utils";
import ExtentedVoicemailsTabContentView from "../VoicemailsTabContentView/ExtendedVoicemailsTabContetView";
import './voicemailsTabs.scss';

export const ExtendedNotificationTabs = () => {
    const intl = useIntl();
    let subscriptionNewVoicemails: Subscription | null = null;

    const getVoicemailTabHeader = () => {
        return {
            key: `voicemails-tab-header-${Math.random()}`,
            content: <Flex >
                <Text content={`${intl.formatMessage({ id: "VoiceMailsTabs.Voicemails" })}`} />
                {
                    newVoicemailsNotifications.current.length > 0 && <Button key="new-voicemails-counter" className="voicemail-notification-tab-count-extended" circular
                        content={newVoicemailsNotifications.current.length} size="small" />
                }
            </Flex >,
            tabid: TabViewIds.VoiceMailVoiceMails,
            active: true,
            viewtype: MenuActiveItem.VoiceMailVoiceMails
        }
    }

    const newVoicemailsNotifications = useRef([] as any);
    const [voicemailTab, setVoicemailTab] = useState([getVoicemailTabHeader()] as any[]);

    React.useEffect(() => {
        initialize();
        return () => componentWillUnmount();
    }, []);

    const componentWillUnmount = () => {
        subscriptionNewVoicemails?.unsubscribe();
    }

    const initialize = () => {
        subscriptionNewVoicemails = extendedVoicemailService.newVoicemailNotification.subscribe(() => {
            newVoicemailsNotifications.current = extendedVoicemailService.newVoicemails;
            reRenderHeaderOnNewVoicemalCountChanged();
        });

        newVoicemailsNotifications.current = extendedVoicemailService.newVoicemails;
        reRenderHeaderOnNewVoicemalCountChanged();
    }

    const reRenderHeaderOnNewVoicemalCountChanged = () => {
        setVoicemailTab([getVoicemailTabHeader()]);
    }

    return (
        <>
            <Flex className="menu-tabs-container" key="menu-tabs-container-flex-key">
                <Flex.Item key="voicemail-menu-flex-item">
                    <Menu key="voicemail-menu"
                        underlined
                        primary
                        accessibility={tabListBehavior}
                        aria-label="Voicemail tabs"
                        styles={{
                            margin: '0 0 20px',
                        }}
                        className="menu-tabs"
                        items={voicemailTab}
                    />
                </Flex.Item>
            </Flex>
            <ExtentedVoicemailsTabContentView key='voicemails-tab-content-view' />
        </>
    );
};

export default ExtendedNotificationTabs;

import { Button, Dialog, EyeFriendlierIcon, EyeSlashIcon, PlayIcon, TrashCanIcon, Text } from "@fluentui/react-northstar";
import AdvancedTable, { stringCellComparator } from "components/AdvancedTable";
import { Paginator } from "containers";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import ReactPlayer from "react-player";
import { queuesService } from "services/queue";
import { StorageKeys } from "utils";

import { GeneralHelper } from "utils/helpers";
import Lockr from 'lockr';
import './voiceTabContenView.scss';
import { socketOutbound } from "services/outbound";
import { Subscription } from "rxjs";
import { extendedVoicemailService } from "services/voiceMails/extendedVoicemailsApi";
import { VoicemailHistoryDto } from "utils/domain/voiceMailsView/extendedVoicemailHistoryDto";

export const ExtentedVoicemailsTabContentView = () => {
    const intl = useIntl();
    const agentHasOutboundEnabled = useRef(false);
    const userVoicemailList = useRef([] as VoicemailHistoryDto[]);
    const [displayedVoiceMailsPerPage, setDisplayedVoiceMailsPerPage] = useState([] as any[]);
    const displayedVoiceMails = useRef([] as any[]);
    const supervisorUserQueues = useRef([] as any[]);
    const [idOfVoicemailToDelete, setIdOfVoicemailToDelete] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const currentPageRef = useRef(1);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const numberOfPagesRef = useRef(1);
    const ElementsPerPage = 10;
    const bigGrowBoldClassValue = "bold-text big-grow";

    const columnsMembers = [
        {
            title: intl.formatMessage({ id: "VoiceMailsTabs.From" }), className: 'big-grow',
            key: 'CallerSip' + Math.random(), name: 'CallerSipColumn', cellComparator: stringCellComparator
        },
        {
            title: intl.formatMessage({ id: "VoiceMailsTabs.Queue" }), className: 'big-grow',
            key: 'QueueName' + Math.random(), name: 'QueueNameColumn', cellComparator: stringCellComparator
        },
        {
            title: intl.formatMessage({ id: "VoiceMailsTabs.Date" }), className: 'big-grow',
            key: 'RecordDate' + Math.random(), name: 'RecordDateColumn', cellComparator: stringCellComparator
        },
        {
            title: '', className: 'big-grow',
            key: 'AudioPlayer' + Math.random(), name: 'AudioPlayerColumn', cellComparator: stringCellComparator
        },
        {
            title: '', className: 'big-grow',
            key: 'AudioPlayerButtons' + Math.random(), name: 'AudioPlayerButtonsColumn', cellComparator: stringCellComparator
        }
    ];

    let subscriptionAgentHasOutboundAccessEnabled: Subscription | null = null;
    let subscriptionInvalidateVoicemailCache: Subscription | null = null;
    let subscriptionVoicemailChangedServerSide: Subscription | null = null;

    React.useEffect(() => {
        initialize();
        return () => componentWillUnmount();
    }, []);

    const componentWillUnmount = () => {
        subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
        subscriptionInvalidateVoicemailCache?.unsubscribe();
        subscriptionVoicemailChangedServerSide?.unsubscribe();
    }

    const initialize = () => {
        initVoicemailsView(true);

        subscriptionAgentHasOutboundAccessEnabled?.unsubscribe();
        subscriptionAgentHasOutboundAccessEnabled = socketOutbound.agentHasOutboundAccessEnabled.subscribe((isOutboundEnabled: boolean) => {
            if (agentHasOutboundEnabled.current !== isOutboundEnabled) {
                agentHasOutboundEnabled.current = isOutboundEnabled;
                mapVoiceMailsDisplayedItems();
            }
        });

        subscriptionInvalidateVoicemailCache = extendedVoicemailService.subscriptionInvalidateVoicemailCache.subscribe(() => {
            initVoicemailsView();
        });

        subscriptionVoicemailChangedServerSide = extendedVoicemailService.subscriptionVoicemailChanged.subscribe(() => {
            initVoicemails();
        });
    }

    const setUserSupervisedQueues = async () => {
        const queuesArray = queuesService.queuesArray;
        const supervisedQueues = queuesArray.length === 0 ?
            await queuesService.getQueues(Lockr.get<string>(StorageKeys.UserId)) :
            queuesArray;

        supervisorUserQueues.current = supervisedQueues
            .filter(x => x.IsSupervisor)
            .map(x => x.QueueRef);
    }

    const initVoicemailsView = async (isOnComponentLoad: boolean = false) => {
        await setUserSupervisedQueues();
        initVoicemails(isOnComponentLoad);
    }

    const initVoicemails = async (isOnComponentLoad: boolean = false) => {
        userVoicemailList.current = await extendedVoicemailService.getCachedVoicemails();

        userVoicemailList.current.forEach(x => {
            x.CanBeDeleted = supervisorUserQueues.current.includes(x.QueueId);

            if (isOnComponentLoad) {
                x.IsVisible = false;
            }
        });
        agentHasOutboundEnabled.current = socketOutbound.HasOutboundAccesEnabled();
        mapVoiceMailsDisplayedItems();
    }

    const sortVoicemailsByDate = (records: VoicemailHistoryDto[]) => {
        return records.sort((a, b) => (a?.StartDate > b?.StartDate) ? -1 : ((b.StartDate > a.StartDate) ? 1 : 0))
    }

    const mapVoiceMailsDisplayedItems = () => {
        const displayedItems = [] as any[];
        const sortedItems = sortVoicemailsByDate(userVoicemailList.current);

        sortedItems.forEach((el) => {
            displayedItems.push({
                key: `voice-mail-item${el.Id}}`,
                items: [
                    {
                        key: `${el.Id}caller-sip${Math.random()}`,
                        content: el.CallerName ? el.CallerName : el.CallerId,
                        className: el.IsRead ? "big-grow" : bigGrowBoldClassValue
                    },
                    {
                        key: `${el.Id}queue-name${Math.random()}`,
                        content: el.QueueName,
                        className: el.IsRead ? "big-grow" : bigGrowBoldClassValue
                    },
                    {
                        key: `${el.Id}record-date${Math.random()}`,
                        content: GeneralHelper.formatAMPM(el.StartDate, true),
                        className: el.IsRead ? "big-grow" : bigGrowBoldClassValue
                    },
                    {
                        key: `${el.Id}audio-player`,
                        content: el.FilePath ? <>
                            {
                                el.IsVisible && <ReactPlayer
                                    className="react-player-item"
                                    url={el.FilePath ? el.FilePath : ''}
                                    height="30px"
                                    controls
                                    playing
                                    key={"voicemails-audio-player" + el.Id}
                                />
                            }
                        </> :
                            <>
                                {
                                    el.IsVisible && <Text content={intl.formatMessage({ id: "VoiceMailsTabs.NoVoicemailFound" })} />
                                }
                            </>,
                        className: "big-grow"
                    },
                    {
                        key: `${el.Id}audio-player-buttons${Math.random()}`,
                        content: <div className="voice-mail-action-buttons">
                            {
                                !el.IsVisible && <Button key={"voicemails-play-button" + el.Id} iconOnly text icon={<PlayIcon key={"voicemails-play-icon" + el.Id} />}
                                    title="Play Voice Mail"
                                    size="medium"
                                    onClick={() => viewVoicemail(el)} />
                            }
                            {el.CanBeDeleted && <Button key={"voicemails-delete-button" + el.Id} iconOnly text icon={<TrashCanIcon key={"voicemails-not-delete-icon" + el.Id} />}
                                title="Delete Voice Mail"
                                size="medium"
                                onClick={() => toggleDeleteVoicemail(el)} />
                            }
                            {el.IsRead ?
                                <EyeFriendlierIcon title={el.AgentId ? el.AgentId : ""} className="view-icon" key={"voicemails-viewed-icon" + el.Id} size="large" /> :
                                <EyeSlashIcon className="view-icon" key={"voicemails-not-viewed-icon" + el.Id} size="large" />
                            }
                        </div>,
                        className: el.IsRead ? "voice-mail-action-buttons-container" : "bold-text voice-mail-action-buttons-container"
                    }
                ]
            });
        });

        displayedVoiceMails.current = displayedItems;
        initNumberOfPages();
        selectPage(currentPageRef.current);
    }

    const viewVoicemail = (voicemailRecord: VoicemailHistoryDto) => {
        if (voicemailRecord.IsVisible) {
            return;
        }

        userVoicemailList.current.forEach((el) => {
            if (el.Id === voicemailRecord.Id) {
                el.IsVisible = true;
                el.IsRead = true;
            } else {
                el.IsVisible = false;
            }
        });

        if (!voicemailRecord.AgentId) {
            updateVoicemail(voicemailRecord);
        }

        mapVoiceMailsDisplayedItems();
    }

    const updateVoicemail = (voiceMailRecordDTO: VoicemailHistoryDto) => {
        const userId = Lockr.get<string>(StorageKeys.UserId);

        voiceMailRecordDTO.AgentId = userId;
        extendedVoicemailService.updateVoicemail(voiceMailRecordDTO);
    }

    const deleteVoicemail = (id: string) => {
        if (!id) {
            setIdOfVoicemailToDelete('');
            return;
        }

        const itemsToDelete = userVoicemailList.current.filter(x => x.Id === id);

        if (!itemsToDelete || !itemsToDelete.length) {
            setIdOfVoicemailToDelete('');
            return;
        }

        const userId = Lockr.get<string>(StorageKeys.UserId);
        const itemToDelete = itemsToDelete[0];
        itemToDelete.AgentId = userId;

        extendedVoicemailService.deleteVoicemail(itemToDelete);
        setIdOfVoicemailToDelete('');
    }

    const toggleDeleteVoicemail = (voicemail: VoicemailHistoryDto) => {
        setIdOfVoicemailToDelete(voicemail.Id!);
    }

    const selectPage = (page: any) => {
        if (page > numberOfPagesRef.current) {
            page = 1;
        }

        const displayedItems = displayedVoiceMails.current.slice((page - 1) * ElementsPerPage, page * ElementsPerPage);
        setDisplayedVoiceMailsPerPage(displayedItems);
        setCurrentPage(page);
        currentPageRef.current = page;
    }

    const initNumberOfPages = () => {
        const itemsCount = displayedVoiceMails.current.length;
        if (!itemsCount) {
            setNumberOfPages(1);
            numberOfPagesRef.current = 1;
            return;
        }

        const nrOfPages = itemsCount % ElementsPerPage === 0 ? Math.floor(itemsCount / ElementsPerPage) : Math.floor(itemsCount / ElementsPerPage) + 1;
        setNumberOfPages(nrOfPages);
        numberOfPagesRef.current = nrOfPages;
    }

    return (
        <>
            <AdvancedTable key="voicemail-tab-table-key" className="voicemails-table" columns={columnsMembers} rows={displayedVoiceMailsPerPage}
                label={intl.formatMessage({ id: "VoiceMailsTabs.Voicemails" })} />
            {numberOfPages > 1 && <Paginator key="voicemail-tab-paginator-key"
                currentPage={currentPage}
                totalPages={numberOfPages}
                onChange={selectPage}
            />}
            <Dialog
                open={idOfVoicemailToDelete !== ''}
                confirmButton={intl.formatMessage({ id: "VoiceMailsTabs.DeleteVoicemailConfirm" })}
                onConfirm={() => deleteVoicemail(idOfVoicemailToDelete)}
                cancelButton={intl.formatMessage({ id: "VoiceMailsTabs.DeleteVoicemailCancel" })}
                onCancel={() => setIdOfVoicemailToDelete('')}
                header={intl.formatMessage({ id: "VoiceMailsTabs.DeleteVoicemail" })}
                content={intl.formatMessage({ id: "VoiceMailsTabs.VoicemailWillBeDeletedMessage" })}
                styles={{ width: "500px" }}
            />
        </>
    )
};

export default ExtentedVoicemailsTabContentView;

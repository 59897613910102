import { Divider, Flex, Input, Text } from "@fluentui/react-northstar";
import React from "react";
import { useIntl } from "react-intl";

interface SubjectInputProps {
    value: string;
    onInputChange: (value: string) => void;
}

const SubjectInput = (props: SubjectInputProps) => {
    const { value, onInputChange } = props;
    const intl = useIntl();

    return (
        <Flex 
            gap="gap.small"
            vAlign="center"
            column
        >
            <Flex
                vAlign="center"
                gap="gap.small"
                className="em-header"
            >
                <Text content={intl.formatMessage({ id: 'EmailEditor.Subject' })} />
                <Input
                    fluid
                    value={value}
                    onChange={(e: any) => onInputChange(e.target.value)}
                    variables={{ backgroundColor: 'white', inputPadding: '0.3571rem 0.1rem' }}
                />
            </Flex>
            <Divider
                fitted
                size={0}
                className="em-divider-padding"
            />
        </Flex>
    );
}

export default SubjectInput;

import { serviceCall } from "./service-call";
import { InvokeServiceArgs } from "./interfaces";
import { logging } from 'utils/logging';
import { ServiceTypes, StorageKeys, ServiceOperations, Controllers } from 'utils';
import { EnvHelper, GeneralHelper } from 'utils/helpers';
import Lockr from 'lockr';
import { Subject } from "rxjs";

export class ServiceRequests {
  private readonly logger = logging.getLogger('ServiceRequests');
  internalPresenceChange: Subject<any> = new Subject<any>();

  private invokeServiceRequest<T>(service: ServiceTypes, operation: string, argsObj: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const args: InvokeServiceArgs = {
        operation: operation,
        requestData: argsObj,
        responseHandler: {
          success: (result) => {
            this.logger.info(`${service} ${operation} response received: ${JSON.stringify(result)}`);

            resolve(result);
          },
          error: (err) => {
            logging.errorHandler.next("ErrorMessage.Offline");
            this.logger.error(err);

            reject(err);
          }
        }
      };

      serviceCall.invokeService(args);
    });
  }

  GetNotes(): Promise<any[]> {
    return this.invokeServiceRequest<any[]>(ServiceTypes.CallCenter, ServiceOperations.GetReasonsFromCache, { CompanyRef: Lockr.get<string>(StorageKeys.CompanyId) });
  }

  GetUserBySip(sip: string): Promise<any> {
    // Stage3 - not found
    if (EnvHelper.isStage3()) {
      return Promise.reject(null);
    }
    // Stage2
    return this.invokeServiceRequest<any>(ServiceTypes.CallCenter, ServiceOperations.GetUserBySip, { Sip: sip });
  }

  GetWrapUpCollectionTopicMapList(): Promise<any[]> {
    return this.invokeServiceRequest<any>(ServiceTypes.CallCenter, ServiceOperations.GetAllWrapUpCollectionTopicMap, null);
  }

  SaveWrapUpHistory(wrapUpHistoryDto: any) {
    this.invokeServiceRequest<any>(ServiceTypes.CallCenter, ServiceOperations.SaveWrapUpHistory, wrapUpHistoryDto);
  }

  CancelWrapUp(sip: string, presence: string, note: string) {
    this.invokeServiceRequest<any>(ServiceTypes.CallCenter, ServiceOperations.CancelWrapUp, { Sip: sip, Presence: presence, Note: note });
  }

  ChangePresence(sip: string, presence: string, note: string | undefined, id: string, changedBy: string | undefined = undefined, wrapUpCodeId: string | undefined = undefined, wrapUpCodeName : string | undefined = undefined ) {
    const data = { 
      Sip: sip, 
      Presence: presence, 
      Note: note, 
      Id: id, 
      ChangedBy: changedBy,
      WrapUpCodeId: wrapUpCodeId,
      WrapUpCodeName: wrapUpCodeName
    };
    
    GeneralHelper.invokeServiceCall(data, ServiceOperations.ChangePresence, this.logger, Controllers.Presence)
      .then(_ => {
        this.internalPresenceChange.next(data);
      });
  }

  ChangeWrapUpCode(wrapUpCodeId: string | undefined = undefined, wrapUpCodeName : string | undefined = undefined ) {
    const data = { 
      Id: Lockr.get<string>(StorageKeys.UserId), 
      WrapUpCodeId: wrapUpCodeId,
      WrapUpCodeName: wrapUpCodeName
    };
    
    GeneralHelper.invokeServiceCall(data, ServiceOperations.ChangeWrapUpCode, this.logger, Controllers.Presence);    
  }
}

export const serviceRequests = new ServiceRequests();

export enum SoundType {
    Notification,
    Tone,
    Busy
}

export const SoundTypeDuration = {
    Notification: 2000,
    Tone: 300,
    Busy: 5000,
    Default: 1000
}

export default class PlaySoundHelper {
    private static IsPlaying: boolean = false;
    private static readonly NotificationSound = new Audio('./assets/audio/notification-ping.mp3');
    private static readonly ToneSound = new Audio('./assets/audio/send-dtmf-tone.wav');

    public static playSound = (soundType: SoundType, path: string | undefined = undefined) => {
        const initialDelay = soundType === SoundType.Busy ? 800 : 0;
        const audioFile = this.getAudioFile(soundType, path);
        const duration = this.getSoundDuration(soundType);

        setTimeout(() => {
            if (!PlaySoundHelper.IsPlaying && audioFile) {
                PlaySoundHelper.IsPlaying = true;
                audioFile.play();

                setTimeout(() => {
                    PlaySoundHelper.IsPlaying = false;
                }, duration)
            }
        }, initialDelay)
    }

    private static readonly getSoundDuration = (soundType: SoundType) => {
        switch (soundType) {
            case SoundType.Notification:
                return SoundTypeDuration.Notification;
            case SoundType.Tone:
                return SoundTypeDuration.Tone;
            case SoundType.Busy:
                return SoundTypeDuration.Busy;
            default:
                return SoundTypeDuration.Default;
        }
    }

    private static readonly getAudioFile = (soundType: SoundType, path: string | undefined = undefined) => {
        switch (soundType) {
            case SoundType.Notification:
                return PlaySoundHelper.NotificationSound;
            case SoundType.Tone:
                return PlaySoundHelper.ToneSound;
            case SoundType.Busy:
                return new Audio(path);
            default:
                return null;
        }
    }
}
import { useIntl } from 'react-intl';
import { stringCellComparator } from '../../../../components/AdvancedTable';
import './SupervisorCallsView.scss'

export const ColumnsResolverStage3 = (waitingOnly = false) => {
  const intl = useIntl();

  const resposiveColumnClassName = 'big-grow table-column';
  const smallerGrowClassValue = "smaller-grow table-header-column";
  const callerNameColumn = "caller-name-column";

  if (waitingOnly) {
    return [
      {
        title: intl.formatMessage({ id: "WaitingCallsView.Media" }), key: 'Media',
        name: 'WaitingCallsMediaColumn', cellComparator: stringCellComparator, className: "smallest-grow"
      },
      {
        title: intl.formatMessage({ id: "WaitingCallsView.CallerName" }), key: 'Caller',
        name: 'WaitingCallsCallerColumn', cellComparator: stringCellComparator, className: callerNameColumn
      },
      {
        title: intl.formatMessage({ id: "WaitingCallsView.CallerPhone" }), key: 'Caller',
        name: 'WaitingCallsCallerColumn', cellComparator: stringCellComparator, className: "caller-phone-column"
      },
      {
        title: intl.formatMessage({ id: "WaitingCallsView.Waiting" }), key: 'Waiting',
        name: 'WaitingCallsWaitingColumn', cellComparator: stringCellComparator, className: smallerGrowClassValue
      },
      {
        title: intl.formatMessage({ id: "WaitingCallsView.Queue" }), key: 'Queue',
        name: 'WaitingCallsQueueColumn', cellComparator: stringCellComparator, className: smallerGrowClassValue
      },
      {
        title: intl.formatMessage({ id: "WaitingCallsView.Status" }), key: 'Status',
        name: 'WaitingCallsStatusColumn', cellComparator: stringCellComparator, className: smallerGrowClassValue
      },
      {
        title: intl.formatMessage({ id: "WaitingCallsView.Action" }), key: 'Action',
        name: 'WaitingCallsCallViewActionsColumn', cellComparator: stringCellComparator, className: "actions-column"
      }
    ];
  }

  return [
    { title: '', key: 'Media', name: 'SupervisorCallsViewMediaColumn', cellComparator: stringCellComparator, className: "smallest-grow" },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.CallerName" }),
      key: 'CallerName', name: 'SupervisorCallsViewCallerColumn', cellComparator: stringCellComparator, className: callerNameColumn
    },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.CallerPhoneNumber" }),
      key: 'CallerPhone', name: 'SupervisorCallsViewCallerColumn', cellComparator: stringCellComparator, className: callerNameColumn
    },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.Timer" }),
      key: 'StartDate', name: 'SupervisorCallsViewTimeColumn', cellComparator: stringCellComparator, className: resposiveColumnClassName
    },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.Queue" }),
      key: 'QueueName', name: 'SupervisorCallsViewQueueColumn', cellComparator: stringCellComparator, className: resposiveColumnClassName
    },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.Status" }),
      key: 'Status', name: 'SupervisorCallsViewStatusColumn', cellComparator: stringCellComparator, className: resposiveColumnClassName
    },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.Agent" }),
      key: 'Agent', name: 'SupervisorCallsViewAgentColumn', cellComparator: stringCellComparator, className: "biggest-grow table-column"
    },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.AssignedTo" }),
      key: 'AssignedTo', name: 'SupervisorCallsViewAgentColumn', cellComparator: stringCellComparator, className: "biggest-grow table-column"
    },
    {
      title: intl.formatMessage({ id: "SupervisorCallsView.Actions" }),
      key: 'Actions', name: 'SupervisorCallsViewActionsColumn', cellComparator: stringCellComparator, className: "actions-column"
    }
  ];
}
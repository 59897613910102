import Lockr from 'lockr';
import { InvokeServiceArgs, ResponseHandlers } from './interfaces';
import { SocketOperations, StorageKeys, HttpMethod, ServiceOperations } from 'utils';
import { Subject, Subscription } from 'rxjs';
import { logging } from 'utils/logging';
import { ConfigurationHelper, EnvHelper, GeneralHelper } from 'utils/helpers';
import { socketManager } from '.';
import { setupInterceptorsTo } from '../axios-interceptor';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

export class ServiceCall {
    private readonly api: AxiosInstance | null;
    socket: any;
    handlers: ResponseHandlers = {};
    handlersCounter: number = 0;
    offlineCounter: number = 0;
    goOfflineFailedRequests: number = 3;
    aliveWaitResponseTimeout: number = 5000;
    waitResponseTimeout: number = 15000;
    retryInvokeServiceInterval: number = 1000;
    onInitialized: Subject<any> = new Subject<any>();
    isDisconnected: boolean = true;
    isOffline: boolean = false;

    private readonly subscriptionOnInitialized: Subscription | null = null;
    private readonly subscriptionOnReconnect: Subscription | null = null;
    private readonly subscriptionOnDisconnect: Subscription | null = null;

    constructor() {
        if (socketManager) {
            this.subscriptionOnInitialized?.unsubscribe();
            this.subscriptionOnInitialized = socketManager.onInitialized.subscribe(() => {
                this.initializeSocket();
            });

            this.subscriptionOnReconnect?.unsubscribe();
            this.subscriptionOnReconnect = socketManager.onReconnect.subscribe(() => {
                GeneralHelper.logCox(`in service-calls.ts, in the socketManager.onReconnect subscription`);
                this.initializeSocket();
            });

            this.subscriptionOnDisconnect?.unsubscribe();
            this.subscriptionOnDisconnect = socketManager.onDisconnect.subscribe(() => {
                this.isDisconnected = true;
            });
        }

        if (EnvHelper.isStage3()) {
            this.isDisconnected = false;
            this.api = setupInterceptorsTo(
                axios.create({
                    baseURL: ConfigurationHelper.gatewayApiUrl,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
            );
        }
        else {
            this.api = axios.create({
                baseURL: ConfigurationHelper.gatewayApiUrl,
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        }
    }

    private initializeSocket() {
        this.socket = socketManager.socket;
        this.bindInvokeServiceResult();
        this.onInitialized.next();
        this.isDisconnected = false;
    }

    private bindInvokeServiceResult() {
        if (this.socket) {
            this.socket.on(SocketOperations.InvokeServiceResult, (value: { UniqueServiceCallId: string; Error: string; ResponseData: string; Operation: string; }) => {
                const responseHandler = this.handlers[value.UniqueServiceCallId];
                delete this.handlers[value.UniqueServiceCallId];

                if (this.isOffline) {
                    this.isOffline = false;
                    logging.onlineHandler.next();
                }

                this.offlineCounter = 0;

                if (responseHandler) {
                    if (value.Error && value.Error !== '') {
                        responseHandler.error(value.Error);
                    }
                    else {
                        try {
                            const resp = JSON.parse(value.ResponseData);
                            GeneralHelper.logCox(`receiving response for Operation ${value.Operation}, UniqueServiceCallId ${value.UniqueServiceCallId}, \
data ${value.ResponseData}`);
                            responseHandler.success(resp);
                        }
                        catch (error) {
                            responseHandler.error(error);
                        }
                    }
                }
            });
        }
    }

    invokeService(args: InvokeServiceArgs) {
        if (EnvHelper.isStage3()) {
            return this.invokeApiService(args);
        } else {
            return this.invokeSocketService(args);
        }
    }

    private invokeSocketService(args: InvokeServiceArgs): Promise<any> {
        if (args.responseHandler) {
            this.handlersCounter++;

            this.handlers[this.handlersCounter + ''] = args.responseHandler;

            const serviceCallPackage =
            {
                Sender: args.sender as string,
                Target: Lockr.get<string>(StorageKeys.CompanyKey),
                Operation: args.operation,
                RequestData: args.requestData ? JSON.stringify(args.requestData) : undefined,
                UniqueServiceCallId: this.handlersCounter.toString(),
                TokenId: Lockr.get<string>(StorageKeys.TokenId)
            };

            GeneralHelper.logCox(`doing invokeSocketService for Operation ${serviceCallPackage.Operation}, serviceCallPackage ${serviceCallPackage.UniqueServiceCallId}, \
data ${serviceCallPackage.RequestData}`);

            if (this.socket) {
                this.socketEmit(serviceCallPackage);
            }
            else {
                const socketInterval = setInterval(() => {
                    if (this.socket) {
                        clearInterval(socketInterval);
                        this.socketEmit(serviceCallPackage);
                    }
                }, this.retryInvokeServiceInterval)
            }
        }
        return Promise.resolve();
    }

    private socketEmit(serviceCallPackage: any) {
        this.socket.emit(SocketOperations.InvokeService, serviceCallPackage);

        if (serviceCallPackage.Operation === ServiceOperations.KeepAlive) {
            setTimeout(() => {
                if (this.handlers[serviceCallPackage.UniqueServiceCallId]) {
                    this.offlineCounter++;

                    if (this.offlineCounter === this.goOfflineFailedRequests) {
                        this.isOffline = true;
                        logging.offlineHandler.next();
                    }

                    delete this.handlers[serviceCallPackage.UniqueServiceCallId];
                }
            }, this.aliveWaitResponseTimeout);
        } else {
            // delete handler if no response after 15 seconds
            setTimeout(() => {
                if (this.handlers[serviceCallPackage.UniqueServiceCallId]) {
                    delete this.handlers[serviceCallPackage.UniqueServiceCallId];

                    const timeout = Lockr.get(StorageKeys.ServiceCallTimeout);
                    if (!timeout) {
                        //#warning-js should log this, maybe server side...
                        logging.errorHandler.next(serviceCallPackage.Operation + ' is not responding. Please refresh the client.');

                        Lockr.set(StorageKeys.ServiceCallTimeout, true)
                    }
                }
            }, this.waitResponseTimeout);
        }
    }

    public invokeApiService(args: InvokeServiceArgs) {
        if (this.api == null) {
            console.log(`early return for invokeApiService: ${JSON.stringify(args)}`);
            return;
        }
        let response: Promise<AxiosResponse> | null;
        let url = `/${args.controller}/${args.operation}`;
        let baseUrl = ConfigurationHelper.gatewayApiUrl;
        if (args.fetchApiUrl) {
            baseUrl = args.fetchApiUrl;
        }
        if (!EnvHelper.isStage3()) {
            url = `${baseUrl}/${url}`;
        }
        // Defaults for httpMethod
        if (!args.httpMethod) {
            if (!args.requestData) {
                args.httpMethod = HttpMethod.Get;
            }
            else {
                args.httpMethod = HttpMethod.Post;
            }
        }

        switch (args.httpMethod) {
            case HttpMethod.Get:
                if (args.requestData) {
                    const queryString = Object.keys(args.requestData).map(key => `${key}=${args.requestData[key]}`).join('&');
                    url = `${url}?${queryString}`;
                }
                response = this.api.get(url);
                break;
            case HttpMethod.Post:
                response = this.api.post(url, args.requestData);
                break;
            case HttpMethod.Delete:
                response = this.api.delete(url, {data: args.requestData});
                break;
            default:
                response = null;
        }
        if (response == null) {
            return;
        }
        response.then((r => {
            args.responseHandler?.success(r);
        })).catch((err) => {
            args.responseHandler?.error(err);
        });
    }
}

export const serviceCall = new ServiceCall();

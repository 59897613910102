import { Subject } from 'rxjs';
import { AzureLoginType, ResponseWrapper } from 'utils';
import { SocketAuth } from './socket-auth';
import { SSOHelper } from './sso-auth';
import { MGTHelper } from './mgt-auth';
import { MsalHelper } from './msal-auth';
import { TeamsStorageUtils } from './teamsStorageUtils';

export interface ApiAuth {
    userSignedIn: Subject<boolean>;
    userSignedOut: Subject<boolean>;
    signIn(username: string, password: string): Promise<ResponseWrapper>;
    signOutStage2(sip:string): Promise<ResponseWrapper>;
}

export class ADSigninParams {
    IdToken!: string;
    LoginType?: AzureLoginType;
}

export const socketAuth = new SocketAuth();
export const ssoAuth = new SSOHelper();
export const mgtAuth = new MGTHelper();
export const msalAuth = new MsalHelper();
export const teamsStorageUtils = new TeamsStorageUtils();
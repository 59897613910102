import { Button, Dropdown, DropdownItemProps, Flex, PhoneArrowIcon, Popup, ShorthandCollection } from "@fluentui/react-northstar";
import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { debounce } from "utils/helpers";

const stylesFaded = {
    backgroundColor: "#605e5c",
    borderRadius: '0px',
    color: "#c8c6c4",
    width: '50px',
    height: '50px',
    ':hover': {
        backgroundColor: 'rgb(52, 52, 65)',
        color: "#c8c6c4",
    }
}

type Props = {
    areTranferDestinationsLoading: boolean;
    isInvalidTransfer: boolean;
    transferDestinations: any[];
    onTransferDestinationSelected: (destination: string) => void;
    onGetSearchedColdTransferDestinations: (searchQuery?: string) => void;
    onHandleColdTransferIVR: () => void;
    onSetIsInvalidTransfer: (isInvalidTransfer: boolean) => void;
    icon: ReactElement;
    placeholder: string;
    title: string;
    action: string;
    useDefaultSearch?: boolean;
}

const ColdTransferIVRButton = ({ transferDestinations, areTranferDestinationsLoading, onTransferDestinationSelected,
    isInvalidTransfer, onHandleColdTransferIVR, onGetSearchedColdTransferDestinations, onSetIsInvalidTransfer, icon,
    placeholder, title, action, useDefaultSearch }: Props) => {

    const intl = useIntl();
    
    const typingTimeout: any = 0;
    const searchInterval: any = 1000;    

    const searchMethod: boolean | ((items: ShorthandCollection<DropdownItemProps>, searchQuery: string) => ShorthandCollection<DropdownItemProps>) =
        useDefaultSearch ? true : (items, _) => items;

    const getColdTransferDestinationsAfterStopTyping = debounce((searchQuery?: string) => {
        onSetIsInvalidTransfer(false);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }


        onGetSearchedColdTransferDestinations(searchQuery?.toLowerCase());
    }, searchInterval);

    const handleColdTransferKeyPressFromTheCallActionPopup = (e: any) => {
        if (e.keyCode === 13 || e.which === 13) {
            onHandleColdTransferIVR();
        }
    };

    return <Popup
        trapFocus
        trigger={<Button
        icon={icon}
        iconOnly
        text
        title={title}
        styles={stylesFaded}
        onClick={() => { getColdTransferDestinationsAfterStopTyping() }} />}
        content={
            <Flex gap="gap.smaller"
                styles={{
                    padding: '10px',
                }}>
                <Dropdown
                    search={searchMethod}
                    id="cold-transfer-dropdown"
                    items={transferDestinations}
                    placeholder={placeholder}
                    noResultsMessage={intl.formatMessage({ id: "AgentView.DrodownCouldNotFindMatches" })}
                    getA11ySelectionMessage={{
                        onAdd: item => `${item} ${intl.formatMessage({ id: "AgentView.DrodownItemSelected" })}`
                    }}
                    onSearchQueryChange={(e, data) => {
                        getColdTransferDestinationsAfterStopTyping(data.searchQuery);
                    }}
                    onChange={(e, selectedOption) => {
                        if (selectedOption) {
                            onTransferDestinationSelected((selectedOption.value as any)?.header.toString() || '');
                        }
                    }}
                    onKeyUp={(e: any) => handleColdTransferKeyPressFromTheCallActionPopup(e)}
                    loading={areTranferDestinationsLoading}
                    error={isInvalidTransfer}
                />

                <Button circular icon={<PhoneArrowIcon />} primary
                    size="medium"
                    title={action}
                    onClick={() => onHandleColdTransferIVR()}
                />
            </Flex>
        }
    />;
}

export default ColdTransferIVRButton;
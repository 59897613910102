import { createSvgIcon } from "@fluentui/react-northstar";
import React from "react";

const SandClock = createSvgIcon({ svg: ({ classes, props }) => (
    <svg
        version="1.1"
        width="20"
        height="20"
        viewBox="833 545 1000 1600"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.svg}
    >
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,2666.6667)" >
            <g transform="scale(0.1)">
                <path
                    d="m 12833,12916.7 c -213.6,-621.1 -718.8,-1559.2 -1913.1,-2501.4 h 0.1 c -130.1,-102.6 -204.6,-256.4 -204.6,-422 0,-165.6 74.5,-319.4 204.5,-422 1194.3,-942.1 1699.5,-1880.3 1913.1,-2501.4 230.3,-669.8 180.5,-1125.5 180.1,-1128.9 -1.3,-10.1 -1.9,-20.4 -1.9,-30.6 V 5231.1 H 6954.82 v 679.3 c 0,10.2 -0.5,19.3 -1.8,29.5 -0.52,4.5 -50.32,460.2 179.99,1130 213.54,621.1 718.8,1559.3 1913.02,2501.4 130.03,102.6 204.6,256.4 204.6,422 0,165.6 -74.57,319.4 -204.58,422 -1194.24,942.2 -1699.5,1880.3 -1913.04,2501.4 -230.31,669.8 -180.51,1125.6 -180.13,1128.9 1.3,10.2 1.94,20.4 1.94,30.7 v 679.3 h 6056.38 v -679.3 c 0,-10.3 0.5,-19.4 1.8,-29.5 0.5,-4.5 50.3,-460.3 -180,-1130.1 z m 1417,1948.1 v 1162.7 H 5750 v -1162.7 h 719.11 v -775.9 c -9.58,-100.7 -40.49,-598.7 193.24,-1296.7 231.45,-691.3 780.7,-1730.9 2082.86,-2758.3 16.29,-12.8 19.71,-29.1 19.71,-40.6 0,-11.4 -3.42,-27.8 -19.73,-40.6 C 7443.05,8925.4 6893.8,7885.7 6662.35,7194.5 6428.62,6496.5 6459.53,5998.5 6469.11,5897.8 V 5135.2 H 5750 V 3972.5 h 8500 v 1162.7 h -753.1 v 762.6 c 9.6,100.7 40.5,598.7 -193.3,1296.7 -231.4,691.2 -780.7,1730.9 -2082.8,2758.2 -16.3,12.8 -19.7,29.2 -19.7,40.6 0,11.5 3.4,27.8 19.7,40.6 v 0.1 c 1302.1,1027.3 1851.4,2066.9 2082.8,2758.2 233.8,698 202.9,1196 193.3,1296.7 v 775.9 h 753.1"
                />
                <path
                    d="m 7621.06,5669.8 h 4731.14 c 0,1306.5 -1059.1,2365.6 -2365.56,2365.6 -1306.46,0 -2365.58,-1059.1 -2365.58,-2365.6"
                />
                <path
                    d="m 10053.1,12518.1 c -336.81,-106.2 -688.99,-158.2 -1041.71,-139.7 -424.23,22.2 -821.46,114.3 -1189.85,281.2 0,0 435.96,-640.8 1456.77,-1456.8 0,0 402.94,-289 664.13,-827 47.61,-98.1 182.56,-110.1 247.26,-22.4 349,474 1234.5,1631.5 1855.1,2052.3 -619.3,291.6 -1281.9,336.1 -1991.7,112.4"
                />
            </g>
        </g>
    </svg>
    ), 
    displayName: "SandClock"
});

export default SandClock;

import { useMemo } from "react";
import { useIntl } from "react-intl";

export const HistoryColumnsResolver = () => {
    const intl = useIntl();

    const columns = useMemo(() => [
        {
            key: 'Name',
            title: intl.formatMessage({ id: "Generic.History.Name" }),
            name: ColumnName.Name,
            className: styleClasses.smallGrow
        },
        {
            key: 'Subject',
            title: intl.formatMessage({ id: "Generic.History.Subject" }),
            name: ColumnName.Subject,
            className: styleClasses.biggestGrow
        },
        {
            key: 'QueueName',
            title: intl.formatMessage({ id: "Generic.History.Queue" }),
            name: ColumnName.Queue,
            className: styleClasses.smallGrow
        },
        {
            key: 'Handled',
            title: intl.formatMessage({ id: "Generic.History.Handled" }),
            name: ColumnName.Handled,
            className: styleClasses.smallestGrow
        },
        {
            key: 'Type',
            title: intl.formatMessage({ id: "Generic.History.Type" }),
            name: ColumnName.Type,
            className: styleClasses.smallerGrow
        },
        {
            key: 'Date',
            title: intl.formatMessage({ id: "Generic.History.Date" }),
            name: ColumnName.Date,
            className: styleClasses.smallerGrow
        },
    ], [intl]);

    return columns;
}

export enum ColumnName {
    Name = 'NameColumn',
    Subject = 'SubjectColumn',
    Queue = 'QueueNameColumn',
    Handled = 'HandledColumn',
    Type = 'TypeColumn',
    Date = 'DateColumn',
}

export const styleClasses = {
    biggestGrow: "biggest-grow",
    biggerGrow: "bigger-grow",
    bigGrow: "big-grow",
    smallGrow: "small-grow",
    smallerGrow: "smaller-grow",
    smallestGrow: "smallest-grow",
};

export default HistoryColumnsResolver;

import { AttendeeIcon, Button, Flex, PlayIcon } from "@fluentui/react-northstar";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { SupervisorMediaFilters } from "utils/domain/supervisor/SupervisorMediaFilters";
import { ConferenceStatusAction, logging, MediaType, MonitorTypes, QueueMediaStatusType, QueueMediaViewType, StorageKeys } from "utils";
import { queuesService } from "services/queue";
import { supervisorManager } from "services/supervisor/supervisor-manager";
import Lockr from 'lockr';
import { MediaDisplayItem, SupervisorMediaDtoStage3 } from "utils/domain/supervisor/SupervisorMediaDtoStage3";
import { GeneralHelper } from "utils/helpers";
import { ActiveMediaDto } from "utils/domain/supervisor/ActiveMediaDto";
import { WaitingMediaDto } from "utils/domain/waitingMediaDTO";
import Timer from "components/Timer";
import { Subscription } from "rxjs";
import { waitingListManager } from "services/io/waiting-list";
import { activeListManager } from "services/io/active-list";
import QueueMediaHelper from "utils/helpers/queue-media-helper";
import { socketContacts } from "services/contacts";
import { Presence, PresenceState } from "utils/domain/presence";
import { socketPresence } from "services/presence";
import { serviceRequests } from "services";
import { socketAudio } from "services/calling";
import { CallSessionDTO } from "utils/domain/callSessionDTO";
import MediaTypeIcon from "../mediaComponents/MediaTypeIcon";
import CallPickupButton from "../mediaComponents/CallPickupButton";
import { QueueMediaViewStage3 } from "../mediaComponents/QueueMediaView";
import ChatButton from "components/ContactActions/ChatButton";

export const SupervisorCallsViewStage3 = ({
    isWarmTransferInProgress: warmTransferInProgress
}: { isWarmTransferInProgress: boolean }) => {
    const intl = useIntl();
    const initialMediaGetIsDone = useRef(false);
    const isOngoingCall = useRef(false);
    const warmTransfer = useRef(false);

    const [activeMediaFilter, setActiveMediaFilter] = useState(false);
    const [waitingMediaFilter, setWaitingMediaFilter] = useState(false);
    const [displayMediaList, setDisplayMediaList] = useState<MediaDisplayItem[]>([]);
    const supervisorMediaList = useRef<MediaDisplayItem[]>([]);
    const [activeMediaCount, setActiveMediaCount] = useState(0);
    const [waitingMediaCount, setWaitingMediaCount] = useState(0);
    const currentCallSession = useRef<CallSessionDTO>();

    let subscriptionWaitingAdded: Subscription | null = null;
    let subscriptionWaitingUpdated: Subscription | null = null;
    let subscriptionWaitingRemoved: Subscription | null = null;
    let subscriptionActiveAdded: Subscription | null = null;
    let subscriptionActiveUpdated: Subscription | null = null;
    let subscriptionActiveRemoved: Subscription | null = null;
    let subscriptionQueueMembershipAdded: Subscription | null = null;
    let subscriptionQueueMembershipRemoved: Subscription | null = null;
    let subscriptionUpdatedPresenceState: Subscription | null = null;
    let subscriptionInternalPresenceChanged: Subscription | null = null;
    let subscriptionCurrentUserCallSessionChanged: Subscription | null = null;
    let subscriptionCallSessionStateChanged: Subscription | null = null;
    let subscriptionQueueSupervisorChanged: Subscription | null = null;
    let subscriptionQueueOperatorChanged: Subscription | null = null;

    const isPresenceStateDND = useRef(false);
    const resposiveColumnClassName = 'big-grow table-column';
    const monitoringHiddenStatuses = [QueueMediaStatusType.ColdTransferRinging, QueueMediaStatusType.WarmTransferRinging, QueueMediaStatusType.WarmTransferInitialized, QueueMediaStatusType.Parked, QueueMediaStatusType.Offering];
    const [refresh, setRefresh] = useState<number>(Date.now());
    const intervalMs: number = 11000;

    React.useEffect(() => {
        initialize();

        // refresh agent data every 11 seconds CC4ALL-4640
        const interval = setInterval(() => setRefresh(Date.now()), intervalMs);

        return () => {
            componentWillUnmount();
            clearInterval(interval);
        }
    }, []);

    React.useEffect(() => {
        if (initialMediaGetIsDone.current) {
            checkForOngoingCallAndRenderSupervisorCalls();
        }
    }, [activeMediaFilter, waitingMediaFilter]);

    React.useEffect(() => {
        if (initialMediaGetIsDone.current) {
            getSupervisorMedia();
        }
    }, [refresh]);

    const initialize = async () => {
        const presence = await socketPresence.GetCurrentPresence();
        isPresenceStateDND.current = presence?.Availability === PresenceState.DND;

        setActiveMediaFilter(getInitialActiveMediaFilter());
        setWaitingMediaFilter(getInitialWaitingMediaFilter());

        subscribeWaitingChanges();
        subscribeActiveChanges();

        subscriptionQueueMembershipAdded = queuesService.queueMembershipAdded.subscribe((queueChange: any) => {
            getSupervisorMedia();
        });

        subscriptionQueueMembershipRemoved = queuesService.queueMembershipRemoved.subscribe((queue: any) => {
            getSupervisorMedia();
        });

        subscriptionQueueOperatorChanged = queuesService.queueOperatorChanged.subscribe((queue: any) => {
            getSupervisorMedia();
        });

        subscriptionUpdatedPresenceState?.unsubscribe();
        subscriptionUpdatedPresenceState = socketContacts.notifyUpdatedPresenceState.subscribe((userPresence: Presence) => {
            if (userPresence.Id === Lockr.get(StorageKeys.UserObjectId)) {
                isPresenceStateDND.current = userPresence.Availability === PresenceState.DND;
            }
        })

        subscriptionInternalPresenceChanged = serviceRequests.internalPresenceChange.subscribe((internalPresence: any) => {
            const userId = Lockr.get(StorageKeys.UserObjectId);
            if (internalPresence.Id === userId) {
                isPresenceStateDND.current = internalPresence.Presence === PresenceState.DND;
            }
        });

        subscriptionCurrentUserCallSessionChanged?.unsubscribe();
        subscriptionCurrentUserCallSessionChanged = socketAudio.currentUserCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
            currentCallSession.current = callSessionDTO;
            checkForOngoingCallAndRenderSupervisorCalls(callSessionDTO);
        });

        subscriptionCallSessionStateChanged?.unsubscribe();
        subscriptionCallSessionStateChanged = socketAudio.callSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
            currentCallSession.current = callSessionDTO.IsCurrentUserInCall ? callSessionDTO : undefined;
            checkForOngoingCallAndRenderSupervisorCalls();
            const userId = Lockr.get<string>(StorageKeys.UserId);

            if (callSessionDTO.CallerId === userId && callSessionDTO.IsOutboundCall &&
                callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.Terminated]) {
                currentCallSession.current = callSessionDTO;
                checkForOngoingCallAndRenderSupervisorCalls(callSessionDTO);
            }
        });

        subscriptionQueueSupervisorChanged = queuesService.queueSupervisorChanged.subscribe((queue: any) => {
            getSupervisorMedia();
        });

        getSupervisorMedia();
    }

    const subscribeWaitingChanges = () => {
        subscriptionWaitingAdded?.unsubscribe();
        subscriptionWaitingAdded = waitingListManager.onWaitingAdded.subscribe((obj: WaitingMediaDto) => {
            handleMediaAddedSubscription(QueueMediaHelper.mapWaitingItemToDisplayItem(obj), false);
        })

        subscriptionWaitingUpdated?.unsubscribe();
        subscriptionWaitingUpdated = waitingListManager.onWaitingUpdated.subscribe((obj: WaitingMediaDto) => {
            handleMediaUpdated(QueueMediaHelper.mapWaitingItemToDisplayItem(obj), false);
        });

        subscriptionWaitingRemoved?.unsubscribe();
        subscriptionWaitingRemoved = waitingListManager.onWaitingRemoved.subscribe((obj: WaitingMediaDto) => {
            handleMediaRemoved(QueueMediaHelper.mapWaitingItemToDisplayItem(obj), false);
        });
    }

    const subscribeActiveChanges = () => {
        subscriptionActiveAdded?.unsubscribe();
        subscriptionActiveAdded = activeListManager.onActiveCallAdded.subscribe((obj: ActiveMediaDto) => {
            handleMediaAddedSubscription(QueueMediaHelper.mapActiveItemToDisplayItem(obj), true);
        });

        subscriptionActiveUpdated?.unsubscribe();
        subscriptionActiveUpdated = activeListManager.onActiveCallUpdated.subscribe((obj: ActiveMediaDto) => {
            handleMediaUpdated(QueueMediaHelper.mapActiveItemToDisplayItem(obj), true);
        });

        subscriptionActiveRemoved?.unsubscribe();
        subscriptionActiveRemoved = activeListManager.onActiveCallRemoved.subscribe((obj: ActiveMediaDto) => {
            handleMediaRemoved(QueueMediaHelper.mapActiveItemToDisplayItem(obj), true);
        });
    }

    const handleMediaAddedSubscription = (obj: MediaDisplayItem, isActive: boolean) => {
        if (!checkAgentCanViewRecord(isActive, obj.QueueRef)) {
            return;
        }

        const elementExist = getElementIndexBySessionId(obj.SessionId, isActive) !== null;

        if (!elementExist) {
            //insert element by queue priority
            const indexToInsert = QueueMediaHelper.getLastElementIdexByQueuePriority(supervisorMediaList.current, obj, true, isActive);
            supervisorMediaList.current.splice(indexToInsert, 0, obj);
            setMediaCount(isActive);
            checkForOngoingCallAndRenderSupervisorCalls();
        }
    }

    const handleMediaUpdated = (obj: MediaDisplayItem, isActive: boolean) => {
        const indexToUpdate = getElementIndexBySessionId(obj.SessionId, isActive);

        if (indexToUpdate != null) {
            supervisorMediaList.current = supervisorMediaList.current.map((item, index) => {
                return index === indexToUpdate ? obj : item
            });

            setMediaCount(isActive);
            checkForOngoingCallAndRenderSupervisorCalls();
        }
    }

    const handleMediaRemoved = (obj: MediaDisplayItem, isActive: boolean) => {
        const indexToDelete = getElementIndexBySessionId(obj.SessionId, isActive);

        if (indexToDelete != null) {
            supervisorMediaList.current = supervisorMediaList.current.filter((_, index) => indexToDelete !== index);
            setMediaCount(isActive);
            checkForOngoingCallAndRenderSupervisorCalls();
        }
    }

    const checkAgentCanViewRecord = (isActive: boolean, queueId?: string) => {
        if (!queueId) {
            return false;
        }

        if (isActive) {
            const filterSupervisedQueues = queuesService.queuesArray.filter((element: any) => element.QueueRef === queueId && (element.IsSupervisor || element.IsOperator));

            return filterSupervisedQueues && filterSupervisedQueues.length;
        }

        const filterMemberedQueues = queuesService.queuesArray.filter((element: any) => element.QueueRef === queueId);

        return filterMemberedQueues && filterMemberedQueues.length;
    }

    const isOperatorOnQueue = (queueId: string) => {
        if (!queueId) {
            return false;
        }

        const filterOperatorQueues = queuesService.queuesArray.filter((element: any) => element.QueueRef === queueId && element.IsOperator);

        return filterOperatorQueues && filterOperatorQueues.length;
    }

    const getElementIndexBySessionId = (sessionId: any, isActive: boolean) => {
        if (!supervisorMediaList.current) {
            return 0;
        }

        const searchedObject = supervisorMediaList.current.filter(element => element.SessionId === sessionId && element.IsActiveMedia === isActive)[0];
        return searchedObject ? supervisorMediaList.current.indexOf(searchedObject) : null;
    };

    const checkForOngoingCallAndRenderSupervisorCalls = (callSessionDTO?: CallSessionDTO) => {
        isOngoingCall.current = false;
        const userId = Lockr.get<string>(StorageKeys.UserId);
        callSessionDTO = callSessionDTO || currentCallSession.current;
        const callSession = callSessionDTO ?? socketAudio.getCallSessionForCurrentUser();
        const isCalleOnOutboundCall = callSession.CallerId === userId && callSession.IsOutboundCall;

        if ((callSession.ConversationKey && callSession.IsCurrentUserInCall && !callSession?.IsTerminated) || isCalleOnOutboundCall) {
            isOngoingCall.current = true;
        }

        filterAndShowTableItems();
    }

    const mapDisplayItem = (obj: MediaDisplayItem) => {
        const key = `row-${obj.SessionId}-is-active-${obj.IsActiveMedia?.toString()}`;

        return {
            key: key,
            queueid: obj.QueueRef,
            items: [
                {
                    content: <MediaTypeIcon status={obj.Status} mediaType={obj.MediaType} sessionId={obj.SessionId} />,
                    className: GeneralHelper.getMediaTypeContainerClassName(obj.MediaType)
                },
                { content: obj.CallerName || obj.CallerPhone, key: obj.SessionId?.toString() + '-1', className: "caller-name-column" },
                { content: obj.CallerPhone || obj.CallerName, key: obj.SessionId?.toString() + '-2', className: "caller-name-column" },
                { content: <Timer startDate={obj.StartDate}></Timer>, key: obj.SessionId?.toString() + '-3', className: resposiveColumnClassName },
                { content: obj.QueueName, key: obj.SessionId?.toString() + '-4', className: resposiveColumnClassName },
                { content: QueueMediaHelper.getQueueMediaStatusText(obj.Status, intl), key: obj.SessionId?.toString() + '-5', className: resposiveColumnClassName },
                { content: obj.Agent ? obj.Agent : "", key: obj.SessionId?.toString() + '-6', className: "biggest-grow table-column" },
                { content: obj.AssignedTo ? obj.AssignedTo : "", key: obj.SessionId?.toString() + '-7', className: "biggest-grow table-column" },
                { content: getActionButtons(obj), key: obj.SessionId?.toString() + '-8', className: "actions-column" }
            ]
        }
    }

    const getActionButtons = (obj: any) => {
        const isSupervisorOnQueue = checkIsSupervisorOnQueue(obj);
        const buttons: any = [
            getChatButton(obj.AgentUpn, !!obj.AssignedTo, isSupervisorOnQueue, obj.IsSupervised),
            getPickUpButton(obj.IsCallPickupEnabled, obj.IsAssigned, isOngoingCall.current, obj.ScenarioId, obj.Status),
            getParkResumeButton(obj),
            getListenButton(obj, obj.ScenarioId, isSupervisorOnQueue),
            getBargeInButton(obj, obj.ScenarioId, isSupervisorOnQueue)];

        return <Flex vAlign="center" hAlign="start">
            {buttons}
        </Flex>;
    }

    const getSupervisorMedia = () => {
        supervisorManager.getMediaBySupervisorStage3(Lockr.get<string>(StorageKeys.UserId)).then((response: SupervisorMediaDtoStage3) => {
            supervisorMediaList.current = [];

            response.ActiveMedia?.forEach(element => {
                supervisorMediaList.current.push(QueueMediaHelper.mapActiveItemToDisplayItem(element));
            });
            response.WaitingMedia?.forEach(element => {
                supervisorMediaList.current.push(QueueMediaHelper.mapWaitingItemToDisplayItem(element));
            });


            checkForOngoingCallAndRenderSupervisorCalls();
            setMediaCount(true);
            setMediaCount(false);
            initialMediaGetIsDone.current = true;
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const filterAndShowTableItems = () => {
        const isWaitingListVisible = getInitialWaitingMediaFilter();
        const isActiveListVisible = getInitialActiveMediaFilter();
        const displayItems: any = [];

        if (isWaitingListVisible) {
            supervisorMediaList.current.forEach((element: any) => {
                if (!element.IsActiveMedia) {
                    displayItems.push(mapDisplayItem(element));
                }
            });
        }

        if (isActiveListVisible) {
            supervisorMediaList.current.forEach((element: any) => {
                if (element.IsActiveMedia) {
                    displayItems.push(mapDisplayItem(element));
                }
            });
        }

        setDisplayMediaList(displayItems);
    }

    const setMediaCount = (isActive: boolean) => {
        if (isActive) {
            const filterActiveList = supervisorMediaList.current.filter(x => x.IsActiveMedia);
            setActiveMediaCount(filterActiveList.length);
            return;
        }

        const filterWaitingList = supervisorMediaList.current.filter(x => !x.IsActiveMedia);
        setWaitingMediaCount(filterWaitingList.length);
    }

    const componentWillUnmount = () => {
        subscriptionWaitingAdded?.unsubscribe();
        subscriptionWaitingUpdated?.unsubscribe();
        subscriptionWaitingRemoved?.unsubscribe();

        subscriptionActiveAdded?.unsubscribe();
        subscriptionActiveUpdated?.unsubscribe();
        subscriptionActiveRemoved?.unsubscribe();
        subscriptionQueueMembershipAdded?.unsubscribe();
        subscriptionQueueMembershipRemoved?.unsubscribe();
        subscriptionUpdatedPresenceState?.unsubscribe();
        subscriptionInternalPresenceChanged?.unsubscribe();
        subscriptionCurrentUserCallSessionChanged?.unsubscribe();
        subscriptionCallSessionStateChanged?.unsubscribe();
        subscriptionQueueSupervisorChanged?.unsubscribe();
        subscriptionQueueOperatorChanged?.unsubscribe();
    }
    const modifyActiveMediaFilter = () => {
        setActiveMediaFilter(prevState => !prevState);

        const filtersFromLocalStorage = getMediaFiltersFromLocalStorage() || {} as SupervisorMediaFilters;
        filtersFromLocalStorage.ActiveMedia = !activeMediaFilter
        Lockr.set(StorageKeys.SupervisorCallsFilters, filtersFromLocalStorage);
    }

    const modifyWaitingMediaFilter = () => {
        setWaitingMediaFilter(prevState => !prevState);

        const filtersFromLocalStorage = getMediaFiltersFromLocalStorage() || {} as SupervisorMediaFilters;
        filtersFromLocalStorage.WaitingMedia = !waitingMediaFilter
        Lockr.set(StorageKeys.SupervisorCallsFilters, filtersFromLocalStorage);
    }

    const getInitialActiveMediaFilter = () => {
        const initialActiveMediaFilterValue = getMediaFiltersFromLocalStorage()?.ActiveMedia;
        return initialActiveMediaFilterValue === undefined ? true : initialActiveMediaFilterValue;
    }

    const getInitialWaitingMediaFilter = () => {
        const initialWaitingFilterValue = getMediaFiltersFromLocalStorage()?.WaitingMedia;
        return initialWaitingFilterValue === undefined ? true : initialWaitingFilterValue;
    }

    const getMediaFiltersFromLocalStorage = () => {
        return (Lockr.get<SupervisorMediaFilters>(StorageKeys.SupervisorCallsFilters));
    }

    const getPickUpButton = (isCallPickupEnabled: boolean, isAssigned: boolean, isOngoing: boolean, scenarioId: string, status: QueueMediaStatusType) => {
        const isVisible = status !== QueueMediaStatusType.CallbackRequest && isCallPickupEnabled && !isOngoing && !isAssigned;
        return <CallPickupButton
            key={`${scenarioId}-supervisor-pickup-btn`}
            isVisible={isVisible}
            callback={() => pickUpButtonOnClick(scenarioId)}
        />
    }

    const getChatButton = (upn: string, isTransfered: boolean, isSupervisorOnQueue: boolean, isSupervised: boolean) => {
        const userSip: string = Lockr.get(StorageKeys.SIP);
        const isValidData = !!upn && upn !== userSip && isSupervisorOnQueue && !isTransfered;
        const isUserSupervisorToCall = isSupervised ? currentCallSession.current?.ActiveSupervisorSIP === userSip : true;

        return isValidData && isUserSupervisorToCall &&
            <ChatButton
                size="large"
                upns={[upn]}
                style={{ marginLeft: -5 }}
            />
    }

    const checkIsSupervisorOnQueue = (obj: MediaDisplayItem): boolean => {
        return !!obj.QueueRef && queuesService.queuesArray.some((x: any) => x.QueueRef === obj.QueueRef && x.IsSupervisor);
    }

    const getListenButton = (obj: MediaDisplayItem, scenarioId: string, isSupervisorOnQueue: boolean) => {
        if (shouldHideMonitoringButtons(obj, isSupervisorOnQueue)) {
            return <></>;
        }

        return <Button key={`listen-${obj.SessionId}`} text iconOnly icon={<img alt='Listen' className='ear-icon' />} size="medium" primary={true}
            title={intl.formatMessage({ id: "SupervisorCallsView.Listen" })}
            data-test={`listen-${obj.ScenarioId}`}
            onClick={() => supervisorActionButtonOnClick(obj, scenarioId, MonitorTypes.Silent)} />
    };

    const getBargeInButton = (obj: MediaDisplayItem, scenarioId: string, isSupervisorOnQueue: boolean) => {
        if (shouldHideMonitoringButtons(obj, isSupervisorOnQueue)) {
            return <></>;
        }

        return <Button key={`barge-in-${obj.SessionId}`} text iconOnly icon={<AttendeeIcon size="medium" />} size="medium" primary={true}
            title={intl.formatMessage({ id: "SupervisorCallsView.BargeIn" })}
            data-test={`barge-in-${obj.ScenarioId}`}
            onClick={() => supervisorActionButtonOnClick(obj, scenarioId, MonitorTypes.BargeIn)} />
    };

    const shouldHideMonitoringButtons = (obj: MediaDisplayItem, isSupervisorOnQueue: boolean): boolean | undefined => {
        return !isSupervisorOnQueue || warmTransfer.current || !obj.IsActiveMedia || isOngoingCall.current || !obj.IsMonitoringEnabled
            || obj.MediaType !== MediaType.Call || obj.IsSupervised || (obj.Status && monitoringHiddenStatuses.includes(obj.Status))
            || (currentCallSession.current && !currentCallSession.current?.SessionId)
    }

    const getParkResumeButton = (obj: MediaDisplayItem) => {
        const isOperator = isOperatorOnQueue(obj.QueueRef || '');

        if (!isOperator || obj.Status !== QueueMediaStatusType.Parked || isOngoingCall.current) {
            return <></>;
        }

        return <Button style={{ "marginRight": "5px" }}
            key={`resume-park-${obj.SessionId}`}
            circular
            icon={<PlayIcon size="small" />} size="small"
            primary
            data-test={`resume-park-${obj.ScenarioId}`}
            title={intl.formatMessage({ id: "CallActions.Resume" })}
            onClick={() => {
                parkResumeButtonOnClick(obj);
            }}
        />
    }

    const supervisorActionButtonOnClick = async (obj: MediaDisplayItem, scenarioId: string, actionType: MonitorTypes) => {
        const isMonitoringStarting = await socketAudio.startSupervisorMonitoring({
            scenarioId, supervisingAgentId: Lockr.get<string>(StorageKeys.UserId),
            monitoringType: actionType
        });
        if (!isMonitoringStarting) {
            logging.errorHandler.next("ErrorMessage.SupervisingMonitoring.CallAlreadyMonitored");
        }
    }

    const parkResumeButtonOnClick = (obj: MediaDisplayItem) => {
        handleMediaRemoved(obj, true);

        socketAudio.unparkCall({
            SessionId: obj.SessionId,
            CallId: obj.SessionId,
            AgentRef: Lockr.get<string>(StorageKeys.UserId),
            QueueId: obj.QueueRef,
            ScenarioId: obj.ScenarioId
        });
    }

    const pickUpButtonOnClick = async (scenarioId: string) => {
        if (isPresenceStateDND.current) {
            logging.errorHandler.next("ErrorMessage.CallPickup.CallPickupDND");
            return;
        }

        checkForOngoingCallAndRenderSupervisorCalls();

        const isCallPickedUp = await waitingListManager.pickUpCallStage3({ scenarioId: scenarioId, agentUserId: Lockr.get<string>(StorageKeys.UserId) })
        if (!isCallPickedUp) {
            logging.errorHandler.next("ErrorMessage.CallPickup.CallUnavailable");
        }
    }

    return (
        <QueueMediaViewStage3 viewType={QueueMediaViewType.Supervisor}
            mediaItems={displayMediaList}
            activeMediaCount={activeMediaCount}
            waitingMediaCount={waitingMediaCount}
            activeMediaFilterCallback={() => modifyActiveMediaFilter()}
            waitingMediaFilterCallback={() => modifyWaitingMediaFilter()}
            showActiveMedia={activeMediaFilter}
            showWaitingMedia={waitingMediaFilter}
        />
    )
}
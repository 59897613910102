import { Button, Flex, Menu, tabListBehavior, Text } from "@fluentui/react-northstar";
import Lockr from 'lockr';
import React, { useRef, useState, useEffect } from "react";
import { MenuActiveItem, StorageKeys, TabViewIds, BotNotificationType } from "utils";
import { ActiveMediaView } from "utils/domain/mediaView/activeMediaView";
import { useIntl } from 'react-intl';
import './voicemailsTabs.scss';
import VoicemailsTabContentView from "../VoicemailsTabContentView";
import AlertsTabContentView from "../AlertsTabContentView";
import { socketVoicemails } from "services/voiceMails";
import { NotificationContainerDTO } from "utils/domain/voiceMailsView/notificationContainerDTO";
import { VoiceMailRecordDTO } from "utils/domain/voiceMailsView/voiceMailRecordDTO";
import { RealtimeNotificationDTO } from "utils/domain/voiceMailsView/realtimeNotificationDTO";
import { socketQueue } from "services/queue";
import { GeneralHelper } from "utils/helpers";
import { Subscription } from "rxjs";

export const VoiceMailsTabs = ({ voiceMailData }: any) => {
    const intl = useIntl();

    let subscriptionNewVoicemails: Subscription | null = null;
    let subscriptionNewAlerts: Subscription | null = null;

    const [isRealtimeRportsAccessGranted, setIsRealtimeRportsAccessGranted] = React.useState(true);
    const [activeTabId, setActiveTabId] = useState(TabViewIds.VoiceMailAlerts);
    const activeTabIdRef = useRef(TabViewIds.VoiceMailAlerts);

    const [staticTabContentKey, setStaticTabContentKey] = useState(Math.random() + "staticTabContentKey");
    const [voiceMailNotificationListKey, setVoiceMailNotificationListKey] = useState(Math.random() + "voiceMailNotificationListKey");
    const [realtimeNotificationListKey, setRealtimeNotificationListKey] = useState(Math.random() + "realtimeNotificationListKey");

    const voiceMailNotificationList = useRef([] as VoiceMailRecordDTO[]);
    const realtimeNotificationList = useRef([] as RealtimeNotificationDTO[]);
    const attachedQueuesList = useRef([] as number[]);
    const [countVoiceMailsButtonKey, setVoiceMailsButtonKey] = React.useState(Math.random() + "count-voicemails" as any);
    const [countAlertsButtonKey, setAlertsButtonKey] = React.useState(Math.random() + "count-alerts" as any);
    const newVoicemailsNotifications = useRef([] as any);
    const newAlertsNotifications = useRef([] as any);

    useEffect(() => {
        setActiveTabId(isRealtimeRportsAccessGranted ? TabViewIds.VoiceMailAlerts : TabViewIds.VoiceMailVoiceMails);
        activeTabIdRef.current = isRealtimeRportsAccessGranted ? TabViewIds.VoiceMailAlerts : TabViewIds.VoiceMailVoiceMails;
    }, [isRealtimeRportsAccessGranted]);

    const getVoicemailText = () => {
        return intl.formatMessage({ id: "VoiceMailsTabs.Voicemails" })
    }

    const getVoicemailAlertsText = () => {
        return intl.formatMessage({ id: "VoiceMailsTabs.Alerts" })
    }

    const [alertsTab, setAlertsTab] = useState([{
        key: 'alerts-tab' + Math.random(),
        content: <Flex className="alerts-tab-header">
            <Text content={getVoicemailAlertsText()} />
            {newAlertsNotifications.current.length > 0 && <Button key={countAlertsButtonKey} className="alerts-notification-tab-count" circular
                content={newAlertsNotifications.current.length} size="small" />}
        </Flex >,
        onClick: () => { changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.VoiceMailAlerts, ActiveTabId: TabViewIds.VoiceMailAlerts })) },
        tabcontent: <AlertsTabContentView key={'alerts-tab-ContentView' + Math.random()} realtimeNotificationList={realtimeNotificationList.current} />,
        tabid: TabViewIds.VoiceMailAlerts,
        active: activeTabIdRef.current === TabViewIds.VoiceMailAlerts,
        viewtype: MenuActiveItem.VoiceMailAlerts
    }] as any[]);

    const [voiceMailTab, setVoiceMailTab] = useState([{
        key: 'voiceMails-tab' + Math.random(),
        content: <Flex >
            <Text content={getVoicemailText()} />
            {newVoicemailsNotifications.current.length > 0 && <Button key={countVoiceMailsButtonKey} className="voicemail-notification-tab-count" circular
                content={newVoicemailsNotifications.current.length} size="small" />}
        </Flex >,
        onClick: () => { changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.VoiceMailVoiceMails, ActiveTabId: TabViewIds.VoiceMailVoiceMails })) },
        tabcontent: <VoicemailsTabContentView key={'voiceMails-tab-ContentView' + Math.random()} voiceMailNotificationList={voiceMailNotificationList.current} />,
        tabid: TabViewIds.VoiceMailVoiceMails,
        active: activeTabIdRef.current === TabViewIds.VoiceMailVoiceMails,
        viewtype: MenuActiveItem.VoiceMailVoiceMails
    }] as any[]);

    const initialize = () => {
        GeneralHelper.logCox(`in VoiceMailsTabs.tsx, in initialize`);
        const attachedQueues = GeneralHelper.getAttachedUserQueues(socketQueue.queuesArray);
        setIsRealtimeRportsAccessGranted(GeneralHelper.checkIsRealtimeReportsAccessGrantedForAgent(socketQueue.queuesArray));

        attachedQueuesList.current = attachedQueues;
        getNotificationListForClient();

        subscriptionNewVoicemails?.unsubscribe();
        subscriptionNewVoicemails = socketVoicemails.newVoicemailsChanged.subscribe(() => {
            newVoicemailsNotifications.current = socketVoicemails.newVoicemails;
            handleNewVoiceMailsCount();
        });

        subscriptionNewAlerts?.unsubscribe();
        subscriptionNewAlerts = socketVoicemails.newAlertsChanged.subscribe(() => {
            newAlertsNotifications.current = socketVoicemails.newAlerts;
            handleNewAlertsCount();
        });


        newVoicemailsNotifications.current = socketVoicemails.newVoicemails;
        handleNewVoiceMailsCount();

        newAlertsNotifications.current = socketVoicemails.newAlerts;
        handleNewAlertsCount();
    }

    const handleNewVoiceMailsCount = () => {
        setVoiceMailTab((currentState: any) => {
            currentState.forEach((el: any) => {
                el.content = <Flex >
                    <Text content={getVoicemailText()} />
                    {newVoicemailsNotifications.current.length > 0 && <Button key={countVoiceMailsButtonKey} className="voicemail-notification-tab-count" circular
                        content={newVoicemailsNotifications.current.length} size="small" />}
                </Flex >;
            });

            return currentState;
        });
        setVoiceMailsButtonKey(Math.random() + "count-voicemails");
    }

    const handleNewAlertsCount = () => {
        setAlertsTab((currentState: any) => {
            currentState.forEach((el: any) => {
                el.content = <Flex className="alerts-tab-header">
                    <Text content={getVoicemailAlertsText()} />
                    {newAlertsNotifications.current.length > 0 && <Button key={countVoiceMailsButtonKey} className="alerts-notification-tab-count" circular
                        content={newAlertsNotifications.current.length} size="small" />}
                </Flex >;
            });

            return currentState;
        });
        setAlertsButtonKey(Math.random() + "count-alerts");
    }

    const changeView = (activeItem: ActiveMediaView) => {
        setActiveTabId(activeItem.ActiveTabId ? activeItem.ActiveTabId : TabViewIds.VoiceMailAlerts);
        activeTabIdRef.current = activeItem.ActiveTabId ? activeItem.ActiveTabId : TabViewIds.VoiceMailAlerts;
        setAlertsTab((currentState: any) => {
            return setActiveState(currentState, activeItem);
        });

        setVoiceMailTab((currentState: any) => {
            return setActiveState(currentState, activeItem);
        });
    }

    const setActiveState = (currentState: any, activeItem: ActiveMediaView) => {
        currentState.forEach((el: any) => {
            if (el.tabid === activeItem.ActiveTabId) {
                el.active = true;
            } else {
                el.active = false;
            }
        });

        return currentState;
    }

    const getNotificationListForClient = () => {
        const userId = Lockr.get<number>(StorageKeys.UserId);

        socketVoicemails.GetNotificationListForClient(userId).then((result: NotificationContainerDTO) => {
            GeneralHelper.logCox(`in VoiceMailsTabs.tsx, after GetNotificationListForClient`);
            voiceMailNotificationList.current = result.VoiceMailNotificationList;
            setVoiceMailNotificationListKey(Math.random() + "voiceMailNotificationListKey");
            changeVoiceMailTab();

            realtimeNotificationList.current = result.RealtimeNotificationList;
            setRealtimeNotificationListKey(Math.random() + "realtimeNotificationListKey");
            changeAlertsTab();
            setStaticTabContentKey(Math.random() + "staticTabContentKey");
        });

        preselectViewIfReceivedNotification();
    }

    const preselectViewIfReceivedNotification = () => {
        if (voiceMailData && voiceMailData !== "") {
            if (voiceMailData.Type === BotNotificationType.Alert) {
                changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.VoiceMailAlerts, ActiveTabId: TabViewIds.VoiceMailAlerts }));
            }
            if (voiceMailData.Type === BotNotificationType.VoiceMail) {
                changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.VoiceMailVoiceMails, ActiveTabId: TabViewIds.VoiceMailVoiceMails }));
            }
        }
    }

    const changeVoiceMailTab = () => {
        setVoiceMailTab([{
            key: 'voiceMails' + Math.random(),
            content: <Flex >
                <Text content={getVoicemailText()} />
                {newVoicemailsNotifications.current.length > 0 && <Button key={countVoiceMailsButtonKey} className="voicemail-notification-tab-count" circular
                    content={newVoicemailsNotifications.current.length} size="small" />}
            </Flex >,
            onClick: () => { changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.VoiceMailVoiceMails, ActiveTabId: TabViewIds.VoiceMailVoiceMails })) },
            tabcontent: <VoicemailsTabContentView key={'voiceMails-tab-ContentView' + Math.random()} queueList={attachedQueuesList.current}
                voiceMailNotificationList={voiceMailNotificationList.current} />,
            tabid: TabViewIds.VoiceMailVoiceMails,
            active: activeTabIdRef.current === TabViewIds.VoiceMailVoiceMails,
            viewtype: MenuActiveItem.VoiceMailVoiceMails
        }] as any[]);
    }

    const changeAlertsTab = () => {
        setAlertsTab([{
            key: 'alerts' + Math.random(),
            content: <Flex className="alerts-tab-header">
                <Text content={getVoicemailAlertsText()} />
                {newAlertsNotifications.current.length > 0 && <Button key={countAlertsButtonKey} className="alerts-notification-tab-count" circular
                    content={newAlertsNotifications.current.length} size="small" />}
            </Flex >,
            onClick: () => { changeView(new ActiveMediaView({ ActiveItem: MenuActiveItem.VoiceMailAlerts, ActiveTabId: TabViewIds.VoiceMailAlerts })) },
            tabcontent: <AlertsTabContentView key={'alerts-tab-ContentView' + Math.random()} realtimeNotificationList={realtimeNotificationList.current} />,
            tabid: TabViewIds.VoiceMailAlerts,
            active: activeTabIdRef.current === TabViewIds.VoiceMailAlerts,
            viewtype: MenuActiveItem.VoiceMailAlerts
        }] as any[]);
    }

    React.useEffect(() => {
        initialize();
        return () => componentWillUnmount();
    }, []);

    const componentWillUnmount = () => {
        GeneralHelper.logCox(`in VoiceMailsTabs.tsx, in componentWillUnmount`);
        subscriptionNewVoicemails?.unsubscribe();
        subscriptionNewAlerts?.unsubscribe();
    }

    return (
        <>
            <Flex className="menu-tabs-container" key="menu-tabs-container-flex-key">
                {isRealtimeRportsAccessGranted && <Flex.Item key={realtimeNotificationListKey + "flex-item"}>
                    <Menu key={realtimeNotificationListKey}
                        items={alertsTab}
                        underlined
                        primary
                        accessibility={tabListBehavior}
                        aria-label="Voicemail tabs"
                        styles={({ theme: { siteVariables } }) => ({
                            margin: '0 0 20px',
                        })}
                        className="menu-tabs"
                    />
                </Flex.Item>}

                <Flex.Item key={voiceMailNotificationListKey + "flex-item"}>
                    <Menu key={voiceMailNotificationListKey}
                        items={voiceMailTab}
                        underlined
                        primary
                        accessibility={tabListBehavior}
                        aria-label="Voicemail tabs"
                        styles={({ theme: { siteVariables } }) => ({
                            margin: '0 0 20px',
                        })}
                        className="menu-tabs"
                    />
                </Flex.Item>
            </Flex>
            <div key={staticTabContentKey} className="notifications-body-container">
                {
                    alertsTab.map((el: any, i: number) => {
                        return (<div key={"alerts-tab-content" + el.tabid} className={activeTabId === el.tabid ? "active-tab" : "hidden-tab"}>{el.tabcontent}</div>);
                    })
                }
                {
                    voiceMailTab.map((el: any, i: number) => {
                        return (<div key={"voiecemails-tab-content" + el.tabid} className={activeTabId === el.tabid ? "active-tab" : "hidden-tab"}>{el.tabcontent}</div>);
                    })
                }
            </div>

        </>
    )
};

export default VoiceMailsTabs;

import { GraphService } from "./graph-service";

export interface ApiGraphService {
    getUserProfilePicture(azureId: string): Promise<any> | null | undefined;
    getRefreshTokenAndRetryCall(calledMethod: string, isDelegated: boolean): Promise<string>;
    getUserCalendarView(azureId: string): Promise<any> | null | undefined;
    getUserTimeZone(azureId: string): Promise<any> | null | undefined;
    getUserPresence(azureId: string): Promise<any> | null | undefined;
}

export const socketGraphApi = new GraphService();
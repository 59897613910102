import { Providers, TeamsProvider } from "@microsoft/mgt";
import * as constants from '../../utils/constants';
import { EnvHelper } from 'utils/helpers';
import Lockr from 'lockr';
import { AzureLoginType, logging, StorageKeys } from "utils";
import * as microsoftTeams from "@microsoft/teams-js";
import { ADSigninParams, socketAuth } from "services";

const azureClients = (window as any)['config'] ? (window as any)['config'].azureClients : undefined;

export class MGTHelper {
    private readonly logger = logging.getLogger('MGTHelper');

    public InitProvider() {
        const companyKey = Lockr.get<string>(StorageKeys.CompanyKey);
        const clientId = azureClients[companyKey] || azureClients['default'];

        const config = {
            clientId: clientId,
            authPopupUrl: `${window.location.origin}/${constants.Auth.singInMgt}${EnvHelper.isStage3() ? '?stage3=true' : ''}`,
            scopes: ['user.read', 'user.read.all']
        }
        TeamsProvider.microsoftTeamsLib = microsoftTeams;
        Providers.globalProvider = new TeamsProvider(config);
    }

    public StartSignIn(): void {
        TeamsProvider.microsoftTeamsLib = microsoftTeams;
        TeamsProvider.handleAuth();
    }

    public Login() {
        const companyKey = Lockr.get<string>(StorageKeys.CompanyKey);
        const clientId = azureClients[companyKey] || azureClients['default'];
        const idTokenKey = `msal.${clientId}.idtoken`;
        const provider = Providers.globalProvider;

        const startLogin = provider.login ? provider.login() : null;

        if (startLogin) {
            startLogin.then(() => {
                return provider.getAccessToken();
            }).then((accessToken: string) => {
                const idToken = localStorage.getItem(idTokenKey) as string;
                Lockr.set(StorageKeys.AzureADLoginType, AzureLoginType.TeamsProvider);
                Lockr.set(StorageKeys.TokenAD, accessToken);

                const params: ADSigninParams = {
                    IdToken: idToken,
                    LoginType: AzureLoginType.TeamsProvider
                };

                socketAuth.userADSignedIn.next(params);
            }).catch((err: any) => {               
                logging.errorHandler.next("ErrorMessage.MGTLogin");
                this.logger.error(err);
                window.location.reload();
            });
        }
    }

    public async RefreshAccessToken(callback?: any, callbackParam?: any) {
        let provider = Providers.globalProvider;
        if (!provider) {
            this.InitProvider();
            provider = Providers.globalProvider;
        }

        return provider.getAccessToken().then((accessToken: string) => {
            Lockr.set(StorageKeys.TokenAD, accessToken);
            if (callback) {
                return callback(accessToken, callbackParam);
            }
            return undefined;
        })
    }
}

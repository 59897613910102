export class AzureActiveDirectorySettingsDTO {
    Attributes!: AzureAdAttributeSettingDTO[];
    SipMappingType!: SipMappingType;
}

export class AzureAdAttributeSettingDTO {
    ColumnName!: string;
    IsVisible!: boolean;
    IsSearchable!: boolean;
    OrderValue!: number;
}

export enum SipMappingType {
    UPN = 0,
    Mail = 1
}

import { useIntl } from 'react-intl';
import { stringCellComparator } from '../../../../components/AdvancedTable';
import './operatorQueue.scss';

export const ColumnsResolver = () => {
  const intl = useIntl();

  const resposiveColumnClassName = 'big-grow table-column';

  return [
    { title: '', key: 'Media', name: 'OperatorCallsColumn', cellComparator: stringCellComparator, className: "smallest-grow" },
    {
      title: intl.formatMessage({ id: "OperatorQueueView.Caller" }),
      key: 'Caller', name: 'OperatorCallsCallerColumn', cellComparator: stringCellComparator, className: "caller-name-column"
    },
    {
      title: intl.formatMessage({ id: "OperatorQueueView.Waiting" }),
      key: 'Waiting', name: 'OperatorCallsWaitingColumn', cellComparator: stringCellComparator, className: resposiveColumnClassName
    },
    {
      title: intl.formatMessage({ id: "OperatorQueueView.Queue" }),
      key: 'Queue', name: 'OperatorCallsQueueColumn', cellComparator: stringCellComparator, className: resposiveColumnClassName
    },
    {
      title: intl.formatMessage({ id: "OperatorQueueView.Skill" }),
      key: 'Operator', name: 'OperatorCallsSkillColumn', cellComparator: stringCellComparator, className: resposiveColumnClassName
    },
    {
      title: intl.formatMessage({ id: "OperatorQueueView.Status" }),
      key: 'Status', name: 'OperatorCallsStatusColumn', cellComparator: stringCellComparator, className: resposiveColumnClassName
    },
    {
      title: intl.formatMessage({ id: "OperatorQueueView.Operator" }),
      key: 'Operator', name: 'OperatorCallsOperatorColumn', cellComparator: stringCellComparator, className: resposiveColumnClassName
    },
    {
      title: intl.formatMessage({ id: "OperatorQueueView.Agent" }),
      key: 'Agent', name: 'OperatorCallsAgentColumn', cellComparator: stringCellComparator, className: resposiveColumnClassName
    },
    { title: '', key: 'unPark', name: 'unPark', cellComparator: stringCellComparator, className: "unPark-column" },
  ];

}
import { IncomingRequests } from './incoming-requests';
import { EnvHelper } from 'utils/helpers';
import { SocketManager } from './socketio';
import { SignalRManager } from './signalR-manager';
export * from './socketio';
export * from './service-requests';
export * from './signalR-manager';

export const socketManager = !EnvHelper.isStage3() ? new SocketManager() : new SignalRManager();
export const incomingRequests = new IncomingRequests();

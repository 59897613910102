import * as React from "react";
import {
  Flex,
  Button,
  Popup,
  List,
  TableIcon,
  CustomerHubIcon,
  AttendeeIcon,
  CallIcon,
  ChatIcon,
  BellIcon,
  ContentIcon
} from "@fluentui/react-northstar";
import Lockr from "lockr";
import { useIntl } from "react-intl";
import "./managementView.scss";
import { socketQueue } from "../../services/queue";
import {
  ActiveMainViewItem,
  NotificationActionStatus,
  NotificationDataType,
  UserAccessRightsTypes,
  StorageKeys
} from "../../utils";
import { mainViewHelper } from "../../utils/helpers/main-view-helper";
import { webChatManager } from "../../services/io/web-chat";
import { webchatNotifications } from "../../utils/helpers/webchat-notifications-helper";
import { socketVoicemails } from "../../services/voiceMails";
import { NotificationContainerDTO } from "../../utils/domain/voiceMailsView/notificationContainerDTO";
import { QueueUserDTO, QueueUserS3DTO } from "../../utils/domain/queueUserDTO";
import { VoiceMailRecordDTO } from "../../utils/domain/voiceMailsView/voiceMailRecordDTO";
import {
  GeneralHelper,
  ConfigurationHelper,
  EnvHelper,
} from "../../utils/helpers";
import { Subscription } from "rxjs";
import { NotificationBaseDTO } from "../../utils/domain/voiceMailsView/notificationBaseDto";
import {
  QueueView,
  WrapUpView,
  PresenceView,
  SettingsView,
} from "../../containers";
import { RealtimeNotificationDTO } from "../../utils/domain/voiceMailsView/realtimeNotificationDTO";
import { socketSocialMedia } from "services/social-media";
import {
  CMMediaSessionMessageDTO,
  CMSourceType,
} from "utils/domain/socialMedia/socialMediaSessionDTO";
import { queuesService } from "../../services/queue/index";
import WrapUpExtendedView from "containers/WrapUpView/WrapUpExtendedView";
import { extendedVoicemailService } from "services/voiceMails/extendedVoicemailsApi";
import CurrentUserPresenceView from "containers/PresenceView/CurrentUserPresenceView";
import PlaySoundHelper, { SoundType } from "utils/helpers/play-sound-helper";

export const ManagementView = () => {
  const intl = useIntl();

  let listItemsArray: any[] = [];
  const [listItems, setListItems] = React.useState([] as any);
  const [queueRef, setQueueRef] = React.useState(Math.random() as any);
  const [isQueuesButtonDisabled, setQueuesButtonDisabled] =
    React.useState(true);
  const [isAnyChatQueueActive, setIsAnyChatQueueActive] = React.useState(false);
  const [isCmMediaActive, setIsCmMediaActive] = React.useState(false);
  const [isAnyQueueJoinedState, setIsAnyQueueJoinedState] =
    React.useState(true);
  const [activeViewItem, setActiveViewItem] = React.useState(
    ActiveMainViewItem.MediaView
  );
  const activeViewItemRef = React.useRef(ActiveMainViewItem.MediaView);
  const [chatSessionRefUpdates, setChatSessionRefUpdates] = React.useState(
    [] as any
  );
  const [cmChatSessionCount, setCmChatSessionCount] = React.useState(0);
  const [countButtonKey, setCountButtonKey] = React.useState(
    Math.random() as any
  );
  const [isRealtimeRportsAccessGranted, setIsRealtimeRportsAccessGranted] =
    React.useState(false);

  const newVoiceMails = React.useRef([] as any);
  const newVoicemailsNotifications = React.useRef([] as any);
  const newAlerts = React.useRef([] as any);
  const newAlertsNotifications = React.useRef([] as any);
  const [
    alertsVoicemailsNotificationCount,
    setAlertsVoicemailsNotificationCount,
  ] = React.useState(0);

  const [isSocialMediaButtonVisible, setIsSocialMediaButtonVisible] =
    React.useState(false);

  const isJoinCallInProgress = React.useRef(false);

  let subscriptionQueueChange: Subscription | null = null;
  let subscriptionNewWebChat: Subscription | null = null;
  let subscriptionChatAccepted: Subscription | null = null;
  let subscriptionNewWebChatRequestsOnAppLoad: Subscription | null = null;
  let subscriptionAlertVoicemailChangeNotification: Subscription | null = null;
  let subscriptionSendCmMessageReceivedFromCustomer: Subscription | null = null;
  let subscriptionShowSocialMediaSwitchBtn: Subscription | null = null;
  let subscriptionIsInACmMedia: Subscription | null = null;
  let subscriptionViewChanged: Subscription | null = null;
  let subscriptionQueueMembershipChanged: Subscription | null = null;
  let subscriptionQueueMembershipRemoved: Subscription | null = null;
  let extendedSubscriptionQueueMembershipAdded: Subscription | null = null;
  let extendedSubscriptionQueueMembershipRemoved: Subscription | null = null;
  let extendedSubscriptionQueueSupervisorChanged: Subscription | null = null;
  let subscriptionNewVoicemails: Subscription | null = null;

  function initialize() {
    GeneralHelper.logCox(`in ManagementView.tsx, in initialize`);

    subscriptionQueueChange?.unsubscribe();
    subscriptionQueueChange = socketQueue.subjectQueueChangeNotification.subscribe(
      (obj: any) => {
        if (
          !(
            obj.ChangedBySip === Lockr.get(StorageKeys.SIP) &&
            !obj.ChangedBySupervisor
          )
        ) {
          getQueues();
        }

        if (
          !EnvHelper.isStage3() &&
          obj.ChangedBySip === Lockr.get(StorageKeys.SIP) &&
          !obj.ChangedBySupervisor
        ) {
          handleVoicemailsNotifications(
            socketQueue.queuesArray,
            newVoiceMails.current
          );
        }
      }
    );

    subscriptionNewWebChat?.unsubscribe();
    subscriptionNewWebChat = webChatManager.onSendChatSessionToAgent.subscribe(
      (message: any) => {
        if (
          activeViewItem === ActiveMainViewItem.MediaView ||
          activeViewItem === ActiveMainViewItem.SocialMedia
        ) {
          addNewUpdatedChatSession(message.SessionRef);
        }

        webchatNotifications.playWebchatAudioNotifcation();
      }
    );

    subscriptionChatAccepted?.unsubscribe();
    subscriptionChatAccepted = webChatManager.onAcceptRejectChat.subscribe(
      (data: any) => {
        chatSessionHandled(data);
      }
    );

    subscriptionNewWebChatRequestsOnAppLoad?.unsubscribe();
    subscriptionNewWebChatRequestsOnAppLoad =
      webChatManager.newWebChatRequestsOnAppLoad.subscribe((data: any) => {
        notifyNewWebChatRequestsOnAppLoad(data);
      });

    if (!EnvHelper.isStage3()) {
      subscriptionAlertVoicemailChangeNotification?.unsubscribe();
      subscriptionAlertVoicemailChangeNotification =
        socketVoicemails.alertVoicemailChangeNotification.subscribe(
          (obj: NotificationBaseDTO) => {
            if (obj && obj.Type === NotificationDataType.Alert) {
              handleAlertsChange(obj);
            }
            if (obj && obj.Type === NotificationDataType.VoiceMail) {
              handleVoiceMailChange(obj);
            }
          }
        );

      subscriptionSendCmMessageReceivedFromCustomer?.unsubscribe();
      subscriptionSendCmMessageReceivedFromCustomer =
        socketSocialMedia.sendCmMessageReceivedFromCustomer.subscribe(
          (cmMessage: CMMediaSessionMessageDTO) => {
            if (activeViewItemRef.current === ActiveMainViewItem.CmMedia) {
              return;
            }
            if (cmMessage.SourceType === CMSourceType.FromCustomer) {
              setCmChatSessionCount((value: number) => {
                return value + 1;
              });
            }
          }
        );

      subscriptionShowSocialMediaSwitchBtn?.unsubscribe();
      subscriptionShowSocialMediaSwitchBtn =
        mainViewHelper.showSocialMediaSwitchBtn.subscribe((value: boolean) => {
          setIsSocialMediaButtonVisible(value);
        });

      subscriptionIsInACmMedia?.unsubscribe();
      subscriptionIsInACmMedia = socketSocialMedia.isInCmMedia.subscribe(
        (obj: boolean) => {
          setIsCmMediaActive(obj);
        }
      );

      subscriptionViewChanged?.unsubscribe();
      subscriptionViewChanged = mainViewHelper.viewChanged.subscribe(
        (item: ActiveMainViewItem) => {
          activeViewItemRef.current = item;
        }
      );
    }
    getQueues();
  }

  function notifyNewWebChatRequestsOnAppLoad(webchats: any) {
    webchats.forEach((element: any) => {
      addNewUpdatedChatSession(element);
    });
  }

  function chatSessionHandled(sessionRef: string) {
    setChatSessionRefUpdates((list: any) => {
      if (list.includes(sessionRef)) {
        return list.filter((item: any) => item !== sessionRef);
      }
      return list;
    });
  }

  function addNewUpdatedChatSession(sessionRef: string) {
    setChatSessionRefUpdates((list: any) => {
      if (!list.includes(sessionRef)) {
        list.push(sessionRef);
        return list;
      }
      return list;
    });

    setCountButtonKey(Math.random());
  }

  function AttachQueueMembershipChangesListners() {
    subscriptionQueueMembershipChanged?.unsubscribe();
    subscriptionQueueMembershipChanged = socketQueue.listenerNotifyQueueUserMembershipChange
      .received.subscribe((queueChange: any) => {
        updateQueuesFromSignalR(queueChange);
        processApiServiceQueueCallResponse(queuesService.queuesArray);
      });

    subscriptionQueueMembershipRemoved?.unsubscribe();
    subscriptionQueueMembershipRemoved = socketQueue.listenerNotifyQueueUserMembershipRemove
      .received.subscribe((queue: any) => {
        removeQueuesFromSignalR(queue);
        processApiServiceQueueCallResponse(queuesService.queuesArray);
      });

    extendedSubscriptionQueueMembershipAdded = queuesService.queueMembershipAdded.subscribe((queueChange: any) => {
      getExtendedVoicemailListForClient(true);
    });

    extendedSubscriptionQueueMembershipRemoved = queuesService.queueMembershipRemoved.subscribe((queue: any) => {
      getExtendedVoicemailListForClient(true);
    });

    extendedSubscriptionQueueSupervisorChanged = queuesService.queueSupervisorChanged.subscribe((queue: any) => {
      getExtendedVoicemailListForClient(true);
    });
  }

  function getQueues() {
    // Stage3
    if (EnvHelper.isStage3()) {
      AttachQueueMembershipChangesListners();
      const userRef = Lockr.get<string>(StorageKeys.UserId);
      queuesService.getQueues(userRef)
        .then((result: QueueUserS3DTO[]) => {
          processApiServiceQueueCallResponse(result);
        })
        .catch((exception) => {
          console.log(`Failure getting Queues ${exception}`);
        });
      return;
    }
    // Stage2
    const userId = Lockr.get<number>(StorageKeys.UserId);
    socketQueue.getQueues(userId).then((result: QueueUserDTO[]) => {
      processSocketQueueCallResponse(result);
    });
  }

  function processSocketQueueCallResponse(result: QueueUserDTO[]) {
    setIsRealtimeRportsAccessGranted(
      GeneralHelper.checkIsRealtimeReportsAccessGrantedForAgent(result)
    );

    listItemsArray = [];
    socketQueue.queuesArray = [];

    if (result && result.length) {
      setQueuesButtonDisabled(false);
      socketQueue.queuesArray = result;

      for (const queue of socketQueue.queuesArray) {
        const isReadOnlyQueue = isReadOnly(queue.AccessRights);

        listItemsArray.push({
          key: Math.random() + "item",
          content: (
            <QueueView
              selectQueue={selectQueue}
              index={listItemsArray.length}
              queue={queue}
              isReadOnly={isReadOnlyQueue}
              key={queue.QueueRef + "view"}
            />
          ),
          className: isReadOnlyQueue
            ? "queue-list-item readonly"
            : "queue-list-item",
        });
      }

      isAnyQueueJoined()
        ? setIsAnyQueueJoinedState(true)
        : setIsAnyQueueJoinedState(false);

      setListItems(listItemsArray);

      socketQueue.subjectQueueChange.next(socketQueue.queuesArray);
    } else {
      setIsAnyQueueJoinedState(false);
      setQueuesButtonDisabled(true);
      setListItems([]);
      setTimeout(() => {
        getQueues();
      }, 10000);
    }

    const anyChatQueue = isAnyWebChatQueueActive();
    anyChatQueue
      ? setIsAnyChatQueueActive(true)
      : setIsAnyChatQueueActive(false);

    if (
      !anyChatQueue &&
      activeViewItemRef.current === ActiveMainViewItem.WebChatView
    ) {
      selectMainViewItem(ActiveMainViewItem.MediaView);
    }

    if (!EnvHelper.isStage3()) {
      getNotificationListForClient().then(
        (notificationContainer: NotificationContainerDTO) => {
          const voicemails = notificationContainer.VoiceMailNotificationList;
          const alerts = notificationContainer.RealtimeNotificationList;
          handleVoicemailsNotifications(result, voicemails);
          handleAlertsNotifications(alerts);
        }
      );
    }
  }

  ///Stage 3 process request

  function processApiServiceQueueCallResponse(result: QueueUserS3DTO[]) {
    listItemsArray = [];
    queuesService.queuesArray = [];

    if (result && result.length) {
      setQueuesButtonDisabled(false);
      queuesService.queuesArray = result;

      for (const queue of queuesService.queuesArray) {
        const isReadOnlyQueue = !queue.CanJoin;

        listItemsArray.push({
          key: Math.random() + "item",
          content: (
            <QueueView
              selectQueue={selectQueueStage3}
              index={listItemsArray.length}
              queue={queue}
              isReadOnly={isReadOnlyQueue}
              key={queue.QueueRef + "view"}
            />
          ),
          className: isReadOnlyQueue
            ? "queue-list-item readonly"
            : "queue-list-item",
        });
      }

      setIsAnyQueueJoinedState(isAnyQueueJoinedStage3());
      setListItems(listItemsArray);
      getExtendedVoicemailListForClient();

      socketQueue.subjectQueueChange.next(queuesService.queuesArray);
    } else {
      setIsAnyQueueJoinedState(false);
      setQueuesButtonDisabled(true);
      setListItems([]);
      setTimeout(() => {
        getQueues();
      }, 10000);
    }

    const anyChatQueue = isAnyWebChatQueueActive();
    setIsAnyChatQueueActive(anyChatQueue);

    if (
      !anyChatQueue &&
      activeViewItemRef.current === ActiveMainViewItem.WebChatView
    ) {
      selectMainViewItem(ActiveMainViewItem.MediaView);
    }

    subscriptionNewVoicemails = extendedVoicemailService.newVoicemailNotification.subscribe(() => {
      newVoicemailsNotifications.current = extendedVoicemailService.getCachedNewVoicemails();
      setAlertsVoicemailsNotificationCount(newVoicemailsNotifications.current.length);
    });
  }

  function updateQueuesFromSignalR(changedQueue: QueueUserS3DTO) {
    const queueToUpdate = queuesService.queuesArray.find((x: QueueUserS3DTO) => x.QueueRef === changedQueue.QueueRef);
    if (queueToUpdate) {
      handleQueueSupervisorChanged(changedQueue, queueToUpdate);
      handleQueueOperatorChanged(changedQueue, queueToUpdate);
      // join or can join was changed on queue from admin
      queueToUpdate.CanJoin = changedQueue.CanJoin;
      queueToUpdate.IsAttached = changedQueue.IsAttached;
      queueToUpdate.IsSupervisor = changedQueue.IsSupervisor;
      queueToUpdate.IsOperator = changedQueue.IsOperator;
    } else if (changedQueue.IsQueueAssigned) {
      // new queue was membered
      queuesService.queuesArray.push(changedQueue);
      queuesService.queueMembershipAdded.next(changedQueue);
    }
  }

  function removeQueuesFromSignalR(changedQueue: QueueUserS3DTO) {
    const queueToRemoveIndex = queuesService.queuesArray.findIndex((x: QueueUserS3DTO) => x.QueueRef === changedQueue.QueueRef);

    if (queueToRemoveIndex > -1) {
      queuesService.queuesArray.splice(queueToRemoveIndex, 1);
      queuesService.queueMembershipRemoved.next(changedQueue);
    }
  }

  const handleQueueSupervisorChanged = (changedQueue: QueueUserS3DTO, oldQueueDetails: QueueUserS3DTO) => {
    if (changedQueue.IsSupervisor !== oldQueueDetails.IsSupervisor) {
      queuesService.queueSupervisorChanged.next(changedQueue);
    }
  }
  const handleQueueOperatorChanged = (changedQueue: QueueUserS3DTO, oldQueueDetails: QueueUserS3DTO) => {
    if (changedQueue.IsOperator !== oldQueueDetails.IsOperator) {
      queuesService.queueOperatorChanged.next(changedQueue);
    }
  }

  async function selectQueueStage3(index: any) {
    const userRef = Lockr.get<string>(StorageKeys.UserId);
    const clickedQueue = queuesService.queuesArray[index];
    if (clickedQueue.CanJoin && !isJoinCallInProgress.current) {
      clickedQueue.IsAttached = !clickedQueue.IsAttached;
      listItemsArray[index].content = (
        <QueueView
          selectQueue={selectQueueStage3}
          index={index}
          queue={queuesService.queuesArray[index]}
          key={queuesService.queuesArray[index].QueueRef + "view"}
        />
      );
      setListItems(listItemsArray);
      setQueueRef(Math.random());

      setIsAnyQueueJoinedState(isAnyQueueJoinedStage3());

      isJoinCallInProgress.current = true;
      await queuesService.clientQueuesChanged(
        userRef,
        clickedQueue.QueueRef,
        clickedQueue.IsAttached
      );
      isJoinCallInProgress.current = false;
    }
  }
  ///Stage 3 process request

  function selectQueue(index: any) {
    if (!isReadOnly(socketQueue.queuesArray[index].AccessRights)) {
      socketQueue.queuesArray[index].IsAttached =
        !socketQueue.queuesArray[index].IsAttached;
      listItemsArray[index].content = (
        <QueueView
          selectQueue={selectQueue}
          index={index}
          queue={socketQueue.queuesArray[index]}
          key={socketQueue.queuesArray[index].QueueRef + "view"}
        />
      );
      setListItems(listItemsArray);
      setQueueRef(Math.random());

      setIsAnyQueueJoinedState(isAnyQueueJoined());

      const sip = Lockr.get<string>(StorageKeys.SIP);

      if (sip) {
        socketQueue.clientQueuesChanged(
          sip,
          socketQueue.queuesArray[index].QueueRef,
          socketQueue.queuesArray[index].IsAttached,
          false
        );
        socketQueue.subjectQueueChange.next(socketQueue.queuesArray);
      }

      const anyChatQueue = isAnyWebChatQueueActive();

      setIsAnyChatQueueActive(anyChatQueue);

      if (
        !anyChatQueue &&
        activeViewItemRef.current === ActiveMainViewItem.WebChatView
      ) {
        selectMainViewItem(ActiveMainViewItem.MediaView);
      }
    }
  }

  function isReadOnly(accessRights: any) {
    if (accessRights) {
      const userAccessRights = accessRights.UserAccessRights;
      if (userAccessRights.length) {
        const joinRights = userAccessRights.filter(function (accessRight: any) {
          return (
            accessRight.QueueOperationRef === UserAccessRightsTypes.JoinLeave
          );
        });
        return joinRights.length ? !joinRights[0].IsGranted : false;
      }
    }

    return false;
  }

  function isAnyQueueJoined() {
    return socketQueue.queuesArray.some(queue => queue.IsAttached === true);
  }

  function isAnyQueueJoinedStage3() {
    return queuesService.queuesArray.some(queue => queue.IsAttached === true);
  }

  function isAnyWebChatQueueActive() {
    return socketQueue.queuesArray.some(queue => queue.IsAttached === true && queue.IsChatQueue);
  }

  function selectMainViewItem(activeView: ActiveMainViewItem) {
    if (activeView === ActiveMainViewItem.CmMedia) {
      setCmChatSessionCount(0);
    }

    activeViewItemRef.current = activeView;
    setActiveViewItem(activeView);
    mainViewHelper.viewChanged.next(activeView);
  }

  const getNotificationListForClient = () => {
    const userId = Lockr.get<number>(StorageKeys.UserId);

    return socketVoicemails.GetNotificationListForClient(userId);
  };

  const getExtendedVoicemailListForClient = async (invalidateCache: boolean = false) => {
    const userId = Lockr.get<string>(StorageKeys.UserId);
    const _ = await extendedVoicemailService.getVoicemails(userId);

    newVoicemailsNotifications.current = extendedVoicemailService.getCachedNewVoicemails();
    setAlertsVoicemailsNotificationCount(newVoicemailsNotifications.current.length);
    extendedVoicemailService.newVoicemailNotification.next();

    if (invalidateCache) {
      extendedVoicemailService.subscriptionInvalidateVoicemailCache.next();
    }
  };

  const handleVoicemailsNotifications = (
    queues: QueueUserDTO[],
    voiceMails: VoiceMailRecordDTO[]
  ) => {
    if (!queues || !queues.length) {
      newVoicemailsNotifications.current = [];
      setAlertsVoicemailsNotificationCount(
        newVoicemailsNotifications.current.length +
        newAlertsNotifications.current.length
      );

      socketVoicemails.newVoicemails = [];
      socketVoicemails.newVoicemailsChanged.next();
      return;
    }

    if (!voiceMails || !voiceMails.length) {
      newVoicemailsNotifications.current = [];
      setAlertsVoicemailsNotificationCount(
        newVoicemailsNotifications.current.length +
        newAlertsNotifications.current.length
      );

      socketVoicemails.newVoicemails = [];
      socketVoicemails.newVoicemailsChanged.next();
      return;
    }
    newVoiceMails.current = voiceMails;
    const attachedQueues = GeneralHelper.getAttachedUserQueues(queues);

    const voicemailNotifications = [] as VoiceMailRecordDTO[];

    voiceMails.forEach((voiceMail: VoiceMailRecordDTO) => {
      if (!voiceMail.IsViewed && attachedQueues?.includes(voiceMail.QueueRef)) {
        voicemailNotifications.push(voiceMail);
      }
    });

    newVoicemailsNotifications.current = voicemailNotifications;
    setAlertsVoicemailsNotificationCount(
      newVoicemailsNotifications.current.length +
      newAlertsNotifications.current.length
    );

    socketVoicemails.newVoicemails = voicemailNotifications;
    socketVoicemails.newVoicemailsChanged.next();
  };

  const handleAlertsNotifications = (alerts: RealtimeNotificationDTO[]) => {
    if (!alerts || !alerts.length) {
      newAlertsNotifications.current = [];
      setAlertsVoicemailsNotificationCount(
        newVoicemailsNotifications.current.length +
        newAlertsNotifications.current.length
      );

      socketVoicemails.newAlerts = [];
      socketVoicemails.newAlertsChanged.next();
      return;
    }
    newAlerts.current = alerts;

    const alertsNotifications = [] as RealtimeNotificationDTO[];

    alerts.forEach((alert: RealtimeNotificationDTO) => {
      if (!alert.IsViewed) {
        alertsNotifications.push(alert);
      }
    });

    newAlertsNotifications.current = alertsNotifications;
    setAlertsVoicemailsNotificationCount(
      newVoicemailsNotifications.current.length +
      newAlertsNotifications.current.length
    );

    socketVoicemails.newAlerts = alertsNotifications;
    socketVoicemails.newAlertsChanged.next();
  };

  const handleVoiceMailChange = (
    voiceMailNotification: NotificationBaseDTO
  ) => {
    if (
      !voiceMailNotification ||
      !voiceMailNotification.VoiceMailNotification
    ) {
      return;
    }

    getNotificationListForClient().then(
      (notificationContainer: NotificationContainerDTO) => {
        const voicemails = notificationContainer.VoiceMailNotificationList;
        handleVoicemailsNotifications(socketQueue.queuesArray, voicemails);

        const notifiedQueues = socketQueue.queuesArray.filter(
          (x) =>
            x.QueueRef === voiceMailNotification.VoiceMailNotification?.QueueRef
        );
        const isQueueAttached =
          notifiedQueues && notifiedQueues.length
            ? notifiedQueues[0].IsAttached
            : false;

        if (
          voiceMailNotification.NotificationActionStatus ===
          NotificationActionStatus.Added &&
          isQueueAttached
        ) {
          PlaySoundHelper.playSound(SoundType.Notification);
        }
      }
    );
  };

  const handleAlertsChange = (alertNotification: NotificationBaseDTO) => {
    const userId = Lockr.get<number>(StorageKeys.UserId);

    if (!alertNotification || !alertNotification.AlertNotification) {
      return;
    }

    if (alertNotification.AlertNotification.AgentRef !== userId) {
      return;
    }

    getNotificationListForClient().then(
      (notificationContainer: NotificationContainerDTO) => {
        const alerts = notificationContainer.RealtimeNotificationList;
        handleAlertsNotifications(alerts);

        if (
          alertNotification.NotificationActionStatus ===
          NotificationActionStatus.Added
        ) {
          PlaySoundHelper.playSound(SoundType.Notification);
        }
      }
    );
  };

  React.useEffect(() => {
    initialize();

    return () => componentWillUnmount();
  }, []);

  const componentWillUnmount = () => {
    GeneralHelper.logCox(`in ManagementView.tsx, in componentWillUnmount`);

    subscriptionQueueChange?.unsubscribe();
    subscriptionNewWebChat?.unsubscribe();
    subscriptionChatAccepted?.unsubscribe();
    subscriptionNewWebChatRequestsOnAppLoad?.unsubscribe();
    subscriptionAlertVoicemailChangeNotification?.unsubscribe();
    subscriptionSendCmMessageReceivedFromCustomer?.unsubscribe();
    subscriptionShowSocialMediaSwitchBtn?.unsubscribe();
    subscriptionIsInACmMedia?.unsubscribe();
    subscriptionViewChanged?.unsubscribe();
    subscriptionQueueMembershipChanged?.unsubscribe();
    subscriptionQueueMembershipRemoved?.unsubscribe();
    subscriptionNewVoicemails?.unsubscribe();
    extendedSubscriptionQueueMembershipAdded?.unsubscribe();
    extendedSubscriptionQueueMembershipRemoved?.unsubscribe();
    extendedSubscriptionQueueSupervisorChanged?.unsubscribe();
  };

  let QueuePopup;
  if (listItems.length > 0) {
    QueuePopup = (
      <Popup
        position="below"
        align="center"
        key="queue-popup"
        trigger={
          <Button
            disabled={isQueuesButtonDisabled}
            circular
            icon={<AttendeeIcon />}
            primary
            title={intl.formatMessage({ id: "ManagementView.Queues" })}
            size="medium"
            className={
              isAnyQueueJoinedState || isQueuesButtonDisabled
                ? "queues-popup-trigger"
                : "queues-popup-trigger no-joined-queue"
            }
          />
        }
        content={{
          content: <List key={queueRef} selectable items={listItems} />,
          className: "queue-list",
        }}
      />
    );
  } else {
    QueuePopup = (
      <Button
        disabled={isQueuesButtonDisabled}
        circular
        icon={<AttendeeIcon />}
        primary
        title={intl.formatMessage({ id: "ManagementView.Queues" })}
        size="medium"
        className={
          isAnyQueueJoinedState || isQueuesButtonDisabled
            ? "queues-popup-trigger"
            : "queues-popup-trigger no-joined-queue"
        }
      />
    );
  }

  const openRealtimeReports = () => {
    const frontEndUrl = ConfigurationHelper.getFrontEndUrl(
      Lockr.get<string>(StorageKeys.CompanyKey)
    );
    const path = "/Home/TeamsAgentLogin";
    const query = `?Token=${Lockr.get(
      StorageKeys.TokenId
    )}&CompanyId=${Lockr.get(StorageKeys.CompanyId)}`;

    const url = `${frontEndUrl}${path}${query}`;
    window.open(url);
  };

  return (
    <Flex
      styles={({ theme: { siteVariables } }) => ({
        backgroundColor: siteVariables.colorScheme.default.backgroundPressed,
        color: siteVariables.colorScheme.default.foregroundPressed,
        padding: "10px 20px",
      })}
      gap="gap.medium"
      space="between"
    >
      <Flex gap="gap.medium">
        {QueuePopup}
        {
          <div style={{ position: "relative" }}>
            {isSocialMediaButtonVisible &&
              (activeViewItem === ActiveMainViewItem.SocialMedia ||
                activeViewItem === ActiveMainViewItem.WebChatView) && (
                <Button
                  className="notification-count"
                  circular
                  content={1}
                  size="small"
                />
              )}
            <Button
              circular
              icon={<CallIcon />}
              primary
              title={intl.formatMessage({ id: "ManagementView.Call" })}
              size="medium"
              onClick={() => selectMainViewItem(ActiveMainViewItem.MediaView)}
            />
          </div>
        }
        {!EnvHelper.isStage3() && isSocialMediaButtonVisible && (
          <div style={{ position: "relative" }}>
            {(activeViewItem === ActiveMainViewItem.MediaView ||
              activeViewItem === ActiveMainViewItem.WebChatView) && (
                <Button
                  className="notification-count"
                  circular
                  content={1}
                  size="small"
                />
              )}
            <Button
              circular
              icon={<CustomerHubIcon />}
              primary
              title={intl.formatMessage({ id: "SocialMedia.SocialMedia" })}
              size="medium"
              onClick={() => selectMainViewItem(ActiveMainViewItem.SocialMedia)}
            />
          </div>
        )}

        {isAnyChatQueueActive && (
          <div style={{ position: "relative" }}>
            {chatSessionRefUpdates.length > 0 && (
              <Button
                key={countButtonKey}
                className="notification-count"
                circular
                content={chatSessionRefUpdates.length}
                size="small"
              />
            )}
            <Button
              circular
              icon={<ChatIcon />}
              primary
              title={intl.formatMessage({ id: "ManagementView.Chat" })}
              size="medium"
              onClick={() => selectMainViewItem(ActiveMainViewItem.WebChatView)}
            />
          </div>
        )}
        {isCmMediaActive && (
          <div style={{ position: "relative" }}>
            {cmChatSessionCount > 0 && (
              <Button
                key={countButtonKey}
                className="notification-count"
                circular
                content={cmChatSessionCount}
                size="small"
              />
            )}
            <Button
              icon={
                <img alt="CM Media Icon" width="35" className="media-cm-icon" />
              }
              circular
              iconOnly
              text
              title={intl.formatMessage({ id: "ManagementView.Chat" })}
              size="medium"
              onClick={() => selectMainViewItem(ActiveMainViewItem.CmMedia)}
            />
          </div>
        )}
      </Flex>

      <Flex vAlign="center" gap="gap.smaller">
        {EnvHelper.isStage3() ? <WrapUpExtendedView /> : <WrapUpView />}
        {EnvHelper.isStage3() ? <CurrentUserPresenceView enableKeyboardShortcut /> : <PresenceView />}
        {
          <div style={{ position: "relative" }}>
            {alertsVoicemailsNotificationCount > 0 && (
              <Button
                className="voicemail-notification-count"
                circular
                content={alertsVoicemailsNotificationCount}
                size="small"
              />
            )}
            <Button
              iconOnly
              text
              icon={<BellIcon size="large" />}
              title="Voice Mails"
              size="medium"
              onClick={() =>
                selectMainViewItem(ActiveMainViewItem.VoiceMailsView)
              }
              className="voice-mails-button"
            />
          </div>
        }
        {!EnvHelper.isStage3() && isRealtimeRportsAccessGranted && (
          <Button
            icon={<TableIcon size="large" />}
            onClick={() => openRealtimeReports()}
            // onClick={() => selectMainViewItem(ActiveMainViewItem.RealtimeReportsView)}
            text
            iconOnly
            title={intl.formatMessage({ id: "ManagementView.RealtimeReports" })}
            className="realtime-reports-button"
          />
        )}

        {!EnvHelper.isStage3() && Lockr.get<boolean>(StorageKeys.IsCustomerJourneyEnabled) && (
          <div style={{ position: "relative" }}>
            <Button iconOnly text icon={<ContentIcon size="large" />} title="Customer journey" size="medium" onClick={() => selectMainViewItem(ActiveMainViewItem.CustomerJourneyView)} />
          </div>
        )}
        <SettingsView />
      </Flex>
    </Flex>
  );
};

export default ManagementView;

import { Flex, Header, Divider, Text, Button, Tooltip, List, ExpandIcon, ChatIcon, Alert } from "@fluentui/react-northstar";
import AgentAvatar from "components/AgentAvatar";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import '../InteractionDetails/InteractionDetailView.scss';
import { customerJourneyManager } from "services/io/customer-journey";
import { CustomerDTO, IJourneyCustomerSessionDTO } from "utils/domain/customerJourneyModels";
import { GeneralHelper } from "utils/helpers";

type HistoryConversationProps = {
    CurrentCustomer: CustomerDTO,
    SelectedHistoricalConversation?: IJourneyCustomerSessionDTO,
    SelectHistoricalConversation: (arg: IJourneyCustomerSessionDTO) => void
}
export const HistoricalConversationOverview: React.FC<HistoryConversationProps> = ({CurrentCustomer, SelectedHistoricalConversation: selectedHistoricalConversation, SelectHistoricalConversation: selectHistoricalConversation}) => {
    const intl = useIntl();

    const [customer, setCustomer] = useState<CustomerDTO>(CurrentCustomer);
    // const [newConvoList, setNewConvoList] = useState<IJourneyCustomerSessionDTO[]>([] as IJourneyCustomerSessionDTO[]);
    const [historicalConvoList, SetHistoricalConvoList] = useState([] as any);
    const [historicalConvos, setHistoricalConvos] = useState<IJourneyCustomerSessionDTO[]>([] as IJourneyCustomerSessionDTO[]);
    const convoRef = React.useRef<IJourneyCustomerSessionDTO[]>([] as IJourneyCustomerSessionDTO[]);
    const currentStartIndex = 0;//it is not changed for now, so no useState
    const defaultPageSize = 5;//it is not changed for now, so no useState
    const [selectedConversation, setSelectedConversation] = useState(selectedHistoricalConversation);

    React.useEffect(() => {
        initialize();
    }, []);

    React.useEffect(() => {
        GeneralHelper.logCox("Received new current customer object in interactions overview component.");
        console.log(CurrentCustomer);
        setCustomer(CurrentCustomer);
        getHistoricalConversations(CurrentCustomer);
    }, [CurrentCustomer]);

    React.useEffect(() => {
        if (selectedHistoricalConversation === undefined || historicalConvos === undefined) {
            return;
        }
        if (historicalConvos.find((element) => element.SessionID === selectedHistoricalConversation.SessionID) !== undefined) {
            setSelectedConversation(selectedHistoricalConversation);

            //Should actually be an updateHistoricalConversations const to match the historicalConvoList to the historicalConvos list.
            mapHistoricalConversations(historicalConvos);
        }
    }, [selectedHistoricalConversation]);

    const initialize = () => {
        getHistoricalConversations(CurrentCustomer);
    }

    const getHistoricalConversations = (mapCustomer: CustomerDTO) => {
        if (mapCustomer === undefined || mapCustomer.IsPlaceholder) {
            return;
        }
        const customerId = mapCustomer.ID;

        customerJourneyManager.getConversationHistoryByCustomer({
            companyRef: customer.CompanyRef, customerId: customerId, startIndex: currentStartIndex, pageSize: defaultPageSize
        }).then((result: IJourneyCustomerSessionDTO[]) => {
            console.log("received conversation list response");
            console.log(result);
            var convos: IJourneyCustomerSessionDTO[] = [] as IJourneyCustomerSessionDTO[];
            result.forEach((element: any, index: any) => {
                convos.push(element as IJourneyCustomerSessionDTO);
            })
            console.log(convos);
            updateConversations(convos);
        }).catch(err => {
            console.log(err);
        });
    }

    const updateConversations = (conversations: IJourneyCustomerSessionDTO[]) => {
        console.log("Updating conversations list");
        console.log(conversations);
        convoRef.current = conversations;
        setHistoricalConvos(conversations);
        mapHistoricalConversations(convoRef.current);
    }

    const mapHistoricalConversations = (result?: IJourneyCustomerSessionDTO[]) => {
        const itemList: any = [];
        console.log("Mapping historical conversations..");
        console.log(result);
        if (result === undefined || !Array.isArray(result) || result.length < 1) {
            SetHistoricalConvoList(itemList);
            console.log(itemList);
            return;
        }

        result.forEach((element: IJourneyCustomerSessionDTO, index: any) => {
            itemList.push(getConversationItem(element));
        });

        SetHistoricalConvoList(itemList);
        console.log("Mapped historical conversations");
        console.log(itemList);
    }

    function onSelectHistoricalConversation(key: any) {
        console.log("Selecting historical convo...");
        console.log(key);
        console.log(historicalConvoList);
        console.log(convoRef.current);
        var itemIndex;
        (historicalConvoList as any[]).forEach((element, index) => {
            console.log("Looping through convo items list..");
            console.log(element);
            if (element.key === key) {
                itemIndex = index;
            }
        });
        if (convoRef.current === undefined || convoRef.current.length < 1) {
            console.log("Historical conversations === undefined");
            return;
        }

        var conversation = undefined;
        
        convoRef.current.forEach((element: IJourneyCustomerSessionDTO, index: any) => {
            console.log("Looping through historical convos..");
            console.log(element);
            if (element.SessionID === key) {
                conversation = element;
            }
        })
        if (conversation === undefined) {
            console.log("Found conversation with undefined values. returing..");
            return;
        }
        console.log("Found conversation to select..");
        console.log(conversation);
        selectHistoricalConversation(conversation);
        setSelectedConversation(conversation);
        var item = getConversationItem(conversation);
        if (itemIndex !== undefined) {
            (historicalConvoList as any[]).fill(item, itemIndex);
        } else {
            (historicalConvoList as any[]).push(item);
        }
    }

    const CommunicationMediumIcon =(conversation: IJourneyCustomerSessionDTO) => {
        var icon: any;

        if (conversation.ConversationType === 0) {
            icon = <Flex>
                {getPhoneCallIcon(conversation)}
            </Flex>
        }
        if (conversation.ConversationType === 1) {
            icon = <ChatIcon size="large" styles={({ theme: {siteVariables} }) => ({
                color: siteVariables.colorScheme.brand.foreground,
            })} />;
        }

        return icon;
    }

    const getPhoneCallIcon = (conversation: IJourneyCustomerSessionDTO) => {
        if (conversation.JourneyCustomerConversationDTO.IsOutBound) {
            return(<img alt='Outgoing Call Icon' className='historical-convo-icon outgoing-call-icon' />);
        } else {
            if (conversation.JourneyCustomerConversationDTO.IsHandled) {
                return(<img alt='Incoming Call Icon' className='historical-convo-icon incoming-call-icon' />);
            } else {
                return(<img alt='Missed Call Icon' className='historical-convo-icon missed-call-icon' />);
            }
        }
    }

    const getConversationItem = (conversation: IJourneyCustomerSessionDTO) => {
        return ({
            key: conversation.SessionID,
            content: <Flex column space="between" onClick={() => {
                onSelectHistoricalConversation(conversation.SessionID);}} styles={({ theme: { siteVariables } }) => ({
                backgroundColor: ((selectedConversation !== undefined && selectedConversation.SessionID === conversation.SessionID) ? siteVariables.colorScheme.brand.background1 : siteVariables.colorScheme.default.background2),
                color: siteVariables.colorScheme.default.foreground2,
                width: "100%",
                borderRadius: "10px",
                marginBottom: "2.5%",
                padding: "1%"
            })}>
                <Flex gap="gap.small" 
                // padding="padding.medium"
                >
                    <Flex.Item grow>
                        <Flex space="between">
                            <Flex vAlign="center" gap="gap.small">
                                {CommunicationMediumIcon(conversation)}
                                <Header as="h2" content={`${(conversation.ConversationTypeString)} (${customerJourneyManager.getDateTimeFormatted(conversation.JourneyCustomerConversationDTO.Date)})`} styles={({ theme: {siteVariables} }) => ({
                                    color: siteVariables.colorScheme.brand.foreground,
                                    margin: "0px"
                                })}/>
                            </Flex>
                            <Flex gap="gap.small">
                                <Tooltip
                                trigger={<Button text icon={<ExpandIcon size="large" />} iconOnly 
                                onClick={() => {
                                    onSelectHistoricalConversation(conversation.SessionID);}}/>}
                                content={intl.formatMessage({ id: "CustomerJourney.InteractionsOverview.ShowDetails"})} />
                            </Flex>
                        </Flex>
                    </Flex.Item>
                </Flex>
                <Divider size={3}/>
                <Flex column styles={{marginTop: "10px !important", paddingLeft: "30px"}} >
                    <Flex hAlign="stretch" space="evenly" gap="gap.small" styles={{marginBottom: 0}}>
                        <Flex fill>                       
                            <Text content={intl.formatMessage({ id: "CustomerJourney.InteractionsOverview.AgentInteractionCall"})} weight="bold" size="medium" />
                        </Flex>
                        {conversation.JourneyCustomerConversationDTO.Queue && <Flex fill>                        
                            <Text content={intl.formatMessage({ id: "AgentView.Queue"})} weight="bold" size="medium" />
                        </Flex>}
                    </Flex>
                    <Flex space="between" gap="gap.small" styles={{paddingTop: 0}}>
                        <Flex fill>                       
                            <Flex space="between" >
                                <Flex gap="gap.small">
                                    {conversation.JourneyCustomerConversationDTO.Agent && <AgentAvatar
                                    name={conversation.JourneyCustomerConversationDTO.Agent.FullName}
                                    showPopup
                                    />}
                                    {!conversation.JourneyCustomerConversationDTO.Agent && <Text content={intl.formatMessage({ id: "CustomerJourney.InteractionsOverview.Unanswered"})} />}
                                </Flex>
                            </Flex>
                        </Flex>
                        {conversation.JourneyCustomerConversationDTO.Queue && <Flex fill>                  
                        <Text content={conversation.JourneyCustomerConversationDTO.Queue.Name} size="medium" />
                        </Flex>}
                    </Flex>
                </Flex>
            </Flex>
        })
    };

    return (
        <Flex column
            styles={({ theme: {siteVariables} }) => ({
                color: siteVariables.colorScheme.default.foreground,
                backgroundColor: siteVariables.colorScheme.default.background,
            })}
        >
            <Flex gap="gap.small" padding="padding.medium">
                <Header as="h2" content={intl.formatMessage({ id: "CustomerJourney.InteractionsOverview.Title"})} styles={({ theme: {siteVariables} }) => ({
                    color: siteVariables.colorScheme.brand.foreground,
                    margin: "0px"
                })}/>
            </Flex>
            <Divider size={3}/>
            <Flex column styles={{maxHeight: "50vh", overflow: "scroll"}}>
                {((historicalConvoList as any[]).length < 1) && <Alert info styles={{alignSelf: "center", width: "80%", textAlign: "center", marginTop: "2px"}} content={intl.formatMessage({ id: "CustomerJourney.InteractionsOverview.NoInteractions"})} />}
                <Flex gap="gap.small" styles={{paddingTop: "0px"}}>
                    <List styles={{width: "100%"}} items={historicalConvoList} />
                </Flex>
                {(customer.IsCallHistoryLoaded === false) && 
                <Flex column gap="gap.small" padding="padding.medium" space="between" hAlign="center">
                    {/* <Text content="Conversation history is not loaded." />
                    <Tooltip
                    trigger={<Button icon={<LeaveIcon outline />}
                    content="Load conversation history" primary
                    onClick={() => {customer.loadCallHistory()}}
                    className="switch-customer-button" />}
                    content={"Load historical conversations of " +  customer.getFullName} 
                    /> */}

                </Flex>}
            </Flex>
        </Flex>
    );
}

export default HistoricalConversationOverview;
import { AcceptIcon, Button, CloseIcon, Divider, Flex, PhoneArrowIcon, Text } from "@fluentui/react-northstar";
import Timer from "components/Timer";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { ActionDetails, genericInviteService } from "./GenericInviteService";
import { useSubjectListenerEffect } from "utils/helpers/listenerHook";
import { mainViewHelper } from "utils/helpers/main-view-helper";
import { ActiveMainViewItem, StorageKeys } from "utils";
import Lockr from "lockr";
import ColdTransferIVRButton from "components/TransferButtons/ColdTransferIVRButton/ColdTransferIVRButton";
import genericTransferService from "../Services/GenericTransfersService";
import { ColdTransferType } from "containers/CallView/CallActions";
import { GenericTransferState } from "utils/enums-s3";
import SubFlowIcon from "components/Icons/SubFlowIcon";

const styles = {
    acceptButton: {
        backgroundColor: "#5CAB45",
        color: "#ffffff",
        width: '50px',
        height: '50px',
        borderRadius: 0,
        ':hover': {
            backgroundColor: 'rgba(92,171,69,0.9)',
            color: "#ffffff",
        }
    },
    rejectButton: {
        backgroundColor: "#C4314B",
        color: "#ffffff",
        width: '50px',
        height: '50px',
        borderRadius: 0,
        ':hover': {
            backgroundColor: 'rgba(167,32,55,0.9)',
            color: "#ffffff",
        }
    }
};

const GenericActionsView = () => {
    const intl = useIntl();
    const [viewData, setViewData] = useState<ActionDetails>({});
    const [currentMainViewTab, setCurrentMainViewTab] = useState<ActiveMainViewItem>(ActiveMainViewItem.WebChatView);
    const [areTranferDestinationsLoading, setAreTranferDestinationsLoading] = useState<boolean>(false);
    const [queueTransferDestinations, setQueueTransferDestinations] = useState<any[]>([]);
    const [agentTransferDestinations, setAgentTransferDestinations] = useState<any[]>([]);
    const [isInvalidTransfer, setIsInvalidTransfer] = useState(false);
    const [transferTarget, setTransferTarget] = useState<string>();

    useEffect(() => {
        setCurrentMainViewTab(Lockr.get<ActiveMainViewItem>(StorageKeys.MainViewTab));
        genericInviteService.init();
    }, []);

    useSubjectListenerEffect((data: ActionDetails) => {
        setViewData(data)
    }, genericInviteService.dataUpdatedSubject);

    useSubjectListenerEffect((data: ActiveMainViewItem) => setCurrentMainViewTab(data), mainViewHelper.viewChanged);

    const getSearchedTransferDestinations = (transferDestinationsSource: Promise<any>, 
        setResultsFunction: (results: any[]) => void, 
        filterFunction?:  (results: any[]) => any[]) => {
        setAreTranferDestinationsLoading(true);

        transferDestinationsSource.then((transferDestinationsDTO: any[]) => {
            if (transferDestinationsDTO) {
                if (filterFunction) {
                    transferDestinationsDTO = filterFunction(transferDestinationsDTO);
                }

                setResultsFunction(mapTargetListForDisplay(transferDestinationsDTO));
            }

            setAreTranferDestinationsLoading(false);
        });
    }

    const getTransferToAgentDestinations = (searchQuery?: string) => {
        if(!searchQuery || searchQuery?.length < 3)
        {
            setAgentTransferDestinations([]);
            return;
        } 

        getSearchedTransferDestinations(genericTransferService.getTransferAgentsDestinations({
            keyword: searchQuery
        }), (results) => setAgentTransferDestinations(results));
    }

    const getTransferToQueueDestinations = (searchQuery?: string) => {
        getSearchedTransferDestinations(genericTransferService.getTransferDestinations(), setQueueTransferDestinations,
        (results) => searchQuery ? results.filter(cd => cd.name.toLowerCase().includes(searchQuery)) : results);
    }

    const handleTransferToQueue = () => {
        const transferDestination = queueTransferDestinations.find((ref: any) => ref.header === transferTarget);

        genericInviteService.transferGenericToQueue(transferDestination.transferId);
    }

    const handleTransferToAgent = () => {
        const transferDestination = agentTransferDestinations.find((ref: any) => ref.header === transferTarget);

        genericInviteService.transferGeneric(transferDestination.transferId);
    }

    const mapTargetListForDisplay = (transferList: any) => {
        const transferDestinationList = [] as any;

        const queues = transferList.filter((el: any) => el.type === ColdTransferType.Queue);

        if (queues.length) {
            transferDestinationList.push({
                content: <Divider content="Queues" />,
                key: Math.random() + 'queues',
                disabled: true
            });
        }

        queues.forEach((queue: any) => {
            transferDestinationList.push({
                header: queue.name,
                transferId: queue.id,
                type: ColdTransferType.Queue,
                key: Math.random() + 'transferTarget',
            });
        });

        const agents = transferList.filter((el: any) => el.type === ColdTransferType.Agent);

        if (agents.length) {
            transferDestinationList.push({
                content: <Divider content="Agents" />,
                key: Math.random() + 'agents',
                disabled: true
            });
        }

        agents.forEach((agent: any) => {
            transferDestinationList.push({
                header: agent.name,
                transferId: agent.id,
                type: ColdTransferType.Agent,
                key: Math.random() + 'transferTarget',
            });
        });

        return transferDestinationList;
    }

    if (currentMainViewTab !== ActiveMainViewItem.WebChatView || !viewData.isVisible) {
        return <></>
    }

    return (
        <Flex fill vAlign="center">
            <Text content={viewData.title} style={{ paddingLeft: '10px' }} />
            {
                !!viewData.description && <Text content={viewData.description} style={{ paddingLeft: '50px' }} />
            }
            <Text content={viewData.queue} style={{ paddingLeft: '50px' }} />

            <Flex.Item push>
                <Flex style={{ paddingRight: '15px' }} gap="gap.medium">
                    <Text content={intl.formatMessage({ id: viewData.state })} />
                    {!!viewData.isAccepted && !!viewData.lastHandledTime && <Timer startDate={viewData.lastHandledTime} />}
                </Flex>
            </Flex.Item>
            {

                viewData.isAccepted && <ColdTransferIVRButton
                    areTranferDestinationsLoading={areTranferDestinationsLoading}
                    isInvalidTransfer={isInvalidTransfer}
                    transferDestinations={agentTransferDestinations}
                    onHandleColdTransferIVR={() => handleTransferToAgent()}
                    onGetSearchedColdTransferDestinations={(searchQuery) => getTransferToAgentDestinations(searchQuery)}
                    icon={<PhoneArrowIcon size="large" />}
                    placeholder={intl.formatMessage({ id: 'AgentView.FindAgent' })}
                    title={intl.formatMessage({ id: 'ChatListView.TransferToAgent' })}
                    action={intl.formatMessage({ id: 'ChatActions.TransferToAgent' })}
                    onSetIsInvalidTransfer={() => setIsInvalidTransfer(false)}
                    onTransferDestinationSelected={(selectedOption) => setTransferTarget(selectedOption)} />
            }
            {
                viewData.isAccepted && <ColdTransferIVRButton
                    areTranferDestinationsLoading={areTranferDestinationsLoading}
                    isInvalidTransfer={isInvalidTransfer}
                    transferDestinations={queueTransferDestinations}
                    onHandleColdTransferIVR={() => handleTransferToQueue()}
                    onGetSearchedColdTransferDestinations={(searchQuery) => getTransferToQueueDestinations(searchQuery)}
                    icon={<SubFlowIcon />}
                    placeholder={intl.formatMessage({ id: 'AgentView.FindQueue' })}
                    title={intl.formatMessage({ id: 'ChatListView.TransferToQueue' })}
                    action={intl.formatMessage({ id: 'ChatActions.TransferToQueue' })}
                    onSetIsInvalidTransfer={() => setIsInvalidTransfer(false)}
                    onTransferDestinationSelected={(selectedOption) => setTransferTarget(selectedOption)} 
                    useDefaultSearch/>
            }

            {
                viewData.transferState === GenericTransferState.Offering &&
                <>
                    <Button
                        onClick={() => genericInviteService.acceptTransferedGeneric()}
                        icon={<AcceptIcon size="large" />}
                        iconOnly
                        text
                        title="Yes"
                        styles={styles.acceptButton}
                    />
                    <Button
                        onClick={() => genericInviteService.rejectTransferedGeneric()}
                        icon={<CloseIcon size="large" />}
                        iconOnly
                        text
                        title="No"
                        styles={styles.rejectButton}
                    />
                </>
            }

            {
                viewData.transferState !== GenericTransferState.Offering &&
                !viewData.transferSubmitted &&
                <>
                    {!viewData.isAccepted && <Button
                        onClick={() => genericInviteService.acceptInvite()}
                        icon={<AcceptIcon size="large" />}
                        iconOnly
                        text
                        title={intl.formatMessage({ id: 'ChatListView.AcceptChat' })}
                        styles={styles.acceptButton}
                    />}
                    <Button
                        onClick={() => viewData.isAccepted ? genericInviteService.terminateGeneric() : genericInviteService.rejectInvite()}
                        icon={<CloseIcon size="large" />}
                        iconOnly
                        text
                        title={intl.formatMessage({ id: `ChatListView.${viewData.isAccepted ? "TerminateChat" : "RejectChat"}` })}
                        styles={styles.rejectButton}
                    />
                </>
            }
        </Flex>
    );
}

export default GenericActionsView;

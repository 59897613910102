import { Listener, listeners } from 'services/io/listeners';
import { ListenerOperations, StorageKeys } from 'utils';
import Lockr from 'lockr';
import { CallSessionDTO } from 'utils/domain/callSessionDTO';
import { Subscription } from 'rxjs';

export class ExternalActions {
    public readonly listenerSendUrlToClient: Listener<any> = listeners.createListener<any>(ListenerOperations.SendUrlToClient);

    private subscriptionExternal: Subscription | null = null;

    initialize() {
        this.subscriptionExternal?.unsubscribe();
        this.subscriptionExternal = this.listenerSendUrlToClient.received.subscribe((obj: any) => {
            const externalProcessed = Lockr.get<string>(StorageKeys.ExternalProcessed);

            if (externalProcessed !== "ExternalProcessed") {
                Lockr.set(StorageKeys.ExternalProcessed, "ExternalProcessed");

                this.openExternalUrl(obj);
            }
        });
    }

    process(callSessionDTO: CallSessionDTO) {
        let externalProcessed = Lockr.get<string>(StorageKeys.ExternalProcessed);

        if (externalProcessed === "ExternalProcessed") {
            Lockr.set(StorageKeys.ExternalProcessed, callSessionDTO.ConversationKey);

            externalProcessed = callSessionDTO.ConversationKey;
        }

        if (externalProcessed !== callSessionDTO.ConversationKey && callSessionDTO.UrlExecuter && callSessionDTO.UrlExecuter.url) {
            Lockr.set(StorageKeys.ExternalProcessed, callSessionDTO.ConversationKey);

            this.openExternalUrl(callSessionDTO.UrlExecuter);
        }
    }

    openExternalUrl(obj: any) {
        if (obj && obj.url) {
            setTimeout(function () {
                window.open(obj.url);
            }, 500);
        }
    }
}

export const externalActions = new ExternalActions();
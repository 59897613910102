import React, { useRef, useState } from 'react';
import { Button, CallIcon, Flex, List, PhoneArrowIcon, StarIcon, UserFriendsIcon } from '@fluentui/react-northstar';
import { useIntl } from 'react-intl';
import { FavoriteContactDTO, FavoriteContactType } from 'utils/domain/favoriteContactDTO';
import { socketContacts } from 'services/contacts';
import { ConferenceStatusAction, logging, MonitorTypes, StorageKeys, PresenceAvailability, CallableContactColumnsEnum } from 'utils';
import Lockr from 'lockr';
import AgentAvatar from 'components/AgentAvatar';
import { socketTransfer } from 'services/transfers';
import { CallSessionDTO } from 'utils/domain/callSessionDTO';
import { Subscription } from 'rxjs';
import './favoritesView.scss';
import { mainViewHelper } from 'utils/helpers/main-view-helper';
import { Presence, PresenceList } from 'utils/domain/presence';
import { socketAudio } from 'services/calling';
import { EnvHelper, GeneralHelper } from 'utils/helpers';
import SipAndPhoneNumberMenu from 'components/SipAndPhoneNumberMenu';
import { AzureActiveDirectorySettingsDTO, SipMappingType } from 'utils/domain/azureActiveDirectorySettingsDTO';

export const FavoritesView = (props: any) => {
  const { isDialpadEnabled } = props;

  const intl = useIntl();
  const favoriteContacts = useRef([] as FavoriteContactDTO[]);
  const [currentCallSession, setCurrentCallSession] = useState(new CallSessionDTO());
  const isOngoingCall = useRef(false);
  const isMonitoring = useRef(false);
  const isCallAccepted = useRef(false);
  const isWarmTransferInProgress = useRef(false);
  const [displayedContacts, setDisplayedContacts] = useState([] as any);
  const azureADSettings = useRef(new AzureActiveDirectorySettingsDTO());

  const acceptedOperations = [
    ConferenceStatusAction[ConferenceStatusAction.Accepted], 
    ConferenceStatusAction[ConferenceStatusAction.WarmInviteRejected],
    ConferenceStatusAction[ConferenceStatusAction.OnHold], 
    ConferenceStatusAction[ConferenceStatusAction.Resumed],
    ConferenceStatusAction[ConferenceStatusAction.WarmCanceled], 
    ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent]
  ];

  const startOperations = [
    ConferenceStatusAction[ConferenceStatusAction.Accepted], 
    ConferenceStatusAction[ConferenceStatusAction.Ringing],
    ConferenceStatusAction[ConferenceStatusAction.WarmInviteSent], 
    ConferenceStatusAction[ConferenceStatusAction.WarmInviteAccepted],
    ConferenceStatusAction[ConferenceStatusAction.WarmInviteRejected],
    ConferenceStatusAction[ConferenceStatusAction.OnHold], 
    ConferenceStatusAction[ConferenceStatusAction.Resumed],
    ConferenceStatusAction[ConferenceStatusAction.WarmCanceled], 
    ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent]
  ];

  const monitoringOperations = [
    ConferenceStatusAction[ConferenceStatusAction.Monitoring], 
    ConferenceStatusAction[ConferenceStatusAction.MonitoringEnded],
    ConferenceStatusAction[ConferenceStatusAction.SupervisorClosed]
  ];

  let subscriptionFavoriteContactsChanged: Subscription | null = null;
  let subscriptionPresence: Subscription | null = null;
  let subscriptionCallSessionReceived: Subscription | null = null;
  let subscriptionWarmTransferInProgress: Subscription | null = null;
  let subscriptionCurrentUserMonitoredCallSessionChanged: Subscription | null = null;
  let subscriptionFavoriteContactsPresenceChanged: Subscription | null = null;
  let subscriptionAzureADSettingsChanged: Subscription | null = null;

  const managementViewCall = "ManagementView.Call";

  React.useEffect(() => {
    initialize();

    return () => {
      componentWillUnmount();
    }
  }, []);

  React.useEffect(() => {
    mapDisplayedContacts();
  }, [isDialpadEnabled]);

  React.useEffect(() => {
    mapDisplayedContacts();
  }, [currentCallSession]);

  const componentWillUnmount = () => {
    subscriptionFavoriteContactsChanged?.unsubscribe();
    subscriptionPresence?.unsubscribe();
    subscriptionCallSessionReceived?.unsubscribe();
    subscriptionWarmTransferInProgress?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
    subscriptionFavoriteContactsPresenceChanged?.unsubscribe();
    subscriptionAzureADSettingsChanged?.unsubscribe();
  }

  const initialize = () => {
    GeneralHelper.logCox(`in FavoritesView.tsx, in initialize`);

    getFavoriteContacts();

    subscriptionFavoriteContactsChanged?.unsubscribe();
    subscriptionFavoriteContactsChanged = mainViewHelper.addedOrRemovedFavoriteContact.subscribe((favContact: FavoriteContactDTO) => {
      if (favContact.IsDeleted) {
        removeFromFavoritesList(favContact);
      } else {
        addToFavoritesList(favContact);
      }

      mapDisplayedContacts();
    });

    subscriptionPresence?.unsubscribe();
    subscriptionPresence = socketContacts.notifyUpdatedPresenceState.subscribe((userPresence: Presence) => {
      updatePresence(Array(1).fill(userPresence), true);
    });

    subscriptionCallSessionReceived?.unsubscribe();
    subscriptionCallSessionReceived = socketAudio.currentUserCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      if (monitoringOperations.includes(callSessionDTO.ConferenceActionAsString)) {
        return;
      }
      GeneralHelper.logCox(`[CC4ALL-3935] FavoritesView.tsx, subscriptionCallSessionReceived, ConferenceActionAsString: ${callSessionDTO.ConferenceActionAsString}`);


      handleCurrentCallSession(callSessionDTO);
      setCurrentCallSession(callSessionDTO);
      mapDisplayedContacts();
    });

    subscriptionWarmTransferInProgress?.unsubscribe();
    subscriptionWarmTransferInProgress = socketAudio.warmTransferInProgress.subscribe((callSessionDTO: CallSessionDTO) => {
      const sip = Lockr.get<string>(StorageKeys.SIP);
      isWarmTransferInProgress.current = (callSessionDTO.TransferTargetUri &&
        callSessionDTO.TransferTargetUri === sip &&
        (callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.WarmCanceledBySecondAgent] &&
          callSessionDTO.ConferenceActionAsString !== ConferenceStatusAction[ConferenceStatusAction.WarmAccepted])) || false;
      GeneralHelper.logCox(`[CC4ALL-3935] FavoritesView.tsx, subscriptionWarmTransferInProgress, sip: ${sip}, isWarmTransferInProgress: ${isWarmTransferInProgress.current}`);

      if (callSessionDTO.PrimaryAgentSIP === sip && callSessionDTO.ConferenceActionAsString === ConferenceStatusAction[ConferenceStatusAction.WarmAccepted]) {
        isOngoingCall.current = true;
        isCallAccepted.current = true;
      }

      mapDisplayedContacts();
    });

    subscriptionCurrentUserMonitoredCallSessionChanged?.unsubscribe();
    subscriptionCurrentUserMonitoredCallSessionChanged = socketAudio.currentUserMonitoredCallSessionChanged.subscribe((callSessionDTO: CallSessionDTO) => {
      if (callSessionDTO.MonitorType === MonitorTypes.None || callSessionDTO.IsComputedAsTerminated) {
        isMonitoring.current = false;
      } else {
        isMonitoring.current = true;
      }
      mapDisplayedContacts();
    });

    subscriptionFavoriteContactsPresenceChanged?.unsubscribe();
    subscriptionFavoriteContactsPresenceChanged = socketContacts.favoriteContactsPresenceChanged.subscribe((obj: boolean) => {
      getAndUpdatePresence(favoriteContacts.current);
    });

    subscriptionAzureADSettingsChanged?.unsubscribe();
    subscriptionAzureADSettingsChanged = socketContacts.azureADSettingsForContactsChanged.subscribe((settings: AzureActiveDirectorySettingsDTO) => {
      azureADSettings.current = settings;
      mapDisplayedContacts();
    });

    getAzureActiveDirectorySettingsForContacts();
    socketAudio.getCurrentCallSession();
  }

  const handleCurrentCallSession = (callSessionDTO: CallSessionDTO) => {
    const isCallParked = GeneralHelper.isCallParkedOrFallback(callSessionDTO.ActionHistory, ConferenceStatusAction.OperatorPark);
    const isFallbackCall = GeneralHelper.isCallParkedOrFallback(callSessionDTO.ActionHistory, ConferenceStatusAction.Fallback);

    isWarmTransferInProgress.current = callSessionDTO.IsWarmTransferInProgress && callSessionDTO.ConferenceActionAsString != ConferenceStatusAction[ConferenceStatusAction.WarmCanceled];
    GeneralHelper.logCox(`[CC4ALL-3935] FavoritesView.tsx, handleCurrentCallSession, isWarmTransferInProgress: ${isWarmTransferInProgress.current}, last ActionHistoryAsString is ${callSessionDTO.ActionHistoryAsString ? callSessionDTO.ActionHistoryAsString[callSessionDTO.ActionHistoryAsString.length - 1] : ''}`);

    let ongoingCall = false;

    if ((startOperations.includes(callSessionDTO.ConferenceActionAsString) && callSessionDTO.IsCurrentUserThePrimaryAgent && !isCallParked && !isFallbackCall)
      || callSessionDTO.IsTeamsUnparkInProgress) {
      ongoingCall = true;
    }

    isOngoingCall.current = ongoingCall;
    isCallAccepted.current = (ongoingCall && acceptedOperations.includes(callSessionDTO.ConferenceActionAsString) &&
      callSessionDTO.ActionHistoryAsString &&
      callSessionDTO.ActionHistoryAsString[callSessionDTO.ActionHistoryAsString.length - 1] !== ConferenceStatusAction[ConferenceStatusAction.WarmInviteAccepted] &&
      !callSessionDTO.IsTeamsUnparkInProgress && !isCallParked && !isFallbackCall) || false;
  }

  const removeFromFavoritesList = (favoriteContact: FavoriteContactDTO) => {
    const removedIndex = favoriteContacts.current.findIndex(x => x.ID === favoriteContact.ID);

    if (removedIndex > -1) {
      favoriteContacts.current.splice(removedIndex, 1);
    }

    socketContacts.maxNumberOfFavoriteContactReached.next(favoriteContacts.current.length);
  }

  const addToFavoritesList = (favoriteContact: FavoriteContactDTO) => {
    if (favoriteContacts.current.length < socketContacts.getMaxNumberOfFavoriteContacts()) {
      const existingIndex = favoriteContacts.current.findIndex(x => x.ID === favoriteContact.ID);

      if (existingIndex < 0) {
        favoriteContacts.current.push(favoriteContact);

        if (favoriteContact.SIP) {
          const favList: FavoriteContactDTO[] = Array(1).fill(favoriteContact);
          getAndUpdatePresence(favList, true);
        }

        socketContacts.maxNumberOfFavoriteContactReached.next(favoriteContacts.current.length);
      }
    }
  }

  const getFavoriteContacts = () => {
    const userId = Lockr.get<number>(StorageKeys.UserId);

    socketContacts.getFavoriteContactsByAgentId(userId).then((favoriteContactsList: FavoriteContactDTO[] | null) => {
      if (favoriteContactsList) {
        favoriteContacts.current = favoriteContactsList;
        mapDisplayedContacts();
        getAndUpdatePresence(favoriteContactsList, true);
        checkIsMaxNumberOfFavoriteContactsReached(favoriteContactsList);
      }
    });
  }

  const checkIsMaxNumberOfFavoriteContactsReached = (favoriteContactsList: FavoriteContactDTO[]) => {
    if (favoriteContactsList.length >= socketContacts.getMaxNumberOfFavoriteContacts()) {
      socketContacts.maxNumberOfFavoriteContactReached.next(favoriteContactsList.length);
    }
  }

  const mapDisplayedContacts = () => {
    const azureSipMappingType = azureADSettings.current.SipMappingType;
    const items: { key: any; media: JSX.Element; header: any; endMedia?: JSX.Element; id: number; sip?: string; displayname?: string; className?: string; }[] = [];

    const sortedContacts = sortByDisplayName(favoriteContacts.current);
    sortedContacts.forEach((el: FavoriteContactDTO) => {
      if (el.FavoriteContactTypeId === FavoriteContactType.AzureActiveDirectory) {
        const sip = azureSipMappingType === SipMappingType.Mail ? el.Email : el.Upn;
        el.SIP = sip ? "sip:" + sip : sip;
      }

      items.push({
        key: "fav-item" + el.ID,
        id: el.ID,
        media: <div className="favorite-avatar-item">
          <AgentAvatar name={el.DisplayName}
            status={el.Presence && el.Presence.Availability ? getStatusValue(el.Presence.Availability, el.Presence.Activity) : "unknown"} />
        </div>,
        endMedia: getButtons(el),
        header: '',
        sip: el.SIP,
        displayname: el.DisplayName,
        className: "fav-item"
      });
    });

    setDisplayedContacts(items);
  }

  const getStatusValue = (availability: string, activity: string | undefined) => {
    return activity ? activity : availability;
  }

  const getCallButtonForCC4SkypeQueue = (el: FavoriteContactDTO) => {
    return <Button icon={<CallIcon />} iconOnly disabled={true} text title={intl.formatMessage({ id: managementViewCall })} />;
  }
  const getCallButtonForNonCC4SkypeQueue = (el: FavoriteContactDTO) => {
    let callableElements = 0;
    callableElements = el.SIP ? callableElements + 1 : callableElements;
    callableElements = el.PhoneNumber ? callableElements + 1 : callableElements;
    callableElements = el.MobilePhone ? callableElements + 1 : callableElements;
    callableElements = el.BusinessPhones ? callableElements + 1 : callableElements;

    return callableElements > 1 ?
      getCallButtonForBothSipAndPhoneNumber(el)
      : getCallButtonForExclusiveSipOrPhoneNumber(el);
  }
  const getCallButtonForExclusiveSipOrPhoneNumber = (el: FavoriteContactDTO) => {
    return <Button icon={<CallIcon />} iconOnly text title={intl.formatMessage({ id: managementViewCall })} onClick={() => handleCall(el)} />;
  }
  const getCallButtonForBothSipAndPhoneNumber = (el: FavoriteContactDTO) => {
    return <SipAndPhoneNumberMenu sip={el.SIP}
      phoneNumber={el.PhoneNumber}
      businessPhone={el.BusinessPhones}
      mobilePhone={el.MobilePhone}
      classNameProp="fav-contact-menu-call"
      triggerTitle={intl.formatMessage({ id: managementViewCall })}
      callBack={handleCall}
      dtoDetails={el}
      triggerButton={<CallIcon />}
    />;
  }
  const getButtons = (el: FavoriteContactDTO) => {
    const shouldDisplayButtons = !isWarmTransferInProgress.current && !isMonitoring.current && isOngoingCall.current && isCallAccepted.current;
    GeneralHelper.logCox(`[CC4ALL-3935] FavoritesView.tsx, getButtons, isWarmTransferInProgress: ${isWarmTransferInProgress.current}, isOngoingCall: ${isOngoingCall.current}, isCallAccepted: ${isCallAccepted.current}, shouldDisplayButtons: ${shouldDisplayButtons}`);
    return <>
      {shouldDisplayButtons &&
        <>
          {el.SIP && el.PhoneNumber ?
            <>
              <SipAndPhoneNumberMenu sip={el.SIP}
                phoneNumber={el.PhoneNumber}
                businessPhone={el.BusinessPhones}
                mobilePhone={el.MobilePhone}
                classNameProp="fav-contact-menu-call"
                triggerTitle={intl.formatMessage({ id: "CallActions.WarmTransfer" })}
                callBack={handleTransfer}
                dtoDetails={el}
                isWarmTransfer={true}
                triggerButton={<UserFriendsIcon size="medium" />}
              />
              <SipAndPhoneNumberMenu sip={el.SIP}
                phoneNumber={el.PhoneNumber}
                businessPhone={el.BusinessPhones}
                mobilePhone={el.MobilePhone}
                classNameProp="fav-contact-menu-call"
                triggerTitle={intl.formatMessage({ id: "CallActions.ColdTransfer" })}
                callBack={handleTransfer}
                dtoDetails={el}
                isWarmTransfer={false}
                triggerButton={<PhoneArrowIcon size="medium" />}
              />
            </> :
            <>
              <Button text
                disabled={el.FavoriteContactTypeId === FavoriteContactType.CC4SkypeQueue}
                iconOnly icon={<UserFriendsIcon size="medium" />}
                title={intl.formatMessage({ id: "CallActions.WarmTransfer" })}
                size="medium" onClick={() => handleTransfer(el, undefined, true)}
              />
              <Button text iconOnly icon={<PhoneArrowIcon size="medium" />} title={intl.formatMessage({ id: "CallActions.ColdTransfer" })}
                size="medium" onClick={() => handleTransfer(el, undefined, false)}
              />
            </>
          }
        </>
      }
      {
        !isWarmTransferInProgress.current && !isMonitoring.current && !isOngoingCall.current &&
        <>
          {isDialpadEnabled && <>
            {el.FavoriteContactTypeId !== FavoriteContactType.CC4SkypeQueue ?
              getCallButtonForNonCC4SkypeQueue(el)
              : getCallButtonForCC4SkypeQueue(el)
            }
          </>
          }
        </>
      }
      <Button text iconOnly icon={<StarIcon className="active-favorite" outline size="medium" />}
        title={intl.formatMessage({ id: "CallActions.RemoveFromFavorites" })} size="medium"
        onClick={() => removeFavorite(el)} />
    </>
  }

  const handleTransfer = (favoriteContact: FavoriteContactDTO, transferToType: CallableContactColumnsEnum | undefined, isWarm: boolean = false) => {
    let transferTo: string | undefined;

    if (transferToType === undefined) {
      transferTo = favoriteContact.SIP ? favoriteContact.SIP : favoriteContact.PhoneNumber;
    } else {
      transferTo = getCallableColumnValue(favoriteContact, transferToType);
    }

    const contactType = GeneralHelper.mapFavoriteTypeToContactType(favoriteContact.FavoriteContactTypeId);

    if (isWarm) {
      socketTransfer.onWarmTransferStarted.next({ 'transferTo': transferTo, 'contactType': contactType });
    }
    else {
      socketTransfer.onColdTransferStarted.next({ 'transferTo': transferTo, 'contactType': contactType });
    }
  }

  const getCallableColumnValue = (favoriteContact: FavoriteContactDTO, transferToType: CallableContactColumnsEnum | undefined) => {
    switch (transferToType) {
      case CallableContactColumnsEnum.Sip:
        return favoriteContact.SIP;
      case CallableContactColumnsEnum.LocalPhone:
        return favoriteContact.PhoneNumber;
      case CallableContactColumnsEnum.BusinessPhones:
        return favoriteContact.BusinessPhones;
      case CallableContactColumnsEnum.MobilePhone:
        return favoriteContact.MobilePhone;
      default:
        return "";
    }
  }

  const handleCall = (el: FavoriteContactDTO, transferToType: CallableContactColumnsEnum | undefined = undefined) => {
    let predialedText: string | undefined = '';

    if (transferToType !== undefined) {
      predialedText = getCallableColumnValue(el, transferToType);
    } else {
      predialedText = el.SIP ? el.SIP : el.PhoneNumber;
      predialedText = !predialedText ? el.MobilePhone : predialedText;
      predialedText = !predialedText ? el.BusinessPhones : predialedText;
    }

    if (!predialedText) {
      return;
    }

    predialedText = predialedText ? predialedText.toLowerCase().replace("tel:", "") : predialedText;
    mainViewHelper.navigationViewGoToDialpad.next(predialedText);
  }

  const removeFavorite = (favoriteContact: FavoriteContactDTO) => {
    socketContacts.removeFavoriteContact(favoriteContact).then((response: boolean) => {
      if (response) {
        favoriteContact.IsDeleted = true;
        removeFromFavoritesList(favoriteContact);
        mainViewHelper.addedOrRemovedFavoriteContact.next(favoriteContact);
        socketContacts.hasAnyFavoriteContactChanged.next(true);

        mapDisplayedContacts();
      } else {
        logging.errorHandler.next("ErrorMessage.FavoriteContact.RemoveContact");
      }
    }).catch(err => {
      logging.errorHandler.next("ErrorMessage.FavoriteContact.RemoveContact");
      console.log(err.message);
    });
  }

  const updatePresence = (presences: Presence[], updatePresenceForAgents: boolean = false) => {
    favoriteContacts.current.forEach((el, index) => {
      let filteredPresences: Presence[] = [];

      if (updatePresenceForAgents) {
        const upnSip = el.FavoriteContactTypeId === FavoriteContactType.AzureActiveDirectory && el.Upn ? 'sip:' + el.Upn.toLowerCase() : '';
        const sipAddress = el.FavoriteContactTypeId === FavoriteContactType.AzureActiveDirectory ? upnSip : el.SIP?.toLowerCase();
        filteredPresences = presences.filter(x => x.Id?.toLowerCase() === sipAddress);
      } else {
        filteredPresences = presences.filter(x => x.Id?.toLowerCase() === el.ContactID?.toLowerCase() && el.Presence?.PresenceType === PresenceAvailability.None);
      }

      const presence = filteredPresences && filteredPresences.length === 1 ? filteredPresences[0] : null;

      if (presence && presence.Availability) {
        favoriteContacts.current[index].Presence = presence;
      }
    });

    mapDisplayedContacts();
  }

  const getAndUpdatePresence = (contacts: FavoriteContactDTO[], getAgentsPresence: boolean = false) => {
    if (contacts.length) {
      const sips = contacts.filter(x => x.SIP && x.SIP.length).map(({ SIP }) => SIP);
      const ids = contacts.filter(x => x.ContactID && x.ContactID.length).map(({ ContactID }) => ContactID);

      const companyKey = Lockr.get<string>(StorageKeys.CompanyKey);

      const paramAgentsPresence = { Sips: sips };
      const paramFavoriteContactsPresence = { Ids: ids, CompanyKey: companyKey };

      if (getAgentsPresence && !EnvHelper.isStage3()) {
        getAndUpdateAgentsPresence(paramAgentsPresence);
      }

      getAndUpdateFavoriteContactsPresence(paramFavoriteContactsPresence);
    }
  }

  const getAndUpdateAgentsPresence = (param: any) => {
    socketContacts.getUsersPresence(param).then((presenceResult: PresenceList) => {
      const presenceList = presenceResult.ResultList;
      updatePresence(presenceList, true);
    });
  }

  const getAndUpdateFavoriteContactsPresence = (param: any) => {
    socketContacts.getFavoriteContactsPresence(param).then((presenceResult: PresenceList) => {
      const presenceList = presenceResult.ResultList;
      updatePresence(presenceList);
    });
  }

  const sortByDisplayName = (favorites: FavoriteContactDTO[]) => {
    return favorites.sort((a, b) => {
      var aDisplayName = a.DisplayName ? a.DisplayName.toLowerCase() : "";
      var bDisplayName = b.DisplayName ? b.DisplayName.toLowerCase() : "";
      if (aDisplayName === bDisplayName) {
        return 0;
      }
      return aDisplayName > bDisplayName ? 1 : -1;
    });
  }

  const getAzureActiveDirectorySettingsForContacts = () => {
    socketContacts.getCachedAzureActiveDirectorySettings().then((settings: AzureActiveDirectorySettingsDTO) => {
      azureADSettings.current = settings;
      mapDisplayedContacts();
    });
  }

  return (
    <Flex className="favorites-contacts-container">
      <List key="favorites-contacts-list"
        className={!isWarmTransferInProgress.current &&
          !isMonitoring.current && isOngoingCall.current &&
          isCallAccepted.current ? "favorites-contacts-list fav-transfer-visible" : "favorites-contacts-list"}
        styles={{ padding: '10px' }} items={displayedContacts} />
    </Flex >
  );
}

export default FavoritesView;
